import styled from 'styled-components';
import { Switch, SwitchProps } from 'antd';
import styles from './index.module.scss';

import React from 'react';

const StyledSwitch = styled(Switch)``;

interface Props extends SwitchProps {
  onChange?: (checked: boolean) => void;
  checked: boolean;
}

const RakSwitch = ({ checked, onChange, ...props }: Props) => {
  return (
    <div className={styles.btn}>
      <StyledSwitch
        {...props}
        defaultChecked={checked}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};

export default RakSwitch;
