import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetWcAppAdapterRequestParamDTO,
  GetWcAppAdapterResponseDTO,
  ICreateWcAppAdapter,
  IWcAppAdapterDetail,
} from 'interfaces/wcAppAdapter';
export function getWcAppAdapterRequest(
  params?: GetWcAppAdapterRequestParamDTO,
): Promise<GetWcAppAdapterResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCAPPADAPTER}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailWcAppAdapter(
  wcAppAdapterId: string,
): Promise<IWcAppAdapterDetail> {
  return Instance.get(`/${PREFIX_API.WCAPPADAPTER}/${wcAppAdapterId}`);
}
export function createWcAppAdapterRequest(
  wcAppAdapter: ICreateWcAppAdapter,
): Promise<GetWcAppAdapterResponseDTO> {
  return Instance.post(`/${PREFIX_API.WCAPPADAPTER}`, wcAppAdapter);
}
export function updateWcAppAdapterRequest(
  wcAppAdapter: ICreateWcAppAdapter,
): Promise<GetWcAppAdapterResponseDTO> {
  const payload: any = { ...wcAppAdapter };
  const id = wcAppAdapter.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.WCAPPADAPTER}/${id}`, payload);
}
export function getWcAppAdapterRequestPaginationConfig(): Promise<GetWcAppAdapterResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCAPPADAPTER}/pagination-config`);
}
