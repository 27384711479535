export enum RequestType {
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  ADD_NEW_USER = 'ADD_NEW_USER',
  UNFREEZE_TRANSACTION = 'UNFREEZE_TRANSACTION',
  MARK_LOST_DEVICE = 'MARK_LOST_DEVICE',
  REP_REPLACE_DEVICE = 'REP_REPLACE_DEVICE',
  CREATE_FEE_INFORMATION = 'CREATE_FEE_INFORMATION',
  UPDATE_FEE_INFORMATION = 'UPDATE_FEE_INFORMATION',
  RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL',
  RESEND_ONBOARDING_EMAIL = 'RESEND_ONBOARDING_EMAIL',
  REMOVE_ACCESS = 'REMOVE_ACCESS',
  UNLOCK_USER = 'UNLOCK_USER',
  REP_UPDATE_USER = 'REP_UPDATE_USER',
  REP_REMOVE_USER = 'REP_REMOVE_USER',
  UPDATE_FEATURE_NETWORK_TRANSFER_ACCESS = 'UPDATE_FEATURE_NETWORK_TRANSFER_ACCESS',
  UPDATE_FEATURE_STAKING_ACCESS = 'UPDATE_FEATURE_STAKING_ACCESS',
  CHANGE_SUBSCRIBE_STAKING = 'CHANGE_SUBSCRIBE_STAKING',
  EDIT_CUSTOMER_POLICY = 'EDIT_CUSTOMER_POLICY',
  CUSTOMER_SUBSCRIBE = 'CUSTOMER_SUBSCRIBE',
}

export enum ApproveErrCode {
  EXISTS_CUSTOMER = 'EXISTS_CUSTOMER',
  EXISTS_USER = 'EXISTS_USER',
}

export enum TAB_CREATE_CUSTOMER {
  GENERAL = 'GENERAL',
  PRODUCT_SUBSCRIPTION = 'PRODUCT_SUBSCRIPTION',
  ADMIN_QUORUM = 'ADMIN_QUORUM',
}
