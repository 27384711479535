export enum InvoiceStatus {
  PENDING = 'PENDING',
  REVIEWED = 'REVIEWED',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  OVERDUE = 'OVERDUE',
  INITIAL = 'INITIAL',
}

export enum SortBillingListTable {
  invoiceNumber = 'INVOICE_NUMBER',
  billingPeriod = 'BILLING_PERIOD',
  customerName = 'CUSTOMER_NAME',
  fee = 'FEE',
  status = 'STATUS',
  dueDate = 'DUEDATE',
}
