import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortCusSubscribe,
  GroupByField,
  CustomerOnScreen,
} from 'enum/cusSubscribe.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetCusSubscribeRequestParamDTO,
  GetCusSubscribeResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ICusSubscribeDetail,
} from 'interfaces/cusSubscribe';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface CusSubscribeState {
  params: GetCusSubscribeRequestParamDTO;
  cusSubscribe: ResponseData<GetCusSubscribeResponseDTO>;
  cusSubscribePaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  cusSubscribeDetail: ResponseData<ICusSubscribeDetail>;
}
export function getDefaultCusSubscribeRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortCusSubscribe.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: CusSubscribeState = {
  params: getDefaultCusSubscribeRequestParams(),
  cusSubscribe: {
    status: RequestStatus.Idle,
  },
  cusSubscribePaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ROLE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  cusSubscribeDetail: {
    status: RequestStatus.Idle,
  },
};
const getCusSubscribeRequest: CaseReducer<
  CusSubscribeState,
  PayloadAction<any>
> = (state: CusSubscribeState) => {
  state.cusSubscribe.status = RequestStatus.Loading;
};
const getCusSubscribeSuccess: CaseReducer<
  CusSubscribeState,
  PayloadAction<GetCusSubscribeResponseDTO>
> = (
  state: CusSubscribeState,
  { payload }: PayloadAction<GetCusSubscribeResponseDTO>,
) => {
  state.cusSubscribe.status = RequestStatus.Success;
  state.cusSubscribe.data = payload;
};
const getCusSubscribeFailed: CaseReducer<CusSubscribeState> = (
  state: CusSubscribeState,
) => {
  state.cusSubscribe.status = RequestStatus.Failed;
};
const getDetailCusSubscribeRequest: CaseReducer<
  CusSubscribeState,
  PayloadAction<string>
> = (state: CusSubscribeState) => {
  state.cusSubscribeDetail.status = RequestStatus.Loading;
};
const getDetailCusSubscribeSuccess: CaseReducer<
  CusSubscribeState,
  PayloadAction<ICusSubscribeDetail>
> = (
  state: CusSubscribeState,
  { payload }: PayloadAction<ICusSubscribeDetail>,
) => {
  state.cusSubscribeDetail.status = RequestStatus.Success;
  state.cusSubscribeDetail.data = payload;
};
const getDetailCusSubscribeFailed: CaseReducer<CusSubscribeState> = (
  state: CusSubscribeState,
) => {
  state.cusSubscribeDetail.status = RequestStatus.Failed;
};
const createCusSubscribeRequest: CaseReducer<
  CusSubscribeState,
  PayloadAction<ICusSubscribeDetail>
> = (state: CusSubscribeState) => {
  state.cusSubscribeDetail.status = RequestStatus.Loading;
};
const createCusSubscribeSuccess: CaseReducer<
  CusSubscribeState,
  PayloadAction<ICusSubscribeDetail>
> = (
  state: CusSubscribeState,
  { payload }: PayloadAction<ICusSubscribeDetail>,
) => {
  state.cusSubscribeDetail.status = RequestStatus.Success;
  state.cusSubscribeDetail.data = payload;
};
const createCusSubscribeFailed: CaseReducer<CusSubscribeState> = (
  state: CusSubscribeState,
) => {
  state.cusSubscribeDetail.status = RequestStatus.Failed;
};
const updateCusSubscribeRequest: CaseReducer<
  CusSubscribeState,
  PayloadAction<ICusSubscribeDetail>
> = (state: CusSubscribeState) => {
  state.cusSubscribeDetail.status = RequestStatus.Loading;
};
const updateCusSubscribeSuccess: CaseReducer<
  CusSubscribeState,
  PayloadAction<ICusSubscribeDetail>
> = (
  state: CusSubscribeState,
  { payload }: PayloadAction<ICusSubscribeDetail>,
) => {
  state.cusSubscribeDetail.status = RequestStatus.Success;
  state.cusSubscribeDetail.data = payload;
};
const updateCusSubscribeFailed: CaseReducer<CusSubscribeState> = (
  state: CusSubscribeState,
) => {
  state.cusSubscribeDetail.status = RequestStatus.Failed;
};
const appendCusSubscribe: CaseReducer<CusSubscribeState, PayloadAction<any>> = (
  state: CusSubscribeState,
  { payload }: PayloadAction<any>,
) => {
  if (state.cusSubscribe?.data?.listCusSubscribe) {
    state.cusSubscribe.status = RequestStatus.Success;
    state.cusSubscribe.data.listCusSubscribe = [...payload];
  }
};
const setCanLoadMore = (
  state: CusSubscribeState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetCusSubscribe: CaseReducer<CusSubscribeState> = (
  state: CusSubscribeState,
) => {
  state.cusSubscribe = {
    status: RequestStatus.Idle,
  };
};
const resetCusSubscribeParams: CaseReducer<CusSubscribeState> = (
  state: CusSubscribeState,
) => {
  state.params = getDefaultCusSubscribeRequestParams();
};
const setCusSubscribeFilterParams = (
  state: CusSubscribeState,
  { payload }: PayloadAction<GetCusSubscribeRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  CusSubscribeState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: CusSubscribeState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<CusSubscribeState, PayloadAction<any>> = (
  state: CusSubscribeState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<CusSubscribeState> = (
  state: CusSubscribeState,
) => {
  state.action.status = RequestStatus.Failed;
};
const getCusSubscribePaginationConfigRequest: CaseReducer<
  CusSubscribeState,
  PayloadAction<any>
> = (state: CusSubscribeState) => {
  state.cusSubscribePaginationConfig.status = RequestStatus.Loading;
};
const getCusSubscribePaginationConfigSuccess: CaseReducer<
  CusSubscribeState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: CusSubscribeState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.cusSubscribePaginationConfig.status = RequestStatus.Success;
  state.cusSubscribePaginationConfig.data = payload;
};
const getCusSubscribePaginationConfigFailed: CaseReducer<CusSubscribeState> = (
  state: CusSubscribeState,
) => {
  state.cusSubscribePaginationConfig.status = RequestStatus.Failed;
};
const cusSubscribeSlice = createSlice({
  name: 'cusSubscribe',
  initialState,
  reducers: {
    getCusSubscribeRequest,
    getCusSubscribeSuccess,
    getCusSubscribeFailed,
    getDetailCusSubscribeRequest,
    getDetailCusSubscribeSuccess,
    getDetailCusSubscribeFailed,
    createCusSubscribeRequest,
    createCusSubscribeSuccess,
    createCusSubscribeFailed,
    updateCusSubscribeRequest,
    updateCusSubscribeSuccess,
    updateCusSubscribeFailed,
    appendCusSubscribe,
    setCanLoadMore,
    resetCusSubscribe,
    setCusSubscribeParams<K extends keyof GetCusSubscribeRequestParamDTO>(
      state: CusSubscribeState,
      action: PayloadAction<{
        key: K;
        value: GetCusSubscribeRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setCusSubscribeFilterParams,
    resetCusSubscribeParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getCusSubscribePaginationConfigRequest,
    getCusSubscribePaginationConfigSuccess,
    getCusSubscribePaginationConfigFailed,
  },
});
// Actions
export const cusSubscribeActions = cusSubscribeSlice.actions;
// Reducer
export const cusSubscribeReducer = cusSubscribeSlice.reducer;
// Selectors
export const selectCusSubscribe = (state: AppState) =>
  state.cusSubscribe?.cusSubscribe;
export const selectParamCusSubscribe = (state: AppState) =>
  state.cusSubscribe?.params;
export const selectCanLoadMoreCusSubscribe = (state: AppState) =>
  state.cusSubscribe?.canLoadMore;
export const selectCusSubscribeDetail = (state: AppState) =>
  state.cusSubscribe?.cusSubscribeDetail;
export const selectCusSubscribePagination = (state: AppState) =>
  state.cusSubscribe?.cusSubscribePaginationConfig;
