import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { FILE_TYPE } from 'constants/index';
import { store } from 'store';
import { authActions } from 'store/slices/auth';

const __DEV__ = process.env.NODE_ENV === 'development';

const Instance = Axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },

  paramsSerializer(params) {
    const searchParams = new URLSearchParams();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(params)) {
      const param = params[key];
      if (param !== undefined) {
        if (Array.isArray(param)) {
          param.forEach((p, i) => {
            searchParams.append(`${key}[${i}]`, p);
          });
        } else {
          searchParams.append(key, param);
        }
      }
    }
    return searchParams.toString();
  },
});

Instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const state = store.getState();

    const accessToken = state.auth.authInfo.data.AccessToken;
    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error: AxiosError) => {
    if (__DEV__) {
      console.error('API Request Error:', error);
    }
    return Promise.reject(error);
  },
);

Instance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.headers?.['content-type']?.includes(FILE_TYPE.PDF) ||
      response.headers?.['content-type']?.includes(FILE_TYPE.ZIP) ||
      response.headers?.['content-type']?.includes(FILE_TYPE.TEXT_CSV)
    ) {
      return response;
    }

    if (response.data?.data) {
      return response.data.data;
    } else if (response.data?.list) {
      return response.data;
    } else {
      return Promise.reject(response.data);
    }
  },
  async (error: AxiosError) => {
    if (__DEV__) {
      console.error('API Response Error:', error);
    }

    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      // TODO: should improve this flow, if not is can be dead loop
      setTimeout(() => {
        window.location.href = '/logout';
        store.dispatch(authActions.logout());
      }, 5000);
    }
    const errorMessage = error?.response?.statusText;
    if (errorMessage) {
      return Promise.reject(new Error(errorMessage));
    }
    return Promise.reject(error.response?.data);
  },
);

export default Instance;
