import { SvgIcon } from 'app/components/SvgIcon';
import ItemComment from 'app/components/Transaction/DetailTransaction/BoxComment/ItemComment';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { commentActions, selectComments } from 'store/slices/comment';
import { selectPendingRequestDetail } from 'store/slices/request';
import KYTInformation from './components/KYTInformation';
import TransactionInformation from './components/TransactionInformation';
import styles from './index.module.scss';

type Props = {
  requestId: string;
  isMask: boolean;
};

const UnfreezeTransactionRequest = ({ requestId, isMask }: Props) => {
  const dispatch = useAppDispatch();
  const requestDetails = useAppSelector(selectPendingRequestDetail);
  const requestDetail = requestDetails?.data;
  const comments = useAppSelector(selectComments) || [];

  useEffect(() => {
    if (requestDetail?.txId)
      dispatch(
        commentActions.getCommentsRequest({
          txnId: requestDetail?.txId,
        }),
      );
  }, [requestDetail?.txId]);

  return (
    <div className={styles.detail}>
      <TransactionInformation
        drawerWidth={1000}
        transactionData={requestDetail}
        isMask={isMask}
      />
      <KYTInformation />
      <div className={styles.container}>
        <div className={styles.header}>
          <SvgIcon
            className={styles.icon}
            name="comment"
            width={16}
            height={16}
          />
          <div className={styles.heading}>Comment</div>
        </div>
        <div className={styles.list_comment}>
          {comments.length > 0 &&
            comments.map(comment => {
              return (
                <ItemComment
                  key={comment.id}
                  comment={comment}
                  txnId={requestId}
                  isNotAllowedAction={true}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UnfreezeTransactionRequest;
