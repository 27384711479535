import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortWcAdapter, GroupByField, CustomerOnScreen } from 'enum/wcAdapter.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetWcAdapterRequestParamDTO,
  GetWcAdapterResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IWcAdapterDetail,
} from 'interfaces/wcAdapter';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface WcAdapterState {
  params: GetWcAdapterRequestParamDTO;
  wcAdapter: ResponseData<GetWcAdapterResponseDTO>;
  wcAdapterPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  wcAdapterDetail: ResponseData<IWcAdapterDetail>;
}
export function getDefaultWcAdapterRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortWcAdapter.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: WcAdapterState = {
  params: getDefaultWcAdapterRequestParams(),
  wcAdapter: {
    status: RequestStatus.Idle,
  },
  wcAdapterPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ROLE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  wcAdapterDetail: {
    status: RequestStatus.Idle,
  },
};
const getWcAdapterRequest: CaseReducer<WcAdapterState, PayloadAction<any>> = (
  state: WcAdapterState,
) => {
  state.wcAdapter.status = RequestStatus.Loading;
};
const getWcAdapterSuccess: CaseReducer<
  WcAdapterState,
  PayloadAction<GetWcAdapterResponseDTO>
> = (state: WcAdapterState, { payload }: PayloadAction<GetWcAdapterResponseDTO>) => {
  state.wcAdapter.status = RequestStatus.Success;
  state.wcAdapter.data = payload;
};
const getWcAdapterFailed: CaseReducer<WcAdapterState> = (state: WcAdapterState) => {
  state.wcAdapter.status = RequestStatus.Failed;
};
const getDetailWcAdapterRequest: CaseReducer<WcAdapterState, PayloadAction<string>> = (
  state: WcAdapterState,
) => {
  state.wcAdapterDetail.status = RequestStatus.Loading;
};
const getDetailWcAdapterSuccess: CaseReducer<
  WcAdapterState,
  PayloadAction<IWcAdapterDetail>
> = (state: WcAdapterState, { payload }: PayloadAction<IWcAdapterDetail>) => {
  state.wcAdapterDetail.status = RequestStatus.Success;
  state.wcAdapterDetail.data = payload;
};
const getDetailWcAdapterFailed: CaseReducer<WcAdapterState> = (state: WcAdapterState) => {
  state.wcAdapterDetail.status = RequestStatus.Failed;
};
const createWcAdapterRequest: CaseReducer<WcAdapterState, PayloadAction<IWcAdapterDetail>> = (
  state: WcAdapterState,
) => {
  state.wcAdapterDetail.status = RequestStatus.Loading;
};
const createWcAdapterSuccess: CaseReducer<WcAdapterState, PayloadAction<IWcAdapterDetail>> = (
  state: WcAdapterState,
  { payload }: PayloadAction<IWcAdapterDetail>,
) => {
  state.wcAdapterDetail.status = RequestStatus.Success;
  state.wcAdapterDetail.data = payload;
};
const createWcAdapterFailed: CaseReducer<WcAdapterState> = (state: WcAdapterState) => {
  state.wcAdapterDetail.status = RequestStatus.Failed;
};
const updateWcAdapterRequest: CaseReducer<WcAdapterState, PayloadAction<IWcAdapterDetail>> = (
  state: WcAdapterState,
) => {
  state.wcAdapterDetail.status = RequestStatus.Loading;
};
const updateWcAdapterSuccess: CaseReducer<WcAdapterState, PayloadAction<IWcAdapterDetail>> = (
  state: WcAdapterState,
  { payload }: PayloadAction<IWcAdapterDetail>,
) => {
  state.wcAdapterDetail.status = RequestStatus.Success;
  state.wcAdapterDetail.data = payload;
};
const updateWcAdapterFailed: CaseReducer<WcAdapterState> = (state: WcAdapterState) => {
  state.wcAdapterDetail.status = RequestStatus.Failed;
};
const appendWcAdapter: CaseReducer<WcAdapterState, PayloadAction<any>> = (
  state: WcAdapterState,
  { payload }: PayloadAction<any>,
) => {
  if (state.wcAdapter?.data?.listWcAdapter) {
    state.wcAdapter.status = RequestStatus.Success;
    state.wcAdapter.data.listWcAdapter = [...payload];
  }
};
const setCanLoadMore = (state: WcAdapterState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetWcAdapter: CaseReducer<WcAdapterState> = (state: WcAdapterState) => {
  state.wcAdapter = {
    status: RequestStatus.Idle,
  };
};
const resetWcAdapterParams: CaseReducer<WcAdapterState> = (state: WcAdapterState) => {
  state.params = getDefaultWcAdapterRequestParams();
};
const setWcAdapterFilterParams = (
  state: WcAdapterState,
  { payload }: PayloadAction<GetWcAdapterRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  WcAdapterState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: WcAdapterState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<WcAdapterState, PayloadAction<any>> = (
  state: WcAdapterState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<WcAdapterState> = (state: WcAdapterState) => {
  state.action.status = RequestStatus.Failed;
};
const getWcAdapterPaginationConfigRequest: CaseReducer<
  WcAdapterState,
  PayloadAction<any>
> = (state: WcAdapterState) => {
  state.wcAdapterPaginationConfig.status = RequestStatus.Loading;
};
const getWcAdapterPaginationConfigSuccess: CaseReducer<
  WcAdapterState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: WcAdapterState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.wcAdapterPaginationConfig.status = RequestStatus.Success;
  state.wcAdapterPaginationConfig.data = payload;
};
const getWcAdapterPaginationConfigFailed: CaseReducer<WcAdapterState> = (
  state: WcAdapterState,
) => {
  state.wcAdapterPaginationConfig.status = RequestStatus.Failed;
};
const wcAdapterSlice = createSlice({
  name: 'wcAdapter',
  initialState,
  reducers: {
    getWcAdapterRequest,
    getWcAdapterSuccess,
    getWcAdapterFailed,
    getDetailWcAdapterRequest,
    getDetailWcAdapterSuccess,
    getDetailWcAdapterFailed,
    createWcAdapterRequest,
    createWcAdapterSuccess,
    createWcAdapterFailed,
    updateWcAdapterRequest,
    updateWcAdapterSuccess,
    updateWcAdapterFailed,
    appendWcAdapter,
    setCanLoadMore,
    resetWcAdapter,
    setWcAdapterParams<K extends keyof GetWcAdapterRequestParamDTO>(
      state: WcAdapterState,
      action: PayloadAction<{
        key: K;
        value: GetWcAdapterRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setWcAdapterFilterParams,
    resetWcAdapterParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getWcAdapterPaginationConfigRequest,
    getWcAdapterPaginationConfigSuccess,
    getWcAdapterPaginationConfigFailed,
  },
});
// Actions
export const wcAdapterActions = wcAdapterSlice.actions;
// Reducer
export const wcAdapterReducer = wcAdapterSlice.reducer;
// Selectors
export const selectWcAdapter = (state: AppState) => state.wcAdapter?.wcAdapter;
export const selectParamWcAdapter = (state: AppState) => state.wcAdapter?.params;
export const selectCanLoadMoreWcAdapter = (state: AppState) =>
  state.wcAdapter?.canLoadMore;
export const selectWcAdapterDetail = (state: AppState) => state.wcAdapter?.wcAdapterDetail;
export const selectWcAdapterPagination = (state: AppState) =>
  state.wcAdapter?.wcAdapterPaginationConfig;
