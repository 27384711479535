import { Checkbox, CheckboxProps } from 'antd';
import clsx from 'clsx';
import { FC } from 'react';
import './styles.scss';
interface Props extends CheckboxProps {}

const BasicCheckbox: FC<Props> = ({ className, ...rest }) => {
  return <Checkbox {...rest} className={clsx('rakkar-checkbox', className)} />;
};

export default BasicCheckbox;
