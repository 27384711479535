import { UserInformation } from 'interfaces/adminQuorum';
import { countryData } from './assets';
import { Address } from 'interfaces/customers';

export const mapRequest = (params: any) => {
  // eslint-disable-next-line prefer-const
  let defaultParams = {
    ...params,
    offset: params?.page > 1 ? (params.page - 1) * params.limit : 0,
  };
  // TODO: Check params undefined or null then remove object
  Object.keys(defaultParams).forEach(key => {
    if (key === 'priceFrom' || key === 'feeFrom' || key === 'feeTo') {
      return;
    }
    if (
      (typeof defaultParams[key] !== 'boolean' && !defaultParams[key]) ||
      defaultParams[key] === null ||
      defaultParams[key] === undefined ||
      (Array.isArray(defaultParams[key]) && !defaultParams[key].length)
    ) {
      delete defaultParams[key];
    }
  });
  return defaultParams;
};

export const formatViewNumber = (num: number, digits = 2) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const getTextAddress = (values: any) => {
  if (!values) return '';
  const country = values['country'];
  const address = values['address'];
  const city = values['city'];
  const postCode = values['postCode'];

  const arrInfo = [address, city, country, postCode];
  const text = arrInfo.filter(item => item).join(', ');
  return text;
};

export const convertTextToObjectAddress = (values: string) => {
  const arrInfo = values.split(',');
  const addressFields = ['address', 'city', 'country', 'postCode'];
  const addressObj: Address = { address: '', city: '', country: '' };
  arrInfo.forEach((item: string, index: number) => {
    addressObj[addressFields[index]] = item;
  });
  return addressObj;
};

export const getDefaultValuePoint = (value: number) => {
  let point: number;
  if (value >= 0 && value <= 10 * 1e6) {
    point = 30;
  } else if (value <= 50 * 1e6) {
    point = 25;
  } else if (value <= 100 * 1e6) {
    point = 20;
  } else {
    point = 15;
  }
  return point;
};

// ------------

export const formatCurrencyTypeA = (
  value?: number,
  isSymbol = true,
  minimumFractionDigits = 2,
) => {
  if (!value) {
    return '0.00';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

/**

     Contain thousand separator

     Contain 2 digits after the decimal point

     Display up to 9 digits then round up 99.99M

     */

// format $ round two number after comma
export const formatCurrencyTypeB = (
  value?: number,
  isSymbol = true,
  minimumFractionDigits = 2,
) => {
  let oneMilion = 1e6;

  // let tenMilon = 1e7;

  const oneHundredMilion = 1e8;

  if (value && Math.abs(value) >= oneHundredMilion) {
    // 1e8 = 100e6
    return `${formatCurrencyTypeA(value / oneMilion)}M`;
  }
  return formatCurrencyTypeA(value);
};

// Asset value:

// Contain thousand separator

// Round and display up to 9 digits

export const formatAssetsListing = (value?: number) => {
  if (!value) {
    return '0.00';
  }

  // const maxDigits = getMaximumFractionDigits(value, 9);
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 9,
  });
  return formatter.format(value);
};

/**

     Contain thousand separator

     Round and display up to 13 digits

     */

export const formatAssetDetails = (value?: number) => {
  if (!value) return '0.00';
  let formatValue = value;
  let positiveValue = value && Math.abs(value);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const oneThousand = 1e3;
  const oneMilion = 1e6;
  const oneHundredMilion = 1e8;
  let milionSymbol = '';
  let maximumFractionDigits = 2;

  if (positiveValue >= oneHundredMilion) {
    formatValue = formatValue / oneMilion;
    milionSymbol = 'M';
  } else {
    maximumFractionDigits = getMaximumFractionDigits(value, 13);
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  });
  return `${formatter.format(formatValue)}${milionSymbol}`;
};

export const getMaximumFractionDigits = (value: number, maxLength: number) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [left, _] = String(value).split('.');
  const right = maxLength - left.length;
  return right;
};

export const mapFullName = (userInfo: UserInformation) => {
  const fullName = userInfo.middleName
    ? `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`
    : `${userInfo.firstName} ${userInfo.lastName}`;
  return fullName;
};

export const mapCountry = (code: string) => {
  const found = countryData.find(country => country.code === code);
  return found?.name ?? '';
};

export const capitalizeFirstLetter = (originalValue: string) => {
  const text = originalValue.toLocaleLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
