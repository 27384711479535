import { Button } from 'antd';
import clsx from 'clsx';
import { STATUS } from 'constants/index';
import styles from './index.module.scss';

interface Props {
  status: string;
}

const StatusButton = ({ status }: Props) => {
  function capitalizeFirstLetter(status: string) {
    const text = status.toLocaleLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return (
    <>
      <Button
        data-testid="status-button"
        className={clsx(styles.button, {
          [styles.button__active]: status === STATUS.ACTIVE,
          [styles.button__pending]: status === STATUS.PENDING,
        })}
      >
        {capitalizeFirstLetter(status)}
      </Button>
    </>
  );
};

export default StatusButton;
