import { Form } from 'antd';
import RButton from 'app/components/Button';
import BasicInput from 'app/components/common/BasicInput';
import BasicModal from 'app/components/common/BasicModal';
import BasicSelect from 'app/components/common/BasicSelect';
import { REGEX_ALLOW_AZ_09_SPACE } from 'constants/index';
import { AddressType } from 'enum/addNewCustomer';
import { Address } from 'interfaces/businessInformation';
import { useCallback, useEffect, useState } from 'react';
import { countryData } from 'utils/assets';
import { validateMessages, validateRegex } from 'utils/common';

interface Props {
  openModal: boolean;
  onCreate?: (values: any, keyForm: AddressType) => void;
  handleCancel: () => void;
  keyForm: AddressType;
  initValue?: Address;
}

export const ModalLocation = ({
  openModal,
  onCreate,
  handleCancel,
  keyForm,
  initValue,
}: Props) => {
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [form] = Form.useForm();

  const [disabledAdd, setDisabledAdd] = useState(true);

  const onValuesChange = useCallback((_, values: any) => {
    const isEmpty = checkModalIsEmpty(values);
    setDisabledAdd(isEmpty);
  }, []);

  const checkModalIsEmpty = (values: any) => {
    const { postCode, ...objectNeedCheck } = values;
    return Object.values(objectNeedCheck).some((value: any) => !value.trim());
  };

  useEffect(() => {
    if (initValue) {
      form.setFieldsValue(initValue);
      const isEmpty = checkModalIsEmpty(initValue);
      setDisabledAdd(isEmpty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  const handleChange = useCallback(value => {
    setSelectedCountry(value);
  }, []);

  const handleOk = () => {
    form.submit();
  };

  const handleFinish = values => {
    onCreate?.(values, keyForm);
  };

  const handleFilterOptions = useCallback(
    (input, option) =>
      (option!.children as unknown as string)
        .toLowerCase()
        .includes(input.toLowerCase()),
    [],
  );

  const contentModal = () => {
    return (
      <Form
        form={form}
        name="formAddLocation"
        layout="vertical"
        validateMessages={validateMessages()}
        onFinish={handleFinish}
        onValuesChange={onValuesChange}
        data-testid="form-add-location"
      >
        <BasicInput
          label="Address"
          rules={[
            { required: true },
            { max: 100 },
            {
              validator: (_, value) => {
                const valueTrim = value?.trim() || '';
                if (value?.length && !valueTrim.length) {
                  return Promise.reject('Address is required');
                }
                return Promise.resolve();
              },
            },
          ]}
          name="address"
          dataTestId="address"
        />

        <BasicInput
          label="City"
          name="city"
          rules={[
            { required: true },
            {
              max: 50,
            },
            {
              validator: (_, value) => {
                const valueTrim = value?.trim() || '';
                if (value?.length && !valueTrim.length) {
                  return Promise.reject('City is required');
                }
                return Promise.resolve();
              },
            },
          ]}
          dataTestId="city"
        />

        <BasicSelect
          options={countryData}
          onChange={handleChange}
          rules={[{ required: true }]}
          placeholder="Select..."
          label="Country"
          name="country"
          labelValue="name"
          keyValue="name"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={handleFilterOptions}
          dataTestId="country"
        />

        <BasicInput
          label="Postcode"
          rules={[
            {
              validator: (_, value) => {
                const checkValidValue = validateRegex(
                  value,
                  REGEX_ALLOW_AZ_09_SPACE,
                );
                if (value && value.length > 50) {
                  return Promise.reject('Max of 50 characters');
                }
                if (value && checkValidValue) {
                  return Promise.reject(
                    'Not allow to input special characters',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          name="postCode"
          dataTestId="postCode"
        />
      </Form>
    );
  };

  return (
    <BasicModal
      destroyOnClose={true}
      onCancel={handleCancel}
      closable={true}
      visible={openModal}
      title="Add Location"
      children={contentModal()}
      maskClosable={true}
      footer={[
        <RButton
          buttonType="cancel"
          content="Cancel"
          width={83}
          height={36}
          onClick={handleCancel}
          data-testid="cancel-button"
        />,
        <RButton
          data-testid="submit-button"
          htmlType="submit"
          buttonType="submit"
          content="Add"
          width={90}
          height={36}
          onClick={() => {
            handleOk();
          }}
          disable={disabledAdd}
        />,
      ]}
      dataTestId="modal-add-location"
    />
  );
};
