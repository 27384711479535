/**
 * Asynchronously loads the component for CustomerManagement
 */

import { lazyLoad } from 'utils/loadable';

export const AddCustomer = lazyLoad(
  () => import('./index'),
  module => module.AddCustomer,
);
