import { PayloadAction } from '@reduxjs/toolkit';
import ToastMessage from 'app/components/common/ToastMessage';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getListAssetStaking } from 'services/addNewCustomer';
import { createNewCustomer } from 'services/customers';
import { newCustomerActions } from 'store/slices/addNewCustomer';
import { handleShowMsgErr } from 'utils/common';
import { history } from 'utils/history';

const addNewCustomerRequest = function* ({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(createNewCustomer, payload);
    yield put(newCustomerActions.createNewCustomerSuccess(res));
    history.push({ pathname: '/customer-management' });
    toast(
      <ToastMessage
        type="info"
        message="Your request has been submitted for approval"
      />,
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(newCustomerActions.createNewCustomerFailed());
  }
};

const handleListStaking = function* ({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(getListAssetStaking);
    if (res) {
      const handleData = Object.assign(
        {},
        ...res.assetsStaking.map(item => ({ [item.symbol]: false })),
      );

      const newData = {
        assetsStaking: res.assetsStaking,
        updatedStaking: handleData,
      };
      yield put(newCustomerActions.getStakingListSuccess(newData));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
  }
};

const addNewCustomerSaga = function* () {
  yield takeLatest(
    newCustomerActions.createNewCustomerRequest.type,
    addNewCustomerRequest,
  );

  yield takeLatest(
    newCustomerActions.getStakingListRequest.type,
    handleListStaking,
  );
};

export default addNewCustomerSaga;
