import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { newCustomerReducer } from 'store/slices/addNewCustomer';
import { authReducer } from 'store/slices/auth';
import { commentReducer } from 'store/slices/comment';
import { commonReducer } from 'store/slices/common';
import { customerProfileReducer } from 'store/slices/customerProfile';
import { customersReducer } from 'store/slices/customers';
import { requestsReducer } from 'store/slices/request';
import { transactionReducer } from 'store/slices/transaction';
import { transactionsReducer } from 'store/slices/transactions';
import { billingsReducer } from 'store/slices/billings';
import { auditLogListReducer } from 'store/slices/auditLogList/auditLogList.slice';
import { accessControlListReducer } from 'store/slices/accessControl/accessControl.slice';
import { walletInfoListReducer } from 'store/slices/walletInfoList/walletInfoList.slice';
import { currencyReducer } from 'store/slices/currency/currency.slice';
import { currencyConvertReducer } from 'store/slices/currencyConvert/currencyConvert.slice';
import { snapshotTokenPriceReducer } from 'store/slices/snapshotTokenPrice/snapshotTokenPrice.slice';
import { wcWeb3ConnectReducer } from 'store/slices/wcWeb3Connect/wcWeb3Connect.slice';
import { wcAdapterReducer } from 'store/slices/wcAdapter/wcAdapter.slice';
import { wcAppAdapterReducer } from 'store/slices/wcAppAdapter/wcAppAdapter.slice';
import { wcAppEntityReducer } from 'store/slices/wcAppEntity/wcAppEntity.slice';
import { wcRequestWeb3ConnectReducer } from 'store/slices/wcRequestWeb3Connect/wcRequestWeb3Connect.slice';
import { wcApplicationReducer } from 'store/slices/wcApplication/wcApplication.slice';
import { vaultWcReducer } from 'store/slices/vaultWc/vaultWc.slice';
import { cusSubscribeReducer } from 'store/slices/cusSubscribe/cusSubscribe.slice';
import { baseRTKApi } from 'store/slices/baseRtkQuery';
import { editCustomerReducer } from 'store/slices/editCustomer';
import { cfg_sourceOfFundReducer } from 'store/slices/cfg_sourceOfFund/cfg_sourceOfFund.slice';
import { cfg_typeOfCompanyReducer } from 'store/slices/cfg_typeOfCompany/cfg_typeOfCompany.slice';
import { cfg_countryReducer } from 'store/slices/cfg_country/cfg_country.slice';

const rootReducer = combineReducers({
  auth: persistReducer(
    { key: 'auth', storage, whitelist: ['authInfo', 'isLoggedIn', 'profile'] },
    authReducer,
  ),
  common: commonReducer,
  customers: customersReducer,
  newCustomerInfo: newCustomerReducer,
  customerProfile: customerProfileReducer,
  requests: requestsReducer,
  transactions: transactionsReducer,
  transaction: transactionReducer,
  comment: commentReducer,
  billings: billingsReducer,
  auditLogList: auditLogListReducer,
  accessControl: accessControlListReducer,
  walletInfoList: walletInfoListReducer,
  currency: currencyReducer,
  currencyConvert: currencyConvertReducer,
  snapshotTokenPrice: snapshotTokenPriceReducer,
  wcWeb3Connect: wcWeb3ConnectReducer,
  wcAdapter: wcAdapterReducer,
  wcAppAdapter: wcAppAdapterReducer,
  wcAppEntity: wcAppEntityReducer,
  wcRequestWeb3Connect: wcRequestWeb3ConnectReducer,
  wcApplication: wcApplicationReducer,
  vaultWc: vaultWcReducer,
  cusSubscribe: cusSubscribeReducer,
  [baseRTKApi.reducerPath]: baseRTKApi.reducer,
  editCustomerInfo: editCustomerReducer,
  cfg_sourceOfFund: cfg_sourceOfFundReducer,
  cfg_typeOfCompany: cfg_typeOfCompanyReducer,
  cfg_country: cfg_countryReducer,
});

export default rootReducer;
