import AccessControlListing from 'app/components/AccessControl/AccessControlListing';
import InputSearch from 'app/components/common/InputSearch';
import accessControl from 'images/AccessControl.png';
import { useState } from 'react';
import styles from './index.module.scss';

const AccessControl = () => {
  const [keyword, setKeyword] = useState('');
  const onInputSearch = (keyword: any) => {
    setKeyword(keyword);
  };

  return (
    <>
      <div className={styles.title_container}>
        <img src={accessControl} alt="" width={28} height={28} />
        <div className={styles.title}>Access Control</div>
      </div>
      <InputSearch
        onInputChange={onInputSearch}
        className={styles.input_search}
      />
      <AccessControlListing keyword={keyword} />
    </>
  );
};

export default AccessControl;
