import { Badge, Checkbox, Popover, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RenderFunction } from 'antd/lib/tooltip';
import clsx from 'clsx';
import {
  ListActionNeedToCheckPermission,
  PAGE,
  TAB_REQUEST,
} from 'enum/common';
import { ReactNode, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectPermissions } from 'store/slices/auth';
import { selectTotalRequest } from 'store/slices/common';
import { checkPermissions, isHistoryPage } from 'utils/common';
import BasicButton from '../BasicButton';
import InputSearch from '../InputSearch';
import { SvgIcon } from '../SvgIcon';

import styles from './index.module.scss';
import { newCustomerActions } from 'store/slices/addNewCustomer';

type Props = {
  page?: PAGE;
  onInputSearch?: (keyword: string) => void;
  keyword?: string;
  onRefresh?: () => void;
  onAddCustomer?: () => void;

  isHasFilter?: boolean;
  isOpenPopover?: boolean;
  setIsOpenPopover?: (value: boolean) => void;
  filterContent?: ReactNode | RenderFunction;
  hasScrollFilter?: boolean;
  isDisableRefresh?: boolean;
  onDownload?: () => void;
  tabRequest?: TAB_REQUEST;
  isFilter?: boolean;
  isHasReviewRequired?: boolean;
  handleFilterReviewRequired?: (e: CheckboxChangeEvent) => void;
};

const RakkarHeaderFilter = ({
  page = PAGE.TRANSACTION,
  onRefresh,
  filterContent,
  isOpenPopover,
  setIsOpenPopover,
  onDownload,
  isHasFilter = false,
  hasScrollFilter,
  isDisableRefresh,
  tabRequest,
  isFilter = true,
  isHasReviewRequired = false,
  handleFilterReviewRequired,
  ...props
}: Props) => {
  const onInputSearch = (keyword: string) => {
    props?.onInputSearch && props?.onInputSearch(keyword);
  };
  const permissions = useAppSelector(selectPermissions);
  const allowAddCus = checkPermissions(
    ListActionNeedToCheckPermission.ADD_NEW_CUSTOMER,
    permissions,
  );
  const dispatch = useAppDispatch();
  const billingHistoryPage = page === PAGE.BILLING ? isHistoryPage() : false;

  const total =
    useAppSelector(selectTotalRequest)?.data?.totalPendingRequest || 0;

  const handleShowReqAssignedToMe = () => {};

  const isShowAssigedToMe = useMemo(() => {
    return page === PAGE.PENDING_REQUEST && tabRequest === TAB_REQUEST.PENDING;
  }, [page, tabRequest]);

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <InputSearch
          onInputChange={onInputSearch}
          term={props.keyword}
          page={page}
          isSuportSearchDebounce={
            page === PAGE.CUSTOMER_MANAGEMENT ? false : true
          }
        />
      </div>
      <div className={styles.option}>
        {isShowAssigedToMe && (
          <div className={styles.attach} data-testid="requests-assigned-to-me">
            <div className={styles.checkbox}>
              <Checkbox onChange={handleShowReqAssignedToMe} />
            </div>
            <div className={styles.assigned}>
              Requests assigned to me
              <Badge count={total || 0} overflowCount={99}></Badge>
            </div>
          </div>
        )}
        {isHasReviewRequired && handleFilterReviewRequired ? (
          <div className={styles.review}>
            <Checkbox onChange={handleFilterReviewRequired}>
              Review required only
            </Checkbox>
          </div>
        ) : (
          <></>
        )}
        {isFilter && (
          <>
            <Popover
              placement="bottomRight"
              content={filterContent}
              trigger="click"
              overlayClassName={clsx(styles.filter__modal, {
                [styles.filter__scroll]: hasScrollFilter,
              })}
              overlayStyle={{ width: 400 }}
              open={isOpenPopover}
              onOpenChange={e => {
                if (setIsOpenPopover) setIsOpenPopover(e);
              }}
            >
              <Tooltip placement="bottom" title="Filter">
                <div
                  className={clsx(styles.filter, {
                    [styles.filter__active]: isOpenPopover || isHasFilter,
                  })}
                  style={{
                    padding: '12px 24px',
                  }}
                  data-testid="filter"
                >
                  <div className={styles.icon}>
                    <SvgIcon name="filter" width={16} height={16} />
                  </div>
                  <div
                    className={styles.title}
                    style={{
                      fontWeight: isOpenPopover || isHasFilter ? 'bold' : '',
                    }}
                  >
                    Filter
                  </div>
                </div>
              </Tooltip>
            </Popover>
            <div className={styles.line}></div>
          </>
        )}
        <Tooltip placement="bottom" title="Refresh">
          <div
            className={styles.refresh}
            onClick={() => (!isDisableRefresh ? onRefresh?.() : null)}
            data-testid="refresh"
          >
            <SvgIcon name="refresh" width={16} height={16} fill="#64697B" />
          </div>
        </Tooltip>
        {(page === PAGE.TRANSACTION ||
          (page === PAGE.BILLING && billingHistoryPage)) && (
          <Tooltip placement="bottomRight" title="Download">
            <div className={styles.download} onClick={() => onDownload?.()}>
              <SvgIcon name="download" width={16} height={16} fill="#64697B" />
            </div>
          </Tooltip>
        )}
        {page === PAGE.CUSTOMER_MANAGEMENT && allowAddCus && (
          <div className={styles.customer}>
            <BasicButton
              title="Add Customer"
              type="filled"
              icon={
                <SvgIcon
                  name="plus-icon"
                  width={12}
                  height={12}
                  style={{ marginBottom: '2px', marginRight: '10px' }}
                />
              }
              onClick={() => {
                props.onAddCustomer && props.onAddCustomer();
                dispatch(newCustomerActions.getStakingListRequest());
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RakkarHeaderFilter;
