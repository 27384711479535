import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetCurrencyConvertRequestParamDTO,
  GetCurrencyConvertResponseDTO,
  ICreateCurrencyConvert,
  ICurrencyConvertDetail,
} from 'interfaces/currencyConvert';
export function getCurrencyConvertRequest(
  params?: GetCurrencyConvertRequestParamDTO,
): Promise<GetCurrencyConvertResponseDTO> {
  return Instance.get(`/${PREFIX_API.CURRENCYCONVERT}`, {
    params,
  });
}
export function getDetailCurrencyConvert(
  currencyConvertId: string,
): Promise<ICurrencyConvertDetail> {
  return Instance.get(`/${PREFIX_API.CURRENCYCONVERT}/${currencyConvertId}`);
}
export function createCurrencyConvertRequest(
  currencyConvert: ICreateCurrencyConvert,
): Promise<GetCurrencyConvertResponseDTO> {
  return Instance.post(`/${PREFIX_API.CURRENCYCONVERT}`, currencyConvert);
}
export function updateCurrencyConvertRequest(
  currencyConvert: ICreateCurrencyConvert,
): Promise<GetCurrencyConvertResponseDTO> {
  const payload: any = { ...currencyConvert };
  const id = currencyConvert.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.CURRENCYCONVERT}/${id}`, payload);
}
