import { Input } from 'antd';
import BasicButton from 'app/components/common/BasicButton';
import { SvgIcon } from 'app/components/SvgIcon';
import { Params, ParamsUserList } from 'interfaces/request';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { customerProfileActions } from 'store/slices/customerProfile';
import styles from './index.module.scss';
import { ChangeEvent, useCallback, useState } from 'react';
import { SEARCH_DEBONCED } from 'constants/index';
import { useAppSelector } from 'store/hooks';
import { checkPermissions } from 'utils/common';
import { ListActionNeedToCheckPermission, SCREEN_USER } from 'enum/common';
import { selectPermissions } from 'store/slices/auth';
import PopupAddOrEditUser from 'app/components/common/PopupAddOrEditUser';

type Props = {
  customerId: string;
};

const HeaderTableUserList = (props: Props) => {
  const dispatch = useDispatch();
  const permissions = useAppSelector(selectPermissions);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const allowAddUser = checkPermissions(
    ListActionNeedToCheckPermission.ADD_NEW_USER,
    permissions,
  );

  const setValue = (key: keyof ParamsUserList) => {
    return (value: ParamsUserList[keyof ParamsUserList]) => {
      dispatch(
        customerProfileActions.setUserListParam({
          key,
          value,
        }),
      );
    };
  };

  const setOffset = setValue(Params.Offset);

  const handleSeach = (e: ChangeEvent<HTMLInputElement>) => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (tableContent) {
      tableContent.scrollTo(0, 0);
    }
    const keyword = e.target.value.trim();
    const setKeyword = setValue(Params.KeyWord);
    setKeyword(keyword);
    setOffset(0);
  };

  const onClickRefresh = useCallback(
    async () => {
      dispatch(customerProfileActions.resetUserListing());
      dispatch(
        customerProfileActions.forceScreenRequest({
          customerId: props.customerId,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <Input
          allowClear
          className={styles.search__search}
          prefix={<SvgIcon name="search-prefix" width={13} height={13} />}
          placeholder="Search..."
          data-testid="search-input"
          name="search"
          autoComplete="on"
          onChange={debounce(handleSeach, SEARCH_DEBONCED)}
        />
      </div>
      <div className={styles['btn-search']}>
        <BasicButton title="Search" type="outline" />
      </div>
      <div className={styles.refresh} onClick={onClickRefresh}>
        <div className={styles.circle__button}>
          <SvgIcon name="refresh" width={15.67} height={13} />
        </div>
      </div>
      <div className={styles['btn-add']}>
        {allowAddUser && (
          <BasicButton
            title="Add User"
            type="filled"
            icon={<SvgIcon width={11} height={11} name="plus-icon" />}
            onClick={() => {
              setIsOpen(true);
            }}
          />
        )}
        {isOpen && (
          <PopupAddOrEditUser
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            userEdit={null}
            customerId={props?.customerId}
            fromScreen={SCREEN_USER.ADD_USER}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderTableUserList;
