import { Form, FormItemProps, Input } from 'antd';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { CSSProperties, ReactNode } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

export interface IInputProps {
  label?: string | ReactNode;
  rules?: FormItemProps['rules'];
  name?: string | string[];
  colon?: boolean;
  useLabel?: boolean;
  placeholder?: string;
  value?: string;
  id?: string;
  onChange?: (e: any) => void;
  onInput?: (e: any) => void;
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  allowClear?: boolean;
  bordered?: boolean;
  defaultValue?: string;
  status?: 'error' | 'warning';
  maxLength?: number;
  disabled?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onPressEnter?: (e: any) => void;
  className?: string;
  type?: 'number' | 'textarea' | 'password';
  classNameFormItem?: string;
  initialValueForm?: string;
  tooltip?: LabelTooltipType;
  readOnly?: boolean;
  noStyle?: boolean;
  inputStyle?: CSSProperties;
  dependencies?: string[];
  validateFirst?: boolean;
  min?: string;
  max?: string;
  onClick?: (e: any) => void;
  dataTestId?: string;
  onKeyDown?: (e: any, name?: string) => void;
  autoComplete?: string;
  autoFocus?: boolean;
  isReadOnlyStyle?: boolean;
}
const BasicInput = (props: IInputProps) => {
  const isRequired = props.rules
    ? props.rules.filter((r: any) => r.required === true).length > 0
    : false;

  const isLabel = props.useLabel || true;
  return (
    <Form.Item
      label={isLabel ? props.label : ''}
      colon={props.colon || false}
      name={props.name}
      required={isRequired}
      rules={props.rules}
      className={`${styles.classNameFormItem} ${props.classNameFormItem}`}
      initialValue={props.initialValueForm}
      tooltip={props.tooltip}
      noStyle={props.noStyle}
      dependencies={props.dependencies}
      validateFirst={props.validateFirst}
    >
      <Input
        autoFocus={props.autoFocus}
        type={props.type}
        placeholder={props.placeholder}
        prefix={props.prefix}
        className={clsx(styles.input, props.className, {
          [styles.read__only]: props.isReadOnlyStyle && props.readOnly,
        })}
        value={props.value}
        id={props.id}
        addonAfter={props.addonAfter}
        addonBefore={props.addonBefore}
        allowClear={props.allowClear}
        bordered={props.bordered}
        defaultValue={props.defaultValue}
        status={props.status}
        maxLength={props.maxLength}
        disabled={props.disabled}
        suffix={props.suffix}
        onPressEnter={props.onPressEnter}
        onChange={props.onChange}
        size="middle"
        style={props.inputStyle}
        readOnly={props.readOnly}
        onClick={props.onClick}
        data-testid={props.dataTestId}
        onKeyDown={props.onKeyDown}
        onInput={props.onInput}
        autoComplete={props.autoComplete}
      />
    </Form.Item>
  );
};
export default BasicInput;
