import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortWcApplication,
  GroupByField,
  CustomerOnScreen,
} from 'enum/wcApplication.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetWcApplicationRequestParamDTO,
  GetWcApplicationResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IWcApplicationDetail,
  ICreateWcApplication,
} from 'interfaces/wcApplication';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface WcApplicationState {
  params: GetWcApplicationRequestParamDTO;
  wcApplication: ResponseData<GetWcApplicationResponseDTO>;
  wcApplicationPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  wcApplicationDetail: ResponseData<IWcApplicationDetail>;
}
export function getDefaultWcApplicationRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortWcApplication.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: WcApplicationState = {
  params: getDefaultWcApplicationRequestParams(),
  wcApplication: {
    status: RequestStatus.Idle,
  },
  wcApplicationPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ROLE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  wcApplicationDetail: {
    status: RequestStatus.Idle,
  },
};
const getWcApplicationRequest: CaseReducer<
  WcApplicationState,
  PayloadAction<any>
> = (state: WcApplicationState) => {
  state.wcApplication.status = RequestStatus.Loading;
};
const getWcApplicationSuccess: CaseReducer<
  WcApplicationState,
  PayloadAction<GetWcApplicationResponseDTO>
> = (
  state: WcApplicationState,
  { payload }: PayloadAction<GetWcApplicationResponseDTO>,
) => {
  state.wcApplication.status = RequestStatus.Success;
  state.wcApplication.data = payload;
};
const getWcApplicationFailed: CaseReducer<WcApplicationState> = (
  state: WcApplicationState,
) => {
  state.wcApplication.status = RequestStatus.Failed;
};
const getDetailWcApplicationRequest: CaseReducer<
  WcApplicationState,
  PayloadAction<string>
> = (state: WcApplicationState) => {
  state.wcApplicationDetail.status = RequestStatus.Loading;
};
const getDetailWcApplicationSuccess: CaseReducer<
  WcApplicationState,
  PayloadAction<IWcApplicationDetail>
> = (
  state: WcApplicationState,
  { payload }: PayloadAction<IWcApplicationDetail>,
) => {
  state.wcApplicationDetail.status = RequestStatus.Success;
  state.wcApplicationDetail.data = payload;
};
const getDetailWcApplicationFailed: CaseReducer<WcApplicationState> = (
  state: WcApplicationState,
) => {
  state.wcApplicationDetail.status = RequestStatus.Failed;
};
const createWcApplicationRequest: CaseReducer<
  WcApplicationState,
  PayloadAction<Partial<ICreateWcApplication>>
> = (state: WcApplicationState) => {
  state.wcApplicationDetail.status = RequestStatus.Loading;
};
const createWcApplicationSuccess: CaseReducer<
  WcApplicationState,
  PayloadAction<IWcApplicationDetail>
> = (
  state: WcApplicationState,
  { payload }: PayloadAction<IWcApplicationDetail>,
) => {
  state.wcApplicationDetail.status = RequestStatus.Success;
  state.wcApplicationDetail.data = payload;
};
const createWcApplicationFailed: CaseReducer<WcApplicationState> = (
  state: WcApplicationState,
) => {
  state.wcApplicationDetail.status = RequestStatus.Failed;
};
const updateWcApplicationRequest: CaseReducer<
  WcApplicationState,
  PayloadAction<IWcApplicationDetail>
> = (state: WcApplicationState) => {
  state.wcApplicationDetail.status = RequestStatus.Loading;
};
const updateWcApplicationSuccess: CaseReducer<
  WcApplicationState,
  PayloadAction<IWcApplicationDetail>
> = (
  state: WcApplicationState,
  { payload }: PayloadAction<IWcApplicationDetail>,
) => {
  state.wcApplicationDetail.status = RequestStatus.Success;
  state.wcApplicationDetail.data = payload;
};
const updateWcApplicationFailed: CaseReducer<WcApplicationState> = (
  state: WcApplicationState,
) => {
  state.wcApplicationDetail.status = RequestStatus.Failed;
};
const appendWcApplication: CaseReducer<
  WcApplicationState,
  PayloadAction<any>
> = (state: WcApplicationState, { payload }: PayloadAction<any>) => {
  if (state.wcApplication?.data?.list) {
    state.wcApplication.status = RequestStatus.Success;
    state.wcApplication.data.list = [...payload];
  }
};
const setCanLoadMore = (
  state: WcApplicationState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetWcApplication: CaseReducer<WcApplicationState> = (
  state: WcApplicationState,
) => {
  state.wcApplication = {
    status: RequestStatus.Idle,
  };
};
const resetWcApplicationParams: CaseReducer<WcApplicationState> = (
  state: WcApplicationState,
) => {
  state.params = getDefaultWcApplicationRequestParams();
};
const setWcApplicationFilterParams = (
  state: WcApplicationState,
  { payload }: PayloadAction<GetWcApplicationRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  WcApplicationState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: WcApplicationState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<
  WcApplicationState,
  PayloadAction<any>
> = (state: WcApplicationState, { payload }: PayloadAction<any>) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<WcApplicationState> = (
  state: WcApplicationState,
) => {
  state.action.status = RequestStatus.Failed;
};
const getWcApplicationPaginationConfigRequest: CaseReducer<
  WcApplicationState,
  PayloadAction<any>
> = (state: WcApplicationState) => {
  state.wcApplicationPaginationConfig.status = RequestStatus.Loading;
};
const getWcApplicationPaginationConfigSuccess: CaseReducer<
  WcApplicationState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: WcApplicationState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.wcApplicationPaginationConfig.status = RequestStatus.Success;
  state.wcApplicationPaginationConfig.data = payload;
};
const getWcApplicationPaginationConfigFailed: CaseReducer<
  WcApplicationState
> = (state: WcApplicationState) => {
  state.wcApplicationPaginationConfig.status = RequestStatus.Failed;
};
const wcApplicationSlice = createSlice({
  name: 'wcApplication',
  initialState,
  reducers: {
    getWcApplicationRequest,
    getWcApplicationSuccess,
    getWcApplicationFailed,
    getDetailWcApplicationRequest,
    getDetailWcApplicationSuccess,
    getDetailWcApplicationFailed,
    createWcApplicationRequest,
    createWcApplicationSuccess,
    createWcApplicationFailed,
    updateWcApplicationRequest,
    updateWcApplicationSuccess,
    updateWcApplicationFailed,
    appendWcApplication,
    setCanLoadMore,
    resetWcApplication,
    setWcApplicationParams<K extends keyof GetWcApplicationRequestParamDTO>(
      state: WcApplicationState,
      action: PayloadAction<{
        key: K;
        value: GetWcApplicationRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setWcApplicationFilterParams,
    resetWcApplicationParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getWcApplicationPaginationConfigRequest,
    getWcApplicationPaginationConfigSuccess,
    getWcApplicationPaginationConfigFailed,
  },
});
// Actions
export const wcApplicationActions = wcApplicationSlice.actions;
// Reducer
export const wcApplicationReducer = wcApplicationSlice.reducer;

// Selectors
export const selectWcApplication = (state: AppState) =>
  state.wcApplication?.wcApplication;
export const selectParamWcApplication = (state: AppState) =>
  state.wcApplication?.params;
export const selectCanLoadMoreWcApplication = (state: AppState) =>
  state.wcApplication?.canLoadMore;
export const selectWcApplicationDetail = (state: AppState) =>
  state.wcApplication?.wcApplicationDetail;
export const selectWcApplicationPagination = (state: AppState) =>
  state.wcApplication?.wcApplicationPaginationConfig;
