import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';

interface Props {}

const Logout = (props: Props) => {
  const { instance } = useMsal();
  useEffect(() => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/login',
    });
  }, []);
  return <div></div>;
};

export default Logout;
