import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getCusSubscribeRequest,
  getDetailCusSubscribe,
  createCusSubscribeRequest,
  updateCusSubscribeRequest,
  getCusSubscribeRequestPaginationConfig,
} from 'services/cusSubscribe.service';
import { cusSubscribeActions } from 'store/slices/cusSubscribe/cusSubscribe.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetCusSubscribeRequestParamDTO,
  ICreateCusSubscribe,
} from 'interfaces/cusSubscribe';
const handleGetCusSubscribeRequest = function* ({
  payload,
}: PayloadAction<GetCusSubscribeRequestParamDTO>) {
  try {
    const res = yield call(getCusSubscribeRequest, payload);
    const params = yield select(state => state.cusSubscribe.params) as any;
    const listCusSubscribe = yield select(
      state => state.cusSubscribe?.cusSubscribe?.data?.listCusSubscribe,
    ) as any;
    if (res) {
      yield put(
        cusSubscribeActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listCusSubscribe || []), ...res.list];
        yield put(cusSubscribeActions.appendCusSubscribe(newList));
      } else {
        yield put(
          cusSubscribeActions.getCusSubscribeSuccess({
            listCusSubscribe: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cusSubscribeActions.getCusSubscribeFailed());
  }
};
const handleGetDetailCusSubscribeRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailCusSubscribe, payload);
    if (res) {
      yield put(cusSubscribeActions.getDetailCusSubscribeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cusSubscribeActions.getDetailCusSubscribeFailed());
  }
};
const handleCreateCusSubscribeRequest = function* ({
  payload,
}: PayloadAction<ICreateCusSubscribe>) {
  try {
    const res = yield call(createCusSubscribeRequest, payload);
    if (res) {
      yield put(cusSubscribeActions.createCusSubscribeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cusSubscribeActions.createCusSubscribeFailed());
  }
};
const handleUpdateCusSubscribeRequest = function* ({
  payload,
}: PayloadAction<ICreateCusSubscribe>) {
  try {
    const res = yield call(updateCusSubscribeRequest, payload);
    if (res) {
      yield put(cusSubscribeActions.updateCusSubscribeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cusSubscribeActions.updateCusSubscribeFailed());
  }
};
const handleGetCusSubscribePaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetCusSubscribeRequestParamDTO>) {
  try {
    const res = yield call(getCusSubscribeRequestPaginationConfig);
    if (res) {
      yield put(
        cusSubscribeActions.getCusSubscribePaginationConfigSuccess(res),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cusSubscribeActions.getCusSubscribePaginationConfigFailed());
  }
};
const cusSubscribeSaga = function* () {
  yield takeLatest(
    cusSubscribeActions.getCusSubscribeRequest.type,
    handleGetCusSubscribeRequest,
  );
  yield takeLatest(
    cusSubscribeActions.createCusSubscribeRequest.type,
    handleCreateCusSubscribeRequest,
  );
  yield takeLatest(
    cusSubscribeActions.updateCusSubscribeRequest.type,
    handleUpdateCusSubscribeRequest,
  );
  yield takeLatest(
    cusSubscribeActions.getCusSubscribePaginationConfigRequest.type,
    handleGetCusSubscribePaginationConfigRequest,
  );
  yield takeLatest(
    cusSubscribeActions.getDetailCusSubscribeRequest.type,
    handleGetDetailCusSubscribeRequest,
  );
};
export default cusSubscribeSaga;
