import Billing from 'app/pages/Billing';

const financeRoute = [
  { path: '/', element: <Billing /> },
  {
    path: '/billing',
    element: <Billing />,
  },
];

export default financeRoute;
