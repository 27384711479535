import AuthLayout from 'app/components/Layout/AuthLayout';
import ProtectedLayout from 'app/components/Layout/ProtectedLayout';
import WithoutSidebarLayout from 'app/components/Layout/WithoutSidebarLayout';
import NotFound from 'app/components/NotFound';
import NotPermision from 'app/components/NotPermision';
import { Login } from 'app/pages/Login/Loadable';
import Logout from 'app/pages/Logout';
import Redirect from 'app/pages/Redirect';
import { USER_ROLES } from 'enum/common';
import { useRoutes } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectRoles } from 'store/slices/auth';
import { checkRoles } from 'utils/common';

import adminRoute from './adminRoute';
import financeRoute from './financeRoute';
import userRoute from './userRoute';

import PermissionRoute from './PermissionRouter';
import withoutSidebarLayout from './withoutSidebarLayout';

export default function RouterList() {
  const roles = useAppSelector(selectRoles) ?? [];
  const isFinance = checkRoles(USER_ROLES.FINANCE, roles);

  const protectedLayoutChildren = [
    ...(isFinance ? financeRoute : adminRoute),
    ...userRoute,
  ];

  let element = useRoutes([
    // AuthLayoutcheck
    {
      path: '/redirect',
      element: <Redirect />,
    },
    {
      element: <AuthLayout />,
      children: [
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/logout',
          element: <Logout />,
        },
      ],
    },
    // ProtectedLayout
    {
      element: (
        <PermissionRoute>
          <ProtectedLayout />
        </PermissionRoute>
      ),
      children: protectedLayoutChildren,
    },

    // WithoutSidebarLayout
    {
      element: <WithoutSidebarLayout />,
      children: withoutSidebarLayout,
    },

    {
      path: '/403',
      element: <NotPermision />,
    },
    // Not Found
    {
      path: '*',
      element: <NotFound />,
    },
  ]);
  return element;
}
