import { PayloadAction } from '@reduxjs/toolkit';
import ToastMessage from 'app/components/common/ToastMessage';
import { BusinessInformationInterface } from 'interfaces/businessInformation';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { editCustomer } from 'services/editCustomer';
import { editCustomerActions } from 'store/slices/editCustomer';
import { handleShowMsgErr } from 'utils/common';
import { history } from 'utils/history';

const editCustomerRequestSaga = function* ({
  payload,
}: PayloadAction<BusinessInformationInterface>) {
  try {
    const res = yield call(editCustomer, payload);
    yield put(editCustomerActions.editCustomerSuccess(res));
    history.push({ pathname: '/customer-management' });
    toast(
      <ToastMessage
        type="info"
        message="Your request has been submitted for approval"
      />,
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(editCustomerActions.editCustomerFailed());
  }
};

const editCustomerSaga = function* () {
  yield takeLatest(
    editCustomerActions.editCustomerRequest.type,
    editCustomerRequestSaga,
  );
};

export default editCustomerSaga;
