import { Button, Divider, Row, Spin } from 'antd';
import {
  BusinessTypeOther,
  latestRiskLevelOpts,
  purposeOfRelationshipOpts,
  sourceOfWealthOpts,
} from 'app/components/BusinessInformation/index.helper';
import {
  CompanyType,
  ContactPersonType,
  SourceOfFunds,
} from 'enum/addNewCustomer';
import { RequestStatus } from 'interfaces/request';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  commonActions,
  selectBusinessTypes,
  selectSourceOfFund,
  selectTypeOfCompany,
} from 'store/slices/common';
import {
  customerProfileActions,
  selectCustomerProfile,
} from 'store/slices/customerProfile';

import TextInfor from 'app/components/common/TextInfor';
import {
  checkPermissions,
  compareStrings,
  getNameFromArrayById,
} from 'utils/common';
import { formatDate } from 'utils/date';
import { getTextAddress, mapCountry } from 'utils/helpers';
import styles from './index.module.scss';
import { SvgIcon } from 'app/components/SvgIcon';
import { ListActionNeedToCheckPermission } from 'enum/common';
import { selectPermissions } from 'store/slices/auth';
import { history } from 'utils/history';
import clsx from 'clsx';

interface Props {
  id: string;
  isMaskedProfile?: boolean;
}

const TabGeneral = ({ id, isMaskedProfile }: Props) => {
  const dispatch = useAppDispatch();
  const bussiness = useAppSelector(selectBusinessTypes)?.data;
  const customerProfile = useAppSelector(selectCustomerProfile);
  const profile = useAppSelector(selectCustomerProfile)?.data;
  const permissions = useAppSelector(selectPermissions);
  const typeOfCompanyStore = useAppSelector(selectTypeOfCompany)?.data;
  const sourceOfFundStore = useAppSelector(selectSourceOfFund)?.data;

  const allowAddCus = checkPermissions(
    ListActionNeedToCheckPermission.ADD_NEW_CUSTOMER,
    permissions,
  );

  const otherContactInfo = useMemo(() => {
    const person = profile?.additionalContacts?.find(
      i => i.type === ContactPersonType.GENERAL,
    );
    return person;
  }, [profile?.additionalContacts]);

  const billingContactInfo = useMemo(() => {
    const person = profile?.additionalContacts?.find(
      i => i.type === ContactPersonType.BILLING,
    );
    return person;
  }, [profile?.additionalContacts]);

  useEffect(() => {
    dispatch(
      customerProfileActions.fetchCustomerByIdRequest({
        customerId: id,
        query: { includeAdditionalContacts: true },
      }),
    );
    dispatch(commonActions.getTypeOfCompanyRequest({}));
    dispatch(commonActions.getSourceOfFundRequest({}));
  }, [dispatch, id]);

  const businessType = useMemo(() => {
    const isOtherBusiness =
      profile?.businessTypeObject &&
      profile.businessTypeObject?.name &&
      profile.businessTypeObject.name === BusinessTypeOther.OTHER;
    if (isOtherBusiness && profile?.businessTypeOther) {
      return profile.businessTypeOther;
    }
    return (
      (!!profile && getNameFromArrayById(bussiness, profile.businessTypeId)) ||
      ''
    );
  }, [bussiness, profile]);

  const companyType = useMemo(() => {
    if (
      profile &&
      profile?.companyType === CompanyType.OTHER &&
      profile?.companyTypeOther
    ) {
      return profile.companyTypeOther;
    }

    return (
      (!!profile &&
        typeOfCompanyStore?.find(item => item.id === profile.companyType)
          ?.name) ||
      ''
    );
  }, [profile, typeOfCompanyStore]);

  const originOfFunds = useMemo(() => {
    return (!!profile && profile.originOfFunds) || '';
  }, [profile]);

  const sourceOfWealth = useMemo(() => {
    if (!!profile && profile.sourceOfWealthOther)
      return profile.sourceOfWealthOther;
    return (
      (!!profile &&
        sourceOfWealthOpts?.find(item => item.value === profile.sourceOfWealth)
          ?.label) ||
      ''
    );
  }, [profile]);

  const sourceOfFunds = useMemo(() => {
    if (!!profile && profile.sourceOfFunds) {
      const listSelected = profile.sourceOfFunds.split(',');
      const listSourceOfFunds: string[] = [];

      listSelected.forEach(element => {
        if (element === SourceOfFunds.OTHER && profile?.sourceOfFundOther) {
          listSourceOfFunds.push(profile?.sourceOfFundOther);
        } else {
          const source =
            sourceOfFundStore?.find(item => item.id === element)?.name || '';
          listSourceOfFunds.push(source);
        }
      });
      listSourceOfFunds.sort(compareStrings);
      return listSourceOfFunds.join(', ');
    }
  }, [profile]);

  const purposeOfRelationship = useMemo(() => {
    if (!!profile && profile.purposeOfRelationshipOther)
      return profile.purposeOfRelationshipOther;
    return (
      (!!profile &&
        purposeOfRelationshipOpts?.find(
          item => item.value === profile.purposeOfRelationship,
        )?.label) ||
      ''
    );
  }, [profile]);

  const lastestRiskLevel = useMemo(() => {
    return (
      (!!profile &&
        latestRiskLevelOpts?.find(
          item => item.value === profile.lastestRiskLevel,
        )?.label) ||
      ''
    );
  }, [profile]);

  const registrationAddress = useMemo(() => {
    if (!profile) return '';
    return getTextAddress(profile.registrationAddress);
  }, [profile]);

  const currentAddress = useMemo(() => {
    if (!profile) return '';
    return getTextAddress(profile.currentAddress);
  }, [profile]);

  const billingAddress = useMemo(() => {
    if (!profile) return '';
    return getTextAddress(profile.billingAddress);
  }, [profile]);

  if (!profile || customerProfile.status === RequestStatus.Loading) {
    return (
      <div className={styles.loading}>
        <Spin />
      </div>
    );
  }
  return (
    <div className={styles.general}>
      <div className={styles.content}>
        {/* IDENTIFICATION INFORMATION */}
        <Row className={styles.content__identification}>
          Identification information
          {allowAddCus && (
            <Button
              className={clsx(styles.button, {
                [styles.disabled]: profile.isPendingRequest,
              })}
              icon={
                <SvgIcon
                  style={{
                    marginRight: 10,
                    marginBottom: 2,
                  }}
                  width={15}
                  height={15}
                  name={profile.isPendingRequest ? 'grey-edit' : 'edit'}
                />
              }
              htmlType="button"
              onClick={() => {
                history.push(`/customer-management?id=${id}`);
              }}
              disabled={profile.isPendingRequest}
            >
              Edit
            </Button>
          )}
        </Row>
        <Row className={styles.body}>
          <TextInfor
            label="Business registered name"
            text={profile.customerName}
            dataTestId="customer-name"
          />
          <TextInfor
            label="Customer short name"
            text={profile.customerShortName}
            dataTestId="short-name"
          />
          <TextInfor
            label="Business registration number"
            text={profile.businessRegistrationId}
            dataTestId="registration-id"
          />
          <TextInfor label="Tax ID" text={profile.taxId} dataTestId="tax-id" />
          <TextInfor
            label="Rakkar ID"
            text={profile.rakkarId}
            dataTestId="rakkar-id"
          />
          <TextInfor
            label="Customer group"
            text={profile.groupName}
            dataTestId="rakkar-group-name"
          />
        </Row>

        {/* LEGAL INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Legal information
        </Divider>
        <Row className={styles.body}>
          <TextInfor
            label="Nature of business"
            text={businessType}
            dataTestId="business-type"
          />
          <TextInfor
            label="Business entity type"
            text={companyType}
            dataTestId="company-type"
          />
          <TextInfor
            label="Date of incorporation"
            text={formatDate(profile.registrationDate)}
            dataTestId="registration-date"
          />
        </Row>
        {/* LOCATION INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Location infomation
        </Divider>
        <Row className={styles.body}>
          <TextInfor
            span={24}
            label="Registered address"
            text={registrationAddress}
            dataTestId="registration-address"
          />
          <TextInfor
            span={24}
            label="Principle place of business"
            text={currentAddress}
            dataTestId="current-address"
          />
          <TextInfor
            span={24}
            label="Billing address"
            text={billingAddress}
            dataTestId="billing-address"
          />
        </Row>

        {/* OTHER INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Other information
        </Divider>
        <Row className={styles.body}>
          <TextInfor
            span={12}
            label="Intended purpose of the account"
            text={purposeOfRelationship}
            dataTestId="purpose-relationship"
          />
          <TextInfor
            span={12}
            label="Origin of funds"
            text={originOfFunds}
            dataTestId="original-of-funds"
          />
          <TextInfor
            span={12}
            label="Source of wealth"
            text={sourceOfWealth}
            dataTestId="source-of-wealth"
          />
          <TextInfor
            span={12}
            label="Source of funds"
            text={sourceOfFunds}
            dataTestId="source-of-funds"
          />
          <TextInfor
            span={12}
            label="Date of Latest risk assessment"
            text={formatDate(profile.dateOfLastestRiskLevel)}
            dataTestId="date-of-latest"
          />
          <TextInfor
            span={24}
            label="Latest risk level"
            text={lastestRiskLevel}
            dataTestId="risk-level"
          />
          <TextInfor
            span={12}
            label="Credit term"
            text={profile.creditTerm || ''}
            dataTestId="credit-term"
          />
          <TextInfor
            span={12}
            label="Rakkar entity"
            text={mapCountry(profile.entityRelation?.countryCode || '')}
            dataTestId="entity-relation-id"
          />
        </Row>

        {/* CONTACT INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Contact information
        </Divider>

        <Row className={styles.content__identification}>
          Main contact person
        </Row>
        <Row className={styles.body}>
          <TextInfor
            span={12}
            label="First name"
            text={profile.firstName}
            dataTestId="first-name"
            isMaskable={true}
            isMask={isMaskedProfile}
          />
          <TextInfor
            span={12}
            label="Last name"
            text={profile.lastName}
            dataTestId="last-name"
            isMaskable={true}
            isMask={isMaskedProfile}
          />
          <TextInfor
            span={12}
            label="Phone number"
            text={`${profile.countryCode} ${profile.phoneNumber}`}
            dataTestId="phone-number"
            isMaskable={true}
            isMask={isMaskedProfile}
          />
          <TextInfor
            span={12}
            label="Email"
            text={profile.email}
            dataTestId="email"
            isMaskable={true}
            isMask={isMaskedProfile}
          />
          <TextInfor
            span={12}
            label="Position"
            text={profile.position}
            dataTestId="position"
          />
        </Row>

        {/* CONTACT INFORMATION */}
        {otherContactInfo ? (
          <>
            <Row className={styles.content__identification}>
              Alternative contact person (optional)
            </Row>
            <Row className={styles.body}>
              <TextInfor
                span={12}
                label="First name"
                text={otherContactInfo.firstName}
                isMaskable={true}
                isMask={isMaskedProfile}
              />
              <TextInfor
                span={12}
                label="Last name"
                text={otherContactInfo.lastName}
                isMaskable={true}
                isMask={isMaskedProfile}
              />
              <TextInfor
                span={12}
                label="Phone number"
                text={`${otherContactInfo.countryCode} ${otherContactInfo.phoneNumber}`}
                isMaskable={true}
                isMask={isMaskedProfile}
              />
              <TextInfor
                span={12}
                label="Email"
                text={otherContactInfo.email}
                isMaskable={true}
                isMask={isMaskedProfile}
              />
              <TextInfor
                span={12}
                label="Position"
                text={otherContactInfo.position}
              />
            </Row>
          </>
        ) : null}

        {/* CONTACT INFORMATION */}
        {billingContactInfo ? (
          <>
            <Divider
              orientationMargin="0"
              className={styles.content__header}
              orientation="left"
              data-testid="other-contact-info"
            >
              Billing contact person
            </Divider>
            <Row className={styles.body}>
              <TextInfor
                span={12}
                label="First name"
                text={billingContactInfo.firstName}
                isMaskable={true}
                isMask={isMaskedProfile}
              />
              <TextInfor
                span={12}
                label="Last name"
                text={billingContactInfo.lastName}
                isMaskable={true}
                isMask={isMaskedProfile}
              />
              <TextInfor
                span={12}
                label="Phone number"
                text={`${billingContactInfo.countryCode} ${billingContactInfo.phoneNumber}`}
                isMaskable={true}
                isMask={isMaskedProfile}
              />
              <TextInfor
                span={12}
                label="Email"
                text={billingContactInfo.email}
                isMaskable={true}
                isMask={isMaskedProfile}
              />
            </Row>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TabGeneral;
