import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';

import {
  GetWalletInfoListRequestParamDTO,
  GetWalletInfoListResponseDTO,
  ICreateWalletInfo,
  IWalletInfoDetail,
} from 'interfaces/walletInfoList.interface';

export function getWalletInfoListRequest(
  params?: GetWalletInfoListRequestParamDTO,
): Promise<GetWalletInfoListResponseDTO> {
  return Instance.get(`/${PREFIX_API.WALLET_INFO}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}

export function getDetailWalletInfo(
  walletInfoId: string,
): Promise<IWalletInfoDetail> {
  return Instance.get(`/${PREFIX_API.WALLET_INFO}/${walletInfoId}`);
}

export function createWalletInfoRequest(
  walletInfo: ICreateWalletInfo,
): Promise<GetWalletInfoListResponseDTO> {
  return Instance.post(`/${PREFIX_API.WALLET_INFO}`, walletInfo);
}

export function updateWalletInfoRequest(
  walletInfo: ICreateWalletInfo,
): Promise<GetWalletInfoListResponseDTO> {
  const payload: any = { ...walletInfo };
  const id = walletInfo.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.WALLET_INFO}/${id}`, payload);
}
