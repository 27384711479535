import { LoadingOutlined } from '@ant-design/icons';
import { Drawer, Row } from 'antd';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { FORMAT_DATE } from 'constants/index';
import { IAuditLogDetail } from 'interfaces/auditLogList.interface';
import { RequestStatus } from 'interfaces/request';
import { capitalize, startCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  auditLogListActions,
  selectAuditLogDetail,
} from 'store/slices/auditLogList/auditLogList.slice';
import styled from 'styled-components/macro';
import { getTextAddress } from 'utils/helpers';
import { dots } from '../common/TextInfor';
import styles from './AuditLogDetail.module.scss';

export const AuditLogDetailChildren = ({ item, isMaskAuditLog }) => {
  const {
    actor,
    customer,
    context,
    status,
    userDevice,
    createdAt,
    platform,
    requestAction,
    requestCategory,
    requestType,
    description,
  } = item || {};

  return (
    <div>
      <Section>
        <div className="title">Customer detail</div>
        <div className="line"></div>
      </Section>
      <SectionDataContainer>
        <SectionName isName>
          <div className="title">Customer</div>
          <div className="label" data-testid="customer-name">
            {customer?.customerName}
          </div>
        </SectionName>
        <SectionName isName>
          <div className="title">Username</div>
          {isMaskAuditLog ? (
            dots()
          ) : (
            <div className="label" data-testid="name">
              {actor?.name}
            </div>
          )}
        </SectionName>
      </SectionDataContainer>
      <SectionDataContainer>
        <SectionName>
          <div className="title">Platform</div>
          <div className="label" data-testid="platform">
            {platform}
          </div>
        </SectionName>
        <SectionName>
          <div className="title">User device</div>
          <div className="label" data-testid="device">
            {userDevice}
          </div>
        </SectionName>
      </SectionDataContainer>
      <SectionDataContainer>
        <SectionName>
          <div className="title">IP address</div>
          {isMaskAuditLog ? (
            dots()
          ) : (
            <div className="label" data-testid="ip-address">
              {context?.ipAddress}
            </div>
          )}
        </SectionName>
      </SectionDataContainer>
      <Section>
        <div className="title">Log detail</div>
        <div className="line"></div>
      </Section>
      <SectionDataContainer>
        <SectionName>
          <div className="title">Category</div>
          <div className="label" data-testid="category">
            {requestCategory}
          </div>
        </SectionName>
        <SectionName>
          <div className="title">Type</div>
          <div className="label" data-testid="type">
            {requestType}
          </div>
        </SectionName>
      </SectionDataContainer>
      <SectionDataContainer>
        <SectionName>
          <div className="title">Action</div>
          <div className="label" data-testid="action">
            {requestAction}
          </div>
        </SectionName>
        <SectionName>
          <div className="title">Status</div>
          <div className="label" data-testid="status">
            {capitalize(status)}
          </div>
        </SectionName>
      </SectionDataContainer>
      <SectionDataContainer>
        <SectionName>
          <div className="title">Activity date</div>
          <div className="label" data-testid="createAt">
            {moment(createdAt).format(FORMAT_DATE.DATE_DMY_hM)}
          </div>
        </SectionName>
        <SectionName>
          <div className="title">Description</div>
          <div className="label" data-testid="description">
            {description}
          </div>
        </SectionName>
      </SectionDataContainer>
    </div>
  );
};

const MetadataDetailChildren = ({ item, isMaskAuditLog }) => {
  return (
    <MetadataContainer isMaskAuditLog={isMaskAuditLog}>
      <Section>
        <div className="title">Metadata</div>
        <div className="line"></div>
      </Section>
      {item &&
        Object.keys(item).map(key => {
          let value;
          if (typeof item[key] === 'string' || typeof item[key] === 'number') {
            value = item[key];
          } else {
            value =
              key === 'address'
                ? getTextAddress(item[key])
                : JSON.stringify(item[key]);
          }
          return (
            <SectionDataContainer key={key}>
              <SectionName>
                <div className="title">{startCase(key)}</div>
                <div className="label">{value}</div>
              </SectionName>
            </SectionDataContainer>
          );
        })}
    </MetadataContainer>
  );
};

const getItems = (item?: IAuditLogDetail, isMaskAuditLog?: boolean) => [
  {
    key: '1',
    label: `Audit log detail`,
    children: (
      <AuditLogDetailChildren item={item} isMaskAuditLog={isMaskAuditLog} />
    ),
  },
  {
    key: '2',
    label: `Metadata`,
    children: (
      <MetadataDetailChildren
        item={item?.metaData}
        isMaskAuditLog={isMaskAuditLog}
      />
    ),
  },
];

const TabBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 28px;
`;

const TabBar = styled.div<{ isActive: boolean }>`
  width: 292px;
  height: 36px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: ${({ isActive }) => (isActive ? '#25397C' : '#2C303C')};
  background-color: ${({ isActive }) => (isActive ? '#E5EDFC' : '#FFFFFF')};

  border: 1px solid ${({ isActive }) => (isActive ? '#CEDDFB' : '#D5D7DF')};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;

  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 6px 8px;

  .LogID {
    font-weight: 600;
    font-size: 12px;
    color: #959aaa;
  }

  .icon {
    cursor: pointer;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 20px;

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #2c303c;
  }

  .line {
    height: 1px;
    flex: 1;
    background: #ebecf0;

    margin-left: 20px;
  }
`;

const MetadataContainer = styled.div<{ isMaskAuditLog: boolean }>`
  filter: ${({ isMaskAuditLog }) => (isMaskAuditLog ? 'blur(6px)' : 'none')};
`;

const SectionDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
`;

const SectionName = styled.div<{ isName?: boolean }>`
  flex: 1;

  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #64697b;

    margin-bottom: 6px;
  }

  .label {
    font-weight: ${({ isName }) => (isName ? 600 : 400)};
    font-size: 14px;
    line-height: 20px;

    color: ${({ isName }) => (isName ? '#2E90FA' : '#2C303C')};
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  itemSelected: any;
}

const AuditLogDetail: React.FunctionComponent<Props> = ({
  open,
  onClose,
  itemSelected,
}) => {
  const { id } = itemSelected || {};
  const [mode, setMode] = useState(0);
  const dispatch = useAppDispatch();
  const auditlogDetail = useAppSelector(selectAuditLogDetail);
  const [isMaskAuditLog, setIsMaskAuditLog] = useState(true);

  const isLoadingDetail = useMemo(() => {
    return auditlogDetail.status === RequestStatus.Loading;
  }, [auditlogDetail.status]);

  useEffect(() => {
    if (!open) {
      setIsMaskAuditLog(true);
    }
  }, [open]);

  const items = getItems(auditlogDetail.data, isMaskAuditLog);

  useEffect(() => {
    dispatch(auditLogListActions.getDetailAuditLogRequest(id));
  }, [id]);

  useEffect(() => {
    setMode(0);
  }, [open]);

  const Spinner = (
    <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
  );

  const content =
    isLoadingDetail || !auditlogDetail.data ? (
      <div className={styles.loading}>{Spinner}</div>
    ) : (
      <>
        <TabBarContainer>
          {items.map(({ key, label }, index) => (
            <TabBar
              key={key}
              isActive={index === mode}
              onClick={() => setMode(index)}
            >
              {label}
            </TabBar>
          ))}
        </TabBarContainer>
        {items[mode].children}
      </>
    );
  return (
    <Drawer
      width="664px"
      placement="right"
      onClose={onClose}
      forceRender
      open={open}
      keyboard={false}
      title={
        <TitleContainer>
          <Row style={{ gap: 8, alignItems: 'center' }}>
            <div className="LogID" data-testid="log-ID">
              {`Log ID: ${id}`}
            </div>
            <SvgIcon
              name={isMaskAuditLog ? 'black-eye' : 'black-eye-off'}
              onClick={() => setIsMaskAuditLog(!isMaskAuditLog)}
              width={16}
              height={isMaskAuditLog ? 12 : 16}
              style={{ cursor: 'pointer', marginRight: '20px' }}
            />
          </Row>
          <div className="icon" onClick={onClose}>
            <SvgIcon name="close" width={16} height={16} />
          </div>
        </TitleContainer>
      }
      closable={false}
      bodyStyle={{ padding: '16px 32px 32px' }}
      // className={styles.drawerBilling}
    >
      {content}
    </Drawer>
  );
};

export default AuditLogDetail;
