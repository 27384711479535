import styled from 'styled-components';

const Styled = styled.div`
  div.ant-checkbox-group {
    display: flex;

    > label.ant-checkbox-wrapper {
      border: 0.5px solid #b5b6b7;
      border-radius: 24px;
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 14px;
      color: $color-black;
      padding: 4px 12px 6px 14px;
      height: 32px;

      > span:first-child {
        display: none;
      }
      > span:last-child {
        padding: 0;
      }
    }

    > label.ant-checkbox-wrapper-checked {
      color: #2e90fa;
      background: #d1e9ff;
      border: 0.5px solid #007aff;
      box-shadow: none;
    }
  }
`;

export default Styled;
