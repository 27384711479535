import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortCfg_country,
  GroupByField,
  CustomerOnScreen,
} from 'enum/cfg_country.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetCfg_countryRequestParamDTO,
  GetCfg_countryResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ICfg_countryDetail,
} from 'interfaces/cfg_country';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface Cfg_countryState {
  params: GetCfg_countryRequestParamDTO;
  cfg_country: ResponseData<GetCfg_countryResponseDTO>;
  cfg_countryPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  cfg_countryDetail: ResponseData<ICfg_countryDetail>;
}
export function getDefaultCfg_countryRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortCfg_country.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: Cfg_countryState = {
  params: getDefaultCfg_countryRequestParams(),
  cfg_country: {
    status: RequestStatus.Idle,
  },
  cfg_countryPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.CFG_COUNTRY,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  cfg_countryDetail: {
    status: RequestStatus.Idle,
  },
};
const getCfg_countryRequest: CaseReducer<
  Cfg_countryState,
  PayloadAction<any>
> = (state: Cfg_countryState) => {
  state.cfg_country.status = RequestStatus.Loading;
};
const getCfg_countrySuccess: CaseReducer<
  Cfg_countryState,
  PayloadAction<GetCfg_countryResponseDTO>
> = (
  state: Cfg_countryState,
  { payload }: PayloadAction<GetCfg_countryResponseDTO>,
) => {
  state.cfg_country.status = RequestStatus.Success;
  state.cfg_country.data = payload;
};
const getCfg_countryFailed: CaseReducer<Cfg_countryState> = (
  state: Cfg_countryState,
) => {
  state.cfg_country.status = RequestStatus.Failed;
};
const getDetailCfg_countryRequest: CaseReducer<
  Cfg_countryState,
  PayloadAction<string>
> = (state: Cfg_countryState) => {
  state.cfg_countryDetail.status = RequestStatus.Loading;
};
const getDetailCfg_countrySuccess: CaseReducer<
  Cfg_countryState,
  PayloadAction<ICfg_countryDetail>
> = (
  state: Cfg_countryState,
  { payload }: PayloadAction<ICfg_countryDetail>,
) => {
  state.cfg_countryDetail.status = RequestStatus.Success;
  state.cfg_countryDetail.data = payload;
};
const getDetailCfg_countryFailed: CaseReducer<Cfg_countryState> = (
  state: Cfg_countryState,
) => {
  state.cfg_countryDetail.status = RequestStatus.Failed;
};
const createCfg_countryRequest: CaseReducer<
  Cfg_countryState,
  PayloadAction<ICfg_countryDetail>
> = (state: Cfg_countryState) => {
  state.cfg_countryDetail.status = RequestStatus.Loading;
};
const createCfg_countrySuccess: CaseReducer<
  Cfg_countryState,
  PayloadAction<ICfg_countryDetail>
> = (
  state: Cfg_countryState,
  { payload }: PayloadAction<ICfg_countryDetail>,
) => {
  state.cfg_countryDetail.status = RequestStatus.Success;
  state.cfg_countryDetail.data = payload;
};
const createCfg_countryFailed: CaseReducer<Cfg_countryState> = (
  state: Cfg_countryState,
) => {
  state.cfg_countryDetail.status = RequestStatus.Failed;
};
const updateCfg_countryRequest: CaseReducer<
  Cfg_countryState,
  PayloadAction<ICfg_countryDetail>
> = (state: Cfg_countryState) => {
  state.cfg_countryDetail.status = RequestStatus.Loading;
};
const updateCfg_countrySuccess: CaseReducer<
  Cfg_countryState,
  PayloadAction<ICfg_countryDetail>
> = (
  state: Cfg_countryState,
  { payload }: PayloadAction<ICfg_countryDetail>,
) => {
  state.cfg_countryDetail.status = RequestStatus.Success;
  state.cfg_countryDetail.data = payload;
};
const updateCfg_countryFailed: CaseReducer<Cfg_countryState> = (
  state: Cfg_countryState,
) => {
  state.cfg_countryDetail.status = RequestStatus.Failed;
};
const appendCfg_country: CaseReducer<Cfg_countryState, PayloadAction<any>> = (
  state: Cfg_countryState,
  { payload }: PayloadAction<any>,
) => {
  if (state.cfg_country?.data?.listCfg_country) {
    state.cfg_country.status = RequestStatus.Success;
    state.cfg_country.data.listCfg_country = [...payload];
  }
};
const setCanLoadMore = (
  state: Cfg_countryState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetCfg_country: CaseReducer<Cfg_countryState> = (
  state: Cfg_countryState,
) => {
  state.cfg_country = {
    status: RequestStatus.Idle,
  };
};
const resetCfg_countryParams: CaseReducer<Cfg_countryState> = (
  state: Cfg_countryState,
) => {
  state.params = getDefaultCfg_countryRequestParams();
};
const setCfg_countryFilterParams = (
  state: Cfg_countryState,
  { payload }: PayloadAction<GetCfg_countryRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  Cfg_countryState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: Cfg_countryState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<Cfg_countryState, PayloadAction<any>> = (
  state: Cfg_countryState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<Cfg_countryState> = (
  state: Cfg_countryState,
) => {
  state.action.status = RequestStatus.Failed;
};
const getCfg_countryPaginationConfigRequest: CaseReducer<
  Cfg_countryState,
  PayloadAction<any>
> = (state: Cfg_countryState) => {
  state.cfg_countryPaginationConfig.status = RequestStatus.Loading;
};
const getCfg_countryPaginationConfigSuccess: CaseReducer<
  Cfg_countryState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: Cfg_countryState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.cfg_countryPaginationConfig.status = RequestStatus.Success;
  state.cfg_countryPaginationConfig.data = payload;
};
const getCfg_countryPaginationConfigFailed: CaseReducer<Cfg_countryState> = (
  state: Cfg_countryState,
) => {
  state.cfg_countryPaginationConfig.status = RequestStatus.Failed;
};
const cfg_countrySlice = createSlice({
  name: 'cfg_country',
  initialState,
  reducers: {
    getCfg_countryRequest,
    getCfg_countrySuccess,
    getCfg_countryFailed,
    getDetailCfg_countryRequest,
    getDetailCfg_countrySuccess,
    getDetailCfg_countryFailed,
    createCfg_countryRequest,
    createCfg_countrySuccess,
    createCfg_countryFailed,
    updateCfg_countryRequest,
    updateCfg_countrySuccess,
    updateCfg_countryFailed,
    appendCfg_country,
    setCanLoadMore,
    resetCfg_country,
    setCfg_countryParams<K extends keyof GetCfg_countryRequestParamDTO>(
      state: Cfg_countryState,
      action: PayloadAction<{
        key: K;
        value: GetCfg_countryRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setCfg_countryFilterParams,
    resetCfg_countryParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getCfg_countryPaginationConfigRequest,
    getCfg_countryPaginationConfigSuccess,
    getCfg_countryPaginationConfigFailed,
  },
});
// Actions
export const cfg_countryActions = cfg_countrySlice.actions;
// Reducer
export const cfg_countryReducer = cfg_countrySlice.reducer;
// Selectors
export const selectCfg_country = (state: AppState) =>
  state.cfg_country?.cfg_country;
export const selectParamCfg_country = (state: AppState) =>
  state.cfg_country?.params;
export const selectCanLoadMoreCfg_country = (state: AppState) =>
  state.cfg_country?.canLoadMore;
export const selectCfg_countryDetail = (state: AppState) =>
  state.cfg_country?.cfg_countryDetail;
export const selectCfg_countryPagination = (state: AppState) =>
  state.cfg_country?.cfg_countryPaginationConfig;
