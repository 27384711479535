import { PayloadAction } from '@reduxjs/toolkit';
import ToastMessage from 'app/components/common/ToastMessage';
import { GetCustomersRequest } from 'interfaces/customers';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getBillingDetail,
  getBillingsListing,
  markAsBillingPaid,
  markAsBillingReviewed,
  viewInvoicePdf,
} from 'services/billings';
import { getCustomersList } from 'services/customers';
import { billingsActions } from 'store/slices/billings';
import { handleShowMsgErr } from 'utils/common';

const handleGetBillingsListing = function* ({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(getBillingsListing, payload);
    const params = yield select(state => state.billings.params);

    const billings = yield select(state => state.billings?.billings);

    yield put(
      billingsActions.setCanLoadMore(
        params.offset + params.limit < res.totalCount,
      ),
    );

    if (params.offset > 0) {
      const newList: any = [
        ...billings?.data?.customerBillings,
        ...res.customerBillings,
      ];
      yield put(billingsActions.appendBillings(newList));
    } else {
      yield put(billingsActions.getBillingListSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(billingsActions.getBillingListFailed(error.message));
  }
};

const handleGetBillingDetail = function* ({ payload }: PayloadAction<any>) {
  try {
    if (payload) {
      const res = yield call(getBillingDetail, payload);
      yield put(billingsActions.getBillingDetailSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(billingsActions.getBillingDetailFailed(error.message));
  }
};

const handleMarkAsBillingReviewed = function* ({
  payload,
}: PayloadAction<any>) {
  try {
    if (payload) {
      const res = yield call(markAsBillingReviewed, payload);
      const currentParams = yield select(state => state.billings?.params);
      const newParams = { ...currentParams, offset: 0 };
      yield put(
        billingsActions.setBillingParam({
          key: 'offset',
          value: 0,
        }),
      );
      yield put(billingsActions.getBillingDetailRequest(payload));
      yield put(billingsActions.getBillingListRequest(newParams));

      toast(
        <ToastMessage type="info" message="Invoice is marked as reviewed." />,
      );
      yield put(billingsActions.markAsBillingReviewedSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(billingsActions.markAsBillingReviewedFailed(error.message));
  }
};

const handleMarkAsBillingPaid = function* ({ payload }: PayloadAction<any>) {
  try {
    if (payload) {
      const res = yield call(markAsBillingPaid, payload);
      const currentParams = yield select(state => state.billings?.params);
      const newParams = { ...currentParams, offset: 0 };
      yield put(
        billingsActions.setBillingParam({
          key: 'offset',
          value: 0,
        }),
      );
      yield put(billingsActions.getBillingDetailRequest(payload));
      yield put(billingsActions.getBillingListRequest(newParams));

      toast(
        <ToastMessage type="info" message="Invoice has been marked as paid." />,
      );
      yield put(billingsActions.markAsBillingPaidSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(billingsActions.markAsBillingPaidFailed(error.message));
  }
};

const handleFetchCustomersHasInvoiceRequest = function* ({
  payload,
}: PayloadAction<GetCustomersRequest>) {
  try {
    const { params } = payload;
    const res = yield call(getCustomersList, params);
    yield put(billingsActions.fetchCustomersSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(billingsActions.fetchCustomersFailed());
  }
};

const handleGetInvoicePdf = function* ({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(viewInvoicePdf, payload);
    yield put(billingsActions.fetchInvoicePdfSuccess(res));
    window.open(res);
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(billingsActions.fetchInvoicePdfFailed(error.message));
  }
};

const billingsSaga = function* () {
  yield takeLatest(
    billingsActions.getBillingListRequest.type,
    handleGetBillingsListing,
  );

  yield takeLatest(
    billingsActions.getBillingDetailRequest.type,
    handleGetBillingDetail,
  );

  yield takeLatest(
    billingsActions.markAsBillingReviewedRequest.type,
    handleMarkAsBillingReviewed,
  );
  yield takeLatest(
    billingsActions.fetchCustomersRequest.type,
    handleFetchCustomersHasInvoiceRequest,
  );
  yield takeLatest(
    billingsActions.fetchInvoicePdfRequest.type,
    handleGetInvoicePdf,
  );
  yield takeLatest(
    billingsActions.markAsBillingPaidRequest.type,
    handleMarkAsBillingPaid,
  );
};

export default billingsSaga;
