import { Form, FormInstance, Input, InputRef, Table, Tooltip } from 'antd';
import { ItemAUC } from 'interfaces/customers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  formatterNumberThousand,
  isNumberKey,
  revertFormat,
} from 'utils/common';
import { SvgIcon } from '../SvgIcon';
import styles from './index.module.scss';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  from?: number | string;
  to: number | string;
  point: number | string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item, dataIndex: any) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    } catch (errInfo) {}
  };

  const onChangeInput = (e: any, name: string) => {
    const value = formatterNumberThousand(e.target.value);

    form.setFieldsValue({ [name]: value });
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          ref={inputRef}
          placeholder={
            dataIndex === 'point' ? 'Annual basis points...' : 'Amount...'
          }
          onPressEnter={save}
          onChange={(e: any) => onChangeInput(e, dataIndex)}
          onKeyPress={(event: any) => {
            if (isNumberKey(event)) {
              const [integerPart, decimalPart] = event.target.value.split('.');
              if (decimalPart && decimalPart.length >= 2) {
                event.preventDefault();
              }
            } 
            
            if (
              !isNumberKey(event) &&
              event.key === '.' &&
              dataIndex === 'point'
            ) {
              return true;
            }

            if (!isNumberKey(event)) {
              event.preventDefault();
            }
            
            return true;
          }}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className={styles.textView}
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  from: number | string;
  to?: any;
  point: number | string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface Props {
  initValue: ItemAUC[] | any;
  onSave?: (value: any) => void;
  onTriggerValid?: (isHasError: boolean) => void;
  hideHeader?: boolean;
}

const AUC = ({
  initValue,
  onSave,
  onTriggerValid,
  hideHeader = false,
}: Props) => {
  const [showActionId, setShowActionId] = useState('');
  const [msgErr, setMsgErr] = useState<string>('');
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [count, setCount] = useState(4);

  const handleDelete = (key: React.Key) => {
    let newData;
    if (initValue.length === 1) {
      newData = [
        {
          key: '0',
          from: null,
          to: null,
          point: null,
        },
      ];
    } else {
      newData = initValue.filter(item => item.key !== key);
    }
    onSave?.(newData);
  };

  useEffect(() => {
    const msgErr = checkIsValidTable(initValue);
    setMsgErr(msgErr);
  }, [initValue]);

  useEffect(() => {
    onTriggerValid && onTriggerValid(!!msgErr || showInfo);
  }, [msgErr, onTriggerValid, showInfo]);

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: () => {
        return (
          <div>
            <span>From</span>
            <Tooltip
              overlayInnerStyle={{
                borderRadius: '8px',
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: 500,
                padding: '12px',
              }}
              title="Fees will incur on amounts less than or equal to ending tier value."
            >
              <SvgIcon
                name="info-outlined"
                width={16}
                height={16}
                className={styles.iconInfo}
              />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'from',
      width: '155px',
      editable: true,
    },
    {
      title: () => {
        return (
          <div>
            <span>To</span>
            <Tooltip
              overlayInnerStyle={{
                borderRadius: '8px',
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: 500,
                padding: '12px',
              }}
              title="Fees will incur on amounts greater than starting tier value."
            >
              <SvgIcon
                name="info-outlined"
                width={16}
                height={16}
                className={styles.iconInfo}
              />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'to',
      width: '155px',
      editable: true,
    },
    {
      title: 'Annual basis points',
      dataIndex: 'point',
      width: '158px',
      editable: true,
    },
    {
      title: '',
      dataIndex: '',
      width: '72.15px',
      render: (_, record: any) => {
        return record.key === showActionId ? (
          <SvgIcon
            className={styles.iconRemove}
            onClick={() => handleDelete(record.key)}
            name="remove"
            width={32}
            height={32}
          />
        ) : null;
      },
    },
  ];

  const handleAdd = () => {
    const from = initValue.length ? initValue[initValue.length - 1].to : 0;
    const newData: DataType = {
      key: count,
      from,
      to: null,
      point: 0,
    };
    onSave?.([...initValue, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType, dataIndex: any) => {
    let newData = [...initValue];
    const index = newData.findIndex(item => row.key === item.key);

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    let objNeedUpdate;
    // change field to
    if (
      newData.length !== 1 &&
      index < newData.length - 1 &&
      dataIndex === 'to'
    ) {
      newData[index + 1].from = row.to;
      objNeedUpdate = newData[index + 1];
      newData.splice(index + 1, 1, {
        ...row,
        ...objNeedUpdate,
      });
      onSave?.(newData);
    }

    // change field from
    if (newData.length !== 1 && index > 0 && dataIndex === 'from') {
      newData[index - 1].to = row.from;
      objNeedUpdate = newData[index - 1];
      newData.splice(index - 1, 1, {
        ...row,
        ...objNeedUpdate,
      });

      onSave?.(newData);
    }
    onSave?.(newData);
  };

  const checkIsValidTable = (array: ItemAUC[]) => {
    setShowInfo(false);
    if (array.length === 0) {
      return '';
    }
    let newAUCs: any;
    newAUCs = array.map(({ from, to, point }) => {
      return {
        from: revertFormat(from),
        to: to ? revertFormat(to) : '',
        point: point ? revertFormat(point) : '',
      };
    });

    const lengthAUCs = newAUCs.length;
    if (!newAUCs[lengthAUCs - 1].to) {
      newAUCs[lengthAUCs - 1].to = Number.MAX_VALUE;
    } else {
      setShowInfo(true);
      return '';
    }

    for (let i = 0; i < newAUCs.length; i++) {
      if (newAUCs[i].point === null || newAUCs[i].point === '') {
        return 'Annual basis points is required';
      }
      if (newAUCs[i].from >= newAUCs[i].to) {
        return 'You must input equal or higher value than the one in From';
      }

      if (i !== newAUCs.length - 1 && newAUCs[i].to !== newAUCs[i + 1].from) {
        return 'You must input higher value than the one in lower row';
      }
    }
    return '';
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className={styles.container}>
      {!hideHeader && (
        <>
          <div className={styles.header}>AUC tier setup</div>
          <div className={styles.description}>
            {/* Asset under custody (AUC) is setting tier which will be used for monthly
        fee calculation */}
          </div>
        </>
      )}

      <div className={styles.body}>
        <Table
          className={styles.tableAUC}
          onRow={(record: DataType) => {
            return {
              onMouseEnter: () => {
                setShowActionId(record.key as any);
              }, // mouse enter row
              onMouseLeave: () => {
                setShowActionId('');
              }, // mouse leave row
            };
          }}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={initValue}
          columns={columns as any}
          pagination={false}
        />
        {!!msgErr && <span className={styles.msgError}>{msgErr}</span>}
        {showInfo && (
          <span className={styles.msgError}>
            To value in the last tier must be left blank
          </span>
        )}
        <div className={styles.btnAdd} onClick={handleAdd}>
          <SvgIcon name="grey-plus-icon" width={16} height={16} />
          <span className={styles['btnAdd__text']}>Add tier</span>
        </div>
      </div>
    </div>
  );
};

export default AUC;
