import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import {
  commonAttributesColumnTable,
  PER_PAGE,
  timeZone,
} from 'constants/index';
import { PAGE } from 'enum/common';
import { Params, RequestStatus } from 'interfaces/request';

import { GetWalletInfoListRequestParamDTO } from 'interfaces/walletInfoList.interface';

import { useAppSelector } from 'store/hooks';
import {
  walletInfoListActions,
  selectWalletInfoList,
  selectCanLoadMoreWalletInfoList,
  selectParamWalletInfoList,
} from 'store/slices/walletInfoList/walletInfoList.slice';

import BasicIconSort from 'app/components/common/BasicIconSort';
import { SvgIcon } from 'app/components/common/SvgIcon';
import RTable from 'app/components/Table';
import WalletInfoDetail from './WalletInfoDetail';
import { Row, Tooltip } from 'antd';
import { SortWalletInfoList } from 'enum/walletInfoList.enum';
import styles from './WalletInfoList.module.scss';
import RButton from '../Button';

const StyledNoSearchResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;

  .Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #959aaa;

    margin: 24px 0 8px;
  }

  .SubTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #959aaa;
  }
`;

const NoSearchResults = () => (
  <StyledNoSearchResults>
    <div>
      <SvgIcon name={'search-empty'} width={80} height={80} fill="#25397C" />
    </div>
    <div className="Title">No search results</div>
    <div className="SubTitle">
      <div>Your search returns no matches,</div>
      <div>please check and try again.</div>
    </div>
  </StyledNoSearchResults>
);

interface Props {
  isFilterShown: boolean;
  isMaskWalletInfo: boolean;
}

const WalletInfoList: React.FunctionComponent<Props> = ({
  isFilterShown,
  isMaskWalletInfo,
}) => {
  const [selectedRowId, setSelectedRowId] = useState('');
  const [open, setOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<null | undefined | unknown>(
    null,
  );
  const [walletInfos, setWalletInfos] = useState<any[]>([]);
  const [firstRender, setFirstRender] = useState(true);

  const dispatch = useDispatch();

  const walletInfoList = useAppSelector(selectWalletInfoList);

  const params = useAppSelector(selectParamWalletInfoList);
  const canLoadMore = useAppSelector(selectCanLoadMoreWalletInfoList);

  const refCanLoadMoreStore = useRef(canLoadMore);

  const loading = walletInfoList?.status === RequestStatus.Loading;

  const height = useMemo(() => {
    const heightFilter = isFilterShown ? 242 : 36;
    const heightPadding = 120; // both top bottom
    return window.innerHeight - (32 * 2 + 24) - heightFilter - heightPadding; //count table height
  }, [isFilterShown]);

  const setValue = (key: keyof GetWalletInfoListRequestParamDTO) => {
    return (
      value: GetWalletInfoListRequestParamDTO[keyof GetWalletInfoListRequestParamDTO],
    ) => {
      dispatch(
        walletInfoListActions.setWalletInfoListParams({
          key,
          value,
        }),
      );
    };
  };

  const setSortStore = setValue(Params.Sort);
  const setSortByStore = setValue(Params.SortBy);
  const setOffset = setValue(Params.Offset);
  const setTimeZone = setValue(Params.TimeZone);

  if (-12 <= timeZone && timeZone <= 14) {
    setTimeZone(timeZone);
  }
  const sortByText = (sort: string) => {
    if (sort === 'ASC') return 'ascend';
    else if (sort === 'DESC') return 'descend';
    return '';
  };
  const renderIcon = (
    sortOrder: string,
    sortColumn: any,
    title: string,
    dataIndex: string,
    align = 'left' as string,
  ) => {
    return (
      <>
        <div
          className={clsx(styles.title, {
            [styles.titleHeaderRight]: align === 'right',
          })}
          data-testid={`column-${dataIndex}`}
          id={dataIndex}
        >
          <span>{title}</span>
          {params.sort && params.sortBy === dataIndex && (
            <BasicIconSort sortOrder={sortByText(params.sort)} />
          )}
        </div>
      </>
    );
  };
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    const sort =
      sorter.order === 'descend'
        ? 'DESC'
        : sorter.order === 'ascend'
        ? 'ASC'
        : undefined;
    const sortBy = sorter.field;

    setOffset(0);
    setSortStore(sort);
    setSortByStore(sortBy);

    if (sortBy && sort) {
      const orderOption = [{ sort: sortBy, order: sort }];
      setValue(Params.Order)(orderOption);
    }
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    return () => {
      if (!firstRender) {
        dispatch(walletInfoListActions.resetWalletInfoListParams());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  useEffect(() => {
    refCanLoadMoreStore.current = canLoadMore;
  }, [canLoadMore]);

  const loadMore = useCallback(
    event => {
      const maxScroll = event.target.scrollHeight - event.target.clientHeight;
      const currentScroll = (event.target.scrollTop + 2).toFixed();
      let offset = params?.offset || 0;
      if (maxScroll === 0 && offset > 0) {
        offset = 0;
      }
      if (
        refCanLoadMoreStore.current &&
        Number(currentScroll) > maxScroll &&
        walletInfos.length === offset + PER_PAGE
      ) {
        setOffset(offset + PER_PAGE);
      }
    },
    [walletInfos.length, params?.offset, setOffset],
  );

  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (!tableContent) return;

    tableContent.addEventListener('scroll', loadMore);
    return () => tableContent.removeEventListener('scroll', loadMore);
  }, [loadMore]);

  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (tableContent && params.offset === 0) {
      tableContent.scrollTop = 0;
    }
  }, [params.offset]);

  useEffect(() => {
    if (walletInfoList?.data?.listWalletInfo) {
      setWalletInfos(walletInfoList.data.listWalletInfo);
    }
  }, [walletInfoList]);

  useEffect(() => {
    const length = walletInfos.length || 0;
    const count = height / 60; //60 height 1 record
    let offset = Number(params?.offset) + PER_PAGE || PER_PAGE;
    const total = walletInfoList?.data?.total ?? 0;

    if (offset < count && offset < total && length === offset) {
      setOffset(offset);
    }
  }, [
    walletInfoList?.data?.total,
    walletInfos,
    height,
    params?.offset,
    setOffset,
  ]);

  const handleClickOnRow = (value: any) => {
    if (value) {
      setOpen(true);
      setItemSelected(value);
      setSelectedRowId(value.id);
    }
  };
  const NoResultComponent = useCallback(() => <NoSearchResults />, []);

  const columns: any = [
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'ID', 'id');
      },
      dataIndex: 'id',
      key: 'id',
      render: (_, item) => {
        return (
          <Tooltip title={item?.id} placement="bottomLeft">
            <div className={styles.id}>
              <span>{item?.id}</span>
            </div>
          </Tooltip>
        );
      },
      width: '172px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Name', 'name');
      },
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => {
        return (
          <Tooltip title={item?.name} placement="bottomLeft">
            <div className={styles.name}>
              <span>{item?.name}</span>
            </div>
          </Tooltip>
        );
      },
      width: '172px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Symbol', 'symbol');
      },
      dataIndex: 'symbol',
      key: 'symbol',
      render: (_, item) => {
        return (
          <Tooltip title={item?.symbol} placement="bottomLeft">
            <div className={styles.symbol}>
              <span>{item?.symbol}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Type', 'type');
      },
      dataIndex: 'type',
      key: 'type',
      render: (_, item) => {
        return (
          <Tooltip title={item?.type} placement="bottomLeft">
            <div className={styles.type}>
              <span>{item?.type}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'NativeAsset', 'nativeAsset');
      },
      dataIndex: 'nativeAsset',
      key: 'nativeAsset',
      render: (_, item) => {
        return (
          <Tooltip title={item?.nativeAsset} placement="bottomLeft">
            <div className={styles.nativeAsset}>
              <span>{item?.nativeAsset}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Decimals', 'decimals');
      },
      dataIndex: 'decimals',
      key: 'decimals',
      render: (_, item) => {
        return (
          <Tooltip title={item?.decimals} placement="bottomLeft">
            <div className={styles.decimals}>
              <span>{item?.decimals}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'TokenAddress',
          'tokenAddress',
        );
      },
      dataIndex: 'tokenAddress',
      key: 'tokenAddress',
      render: (_, item) => {
        return (
          <Tooltip title={item?.tokenAddress} placement="bottomLeft">
            <div className={styles.tokenAddress}>
              <span>{item?.tokenAddress}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Network', 'network');
      },
      dataIndex: 'network',
      key: 'network',
      render: (_, item) => {
        return (
          <Tooltip title={item?.network || 'null'} placement="bottomLeft">
            <div className={styles.network}>
              <span>{item?.network || 'null'}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Image', 'image');
      },
      dataIndex: 'image',
      key: 'image',
      render: (_, item) => {
        return (
          <Tooltip title={item?.image} placement="bottomLeft">
            <div className={styles.image}>
              <span>{item?.image || 'null'}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'NetworkImage',
          'networkImage',
        );
      },
      dataIndex: 'networkImage',
      key: 'networkImage',
      render: (_, item) => {
        return (
          <Tooltip title={item?.networkImage} placement="bottomLeft">
            <div className={styles.networkImage}>
              <span>{item?.networkImage || 'null'}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'BlockExplorerUrl',
          'blockExplorerUrl',
        );
      },
      dataIndex: 'blockExplorerUrl',
      key: 'blockExplorerUrl',
      render: (_, item) => {
        return (
          <Tooltip title={item?.blockExplorerUrl} placement="bottomLeft">
            <div className={styles.blockExplorerUrl}>
              <span>{item?.blockExplorerUrl || 'null'}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'BlockExplorerTxUrl',
          'blockExplorerTxUrl',
        );
      },
      dataIndex: 'blockExplorerTxUrl',
      key: 'blockExplorerTxUrl',
      render: (_, item) => {
        return (
          <Tooltip title={item?.blockExplorerTxUrl} placement="bottomLeft">
            <div className={styles.blockExplorerTxUrl}>
              <span>{item?.blockExplorerTxUrl || 'null'}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'MinimumAmount',
          'minimumAmount',
        );
      },
      dataIndex: 'minimumAmount',
      key: 'minimumAmount',
      render: (_, item) => {
        return (
          <Tooltip title={item?.minimumAmount} placement="bottomLeft">
            <div className={styles.minimumAmount}>
              <span>{item?.minimumAmount || 'null'}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'BlockExplorerTokenUrl',
          'blockExplorerTokenUrl',
        );
      },
      dataIndex: 'blockExplorerTokenUrl',
      key: 'blockExplorerTokenUrl',
      render: (_, item) => {
        return (
          <Tooltip title={item?.blockExplorerTokenUrl} placement="bottomLeft">
            <div className={styles.blockExplorerTokenUrl}>
              <span>{item?.blockExplorerTokenUrl || 'null'}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'status', 'status');
      },
      dataIndex: 'Status',
      key: 'status',
      render: (_, item) => {
        return (
          <Tooltip title={item?.status} placement="bottomLeft">
            <div className={styles.status}>
              <span>{item?.status || 'null'}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
  ];

  return (
    <div className={styles.tableAudit} data-testId="table">
      Total: {walletInfoList?.data?.total ?? 0}
      <Row style={{ justifyContent: 'end', marginBottom: '20px' }}>
        <RButton
          buttonType="submit"
          content="Insert"
          width={75}
          height={32}
          onClick={() => {
            setItemSelected(undefined);
            setOpen(true);
            dispatch(walletInfoListActions.getDetailWalletInfoSuccess({}));
          }}
          style={{ padding: '0' }}
        />
      </Row>
      <RTable
        currentTableData={walletInfos}
        columns={columns}
        loading={loading}
        height={height}
        selectedRowId={selectedRowId}
        clickOnRow={handleClickOnRow}
        page={PAGE.WALLET_INFO}
        onChange={onTableChange}
        NoSearchResults={NoResultComponent}
      />
      {itemSelected !== null && (
        <WalletInfoDetail
          open={open}
          onClose={() => {
            setOpen(false);
            setSelectedRowId('');
            dispatch(walletInfoListActions.getDetailWalletInfoSuccess({}));
          }}
          itemSelected={itemSelected}
        />
      )}
    </div>
  );
};

export default WalletInfoList;
