import { SvgIcon } from 'app/components/SvgIcon';
import GeneralInfoApprover from '../../ApprovalLog/GeneralInfoApprover';
import ItemGroup from '../Group';
import styles from './index.module.scss';

interface Props {
  members: any;
  quorumApprovals: number;
  index: number;
  isMaskedTransaction: boolean;
  isRequired?: boolean;
}

const Quorum = ({
  members,
  quorumApprovals,
  index,
  isMaskedTransaction,
  isRequired = false,
}: Props) => {
  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.header__name}>
          Quorum {index}: {quorumApprovals} of {members?.length}{' '}
          {members?.length > 1 ? 'approvals' : 'approval'}
        </div>
        {isRequired && (
          <div className={styles.required}>
            <SvgIcon name="shield" width={12} height={12} />
          </div>
        )}
      </div>
      <div className={styles.body}>
        {!!members &&
          members.map(item => {
            if (item.type === 'USER')
              return (
                <div className={styles.list} data-testid="user-list">
                  <GeneralInfoApprover
                    key={`user-${item.userId}`}
                    data={item}
                    isMaskedTransaction={isMaskedTransaction}
                    isAdvanced={true}
                  />
                </div>
              );
            else
              return (
                <div className={styles.items}>
                  <ItemGroup
                    quorum={item}
                    key={item.id}
                    isMask={isMaskedTransaction}
                  />
                </div>
              );
          })}
      </div>
    </div>
  );
};

export default Quorum;
