import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Spin } from 'antd';
import { USER_ROLES } from 'enum/common';
import { RequestStatus } from 'interfaces/request';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import {
  authActions,
  selectLoadingLogin,
  selectRoles,
} from 'store/slices/auth';
import styled from 'styled-components';
import { checkRoles } from 'utils/common';

type Props = {};

const Redirect = (props: Props) => {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Main = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const redirectHandler = async () => {
    const result = await instance.handleRedirectPromise();
    if (!result) {
      return dispatch(authActions.getAuthInfoFailed());
    }
    dispatch(authActions.getAuthInfoRequest(result.accessToken));
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      redirectHandler().catch(e => console.log(e));
    }
  }, [inProgress]);

  const tokenOfStore = useAppSelector(selectLoadingLogin);

  const roles = useAppSelector(selectRoles);

  const isFinance = roles && checkRoles(USER_ROLES.FINANCE, roles);
  const isProduct = roles && checkRoles(USER_ROLES.PRODUCT, roles);
  const isSecurity = roles && checkRoles(USER_ROLES.SECURITY, roles);
  const isReviewTransaction =
    roles && checkRoles(USER_ROLES.REVIEW_TRANSACTION, roles);

  if (!isFinance && tokenOfStore === RequestStatus.Success && roles) {
    navigate('/customer-management');
  }

  if (isProduct && tokenOfStore === RequestStatus.Success && roles) {
    navigate('/pending-request');
  }

  if (isSecurity && tokenOfStore === RequestStatus.Success && roles) {
    if (isReviewTransaction) {
      navigate('/transaction');
    } else {
      navigate('/audit-log');
    }
  }

  if (isFinance && tokenOfStore === RequestStatus.Success && roles) {
    navigate('/billing');
  }

  if (tokenOfStore === RequestStatus.Failed && roles) {
    navigate('/403');
  }

  return (
    <Main>
      <Spin />
    </Main>
  );
};

export default Redirect;
