import moment from 'moment';
import { FORMAT_DATE } from 'constants/index';

export const formatDate = (
  date: any,
  formatDate = FORMAT_DATE.DEFAULT,
  isUtc = false,
) => {
  if (isUtc) {
    return moment(date).utc().format(formatDate);
  }

  return moment(date).format(formatDate);
};

export const formatDateRange = (
  startDate: any,
  endDate?: any,
  format = FORMAT_DATE.DEFAULT,
  isUtc = false,
  noEndDateSubstitution = 'Since',
) => {
  if (endDate) {
    return `${formatDate(startDate, format, isUtc)} - ${formatDate(
      endDate,
      format,
      isUtc,
    )}`;
  }

  return `${noEndDateSubstitution} ${formatDate(startDate, format, isUtc)}`;
};
