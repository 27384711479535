import { Button, Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { RoleQuorum } from 'enum/addNewCustomer';
import { useCallback, useMemo, useState } from 'react';

import { SvgIcon } from 'app/components/SvgIcon';

import { documentTypeOpts } from 'app/components/BusinessInformation/index.helper';
import CustomerProfileTab from 'app/components/common/CustomerProfile';
import IdPassportInfo from 'app/components/common/IdOrPassportInfo';
import StatusUser from 'app/components/common/StatusUser';
import TextInfor from 'app/components/common/TextInfor';
import Vaults, { RenderVault } from 'app/components/common/Vaults';
import { FORMAT_DATE, STATUS_REQUEST } from 'constants/index';
import { DOCUMENT_TYPE, DOCUMENT_TYPE_VIEW } from 'enum/common';
import { orderBy } from 'lodash';
import moment from 'moment';
import { getCountryName } from 'utils/common';
import { getTextAddress } from 'utils/helpers';
import { renderStatus } from '../MarkedAsLostDevice';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
  isEditUser: boolean;
  isMask: boolean;
}

export const showUserInfo = (
  oldInfo: string | undefined,
  newInfo?: string,
  documentType?: boolean,
) => {
  if (!oldInfo) return '';
  if (
    newInfo === DOCUMENT_TYPE_VIEW.ID_CARD ||
    newInfo === DOCUMENT_TYPE_VIEW.PASSPORT
  ) {
    return (
      <div className={styles.fieldChanged}>
        <div className={styles['identity-document']}>
          <SvgIcon
            name={
              oldInfo === DOCUMENT_TYPE_VIEW.ID_CARD ? 'id_card' : 'passport'
            }
            width={16}
            height={16}
          />
          {oldInfo}
        </div>
        <div className={styles.icon}>
          <SvgIcon name="arrow-right" width={16} height={16} />
        </div>
        <div className={styles['identity-document']}>
          <SvgIcon
            name={
              newInfo === DOCUMENT_TYPE_VIEW.ID_CARD ? 'id_card' : 'passport'
            }
            width={16}
            height={16}
          />
          {newInfo}
        </div>
      </div>
    );
  }
  if (!newInfo && documentType) {
    return (
      <div className={styles['identity-document']}>
        <SvgIcon
          name={oldInfo === DOCUMENT_TYPE_VIEW.ID_CARD ? 'id_card' : 'passport'}
          width={16}
          height={16}
        />
        {oldInfo}
      </div>
    );
  }
  return !newInfo ? (
    <>{oldInfo}</>
  ) : (
    <div className={styles.fieldChanged}>
      <div className={styles.old}>{oldInfo}</div>
      <div className={styles.icon}>
        <SvgIcon name="arrow-right" width={16} height={16} />
      </div>
      <div className={styles.new}>{newInfo}</div>
    </div>
  );
};

const UserCreation = ({ requestDetail, isEditUser, isMask }: Props) => {
  const [open, setOpen] = useState(false);
  let vaultQuantity = requestDetail?.user?.vaults?.length;
  const userInfo = isEditUser ? requestDetail?.oldUser : requestDetail?.user;
  const newUser = isEditUser ? requestDetail?.newUser : [];

  const renderVaultEditUser = useCallback(() => {
    const vaultsAdded = requestDetail?.vaultsAdded || [];
    const vaultsRemoved = requestDetail?.vaultsRemoved || [];

    const vaultsAddedArranged = orderBy(
      vaultsAdded,
      v => {
        return v.name.toLowerCase();
      },
      ['asc'],
    );

    const vaultsRemovedArranged = orderBy(
      vaultsRemoved,
      v => {
        return v.name.toLowerCase();
      },
      ['asc'],
    );

    return (
      <>
        {vaultsAddedArranged.length ? (
          <>
            <Row>
              <div className={styles.title}>
                <div>New access</div>
                <div className={styles.title__line}></div>
              </div>
            </Row>
            {vaultsAddedArranged.map(vault => (
              <div key={vault.id}>{RenderVault(vault.name)}</div>
            ))}
          </>
        ) : (
          <></>
        )}

        {vaultsRemovedArranged?.length ? (
          <>
            <Row>
              <div className={styles.title}>
                <div>Remove access</div>
                <div className={styles.title__line}></div>
              </div>
            </Row>
            {vaultsRemovedArranged.map(vault => (
              <div key={vault.id}>{RenderVault(vault.name)}</div>
            ))}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }, [requestDetail]);

  const detailDocumentType = useMemo(() => {
    return (
      (!!requestDetail &&
        documentTypeOpts?.find(
          item => item.value === requestDetail?.user?.documentType,
        )?.label) ||
      ''
    );
  }, [requestDetail]);

  return (
    <div className={styles.content}>
      <SvgIcon
        name={isEditUser ? 'user_edit' : 'users_plus'}
        width={32}
        height={32}
      />
      <Row className={styles.reviewing__title}>
        {isEditUser ? 'Edit user profile request' : 'User creation request'}
        {(requestDetail?.status === STATUS_REQUEST.APPROVED ||
          requestDetail?.status === STATUS_REQUEST.REJECTED) &&
          renderStatus(requestDetail.status)}
      </Row>
      <Row>
        <TextInfor
          label="Customer name"
          text={
            <Button
              type="link"
              onClick={() => setOpen(true)}
              className={styles.customer__detail}
            >
              {requestDetail?.customer?.name ?? ''}
            </Button>
          }
          dataTestId="customer-name"
        />
        <TextInfor
          label="Business registration number"
          text={
            <Button
              type="link"
              onClick={() => setOpen(true)}
              className={styles.customer__detail}
            >
              {requestDetail?.customer?.businessRegistrationId ?? ''}
            </Button>
          }
          dataTestId="business-registration-number"
        />
      </Row>
      <div className={styles.user__info}>
        <Row style={{ alignItems: 'center', marginBottom: 20 }}>
          <SvgIcon name="menu-icon" width={12} height={8} />{' '}
          <span className={styles.content__header}>User information</span>
        </Row>
        <Row>
          <TextInfor
            span={12}
            label="Name"
            text={showUserInfo(userInfo?.name, newUser?.name)}
            dataTestId="user-name"
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            span={12}
            label="Role"
            dataTestId="role"
            text={
              <Row>
                <div
                  className={clsx(styles.role, {
                    [styles.role__viewer]: userInfo?.role === RoleQuorum.VIEWER,
                    [styles.role__member]: userInfo?.role === RoleQuorum.MEMBER,
                  })}
                >
                  {userInfo?.role}
                </div>
                {userInfo?.role === RoleQuorum.ADMIN &&
                  userInfo?.requiredApprover && (
                    <div className={styles.required__approver}>
                      <span className={styles.required__approver__text}>
                        REQUIRED APPROVER
                      </span>{' '}
                      <SvgIcon
                        width={10}
                        height={12}
                        name="required-approver"
                        className={styles.required__approver__icon}
                      />
                    </div>
                  )}
                {newUser?.role && (
                  <>
                    <div className={styles.icon_role}>
                      <SvgIcon name="arrow-right" width={16} height={16} />
                    </div>
                    <div
                      className={clsx(styles.role, {
                        [styles.role__viewer]:
                          newUser?.role === RoleQuorum.VIEWER,
                        [styles.role__member]:
                          newUser?.role === RoleQuorum.MEMBER,
                      })}
                    >
                      {newUser?.role}
                    </div>
                  </>
                )}
              </Row>
            }
          />
          {isEditUser && (
            <>
              <TextInfor
                span={12}
                label="Status"
                text={<Row>{StatusUser(userInfo?.status)}</Row>}
              />
              <Col span={24}>
                <div className={styles.title}>
                  <div>Legal information</div>
                  <div className={styles.title__line}></div>
                </div>
              </Col>
              <Col span={24}>
                <TextInfor
                  span={12}
                  label="Identity document"
                  text={showUserInfo(
                    DOCUMENT_TYPE_VIEW[userInfo?.documentType],
                    DOCUMENT_TYPE_VIEW[newUser?.documentType],
                    true,
                  )}
                />
              </Col>
            </>
          )}
          {!isEditUser && (
            <TextInfor
              label="Identity document"
              text={
                <div
                  data-testid="identity-document"
                  className={styles.passport_id}
                >
                  {requestDetail?.user?.documentType && (
                    <SvgIcon
                      name={
                        requestDetail?.user?.documentType ===
                        DOCUMENT_TYPE.PASSPORT
                          ? 'passport'
                          : 'id_card'
                      }
                      width={16}
                      height={16}
                      className={styles.passport_id__icon}
                    />
                  )}
                  {detailDocumentType ? detailDocumentType : '-'}
                </div>
              }
            />
          )}
          <Col span={24}>
            <IdPassportInfo
              requestDetail={requestDetail}
              isMask={isMask}
              isEditUser={isEditUser}
            />
          </Col>
          <TextInfor
            span={12}
            label="Date of birth"
            text={showUserInfo(
              moment(userInfo?.dateOfBirth).format(FORMAT_DATE.DEFAULT),
              newUser?.dateOfBirth
                ? moment(newUser?.dateOfBirth).format(FORMAT_DATE.DEFAULT)
                : undefined,
            )}
            dataTestId="date-of-birth"
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            span={12}
            label="Nationality"
            text={showUserInfo(
              getCountryName(userInfo?.nationality),
              getCountryName(newUser?.nationality),
            )}
          />

          {isEditUser && (
            <Col span={24}>
              <div className={styles.title}>
                <div>Contact information</div>
                <div className={styles.title__line}></div>
              </div>
            </Col>
          )}
          {!isEditUser && (
            <TextInfor
              span={24}
              label="Address"
              text={showUserInfo(
                getTextAddress(userInfo?.address),
                getTextAddress(newUser?.address),
              )}
              dataTestId="address"
              isMask={isMask}
              isMaskable={true}
            />
          )}
          <TextInfor
            span={12}
            label="Phone number"
            dataTestId="phone-number"
            text={showUserInfo(userInfo?.phoneNumber, newUser?.phoneNumber)}
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            span={12}
            label="Email"
            dataTestId="email"
            text={showUserInfo(userInfo?.email, newUser?.email)}
            isMask={isMask}
            isMaskable={true}
          />
          {isEditUser && (
            <TextInfor
              span={24}
              label="Address"
              text={showUserInfo(
                getTextAddress(userInfo.address),
                getTextAddress(newUser.address),
              )}
              isMask={isMask}
              isMaskable={true}
            />
          )}
        </Row>
        {isEditUser && (
          <>
            <Row>
              <div className={styles.title}>
                <div>Device information</div>
                <div className={styles.title__line}></div>
              </div>
            </Row>
            <Row>
              <TextInfor
                span={12}
                label="Device"
                text={userInfo?.device?.deviceName}
              />
              <TextInfor
                span={12}
                label="Device registered date"
                text={userInfo?.device?.registeredDate}
              />
            </Row>
          </>
        )}
        {!isEditUser && <Divider orientationMargin="0" />}
        <div className={styles.text__portion}>
          {isEditUser ? (
            <>
              <Row className={styles.sectionVaults}>
                {renderVaultEditUser()}
              </Row>
            </>
          ) : vaultQuantity ? (
            <>
              <Vaults requestDetail={requestDetail} />
            </>
          ) : (
            ''
          )}
        </div>
      </div>

      <CustomerProfileTab
        open={open}
        setOpen={setOpen}
        requestDetail={requestDetail}
      />
    </div>
  );
};

export default UserCreation;
