import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  getAuditLogListRequest,
  getListGroupByField,
  getListUserGroupByCustomer,
  getDetailAuditLog,
} from 'services/auditLogList.service';
import { auditLogListActions } from 'store/slices/auditLogList/auditLogList.slice';
import { handleShowMsgErr } from 'utils/common';

import {
  CategoryGroupByRequestParamsDTO,
  GetAuditLogListRequestParamDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
} from 'interfaces/auditLogList.interface';
import { getCustomersList } from 'services/customers';
import { mapRequest } from 'utils/helpers';

const handleGetAuditLogListRequest = function* ({
  payload,
}: PayloadAction<GetAuditLogListRequestParamDTO>) {
  try {
    const res = yield call(getAuditLogListRequest, payload);

    const params = yield select(state => state.auditLogList.params) as any;

    const listAuditLog = yield select(
      state => state.auditLogList?.auditLogList?.data?.listAuditLog,
    ) as any;

    if (res) {
      yield put(
        auditLogListActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );

      if (params.offset > 0) {
        const newList: any = [...listAuditLog, ...res.listAuditLog];
        yield put(auditLogListActions.appendAuditLogList(newList));
      } else {
        yield put(auditLogListActions.getAuditLogListSuccess(res));
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(auditLogListActions.getAuditLogListFailed());
  }
};

const handleGetCompanyListRequest = function* ({
  payload,
}: PayloadAction<GetCompanyListRequestParamsDTO>) {
  try {
    const { paramsCompany } = yield select(state => state.auditLogList);
    const res = yield call(getCustomersList, mapRequest(payload));

    if (paramsCompany.page > 1) {
      yield put(auditLogListActions.appendCompany(res.customers));
    } else {
      yield put(auditLogListActions.setCompany(res));
    }

    yield put(
      auditLogListActions.setCanLoadMoreCompany(
        paramsCompany.page * paramsCompany.limit < res.totalCount,
      ),
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(auditLogListActions.fetchCompanyFailed());
  }
};

const handleGetUsersListRequest = function* ({
  payload,
}: PayloadAction<GroupByUserRequestParamsDTO>) {
  try {
    const { paramsUsersByCompany } = yield select(state => state.auditLogList);

    const res = yield call(getListUserGroupByCustomer, mapRequest(payload));
    if (paramsUsersByCompany.page > 1) {
      yield put(auditLogListActions.appendUsers(res.customers));
    } else {
      yield put(auditLogListActions.setUsers(res));
    }

    yield put(
      auditLogListActions.setCanLoadMoreUsers(
        paramsUsersByCompany.page * paramsUsersByCompany.limit < res.total,
      ),
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(auditLogListActions.fetchUsersFailed());
  }
};

const handleGetCategoryListRequest = function* ({
  payload,
}: PayloadAction<CategoryGroupByRequestParamsDTO>) {
  try {
    const res = yield call(getListGroupByField, payload);
    // TODO: map response of BE for render dropdown list category
    const arr = res.map(item => {
      return {
        id: item?.requestCategory,
        name: item?.requestCategory,
      };
    });
    yield put(auditLogListActions.fetchCategorySuccess(arr));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(auditLogListActions.fetchCategoryFailed());
  }
};

const handleGetTypeListRequest = function* ({
  payload,
}: PayloadAction<CategoryGroupByRequestParamsDTO>) {
  try {
    const res = yield call(getListGroupByField, payload);
    // TODO: map response of BE for render dropdown list category
    const arr = res.map(item => {
      return {
        label: item?.requestCategory,
        options: item?.requestType,
      };
    });
    yield put(auditLogListActions.fetchTypeSuccess(arr));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(auditLogListActions.fetchTypeFailed());
  }
};

const handleGetActionListRequest = function* ({
  payload,
}: PayloadAction<CategoryGroupByRequestParamsDTO>) {
  try {
    const res = yield call(getListGroupByField, payload);
    // TODO: map response of BE for render dropdown list category
    const arr = res.map(item => {
      return {
        label: item?.requestType,
        options: item?.requestAction,
      };
    });
    yield put(auditLogListActions.fetchActionSuccess(arr));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(auditLogListActions.fetchActionFailed());
  }
};

const handleGetDetailAuditlogRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailAuditLog, payload);
    if (res) {
      yield put(auditLogListActions.getDetailAuditSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(auditLogListActions.getDetailAuditFailed());
  }
};

const auditLogListSaga = function* () {
  yield takeLatest(
    auditLogListActions.getAuditLogListRequest.type,
    handleGetAuditLogListRequest,
  );
  yield takeLatest(
    auditLogListActions.fetchCompanyRequest.type,
    handleGetCompanyListRequest,
  );
  yield takeLatest(
    auditLogListActions.fetchUsersRequest.type,
    handleGetUsersListRequest,
  );
  yield takeLatest(
    auditLogListActions.fetchCategoryRequest.type,
    handleGetCategoryListRequest,
  );
  yield takeLatest(
    auditLogListActions.fetchTypeRequest.type,
    handleGetTypeListRequest,
  );
  yield takeLatest(
    auditLogListActions.fetchActionRequest.type,
    handleGetActionListRequest,
  );

  yield takeLatest(
    auditLogListActions.getDetailAuditLogRequest.type,
    handleGetDetailAuditlogRequest,
  );
};

export default auditLogListSaga;
