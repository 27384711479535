export const addressDefault = {
  country: 'Singapore',
  city: '',
  address: '',
};

export const CUSTOMER_ACTION = {
  LOST_DEVICE: 'lost-device',
  REPLACE_DEVICE: 'phone-new',
  UNLOCK_USER: 'unlock',
  RESEND_EMAIL: 'resend-email',
  EDIT_USER: 'custom-icon',
};

export const CUSTOMER_STATUS = {
  AWAIT_ONBOARDING: 'AWAIT_ONBOARDING',
  FAIL_ONBOARDING: 'FAIL_ONBOARDING',
  ACTIVE: 'ACTIVE',
  PENDING_VERIFY: 'PENDING_VERIFY',
};

export const LOCKED_BY = {
  NONE: 'NONE',
  USER: 'USER',
  RAKKAR: 'RAKKAR',
};
