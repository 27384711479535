import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortVaultWc, GroupByField, CustomerOnScreen } from 'enum/vaultWc.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetVaultWcRequestParamDTO,
  GetVaultWcResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IVaultWcDetail,
} from 'interfaces/vaultWc';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface VaultWcState {
  params: GetVaultWcRequestParamDTO;
  vaultWc: ResponseData<GetVaultWcResponseDTO>;
  vaultWcPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  vaultWcDetail: ResponseData<IVaultWcDetail>;
}
export function getDefaultVaultWcRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortVaultWc.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: VaultWcState = {
  params: getDefaultVaultWcRequestParams(),
  vaultWc: {
    status: RequestStatus.Idle,
  },
  vaultWcPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ROLE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  vaultWcDetail: {
    status: RequestStatus.Idle,
  },
};
const getVaultWcRequest: CaseReducer<VaultWcState, PayloadAction<any>> = (
  state: VaultWcState,
) => {
  state.vaultWc.status = RequestStatus.Loading;
};
const getVaultWcSuccess: CaseReducer<
  VaultWcState,
  PayloadAction<GetVaultWcResponseDTO>
> = (state: VaultWcState, { payload }: PayloadAction<GetVaultWcResponseDTO>) => {
  state.vaultWc.status = RequestStatus.Success;
  state.vaultWc.data = payload;
};
const getVaultWcFailed: CaseReducer<VaultWcState> = (state: VaultWcState) => {
  state.vaultWc.status = RequestStatus.Failed;
};
const getDetailVaultWcRequest: CaseReducer<VaultWcState, PayloadAction<string>> = (
  state: VaultWcState,
) => {
  state.vaultWcDetail.status = RequestStatus.Loading;
};
const getDetailVaultWcSuccess: CaseReducer<
  VaultWcState,
  PayloadAction<IVaultWcDetail>
> = (state: VaultWcState, { payload }: PayloadAction<IVaultWcDetail>) => {
  state.vaultWcDetail.status = RequestStatus.Success;
  state.vaultWcDetail.data = payload;
};
const getDetailVaultWcFailed: CaseReducer<VaultWcState> = (state: VaultWcState) => {
  state.vaultWcDetail.status = RequestStatus.Failed;
};
const createVaultWcRequest: CaseReducer<VaultWcState, PayloadAction<IVaultWcDetail>> = (
  state: VaultWcState,
) => {
  state.vaultWcDetail.status = RequestStatus.Loading;
};
const createVaultWcSuccess: CaseReducer<VaultWcState, PayloadAction<IVaultWcDetail>> = (
  state: VaultWcState,
  { payload }: PayloadAction<IVaultWcDetail>,
) => {
  state.vaultWcDetail.status = RequestStatus.Success;
  state.vaultWcDetail.data = payload;
};
const createVaultWcFailed: CaseReducer<VaultWcState> = (state: VaultWcState) => {
  state.vaultWcDetail.status = RequestStatus.Failed;
};
const updateVaultWcRequest: CaseReducer<VaultWcState, PayloadAction<IVaultWcDetail>> = (
  state: VaultWcState,
) => {
  state.vaultWcDetail.status = RequestStatus.Loading;
};
const updateVaultWcSuccess: CaseReducer<VaultWcState, PayloadAction<IVaultWcDetail>> = (
  state: VaultWcState,
  { payload }: PayloadAction<IVaultWcDetail>,
) => {
  state.vaultWcDetail.status = RequestStatus.Success;
  state.vaultWcDetail.data = payload;
};
const updateVaultWcFailed: CaseReducer<VaultWcState> = (state: VaultWcState) => {
  state.vaultWcDetail.status = RequestStatus.Failed;
};
const appendVaultWc: CaseReducer<VaultWcState, PayloadAction<any>> = (
  state: VaultWcState,
  { payload }: PayloadAction<any>,
) => {
  if (state.vaultWc?.data?.listVaultWc) {
    state.vaultWc.status = RequestStatus.Success;
    state.vaultWc.data.listVaultWc = [...payload];
  }
};
const setCanLoadMore = (state: VaultWcState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetVaultWc: CaseReducer<VaultWcState> = (state: VaultWcState) => {
  state.vaultWc = {
    status: RequestStatus.Idle,
  };
};
const resetVaultWcParams: CaseReducer<VaultWcState> = (state: VaultWcState) => {
  state.params = getDefaultVaultWcRequestParams();
};
const setVaultWcFilterParams = (
  state: VaultWcState,
  { payload }: PayloadAction<GetVaultWcRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  VaultWcState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: VaultWcState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<VaultWcState, PayloadAction<any>> = (
  state: VaultWcState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<VaultWcState> = (state: VaultWcState) => {
  state.action.status = RequestStatus.Failed;
};
const getVaultWcPaginationConfigRequest: CaseReducer<
  VaultWcState,
  PayloadAction<any>
> = (state: VaultWcState) => {
  state.vaultWcPaginationConfig.status = RequestStatus.Loading;
};
const getVaultWcPaginationConfigSuccess: CaseReducer<
  VaultWcState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: VaultWcState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.vaultWcPaginationConfig.status = RequestStatus.Success;
  state.vaultWcPaginationConfig.data = payload;
};
const getVaultWcPaginationConfigFailed: CaseReducer<VaultWcState> = (
  state: VaultWcState,
) => {
  state.vaultWcPaginationConfig.status = RequestStatus.Failed;
};
const vaultWcSlice = createSlice({
  name: 'vaultWc',
  initialState,
  reducers: {
    getVaultWcRequest,
    getVaultWcSuccess,
    getVaultWcFailed,
    getDetailVaultWcRequest,
    getDetailVaultWcSuccess,
    getDetailVaultWcFailed,
    createVaultWcRequest,
    createVaultWcSuccess,
    createVaultWcFailed,
    updateVaultWcRequest,
    updateVaultWcSuccess,
    updateVaultWcFailed,
    appendVaultWc,
    setCanLoadMore,
    resetVaultWc,
    setVaultWcParams<K extends keyof GetVaultWcRequestParamDTO>(
      state: VaultWcState,
      action: PayloadAction<{
        key: K;
        value: GetVaultWcRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setVaultWcFilterParams,
    resetVaultWcParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getVaultWcPaginationConfigRequest,
    getVaultWcPaginationConfigSuccess,
    getVaultWcPaginationConfigFailed,
  },
});
// Actions
export const vaultWcActions = vaultWcSlice.actions;
// Reducer
export const vaultWcReducer = vaultWcSlice.reducer;
// Selectors
export const selectVaultWc = (state: AppState) => state.vaultWc?.vaultWc;
export const selectParamVaultWc = (state: AppState) => state.vaultWc?.params;
export const selectCanLoadMoreVaultWc = (state: AppState) =>
  state.vaultWc?.canLoadMore;
export const selectVaultWcDetail = (state: AppState) => state.vaultWc?.vaultWcDetail;
export const selectVaultWcPagination = (state: AppState) =>
  state.vaultWc?.vaultWcPaginationConfig;
