import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortCfg_typeOfCompany, GroupByField, CustomerOnScreen } from 'enum/cfg_typeOfCompany.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetCfg_typeOfCompanyRequestParamDTO,
  GetCfg_typeOfCompanyResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ICfg_typeOfCompanyDetail,
} from 'interfaces/cfg_typeOfCompany';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface Cfg_typeOfCompanyState {
  params: GetCfg_typeOfCompanyRequestParamDTO;
  cfg_typeOfCompany: ResponseData<GetCfg_typeOfCompanyResponseDTO>;
  cfg_typeOfCompanyPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  cfg_typeOfCompanyDetail: ResponseData<ICfg_typeOfCompanyDetail>;
}
export function getDefaultCfg_typeOfCompanyRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortCfg_typeOfCompany.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: Cfg_typeOfCompanyState = {
  params: getDefaultCfg_typeOfCompanyRequestParams(),
  cfg_typeOfCompany: {
    status: RequestStatus.Idle,
  },
  cfg_typeOfCompanyPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.CFG_TYPEOFCOMPANY,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  cfg_typeOfCompanyDetail: {
    status: RequestStatus.Idle,
  },
};
const getCfg_typeOfCompanyRequest: CaseReducer<Cfg_typeOfCompanyState, PayloadAction<any>> = (
  state: Cfg_typeOfCompanyState,
) => {
  state.cfg_typeOfCompany.status = RequestStatus.Loading;
};
const getCfg_typeOfCompanySuccess: CaseReducer<
  Cfg_typeOfCompanyState,
  PayloadAction<GetCfg_typeOfCompanyResponseDTO>
> = (state: Cfg_typeOfCompanyState, { payload }: PayloadAction<GetCfg_typeOfCompanyResponseDTO>) => {
  state.cfg_typeOfCompany.status = RequestStatus.Success;
  state.cfg_typeOfCompany.data = payload;
};
const getCfg_typeOfCompanyFailed: CaseReducer<Cfg_typeOfCompanyState> = (state: Cfg_typeOfCompanyState) => {
  state.cfg_typeOfCompany.status = RequestStatus.Failed;
};
const getDetailCfg_typeOfCompanyRequest: CaseReducer<Cfg_typeOfCompanyState, PayloadAction<string>> = (
  state: Cfg_typeOfCompanyState,
) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Loading;
};
const getDetailCfg_typeOfCompanySuccess: CaseReducer<
  Cfg_typeOfCompanyState,
  PayloadAction<ICfg_typeOfCompanyDetail>
> = (state: Cfg_typeOfCompanyState, { payload }: PayloadAction<ICfg_typeOfCompanyDetail>) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Success;
  state.cfg_typeOfCompanyDetail.data = payload;
};
const getDetailCfg_typeOfCompanyFailed: CaseReducer<Cfg_typeOfCompanyState> = (state: Cfg_typeOfCompanyState) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Failed;
};
const createCfg_typeOfCompanyRequest: CaseReducer<Cfg_typeOfCompanyState, PayloadAction<ICfg_typeOfCompanyDetail>> = (
  state: Cfg_typeOfCompanyState,
) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Loading;
};
const createCfg_typeOfCompanySuccess: CaseReducer<Cfg_typeOfCompanyState, PayloadAction<ICfg_typeOfCompanyDetail>> = (
  state: Cfg_typeOfCompanyState,
  { payload }: PayloadAction<ICfg_typeOfCompanyDetail>,
) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Success;
  state.cfg_typeOfCompanyDetail.data = payload;
};
const createCfg_typeOfCompanyFailed: CaseReducer<Cfg_typeOfCompanyState> = (state: Cfg_typeOfCompanyState) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Failed;
};
const updateCfg_typeOfCompanyRequest: CaseReducer<Cfg_typeOfCompanyState, PayloadAction<ICfg_typeOfCompanyDetail>> = (
  state: Cfg_typeOfCompanyState,
) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Loading;
};
const updateCfg_typeOfCompanySuccess: CaseReducer<Cfg_typeOfCompanyState, PayloadAction<ICfg_typeOfCompanyDetail>> = (
  state: Cfg_typeOfCompanyState,
  { payload }: PayloadAction<ICfg_typeOfCompanyDetail>,
) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Success;
  state.cfg_typeOfCompanyDetail.data = payload;
};
const updateCfg_typeOfCompanyFailed: CaseReducer<Cfg_typeOfCompanyState> = (state: Cfg_typeOfCompanyState) => {
  state.cfg_typeOfCompanyDetail.status = RequestStatus.Failed;
};
const appendCfg_typeOfCompany: CaseReducer<Cfg_typeOfCompanyState, PayloadAction<any>> = (
  state: Cfg_typeOfCompanyState,
  { payload }: PayloadAction<any>,
) => {
  if (state.cfg_typeOfCompany?.data?.listCfg_typeOfCompany) {
    state.cfg_typeOfCompany.status = RequestStatus.Success;
    state.cfg_typeOfCompany.data.listCfg_typeOfCompany = [...payload];
  }
};
const setCanLoadMore = (state: Cfg_typeOfCompanyState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetCfg_typeOfCompany: CaseReducer<Cfg_typeOfCompanyState> = (state: Cfg_typeOfCompanyState) => {
  state.cfg_typeOfCompany = {
    status: RequestStatus.Idle,
  };
};
const resetCfg_typeOfCompanyParams: CaseReducer<Cfg_typeOfCompanyState> = (state: Cfg_typeOfCompanyState) => {
  state.params = getDefaultCfg_typeOfCompanyRequestParams();
};
const setCfg_typeOfCompanyFilterParams = (
  state: Cfg_typeOfCompanyState,
  { payload }: PayloadAction<GetCfg_typeOfCompanyRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  Cfg_typeOfCompanyState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: Cfg_typeOfCompanyState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<Cfg_typeOfCompanyState, PayloadAction<any>> = (
  state: Cfg_typeOfCompanyState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<Cfg_typeOfCompanyState> = (state: Cfg_typeOfCompanyState) => {
  state.action.status = RequestStatus.Failed;
};
const getCfg_typeOfCompanyPaginationConfigRequest: CaseReducer<
  Cfg_typeOfCompanyState,
  PayloadAction<any>
> = (state: Cfg_typeOfCompanyState) => {
  state.cfg_typeOfCompanyPaginationConfig.status = RequestStatus.Loading;
};
const getCfg_typeOfCompanyPaginationConfigSuccess: CaseReducer<
  Cfg_typeOfCompanyState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: Cfg_typeOfCompanyState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.cfg_typeOfCompanyPaginationConfig.status = RequestStatus.Success;
  state.cfg_typeOfCompanyPaginationConfig.data = payload;
};
const getCfg_typeOfCompanyPaginationConfigFailed: CaseReducer<Cfg_typeOfCompanyState> = (
  state: Cfg_typeOfCompanyState,
) => {
  state.cfg_typeOfCompanyPaginationConfig.status = RequestStatus.Failed;
};
const cfg_typeOfCompanySlice = createSlice({
  name: 'cfg_typeOfCompany',
  initialState,
  reducers: {
    getCfg_typeOfCompanyRequest,
    getCfg_typeOfCompanySuccess,
    getCfg_typeOfCompanyFailed,
    getDetailCfg_typeOfCompanyRequest,
    getDetailCfg_typeOfCompanySuccess,
    getDetailCfg_typeOfCompanyFailed,
    createCfg_typeOfCompanyRequest,
    createCfg_typeOfCompanySuccess,
    createCfg_typeOfCompanyFailed,
    updateCfg_typeOfCompanyRequest,
    updateCfg_typeOfCompanySuccess,
    updateCfg_typeOfCompanyFailed,
    appendCfg_typeOfCompany,
    setCanLoadMore,
    resetCfg_typeOfCompany,
    setCfg_typeOfCompanyParams<K extends keyof GetCfg_typeOfCompanyRequestParamDTO>(
      state: Cfg_typeOfCompanyState,
      action: PayloadAction<{
        key: K;
        value: GetCfg_typeOfCompanyRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setCfg_typeOfCompanyFilterParams,
    resetCfg_typeOfCompanyParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getCfg_typeOfCompanyPaginationConfigRequest,
    getCfg_typeOfCompanyPaginationConfigSuccess,
    getCfg_typeOfCompanyPaginationConfigFailed,
  },
});
// Actions
export const cfg_typeOfCompanyActions = cfg_typeOfCompanySlice.actions;
// Reducer
export const cfg_typeOfCompanyReducer = cfg_typeOfCompanySlice.reducer;
// Selectors
export const selectCfg_typeOfCompany = (state: AppState) => state.cfg_typeOfCompany?.cfg_typeOfCompany;
export const selectParamCfg_typeOfCompany = (state: AppState) => state.cfg_typeOfCompany?.params;
export const selectCanLoadMoreCfg_typeOfCompany = (state: AppState) =>
  state.cfg_typeOfCompany?.canLoadMore;
export const selectCfg_typeOfCompanyDetail = (state: AppState) => state.cfg_typeOfCompany?.cfg_typeOfCompanyDetail;
export const selectCfg_typeOfCompanyPagination = (state: AppState) =>
  state.cfg_typeOfCompany?.cfg_typeOfCompanyPaginationConfig;
