import { TierSignerLevel } from 'enum/transaction';
import { TransactionTierSigner } from 'interfaces/transaction';

export const sortTiers = (tiers: TransactionTierSigner[]) => {
  const priority = (tier: TransactionTierSigner) => {
    switch (tier.tierLevel) {
      case TierSignerLevel.LARGE:
        return 1;
      case TierSignerLevel.MID:
        return 2;
      case TierSignerLevel.LOW:
        return 3;
    }
  };

  return tiers.sort((a, b) => priority(a) - priority(b));
};

export const getTier = (
  txnWorkspaceId: string,
  txnTotalUSD: number,
  tiers: TransactionTierSigner[],
) => {
  const findTier = (tiers: TransactionTierSigner[]) => {
    return tiers.find(
      t => (t.from || 0) < txnTotalUSD && (!t.to || txnTotalUSD < t.to),
    );
  };

  const filteredTier = sortTiers(
    tiers.filter(t => t.workspaceId === txnWorkspaceId),
  );

  if (filteredTier.length) {
    return findTier(filteredTier);
  } else {
    // when tier workspace doesn't match with txn workspace, use default instead
    const fallbackTier = sortTiers(tiers.filter(t => !t.workspaceId));
    return findTier(fallbackTier);
  }
};
