import { Col, Collapse, Divider, Row, Space, Table, Tabs } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { checkBusinessTypeOther } from 'app/components/BusinessInformation';
import {
  latestRiskLevelOpts,
  purposeOfRelationshipOpts,
  sourceOfWealthOpts,
} from 'app/components/BusinessInformation/index.helper';
import { CustomSpace } from 'app.v2/components/orgarnism/CustomerProfile/Tabs/ProductSubscription/components/CustomSpace';
import { SvgIcon } from 'app/components/SvgIcon';
import TextInfor, { dots } from 'app/components/common/TextInfor';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import {
  CompanyType,
  ContactPersonType,
  RoleQuorum,
  SourceOfFunds,
} from 'enum/addNewCustomer';
import { DOCUMENT_TYPE } from 'enum/common';
import { TAB_CREATE_CUSTOMER } from 'enum/pendingRequest';
import { UserInformation } from 'interfaces/adminQuorum';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { AppState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  commonActions,
  selectBusinessTypes,
  selectSourceOfFund,
  selectTypeOfCompany,
} from 'store/slices/common';
import { countryData } from 'utils/assets';
import {
  compareStrings,
  formatThousandSeparator,
  getCountryName,
  getNameFromArrayById,
} from 'utils/common';
import { formatDate } from 'utils/date';
import { getTextAddress } from 'utils/helpers';
import { getWorkspaceTypeName } from 'utils/workspace.utils';
import styles from './index.module.scss';

interface Props {
  id: string;
  name: string;
  onClose?: () => void;
  requestDetail: any;
  isMask: boolean;
}

const defaultColumns = [
  {
    title: 'From',
    dataIndex: 'from',
  },
  {
    title: 'To',
    dataIndex: 'to',
  },
  {
    title: 'Annual basis points',
    dataIndex: 'point',
  },
];

const CustomerCreation = ({
  id,
  name,
  onClose,
  requestDetail,
  isMask,
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const workspaces = useAppSelector(
    (state: AppState) => state.common.workspaces.data,
  );
  const bussiness = useAppSelector(selectBusinessTypes).data;
  const typeOfCompanyStore = useAppSelector(selectTypeOfCompany)?.data;
  const sourceOfFundStore = useAppSelector(selectSourceOfFund)?.data;
  const isOther = (requestDetail: string) =>
    requestDetail === CompanyType.OTHER ? true : false;

  useEffect(() => {
    dispatch(commonActions.getTypeOfCompanyRequest({}));
    dispatch(commonActions.getSourceOfFundRequest({}));
    dispatch(commonActions.getCustomerEntityRelationRequest());
  }, [dispatch]);

  const companyType = useMemo(() => {
    if (isOther(requestDetail?.companyType) && requestDetail?.companyTypeOther)
      return requestDetail?.companyTypeOther;
    return (
      (!!requestDetail &&
        typeOfCompanyStore?.find(item => item.id === requestDetail.companyType)
          ?.name) ||
      ''
    );
  }, [requestDetail, typeOfCompanyStore]);

  const mapFullName = (userInfo: UserInformation) => {
    const fullName = userInfo.middleName
      ? `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`
      : `${userInfo.firstName} ${userInfo.lastName}`;
    return fullName;
  };

  const sourceOfFunds = useMemo(() => {
    const listSelected = requestDetail.sourceOfFunds?.split(',');
    const listSourceOfFunds: string[] = [];

    listSelected?.forEach(element => {
      if (element === SourceOfFunds.OTHER && requestDetail?.sourceOfFundOther) {
        listSourceOfFunds.push(requestDetail?.sourceOfFundOther);
      } else {
        const source =
          sourceOfFundStore?.find(item => item.id === element)?.name || '';
        listSourceOfFunds.push(source);
      }
    });
    listSourceOfFunds.sort(compareStrings);
    return listSourceOfFunds.join(', ');
  }, [requestDetail, sourceOfFundStore]);

  const sourceOfWealths = useMemo(() => {
    if (
      isOther(requestDetail?.sourceOfWealth) &&
      requestDetail.sourceOfWealthOther
    )
      return requestDetail.sourceOfWealthOther;
    return (
      (!!requestDetail &&
        sourceOfWealthOpts?.find(
          item => item.value === requestDetail.sourceOfWealth,
        )?.label) ||
      ''
    );
  }, [requestDetail]);

  const purposeOfRelationship = useMemo(() => {
    if (
      isOther(requestDetail?.purposeOfRelationship) &&
      requestDetail.purposeOfRelationshipOther
    )
      return requestDetail.purposeOfRelationshipOther;
    return (
      (!!requestDetail &&
        purposeOfRelationshipOpts?.find(
          item => item.value === requestDetail.purposeOfRelationship,
        )?.label) ||
      ''
    );
  }, [requestDetail]);

  const lastestRiskLevel = useMemo(() => {
    return (
      (!!requestDetail &&
        latestRiskLevelOpts?.find(
          item => item.value === requestDetail.lastestRiskLevel,
        )?.label) ||
      ''
    );
  }, [requestDetail]);

  const registrationAddress = useMemo(() => {
    return getTextAddress(requestDetail.registrationAddress);
  }, [requestDetail.registrationAddress]);

  const currentAddress = useMemo(() => {
    return getTextAddress(requestDetail.currentAddress);
  }, [requestDetail.currentAddress]);

  const billingAddress = useMemo(() => {
    return getTextAddress(requestDetail.billingAddress);
  }, [requestDetail.billingAddress]);

  const textHot = useMemo(() => {
    return workspaces?.hot.find(item => item.id === requestDetail?.hotWalletId)
      ?.name;
  }, [requestDetail?.hotWalletId, workspaces]);

  const textCold = useMemo(() => {
    return workspaces?.cold.find(
      item => item.id === requestDetail?.coldWalletId,
    )?.name;
  }, [requestDetail?.coldWalletId, workspaces]);

  const businessType = useMemo(() => {
    const isOther = checkBusinessTypeOther(
      Number(requestDetail.businessTypeId),
      bussiness,
    );
    if (isOther && requestDetail?.businessTypeOther) {
      return requestDetail.businessTypeOther;
    }
    return (
      (!!requestDetail?.businessTypeId &&
        getNameFromArrayById(bussiness, requestDetail.businessTypeId ?? '')) ||
      ''
    );
  }, [bussiness, requestDetail]);

  const mapCountry = (code: string) => {
    const found = countryData.find(country => country.code === code);
    return found?.name ?? '';
  };

  const capitalizeFirstLetter = (status: string) => {
    const text = status.toLocaleLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const renderWallet = (wallet: any, text: string, type: string) => {
    return (
      <div>
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
          style={{ marginTop: 0 }}
        >
          {getWorkspaceTypeName(type)}
        </Divider>
        <Row className={styles.body} data-testid="cold-hot-wallet">
          <TextInfor
            span={24}
            label={`${getWorkspaceTypeName(type)} workspace`}
            text={text}
          />
        </Row>

        {/* FEE INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Fee information
        </Divider>
        <Row className={styles.body}>
          <TextInfor
            span={12}
            label="Initial setup fee (USD)"
            text={
              wallet?.initialSetupFee &&
              wallet?.initialSetupFee
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            dataTestId="set-up-fee"
          />
          <TextInfor
            span={12}
            label="Minimum monthly fee (USD)"
            text={
              wallet?.minimumMonthlyFee &&
              wallet?.minimumMonthlyFee
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            dataTestId="monthly-fee"
          />
          <TextInfor
            span={12}
            label="Minimum AUC Balance (USD)"
            text={
              wallet?.minimumAUCBalance &&
              wallet?.minimumAUCBalance
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            dataTestId="set-up-fee"
          />
        </Row>

        {/* AUC */}
        {!!wallet?.assetUnderCustody && (
          <>
            <Divider
              orientationMargin="0"
              className={styles.content__header}
              orientation="left"
            >
              Asset under custody
            </Divider>
            <div style={{ marginTop: '12px' }}>
              <Table
                className={styles.tableAUC}
                columns={defaultColumns}
                bordered
                dataSource={wallet?.assetUnderCustody.map(
                  ({ key, from, to, point }) => ({
                    key,
                    from: formatThousandSeparator(from) || 0,
                    to: formatThousandSeparator(to),
                    point: formatThousandSeparator(point),
                  }),
                )}
                pagination={false}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const TabGeneral = () => (
    <div className={styles.user__info}>
      {/* IDENTIFICATION INFORMATION */}
      <Divider
        orientationMargin="0"
        className={styles.content__header}
        orientation="left"
      >
        Identification information
      </Divider>
      <Row className={styles.body}>
        <TextInfor
          label="Business registered name"
          text={requestDetail.customerName ?? ''}
          dataTestId="customer-name"
        />
        <TextInfor
          label="Customer short name"
          text={requestDetail.customerShortName ?? ''}
          dataTestId="short-name"
        />
        <TextInfor
          label="Business registration number"
          text={requestDetail.businessRegistrationId ?? ''}
          dataTestId="registration-id"
        />
        <TextInfor
          label="Tax ID"
          text={requestDetail.taxId ?? ''}
          dataTestId="tax-id"
        />
      </Row>

      {/* LEGAL INFORMATION */}
      <Divider
        orientationMargin="0"
        className={styles.content__header}
        orientation="left"
      >
        Legal information
      </Divider>
      <Row className={styles.body}>
        <TextInfor
          label="Nature of business"
          text={businessType}
          dataTestId="business-type"
        />
        <TextInfor
          label="Business entity type"
          text={companyType ?? ''}
          dataTestId="company-type"
        />
        <TextInfor
          label="Date of incorporation"
          text={formatDate(requestDetail.registrationDate ?? '')}
          dataTestId="registration-date"
        />
      </Row>
      {/* LOCATION INFORMATION */}
      <Divider
        orientationMargin="0"
        className={styles.content__header}
        orientation="left"
      >
        Location infomation
      </Divider>
      <Row className={styles.body}>
        <TextInfor
          span={24}
          label="Registered address"
          text={registrationAddress ?? ''}
          dataTestId="registration-address"
        />
        <TextInfor
          span={24}
          label="Principle place of business"
          text={currentAddress ?? ''}
          dataTestId="current-address"
        />
        <TextInfor
          span={24}
          label="Billing address"
          text={billingAddress}
          dataTestId="billing-address"
        />
      </Row>

      {/* OTHER INFORMATION */}
      <Divider
        orientationMargin="0"
        className={styles.content__header}
        orientation="left"
      >
        Other information
      </Divider>
      <Row className={styles.body}>
        <TextInfor
          span={12}
          label="Intended purpose of the account"
          text={purposeOfRelationship ?? ''}
          dataTestId="purpose-relationship"
        />
        <TextInfor
          span={12}
          label="Source of wealth"
          text={sourceOfWealths ?? ''}
          dataTestId="source-of-wealth"
        />
        <TextInfor
          span={12}
          label="Source of funds"
          text={sourceOfFunds ?? ''}
          dataTestId="source-of-funds"
        />
        <TextInfor
          span={12}
          label="Date of Latest risk assessment"
          text={formatDate(requestDetail.dateOfLastestRiskLevel ?? '')}
          dataTestId="date-of-latest"
        />
        <TextInfor
          span={12}
          label="Latest risk level"
          text={lastestRiskLevel ?? ''}
          dataTestId="risk-level"
        />
        <TextInfor
          span={12}
          label="Credit term"
          text={requestDetail?.creditTerm || ''}
          dataTestId="credit-term"
        />
        <TextInfor
          span={12}
          label="Rakkar entity"
          text={mapCountry(requestDetail?.entityRelation?.countryCode || '')}
          dataTestId="entity-relation-id"
        />
      </Row>

      {/* CONTACT INFORMATION */}
      <Divider
        orientationMargin="0"
        className={styles.content__header}
        orientation="left"
      >
        Contact information
      </Divider>
      <Divider
        orientationMargin="0"
        className={styles.content__header}
        orientation="left"
        style={{ marginTop: 0 }}
      >
        Main contact person
      </Divider>
      <Row className={styles.body}>
        <TextInfor
          span={12}
          label="First name"
          text={requestDetail.firstName ?? ''}
          dataTestId="first-name"
          isMask={isMask}
          isMaskable={true}
        />
        <TextInfor
          span={12}
          label="Last name"
          text={requestDetail.lastName ?? ''}
          dataTestId="last-name"
          isMask={isMask}
          isMaskable={true}
        />
        <TextInfor
          span={12}
          label="Phone number"
          text={`${requestDetail.countryCode} ${requestDetail.phoneNumber}`}
          dataTestId="phone-number"
          isMask={isMask}
          isMaskable={true}
        />
        <TextInfor
          span={12}
          label="Email"
          text={requestDetail.email ?? ''}
          dataTestId="email"
          isMask={isMask}
          isMaskable={true}
        />
        <TextInfor
          span={12}
          label="Position"
          text={requestDetail.position ?? ''}
          dataTestId="position"
        />
      </Row>
      {requestDetail?.additionalContacts
        ?.filter((i: any) => i.type === ContactPersonType.GENERAL)
        ?.map((i: any) => {
          return (
            <div key={i}>
              <Divider
                orientationMargin="0"
                className={styles.content__header}
                orientation="left"
              >
                Alternative contact person (optional)
              </Divider>
              <Row className={styles.body}>
                <TextInfor
                  span={12}
                  label="First name"
                  text={i?.firstName ?? ''}
                  dataTestId="first-name"
                  isMask={isMask}
                  isMaskable={true}
                />
                <TextInfor
                  span={12}
                  label="Last name"
                  text={i?.lastName ?? ''}
                  dataTestId="last-name"
                  isMask={isMask}
                  isMaskable={true}
                />
                <TextInfor
                  span={12}
                  label="Phone number"
                  text={`${i?.countryCode} ${i?.phoneNumber}`}
                  dataTestId="phone-number"
                  isMask={isMask}
                  isMaskable={true}
                />
                <TextInfor
                  span={12}
                  label="Email"
                  text={i?.email ?? ''}
                  dataTestId="email"
                  isMask={isMask}
                  isMaskable={true}
                />
                <TextInfor
                  span={12}
                  label="Position"
                  text={i?.position ?? ''}
                  dataTestId="position"
                />
              </Row>
            </div>
          );
        })}

      {/* Billing contact person */}
      <Divider
        orientationMargin="0"
        className={styles.content__header}
        orientation="left"
      >
        Billing contact person
      </Divider>
      {requestDetail?.additionalContacts
        ?.filter((i: any) => i.type === ContactPersonType.BILLING)
        ?.map((i: any) => {
          return (
            <Row className={styles.body} key={i}>
              <TextInfor
                label="First name"
                span={12}
                text={i?.firstName}
                isMask={isMask}
                isMaskable={true}
                dataTestId="first-name"
              />
              <TextInfor
                label="Last name"
                span={12}
                text={i?.lastName}
                isMask={isMask}
                isMaskable={true}
                dataTestId="last-name"
              />
              <TextInfor
                label="Phone number"
                span={12}
                text={`${i?.countryCode} ${i?.phoneNumber}`}
                isMask={isMask}
                isMaskable={true}
                dataTestId="phone-number"
              />
              <TextInfor
                label="Email"
                span={12}
                text={i?.email}
                isMask={isMask}
                isMaskable={true}
                dataTestId="email"
              />
            </Row>
          );
        })}
    </div>
  );

  const ProductSubscriptionTab = () => (
    <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
      <div className={styles.user__info}>
        {!!textHot &&
          renderWallet(requestDetail.feeInformation?.hotWallet, textHot, 'hot')}
        {!!textCold &&
          renderWallet(
            requestDetail.feeInformation?.coldWallet,
            textCold,
            'cold',
          )}
        {requestDetail?.staking.length !== 0 && (
          <div style={{ marginTop: '24px' }}>
            <Divider
              orientationMargin="0"
              className={styles.content__header}
              orientation="left"
              style={{ marginTop: 0 }}
            >
              {getWorkspaceTypeName('staking')}
            </Divider>

            <Row>
              <Col span={12}>
                <CustomSpace>
                  <span className={styles.content__label}>Assets</span>
                  <span className={styles.text}>
                    {requestDetail?.staking.length > 0 &&
                      requestDetail?.staking.map((item, index) => (
                        <>
                          {item.symbol} ({item.asset})
                          {index !== requestDetail?.staking.length - 1 && ', '}
                        </>
                      ))}
                  </span>
                </CustomSpace>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );

  const AdminQuorumTab = () => (
    <div>
      <Space className={styles.total} size="middle" align="center">
        <Space direction="vertical" size={0} align="start">
          <span className={styles.total_role_label_1}>Quorum</span>
          <span className={styles.total_role_label_2}>
            {requestDetail.quorumSize || 0} of{' '}
            {requestDetail.adminQuorum?.length || 0}
          </span>
        </Space>
        <Space direction="vertical" size={0} align="start">
          <span className={styles.total_role_label_1}>
            Required{' '}
            <SvgIcon
              width={12}
              height={12}
              name="required-approver"
              className={styles.require__approver__icon}
            />
          </span>
          <span className={styles.total_role_label_2}>
            {requestDetail.adminQuorum?.filter(i => i.requiredApprover)
              ?.length || 0}
          </span>
        </Space>
      </Space>
      <Collapse ghost expandIconPosition="end" className={styles.collapse}>
        {requestDetail.adminQuorum?.map(
          (userInfo: UserInformation, index: number) => (
            <CollapsePanel
              header={
                <Divider
                  orientationMargin="0"
                  className={styles.user__name}
                  orientation="left"
                >
                  <div style={{ display: 'flex' }}>
                    <span style={{ fontSize: '14px' }}>
                      {isMask ? dots() : mapFullName(userInfo)}
                    </span>
                    <div
                      className={clsx(styles.role, {
                        [styles.role__viewer]:
                          userInfo.userRole === RoleQuorum.VIEWER,
                        [styles.role__member]:
                          userInfo.userRole === RoleQuorum.MEMBER,
                      })}
                    >
                      {capitalizeFirstLetter(userInfo.userRole)}
                    </div>
                    {userInfo.userRole === RoleQuorum.ADMIN &&
                      userInfo.requiredApprover && (
                        <SvgIcon
                          width={20}
                          height={20}
                          name="is-admin"
                          className={styles.admin__icon}
                        />
                      )}
                  </div>
                </Divider>
              }
              key={userInfo.id || index}
            >
              {userInfo.documentType === DOCUMENT_TYPE.PASSPORT && (
                <Row>
                  <Col span={12}>
                    <div className={styles.text__portion}>
                      <span className={styles.label_content}>
                        Passport number
                      </span>
                      <span className={styles.text_content}>
                        {isMask ? dots() : userInfo.passportNumber}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className={styles.text__portion}
                      data-testid="registration-country"
                    >
                      <span className={styles.label_content}>
                        Country of issued passport
                      </span>
                      <span className={styles.text_content}>
                        {!!userInfo.passportIssuedCountry &&
                          mapCountry(userInfo.passportIssuedCountry)}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}

              {userInfo.documentType === DOCUMENT_TYPE.ID_CARD && (
                <Row>
                  <Col span={12}>
                    <div className={styles.text__portion}>
                      <span className={styles.label_content}>ID number</span>
                      <span className={styles.text_content}>
                        {isMask ? dots() : userInfo.idCardNumber}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className={styles.text__portion}
                      data-testid="idCard-IssuedCountry"
                    >
                      <span className={styles.label_content}>
                        Country of issued ID card
                      </span>
                      <span className={styles.text_content}>
                        {!!userInfo.idCardIssuedCountry &&
                          mapCountry(userInfo.idCardIssuedCountry)}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={12}>
                  <div
                    className={styles.text__portion}
                    data-testid="date-of-birth"
                  >
                    <span className={styles.label_content}>Date of birth</span>
                    <span className={styles.text_content}>
                      {isMask
                        ? dots()
                        : moment(userInfo.dateOfBirth).format(
                            FORMAT_DATE.DEFAULT,
                          )}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div
                    className={styles.text__portion}
                    data-testid="nationality"
                  >
                    <span className={styles.label_content}>Nationality</span>
                    <span className={styles.text_content}>
                      {getCountryName(userInfo.nationality)}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className={styles.text__portion} data-testid="address">
                    <span className={styles.label_content}>Address</span>
                    <span className={styles.text_content}>
                      {isMask ? dots() : getTextAddress(userInfo.address)}
                    </span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div className={styles.text__portion}>
                    <span className={styles.label_content}>Phone number</span>
                    <span className={styles.text_content}>
                      {isMask ? dots() : userInfo.phoneNumber}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className={styles.text__portion} data-testid="email">
                    <span className={styles.label_content}>Email</span>
                    <span className={styles.text_content}>
                      {isMask ? dots() : userInfo.email}
                    </span>
                  </div>
                </Col>
              </Row>
            </CollapsePanel>
          ),
        )}
      </Collapse>
    </div>
  );

  return (
    <div className={styles.content}>
      <SvgIcon name="company-creation" width={32} height={32} />
      <Row className={styles.reviewing__title}>Customer creation request</Row>
      <Tabs
        className={styles.tabProfile}
        defaultActiveKey={TAB_CREATE_CUSTOMER.GENERAL}
        items={[
          {
            label: `Company information`,
            key: TAB_CREATE_CUSTOMER.GENERAL,
            children: <TabGeneral />,
          },
          {
            label: `Product subcription`,
            key: TAB_CREATE_CUSTOMER.PRODUCT_SUBSCRIPTION,
            children: <ProductSubscriptionTab />,
          },
          {
            label: `Account policy and user`,
            key: TAB_CREATE_CUSTOMER.ADMIN_QUORUM,
            children: <AdminQuorumTab />,
          },
        ]}
      />
    </div>
  );
};

export default CustomerCreation;
