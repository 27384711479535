import { PREFIX_API } from 'constants/index';
import {
  AccessControlDetail,
  AccessControlGroups,
  GetListAccessControlRequestParams,
} from 'interfaces/accessControl';
import Instance from '../app.v2/config/instance';

export function getAccessControlListRequest(
  params?: GetListAccessControlRequestParams,
): Promise<any> {
  return Instance.get(`/${PREFIX_API.AUTH}/features`, {
    params,
  });
}

export function getDetailAccessControl(
  id: string,
  limit: number,
): Promise<AccessControlDetail> {
  return Instance.get(`/${PREFIX_API.AUTH}/features/${id}`, {
    params: { limit: limit },
  });
}

export function requestUpdateAccessControl(
  id: string,
  groups: AccessControlGroups[],
): Promise<any> {
  return Instance.put(`/${PREFIX_API.AUTH}/features/${id}`, {
    groups,
  });
}
