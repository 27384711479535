import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import { RequestStatus } from 'interfaces/request';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  accessControlListActions,
  selectAccessControlDetail,
  selectAccessControlRequest,
  selectInitGroups,
  selectUpdateGroups,
} from 'store/slices/accessControl/accessControl.slice';
import AccessControlCheckList from './AccessControlChecklist';
import PopupAccessControlRequest from './PopupConfirmAccessControlRequest';
import styles from './index.module.scss';

type Props = {
  accessControlId: any;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  loading: boolean;
};

const PopupAccessControl = ({
  isOpen,
  accessControlId,
  setIsOpen,
  loading,
}: Props) => {
  const dispatch = useDispatch();
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const accessControlDetail = useAppSelector(selectAccessControlDetail);
  const updatedGroups = useAppSelector(selectUpdateGroups);
  const initGroups = useAppSelector(selectInitGroups);
  const requestUpdate = useAppSelector(selectAccessControlRequest);
  const detail = accessControlDetail?.data?.feature;
  const detailId = detail?.id;
  const pending = accessControlDetail?.data?.hasPendingRequest;
  const [isPending, setIsPending] = useState(false);
  const [pendingId, setPendingID] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const onCLick = () => {
    setOpenPopupConfirm(true);
  };

  useEffect(() => {
    const isEqual = _.isEqual(updatedGroups, initGroups);
    setIsChanged(isEqual ? false : true);
  }, [updatedGroups, initGroups]);

  useEffect(() => {
    if (accessControlId) {
      dispatch(
        accessControlListActions.getDetailAccessControlRequest({
          id: accessControlId,
          limit: 100,
        }),
      );
    }
  }, [accessControlId]);

  useEffect(() => {
    if (accessControlDetail) {
      dispatch(
        accessControlListActions.setInitGroups(
          accessControlDetail.data?.feature?.groups,
        ),
      );
      dispatch(
        accessControlListActions.setUpdateGroups(
          accessControlDetail.data?.feature?.groups,
        ),
      );
    }
  }, [accessControlDetail]);

  useEffect(() => {
    if (pending?.length) {
      setIsPending(true);
      setPendingID(pending[0]?.id);
    } else setIsPending(false);
  }, [pending]);

  useEffect(() => {
    if (accessControlDetail?.status === RequestStatus.Loading) {
      setIsLoadingDetail(true);
    } else {
      setIsLoadingDetail(false);
    }
  }, [accessControlDetail?.status]);

  useEffect(() => {
    if (requestUpdate?.status === RequestStatus.Success) {
      setIsOpen(false);
      setOpenPopupConfirm(false);
      dispatch(accessControlListActions.resetDetailAccessControl());
    }
  }, [requestUpdate?.status]);

  useEffect(() => {
    const disableRule = !isChanged || isPending;
    setIsDisable(disableRule ? true : false);
  }, [isChanged, isPending]);

  const handleConfirm = () => {
    if (detailId)
      dispatch(
        accessControlListActions.accessControlRequest({
          id: detailId,
          groups: updatedGroups,
        }),
      );
  };

  const content = (
    <AccessControlCheckList
      loading={isLoadingDetail}
      isPending={isPending}
      pendingId={pendingId}
      isOpen={isOpen}
    />
  );

  return (
    <>
      <BasicModal
        title={detail?.name}
        visible={isOpen}
        children={content}
        onCancel={() => setIsOpen(false)}
        centered={true}
        maskClosable={true}
        width={600}
        footer={[
          <div className={styles.footer} key={detail?.id}>
            <div className={styles.last_update} data-testId="last-update">
              Last Updated
              <div className={styles.last_update__time}>
                {moment(detail?.lastUpdatedAt).format(FORMAT_DATE.DATE_DMY_hM)}
              </div>
            </div>
            <div className={styles.button} key="popup-access-control">
              <BasicButton
                title="Cancel"
                type="outline"
                onClick={() => setIsOpen(false)}
                disabled={isDisable}
                className={clsx(styles.button, {
                  [styles.button__disable]: isDisable,
                })}
                dataTestId="cancel-btn"
              />
              <BasicButton
                title="Submit"
                type="filled"
                className={clsx(styles.button, {
                  [styles.button__disable]: isDisable,
                })}
                onClick={onCLick}
                loading={loading}
                disabled={isDisable}
                dataTestId="submit-btn"
              />
            </div>
          </div>,
        ]}
        className={styles.modal_access_control}
        dataTestId="modal-access-control"
      />
      <PopupAccessControlRequest
        isOpen={openPopupConfirm}
        setIsOpen={setOpenPopupConfirm}
        handleConfirm={handleConfirm}
        featureName={accessControlDetail?.data?.feature.name || ''}
      />
    </>
  );
};
export default PopupAccessControl;
