import { DatePicker, Form, Tooltip } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { FORMAT_DATE } from 'constants/index';
import { AuditLogStatus, PAGE } from 'enum/common';

import InputSearch from 'app/components/common/InputSearch';
import { SvgIcon } from 'app/components/common/SvgIcon';

import { RequestStatus } from 'interfaces/request';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  auditLogListActions,
  selectAuditLogList,
} from 'store/slices/auditLogList/auditLogList.slice';
import BasicButton from '../common/BasicButton';
import SelectInfiniteScroll from '../common/SelectInfiniteScroll';
import { FormItem } from '../common/SelectInfiniteScroll/Styles';
import styles from './AuditLogFilter.module.scss';

const { RangePicker } = DatePicker;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
`;

const BoxTop = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
`;

const BoxBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 32px;
`;

const FormItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SvgIconButton = styled.div`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;

  margin-right: 8px;
`;

const ClearAllButton = styled(BasicButton)`
  cursor: pointer;

  height: 36px;
  padding: 0 16px;
  background: #ffffff;
  border: 1px solid #d5d7df;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  color: #2c303c;
  margin-right: 8px;
  margin-bottom: 16px;

  font-weight: 500;
  font-size: 13px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ApplyButton = styled(BasicButton)`
  cursor: pointer;

  /* padding: 10px 16px; */
  background: #25397c;
  border-radius: 24px;
  color: #ffffff;
  margin-bottom: 16px;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;

const StyledForm = styled(Form)`
  padding: 16px 16px 0;
  background: #ffffff;
  border: 1px solid #ebecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: auto;
`;

const StyledRangePicker = styled(RangePicker)`
  width: 100%;
`;

const disabledDate: RangePickerProps['disabledDate'] = current => {
  return current && current.isAfter(moment().subtract(0, 'day'));
};

const statusOptions = [
  {
    label: 'Initiated',
    value: AuditLogStatus.INITIATED,
  },
  {
    label: 'Success',
    value: AuditLogStatus.SUCCESSFUL,
  },
  {
    label: 'Failed',
    value: AuditLogStatus.FAILED,
  },
];

interface Props {
  FilterButton: () => JSX.Element;
  onInputSearch: (value: string) => void;
  keyword?: string;
  onRefresh: () => void;
  onDownload: () => void;
  onFinishFilter: (values: any) => void;
  isFilterShown: boolean;
  isMaskAuditLog: boolean;
  setIsMaskAuditLog: (value: boolean) => void;
}

const defaultFormFilter = {
  company: undefined,
  username: undefined,
  category: undefined,
  type: undefined,
  action: undefined,
  status: undefined,
  activityDate: [null, null],
};

const AuditLogFilter: React.FunctionComponent<Props> = ({
  FilterButton,
  onInputSearch,
  onRefresh,
  onDownload,
  onFinishFilter,
  isFilterShown,
  keyword,
  isMaskAuditLog,
  setIsMaskAuditLog,
}) => {
  const dispatch = useAppDispatch();

  const {
    company,
    canLoadMoreCompany,
    paramsCompany,
    usersByCompany,
    paramsUsersByCompany,
    canLoadMoreUsers,
    category,
    paramsCategory,
    type,
    paramsType,
    action,
    paramsAction,
  } = useAppSelector(state => state.auditLogList);
  const totalAuditLogList =
    useAppSelector(selectAuditLogList)?.data?.total || 0;
  const allowDownload = totalAuditLogList > 0 && totalAuditLogList < 1000;

  const [companies, setCompanies] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [types, setTypes] = useState<any>([]);
  const [actions, setActions] = useState<any>([]);

  const [isDisabledClearAll, setIsDisabledClearAll] = useState(true);

  const [form] = Form.useForm();
  const categoryValue = Form.useWatch('category', form);
  const typeValue = Form.useWatch('type', form);

  useEffect(() => {
    dispatch(auditLogListActions.fetchCompanyRequest(paramsCompany));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsCompany]);

  useEffect(() => {
    dispatch(auditLogListActions.fetchUsersRequest(paramsUsersByCompany));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUsersByCompany]);

  useEffect(() => {
    dispatch(auditLogListActions.fetchCategoryRequest(paramsCategory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (company?.data?.customers) {
      setCompanies(company?.data?.customers);
    }

    if (usersByCompany?.data?.customers) {
      setUsers(usersByCompany?.data?.customers);
    }

    if (category?.data) {
      setCategories(category?.data);
    }

    if (type?.data) {
      setTypes(type?.data);
    }
    if (action?.data) {
      setActions(action?.data);
    }
  }, [company, usersByCompany, category, type, action]);

  const onChangeCompany = value => {
    if (!!value && value.length > 0) {
      const params = {
        ...paramsUsersByCompany,
        customerIds: value.toString(),
        page: 1,
      };
      dispatch(auditLogListActions.setUsersListParams(params));
    } else {
      onClearCompany();
    }
    form.setFieldValue('username', undefined);
  };

  const onClearCompany = () => {
    dispatch(auditLogListActions.resetUsers());
  };

  const onChangeUsername = value => {
    if (!!value && value.length > 0) {
      dispatch(
        auditLogListActions.setUsersListParams({
          ...paramsUsersByCompany,
          page: 1,
        }),
      );
    }
  };

  const onChangeCategory = value => {
    form.setFieldValue('type', undefined);
    form.setFieldValue('action', undefined);
    if (!!value && value.length > 0) {
      dispatch(
        auditLogListActions.fetchTypeRequest({
          ...paramsType,
          categories: value.toString(),
        }),
      );
    }
  };

  const onChangeType = value => {
    form.setFieldValue('action', undefined);
    if (!!value && value.length > 0) {
      dispatch(
        auditLogListActions.fetchActionRequest({
          ...paramsAction,
          types: value.toString(),
        }),
      );
    }
  };

  const onScrollLoadmoreCompany = () => {
    const page = (Number(paramsCompany.page) || 1) + 1;
    dispatch(
      auditLogListActions.setCompanyListParams({
        ...paramsCompany,
        page,
      }),
    );
  };

  const onScrollLoadmoreUsername = () => {
    const page = (Number(paramsUsersByCompany.page) || 1) + 1;
    dispatch(
      auditLogListActions.setUsersListParams({
        ...paramsUsersByCompany,
        page,
      }),
    );
  };

  const onInputSearchCompany = (keyword: string) => {
    dispatch(
      auditLogListActions.setCompanyListParams({
        ...paramsCompany,
        keyword,
        page: 1,
      }),
    );
  };

  const onInputSearchUsername = (keyword: string) => {
    dispatch(
      auditLogListActions.setUsersListParams({
        ...paramsUsersByCompany,
        keyword,
        page: 1,
      }),
    );
  };

  const onClearAll = () => {
    form.resetFields();
    dispatch(auditLogListActions.resetAuditLogList());
    dispatch(auditLogListActions.resetCompany());
    dispatch(auditLogListActions.resetUsers());
    setIsDisabledClearAll(true);
  };

  const handleFieldsChange = useCallback((_, values) => {
    const hasSomeValue = hasSomeParameters(values);
    setIsDisabledClearAll(!hasSomeValue);
  }, []);

  const hasSomeParameters = (values: any) => {
    const { action, activityDate, category, company, status, type, username } =
      values;

    const hasAction = action?.length;
    const hasCategory = category?.length;
    const hasCompany = company?.length;
    const hasStatus = status?.length;
    const hasType = type?.length;
    const hasUsername = username?.length;

    const hasActivitiDate = activityDate?.some(Boolean);

    return (
      hasAction ||
      hasCategory ||
      hasCompany ||
      hasStatus ||
      hasType ||
      hasUsername ||
      hasActivitiDate
    );
  };

  const onFinish = (values: any) => {
    onFinishFilter(values);
  };

  return (
    <div id="AuditLogFilter">
      <TopContainer>
        <InputSearch
          term={keyword}
          onInputChange={onInputSearch}
          page={PAGE.AUDITLOGLIST}
          style={{ width: '316px' }}
        />
        <Buttons>
          <SvgIcon
            name={isMaskAuditLog ? 'black-eye' : 'black-eye-off'}
            onClick={() => setIsMaskAuditLog(!isMaskAuditLog)}
            width={16}
            height={isMaskAuditLog ? 12 : 16}
            style={{ cursor: 'pointer', marginRight: '20px' }}
          />
          <Tooltip placement="bottom" title="Refresh">
            <SvgIconButton onClick={onRefresh} data-testid="refresh">
              <SvgIcon name="refresh" width={16} height={16} fill="#64697B" />
            </SvgIconButton>
          </Tooltip>
          <Tooltip
            placement="bottomRight"
            title="Only allow to download up to the 1,000 logs"
            overlayInnerStyle={{ whiteSpace: 'nowrap', width: 'fit-content' }}
          >
            <SvgIconButton
              onClick={() => {
                if (allowDownload) onDownload();
              }}
              style={{ cursor: allowDownload ? 'pointer' : 'not-allowed' }}
            >
              <SvgIcon
                name="download"
                width={16}
                height={16}
                fill={`${allowDownload ? '#64697B' : '#B4B7C3'}`}
              />
            </SvgIconButton>
          </Tooltip>
          <FilterButton />
        </Buttons>
      </TopContainer>
      {isFilterShown && (
        <StyledForm
          form={form}
          initialValues={defaultFormFilter}
          layout="vertical"
          name="requestFilter"
          onValuesChange={handleFieldsChange}
          onFinish={onFinish}
        >
          <BoxTop>
            <FormItem label={<span>Activity date</span>} name={'activityDate'}>
              <StyledRangePicker
                separator={
                  <SvgIcon name="arrow-right" width={16} height={16} />
                }
                placeholder={['From', 'To']}
                allowEmpty={[true, true]}
                disabledDate={disabledDate}
                format={FORMAT_DATE.DEFAULT}
                className={styles.datePicker}
                data-testid="activityDate"
              />
            </FormItem>
            <SelectInfiniteScroll
              items={companies}
              canLoadMore={canLoadMoreCompany}
              status={company.status || RequestStatus.Idle}
              name={'company'}
              dataTestId={'company'}
              label="Company"
              labelValue="customerName"
              keyValue="id"
              placeholder="Select company"
              loadMore={onScrollLoadmoreCompany}
              onInputSearch={onInputSearchCompany}
              onChange={onChangeCompany}
              onClear={onClearCompany}
            />

            <SelectInfiniteScroll
              items={users}
              canLoadMore={canLoadMoreUsers}
              status={usersByCompany.status || RequestStatus.Idle}
              name={'username'}
              dataTestId={'username'}
              label="Username"
              labelValue="name"
              keyValue="userId"
              labelGroup="customerName"
              keyGroup="users"
              placeholder="Select username"
              loadMore={onScrollLoadmoreUsername}
              onInputSearch={onInputSearchUsername}
              onChange={onChangeUsername}
            />
          </BoxTop>
          <BoxBottom>
            <BoxTop>
              <SelectInfiniteScroll
                items={categories}
                status={category.status || RequestStatus.Idle}
                name={'category'}
                dataTestId={'category'}
                label="Category"
                labelValue="name"
                keyValue="id"
                placeholder="Select category"
                showSearch={false}
                onChange={onChangeCategory}
              />

              <SelectInfiniteScroll
                items={types}
                status={type.status || RequestStatus.Idle}
                name={'type'}
                dataTestId={'type'}
                label="Type"
                placeholder="Select type"
                showSearch={false}
                labelValue="name"
                keyValue="id"
                labelGroup="label"
                keyGroup="options"
                onChange={onChangeType}
                disabled={!categoryValue || categoryValue?.length <= 0}
              />

              <SelectInfiniteScroll
                items={actions}
                status={action.status || RequestStatus.Idle}
                name={'action'}
                dataTestId={'action'}
                label="Action"
                placeholder="Select action"
                showSearch={false}
                labelValue="name"
                keyValue="id"
                labelGroup="label"
                keyGroup="options"
                disabled={!typeValue || typeValue?.length <= 0}
              />

              <SelectInfiniteScroll
                items={statusOptions}
                status={RequestStatus.Success}
                name={'status'}
                dataTestId={'status'}
                label="Status"
                placeholder="Select status"
                showSearch={false}
                labelValue="label"
                keyValue="value"
              />
            </BoxTop>
            <FormItems>
              <ClearAllButton
                title="Clear all"
                type="outline"
                dataTestId="btn-clear"
                disabled={isDisabledClearAll}
                onClick={onClearAll}
              />
              <ApplyButton
                type="filled"
                title="Apply"
                dataTestId="btn-apply"
                onClick={() => {
                  form.submit();
                }}
              />
            </FormItems>
          </BoxBottom>
        </StyledForm>
      )}
    </div>
  );
};

export default AuditLogFilter;
