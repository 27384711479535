import { FORMAT_DATE } from 'constants/index';
import { TransactionStatusApprovalLogs } from 'enum/transaction';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectReviewerByTxnIdData } from 'store/slices/transaction';
import WrapCard from '../WrapCard';

import styles from './index.module.scss';

type Props = {};

const Signer = (props: Props) => {
  const [reviews, setReviews] = useState([]);
  const reviewerData = useAppSelector(selectReviewerByTxnIdData)?.data;

  useEffect(() => {
    if (reviewerData && reviewerData?.reviewers) {
      setReviews(reviewerData?.reviewers);
    }
  }, [reviewerData]);

  const renderItem = (item, idx) => {
    const statusText = capitalize(
      TransactionStatusApprovalLogs[item.reviewStatus],
    );
    return (
      <div className={styles.content} key={`signer-${idx}`}>
        <div className={styles.title}>{item.role}</div>
        <div className={styles.info}>
          <div className={styles.item}>
            <div className={styles.item__title}>{statusText} by</div>
            <div className={styles.item__content}>
              {item.name}
              <div
                className={`${styles.status} ${
                  styles[TransactionStatusApprovalLogs[item.reviewStatus]]
                }`}
              >
                {statusText}
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item__title}>{statusText} date</div>
            <div className={styles.item__content}>
              {moment(item.createdAt).format(FORMAT_DATE.DATE_DMY_hM)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!reviews.length) return <></>;

  return (
    <>
      <WrapCard icon="signer" title="Rakkar Reviewers">
        <>
          {reviews.map((item, idx) => {
            return renderItem(item, idx);
          })}
        </>
      </WrapCard>
    </>
  );
};

export default Signer;
