import RButton from 'app/components/Button';
import { SvgIcon } from 'app/components/SvgIcon';
import styles from './index.module.scss';

interface Props {
  title: string;
  description: string;
  allowAdd?: boolean;
  icon?: string;
  buttonText?: string;
  onClickButton?: () => void;
}

const NotFound = ({
  title,
  description,
  allowAdd,
  icon = 'empty-data',
  buttonText,
  ...props
}: Props) => {
  return (
    <div className={styles.noAccount}>
      <SvgIcon name={icon} width="80" height="80" />
      <h3 className={styles.noAccount__title}>{title}</h3>
      <p className={styles.noAccount__description}>{description} </p>
      <div className={styles.buttons}>
        {allowAdd && (
          <div>
            <RButton
              buttonType="submit"
              content={
                <>
                  <SvgIcon
                    style={{ marginBottom: '2px', marginRight: '10px' }}
                    width={12}
                    height={12}
                    name="plus-icon"
                  />
                  {buttonText}
                </>
              }
              onClick={props.onClickButton}
              width={159}
              height={36}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NotFound;
