import { Button } from 'antd';
// import { SHAPE_TYPE, SIZE_TYPE } from 'enums/common';
import { ReactNode, MouseEvent, useState, useEffect } from 'react';
import styles from './index.module.scss';

export interface IButtonProps {
  title: ReactNode;
  type: 'filled' | 'outline' | 'transparent' | 'link' | 'secondary';
  disabled?: boolean;
  block?: boolean;
  danger?: boolean;
  ghost?: boolean;
  href?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  icon?: ReactNode;
  loading?: boolean | { delay: number };
  shape?: any;
  size?: any;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  dataTestId?: string;
  iconOnly?: boolean;
}

const BasicButton = (props: IButtonProps) => {
  const [cls, setCls] = useState('');
  const getClass = (type: string) => {
    switch (type) {
      case 'filled':
        setCls('btn--filled');
        break;
      case 'outline':
        setCls('btn--outline');
        break;
      case 'transparent':
        setCls('btn--transparent');
        break;
      case 'link':
        setCls('btn--link');
        break;
      case 'secondary':
        setCls('btn--secondary');
        break;
    }
  };

  useEffect(() => {
    getClass(props?.type || 'filled');
  }, [props?.type]);

  return (
    <Button
      className={`${styles.common} ${styles[cls]} ${props.className} ${
        props.iconOnly && styles.icon
      }`}
      type="primary"
      disabled={props.disabled}
      block={props.block}
      danger={props.danger}
      ghost={props.ghost}
      href={props.href}
      icon={props.icon}
      loading={props.loading}
      shape={props.shape}
      size={props.size || 'middle'}
      onClick={props.onClick}
      htmlType={props.htmlType}
      data-testid={props.dataTestId}
    >
      {props.title}
    </Button>
  );
};

export default BasicButton;
