import { Button, Tooltip } from 'antd';
import styles from './index.module.scss';
import clsx from 'clsx';
import {
  getShortWorkspaceTypeName,
  getWorkspaceTypeName,
} from 'utils/workspace.utils';

interface Props {
  productType: any;
}

const ProductButton = ({ productType }: Props) => {
  return (
    <>
      <Tooltip
        placement="bottom"
        className={styles.tooltip}
        title={getWorkspaceTypeName(productType.text)}
      >
        <Button
          data-testid="product-button"
          className={clsx(styles.button, {
            [styles.button__hot]: productType.title === 'Hot',
          })}
        >
          {getShortWorkspaceTypeName(productType.title)}
        </Button>
      </Tooltip>
    </>
  );
};

export default ProductButton;
