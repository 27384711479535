import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Drawer, Row, Tooltip } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import BasicButton from 'app/components/common/BasicButton';
import TextInfor, { dots } from 'app/components/common/TextInfor';
import clsx from 'clsx';
import { LOCKED_BY } from 'constants/customer';
import { FORMAT_DATE } from 'constants/index';
import { DOCUMENT_TYPE, USER_ROLES } from 'enum/common';
import { RoleUser, StatusUser } from 'enum/userList';
import { RequestStatus } from 'interfaces/request';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRoles } from 'store/slices/auth';
import {
  customerProfileActions,
  selectRemoveUserAccess,
  selectUserDetail,
} from 'store/slices/customerProfile';
import { lockByOpts } from 'utils/assets';
import { checkRoles, showRoleInfo } from 'utils/common';
import { formatDate } from 'utils/date';
import { getTextAddress, mapCountry } from 'utils/helpers';
import { history } from 'utils/history';
import CustomerOptionAction from './ActionPopover';
import PopupReplaceLostDevice from './PopupReplaceLostDevice';
import PopupShowCaseError from './PopupShowCaseError';
import styles from './index.module.scss';

interface Props {
  id: string;
  open?: boolean;
  onClose?: () => void;
}

export const status = (status: StatusUser) => {
  return {
    [StatusUser.ACTIVE]: {
      text: 'Active',
      className: 'status__active',
    },
    [StatusUser.INACTIVE]: {
      text: 'Inactive',
      className: 'status__inactive',
    },
    [StatusUser.WAITING_ON_BOARDING]: {
      text: 'Await Onboarding',
      className: 'status__awaitOnboarding',
    },
    [StatusUser.PENDING_VERIFY]: {
      text: 'Await Onboarding',
      className: 'status__awaitOnboarding',
    },
    [StatusUser.FAILED_ONBOARDING]: {
      text: 'Fail Onboarding',
      className: 'status__failOnboarding',
    },
  }[status as StatusUser];
};

const UserDetail = ({ id, open, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectRoles);
  const userDetailStore = useAppSelector(selectUserDetail);
  const [isOpenRemovePopup, setIsOpenRemovePopup] = useState(false);
  const [isOpenRequiredPopup, setOpenRequiredPopup] = useState(false);
  const [failedContent, setFailedContent] = useState<JSX.Element>(<></>);
  const lockBy = userDetailStore?.data?.lockedBy;

  const user = userDetailStore.data;
  const [isShowAllVault, setIsShowAllVault] = useState(false);
  const removeUserAccess = useAppSelector(selectRemoveUserAccess);
  const [isShowVaultViolated, setIsShowVaultViolated] = useState(false);
  const [isShowAllAdvancedVaults, setIsShowAllAdvancedVaults] = useState(false);
  const [isMaskedProfile, setIsMaskedProfile] = useState<boolean>(true);
  const loadingBtnRemove = removeUserAccess.status === RequestStatus.Loading;
  const vaultViolated = removeUserAccess && removeUserAccess.data.vaultList;

  let vaultQuantity = (user && user.vaults.length) || 0;
  let vaultFailedQuantity =
    (user && removeUserAccess.data?.vaultList.length) || 0;
  let showNumber = 10;
  let vaultNumberToShow = 5;
  const status = (status: StatusUser) => {
    return {
      [StatusUser.ACTIVE]: {
        text: 'Active',
        className: 'status__active',
      },
      [StatusUser.INACTIVE]: {
        text: 'Inactive',
        className: 'status__inactive',
      },
      [StatusUser.WAITING_ON_BOARDING]: {
        text: 'Await Onboarding',
        className: 'status__awaitOnboarding',
      },
      [StatusUser.PENDING_VERIFY]: {
        text: 'Await Onboarding',
        className: 'status__awaitOnboarding',
      },
      [StatusUser.FAILED_ONBOARDING]: {
        text: 'Failed Onboarding',
        className: 'status__failOnboarding',
      },
    }[status as StatusUser];
  };

  const title = useMemo(() => {
    return (
      <div className={styles.title}>
        <Row style={{ gap: 6, alignItems: 'center' }}>
          <div className={styles.content}>ID: {user?.userIdFormat}</div>
          <SvgIcon
            name={isMaskedProfile ? 'black-eye' : 'black-eye-off'}
            onClick={() => setIsMaskedProfile(!isMaskedProfile)}
            width={16}
            height={isMaskedProfile ? 12 : 16}
            style={{ cursor: 'pointer' }}
          />
        </Row>
        <div className={styles.icon}>
          <SvgIcon name="close" width={16} height={16} onClick={onClose} />
        </div>
      </div>
    );
  }, [onClose, user, isMaskedProfile]);

  useEffect(() => {
    dispatch(customerProfileActions.fetchUserDetailRequest({ id }));
  }, [id]);

  const isLoadingDetail = useMemo(() => {
    return userDetailStore.status === RequestStatus.Loading;
  }, [userDetailStore.status]);

  useEffect(() => {
    if (vaultQuantity > showNumber) setIsShowAllVault(false);
    else setIsShowAllVault(true);
  }, [vaultQuantity]);

  useEffect(() => {
    if (vaultFailedQuantity > vaultNumberToShow) setIsShowVaultViolated(false);
    else setIsShowVaultViolated(true);
  }, [vaultFailedQuantity]);

  useEffect(() => {
    if (removeUserAccess.status === RequestStatus.Success) {
      setIsOpenRemovePopup(false);
      dispatch(customerProfileActions.resetRemoveUserAccess());
    }
    if (
      removeUserAccess.data.isRequiredApprover.status === RequestStatus.Failed
    ) {
      setIsOpenRemovePopup(false);
      setOpenRequiredPopup(true);
      const failedElement =
        vaultViolated.length === 0 ? (
          <div>This user is a required approver in account admin policy.</div>
        ) : (
          <div>
            This user is a required approver in vault policy for
            {renderVaultViolated}
          </div>
        );
      setFailedContent(failedElement);
      dispatch(customerProfileActions.resetRemoveUserAccess());
    }

    if (
      removeUserAccess.data.isRequiredApproverAndVault.status ===
      RequestStatus.Failed
    ) {
      setIsOpenRemovePopup(false);
      setOpenRequiredPopup(true);
      const failedElement = (
        <div>
          This user is a required approver in account admin policy and vault
          policy for
          {renderVaultViolated}
        </div>
      );
      setFailedContent(failedElement);
      dispatch(customerProfileActions.resetRemoveUserAccess());
    }

    if (
      removeUserAccess.data.isUnmetQuorumAndVault.status ===
      RequestStatus.Failed
    ) {
      setIsOpenRemovePopup(false);
      setOpenRequiredPopup(true);
      const failedElement = (
        <div>
          You cannot remove access of this user due to unmet minimum quorum size
          for account admin policy and vault policy.
          {renderVaultViolated}
        </div>
      );
      setFailedContent(failedElement);
      dispatch(customerProfileActions.resetRemoveUserAccess());
    }

    if (
      removeUserAccess.data.isValidQuromSize.status === RequestStatus.Failed
    ) {
      setIsOpenRemovePopup(false);
      setOpenRequiredPopup(true);
      const failedElement =
        vaultViolated.length === 0 ? (
          <div>
            You cannot remove access of this user due to unmet minimum quorum
            size for account admin policy.
          </div>
        ) : (
          <div>
            You cannot remove access of this user due to unmet minimum quorum
            size for vault policy.
            {renderVaultViolated}
          </div>
        );
      setFailedContent(failedElement);
      dispatch(customerProfileActions.resetRemoveUserAccess());
    }
    //user is in advanced quorum vaults
    if (
      removeUserAccess.data.isInAdvancedVaults.status === RequestStatus.Failed
    ) {
      setIsOpenRemovePopup(false);
      setOpenRequiredPopup(true);
      const failedElement = (
        <div>
          This user is a part of advanced vaults for
          {renderVaultViolated}
        </div>
      );
      setFailedContent(failedElement);

      dispatch(customerProfileActions.resetRemoveUserAccess());
    }
  }, [
    removeUserAccess.status,
    removeUserAccess.data.isValidQuromSize.status,
    removeUserAccess.data.isRequiredApprover.status,
    removeUserAccess.data.isInAdvancedVaults.status,
    removeUserAccess.data.vaultList,
    isShowVaultViolated,
    isShowAllAdvancedVaults,
  ]);

  const PopupRemoveUser = PopupReplaceLostDevice;

  const handleViewRequest = useCallback(() => {
    if (!user) {
      return;
    }
    history.replace({
      pathname: '/pending-request',
      search: `id=${user.pendingRequestId}`,
    });
  }, [user]);

  const renderVault = (name: string) => {
    return (
      <Row className={styles.vault}>
        <div className={styles.vault__icon}>
          <SvgIcon name="vault" width={14} height={17} />
        </div>
        <span className={styles.vault__name}>{name}</span>
      </Row>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderVaultConditionaly = (vaultPolicyViolated?) => {
    if (vaultPolicyViolated) {
      if (!removeUserAccess) return;
      if (
        vaultViolated.length <= vaultNumberToShow ||
        isShowAllAdvancedVaults
      ) {
        return removeUserAccess.data.vaultList.map(vault => (
          <div key={vault.id}>{renderVault(vault.vaultName)}</div>
        ));
      } else {
        const vaultViolated: JSX.Element[] = [];
        for (let index = 0; index < vaultNumberToShow; index++) {
          const vaultInfo = removeUserAccess.data.vaultList[index];
          vaultViolated.push(renderVault(vaultInfo?.vaultName));
        }
        return vaultViolated;
      }
    }

    if (!user) return;
    if (vaultQuantity <= showNumber || isShowAllVault) {
      return user.vaults.map(vault => (
        <div key={vault.id}>{renderVault(vault.name)}</div>
      ));
    } else {
      let returnVault: JSX.Element[] = [];
      for (let index = 0; index < showNumber; index++) {
        let vault = user.vaults[index];
        returnVault.push(renderVault(vault.name));
      }
      return returnVault;
    }
  };

  const renderVaultViolated = useMemo(() => {
    return (
      <>
        <Row
          className={clsx(styles.sectionVaults, {
            [styles.sectionVaultShowAll]: !isShowAllAdvancedVaults,
            [styles.allVaults]: !!isShowAllAdvancedVaults,
          })}
          data-testid="vaults"
        >
          {renderVaultConditionaly(vaultViolated)}
          <div
            className={clsx({
              [styles.vaultsShadow]:
                !isShowAllAdvancedVaults && !isShowVaultViolated,
            })}
          />
        </Row>
        {!isShowVaultViolated && (
          <Row>
            <div
              className={clsx(styles['showAll'])}
              onClick={() => {
                setIsShowAllAdvancedVaults(!isShowAllAdvancedVaults);
              }}
            >
              {!isShowAllAdvancedVaults
                ? `Show all ${vaultViolated.length} vaults`
                : 'Show less'}
            </div>
            <SvgIcon
              name={isShowAllAdvancedVaults ? 'chevron-up' : 'chevron-down'}
              width={14}
              height={14}
              className={styles.arrowIcon}
            />
          </Row>
        )}
      </>
    );
  }, [
    isShowVaultViolated,
    renderVaultConditionaly,
    vaultViolated,
    isShowAllAdvancedVaults,
  ]);

  const lockedByValue = useMemo(() => {
    return (
      (!!userDetailStore &&
        lockByOpts?.find(item => item.value === userDetailStore.data?.lockedBy)
          ?.label) ||
      ''
    );
  }, [userDetailStore]);

  const isOperation = useMemo(() => {
    return checkRoles(USER_ROLES.OPERATION, roles);
  }, [roles]);

  const isDisabledButtonRemove = useMemo(() => {
    if (!isOperation) return true;
    if (!!userDetailStore.data?.isPendingRequest) return true;
    return false;
  }, [userDetailStore.data, isOperation]);

  const handleRemoveUserAccess = () => {
    dispatch(
      customerProfileActions.removeUserAccessRequest({
        userId: id,
        params: {
          isRemoveAccountAccess: true,
        },
      }),
    );
  };

  const confirmPopup = () => {
    if (isOpenRequiredPopup) {
      setOpenRequiredPopup(false);
      dispatch(customerProfileActions.resetremoveUserAccessFailedRequired());
      dispatch(
        customerProfileActions.resetRemoveUserAccessFailedAdvancedVault(),
      );
      dispatch(customerProfileActions.resetRemoveUserAccessFailedQuorum());
      dispatch(customerProfileActions.resetremoveUserFailedRequiredAndVault());
      dispatch(customerProfileActions.resetremoveUserUnmetQuorumAndVault());

      setIsShowAllAdvancedVaults(false);
    }
  };

  const footer = (
    <div className={styles.footer}>
      <div
        className={styles.button}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '-24px',
          paddingBottom: '0',
        }}
      >
        <BasicButton
          onClick={() => setIsOpenRemovePopup(true)}
          title="Remove access"
          type="outline"
          className={styles.button__removeAccess}
          disabled={isDisabledButtonRemove}
          dataTestId="remove-access"
        />
      </div>
    </div>
  );

  const Spinner = (
    <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
  );

  const content = useMemo(() => {
    return (
      <>
        {isLoadingDetail || !user ? (
          <div className={styles.loading}>{Spinner}</div>
        ) : (
          <>
            <div className={styles.top}>
              <Row
                style={{
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {isMaskedProfile ? (
                  <div>{dots(16)}</div>
                ) : (
                  <p className={styles.name}>{`${user.name}`}</p>
                )}

                {!!user.pendingRequestId && (
                  <div className={styles.description}>
                    <span className={styles.description__text}>
                      This user has a pending request awaiting for approval
                    </span>
                    <span
                      className={styles.description__viewRequest}
                      onClick={handleViewRequest}
                    >
                      View request
                    </span>
                  </div>
                )}
              </Row>
              {isOperation && <CustomerOptionAction id={id} />}
            </div>
            <div className={styles.container}>
              <div className={styles.header}>
                <SvgIcon
                  className={styles.icon}
                  name="menu-icon"
                  width={16}
                  height={16}
                />
                <div className={styles.heading}>User information</div>
              </div>
              <div className={styles.content}>
                <Row style={{ marginTop: '20px' }}>
                  {user.status && (
                    <TextInfor
                      label="Status"
                      text={
                        <Row>
                          <div
                            className={clsx(
                              styles.status,
                              styles[status(user.status)?.className],
                            )}
                          >
                            {status(user.status)?.text}
                          </div>
                          {lockBy !== LOCKED_BY.NONE && (
                            <Tooltip
                              placement="bottom"
                              title={'By ' + lockedByValue}
                            >
                              <div
                                className={clsx(
                                  styles.status,
                                  styles['status__locked'],
                                )}
                              >
                                {'Locked'}
                                <SvgIcon
                                  name="status_lock"
                                  width={12}
                                  height={12}
                                  style={{
                                    marginRight: '-3px',
                                    marginLeft: '3px',
                                  }}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </Row>
                      }
                      dataTestId="status"
                    />
                  )}
                  {user.roleName && (
                    <TextInfor
                      label="Role"
                      text={
                        <Row>
                          <div
                            className={clsx(
                              styles.role,
                              styles[showRoleInfo(user.roleName).className],
                            )}
                          >
                            {showRoleInfo(user.roleName).text}
                          </div>
                          {user.roleName === RoleUser.ADMIN &&
                            user.requiredApprover && (
                              <div className={styles['role__admin--required']}>
                                <span className={styles['role__text']}>
                                  required approver
                                </span>{' '}
                                <SvgIcon
                                  width={10}
                                  height={12}
                                  name="required-approver"
                                  className={styles['role__icon']}
                                />
                              </div>
                            )}
                        </Row>
                      }
                      dataTestId="role"
                    />
                  )}
                </Row>

                {lockBy !== LOCKED_BY.NONE && (
                  <TextInfor
                    label="Remark"
                    text={
                      <Row>
                        {lockedByValue ? (
                          <div className={styles.body}>
                            {'This user account has been locked by '}
                            {lockedByValue}
                          </div>
                        ) : (
                          '-'
                        )}
                      </Row>
                    }
                    dataTestId="status"
                  />
                )}

                {/* LEGAL INFORMATION */}
                <div>
                  <Divider
                    orientationMargin="0"
                    className={styles.content__header}
                    orientation="left"
                  >
                    Legal information
                  </Divider>
                  <Row className={styles.body}>
                    {user?.documentType === DOCUMENT_TYPE.PASSPORT && (
                      <>
                        <TextInfor
                          label="Passport Number"
                          text={user?.passportNumber}
                          dataTestId="passpord-number"
                          isMaskable={true}
                          isMask={isMaskedProfile}
                        />
                        <TextInfor
                          label="Country of issued passport"
                          text={mapCountry(user?.passportIssuedCountry || '')}
                          dataTestId="passpord-country"
                        />
                      </>
                    )}
                    {user?.documentType === DOCUMENT_TYPE.ID_CARD && (
                      <>
                        <TextInfor
                          label="ID Number"
                          text={user?.idCardNumber}
                          dataTestId="id-number"
                          isMaskable={true}
                          isMask={isMaskedProfile}
                        />
                        <TextInfor
                          label="Country of issued ID card"
                          text={mapCountry(user?.idCardIssuedCountry || '')}
                          dataTestId="passpord-country"
                        />
                      </>
                    )}
                    <TextInfor
                      label="Date of Birth"
                      text={
                        user.dateOfBirth &&
                        formatDate(user.dateOfBirth, FORMAT_DATE.DEFAULT)
                      }
                      dataTestId="date-of-birth"
                      isMaskable={true}
                      isMask={isMaskedProfile}
                    />
                    <TextInfor
                      label="Nationality"
                      text={
                        user.nationality ? mapCountry(user.nationality) : ''
                      }
                      dataTestId="nationality"
                    />
                  </Row>
                </div>

                {/* Contact Information */}
                <div>
                  <Divider
                    orientationMargin="0"
                    className={styles.content__header}
                    orientation="left"
                  >
                    Contact Information
                  </Divider>
                  <Row className={styles.body}>
                    <TextInfor
                      label="Phone number"
                      text={user?.phoneNumber}
                      dataTestId="phone-number"
                      isMaskable={true}
                      isMask={isMaskedProfile}
                    />
                    <TextInfor
                      label="Email"
                      text={user.email}
                      dataTestId="email"
                      isMaskable={true}
                      isMask={isMaskedProfile}
                    />
                    <TextInfor
                      label="Address"
                      text={getTextAddress(user.address)}
                      dataTestId="address"
                      isMaskable={true}
                      isMask={isMaskedProfile}
                    />
                  </Row>
                </div>

                {/* Device information */}
                <div>
                  <Divider
                    orientationMargin="0"
                    className={styles.content__header}
                    orientation="left"
                  >
                    Device information
                  </Divider>
                  <Row className={styles.body}>
                    <TextInfor
                      label="Device"
                      text={
                        <div className={styles.device}>
                          <span>{user.device?.deviceName ?? '-'}</span>
                          {user.device?.isJailbreak && (
                            <Tooltip title="Jailbroken" placement="bottom">
                              <SvgIcon
                                className={styles.device__iconSvg}
                                name="jailbreak-device"
                                width={16}
                                height={16}
                              />
                            </Tooltip>
                          )}
                          {user.isLostDevice && (
                            <Tooltip title="Lost device" placement="bottom">
                              <SvgIcon
                                className={styles.device__iconSvg}
                                name="lost-device"
                                width={16}
                                height={16}
                              />
                            </Tooltip>
                          )}
                        </div>
                      }
                      dataTestId="device"
                    />
                    {user?.status === StatusUser.ACTIVE && (
                      <TextInfor
                        label="Device registered date"
                        text={user?.device?.registeredDate || '-'}
                        dataTestId="device-registered-date"
                      />
                    )}
                    {user.isLostDevice && (
                      <TextInfor
                        label="Marked as lost date"
                        text={formatDate(
                          user?.markedAsLostDeviceDate,
                          FORMAT_DATE.DEFAULT,
                        )}
                        dataTestId="mark-as-lost-date"
                      />
                    )}
                  </Row>
                </div>

                {/* Vault access */}

                <Divider
                  orientationMargin="0"
                  className={styles.content__header}
                  orientation="left"
                >
                  Vault access
                </Divider>
                <div>
                  <Row
                    className={clsx(styles.sectionVaults, {
                      [styles.sectionVaultShowAll]: !isShowAllVault,
                    })}
                    data-testid="vaults"
                  >
                    {renderVaultConditionaly()}
                    <div
                      className={clsx({
                        [styles.vaultsShadow]: !isShowAllVault,
                      })}
                    />
                  </Row>
                  {!isShowAllVault && (
                    <div
                      className={clsx(styles['showAll'])}
                      onClick={() => setIsShowAllVault(true)}
                    >
                      Show all {user.vaults.length} vaults
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'end',
              }}
            >
              {footer}
            </div>
            <PopupRemoveUser
              isOpen={isOpenRemovePopup}
              setIsOpen={setIsOpenRemovePopup}
              title="You confirm to submit the remove user access request?"
              content="After the request has been approved, the customer will no longer have access to their organization on Rakkar"
              loading={loadingBtnRemove}
              handleConfirm={handleRemoveUserAccess}
            />
            <PopupShowCaseError
              isOpen={isOpenRequiredPopup}
              setIsOpen={setOpenRequiredPopup}
              title="Cannot remove user"
              contentError={failedContent}
              handleConfirm={confirmPopup}
            />
          </>
        )}
      </>
    );
  }, [
    isLoadingDetail,
    isShowAllVault,
    renderVaultConditionaly,
    user,
    isShowVaultViolated,
    isShowAllAdvancedVaults,
  ]);

  return (
    <div className={styles.container}>
      <Drawer
        width={1000}
        placement="right"
        onClose={onClose}
        forceRender
        maskClosable={true}
        open={open}
        keyboard={false}
        title={title}
        closable={false}
        className={styles.drawerProfile}
        // footer={isLoadingDetail ? null : [footer]}
      >
        {content}
      </Drawer>
    </div>
  );
};

export default UserDetail;
