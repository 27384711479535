export enum DynamicCondition {
  OR = 'OR',
  AND = 'AND',
}

export enum DynamicOperation {
  IS = 'IS',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  LT = 'LT',
  LTE = 'LTE',
  GT = 'GT',
  GTE = 'GTE',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  NOT_ENDS_WITH = 'NOT_ENDS_WITH',
}

export interface IRakCRUDBaseControllerConfig {
  condition?: DynamicCondition[];
  filter?: {
    [key: string]: {
      label?: string;
      key?: string;
      options: {
        operation: DynamicOperation;
        options?: {
          key: string;
          label: string;
        }[];
      }[];
    };
  };
}
