import { LoadingOutlined } from '@ant-design/icons';
import { Form, Select, Spin } from 'antd';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { Params, RequestStatus } from 'interfaces/request';
import { TransactionParamsRequest } from 'interfaces/transactions';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectAsset,
  selectAssetParams,
  selectCanLoadMoreAssets,
  transactionsActions,
} from 'store/slices/transactions';

import styles from './index.module.scss';

interface Props {
  selectedAsset?: any;
}

const SelectAssetFilterTxn = ({ selectedAsset }: Props) => {
  const data = useAppSelector(selectAsset);
  const assetParams = useAppSelector(selectAssetParams);
  const canLoadMoreAssets = useAppSelector(selectCanLoadMoreAssets);

  const [assets, setAsset] = useState<any>([]);
  const refSelect = useRef<any>();
  const [loading, setLoading] = useState(false);
  const refCanLoadMoreAsset = useRef(canLoadMoreAssets);

  const dispatch = useAppDispatch();

  useEffect(() => {
    refCanLoadMoreAsset.current = canLoadMoreAssets;
  }, [canLoadMoreAssets]);

  useEffect(() => {
    dispatch(
      transactionsActions.getAssetsRequest({
        params: assetParams,
      }),
    );
  }, [dispatch, assetParams]);

  useEffect(() => {
    if (data.status === RequestStatus.Loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (data?.data?.tokens || data?.data?.wallets) {
      setAsset(data?.data?.tokens || data?.data?.wallets);
    }
  }, [data?.data?.tokens, data?.data?.wallets]);

  const setValueAssetParams = (key: keyof TransactionParamsRequest) => {
    return (
      value: TransactionParamsRequest[keyof TransactionParamsRequest],
    ) => {
      dispatch(
        transactionsActions.setAssetParams({
          key,
          value,
        }),
      );
    };
  };

  //source params
  const setKeywordAssetParams = setValueAssetParams(Params.KeyWord);
  const setOffsetAssetParams = setValueAssetParams(Params.Offset);

  const handleSearch = (value: string) => {
    setOffsetAssetParams(0);
    setKeywordAssetParams(value);
  };

  const deboundSearchOpts = debounce(async (value: string) => {
    return handleSearch(value);
  }, 500);

  const onSearchOpts = (newValue: string) => {
    const value = newValue || '';
    deboundSearchOpts(value);
  };

  const onScroll = event => {
    const target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      if (refCanLoadMoreAsset.current) {
        const currentOffset = (Number(assetParams.offset) || 0) + 10;
        setOffsetAssetParams(currentOffset);
      }
    }
  };

  const iconLoading = <LoadingOutlined style={{ fontSize: 14 }} spin />;

  return (
    <div className={styles.box}>
      <Form.Item label="Asset" name={'assetId'}>
        <Select
          mode="multiple"
          showArrow
          className={styles.asset}
          ref={refSelect}
          loading={loading}
          popupClassName={styles.popup}
          placeholder={'Asset'}
          filterOption={false}
          onSearch={onSearchOpts}
          onPopupScroll={onScroll}
          onBlur={() => {
            setKeywordAssetParams('');
          }}
          autoClearSearchValue={false}
          showSearch={true}
          disabled={selectedAsset}
          allowClear
          suffixIcon={
            loading ? (
              <Spin indicator={iconLoading} />
            ) : (
              <SvgIcon name="chevron-down" width={16} height={16} />
            )
          }
        >
          {assets.length &&
            assets.map((item: any) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  <span>{item.name}</span>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
    </div>
  );
};

export default SelectAssetFilterTxn;
