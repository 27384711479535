import { Button, Checkbox, Col, DatePicker, Form, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ModalLocation } from 'app/pages/AddCustomer/BussinessInformation/ModalLocation';
import { addressDefault } from 'constants/customer';
import {
  FORMAT_DATE,
  REGEX_ALLOW_AZ_09,
  REGEX_ALLOW_AZ_09_SPACE,
} from 'constants/index';
import {
  AddressType,
  CompanyType,
  PurposeOfRelationship,
  SourceOfFunds,
  SourceOfWealth,
} from 'enum/addNewCustomer';
import useMessage from 'hooks/useMessage';
import { Address, BusinessTypeInterface } from 'interfaces/businessInformation';
import { CheckBusinessAndCountryRequest } from 'interfaces/customers';
import _, { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch } from 'react-redux';
import {
  checkBothBusinessIdAndCountry,
  checkShortNameCustomer,
} from 'services/addNewCustomer';
import { AppState } from 'store';
import { useAppSelector } from 'store/hooks';
import {
  newCustomerActions,
  selectBussinessInformation,
  selectOldBussinessInformation,
} from 'store/slices/addNewCustomer';
import {
  selectCustomerEntityRelation,
  selectSourceOfFund,
  selectTypeOfCompany,
} from 'store/slices/common';
import { countryData } from 'utils/assets';
import {
  listCountryForPhoneNumber,
  validateMessages,
  validateRegex,
} from 'utils/common';
import { handleNumberKeyDown } from 'utils/event.utils';
import { getTextAddress, mapCountry } from 'utils/helpers';
import BasicButton from '../common/BasicButton';
import BasicInput from '../common/BasicInput';
import BasicModal from '../common/BasicModal';
import BasicSelect from '../common/BasicSelect';
import { SvgIcon } from '../common/SvgIcon';
import {
  addressInfoDefault,
  latestRiskLevelOpts,
  purposeOfRelationshipOpts,
  sourceOfWealthOpts,
} from './index.helper';
import styles from './index.module.scss';
import clsx from 'clsx';
import { WalletInfoStatus } from 'enum/common';

type Props = {
  form: any;
  next: () => void;
  blockCurrentAddress: boolean;
  setBlockCurrentAddress: (value: boolean) => void;
  blockBillingAddress: boolean;
  setBlockBillingAddress: (value: boolean) => void;
  onFieldsChange?: (disabledNext: boolean) => void;
  onCheckBusineesIdAndCountry?: (value: boolean) => void;
  isEditCustomer?: boolean;
  isCheckCountry?: boolean;
};
const defaultCountryCode = '+65';

type Item = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

export const checkBusinessTypeOther = (id: number, businessTypes) => {
  const isOtherBusinessType = businessTypes?.findIndex(item => {
    return item.id === id && item.name === 'Other (please specify)';
  });
  return isOtherBusinessType !== -1;
};

const BusinessInformation = ({
  form,
  next,
  blockCurrentAddress,
  blockBillingAddress,
  setBlockCurrentAddress,
  setBlockBillingAddress,
  onFieldsChange,
  onCheckBusineesIdAndCountry,
  isEditCustomer,
  isCheckCountry = true,
}: Props) => {
  const msg = 'Not allow to input special characters';
  const dispatch = useDispatch();
  const businessInformation = useAppSelector(selectBussinessInformation);
  const oldBusinessInformation = useAppSelector(selectOldBussinessInformation);

  const { addMessage } = useMessage();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [keyForm, setKeyForm] = useState<AddressType>(AddressType.REGISTRATION);
  const [resgistrationField, setRegistrationField] = useState('');
  const [currentField, setCurrentField] = useState('');
  const [billingField, setBillingField] = useState('');

  const bussinessTypesStore = useAppSelector(
    (state: AppState) => state.common.businessTypes,
  );

  const [businessTypes, setBusinessTypes] = useState<BusinessTypeInterface[]>(
    [],
  );
  const customerEntityRelationStore = useAppSelector(
    selectCustomerEntityRelation,
  );

  const typeOfCompanyStore = useAppSelector(selectTypeOfCompany);

  const sourceOfFundStore = useAppSelector(selectSourceOfFund);

  const [customerEntityRelation, setCustomerEntityRelation] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  const [typeOfCompany, setTypeOfCompany] = useState<Item[]>([]);

  const [listSourceOfFund, setListSourceOfFund] = useState<Item[]>([]);

  const [addressInfo, setAddressInfo] = useState<{
    registrationAddress: Address;
    currentAddress: Address;
    billingAddress: Address;
  }>(addressInfoDefault);
  const [dataModal, setDataModal] = useState<Address>(addressDefault);
  const [showErrPhone, setShowErrPhone] = useState(false);
  const [showOtherPersonErrPhone, setShowOtherPersonErrPhone] = useState(false);
  const [showBillingPersonErrPhone, setShowBillingPersonErrPhone] =
    useState(false);
  const [addContactPerson, setAddContactPerson] = useState(
    form?.getFieldValue('otherPerson')?.firstName ? true : false,
  );
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const [otherPersonCountryCode, setOtherPersonCountryCode] =
    useState(defaultCountryCode);
  const [billingPersonCountryCode, setBillingPersonCountryCode] =
    useState(defaultCountryCode);

  const [isShowPopupError, setIsShowPopupError] = useState<boolean>(false);
  const [otherBusinessSelected, setOtherBusinessSelected] = useState(false);
  const [otherCompanySelected, setOtherCompanySelected] = useState(false);
  const [otherSourceOfWealthSelected, setOtherSourceOfWealthSelected] =
    useState(false);

  const [
    otherPurposeOfRelationshipSelected,
    setOtherPurposeOfRelationshipSelected,
  ] = useState(false);

  const [otherSourceOfFundSelected, setOtherSourceOfFundSelected] =
    useState(false);

  const countries = listCountryForPhoneNumber() || [];

  useEffect(() => {
    form.setFieldsValue({
      ...businessInformation,
      sourceOfFunds: businessInformation.sourceOfFunds
        ? businessInformation.sourceOfFunds.split(',')
        : [],
    });
    if (businessInformation?.dateOfLastestRiskLevel) {
      form.setFieldsValue({
        dateOfLastestRiskLevel: moment(
          businessInformation.dateOfLastestRiskLevel,
        ),
      });
    }
    if (businessInformation?.registrationDate) {
      form.setFieldsValue({
        registrationDate: moment(businessInformation.registrationDate),
      });
    }

    if (businessInformation?.countryCode && businessInformation?.phoneNumber) {
      form.setFieldsValue({
        phoneNumber: `${businessInformation.countryCode}${businessInformation.phoneNumber}`,
      });
      setCountryCode(businessInformation.countryCode);
    }

    if (businessInformation?.companyType === CompanyType.OTHER) {
      setOtherCompanySelected(true);
    }

    if (businessInformation?.sourceOfWealth === SourceOfWealth.OTHER) {
      setOtherSourceOfWealthSelected(true);
    }

    if (
      businessInformation?.purposeOfRelationship === PurposeOfRelationship.OTHER
    ) {
      setOtherPurposeOfRelationshipSelected(true);
    }
    if (businessInformation?.sourceOfFunds?.includes(SourceOfFunds.OTHER)) {
      setOtherSourceOfFundSelected(true);
    }

    setAddressInfo({
      registrationAddress: businessInformation?.registrationAddress,
      currentAddress: businessInformation?.currentAddress,
      billingAddress: businessInformation?.billingAddress,
    });

    if (
      businessInformation?.otherPerson?.countryCode &&
      businessInformation?.otherPerson?.phoneNumber
    ) {
      const orginalOtherPerson = form.getFieldValue('otherPerson');
      form.setFieldsValue({
        otherPerson: {
          ...orginalOtherPerson,
          phoneNumber: `${businessInformation.otherPerson.countryCode}${businessInformation.otherPerson.phoneNumber}`,
        },
      });
      setOtherPersonCountryCode(businessInformation.otherPerson.countryCode);
      setAddContactPerson(true);
    }

    if (
      businessInformation?.billingPerson?.countryCode &&
      businessInformation?.billingPerson?.phoneNumber
    ) {
      form.setFieldsValue({
        billingPerson: {
          phoneNumber: `${businessInformation.billingPerson.countryCode}${businessInformation.billingPerson.phoneNumber}`,
        },
      });
      setBillingPersonCountryCode(
        businessInformation.billingPerson.countryCode,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isOtherBusinessType = checkBusinessTypeOther(
      Number(businessInformation?.businessTypeId),
      businessTypes,
    );
    if (isOtherBusinessType) {
      setOtherBusinessSelected(true);
    }
  }, [businessTypes]);

  useEffect(() => {
    if (bussinessTypesStore.data) {
      setBusinessTypes(bussinessTypesStore.data);
    }
  }, [bussinessTypesStore]);

  useEffect(() => {
    if (customerEntityRelationStore?.data) {
      const options = customerEntityRelationStore.data.map(item => {
        return { label: mapCountry(item.countryCode), value: item.id };
      });
      setCustomerEntityRelation(options);
    }
  }, [customerEntityRelationStore]);

  useEffect(() => {
    if (typeOfCompanyStore?.data) {
      let sorted_array = _.sortBy(typeOfCompanyStore.data, ['status'], ['asc']);
      const options = sorted_array?.map(item => {
        return {
          label: item.name,
          value: item.id,
          isDisabled: item.status === WalletInfoStatus.DEACTIVE,
        };
      });
      setTypeOfCompany(options);
    }
  }, [typeOfCompanyStore]);

  useEffect(() => {
    if (sourceOfFundStore?.data) {
      const options = sourceOfFundStore.data.map(item => {
        return {
          label: item.name,
          value: item.id,
          isDisabled: item.status === 'INACTIVE',
        };
      });
      setListSourceOfFund(options);
    }
  }, [sourceOfFundStore]);

  useEffect(() => {
    if (otherBusinessSelected || otherCompanySelected) {
      const data = form.getFieldsValue();
      const isEmpty = checkIsEmpty(data);
      onFieldsChange?.(isEmpty);
    }
  }, [otherBusinessSelected, otherCompanySelected]);

  const handleOpenModalLocation = useCallback(
    key => {
      let values;
      if (key === AddressType.REGISTRATION) {
        values = addressInfo.registrationAddress;
      } else if (key === AddressType.CURRENT) {
        values = addressInfo.currentAddress;
      } else {
        values = addressInfo.billingAddress;
      }
      setDataModal(values);
      setKeyForm(key);
      setOpenModal(true);
    },
    [addressInfo],
  );

  const handleCancel = () => {
    setDataModal(addressDefault);
    setOpenModal(false);
  };

  const handleAddLocation = useCallback(
    (values: any, keyForm: AddressType) => {
      const text = getTextAddress(values);
      setOpenModal(false);

      if (keyForm === AddressType.REGISTRATION) {
        setAddressInfo({ ...addressInfo, registrationAddress: values });
        form.setFieldsValue({ registration: [text] });
        setRegistrationField(text);
      } else if (keyForm === AddressType.CURRENT) {
        setAddressInfo({ ...addressInfo, currentAddress: values });
        form.setFieldsValue({ current: [text] });
        setCurrentField(text);
      } else if (keyForm === AddressType.BILLING) {
        setAddressInfo({ ...addressInfo, billingAddress: values });
        form.setFieldsValue({ billing: [text] });
        setBillingField(text);
      }
      const data = form.getFieldsValue();
      const isEmpty = checkIsEmpty(data);
      onFieldsChange?.(isEmpty);
    },
    [addressInfo, form],
  );

  const formatDate = (date: Moment) => {
    if (!date) return;
    return moment(date).format(FORMAT_DATE.DATE_YMD);
  };

  const handleClearOtherPerson = () => {
    form.setFieldValue('otherPerson', undefined);
    setAddContactPerson(false);
  };

  const handleFinish = async (values: any) => {
    const dateOfLastestRiskLevel = formatDate(values.dateOfLastestRiskLevel);
    const registrationDate = formatDate(values.registrationDate);

    const formatPhoneNumber = values.phoneNumber.replace(countryCode, '');

    const newValues: any = {
      ...addressInfo,
      ...values,
      dateOfLastestRiskLevel: dateOfLastestRiskLevel,
      registrationDate: registrationDate,
      countryCode: countryCode,
      phoneNumber: formatPhoneNumber,
      billingPerson: {
        ...values.billingPerson,
        countryCode: billingPersonCountryCode,
        phoneNumber: values.billingPerson.phoneNumber.replace(
          billingPersonCountryCode,
          '',
        ),
      },
      ...(addContactPerson && {
        otherPerson: {
          ...values.otherPerson,
          countryCode: otherPersonCountryCode,
          phoneNumber: values.otherPerson.phoneNumber.replace(
            otherPersonCountryCode,
            '',
          ),
        },
      }),
      sourceOfFunds: values.sourceOfFunds.join(','),
    };

    if (isCheckCountry) {
      const res = await handleCheckUniqBothBusinessIdAndCountry({
        businessRegistrationId: newValues.businessRegistrationId,
        country: addressInfo?.registrationAddress?.country,
      });
      if (res) {
        setIsShowPopupError(true);
        return;
      }
    }
    dispatch(newCustomerActions.updateBussinessInformation(newValues));
    next();
  };

  const handleFillCurrentAddress = (checked: CheckboxChangeEvent) => {
    const stt = checked.target.checked;
    if (!addressInfo.registrationAddress.address) {
      setBlockCurrentAddress(stt);
      return;
    }
    setBlockCurrentAddress(stt);
    if (stt) {
      const text = getTextAddress(addressInfo.registrationAddress);
      setAddressInfo({
        ...addressInfo,
        currentAddress: addressInfo.registrationAddress,
      });
      form.setFieldsValue({ current: [text] });
      setCurrentField(text);
    } else {
      form.setFieldsValue({ current: undefined });
      setAddressInfo({
        ...addressInfo,
        currentAddress: addressDefault,
      });
      setCurrentField('');
    }
  };

  const handleFillBillingAddress = (checked: CheckboxChangeEvent) => {
    const stt = checked.target.checked;
    if (!addressInfo.registrationAddress.address) {
      setBlockBillingAddress(stt);
      return;
    }
    setBlockBillingAddress(stt);
    if (stt) {
      const text = getTextAddress(addressInfo.registrationAddress);
      setAddressInfo({
        ...addressInfo,
        billingAddress: addressInfo.registrationAddress,
      });
      form.setFieldsValue({ billing: [text] });
      setBillingField(text);
    } else {
      form.setFieldsValue({ billing: undefined });
      setAddressInfo({
        ...addressInfo,
        billingAddress: addressDefault,
      });
      setBillingField('');
    }
  };

  const handleCheckUniq = async (value: string) => {
    try {
      const checkUniq = await checkShortNameCustomer({
        customerShortName: value,
      });
      if (checkUniq.exist) {
        form.setFields([
          {
            name: 'customerShortName',
            errors: ['The short name is duplicated'],
          },
        ]);
      }
    } catch (error: any) {
      addMessage('error', error?.message);
    }
  };

  const handleCheckUniqBothBusinessIdAndCountry = async (
    params: CheckBusinessAndCountryRequest,
  ) => {
    onCheckBusineesIdAndCountry?.(true);
    try {
      const { exist } = await checkBothBusinessIdAndCountry(params);
      onCheckBusineesIdAndCountry?.(false);
      return exist;
    } catch (error: any) {
      addMessage('error', error?.message);
    } finally {
      onCheckBusineesIdAndCountry?.(false);
    }
  };

  const deboundCheckShortNameUnique = debounce(async (value: string) => {
    return handleCheckUniq(value);
  }, 500);

  const checkIsEmpty = useCallback(
    (values: any) => {
      const validOtherPerson = () => {
        if (!addContactPerson) return false;

        return (
          !values?.otherPerson ||
          Object.values(values?.otherPerson).includes('') ||
          Object.values(values?.otherPerson).includes(undefined) ||
          Object.values(values).some(value => !value)
        );
      };

      const validBillingPerson = () => {
        const billingPersonValues = Object.keys(values?.billingPerson)
          .filter(f => f !== 'middleName')
          .map(f => values?.billingPerson[f]);

        return (
          !values?.billingPerson ||
          Object.values(billingPersonValues).includes('') ||
          Object.values(billingPersonValues).includes(undefined) ||
          Object.values(values).some(value => !value)
        );
      };

      if (!otherBusinessSelected) delete values.businessTypeOther;
      if (!otherCompanySelected) delete values.companyTypeOther;

      return (
        Object.values(values).includes('') ||
        Object.values(values).includes(undefined) ||
        Object.values(values).some(value => !value) ||
        validOtherPerson() ||
        validBillingPerson()
      );
    },
    [addContactPerson, otherBusinessSelected, otherCompanySelected],
  );

  const onValuesChange = (_, values) => {
    const changed = Object.keys(values).some(key => {
      if (key === 'dateOfLastestRiskLevel' || key === 'registrationDate') {
        return (
          moment(oldBusinessInformation[key]).format(FORMAT_DATE.DATE_YMD) !==
          moment(values[key]).format(FORMAT_DATE.DATE_YMD)
        );
      }
      if (key === 'sourceOfFunds') {
        return oldBusinessInformation[key] !== values[key].join(',');
      }
      if (key === 'phoneNumber') {
        const formatPhoneNumber = values.phoneNumber?.replace(countryCode, '');
        return oldBusinessInformation[key] !== formatPhoneNumber;
      }
      if (key === 'otherPerson' || key === 'billingPerson') {
        const otherPersonChanged = Object.keys(values[key]).some(pKey => {
          if (pKey === 'phoneNumber') {
            const formatPhoneNumber = values[key]?.phoneNumber?.replace(
              countryCode,
              '',
            );
            return oldBusinessInformation[key]?.[pKey] !== formatPhoneNumber;
          }
          return oldBusinessInformation[key]?.[pKey] !== values[key]?.[pKey];
        });

        return otherPersonChanged;
      }
      return oldBusinessInformation[key] !== values[key];
    });

    const isEmpty = checkIsEmpty(values);
    onFieldsChange?.(isEmpty || (!changed && !!isEditCustomer));
    return changed;
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    const isEmpty = checkIsEmpty(values);
    const isChangedData = onValuesChange(_, form.getFieldsValue());
    if (isEditCustomer) {
      onFieldsChange?.(isEditCustomer && !isChangedData);
    } else onFieldsChange?.(isEmpty);
  }, [form, checkIsEmpty, onFieldsChange, isEditCustomer, addContactPerson]);

  const renderPopupBusinessIdAndCountryExisted = useMemo(() => {
    return (
      <BasicModal
        destroyOnClose={true}
        className={styles.existed__popup}
        visible={isShowPopupError}
        title={`Customer already exists in Rakkar`}
        centered={true}
        onCancel={() => setIsShowPopupError(false)}
        maskClosable={true}
        width={404}
        children={
          <div className={styles.content}>
            Please verify if the entered customer information is correct
          </div>
        }
        footer={[
          <div className={styles.button}>
            <BasicButton
              title="OK"
              type="filled"
              className={styles.confirm}
              onClick={() => setIsShowPopupError(false)}
            />
          </div>,
        ]}
      />
    );
  }, [isShowPopupError]);

  const handleChangeBusinessType = value => {
    const isOther = checkBusinessTypeOther(Number(value), businessTypes);
    if (isOther && !otherBusinessSelected) {
      setOtherBusinessSelected(true);
    }
    if (!isOther && otherBusinessSelected) {
      setOtherBusinessSelected(false);
      form.setFieldsValue({ businessTypeOther: null });
    }
  };

  const handleChangeCompanyType = (value: CompanyType) => {
    const isOther = value === CompanyType.OTHER;
    if (isOther && !otherCompanySelected) {
      setOtherCompanySelected(true);
    }
    if (!isOther && otherCompanySelected) {
      setOtherCompanySelected(false);
      form.setFieldsValue({ companyTypeOther: null });
    }
  };

  const handleChangeSourceOfWealthOpts = (value: SourceOfWealth) => {
    const isOther = value === SourceOfWealth.OTHER;
    if (isOther && !otherSourceOfWealthSelected) {
      setOtherSourceOfWealthSelected(true);
    }
    if (!isOther && otherSourceOfWealthSelected) {
      setOtherSourceOfWealthSelected(false);
      form.setFieldsValue({ sourceOfWealthOther: null });
    }
  };
  const handleChangePurposeOfRelationshipOpts = (
    value: PurposeOfRelationship,
  ) => {
    const isOther = value === PurposeOfRelationship.OTHER;
    if (isOther && !otherPurposeOfRelationshipSelected) {
      setOtherPurposeOfRelationshipSelected(true);
    }
    if (!isOther && otherPurposeOfRelationshipSelected) {
      setOtherPurposeOfRelationshipSelected(false);
      form.setFieldsValue({ purposeOfRelationshipOther: null });
    }
  };
  const handleChangeSourceOfFundsOpts = (value: SourceOfFunds) => {
    const isOther = value.includes(SourceOfFunds.OTHER);
    if (isOther && !otherSourceOfFundSelected) {
      setOtherSourceOfFundSelected(true);
    }
    if (!isOther && otherSourceOfFundSelected) {
      setOtherSourceOfFundSelected(false);
      form.setFieldsValue({ sourceOfFundOther: null });
    }
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.container__edit__cus]: isEditCustomer,
      })}
    >
      <div className={styles['customer-information']}></div>
      <Form
        form={form}
        layout="vertical"
        name="bussinessForm"
        validateMessages={validateMessages()}
        onFinish={handleFinish}
        onValuesChange={onValuesChange}
      >
        <>
          <div className={styles.title}>Customer information</div>
          <div className={styles['sub-title']}>
            <div
              className={clsx(styles['sub-title__content'], {
                [styles['sub-title__edit']]: isEditCustomer,
              })}
            >
              Identification information
            </div>
            <div className={styles['sub-title__line']} />
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <BasicInput
                label="Business registered name"
                name="customerName"
                rules={[
                  { required: true },
                  {
                    max: 100,
                  },
                  {
                    validator: (_, value) => {
                      const valueTrim = value.trim();
                      if (value.length && !valueTrim) {
                        return Promise.reject(
                          'Business registered name is required',
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                dataTestId="customerName"
              />
            </Col>
            <Col span={12}>
              <BasicInput
                label="Customer short name"
                name="customerShortName"
                rules={
                  isEditCustomer
                    ? []
                    : [
                        { required: true },
                        {
                          validator: (_, value) => {
                            const checkValidValue = validateRegex(
                              value,
                              REGEX_ALLOW_AZ_09,
                            );

                            if (value && value.length > 4) {
                              return Promise.reject('Max of 4 characters');
                            }
                            if (value && checkValidValue) {
                              return Promise.reject(
                                'Not allow to input special characters and space',
                              );
                            }
                            if (value) {
                              deboundCheckShortNameUnique(value);
                            }
                            return Promise.resolve();
                          },
                        },
                      ]
                }
                readOnly={isEditCustomer}
                isReadOnlyStyle={isEditCustomer}
                dataTestId="customerShortName"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <BasicInput
                label="Business registration number"
                name="businessRegistrationId"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      const checkValidValue = validateRegex(
                        value,
                        REGEX_ALLOW_AZ_09,
                      );
                      if (value && value.length > 50) {
                        return Promise.reject('Max of 50 characters');
                      }
                      if (value && checkValidValue) {
                        return Promise.reject(
                          'Not allow to input special characters and space',
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                dataTestId="businessRegistrationId"
              />
            </Col>
            <Col span={12}>
              <BasicInput
                label="Tax ID"
                name="taxId"
                rules={[{ required: true }]}
                dataTestId="taxId"
              />
            </Col>
          </Row>
          {isEditCustomer && (
            <Row gutter={16}>
              <Col span={12}>
                <BasicInput
                  label="Rakkar ID"
                  name="rakkarId"
                  readOnly
                  isReadOnlyStyle={true}
                  dataTestId="rakkarId"
                />
              </Col>
              <Col span={12}>
                <BasicInput
                  label="Customer group"
                  name="groupName"
                  readOnly
                  isReadOnlyStyle={true}
                  dataTestId="customerGroup"
                />
              </Col>
            </Row>
          )}
          <div className={styles['sub-title']}>
            <div
              className={clsx(styles['sub-title__content'], {
                [styles['sub-title__edit']]: isEditCustomer,
              })}
            >
              Legal information
            </div>
            <div className={styles['sub-title__line']} />
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <BasicSelect
                options={businessTypes}
                rules={[{ required: true }]}
                placeholder="Select..."
                label="Nature of business"
                name="businessTypeId"
                allowClear
                labelValue="name"
                keyValue="id"
                dataTestId="businessTypeId"
                onChange={value => {
                  handleChangeBusinessType(value);
                }}
              />
            </Col>
            {otherBusinessSelected && (
              <Col span={12} className={styles.other}>
                <BasicInput
                  placeholder="Nature of business"
                  name="businessTypeOther"
                  label="Nature of business"
                  rules={[{ required: true, max: 50 }]}
                />
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <BasicSelect
                options={typeOfCompany}
                rules={[{ required: true }]}
                placeholder="Select..."
                label="Business entity type"
                name="companyType"
                allowClear
                dataTestId="companyType"
                onChange={value => {
                  handleChangeCompanyType(value);
                }}
              />
            </Col>
            {otherCompanySelected && (
              <Col span={12} className={styles.other}>
                <BasicInput
                  placeholder="Business entity type"
                  name="companyTypeOther"
                  label="Business entity type"
                  rules={[
                    {
                      required: true,
                      max: 50,
                    },
                  ]}
                />
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="registrationDate"
                rules={[{ required: true }]}
                label="Date of incorporation"
                className={styles.date}
                data-testid="registrationDate"
              >
                <DatePicker
                  format={FORMAT_DATE.DEFAULT}
                  disabledDate={current => current.isAfter(moment())}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles['sub-title']}>
            <div
              className={clsx(styles['sub-title__content'], {
                [styles['sub-title__edit']]: isEditCustomer,
              })}
            >
              Location information
            </div>
            <div className={styles['sub-title__line']} />
          </div>
          <div className={styles.address}>
            <div>
              <BasicInput
                label="Registered address"
                name="registration"
                rules={[{ required: true }]}
                placeholder="Select..."
                readOnly
                prefix={
                  <SvgIcon
                    name="address"
                    width={16}
                    height={16}
                    color="#93939B"
                  />
                }
                value={resgistrationField}
                onClick={() => {
                  handleOpenModalLocation(AddressType.REGISTRATION);
                }}
                dataTestId="registrationAddress"
              />
            </div>
            <div className={styles.address__current}>
              <BasicInput
                label="Principle place of business"
                name="current"
                rules={[{ required: true }]}
                placeholder="Select..."
                readOnly
                prefix={
                  <SvgIcon
                    name="address"
                    width={16}
                    height={16}
                    color="#93939B"
                  />
                }
                value={currentField}
                onClick={() => {
                  if (!blockCurrentAddress) {
                    handleOpenModalLocation(AddressType.CURRENT);
                  }
                }}
                dataTestId="currentAddress"
              />
              <Checkbox
                onChange={handleFillCurrentAddress}
                className={styles.checkbox}
                defaultChecked={blockCurrentAddress}
              >
                Same address with Registered address
              </Checkbox>
            </div>
            <div className={styles.address__billing}>
              <BasicInput
                label="Billing address"
                name="billing"
                rules={[{ required: true }]}
                placeholder="Select..."
                readOnly
                prefix={
                  <SvgIcon
                    name="address"
                    width={16}
                    height={16}
                    color="#93939B"
                  />
                }
                value={billingField}
                onClick={() => {
                  if (!blockBillingAddress) {
                    handleOpenModalLocation(AddressType.BILLING);
                  }
                }}
                dataTestId="billingAddress"
              />
              <Checkbox
                onChange={handleFillBillingAddress}
                className={styles.checkbox}
                defaultChecked={blockBillingAddress}
              >
                Same address with Registered address
              </Checkbox>
            </div>
          </div>
          <div className={styles['sub-title']}>
            <div
              className={clsx(styles['sub-title__content'], {
                [styles['sub-title__edit']]: isEditCustomer,
              })}
            >
              Other
            </div>
            <div className={styles['sub-title__line']} />
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <BasicSelect
                options={countryData}
                label="Origin of funds"
                rules={[{ required: true }]}
                placeholder="Select..."
                allowClear
                name="originOfFunds"
                dataTestId="originOfFunds"
                labelValue="name"
                keyValue="name"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <BasicSelect
                options={purposeOfRelationshipOpts}
                label="Intended purpose of the account"
                rules={[{ required: true }]}
                placeholder="Select..."
                allowClear
                name="purposeOfRelationship"
                dataTestId="purposeOfRelationship"
                onChange={value => {
                  handleChangePurposeOfRelationshipOpts(value);
                }}
              />
            </Col>
            {otherPurposeOfRelationshipSelected && (
              <Col span={12} className={styles.other}>
                <BasicInput
                  placeholder="Intended purpose of the account"
                  name="purposeOfRelationshipOther"
                  label="Intended purpose of the account"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <BasicSelect
                options={sourceOfWealthOpts}
                label="Source of wealth"
                rules={[{ required: true }]}
                placeholder="Select..."
                allowClear
                name="sourceOfWealth"
                dataTestId="sourceOfWealth"
                onChange={value => {
                  handleChangeSourceOfWealthOpts(value);
                }}
              />
            </Col>
            {otherSourceOfWealthSelected && (
              <Col span={12} className={styles.other}>
                <BasicInput
                  placeholder="Source of wealth"
                  name="sourceOfWealthOther"
                  label="Source of wealth"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <BasicSelect
                options={listSourceOfFund}
                label="Source of funds"
                rules={[{ required: true }]}
                placeholder="Select..."
                allowClear
                name="sourceOfFunds"
                dataTestId="sourceOfFunds"
                onChange={value => {
                  handleChangeSourceOfFundsOpts(value);
                }}
                mode="multiple"
                maxTagCount={1}
              />
            </Col>
            {otherSourceOfFundSelected && (
              <Col span={12} className={styles.other}>
                <BasicInput
                  placeholder="Source of funds"
                  name="sourceOfFundOther"
                  label="Source of funds"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <BasicSelect
                options={latestRiskLevelOpts}
                label="Latest risk level"
                rules={[{ required: true }]}
                placeholder="Select..."
                allowClear
                name="lastestRiskLevel"
                dataTestId="lastestRiskLevel"
              />
            </Col>
            <Col span={12}>
              <Form.Item
                name="dateOfLastestRiskLevel"
                rules={[{ required: true }]}
                className={styles.date}
                label="Date of Latest risk assessment"
                data-testid="dateOfLastestRiskLevel"
              >
                <DatePicker
                  format={FORMAT_DATE.DEFAULT}
                  disabledDate={current => current.isAfter(moment())}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ paddingBottom: '1rem' }}>
            <Col span={12}>
              <BasicInput
                label="Credit term"
                name="creditTerm"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      const checkValidValue = validateRegex(value, /^[0-9]+$/);
                      if (value && checkValidValue) {
                        return Promise.reject('Invalid credit term format');
                      }
                      if (value && (+value < 0 || +value > 90)) {
                        return Promise.reject(
                          'Credit term cannot greater than 90 days',
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                onKeyDown={handleNumberKeyDown}
                dataTestId="creditTerm"
              />
            </Col>
            <Col span={12}>
              <BasicSelect
                options={customerEntityRelation}
                label="Rakkar entity"
                tooltip={{
                  title: 'Entity that customer having a relationship with',
                  overlayStyle: {
                    borderRadius: '8px',
                    padding: '10px 12px',
                    maxWidth: 'unset',
                  },
                  overlayInnerStyle: {
                    fontWeight: 400,
                    fontSize: 12,
                  },
                  icon: (
                    <SvgIcon
                      name="info-outlined"
                      width={16}
                      height={16}
                      className={styles.iconInfo}
                    />
                  ),
                }}
                name="entityRelationId"
                rules={[{ required: true }]}
                allowClear
                labelValue="label"
                keyValue="value"
                dataTestId="entityRelationId"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Col>
          </Row>

          {!isEditCustomer && <div className={styles.space} />}
          <div className={styles.title}>Contact information</div>
          <div className={styles['sub-title']}>
            <div
              className={clsx(styles['sub-title__content'], {
                [styles['sub-title__edit']]: isEditCustomer,
              })}
            >
              Main contact person
            </div>
            <div className={styles['sub-title__line']} />
          </div>

          <Row gutter={16}>
            <Col span={12}>
              <BasicInput
                label="First name"
                name="firstName"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      const valueTrim = value.trim();
                      const checkValidValue = validateRegex(
                        value,
                        REGEX_ALLOW_AZ_09_SPACE,
                      );
                      if (value.length && !valueTrim) {
                        return Promise.reject('First name is required');
                      }
                      if (value && value.length > 50) {
                        return Promise.reject('Max of 50 characters');
                      }
                      if (value && checkValidValue) {
                        return Promise.reject(msg);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                dataTestId="firstName"
              />
            </Col>
            <Col span={12}>
              <BasicInput
                label="Last name"
                name="lastName"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      const checkValidValue = validateRegex(
                        value,
                        REGEX_ALLOW_AZ_09_SPACE,
                      );
                      const valueTrim = value.trim();
                      if (value.length && !valueTrim) {
                        return Promise.reject('Last name is required');
                      }
                      if (value && value.length > 50) {
                        return Promise.reject('Max of 50 characters');
                      }
                      if (value && checkValidValue) {
                        return Promise.reject(msg);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                dataTestId="lastName"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} className={styles.phone}>
              <Form.Item
                label={
                  <>
                    Phone number <span className={styles.required}>*</span>
                  </>
                }
                name="phoneNumber"
                rules={[
                  {
                    validator: (_, value) => {
                      const checkPhone = isValidPhoneNumber(value || '');
                      if (value === undefined) {
                        setShowErrPhone(true);
                        return Promise.reject('Phone number is required');
                      }
                      if (value !== undefined && !checkPhone) {
                        setShowErrPhone(true);
                        return Promise.reject('Invalid phone number');
                      }
                      setShowErrPhone(false);
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <PhoneInput
                  onChange={(value: string) => {}}
                  defaultCountry="SG"
                  addInternationalOption={false}
                  displayInitialValueAsLocalNumber
                  className={`${showErrPhone && styles.ipErr}`}
                  dataTestId="phoneNumber"
                  onCountryChange={(value: any) => {
                    if (value) {
                      const code = getCountryCallingCode(value);
                      setCountryCode(`+${code}`);
                    } else {
                      setCountryCode('');
                    }
                  }}
                  data-testid="phoneNumber"
                  countries={[...countries]}
                />
              </Form.Item>
              <div className={styles.code}>{countryCode}</div>
            </Col>
            <Col span={12}>
              <BasicInput
                label="Email"
                name="email"
                rules={[{ required: true, type: 'email' }, { max: 100 }]}
                dataTestId="email"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <BasicInput
                label="Position"
                name="position"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      const valueTrim = value?.trim() || '';
                      const checkValidValue = validateRegex(
                        valueTrim,
                        REGEX_ALLOW_AZ_09_SPACE,
                      );
                      if (value?.length && !valueTrim.length) {
                        return Promise.reject('Position is required');
                      }
                      if (value?.length && valueTrim.length > 50) {
                        return Promise.reject('Max of 50 characters');
                      }
                      if (value?.length && checkValidValue) {
                        return Promise.reject(msg);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                dataTestId="position"
              />
            </Col>
          </Row>

          {addContactPerson ? (
            <>
              <div className={styles['sub-title']}>
                <div
                  className={clsx(styles['sub-title__content'], {
                    [styles['sub-title__edit']]: isEditCustomer,
                  })}
                >
                  Alternative contact person (optional)
                </div>
                <div className={styles['sub-title__line']} />
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => handleClearOtherPerson()}
                  icon={<SvgIcon name="delete" />}
                  data-testid="delete"
                />
              </div>

              <Row gutter={16}>
                <Col span={12}>
                  <BasicInput
                    label="First name"
                    name={['otherPerson', 'firstName']}
                    rules={[
                      { required: true },
                      {
                        validator: (_, value) => {
                          const valueTrim = value?.trim() || '';
                          const checkValidValue = validateRegex(
                            valueTrim,
                            REGEX_ALLOW_AZ_09_SPACE,
                          );
                          if (value?.length && !valueTrim.length) {
                            return Promise.reject('First name is required');
                          }
                          if (value?.length && valueTrim.length > 50) {
                            return Promise.reject('Max of 50 characters');
                          }
                          if (value?.length && checkValidValue) {
                            return Promise.reject(msg);
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    dataTestId="otherPerson-firstName"
                  />
                </Col>
                <Col span={12}>
                  <BasicInput
                    label="Last name"
                    name={['otherPerson', 'lastName']}
                    rules={[
                      { required: true },
                      {
                        validator: (_, value) => {
                          const valueTrim = value?.trim() || '';
                          const checkValidValue = validateRegex(
                            valueTrim,
                            REGEX_ALLOW_AZ_09_SPACE,
                          );
                          if (value?.length && !valueTrim.length) {
                            return Promise.reject('Last name is required');
                          }
                          if (value?.length && valueTrim.length > 50) {
                            return Promise.reject('Max of 50 characters');
                          }
                          if (value?.length && checkValidValue) {
                            return Promise.reject(msg);
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    dataTestId="otherPerson-lastName"
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12} className={styles.phone}>
                  <Form.Item
                    label={
                      <>
                        Phone number <span className={styles.required}>*</span>
                      </>
                    }
                    name={['otherPerson', 'phoneNumber']}
                    rules={[
                      {
                        validator: (_, value) => {
                          const checkPhone = isValidPhoneNumber(value || '');
                          if (value === undefined) {
                            setShowOtherPersonErrPhone(true);
                            return Promise.reject('Phone number is required');
                          }
                          if (value !== undefined && !checkPhone) {
                            setShowOtherPersonErrPhone(true);
                            return Promise.reject('Invalid phone number');
                          }
                          setShowOtherPersonErrPhone(false);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      onChange={(value: string) => {}}
                      defaultCountry="SG"
                      addInternationalOption={false}
                      displayInitialValueAsLocalNumber
                      className={`${showOtherPersonErrPhone && styles.ipErr}`}
                      data-testid="otherPerson-phoneNumber"
                      onCountryChange={(value: any) => {
                        if (value) {
                          const code = getCountryCallingCode(value);
                          setOtherPersonCountryCode(`+${code}`);
                        } else {
                          setOtherPersonCountryCode('');
                        }
                      }}
                    />
                  </Form.Item>
                  <div className={styles.code}>{otherPersonCountryCode}</div>
                </Col>
                <Col span={12}>
                  <BasicInput
                    label="Email"
                    name={['otherPerson', 'email']}
                    rules={[{ required: true, type: 'email' }, { max: 100 }]}
                    dataTestId="otherPerson-email"
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <BasicInput
                    label="Position"
                    name={['otherPerson', 'position']}
                    rules={[
                      { required: true },
                      {
                        validator: (_, value) => {
                          const valueTrim = value?.trim() || '';
                          const checkValidValue = validateRegex(
                            valueTrim,
                            REGEX_ALLOW_AZ_09_SPACE,
                          );
                          if (value?.length && !valueTrim.length) {
                            return Promise.reject('Position is required');
                          }
                          if (value?.length && valueTrim.length > 50) {
                            return Promise.reject('Max of 50 characters');
                          }
                          if (value?.length && checkValidValue) {
                            return Promise.reject(msg);
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    dataTestId="otherPerson-position"
                  />
                </Col>
              </Row>
            </>
          ) : (
            <div className={styles['sub-title']}>
              <div
                className={styles.btnAdd}
                onClick={() => setAddContactPerson(true)}
                data-testid="addPerson"
              >
                <SvgIcon name="grey-plus-icon" width={16} height={16} />
                <span className={styles['btnAdd__text']}>
                  Add contact person
                </span>
              </div>
            </div>
          )}
        </>

        {!isEditCustomer && <div className={styles.space} />}
        <div className={styles.title}>Billing contact person</div>

        <Row gutter={16}>
          <Col span={12}>
            <BasicInput
              label="First name"
              name={['billingPerson', 'firstName']}
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    const valueTrim = value.trim();
                    const checkValidValue = validateRegex(
                      value,
                      REGEX_ALLOW_AZ_09_SPACE,
                    );
                    if (value.length && !valueTrim) {
                      return Promise.reject('First name is required');
                    }
                    if (value && value.length > 50) {
                      return Promise.reject('Max of 50 characters');
                    }
                    if (value && checkValidValue) {
                      return Promise.reject(msg);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              dataTestId="billngFirstName"
            />
          </Col>

          <Col span={12}>
            <BasicInput
              label="Last name"
              name={['billingPerson', 'lastName']}
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    const checkValidValue = validateRegex(
                      value,
                      REGEX_ALLOW_AZ_09_SPACE,
                    );
                    const valueTrim = value.trim();
                    if (value.length && !valueTrim) {
                      return Promise.reject('Last name is required');
                    }
                    if (value && value.length > 50) {
                      return Promise.reject('Max of 50 characters');
                    }
                    if (value && checkValidValue) {
                      return Promise.reject(msg);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              dataTestId="billingLastName"
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12} className={styles.phone}>
            <Form.Item
              label={
                <>
                  Phone number <span className={styles.required}>*</span>
                </>
              }
              name={['billingPerson', 'phoneNumber']}
              rules={[
                {
                  validator: (_, value) => {
                    const checkPhone = isValidPhoneNumber(value || '');
                    if (value === undefined) {
                      setShowBillingPersonErrPhone(true);
                      return Promise.reject('Phone number is required');
                    }
                    if (value !== undefined && !checkPhone) {
                      setShowBillingPersonErrPhone(true);
                      return Promise.reject('Invalid phone number');
                    }
                    setShowBillingPersonErrPhone(false);
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                onChange={(value: string) => {}}
                defaultCountry="SG"
                addInternationalOption={false}
                displayInitialValueAsLocalNumber
                className={`${showBillingPersonErrPhone && styles.ipErr}`}
                dataTestId="billingPhoneNumber"
                onCountryChange={(value: any) => {
                  if (value) {
                    const code = getCountryCallingCode(value);
                    setBillingPersonCountryCode(`+${code}`);
                  } else {
                    setBillingPersonCountryCode('');
                  }
                }}
                data-testid="billingPhoneNumber"
              />
            </Form.Item>
            <div className={styles.code}>{billingPersonCountryCode}</div>
          </Col>
          <Col span={12}>
            <BasicInput
              label="Email"
              name={['billingPerson', 'email']}
              rules={[{ required: true, type: 'email' }, { max: 100 }]}
              dataTestId="billingEmail"
            />
          </Col>
        </Row>
      </Form>

      {openModal && (
        <ModalLocation
          openModal={openModal}
          handleCancel={handleCancel}
          keyForm={keyForm}
          onCreate={handleAddLocation}
          initValue={dataModal}
        />
      )}
      {renderPopupBusinessIdAndCountryExisted}
    </div>
  );
};

export default BusinessInformation;
