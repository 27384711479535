import { Button, Col, Divider, Drawer, Row, Tooltip } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import clsx from 'clsx';
import { FORMAT_DATE, PREFIX_API } from 'constants/index';
import {
  ListActionNeedToCheckPermission,
  TransactionType,
  PeerType,
  TransactionFeature,
  TransactionTypeSource,
  ContractCallMethod,
} from 'enum/common';
import { capitalize, debounce, upperCase } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import TabGeneral from 'app.v2/components/orgarnism/CustomerProfile/Tabs/General';
import Instance from 'app.v2/config/instance';
import RButton from 'app/components/Button';
import { infoTxn } from 'app/components/PendingRequestDrawer/UnfreezeTransactionRequest/components/TransactionInformation/index.helper';
import RakkarStatusButton from 'app/components/common/RakkarStatusButton';
import ToastMessage from 'app/components/common/ToastMessage';
import { SubStatus, TransactionStatusResponse } from 'enum/transaction';
import { SyncDataResponse, TransactionRecord } from 'interfaces/transactions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store/hooks';
import { selectPermissions } from 'store/slices/auth';
import { selectTransactionDetail } from 'store/slices/transaction';
import { checkPermissions } from 'utils/common';
import {
  capitalizeFirstLetter,
  formatAssetDetails,
  formatCurrencyTypeB,
} from 'utils/helpers';
import { isStakingTransaction } from '..';
import styles from './index.module.scss';
import { RightArrowIcon } from 'assets/components/icon';

type Props = {
  drawerWidth?: number;
  transactionData?: any;
  tier: any;
  isMask: boolean;
  detail?: TransactionRecord;
  setSyncData?: (value: Partial<SyncDataResponse>) => void;
};

interface ITextInfor {
  label: any;
  text: any;
  span?: number;
  dataTestId?: string;
  isEmpty?: boolean;
  isLast?: boolean;
}

const excludeFeeBadge = 'EXCL.FEE';
export const subStatuses = [
  SubStatus.AUTO_FREEZE,
  SubStatus.FROZEN_MANUALLY,
  SubStatus.REJECTED_AML_SCREENING,
];

export const TierTooltip = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  return (
    <Tooltip
      placement="bottom"
      overlayStyle={{
        borderRadius: '8px',
        padding: '10px 12px',
        maxWidth: 'unset',
      }}
      overlayInnerStyle={{
        fontWeight: 400,
        fontSize: 12,
      }}
      title={title}
    >
      {content}
    </Tooltip>
  );
};

// checked
const TransactionInformation = ({
  drawerWidth = 665,
  transactionData,
  tier,
  isMask,
  detail,
  setSyncData,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);
  const [isOpenSourceTooltip, setIsOpenSourceTooltip] =
    useState<boolean>(false);
  const [isOpenDestinationTooltip, setIsOpenDestinationTooltip] =
    useState<boolean>(false);
  const transactionSelector = useSelector(selectTransactionDetail)?.data ?? {};
  const transactionDetail = transactionData ?? transactionSelector;
  const transactionType = transactionDetail?.type;
  const permissions = useAppSelector(selectPermissions);
  const allowSyncFbTxn = checkPermissions(
    ListActionNeedToCheckPermission.SYNC_FB_TXN,
    permissions,
  );

  const [isSyncing, setIsSyncing] = useState(false);
  const [isSyncingVaultBalance, setIsSyncingVaultBalance] = useState(false);

  const [isMaskUnfreezeTransaction, setIsMaskUnfreezeTransaction] =
    useState(true);

  useEffect(() => {
    setIsMaskUnfreezeTransaction(isMask ? isMask : !isMask);
  }, [isMask]);

  const isLiquidStaking = useMemo(() => {
    return (
      transactionDetail?.additionalData?.feature ===
      TransactionFeature.LIQUID_STAKING
    );
  }, [transactionDetail?.additionalData?.feature]);

  const isIncommingContractCall = useMemo(() => {
    return (
      transactionDetail?.additionalData?.contractCallMethod ===
      ContractCallMethod.INCOMING
    );
  }, [transactionDetail?.additionalData?.contractCallMethod]);

  const walletInfoDict = useMemo(() => {
    const walletInfoDictId =
      transactionDetail?.additionalData?.walletInfoId?.[0];
    if (walletInfoDictId)
      return transactionDetail?.additionalData?.walletInfoDict[
        walletInfoDictId
      ];
  }, [transactionDetail?.additionalData]);

  const isStakingOnly = useMemo(() => {
    return transactionType === TransactionType.STAKING;
  }, [transactionType]);

  const isStaking = useMemo(() => {
    return isStakingTransaction(transactionType);
  }, [transactionType]);

  const substr = (text: string) => {
    if (text?.length > 10) {
      return `${text.substring(0, 6)} ... ${text.substring(
        text.length,
        text.length - 6,
      )}`;
    } else return text || '';
  };

  const TextInfor = ({
    span = 12,
    label,
    text,
    dataTestId,
    isEmpty = false,
    isLast = false,
  }: ITextInfor) => {
    return (
      <Col span={span}>
        <div
          className={clsx(styles.content__item, {
            [styles.content__last_item]: isLast,
          })}
        >
          <p className={styles.content__label}>{label}</p>
          <p className={styles.content__text} data-testid={dataTestId}>
            {isEmpty ? '-' : text}
          </p>
        </div>
      </Col>
    );
  };

  const onCopy = (e: string) => {
    navigator.clipboard.writeText(e);
    setIsOpenTooltip(true);
    const setCloseTooltip = debounce(() => {
      setIsOpenTooltip(false);
    }, 500);
    setCloseTooltip();
  };

  const onCopySource = (e: string) => {
    navigator.clipboard.writeText(e);
    setIsOpenSourceTooltip(true);
    const setCloseTooltip = debounce(() => {
      setIsOpenSourceTooltip(false);
    }, 500);
    setCloseTooltip();
  };

  const onCopyDestination = (e: string) => {
    navigator.clipboard.writeText(e);
    setIsOpenDestinationTooltip(true);
    const setCloseTooltip = debounce(() => {
      setIsOpenDestinationTooltip(false);
    }, 500);
    setCloseTooltip();
  };
  const decodeType = transactionDetail?.type;

  const networkFee = useMemo(() => {
    const status = transactionDetail?.status;
    return status === TransactionStatusResponse.COMPLETED
      ? transactionDetail?.networkFee
      : transactionDetail?.totalEstimatedFee;
  }, [transactionDetail]);

  const repStatus = useMemo(() => {
    const repStatus = transactionDetail?.repStatus;
    if (
      repStatus === TransactionStatusResponse.FAILED &&
      subStatuses.includes(transactionDetail?.subStatusFireblock)
    ) {
      return TransactionStatusResponse.PENDING_REVIEW;
    } else return repStatus;
  }, [transactionDetail]);

  const handleSyncTransaction = async (transactionId: string) => {
    try {
      setIsSyncing(true);
      const response: SyncDataResponse = await Instance.post(
        `/${PREFIX_API.TRANSACTION}/transactions/${transactionId}/sync`,
      );
      setIsSyncing(false);
      setSyncData?.(response);
    } catch (error) {
      toast(
        <ToastMessage
          type="error"
          message="The transaction details cannot be updated. Please try again."
        />,
      );

      setIsSyncing(false);
    }
  };

  const handleSyncVaultBalance = async () => {
    const transactionType = transactionDetail?.type;
    let sourceVaultId = transactionDetail.sourceId;
    let sourceVaultType = transactionDetail.sourceType;
    let destinationVaultId = transactionDetail.destinationId;
    let destinationVaultType = transactionDetail.destinationType;
    if (transactionType === TransactionType.CONTRACT_CALL) {
      sourceVaultId = transactionDetail.newSource?.id;
      sourceVaultType = transactionDetail.newSource?.type;
      destinationVaultId = transactionDetail.newDestination?.id;
      destinationVaultType = transactionDetail.newDestination?.type;
    }
    let isFinishFireblock = false;
    try {
      setIsSyncingVaultBalance(true);
      if (sourceVaultId && sourceVaultType === PeerType.VAULT_ACCOUNT) {
        await Instance.patch(
          `/${PREFIX_API.CORE}/v2/rep/vault/${sourceVaultId}/sync-from-fireBlock`,
        );
        isFinishFireblock = true;
      }
      if (
        destinationVaultId &&
        destinationVaultType === PeerType.VAULT_ACCOUNT
      ) {
        await Instance.patch(
          `/${PREFIX_API.CORE}/v2/rep/vault/${destinationVaultId}/sync-from-fireBlock`,
        );
        isFinishFireblock = true;
      }

      if (isFinishFireblock) {
        toast(
          <ToastMessage type="info" message="Vault balance has been updated" />,
        );
      }
      setIsSyncingVaultBalance(false);
    } catch (error) {
      toast(
        <ToastMessage
          type="error"
          message="Vault balance cannot be updated. Please try again."
        />,
      );

      setIsSyncingVaultBalance(false);
    }
  };

  const isDisableSyncBtn = useMemo(() => {
    return (
      [
        TransactionStatusResponse.PENDING,
        TransactionStatusResponse.REJECTED,
        TransactionStatusResponse.CANCELLED,
        TransactionStatusResponse.COMPLETED,
      ].includes(transactionDetail?.repStatus) || isSyncing
    );
  }, [transactionDetail?.repStatus, isSyncing]);

  return (
    <div className={styles.content}>
      <Row justify="space-between">
        <SvgIcon name={infoTxn(decodeType)?.icon} width={32} height={32} />
        <Row align="middle" style={{ gap: 8 }}>
          {allowSyncFbTxn && (
            <>
              <RButton
                onClick={() => handleSyncVaultBalance()}
                className={styles.button}
                loading={isSyncingVaultBalance}
                content="Sync vault balance"
                buttonType="cancel"
              />
              <RButton
                onClick={() =>
                  detail?.transactionId && handleSyncTransaction(detail?.id)
                }
                disable={isDisableSyncBtn}
                className={clsx(styles.button, {
                  [styles.disabled]: isDisableSyncBtn,
                })}
                loading={isSyncing}
                content="Sync transaction"
                buttonType="cancel"
              />
            </>
          )}
        </Row>
      </Row>
      <Row className={styles.reviewing__title} data-testid="transaction-title">
        {infoTxn(decodeType)?.title}{' '}
        <span style={{ color: '#909193' }}> by</span>{' '}
        <Button
          type="link"
          onClick={() => setOpen(true)}
          className={styles.customer__detail}
          data-testid="customer-name"
        >
          {transactionDetail?.customerName}
        </Button>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Asset"
          dataTestId="asset"
          text={
            <Row style={{ gap: 8 }} align="middle">
              {isLiquidStaking && !isIncommingContractCall ? (
                <>
                  <div className={styles.icon}>
                    <img src={transactionDetail?.icon} alt="" />
                  </div>
                  <span className={clsx(styles.name, styles.bold__name)}>
                    {transactionDetail?.symbol}
                  </span>
                  <RightArrowIcon width={16} height={16} />
                  <div className={styles.icon}>
                    <img src={walletInfoDict?.image} alt="" />
                  </div>
                  <span className={clsx(styles.name, styles.bold__name)}>
                    {walletInfoDict?.symbol}
                  </span>
                </>
              ) : (
                <>
                  <div className={styles.icon}>
                    <img src={transactionDetail?.icon} alt="" />
                  </div>
                  <span className={clsx(styles.name, styles.bold__name)}>
                    {transactionDetail?.symbol}
                  </span>
                  <span className={clsx(styles.name, styles.sub__name)}>
                    {transactionDetail?.name}
                  </span>
                </>
              )}
            </Row>
          }
        />
        <TextInfor
          label="Network"
          dataTestId="network"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {transactionDetail?.network}
              </span>
            </Row>
          }
        />
      </Row>
      <Row>
        <TextInfor
          label={
            isLiquidStaking ? (
              <Row align="middle" style={{ gap: 4 }}>
                Amount{' '}
                <div className={styles.excludeFeeBadge}>{excludeFeeBadge}</div>
              </Row>
            ) : (
              'Amount'
            )
          }
          dataTestId="amount"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.bold__name)}>
                {`${formatAssetDetails(transactionDetail?.amount)} ${
                  transactionDetail?.symbol
                }`}
              </span>
              <span className={clsx(styles.name, styles.sub__name)}>
                ${formatCurrencyTypeB(transactionDetail?.totalUSD)}
              </span>
              {/* {tier && !isStaking && (
                <span
                  className={`${styles.tier} ${
                    tier.tierLevel === TierSignerLevel.LARGE
                      ? styles.large__tier
                      : ''
                  }${
                    tier.tierLevel === TierSignerLevel.MID
                      ? styles.mid__tier
                      : ''
                  }${
                    tier.tierLevel === TierSignerLevel.LOW
                      ? styles.low__tier
                      : ''
                  }
                  }`}
                >
                  {tier.tierLevel === TierSignerLevel.LARGE && (
                    <TierTooltip
                      title={`More than $${formatAssetDetails(tier.from)}`}
                      content="TIER 3"
                    />
                  )}
                  {tier.tierLevel === TierSignerLevel.MID && (
                    <TierTooltip
                      title={`More than $${formatAssetDetails(
                        tier.from,
                      )} to $${formatAssetDetails(tier.to)}`}
                      content="TIER 2"
                    />
                  )}
                  {tier.tierLevel === TierSignerLevel.LOW && (
                    <TierTooltip
                      title={`More than $${formatAssetDetails(
                        tier.from,
                      )} to $${formatAssetDetails(tier.to)}`}
                      content="TIER 1"
                    />
                  )}
                </span>
              )} */}
            </Row>
          }
        />

        <TextInfor
          label={
            isStaking &&
            transactionDetail?.status !== TransactionStatusResponse.COMPLETED
              ? 'Estimated fee'
              : 'Network fee'
          }
          dataTestId="network-fee"
          text={
            ((transactionDetail?.status ===
              TransactionStatusResponse.CONFIRMING ||
              transactionDetail?.status ===
                TransactionStatusResponse.COMPLETED) &&
              transactionDetail?.type === TransactionType.CONTRACT_CALL &&
              transactionDetail?.additionalData?.contractCallMethod?.toUpperCase() !==
                TransactionTypeSource.INCOMING) ||
            transactionDetail?.type !== TransactionType.CONTRACT_CALL ? (
              <Row style={{ gap: 8 }}>
                <span className={clsx(styles.name, styles.normal__name)}>
                  {`${formatAssetDetails(networkFee)} ${
                    transactionDetail?.feeCurrency
                  }`}
                </span>
                <span className={clsx(styles.name, styles.sub__name)}>
                  ${formatCurrencyTypeB(transactionDetail?.totalFeeUSD)}
                </span>
              </Row>
            ) : (
              '-'
            )
          }
        />
        {isStakingOnly && (
          <TextInfor
            isLast={true}
            label="Registration fee"
            dataTestId="registration-fee"
            text={
              <Row style={{ gap: 8 }}>
                <span className={clsx(styles.name, styles.normal__name)}>
                  {`${formatAssetDetails(transactionDetail?.registrationFee)} ${
                    transactionDetail?.symbol
                  }`}
                </span>
                <span className={clsx(styles.name, styles.sub__name)}>
                  ${formatCurrencyTypeB(transactionDetail?.registrationFeeUSD)}
                </span>
              </Row>
            }
          />
        )}
      </Row>
      {transactionDetail.type === TransactionType.CONTRACT_CALL && (
        <Row style={{ marginBottom: 10 }}>
          <TextInfor
            label="Method"
            dataTestId="method"
            text={capitalizeFirstLetter(transactionDetail.wcInfo?.method)}
          />
        </Row>
      )}
      <Divider orientationMargin="0" />
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Workspace"
          dataTestId="workspace"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {transactionDetail?.workspace}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.workspace}
        />
        <TextInfor
          label="Transaction status"
          dataTestId="transaction-status"
          text={
            <Row style={{ gap: 8 }}>
              <RakkarStatusButton status={repStatus} />
            </Row>
          }
          isEmpty={!repStatus}
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Transaction date"
          dataTestId="transaction-date"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {transactionDetail?.transactionDate
                  ? moment(+transactionDetail?.transactionDate).format(
                      FORMAT_DATE.DATE_DMY_hM,
                    )
                  : '-'}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.transactionDate}
        />
        <TextInfor
          label={
            <Row style={{ gap: 10 }}>
              <span>Transaction hash</span>
              <Tooltip placement="top" title={'Copied'} open={isOpenTooltip}>
                <SvgIcon
                  name="copy"
                  width={14}
                  height={14}
                  className={styles.copy__icon}
                  onClick={() => onCopy(transactionDetail?.txHash ?? '')}
                />
              </Tooltip>
            </Row>
          }
          dataTestId="transaction-hash"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name)}
                style={{
                  color: '#2E90FA',
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  transactionDetail?.blockExplorerUrl
                    ? window.open(transactionDetail?.blockExplorerUrl)
                    : null
                }
              >
                {substr(transactionDetail?.txHash)}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.txHash}
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Source"
          dataTestId="source"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {transactionDetail?.sourceName}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.sourceName}
        />
        <TextInfor
          label={
            <Row style={{ gap: 10 }}>
              <span>Source Address</span>
              <Tooltip
                placement="top"
                title={'Copied'}
                open={isOpenSourceTooltip}
              >
                <SvgIcon
                  name="copy"
                  width={14}
                  height={14}
                  className={styles.copy__icon}
                  onClick={() => onCopySource(transactionDetail?.sourceAddress)}
                />
              </Tooltip>
            </Row>
          }
          dataTestId="source-address"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {substr(transactionDetail?.sourceAddress)}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.sourceAddress}
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Destination"
          dataTestId="destination"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {transactionDetail?.destinationName}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.destinationName}
        />
        <TextInfor
          label={
            <Row style={{ gap: 10 }}>
              <span>Destination Address</span>
              <Tooltip
                placement="top"
                title={'Copied'}
                open={isOpenDestinationTooltip}
              >
                <SvgIcon
                  name="copy"
                  width={14}
                  height={14}
                  className={styles.copy__icon}
                  onClick={() =>
                    onCopyDestination(transactionDetail?.destinationAddress)
                  }
                />
              </Tooltip>
            </Row>
          }
          dataTestId="destination-address"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {substr(transactionDetail?.destinationAddress)}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.destinationAddress}
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Fireblock status"
          dataTestId="fireblocks-status"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {capitalize(upperCase(transactionDetail?.fireblocksStatus))}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.fireblocksStatus}
        />
        <TextInfor
          label="Substatus"
          dataTestId="substatus"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name)}
                style={{ fontSize: 12 }}
              >
                {capitalize(transactionDetail?.fireblocksStatus)}
              </span>
            </Row>
          }
          isEmpty={!transactionDetail?.fireblocksStatus}
        />
      </Row>
      {transactionDetail.type === TransactionType.CONTRACT_CALL && (
        <Row style={{ marginBottom: 10 }}>
          <TextInfor
            label="Fireblocks ID"
            dataTestId="fireBlocks-id"
            text={capitalizeFirstLetter(
              transactionDetail.wcInfo?.externalTransactionId,
            )}
          />
        </Row>
      )}
      <Drawer
        width={drawerWidth}
        placement="right"
        onClose={() => setOpen(false)}
        maskClosable={true}
        open={open}
        keyboard={false}
        title={
          <Row style={{ alignItems: 'center', gap: 8 }}>
            Customer Profile
            <SvgIcon
              name={isMaskUnfreezeTransaction ? 'black-eye' : 'black-eye-off'}
              onClick={() =>
                setIsMaskUnfreezeTransaction(!isMaskUnfreezeTransaction)
              }
              width={16}
              height={isMaskUnfreezeTransaction ? 12 : 16}
              style={{ cursor: 'pointer' }}
            />
          </Row>
        }
        className={styles.drawerProfile}
      >
        <div className={styles.profile}>
          <div className={styles.profile__top}>
            <span className={styles.name}>
              {transactionDetail?.customerName}
            </span>
          </div>
          {transactionDetail?.customerId && (
            <TabGeneral
              id={transactionDetail?.customerId}
              isMaskedProfile={isMaskUnfreezeTransaction}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default TransactionInformation;
