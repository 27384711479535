import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { RequestStatus } from 'interfaces/request';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  billingsActions,
  selectMarkAsBillingPaid,
} from 'store/slices/billings';
import styles from './index.module.scss';

type Props = {
  invoiceId: string | undefined;
  open: boolean;
  setOpen: (v: boolean) => void;
};

const ModalMarkAsPaid = ({ open, setOpen, invoiceId }: Props) => {
  const dispatch = useDispatch();
  const onClose = () => {
    setOpen(false);
  };

  const data = useAppSelector(selectMarkAsBillingPaid);

  const loading = data.status === RequestStatus.Loading;

  useEffect(() => {
    if (data.status === RequestStatus.Success) {
      setOpen(false);
    }
  }, [data, setOpen]);

  const handleConfirmMarkAsPaid = () => {
    if (invoiceId)
      dispatch(billingsActions.markAsBillingPaidRequest(invoiceId));
  };

  return (
    <BasicModal
      destroyOnClose={false}
      className={styles.modal}
      visible={open}
      centered={true}
      onCancel={onClose}
      maskClosable={true}
      width={404}
      footer={null}
      closable={false}
    >
      <div data-testid="modal-mark-as-paid">
        <div className={styles.title}>Mark this invoice as paid?</div>
        <div className={styles.content}>
          {` Please check all information carefully before confirming.`}
        </div>
        <div className={styles.footer}>
          <BasicButton
            title="Cancel"
            dataTestId="cancel"
            type="outline"
            onClick={onClose}
          />
          <BasicButton
            title="Confirm"
            dataTestId="confirm"
            type="filled"
            onClick={handleConfirmMarkAsPaid}
            loading={loading}
          />
        </div>
      </div>
    </BasicModal>
  );
};

export default ModalMarkAsPaid;
