import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortCurrencyConvert,
  GroupByField,
  CustomerOnScreen,
} from 'enum/currencyConvert.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetCurrencyConvertRequestParamDTO,
  GetCurrencyConvertResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ICurrencyConvertDetail,
} from 'interfaces/currencyConvert';
import { AppState } from '../..';
interface CurrencyConvertState {
  params: GetCurrencyConvertRequestParamDTO;
  currencyConvert: ResponseData<GetCurrencyConvertResponseDTO>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  currencyConvertDetail: ResponseData<ICurrencyConvertDetail>;
}
export function getDefaultCurrencyConvertRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortCurrencyConvert.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: CurrencyConvertState = {
  params: getDefaultCurrencyConvertRequestParams(),
  currencyConvert: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.CURRENCYCONVERT,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  currencyConvertDetail: {
    status: RequestStatus.Idle,
  },
};
const getCurrencyConvertRequest: CaseReducer<
  CurrencyConvertState,
  PayloadAction<any>
> = (state: CurrencyConvertState) => {
  state.currencyConvert.status = RequestStatus.Loading;
};
const getCurrencyConvertSuccess: CaseReducer<
  CurrencyConvertState,
  PayloadAction<GetCurrencyConvertResponseDTO>
> = (
  state: CurrencyConvertState,
  { payload }: PayloadAction<GetCurrencyConvertResponseDTO>,
) => {
  state.currencyConvert.status = RequestStatus.Success;
  state.currencyConvert.data = payload;
};
const getCurrencyConvertFailed: CaseReducer<CurrencyConvertState> = (
  state: CurrencyConvertState,
) => {
  state.currencyConvert.status = RequestStatus.Failed;
};
const getDetailCurrencyConvertRequest: CaseReducer<
  CurrencyConvertState,
  PayloadAction<string>
> = (state: CurrencyConvertState) => {
  state.currencyConvertDetail.status = RequestStatus.Loading;
};
const getDetailCurrencyConvertSuccess: CaseReducer<
  CurrencyConvertState,
  PayloadAction<ICurrencyConvertDetail>
> = (
  state: CurrencyConvertState,
  { payload }: PayloadAction<ICurrencyConvertDetail>,
) => {
  state.currencyConvertDetail.status = RequestStatus.Success;
  state.currencyConvertDetail.data = payload;
};
const getDetailCurrencyConvertFailed: CaseReducer<CurrencyConvertState> = (
  state: CurrencyConvertState,
) => {
  state.currencyConvertDetail.status = RequestStatus.Failed;
};
const createCurrencyConvertRequest: CaseReducer<
  CurrencyConvertState,
  PayloadAction<ICurrencyConvertDetail>
> = (state: CurrencyConvertState) => {
  state.currencyConvertDetail.status = RequestStatus.Loading;
};
const createCurrencyConvertSuccess: CaseReducer<
  CurrencyConvertState,
  PayloadAction<ICurrencyConvertDetail>
> = (
  state: CurrencyConvertState,
  { payload }: PayloadAction<ICurrencyConvertDetail>,
) => {
  state.currencyConvertDetail.status = RequestStatus.Success;
  state.currencyConvertDetail.data = payload;
};
const createCurrencyConvertFailed: CaseReducer<CurrencyConvertState> = (
  state: CurrencyConvertState,
) => {
  state.currencyConvertDetail.status = RequestStatus.Failed;
};
const updateCurrencyConvertRequest: CaseReducer<
  CurrencyConvertState,
  PayloadAction<ICurrencyConvertDetail>
> = (state: CurrencyConvertState) => {
  state.currencyConvertDetail.status = RequestStatus.Loading;
};
const updateCurrencyConvertSuccess: CaseReducer<
  CurrencyConvertState,
  PayloadAction<ICurrencyConvertDetail>
> = (
  state: CurrencyConvertState,
  { payload }: PayloadAction<ICurrencyConvertDetail>,
) => {
  state.currencyConvertDetail.status = RequestStatus.Success;
  state.currencyConvertDetail.data = payload;
};
const updateCurrencyConvertFailed: CaseReducer<CurrencyConvertState> = (
  state: CurrencyConvertState,
) => {
  state.currencyConvertDetail.status = RequestStatus.Failed;
};
const appendCurrencyConvert: CaseReducer<
  CurrencyConvertState,
  PayloadAction<any>
> = (state: CurrencyConvertState, { payload }: PayloadAction<any>) => {
  if (state.currencyConvert?.data?.listCurrencyConvert) {
    state.currencyConvert.status = RequestStatus.Success;
    state.currencyConvert.data.listCurrencyConvert = [...payload];
  }
};
const setCanLoadMore = (
  state: CurrencyConvertState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetCurrencyConvert: CaseReducer<CurrencyConvertState> = (
  state: CurrencyConvertState,
) => {
  state.currencyConvert = {
    status: RequestStatus.Idle,
  };
};
const resetCurrencyConvertParams: CaseReducer<CurrencyConvertState> = (
  state: CurrencyConvertState,
) => {
  state.params = getDefaultCurrencyConvertRequestParams();
};
const setCurrencyConvertFilterParams = (
  state: CurrencyConvertState,
  { payload }: PayloadAction<GetCurrencyConvertRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  CurrencyConvertState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: CurrencyConvertState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<
  CurrencyConvertState,
  PayloadAction<any>
> = (state: CurrencyConvertState, { payload }: PayloadAction<any>) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<CurrencyConvertState> = (
  state: CurrencyConvertState,
) => {
  state.action.status = RequestStatus.Failed;
};
const currencyConvertSlice = createSlice({
  name: 'currencyConvert',
  initialState,
  reducers: {
    getCurrencyConvertRequest,
    getCurrencyConvertSuccess,
    getCurrencyConvertFailed,
    getDetailCurrencyConvertRequest,
    getDetailCurrencyConvertSuccess,
    getDetailCurrencyConvertFailed,
    createCurrencyConvertRequest,
    createCurrencyConvertSuccess,
    createCurrencyConvertFailed,
    updateCurrencyConvertRequest,
    updateCurrencyConvertSuccess,
    updateCurrencyConvertFailed,
    appendCurrencyConvert,
    setCanLoadMore,
    resetCurrencyConvert,
    setCurrencyConvertParams<K extends keyof GetCurrencyConvertRequestParamDTO>(
      state: CurrencyConvertState,
      action: PayloadAction<{
        key: K;
        value: GetCurrencyConvertRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setCurrencyConvertFilterParams,
    resetCurrencyConvertParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
  },
});
// Actions
export const currencyConvertActions = currencyConvertSlice.actions;
// Reducer
export const currencyConvertReducer = currencyConvertSlice.reducer;
// Selectors
export const selectCurrencyConvert = (state: AppState) =>
  state.currencyConvert?.currencyConvert;
export const selectParamCurrencyConvert = (state: AppState) =>
  state.currencyConvert?.params;
export const selectCanLoadMoreCurrencyConvert = (state: AppState) =>
  state.currencyConvert?.canLoadMore;
export const selectCurrencyConvertDetail = (state: AppState) =>
  state.currencyConvert?.currencyConvertDetail;
