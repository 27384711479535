import { Row } from 'antd';
import { documentTypeOpts } from 'app/components/BusinessInformation/index.helper';
import { SvgIcon } from 'app/components/SvgIcon';
import CustomerProfileTab from 'app/components/common/CustomerProfile';
import IdPassportInfo from 'app/components/common/IdOrPassportInfo';
import RakkarUserRole from 'app/components/common/RakkarUserRole';
import TextInfor from 'app/components/common/TextInfor';
import Vaults from 'app/components/common/Vaults';
import { STATUS_REQUEST } from 'constants/index';
import React, { useMemo, useState } from 'react';
import { StatusRequest, TitleInfo } from './component/index';
import styles from './index.module.scss';

interface Props {
  requestDetail?: any;
  isMask: boolean;
}

const RemoveAccess = ({ requestDetail, isMask }: Props) => {
  const isApproved = useMemo(
    () => requestDetail?.status === STATUS_REQUEST.APPROVED,
    [requestDetail?.status],
  );

  const isRejected = useMemo(
    () => requestDetail?.status === STATUS_REQUEST.REJECTED,
    [requestDetail?.status],
  );

  const [open, setOpen] = useState(false);

  const titleInfo = (
    <div className={styles['customer-inform']}>
      <Row>
        <TitleInfo label="Customer name" setOpenDrawer={setOpen}>
          {requestDetail?.customer?.name}
        </TitleInfo>
        <TitleInfo label="Business registration number" setOpenDrawer={setOpen}>
          {requestDetail?.customer?.businessRegistrationId}
        </TitleInfo>
      </Row>
    </div>
  );

  const checkIdentityDocument = useMemo(() => {
    return (
      (!!requestDetail &&
        documentTypeOpts?.find(
          item => item.value === requestDetail?.user?.documentType,
        )?.label) ||
      '-'
    );
  }, [requestDetail]);

  return (
    <React.Fragment>
      <div className={styles.container} data-testid="remove-user-request">
        <div className={styles.icon}>
          <SvgIcon name="remove_access_user" width={32} height={32} />
        </div>
        <div className={styles.title} data-testid="title">
          <div>Remove user access request</div>
          <StatusRequest isApproved={isApproved} isRejected={isRejected} />
        </div>
        {titleInfo}
        <div className={styles.content}>
          <div className={styles['user-inform']}>
            <div>
              <SvgIcon name="user-info" width={16} height={16} />
            </div>
            <div className={styles['user-inform__title']}>User information</div>
          </div>
          <Row>
            <TextInfor
              label="Name"
              text={
                <div className={styles.name} data-testid="user-info">
                  {requestDetail?.user?.name}
                </div>
              }
              isMask={isMask}
              isMaskable={true}
            />
            <TextInfor
              label="Role"
              text={
                <div className={styles.role}>
                  <div>
                    <RakkarUserRole userRole={requestDetail?.user?.role} />
                  </div>
                  {requestDetail?.user?.requiredApprover && (
                    <div className={styles.required}>
                      REQUIRED APPROVER
                      <SvgIcon
                        name="required-approver"
                        width={10}
                        height={12}
                      />
                    </div>
                  )}
                </div>
              }
            />
          </Row>
          <Row>
            <TextInfor
              label="Identity document"
              text={<div className={styles.role}>{checkIdentityDocument}</div>}
              dataTestId="identity-document"
            />
          </Row>
          {checkIdentityDocument && (
            <IdPassportInfo requestDetail={requestDetail} isMask={isMask} />
          )}
          <Row>
            <TextInfor
              label="Phone number"
              text={requestDetail?.user?.phoneNumber}
              dataTestId="phone-number"
              isMask={isMask}
              isMaskable={true}
            />
            <TextInfor
              label="Email"
              text={requestDetail?.user?.email}
              dataTestId="email"
              isMask={isMask}
              isMaskable={true}
            />
          </Row>
          <Vaults requestDetail={requestDetail} />
        </div>
        <CustomerProfileTab
          requestDetail={requestDetail}
          open={open}
          setOpen={setOpen}
          isMask={isMask}
        />
      </div>
    </React.Fragment>
  );
};

export default RemoveAccess;
