import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getWcRequestWeb3ConnectRequest,
  getDetailWcRequestWeb3Connect,
  createWcRequestWeb3ConnectRequest,
  updateWcRequestWeb3ConnectRequest,
  getWcRequestWeb3ConnectRequestPaginationConfig,
} from 'services/wcRequestWeb3Connect.service';
import { wcRequestWeb3ConnectActions } from 'store/slices/wcRequestWeb3Connect/wcRequestWeb3Connect.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetWcRequestWeb3ConnectRequestParamDTO, ICreateWcRequestWeb3Connect } from 'interfaces/wcRequestWeb3Connect';
const handleGetWcRequestWeb3ConnectRequest = function* ({
  payload,
}: PayloadAction<GetWcRequestWeb3ConnectRequestParamDTO>) {
  try {
    const res = yield call(getWcRequestWeb3ConnectRequest, payload);
    const params = yield select(state => state.wcRequestWeb3Connect.params) as any;
    const listWcRequestWeb3Connect = yield select(
      state => state.wcRequestWeb3Connect?.wcRequestWeb3Connect?.data?.listWcRequestWeb3Connect,
    ) as any;
    if (res) {
      yield put(
        wcRequestWeb3ConnectActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listWcRequestWeb3Connect || []), ...res.list];
        yield put(wcRequestWeb3ConnectActions.appendWcRequestWeb3Connect(newList));
      } else {
        yield put(
          wcRequestWeb3ConnectActions.getWcRequestWeb3ConnectSuccess({
            listWcRequestWeb3Connect: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcRequestWeb3ConnectActions.getWcRequestWeb3ConnectFailed());
  }
};
const handleGetDetailWcRequestWeb3ConnectRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailWcRequestWeb3Connect, payload);
    if (res) {
      yield put(wcRequestWeb3ConnectActions.getDetailWcRequestWeb3ConnectSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcRequestWeb3ConnectActions.getDetailWcRequestWeb3ConnectFailed());
  }
};
const handleCreateWcRequestWeb3ConnectRequest = function* ({
  payload,
}: PayloadAction<ICreateWcRequestWeb3Connect>) {
  try {
    const res = yield call(createWcRequestWeb3ConnectRequest, payload);
    if (res) {
      yield put(wcRequestWeb3ConnectActions.createWcRequestWeb3ConnectSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcRequestWeb3ConnectActions.createWcRequestWeb3ConnectFailed());
  }
};
const handleUpdateWcRequestWeb3ConnectRequest = function* ({
  payload,
}: PayloadAction<ICreateWcRequestWeb3Connect>) {
  try {
    const res = yield call(updateWcRequestWeb3ConnectRequest, payload);
    if (res) {
      yield put(wcRequestWeb3ConnectActions.updateWcRequestWeb3ConnectSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcRequestWeb3ConnectActions.updateWcRequestWeb3ConnectFailed());
  }
};
const handleGetWcRequestWeb3ConnectPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetWcRequestWeb3ConnectRequestParamDTO>) {
  try {
    const res = yield call(getWcRequestWeb3ConnectRequestPaginationConfig);
    if (res) {
      yield put(wcRequestWeb3ConnectActions.getWcRequestWeb3ConnectPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcRequestWeb3ConnectActions.getWcRequestWeb3ConnectPaginationConfigFailed());
  }
};
const wcRequestWeb3ConnectSaga = function* () {
  yield takeLatest(wcRequestWeb3ConnectActions.getWcRequestWeb3ConnectRequest.type, handleGetWcRequestWeb3ConnectRequest);
  yield takeLatest(wcRequestWeb3ConnectActions.createWcRequestWeb3ConnectRequest.type, handleCreateWcRequestWeb3ConnectRequest);
  yield takeLatest(wcRequestWeb3ConnectActions.updateWcRequestWeb3ConnectRequest.type, handleUpdateWcRequestWeb3ConnectRequest);
  yield takeLatest(
    wcRequestWeb3ConnectActions.getWcRequestWeb3ConnectPaginationConfigRequest.type,
    handleGetWcRequestWeb3ConnectPaginationConfigRequest,
  );
  yield takeLatest(
    wcRequestWeb3ConnectActions.getDetailWcRequestWeb3ConnectRequest.type,
    handleGetDetailWcRequestWeb3ConnectRequest,
  );
};
export default wcRequestWeb3ConnectSaga;
