import {
  AuthInfo,
  PermissionsResponse,
  RequestAuthResponse,
  UserProfile,
} from './../../../interfaces/auth';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'store/index';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';

interface AuthState {
  isLoggedIn: boolean;
  loadingLogin: RequestStatus;
  authInfo: RequestAuthResponse;
  permissions: ResponseData<PermissionsResponse>;
  profile: ResponseData<UserProfile>;
}

const initialState: AuthState = {
  isLoggedIn: false,
  authInfo: {
    status: RequestStatus.Idle,
    data: {
      AccessToken: '',
      ExpiresIn: 0,
      TokenType: '',
      RefreshToken: '',
      IdToken: '',
    },
  },
  loadingLogin: RequestStatus.Idle,
  permissions: {
    status: RequestStatus.Idle,
  },

  profile: {
    status: RequestStatus.Idle,
  },
};

const updateStatusLoadingLogin: CaseReducer<
  AuthState,
  PayloadAction<RequestStatus>
> = (state: AuthState, { payload }: PayloadAction<RequestStatus>) => {
  state.loadingLogin = payload;
};

const getUserProfileRequest: CaseReducer<AuthState> = (state: AuthState) => {
  state.profile.status = RequestStatus.Loading;
};

const getUserProfileSuccess: CaseReducer<
  AuthState,
  PayloadAction<UserProfile>
> = (state: AuthState, { payload }: PayloadAction<UserProfile>) => {
  state.profile.status = RequestStatus.Success;
  state.profile.data = payload;
};

const getUserProfileFailed: CaseReducer<AuthState> = (state: AuthState) => {
  state.profile.status = RequestStatus.Failed;
};

const getPermissionsRequest: CaseReducer<AuthState> = (state: AuthState) => {
  state.permissions.status = RequestStatus.Loading;
};

const getPermissionsSuccess: CaseReducer<
  AuthState,
  PayloadAction<PermissionsResponse>
> = (state: AuthState, { payload }: PayloadAction<PermissionsResponse>) => {
  state.permissions.status = RequestStatus.Success;
  state.permissions.data = payload;
};

const getPermissionsFailed: CaseReducer<AuthState> = (state: AuthState) => {
  state.permissions.status = RequestStatus.Failed;
};

const getAuthInfoRequest: CaseReducer<AuthState, PayloadAction<string>> = (
  state: AuthState,
  { payload }: PayloadAction<string>,
) => {
  state.authInfo.status = RequestStatus.Loading;
  state.loadingLogin = RequestStatus.Loading;
};

const getAuthInfoSuccess: CaseReducer<AuthState, PayloadAction<AuthInfo>> = (
  state: AuthState,
  { payload }: PayloadAction<AuthInfo>,
) => {
  state.authInfo.status = RequestStatus.Success;
  state.authInfo.data = payload;
  state.loadingLogin = RequestStatus.Success;
};

const getAuthInfoFailed: CaseReducer<AuthState> = (state: AuthState) => {
  state.authInfo.status = RequestStatus.Failed;
  state.loadingLogin = RequestStatus.Failed;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state: AuthState, { payload }: PayloadAction<any>) => {
      state.isLoggedIn = true;
    },

    logout: (state: AuthState) => {
      state.isLoggedIn = false;
      state.profile = {
        status: RequestStatus.Idle,
      };
    },

    updateStatusLoadingLogin,

    getAuthInfoRequest,
    getAuthInfoSuccess,
    getAuthInfoFailed,

    getPermissionsRequest,
    getPermissionsSuccess,
    getPermissionsFailed,

    getUserProfileRequest,
    getUserProfileSuccess,
    getUserProfileFailed,
  },
});

export const authActions = authSlice.actions;

export const { loginSuccess } = authSlice.actions;

export const authReducer = authSlice.reducer;

// Selectors
export const selectIsLoggedIn = (state: AppState) => state.auth.isLoggedIn;
export const selectLoadingLogin = (state: AppState) => state.auth.loadingLogin;
export const selectAuthInfo = (state: AppState) => state.auth.authInfo;
export const selectPermissions = (state: AppState) =>
  state.auth.permissions?.data?.permissions;

export const selectRoles = (state: AppState) =>
  state.auth.permissions?.data?.roles;

export const selectUserProfile = (state: AppState) => state.auth.profile.data;
