import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import Instance from './instance';

export const axiosBaseQuery =
  (url: string): BaseQueryFn<AxiosRequestConfig, unknown, unknown> =>
  async axiosRequestConfig => {
    try {
      const result = await Instance({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        url,
        ...axiosRequestConfig,
      });

      return {
        data: result,
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
