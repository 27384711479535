import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetSnapshotTokenPriceRequestParamDTO,
  GetSnapshotTokenPriceResponseDTO,
  ICreateSnapshotTokenPrice,
  ISnapshotTokenPriceDetail,
} from 'interfaces/snapshotTokenPrice';
export function getSnapshotTokenPriceRequest(
  params?: GetSnapshotTokenPriceRequestParamDTO,
): Promise<GetSnapshotTokenPriceResponseDTO> {
  return Instance.get(`/${PREFIX_API.SNAPSHOT_TOKEN_PRICE}`, {
    params,
  });
}
export function getDetailSnapshotTokenPrice(
  snapshotTokenPriceId: string,
): Promise<ISnapshotTokenPriceDetail> {
  return Instance.get(
    `/${PREFIX_API.SNAPSHOT_TOKEN_PRICE}/${snapshotTokenPriceId}`,
  );
}
export function createSnapshotTokenPriceRequest(
  snapshotTokenPrice: ICreateSnapshotTokenPrice,
): Promise<GetSnapshotTokenPriceResponseDTO> {
  return Instance.post(
    `/${PREFIX_API.SNAPSHOT_TOKEN_PRICE}`,
    snapshotTokenPrice,
  );
}
export function updateSnapshotTokenPriceRequest(
  snapshotTokenPrice: ICreateSnapshotTokenPrice,
): Promise<GetSnapshotTokenPriceResponseDTO> {
  const payload: any = { ...snapshotTokenPrice };
  const id = snapshotTokenPrice.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.SNAPSHOT_TOKEN_PRICE}/${id}`, payload);
}
