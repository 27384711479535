import { Divider, Row, Spin } from 'antd';
import {
  BusinessTypeOther,
  latestRiskLevelOpts,
  purposeOfRelationshipOpts,
  sourceOfWealthOpts,
} from 'app/components/BusinessInformation/index.helper';
import {
  CompanyType,
  ContactPersonType,
  SourceOfFunds,
} from 'enum/addNewCustomer';
import { RequestStatus } from 'interfaces/request';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  commonActions,
  selectBusinessTypes,
  selectCustomerEntityRelation,
  selectSourceOfFund,
  selectTypeOfCompany,
} from 'store/slices/common';
import { selectCustomerProfile } from 'store/slices/customerProfile';

import EditedTextInform from 'app/components/common/EditedTextInform';
import {
  selectBussinessInformation,
  selectOldBussinessInformation,
} from 'store/slices/addNewCustomer';
import { compareStrings, getNameFromArrayById } from 'utils/common';
import { formatDate } from 'utils/date';
import { getTextAddress, mapCountry } from 'utils/helpers';
import styles from './index.module.scss';
import { SvgIcon } from 'app/components/SvgIcon';
import { STATUS_REQUEST } from 'constants/index';
import { renderStatus } from 'app/components/PendingRequestDrawer/MarkedAsLostDevice';
import { checkBusinessTypeOther } from 'app/components/BusinessInformation';

interface Props {
  isMask?: boolean;
  isRequest?: boolean;
  requestStatus?: string;
}

const ReviewAndSubmitEditCus = ({
  isMask = false,
  isRequest = false,
  requestStatus,
}: Props) => {
  const dispatch = useAppDispatch();
  const bussiness = useAppSelector(selectBusinessTypes)?.data;
  const customerProfile = useAppSelector(selectCustomerProfile);
  const typeOfCompanyStore = useAppSelector(selectTypeOfCompany)?.data;
  const sourceOfFundStore = useAppSelector(selectSourceOfFund)?.data;
  const newBusinessInform = useAppSelector(selectBussinessInformation);
  const oldBusinessInform = useAppSelector(selectOldBussinessInformation);
  const customerEntityRelationStore = useAppSelector(
    selectCustomerEntityRelation,
  );
  const [customerEntityRelation, setCustomerEntityRelation] = useState<
    {
      label: string;
      value: number;
      countryCode: string;
    }[]
  >([]);

  const oldEntityRelation = customerEntityRelation?.find(
    e => e.value === oldBusinessInform.entityRelationId,
  );

  const newEntityRelation = customerEntityRelation?.find(
    e => e.value === newBusinessInform.entityRelationId,
  );

  const otherContactInfo = useMemo(() => {
    const person = newBusinessInform?.additionalContacts?.find(
      i => i?.type === ContactPersonType.GENERAL,
    );
    return person || newBusinessInform?.otherPerson;
  }, [newBusinessInform?.additionalContacts, newBusinessInform?.otherPerson]);

  const oldOtherContactInfo = useMemo(() => {
    const person = oldBusinessInform?.additionalContacts?.find(
      i => i?.type === ContactPersonType.GENERAL,
    );
    return person;
  }, [oldBusinessInform?.additionalContacts]);

  const billingContactInfo = useMemo(() => {
    const person = newBusinessInform?.additionalContacts?.find(
      i => i.type === ContactPersonType.BILLING,
    );
    return person || newBusinessInform?.billingPerson;
  }, [newBusinessInform?.additionalContacts]);

  const oldBillingContactInfo = useMemo(() => {
    const person = oldBusinessInform?.additionalContacts?.find(
      i => i.type === ContactPersonType.BILLING,
    );
    return person;
  }, [oldBusinessInform?.additionalContacts]);

  useEffect(() => {
    dispatch(commonActions.getTypeOfCompanyRequest({}));
    dispatch(commonActions.getSourceOfFundRequest({}));
  }, [dispatch]);

  useEffect(() => {
    if (customerEntityRelationStore?.data) {
      const options = customerEntityRelationStore.data.map(item => {
        return {
          label: mapCountry(item.countryCode),
          value: item.id,
          countryCode: item.countryCode,
        };
      });
      setCustomerEntityRelation(options);
    }
  }, [customerEntityRelationStore]);

  const businessType = useMemo(() => {
    const isOtherBusinessType = checkBusinessTypeOther(
      Number(newBusinessInform?.businessTypeId),
      bussiness,
    );

    if (isOtherBusinessType && newBusinessInform?.businessTypeOther) {
      return newBusinessInform.businessTypeOther;
    }
    return (
      (!!newBusinessInform &&
        getNameFromArrayById(bussiness, newBusinessInform.businessTypeId)) ||
      ''
    );
  }, [bussiness, newBusinessInform]);

  const oldBusinessType = useMemo(() => {
    const isOtherBusinessType = checkBusinessTypeOther(
      Number(oldBusinessInform?.businessTypeId),
      bussiness,
    );

    if (isOtherBusinessType && oldBusinessInform?.businessTypeOther) {
      return oldBusinessInform.businessTypeOther;
    }
    return (
      (!!oldBusinessInform &&
        getNameFromArrayById(bussiness, oldBusinessInform.businessTypeId)) ||
      ''
    );
  }, [bussiness, oldBusinessInform]);

  const companyType = useMemo(() => {
    if (
      newBusinessInform &&
      newBusinessInform?.companyType === CompanyType.OTHER &&
      newBusinessInform?.companyTypeOther
    ) {
      return newBusinessInform.companyTypeOther;
    }

    return (
      (!!newBusinessInform &&
        typeOfCompanyStore?.find(
          item => item.id === newBusinessInform.companyType,
        )?.name) ||
      ''
    );
  }, [newBusinessInform, typeOfCompanyStore]);

  const oldCompanyType = useMemo(() => {
    if (
      oldBusinessInform &&
      oldBusinessInform?.companyType === CompanyType.OTHER &&
      oldBusinessInform?.companyTypeOther
    ) {
      return oldBusinessInform.companyTypeOther;
    }

    return (
      (!!oldBusinessInform &&
        typeOfCompanyStore?.find(
          item => item.id === oldBusinessInform.companyType,
        )?.name) ||
      ''
    );
  }, [oldBusinessInform, typeOfCompanyStore]);

  const originOfFunds = useMemo(() => {
    return (!!newBusinessInform && newBusinessInform.originOfFunds) || '';
  }, [newBusinessInform]);

  const oldOriginOfFunds = useMemo(() => {
    return (!!oldBusinessInform && oldBusinessInform.originOfFunds) || '';
  }, [oldBusinessInform]);

  const sourceOfWealth = useMemo(() => {
    if (!!newBusinessInform && newBusinessInform.sourceOfWealthOther)
      return newBusinessInform.sourceOfWealthOther;
    return (
      (!!newBusinessInform &&
        sourceOfWealthOpts?.find(
          item => item.value === newBusinessInform.sourceOfWealth,
        )?.label) ||
      ''
    );
  }, [newBusinessInform]);

  const oldSourceOfWealth = useMemo(() => {
    if (!!oldBusinessInform && oldBusinessInform.sourceOfWealthOther)
      return oldBusinessInform.sourceOfWealthOther;
    return (
      (!!oldBusinessInform &&
        sourceOfWealthOpts?.find(
          item => item.value === oldBusinessInform.sourceOfWealth,
        )?.label) ||
      ''
    );
  }, [oldBusinessInform]);

  const sourceOfFunds = useMemo(() => {
    if (!!newBusinessInform && newBusinessInform.sourceOfFunds) {
      const listSelected = newBusinessInform.sourceOfFunds.split(',');
      const listSourceOfFunds: string[] = [];

      listSelected.forEach(element => {
        if (
          element === SourceOfFunds.OTHER &&
          newBusinessInform?.sourceOfFundOther
        ) {
          listSourceOfFunds.push(newBusinessInform?.sourceOfFundOther);
        } else {
          const source =
            sourceOfFundStore?.find(item => item.id === element)?.name || '';
          listSourceOfFunds.push(source);
        }
      });
      listSourceOfFunds.sort(compareStrings);
      return listSourceOfFunds.join(', ');
    }
  }, [newBusinessInform, sourceOfFundStore]);

  const oldSourceOfFunds = useMemo(() => {
    if (!!oldBusinessInform && oldBusinessInform.sourceOfFunds) {
      const listSelected = oldBusinessInform.sourceOfFunds.split(',');
      const listSourceOfFunds: string[] = [];

      listSelected.forEach(element => {
        if (
          element === SourceOfFunds.OTHER &&
          oldBusinessInform?.sourceOfFundOther
        ) {
          listSourceOfFunds.push(oldBusinessInform?.sourceOfFundOther);
        } else {
          const source =
            sourceOfFundStore?.find(item => item.id === element)?.name || '';
          listSourceOfFunds.push(source);
        }
      });
      listSourceOfFunds.sort(compareStrings);
      return listSourceOfFunds.join(', ');
    }
  }, [oldBusinessInform, sourceOfFundStore]);

  const purposeOfRelationship = useMemo(() => {
    if (!!newBusinessInform && newBusinessInform.purposeOfRelationshipOther)
      return newBusinessInform.purposeOfRelationshipOther;
    return (
      (!!newBusinessInform &&
        purposeOfRelationshipOpts?.find(
          item => item.value === newBusinessInform.purposeOfRelationship,
        )?.label) ||
      ''
    );
  }, [newBusinessInform]);

  const oldPurposeOfRelationship = useMemo(() => {
    if (!!oldBusinessInform && oldBusinessInform.purposeOfRelationshipOther)
      return oldBusinessInform.purposeOfRelationshipOther;
    return (
      (!!oldBusinessInform &&
        purposeOfRelationshipOpts?.find(
          item => item.value === oldBusinessInform.purposeOfRelationship,
        )?.label) ||
      ''
    );
  }, [oldBusinessInform]);

  const lastestRiskLevel = useMemo(() => {
    return (
      (!!newBusinessInform &&
        latestRiskLevelOpts?.find(
          item => item.value === newBusinessInform.lastestRiskLevel,
        )?.label) ||
      ''
    );
  }, [newBusinessInform]);

  const oldLastestRiskLevel = useMemo(() => {
    return (
      (!!oldBusinessInform &&
        latestRiskLevelOpts?.find(
          item => item.value === oldBusinessInform.lastestRiskLevel,
        )?.label) ||
      ''
    );
  }, [oldBusinessInform]);

  const registrationAddress = useMemo(() => {
    if (!newBusinessInform) return '';
    return getTextAddress(newBusinessInform.registrationAddress);
  }, [newBusinessInform]);

  const oldRegistrationAddress = useMemo(() => {
    if (!oldBusinessInform) return '';
    return getTextAddress(oldBusinessInform.registrationAddress);
  }, [oldBusinessInform]);

  const currentAddress = useMemo(() => {
    if (!newBusinessInform) return '';
    return getTextAddress(newBusinessInform.currentAddress);
  }, [newBusinessInform]);

  const oldCurrentAddress = useMemo(() => {
    if (!oldBusinessInform) return '';
    return getTextAddress(oldBusinessInform.currentAddress);
  }, [oldBusinessInform]);

  const billingAddress = useMemo(() => {
    if (!newBusinessInform) return '';
    return getTextAddress(newBusinessInform.billingAddress);
  }, [newBusinessInform]);

  const oldBillingAddress = useMemo(() => {
    if (!oldBusinessInform) return '';
    return getTextAddress(oldBusinessInform.billingAddress);
  }, [oldBusinessInform]);

  if (!newBusinessInform || customerProfile.status === RequestStatus.Loading) {
    return (
      <div className={styles.loading} data-testid="loading-spinner">
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.general}>
      <div className={styles.content}>
        {isRequest ? (
          <>
            <SvgIcon name="company-creation" width={32} height={32} />
            <Row className={styles.reviewing__title} data-testid="title">
              Edit customer profile request
              {(requestStatus === STATUS_REQUEST.APPROVED ||
                requestStatus === STATUS_REQUEST.REJECTED) &&
                renderStatus(requestStatus)}
            </Row>
          </>
        ) : (
          <Row className={styles.reviewing__title}>Review and submit</Row>
        )}
        {/* IDENTIFICATION INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Identification information
        </Divider>
        <Row className={styles.body}>
          <EditedTextInform
            newData={newBusinessInform.customerName}
            oldData={oldBusinessInform.customerName}
            label="Business registered name"
            dataTestId="customer-name"
          />
          <EditedTextInform
            label="Customer short name"
            newData={newBusinessInform.customerShortName}
            oldData={oldBusinessInform.customerShortName}
            dataTestId="short-name"
          />
          <EditedTextInform
            label="Business registration number"
            newData={newBusinessInform.businessRegistrationId}
            oldData={oldBusinessInform.businessRegistrationId}
            dataTestId="registration-id"
          />
          <EditedTextInform
            label="Tax ID"
            newData={newBusinessInform.taxId}
            oldData={oldBusinessInform.taxId}
            dataTestId="tax-id"
          />
          <EditedTextInform
            label="Rakkar ID"
            newData={newBusinessInform.rakkarId}
            oldData={oldBusinessInform.rakkarId}
            dataTestId="rakkar-id"
          />
          <EditedTextInform
            label="Customer group"
            newData={newBusinessInform.groupName}
            oldData={oldBusinessInform.groupName}
            dataTestId="rakkar-group-name"
          />
        </Row>

        {/* LEGAL INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Legal information
        </Divider>
        <Row className={styles.body}>
          <EditedTextInform
            label="Nature of business"
            newData={businessType}
            oldData={oldBusinessType}
            dataTestId="business-type"
          />
          <EditedTextInform
            label="Business entity type"
            newData={companyType}
            oldData={oldCompanyType}
            dataTestId="company-type"
          />
          <EditedTextInform
            label="Date of incorporation"
            newData={formatDate(newBusinessInform.registrationDate)}
            oldData={formatDate(oldBusinessInform.registrationDate)}
            dataTestId="registration-date"
          />
        </Row>
        {/* LOCATION INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Location infomation
        </Divider>
        <Row className={styles.body}>
          <EditedTextInform
            span={24}
            label="Registered address"
            newData={registrationAddress}
            oldData={oldRegistrationAddress}
            dataTestId="registration-address"
          />
          <EditedTextInform
            span={24}
            label="Principle place of business"
            newData={currentAddress}
            oldData={oldCurrentAddress}
            dataTestId="current-address"
          />
          <EditedTextInform
            span={24}
            label="Billing address"
            newData={billingAddress}
            oldData={oldBillingAddress}
            dataTestId="billing-address"
          />
        </Row>

        {/* OTHER INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Other information
        </Divider>
        <Row className={styles.body}>
          <EditedTextInform
            span={12}
            label="Purpose and intended nature"
            newData={purposeOfRelationship}
            oldData={oldPurposeOfRelationship}
            dataTestId="purpose-relationship"
          />
          <EditedTextInform
            span={12}
            label="Origin of funds"
            newData={originOfFunds}
            oldData={oldOriginOfFunds}
            dataTestId="original-of-funds"
          />
          <EditedTextInform
            span={12}
            label="Source of wealth"
            newData={sourceOfWealth}
            oldData={oldSourceOfWealth}
            dataTestId="source-of-wealth"
          />
          <EditedTextInform
            span={12}
            label="Source of funds"
            newData={sourceOfFunds}
            oldData={oldSourceOfFunds}
            dataTestId="source-of-funds"
          />
          <EditedTextInform
            span={12}
            label="Date of Latest risk assessment"
            newData={formatDate(newBusinessInform.dateOfLastestRiskLevel)}
            oldData={formatDate(oldBusinessInform.dateOfLastestRiskLevel)}
            dataTestId="date-of-latest"
          />
          <EditedTextInform
            span={12}
            label="Latest risk level"
            newData={lastestRiskLevel}
            oldData={oldLastestRiskLevel}
            dataTestId="risk-level"
          />
          <EditedTextInform
            span={12}
            label="Credit term"
            newData={newBusinessInform.creditTerm || ''}
            oldData={oldBusinessInform.creditTerm || ''}
            dataTestId="credit-term"
          />
          <EditedTextInform
            span={12}
            label="Rakkar entity"
            newData={mapCountry(newEntityRelation?.countryCode || '')}
            oldData={mapCountry(oldEntityRelation?.countryCode || '')}
            dataTestId="entity-relation-id"
          />
        </Row>

        {/* CONTACT INFORMATION */}
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Contact information
        </Divider>

        <Row className={styles.content__identification}>
          Main contact person
        </Row>
        <Row className={styles.body}>
          <EditedTextInform
            span={12}
            label="First name"
            newData={newBusinessInform.firstName}
            oldData={oldBusinessInform.firstName}
            dataTestId="first-name"
            isMask={isMask}
            isMaskable={true}
          />
          <EditedTextInform
            span={12}
            label="Last name"
            newData={newBusinessInform.lastName}
            oldData={oldBusinessInform.lastName}
            dataTestId="last-name"
            isMask={isMask}
            isMaskable={true}
          />
          <EditedTextInform
            span={12}
            label="Phone number"
            newData={`${newBusinessInform.countryCode} ${newBusinessInform.phoneNumber}`}
            oldData={`${oldBusinessInform.countryCode} ${oldBusinessInform.phoneNumber}`}
            dataTestId="phone-number"
            isMask={isMask}
            isMaskable={true}
          />
          <EditedTextInform
            span={12}
            label="Email"
            newData={newBusinessInform.email}
            oldData={oldBusinessInform.email}
            dataTestId="email"
            isMask={isMask}
            isMaskable={true}
          />
          <EditedTextInform
            span={12}
            label="Position"
            newData={newBusinessInform.position}
            oldData={oldBusinessInform.position}
            dataTestId="position"
            isMask={isMask}
            isMaskable={true}
          />
        </Row>

        {/* CONTACT INFORMATION */}
        {(otherContactInfo || oldOtherContactInfo) && (
          <>
            <Row className={styles.content__identification}>
              Alternative contact person (optional)
            </Row>
            <Row className={styles.body}>
              <EditedTextInform
                span={12}
                label="First name"
                newData={newBusinessInform.otherPerson?.firstName}
                oldData={oldBusinessInform.otherPerson?.firstName}
                isMask={isMask}
                isMaskable={true}
                dataTestId="other-first-name"
              />
              <EditedTextInform
                span={12}
                label="Last name"
                newData={newBusinessInform.otherPerson?.lastName}
                oldData={oldBusinessInform.otherPerson?.lastName}
                isMask={isMask}
                isMaskable={true}
                dataTestId="other-last-name"
              />
              <EditedTextInform
                span={12}
                label="Phone number"
                newData={
                  newBusinessInform.otherPerson &&
                  `${newBusinessInform.otherPerson?.countryCode} ${newBusinessInform.otherPerson?.phoneNumber}`
                }
                oldData={
                  oldBusinessInform &&
                  `${oldBusinessInform.otherPerson?.countryCode} ${oldBusinessInform.otherPerson?.phoneNumber}`
                }
                isMask={isMask}
                isMaskable={true}
                dataTestId="other-phone-number"
              />
              <EditedTextInform
                span={12}
                label="Email"
                newData={newBusinessInform.otherPerson?.email}
                oldData={oldBusinessInform.otherPerson?.email}
                isMask={isMask}
                isMaskable={true}
                dataTestId="other-email"
              />
              <EditedTextInform
                span={12}
                label="Position"
                newData={newBusinessInform.otherPerson?.position}
                oldData={oldBusinessInform.otherPerson?.position}
                isMask={isMask}
                isMaskable={true}
                dataTestId="other-position"
              />
            </Row>
          </>
        )}

        {/* CONTACT INFORMATION */}
        {billingContactInfo || oldBillingContactInfo ? (
          <>
            <Divider
              orientationMargin="0"
              className={styles.content__header}
              orientation="left"
            >
              Billing contact person
            </Divider>
            <Row className={styles.body}>
              <EditedTextInform
                span={12}
                label="First name"
                newData={newBusinessInform.billingPerson?.firstName}
                oldData={oldBillingContactInfo?.firstName}
                isMask={isMask}
                isMaskable={true}
                dataTestId="billing-first-name"
              />
              <EditedTextInform
                span={12}
                label="Last name"
                newData={newBusinessInform.billingPerson?.lastName}
                oldData={oldBillingContactInfo?.lastName}
                isMask={isMask}
                isMaskable={true}
                dataTestId="billing-last-name"
              />
              <EditedTextInform
                span={12}
                label="Phone number"
                newData={
                  newBusinessInform.billingPerson &&
                  `${newBusinessInform.billingPerson?.countryCode} ${newBusinessInform.billingPerson?.phoneNumber}`
                }
                oldData={
                  oldBillingContactInfo &&
                  `${oldBillingContactInfo?.countryCode} ${oldBillingContactInfo?.phoneNumber}`
                }
                isMask={isMask}
                isMaskable={true}
                dataTestId="billing-phone-number"
              />
              <EditedTextInform
                span={12}
                label="Email"
                newData={newBusinessInform.billingPerson?.email}
                oldData={oldBillingContactInfo?.email}
                isMask={isMask}
                isMaskable={true}
                dataTestId="billing-email"
              />
            </Row>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ReviewAndSubmitEditCus;
