import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BusinessTypeInterface,
  CustomerEntityRelation,
  TotalRequest,
  TypeOfCompany,
  TypeOfCompanyParams,
  WorkSpaces,
} from 'interfaces/businessInformation';
import { Creator, RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import { AppState } from '../..';

interface CommonState {
  isMobile: boolean;
  isMobileOnly: boolean;
  workspaces: ResponseData<WorkSpaces>;
  businessTypes: ResponseData<BusinessTypeInterface[]>;
  totalRequest: ResponseData<TotalRequest>;
  creatorsRequest: ResponseData<Creator[]>;
  customerEntityRelation: ResponseData<CustomerEntityRelation[]>;
  typeOfCompany: ResponseData<TypeOfCompany[]>;
  sourceOfFund: ResponseData<TypeOfCompany[]>;
  isOpenPopupOnGoingStaking: boolean;
}

const initialState: CommonState = {
  isMobile: true,
  isMobileOnly: true,
  workspaces: {
    data: {
      hot: [],
      cold: [],
    },
    status: RequestStatus.Idle,
  },
  businessTypes: {
    status: RequestStatus.Idle,
  },
  totalRequest: {
    status: RequestStatus.Idle,
  },
  creatorsRequest: {
    status: RequestStatus.Idle,
  },
  customerEntityRelation: {
    status: RequestStatus.Idle,
  },
  isOpenPopupOnGoingStaking: false,
  typeOfCompany: {
    status: RequestStatus.Idle,
  },
  sourceOfFund: {
    status: RequestStatus.Idle,
  },
};

const setViewPort = (
  state: CommonState,
  { payload }: PayloadAction<number>,
) => {
  const windowWidth = payload;
  state.isMobile = windowWidth <= 768;
  state.isMobileOnly = windowWidth <= 480;
};

const getWorkSpacesRequest: CaseReducer<CommonState> = (state: CommonState) => {
  state.workspaces.status = RequestStatus.Loading;
};

const getWorkSpacesSuccess: CaseReducer<
  CommonState,
  PayloadAction<WorkSpaces>
> = (state: CommonState, { payload }: PayloadAction<WorkSpaces>) => {
  state.workspaces.status = RequestStatus.Success;
  state.workspaces.data = payload;
};

const getWorkSpacesFailed: CaseReducer<CommonState> = (state: CommonState) => {
  state.workspaces.status = RequestStatus.Failed;
};

const getBusinessTypesRequest: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.businessTypes.status = RequestStatus.Loading;
};

const getBusinessTypesSuccess: CaseReducer<
  CommonState,
  PayloadAction<BusinessTypeInterface[]>
> = (
  state: CommonState,
  { payload }: PayloadAction<BusinessTypeInterface[]>,
) => {
  state.businessTypes.status = RequestStatus.Success;
  state.businessTypes.data = payload;
};

const getBusinessTypesFailed: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.businessTypes.status = RequestStatus.Failed;
};

const getTotalRequestRequest: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.totalRequest.status = RequestStatus.Loading;
};

const getTotalRequestSuccess: CaseReducer<
  CommonState,
  PayloadAction<TotalRequest>
> = (state: CommonState, { payload }: any) => {
  state.totalRequest.status = RequestStatus.Success;
  if (
    typeof payload?.totalPendingRequest === 'object' &&
    typeof payload?.totalPendingRequest !== null
  ) {
    payload.totalPendingRequest = payload.totalPendingRequest.total;
  }
  state.totalRequest.data = payload;
};

const getTotalRequestFailed: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.totalRequest.status = RequestStatus.Failed;
};

const getCreatorsRequestRequest: CaseReducer<
  CommonState,
  PayloadAction<any>
> = (state: CommonState) => {
  state.creatorsRequest.status = RequestStatus.Loading;
};

const getCreatorsRequestSuccess: CaseReducer<
  CommonState,
  PayloadAction<Creator[]>
> = (state: CommonState, { payload }: PayloadAction<Creator[]>) => {
  state.creatorsRequest.status = RequestStatus.Success;
  state.creatorsRequest.data = payload;
};

const getCreatorsRequestFailed: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.creatorsRequest.status = RequestStatus.Failed;
};

const getCustomerEntityRelationRequest: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.customerEntityRelation.status = RequestStatus.Loading;
};

const getCustomerEntityRelationSuccess: CaseReducer<
  CommonState,
  PayloadAction<CustomerEntityRelation[]>
> = (
  state: CommonState,
  { payload }: PayloadAction<CustomerEntityRelation[]>,
) => {
  state.customerEntityRelation.status = RequestStatus.Success;
  state.customerEntityRelation.data = payload;
};

const getCustomerEntityRelationFailed: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.customerEntityRelation.status = RequestStatus.Failed;
};

const getTypeOfCompanyRequest: CaseReducer<
  CommonState,
  PayloadAction<TypeOfCompanyParams>
> = (state: CommonState) => {
  state.typeOfCompany.status = RequestStatus.Loading;
};

const getTypeOfCompanySuccess: CaseReducer<
  CommonState,
  PayloadAction<TypeOfCompany[]>
> = (state: CommonState, { payload }: PayloadAction<TypeOfCompany[]>) => {
  state.typeOfCompany.status = RequestStatus.Success;
  state.typeOfCompany.data = payload;
};

const getTypeOfCompanyFailed: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.typeOfCompany.status = RequestStatus.Failed;
};

const getSourceOfFundRequest: CaseReducer<
  CommonState,
  PayloadAction<TypeOfCompanyParams>
> = (state: CommonState) => {
  state.sourceOfFund.status = RequestStatus.Loading;
};

const getSourceOfFundSuccess: CaseReducer<
  CommonState,
  PayloadAction<TypeOfCompany[]>
> = (state: CommonState, { payload }: PayloadAction<TypeOfCompany[]>) => {
  state.sourceOfFund.status = RequestStatus.Success;
  state.sourceOfFund.data = payload;
};

const getSourceOfFundFailed: CaseReducer<CommonState> = (
  state: CommonState,
) => {
  state.sourceOfFund.status = RequestStatus.Failed;
};

const setOpenPopupOnGoingStaking = (
  state: CommonState,
  { payload }: PayloadAction<boolean>,
) => {
  state.isOpenPopupOnGoingStaking = payload;
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setViewPort,

    getWorkSpacesRequest,
    getWorkSpacesSuccess,
    getWorkSpacesFailed,

    getBusinessTypesRequest,
    getBusinessTypesSuccess,
    getBusinessTypesFailed,

    getTotalRequestRequest,
    getTotalRequestSuccess,
    getTotalRequestFailed,

    getCreatorsRequestRequest,
    getCreatorsRequestSuccess,
    getCreatorsRequestFailed,

    getCustomerEntityRelationRequest,
    getCustomerEntityRelationSuccess,
    getCustomerEntityRelationFailed,

    getTypeOfCompanyRequest,
    getTypeOfCompanySuccess,
    getTypeOfCompanyFailed,

    getSourceOfFundRequest,
    getSourceOfFundSuccess,
    getSourceOfFundFailed,

    setOpenPopupOnGoingStaking,
  },
});

// Actions
export const commonActions = commonSlice.actions;

// Reducer
export const commonReducer = commonSlice.reducer;

// Selectors
export const selectIsMobile = (state: AppState) => state.common.isMobile;

export const selectIsMobileOnly = (state: AppState) =>
  state.common.isMobileOnly;

export const selectWorkspaces = (state: AppState) => state.common.workspaces;

export const selectBusinessTypes = (state: AppState) =>
  state.common?.businessTypes;

export const selectTotalRequest = (state: AppState) =>
  state.common.totalRequest;

export const selectCreatorsRequest = (state: AppState) =>
  state.common.creatorsRequest;

export const selectCustomerEntityRelation = (state: AppState) =>
  state.common.customerEntityRelation;

export const selectOpenPopUpOnGoing = (state: AppState) =>
  state.common.isOpenPopupOnGoingStaking;

export const selectTypeOfCompany = (state: AppState) =>
  state.common.typeOfCompany;

export const selectSourceOfFund = (state: AppState) =>
  state.common.sourceOfFund;
