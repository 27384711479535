import { Drawer, Menu, Row, Tabs } from 'antd';
import { ListActionNeedToCheckPermission } from 'enum/common';
import { TAB_PROFILE } from 'enum/profile';
import { Customer } from 'interfaces/customers';
import { RequestStatus } from 'interfaces/request';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectPermissions } from 'store/slices/auth';
import {
  customersActions,
  selectStatusDeleteCustomer,
} from 'store/slices/customers';
import { checkPermissions } from 'utils/common';
import { SvgIcon } from '../../../../app/components/SvgIcon';
import ModalConfirmDeleteCustomer from './ModalConfirmDeleteCustomer';
import { AdminQuorumTab } from './Tabs/AdminQuorum';
import TabGeneral from './Tabs/General';
import { history } from 'utils/history';
import { selectCustomerProfile } from 'store/slices/customerProfile';
import styles from './index.module.scss';
import { STATUS } from 'constants/index';
import UserListing from 'app/components/UserListing';
import { ProductSubscriptionTab } from './Tabs/ProductSubscription';

interface Props {
  id: string;
  name: string;
  open?: boolean;
  onClose?: () => void;
  item?: Customer;
}

const CustomerProfile = ({
  id,
  name,
  open,
  onClose,
  item,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMaskedProfile, setIsMaskedProfile] = useState<boolean>(true);
  const [titleModal, setTitleModal] = useState('');
  const dispatch = useDispatch();
  const statusDeleteCus = useAppSelector(selectStatusDeleteCustomer);
  const permissions = useAppSelector(selectPermissions);
  const customerProfile = useAppSelector(selectCustomerProfile)?.data;
  const [requestId, setRequestId] = useState<string>('');

  const allowEditCus = checkPermissions(
    ListActionNeedToCheckPermission.EDIT_CUSTOMER,
    permissions,
  );

  const allowDeactiveCus = checkPermissions(
    ListActionNeedToCheckPermission.DEACTIVE_CUSTOMER,
    permissions,
  );
  const allowViewUserListing = checkPermissions(
    ListActionNeedToCheckPermission.VIEW_USER_LIST,
    permissions,
  );
  const allowViewDetailUser = checkPermissions(
    ListActionNeedToCheckPermission.VIEW_USER_DETAIL,
    permissions,
  );

  useEffect(() => {
    if (name) {
      setTitleModal(`You confirm to disable ${name}?`);
    }
  }, [name]);

  useEffect(() => {
    if (
      statusDeleteCus?.status === RequestStatus.Failed ||
      statusDeleteCus?.status === RequestStatus.Success
    ) {
      setIsOpen(false);
      dispatch(customersActions.resetDeleteCustomer());
      onClose && onClose();
    }
  }, [statusDeleteCus?.status, dispatch, onClose]);

  useEffect(() => {
    if (customerProfile?.requestId) {
      setRequestId(customerProfile.requestId);
    }
  }, [customerProfile]);

  const handleDeleteCustomer = () => {
    if (id) {
      dispatch(customersActions.deleteCustomerRequest(`${id}`));
    }
  };

  const handleViewRequest = useCallback(() => {
    if (!requestId) {
      return;
    }
    history.replace({
      pathname: '/pending-request',
      search: `id=${requestId}`,
    });
  }, [requestId]);

  const menu = (
    <Menu
      items={[
        ...(allowEditCus
          ? [
              {
                key: '1',
                label: (
                  <div className={styles.action}>
                    <div className={styles.text}>Edit Customer Profile</div>
                  </div>
                ),
              },
            ]
          : []),
        ...(item?.status === STATUS.ACTIVE && allowDeactiveCus
          ? [
              {
                key: '2',
                label: (
                  <div
                    className={`${styles.action} ${styles.delete}`}
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <div className={styles.text}>Deactivate Customer</div>
                  </div>
                ),
              },
            ]
          : []),
      ]}
    />
  );

  return (
    <div className={styles.container}>
      <Drawer
        width={1000}
        placement="right"
        onClose={onClose}
        forceRender
        maskClosable={true}
        open={open}
        keyboard={false}
        title={
          <Row style={{ alignItems: 'center', gap: 8 }}>
            Customer Profile
            <SvgIcon
              name={isMaskedProfile ? 'black-eye' : 'black-eye-off'}
              onClick={() => setIsMaskedProfile(!isMaskedProfile)}
              width={16}
              height={isMaskedProfile ? 12 : 16}
              style={{ cursor: 'pointer' }}
            />
          </Row>
        }
        className={styles.drawerProfile}
      >
        {/* <p>View user profile {id}</p> */}
        <div className={styles.profile}>
          <div className={styles.profile__top}>
            <span className={styles.name}>{name}</span>
          </div>
          <div>
            {' '}
            {requestId && customerProfile?.isPendingRequest && customerProfile && (
              <div className={styles.description}>
                <span className={styles.description__text}>
                  This customer has a pending request awaiting for approval{' '}
                </span>
                <span
                  className={styles.description__viewRequest}
                  onClick={handleViewRequest}
                >
                  View request
                </span>
              </div>
            )}
          </div>
          <Tabs
            className={styles.tabProfile}
            defaultActiveKey={TAB_PROFILE.GENERAL}
            items={[
              ...(allowViewDetailUser
                ? [
                    {
                      label: `General`,
                      key: TAB_PROFILE.GENERAL,
                      children: (
                        <TabGeneral id={id} isMaskedProfile={isMaskedProfile} />
                      ),
                    },
                    {
                      label: `Product subscription`,
                      key: TAB_PROFILE.PRODUCT_SUBSCRIPTION,
                      children: (
                        <ProductSubscriptionTab
                          customerId={id}
                          onUpdateFeeSuccess={onClose}
                        />
                      ),
                    },
                  ]
                : []),
              ...(allowViewUserListing
                ? [
                    {
                      label: `Account admin policy`,
                      key: TAB_PROFILE.ADMIN_QUORUM,
                      children: (
                        <AdminQuorumTab
                          id={id}
                          isMaskedProfile={isMaskedProfile}
                        />
                      ),
                    },
                    {
                      label: `User listing`,
                      key: TAB_PROFILE.USER_LISTING,
                      children: (
                        <UserListing
                          id={id}
                          isMaskedProfile={isMaskedProfile}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </div>
      </Drawer>
      {isOpen && (
        <ModalConfirmDeleteCustomer
          isOpen
          setIsOpen={setIsOpen}
          title={titleModal}
          handleDeleteCustomer={handleDeleteCustomer}
        />
      )}
    </div>
  );
};

export default CustomerProfile;
