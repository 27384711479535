import { Col, Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import CustomerProfileTab from 'app/components/common/CustomerProfile';
import TextInfor from 'app/components/common/TextInfor';
import { STATUS_REQUEST } from 'constants/index';
import { CustomerSubscribeType } from 'interfaces/cusSubscribe';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  customerProfileActions,
  selectCustomerProfile,
} from 'store/slices/customerProfile';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
  isMask: boolean;
}

const NewProductSubscription = ({ requestDetail, isMask }: Props) => {
  const dispatch = useDispatch();
  const isApproved = requestDetail?.status === STATUS_REQUEST.APPROVED;
  const isRejected = requestDetail?.status === STATUS_REQUEST.REJECTED;
  const [open, setOpen] = useState(false);
  const customerProfileData = useAppSelector(selectCustomerProfile);

  useEffect(() => {
    const newCusSubscribes = requestDetail?.data?.newCusSubscribes.filter(
      subscribe => {
        return subscribe.type === CustomerSubscribeType.WALLET_CONNECT;
      },
    );

    if (!!newCusSubscribes.length) {
      const customerSubscribe = newCusSubscribes[newCusSubscribes.length - 1];
      dispatch(
        customerProfileActions.fetchCustomerByIdRequest({
          customerId: customerSubscribe.customerId,
        }),
      );
    }
  }, [dispatch, requestDetail?.data?.newCusSubscribes]);

  const approveOrReject = (isApproved: boolean) => {
    return (
      (isApproved || isRejected) && (
        <div
          className={`${styles.status} ${
            isApproved ? styles.approved : styles.rejected
          }`}
        >
          <div>
            <SvgIcon name={isApproved ? 'white-approve' : 'white-reject'} />
          </div>
          <div>
            {isApproved ? STATUS_REQUEST.APPROVED : STATUS_REQUEST.REJECTED}
          </div>
        </div>
      )
    );
  };

  const inform = (label: string, content) => {
    return (
      <Col span={12}>
        <div className={styles['customer-inform__label']} data-testid="label">
          {label}
        </div>
        <div
          className={styles['customer-inform__content']}
          onClick={() => setOpen(true)}
        >
          {content}
        </div>
      </Col>
    );
  };

  const customerInfo = () => {
    return (
      <div className={styles['customer-inform']} data-testid="customer">
        <Row>
          {inform(
            'Business registered name',
            customerProfileData?.data?.customerName,
          )}
          {inform(
            'Business registration number',
            customerProfileData?.data?.businessRegistrationId,
          )}
        </Row>
      </div>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.icon}>
          <SvgIcon name="product-subscription" width={32} height={32} />
        </div>
        <div className={styles.title}>
          <div>New product subscription request</div>
          {approveOrReject(isApproved)}
        </div>
        {customerInfo()}
        <div className={styles.content}>
          <Row>
            <TextInfor label="Product subcription" text="Rakkar Connect" />
          </Row>
        </div>
        <CustomerProfileTab
          open={open}
          setOpen={setOpen}
          requestDetail={{
            ...requestDetail,
            customer: { ...customerProfileData?.data },
          }}
          isMask={isMask}
        />
      </div>
    </>
  );
};

export default NewProductSubscription;
