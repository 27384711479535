import { Modal, ModalFuncProps } from 'antd';
import BasicButton from '../../BasicButton';
import styles from './index.module.scss';
export type ModalConfirmOption = {
  onCancel?: <T extends any[]>(args?: T[]) => void;
  onOk?: <T extends any[]>(args?: T[]) => void;
  okText?: string;
  cancelText?: string;
  title?: string;
  description?: string;
} & Omit<ModalFuncProps, 'cancelText' | 'okText' | 'title' | 'content'>;

export const modalConfirm = ({
  onOk,
  onCancel,
  title,
  description,
  okText,
  cancelText,
  ...rest
}: ModalConfirmOption) => {
  const modal = Modal.confirm({
    centered: true,
    modalRender(node) {
      return (
        <div className={styles.confirmModal}>
          <div className={styles['content-container']}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
          <div className={styles['button-container']}>
            <BasicButton
              type="outline"
              title={cancelText ?? ''}
              className={styles.cancel}
              onClick={() => {
                onCancel?.();
                modal.destroy();
              }}
            />
            <BasicButton
              type="filled"
              title={okText ?? ''}
              className={styles.ok}
              onClick={() => {
                onOk?.();
              }}
            />
          </div>
        </div>
      );
    },

    ...rest,
  });

  return modal;
};
