import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortWcAppAdapter, GroupByField, CustomerOnScreen } from 'enum/wcAppAdapter.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetWcAppAdapterRequestParamDTO,
  GetWcAppAdapterResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IWcAppAdapterDetail,
} from 'interfaces/wcAppAdapter';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface WcAppAdapterState {
  params: GetWcAppAdapterRequestParamDTO;
  wcAppAdapter: ResponseData<GetWcAppAdapterResponseDTO>;
  wcAppAdapterPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  wcAppAdapterDetail: ResponseData<IWcAppAdapterDetail>;
}
export function getDefaultWcAppAdapterRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortWcAppAdapter.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: WcAppAdapterState = {
  params: getDefaultWcAppAdapterRequestParams(),
  wcAppAdapter: {
    status: RequestStatus.Idle,
  },
  wcAppAdapterPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ROLE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  wcAppAdapterDetail: {
    status: RequestStatus.Idle,
  },
};
const getWcAppAdapterRequest: CaseReducer<WcAppAdapterState, PayloadAction<any>> = (
  state: WcAppAdapterState,
) => {
  state.wcAppAdapter.status = RequestStatus.Loading;
};
const getWcAppAdapterSuccess: CaseReducer<
  WcAppAdapterState,
  PayloadAction<GetWcAppAdapterResponseDTO>
> = (state: WcAppAdapterState, { payload }: PayloadAction<GetWcAppAdapterResponseDTO>) => {
  state.wcAppAdapter.status = RequestStatus.Success;
  state.wcAppAdapter.data = payload;
};
const getWcAppAdapterFailed: CaseReducer<WcAppAdapterState> = (state: WcAppAdapterState) => {
  state.wcAppAdapter.status = RequestStatus.Failed;
};
const getDetailWcAppAdapterRequest: CaseReducer<WcAppAdapterState, PayloadAction<string>> = (
  state: WcAppAdapterState,
) => {
  state.wcAppAdapterDetail.status = RequestStatus.Loading;
};
const getDetailWcAppAdapterSuccess: CaseReducer<
  WcAppAdapterState,
  PayloadAction<IWcAppAdapterDetail>
> = (state: WcAppAdapterState, { payload }: PayloadAction<IWcAppAdapterDetail>) => {
  state.wcAppAdapterDetail.status = RequestStatus.Success;
  state.wcAppAdapterDetail.data = payload;
};
const getDetailWcAppAdapterFailed: CaseReducer<WcAppAdapterState> = (state: WcAppAdapterState) => {
  state.wcAppAdapterDetail.status = RequestStatus.Failed;
};
const createWcAppAdapterRequest: CaseReducer<WcAppAdapterState, PayloadAction<IWcAppAdapterDetail>> = (
  state: WcAppAdapterState,
) => {
  state.wcAppAdapterDetail.status = RequestStatus.Loading;
};
const createWcAppAdapterSuccess: CaseReducer<WcAppAdapterState, PayloadAction<IWcAppAdapterDetail>> = (
  state: WcAppAdapterState,
  { payload }: PayloadAction<IWcAppAdapterDetail>,
) => {
  state.wcAppAdapterDetail.status = RequestStatus.Success;
  state.wcAppAdapterDetail.data = payload;
};
const createWcAppAdapterFailed: CaseReducer<WcAppAdapterState> = (state: WcAppAdapterState) => {
  state.wcAppAdapterDetail.status = RequestStatus.Failed;
};
const updateWcAppAdapterRequest: CaseReducer<WcAppAdapterState, PayloadAction<IWcAppAdapterDetail>> = (
  state: WcAppAdapterState,
) => {
  state.wcAppAdapterDetail.status = RequestStatus.Loading;
};
const updateWcAppAdapterSuccess: CaseReducer<WcAppAdapterState, PayloadAction<IWcAppAdapterDetail>> = (
  state: WcAppAdapterState,
  { payload }: PayloadAction<IWcAppAdapterDetail>,
) => {
  state.wcAppAdapterDetail.status = RequestStatus.Success;
  state.wcAppAdapterDetail.data = payload;
};
const updateWcAppAdapterFailed: CaseReducer<WcAppAdapterState> = (state: WcAppAdapterState) => {
  state.wcAppAdapterDetail.status = RequestStatus.Failed;
};
const appendWcAppAdapter: CaseReducer<WcAppAdapterState, PayloadAction<any>> = (
  state: WcAppAdapterState,
  { payload }: PayloadAction<any>,
) => {
  if (state.wcAppAdapter?.data?.listWcAppAdapter) {
    state.wcAppAdapter.status = RequestStatus.Success;
    state.wcAppAdapter.data.listWcAppAdapter = [...payload];
  }
};
const setCanLoadMore = (state: WcAppAdapterState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetWcAppAdapter: CaseReducer<WcAppAdapterState> = (state: WcAppAdapterState) => {
  state.wcAppAdapter = {
    status: RequestStatus.Idle,
  };
};
const resetWcAppAdapterParams: CaseReducer<WcAppAdapterState> = (state: WcAppAdapterState) => {
  state.params = getDefaultWcAppAdapterRequestParams();
};
const setWcAppAdapterFilterParams = (
  state: WcAppAdapterState,
  { payload }: PayloadAction<GetWcAppAdapterRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  WcAppAdapterState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: WcAppAdapterState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<WcAppAdapterState, PayloadAction<any>> = (
  state: WcAppAdapterState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<WcAppAdapterState> = (state: WcAppAdapterState) => {
  state.action.status = RequestStatus.Failed;
};
const getWcAppAdapterPaginationConfigRequest: CaseReducer<
  WcAppAdapterState,
  PayloadAction<any>
> = (state: WcAppAdapterState) => {
  state.wcAppAdapterPaginationConfig.status = RequestStatus.Loading;
};
const getWcAppAdapterPaginationConfigSuccess: CaseReducer<
  WcAppAdapterState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: WcAppAdapterState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.wcAppAdapterPaginationConfig.status = RequestStatus.Success;
  state.wcAppAdapterPaginationConfig.data = payload;
};
const getWcAppAdapterPaginationConfigFailed: CaseReducer<WcAppAdapterState> = (
  state: WcAppAdapterState,
) => {
  state.wcAppAdapterPaginationConfig.status = RequestStatus.Failed;
};
const wcAppAdapterSlice = createSlice({
  name: 'wcAppAdapter',
  initialState,
  reducers: {
    getWcAppAdapterRequest,
    getWcAppAdapterSuccess,
    getWcAppAdapterFailed,
    getDetailWcAppAdapterRequest,
    getDetailWcAppAdapterSuccess,
    getDetailWcAppAdapterFailed,
    createWcAppAdapterRequest,
    createWcAppAdapterSuccess,
    createWcAppAdapterFailed,
    updateWcAppAdapterRequest,
    updateWcAppAdapterSuccess,
    updateWcAppAdapterFailed,
    appendWcAppAdapter,
    setCanLoadMore,
    resetWcAppAdapter,
    setWcAppAdapterParams<K extends keyof GetWcAppAdapterRequestParamDTO>(
      state: WcAppAdapterState,
      action: PayloadAction<{
        key: K;
        value: GetWcAppAdapterRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setWcAppAdapterFilterParams,
    resetWcAppAdapterParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getWcAppAdapterPaginationConfigRequest,
    getWcAppAdapterPaginationConfigSuccess,
    getWcAppAdapterPaginationConfigFailed,
  },
});
// Actions
export const wcAppAdapterActions = wcAppAdapterSlice.actions;
// Reducer
export const wcAppAdapterReducer = wcAppAdapterSlice.reducer;
// Selectors
export const selectWcAppAdapter = (state: AppState) => state.wcAppAdapter?.wcAppAdapter;
export const selectParamWcAppAdapter = (state: AppState) => state.wcAppAdapter?.params;
export const selectCanLoadMoreWcAppAdapter = (state: AppState) =>
  state.wcAppAdapter?.canLoadMore;
export const selectWcAppAdapterDetail = (state: AppState) => state.wcAppAdapter?.wcAppAdapterDetail;
export const selectWcAppAdapterPagination = (state: AppState) =>
  state.wcAppAdapter?.wcAppAdapterPaginationConfig;
