import React from 'react';
import styled from 'styled-components';

const Message = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  align-items: center;
  display: grid;
  align-self: center;
  max-width: calc(100% - 40px);
  word-break: break-word;
`;

const rootColors = {
  info: {
    background: '#2E90FA',
    opacity: '#84CAFF',
  },
  warning: {
    background: '#F79009',
    opacity: '#FEC84B',
  },
  success: {
    background: '#12B76A',
    opacity: '#6CE9A6',
  },
  error: {
    background: '#F04438',
    opacity: '#FDA29B',
  },
};

type Props = {
  type: 'info' | 'warning' | 'success' | 'error';
  message: string;
};

const Root = styled.div<{ background: string; bgHover: string }>`
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: ${p => p.background};
  display: flex;

  &:hover {
    background-color: ${p => p.bgHover};
  }
`;

const ToastMessage = ({ type, message }: Props) => {
  return (
    <Root
      background={rootColors[type].background}
      bgHover={rootColors[type].opacity}
    >
      <Message>{message}</Message>
    </Root>
  );
};

export default ToastMessage;
