import { status } from 'app/components/UserListing/UserDetail';
import clsx from 'clsx';
import React from 'react';
import styles from './index.module.scss';

const StatusUser = (user: any) => {
  if (!user) return;
  return (
    <div className={clsx(styles.status, styles[status(user)?.className])}>
      {status(user)?.text}
    </div>
  );
};

export default StatusUser;
