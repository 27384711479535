import { Form } from 'antd';
import styled, { CSSProp } from 'styled-components';

export const FormItem = styled(Form.Item)<{ css?: CSSProp }>`
  &.ant-form-item {
    margin-right: 8px;
  }
  .ant-select .ant-select-selector,
  .ant-picker {
    min-height: 36px;
    border-radius: 6px;
  }

  .ant-select-selection-item {
    background: #f7f7f9;
    border: 0;
    border-radius: 24px;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border: 1px solid #3b5ac4;
    box-shadow: 0px 0px 16px 8px rgba(130, 150, 218, 0.16),
      0px 0px 0px 2px rgba(130, 150, 218, 0.8);
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid #3b5ac4;
    box-shadow: 0px 0px 16px 8px rgba(130, 150, 218, 0.16),
      0px 0px 0px 2px rgba(130, 150, 218, 0.8);
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: #ebecf0;
    border: 1px solid #eceded;
  }

  .ant-picker-input > input::placeholder,
  .ant-select-selection-placeholder {
    color: #959aaa;
    opacity: 1;
  }

  .ant-select-disabled .ant-select-selection-placeholder {
    color: #b4b7c3;
  }

  label {
    font-weight: 600;
    color: #64697b;
  }

  .ant-select-item.ant-select-item-group {
    border-top: 1px solid #ebecf0;
    font-weight: 600;
  }

  ${props => props.css};
`;
