import Instance from 'app.v2/config/instance';
import { PREFIX_API } from 'constants/index';

import {
  GetCfg_typeOfCompanyRequestParamDTO,
  GetCfg_typeOfCompanyResponseDTO,
  ICreateCfg_typeOfCompany,
  ICfg_typeOfCompanyDetail,
} from 'interfaces/cfg_typeOfCompany';
export function getCfg_typeOfCompanyRequest(
  params?: GetCfg_typeOfCompanyRequestParamDTO,
): Promise<GetCfg_typeOfCompanyResponseDTO> {
  return Instance.get(`/${PREFIX_API.CFG_TYPEOFCOMPANY}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailCfg_typeOfCompany(
  cfg_typeOfCompanyId: string,
): Promise<ICfg_typeOfCompanyDetail> {
  return Instance.get(
    `/${PREFIX_API.CFG_TYPEOFCOMPANY}/${cfg_typeOfCompanyId}`,
  );
}
export function createCfg_typeOfCompanyRequest(
  cfg_typeOfCompany: ICreateCfg_typeOfCompany,
): Promise<GetCfg_typeOfCompanyResponseDTO> {
  return Instance.post(`/${PREFIX_API.CFG_TYPEOFCOMPANY}`, cfg_typeOfCompany);
}
export function updateCfg_typeOfCompanyRequest(
  cfg_typeOfCompany: ICreateCfg_typeOfCompany,
): Promise<GetCfg_typeOfCompanyResponseDTO> {
  const payload: any = { ...cfg_typeOfCompany };
  const id = cfg_typeOfCompany.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.CFG_TYPEOFCOMPANY}/${id}`, payload);
}
export function getCfg_typeOfCompanyRequestPaginationConfig(): Promise<GetCfg_typeOfCompanyResponseDTO> {
  return Instance.get(`/${PREFIX_API.CFG_TYPEOFCOMPANY}/pagination-config`);
}
