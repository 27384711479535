import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Row } from 'antd';
import clsx from 'clsx';
import { KEY_CODE } from 'constants/index';
import {
  CompanyType,
  ContactPersonType,
  PurposeOfRelationship,
  SourceOfFunds,
  SourceOfWealth,
} from 'enum/addNewCustomer';
import { RequestStatus } from 'interfaces/request';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import {
  newCustomerActions,
  selectBussinessInformation,
  selectOldBussinessInformation,
} from 'store/slices/addNewCustomer';
import {
  customerProfileActions,
  selectCustomerProfile,
} from 'store/slices/customerProfile';
import { editCustomerActions } from 'store/slices/editCustomer';
import { convertTextToObjectAddress, getTextAddress } from 'utils/helpers';
import BusinessInformation, {
  checkBusinessTypeOther,
} from '../BusinessInformation';
import styles from './index.module.scss';
import { SvgIcon } from '../SvgIcon';
import ReviewAndSubmitEditCus from './components/ReviewAndSubmit';
import { commonActions, selectBusinessTypes } from 'store/slices/common';
import { history } from 'utils/history';
import RButton from '../Button';
import { BusinessInformationInterface } from 'interfaces/businessInformation';
import { Address } from 'interfaces/customers';
import moment from 'moment';

export interface Ref {
  onNext: () => void;
  onPrev: () => void;
}

const EditCustomer = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState<number>(0);
  const [blockCurrentAddress, setBlockCurrentAddress] =
    useState<boolean>(false);
  const [blockBillingAddress, setBlockBillingAddress] =
    useState<boolean>(false);
  const oldBusinessInform = useAppSelector(selectOldBussinessInformation);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [isDisabledNext, setIsDisabledNext] = useState<boolean>(true);
  const [isCheckCountry, setIsCheckCountry] = useState<boolean>(false);
  const customerProfileData = useAppSelector(selectCustomerProfile);
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get('id');
  const oldBusinessInformation = useAppSelector(selectOldBussinessInformation);
  const businessInformation = useAppSelector(selectBussinessInformation);
  const businessTypes = useAppSelector(selectBusinessTypes)?.data;

  useEffect(() => {
    dispatch(commonActions.getTypeOfCompanyRequest({}));
    dispatch(commonActions.getSourceOfFundRequest({}));
    dispatch(commonActions.getCustomerEntityRelationRequest());
    return () => {
      dispatch(newCustomerActions.resetcreateNewCustomer());
    };
  }, []);

  useEffect(() => {
    if (customerId)
      dispatch(
        customerProfileActions.fetchCustomerByIdRequest({
          customerId: customerId,
          query: { includeAdditionalContacts: true },
        }),
      );
  }, [customerId, dispatch]);

  useEffect(() => {
    if (customerId)
      dispatch(
        customerProfileActions.fetchCustomerByIdRequest({
          customerId: customerId,
          query: { includeAdditionalContacts: true },
        }),
      );
  }, [customerId, dispatch]);

  useEffect(() => {
    const billingContactInfo =
      customerProfileData?.data?.additionalContacts?.find(
        i => i.type === ContactPersonType.BILLING,
      );

    const otherContactInfo =
      customerProfileData?.data?.additionalContacts?.find(
        i => i.type === ContactPersonType.GENERAL,
      );
    if (customerProfileData?.data) {
      const cusInform: BusinessInformationInterface = {
        ...customerProfileData?.data,
        billing: getTextAddress(customerProfileData?.data?.billingAddress),
        registration: getTextAddress(
          customerProfileData?.data?.registrationAddress,
        ),
        current: getTextAddress(customerProfileData?.data?.currentAddress),
        billingPerson: billingContactInfo,
        otherPerson: otherContactInfo,
      };
      dispatch(newCustomerActions.updateBussinessInformation(cusInform));
      dispatch(newCustomerActions.setOldBusinessInformation(cusInform));
    }
  }, [customerProfileData, dispatch]);

  const next = useCallback(() => {
    setCurrent(current + 1);
  }, [current]);

  const handleNext = () => {
    if (current === 0) {
      const newData = form.getFieldsValue();
      let billingAddress: Address;
      if (typeof newData?.billing === 'string') {
        billingAddress = convertTextToObjectAddress(newData?.billing);
      } else {
        billingAddress = newData.billingAddress;
      }
      if (
        oldBusinessInform.businessRegistrationId !==
          newData.businessRegistrationId &&
        oldBusinessInform?.registrationAddress?.country !==
          billingAddress?.country
      ) {
        setIsCheckCountry(true);
      } else {
        setIsCheckCountry(false);
      }
      form.submit();
    }
  };

  const handlePrev = () => {
    if (current) {
      setCurrent(current - 1);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.which === KEY_CODE.ENTER || event.keyCode === KEY_CODE.ENTER) {
      event.preventDefault();
    }
  };

  const submitEditCustomer = () => {
    const removedFields = [
      'registration',
      'current',
      'billing',
      'billingPerson',
      'otherPerson',
    ];
    const additionalContacts = [
      {
        ...businessInformation.billingPerson,
        id: oldBusinessInformation?.billingPerson?.id,
        type: ContactPersonType.BILLING,
      },
    ];
    if (businessInformation.otherPerson) {
      additionalContacts.push({
        ...businessInformation.otherPerson,
        id: oldBusinessInformation?.otherPerson?.id,
        type: ContactPersonType.GENERAL,
      });
    }

    const submitData = {
      ...businessInformation,
      id: oldBusinessInform.id,
      additionalContacts: additionalContacts,
      dateOfLastestRiskLevel: moment
        .utc(businessInformation.dateOfLastestRiskLevel)
        .toISOString(),
      registrationDate: moment
        .utc(businessInformation.registrationDate)
        .toISOString(),
    };

    if (
      businessInformation.purposeOfRelationship !== PurposeOfRelationship.OTHER
    )
      submitData.purposeOfRelationshipOther = null;

    const isOtherBusinessType = checkBusinessTypeOther(
      Number(businessInformation.businessTypeId),
      businessTypes,
    );
    if (!isOtherBusinessType) submitData.businessTypeOther = null;

    if (businessInformation?.companyType !== CompanyType.OTHER)
      submitData.companyTypeOther = null;

    if (businessInformation?.sourceOfWealth !== SourceOfWealth.OTHER)
      submitData.sourceOfWealthOther = null;

    if (!businessInformation?.sourceOfFunds?.includes(SourceOfFunds.OTHER))
      submitData.sourceOfFundOther = null;

    removedFields.forEach(element => {
      delete submitData[element];
    });

    dispatch(editCustomerActions.editCustomerRequest(submitData));
  };

  const steps = [
    {
      title: 'Step 1',
      description: 'Edit customer profile',
      content: (
        <BusinessInformation
          form={form}
          next={next}
          blockCurrentAddress={blockCurrentAddress}
          setBlockCurrentAddress={setBlockCurrentAddress}
          blockBillingAddress={blockBillingAddress}
          setBlockBillingAddress={setBlockBillingAddress}
          onFieldsChange={setIsDisabledNext}
          onCheckBusineesIdAndCountry={() => {}}
          isEditCustomer={true}
          isCheckCountry={isCheckCountry}
        />
      ),
    },
    {
      title: 'Step 2',
      description: 'Review and submit',
      content: <ReviewAndSubmitEditCus />,
    },
  ];

  const Spinner = (
    <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
  );

  if (
    (customerProfileData.status === RequestStatus.Loading ||
      !oldBusinessInformation.id) &&
    current === 0
  ) {
    return <div className={styles.loading}>{Spinner}</div>;
  }

  return (
    <div className={styles.edit__container}>
      <div className={styles.body}>
        <div className={styles.header}>
          <div className={styles.steps}>
            {steps.map((_, index) => (
              <>
                <div
                  className={clsx(styles.dot, {
                    [styles.current]: current === index,
                    [styles.completed]: current === index + 1,
                  })}
                >
                  {current === index + 1 && (
                    <SvgIcon
                      name="approve-icon"
                      width={9}
                      height={7}
                      color="#93939B"
                    />
                  )}
                </div>
                {index !== steps.length - 1 && (
                  <div
                    className={clsx(styles.line, {
                      [styles.completedLine]: current === index + 1,
                    })}
                  ></div>
                )}
              </>
            ))}
          </div>
          <div className={styles.step__name}>
            <span className={styles.title} data-testid="header-title">
              {steps[current].title}:{' '}
            </span>
            <span className={styles.description} data-testid="header-desc">
              {steps[current].description}
            </span>
          </div>
        </div>
        <div className={styles.content__right}>{steps[current]?.content}</div>
        <Modal
          className={styles.modal}
          open={isOpenConfirmModal}
          centered
          onOk={() => {
            history.push('/customer-management');
          }}
          onCancel={() => setIsOpenConfirmModal(false)}
          footer={[
            <RButton
              buttonType="back"
              content="Cancel"
              width={90}
              height={44}
              onClick={() => setIsOpenConfirmModal(false)}
              key="cancel-edit-cus"
            />,
            <RButton
              buttonType="submit"
              content="Submit"
              width={90}
              height={44}
              onClick={submitEditCustomer}
              key="submit-edit-cus"
            />,
          ]}
        >
          <div className={styles.modal__title}>
            You confirm to submit the edit customer profile request?{' '}
          </div>
          <div className={styles.modal__desc}>
            After the request has been approved, customer profile information
            will be changed.
          </div>
        </Modal>
      </div>
      <div className={styles.footer}>
        <Button
          className={clsx(styles.button, styles['button__cancel'])}
          onClick={() => {
            dispatch(editCustomerActions.setIsOpenNavigation(true));
            dispatch(
              editCustomerActions.setNavigationPath('customer-management'),
            );
          }}
          htmlType="button"
          data-testid="cancel-button"
        >
          Cancel
        </Button>

        <div className={styles.actions}>
          <div>
            {current !== steps.length - 1 && (
              <Button
                className={clsx(styles.button, styles['button__next'], {
                  [styles['button--disabled']]: isDisabledNext,
                })}
                disabled={isDisabledNext}
                onClick={() => handleNext()}
                onKeyDown={handleKeyDown}
                htmlType="button"
                data-testid="next-button"
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Row>
                <Button
                  className={clsx(styles.button, styles['button__back'])}
                  onClick={(e: any) => {
                    handlePrev();
                  }}
                  onKeyDown={handleKeyDown}
                  htmlType="button"
                  data-testid="back-button"
                >
                  Back
                </Button>
                <Button
                  className={clsx(styles.button, styles['button__next'])}
                  htmlType="button"
                  onClick={() => setIsOpenConfirmModal(true)}
                  data-testid="submit-button"
                >
                  Submit
                </Button>
              </Row>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomer;
