import { PayloadAction } from '@reduxjs/toolkit';
import { WorkSpaceType } from 'enum/common';
import { TypeOfCompanyParams } from 'interfaces/businessInformation';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTotalPendingRequest } from 'services/common';
import {
  getBusinessTypes,
  getCustomerEntityRelation,
  getSourceOfFund,
  getTypeOfCompany,
  getWorkspaces,
} from 'services/customers';
import { getCreators } from 'services/request';
import { commonActions } from 'store/slices/common';
import { handleShowMsgErr } from 'utils/common';

const fetchBusinessTypesRequest = function* () {
  try {
    const res = yield call(getBusinessTypes);
    yield put(commonActions.getBusinessTypesSuccess(res.businessTypes));
  } catch (error: any) {
    yield put(commonActions.getBusinessTypesFailed());
  }
};

const fetchCustomerByIdRequest = function* () {
  try {
    const resHot = yield call(getWorkspaces, { type: WorkSpaceType.Hot });
    const resCold = yield call(getWorkspaces, { type: WorkSpaceType.Cold });
    yield put(
      commonActions.getWorkSpacesSuccess({
        hot: resHot.workspaces,
        cold: resCold.workspaces,
      }),
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(commonActions.getWorkSpacesFailed());
  }
};

const handleGetTotalRequestRequest = function* () {
  try {
    const res = yield call(getTotalPendingRequest);
    yield put(commonActions.getTotalRequestSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(commonActions.getTotalRequestFailed());
  }
};

// eslint-disable-next-line require-yield
const fetchCreatorsRequest = function* ({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(getCreators, payload);
    yield put(commonActions.getCreatorsRequestSuccess(res));
  } catch (error: any) {
    yield put(commonActions.getCreatorsRequestFailed());
  }
};

const fetchCustomerEntityRelation = function* () {
  try {
    const result = yield call(getCustomerEntityRelation);
    if (result?.entities) {
      yield put(
        commonActions.getCustomerEntityRelationSuccess(result.entities),
      );
    }
  } catch {
    yield put(commonActions.getCustomerEntityRelationFailed());
  }
};

const fetchTypeOfCompany = function* ({
  payload,
}: PayloadAction<TypeOfCompanyParams>) {
  try {
    const res = yield call(getTypeOfCompany, payload);
    if (res.list) yield put(commonActions.getTypeOfCompanySuccess(res.list));
  } catch {
    yield put(commonActions.getTypeOfCompanyFailed());
  }
};

const fetchSourceOfFund = function* ({
  payload,
}: PayloadAction<TypeOfCompanyParams>) {
  try {
    const res = yield call(getSourceOfFund, payload);
    if (res.list) yield put(commonActions.getSourceOfFundSuccess(res.list));
  } catch {
    yield put(commonActions.getSourceOfFundFailed());
  }
};

const commonSaga = function* () {
  yield takeLatest(
    commonActions.getBusinessTypesRequest.type,
    fetchBusinessTypesRequest,
  );

  yield takeLatest(
    commonActions.getWorkSpacesRequest.type,
    fetchCustomerByIdRequest,
  );

  yield takeLatest(
    commonActions.getTotalRequestRequest.type,
    handleGetTotalRequestRequest,
  );

  yield takeLatest(
    commonActions.getCreatorsRequestRequest.type,
    fetchCreatorsRequest,
  );

  yield takeLatest(
    commonActions.getCustomerEntityRelationRequest.type,
    fetchCustomerEntityRelation,
  );

  yield takeLatest(
    commonActions.getTypeOfCompanyRequest.type,
    fetchTypeOfCompany,
  );

  yield takeLatest(
    commonActions.getSourceOfFundRequest.type,
    fetchSourceOfFund,
  );
};

export default commonSaga;
