import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';

import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  BillingDetailResponse,
  BillingParamsRequest,
  BillingsResponse,
} from 'interfaces/billings';
import {
  GetCustomersRequest,
  ListCustomersResponse,
} from 'interfaces/customers';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import { isHistoryPage } from 'utils/common';
import { AppState } from '../..';

interface BillingsState {
  params: BillingParamsRequest;
  billings: ResponseData<BillingsResponse>;
  canLoadMore: boolean;
  billingDetail: ResponseData<BillingDetailResponse>;
  markAsBillingReviewed: ResponseData<boolean>;
  customersList: ResponseData<ListCustomersResponse>;
  invoicePdf: ResponseData<any>;
  markAsBillingPaid: ResponseData<boolean>;
}

export function getDefaultBillingRequestParams() {
  return {
    [Params.Page]: 1,
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
  };
}

export function getDefaultAllBillingRequestParams() {
  return {
    [Params.Page]: 1,
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
  };
}

const initialState: BillingsState = {
  params: isHistoryPage()
    ? getDefaultAllBillingRequestParams()
    : getDefaultBillingRequestParams(),
  billings: {
    status: RequestStatus.Idle,
    data: { customerBillings: [], totalCount: 0 },
  },
  canLoadMore: true,
  billingDetail: {
    status: RequestStatus.Idle,
  },
  markAsBillingReviewed: {
    status: RequestStatus.Idle,
  },
  customersList: {
    status: RequestStatus.Idle,
  },
  invoicePdf: {
    status: RequestStatus.Idle,
  },
  markAsBillingPaid: {
    status: RequestStatus.Idle,
  },
};

const getBillingListRequest: CaseReducer<BillingsState, PayloadAction<any>> = (
  state: BillingsState,
) => {
  state.billings.status = RequestStatus.Loading;
};

const getBillingListSuccess: CaseReducer<
  BillingsState,
  PayloadAction<BillingsResponse>
> = (state: BillingsState, { payload }: PayloadAction<BillingsResponse>) => {
  state.billings.status = RequestStatus.Success;
  state.billings.data = payload;
};

const getBillingListFailed: CaseReducer<
  BillingsState,
  PayloadAction<string>
> = (state: BillingsState, { payload }: PayloadAction<string>) => {
  state.billings.status = RequestStatus.Failed;
  state.billings.error = payload;
};

const updateCustomerBillings: CaseReducer<BillingsState, PayloadAction<any>> = (
  state: BillingsState,
  { payload }: PayloadAction<any>,
) => {
  if (payload && state.billings?.data?.customerBillings) {
    state.billings.data.customerBillings = payload;
  }
};

const getBillingDetailRequest: CaseReducer<
  BillingsState,
  PayloadAction<any>
> = (state: BillingsState) => {
  state.billingDetail.status = RequestStatus.Loading;
};

const getBillingDetailSuccess: CaseReducer<
  BillingsState,
  PayloadAction<BillingDetailResponse>
> = (
  state: BillingsState,
  { payload }: PayloadAction<BillingDetailResponse>,
) => {
  state.billingDetail.status = RequestStatus.Success;
  state.billingDetail.data = payload;
};

const getBillingDetailFailed: CaseReducer<
  BillingsState,
  PayloadAction<string>
> = (state: BillingsState, { payload }: PayloadAction<string>) => {
  state.billingDetail.status = RequestStatus.Failed;
  state.billingDetail.error = payload;
};

const markAsBillingReviewedRequest: CaseReducer<
  BillingsState,
  PayloadAction<any>
> = (state: BillingsState) => {
  state.markAsBillingReviewed.status = RequestStatus.Loading;
};

const markAsBillingReviewedSuccess: CaseReducer<
  BillingsState,
  PayloadAction<boolean>
> = (state: BillingsState, { payload }: PayloadAction<boolean>) => {
  state.markAsBillingReviewed.status = RequestStatus.Success;
  state.markAsBillingReviewed.data = payload;
};

const markAsBillingReviewedFailed: CaseReducer<
  BillingsState,
  PayloadAction<string>
> = (state: BillingsState, { payload }: PayloadAction<string>) => {
  state.markAsBillingReviewed.status = RequestStatus.Failed;
  state.markAsBillingReviewed.error = payload;
};

const appendBillings: CaseReducer<BillingsState, PayloadAction<any>> = (
  state: BillingsState,
  { payload }: PayloadAction<any>,
) => {
  if (state.billings?.data?.customerBillings) {
    state.billings.status = RequestStatus.Success;
    state.billings.data.customerBillings = [...payload];
  }
};

const markAsBillingPaidRequest: CaseReducer<
  BillingsState,
  PayloadAction<any>
> = (state: BillingsState) => {
  state.markAsBillingPaid.status = RequestStatus.Loading;
};

const markAsBillingPaidSuccess: CaseReducer<
  BillingsState,
  PayloadAction<boolean>
> = (state: BillingsState, { payload }: PayloadAction<boolean>) => {
  state.markAsBillingPaid.status = RequestStatus.Success;
  state.markAsBillingPaid.data = payload;
};

const markAsBillingPaidFailed: CaseReducer<
  BillingsState,
  PayloadAction<string>
> = (state: BillingsState, { payload }: PayloadAction<string>) => {
  state.markAsBillingPaid.status = RequestStatus.Failed;
  state.markAsBillingPaid.error = payload;
};

const setCanLoadMore = (
  state: BillingsState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};

const resetBillingPage: CaseReducer<BillingsState> = (state: BillingsState) => {
  state.params = getDefaultBillingRequestParams();
  state.billings = {
    status: RequestStatus.Idle,
  };
};

const resetAllBillingPage: CaseReducer<BillingsState> = (
  state: BillingsState,
) => {
  state.params = getDefaultAllBillingRequestParams();
  state.billings = {
    status: RequestStatus.Idle,
  };
};

const resetParamsBilling: CaseReducer<BillingsState> = (
  state: BillingsState,
) => {
  state.params = isHistoryPage()
    ? getDefaultAllBillingRequestParams()
    : getDefaultBillingRequestParams();
};

const setBillingFilterParams = (
  state: BillingsState,
  { payload }: PayloadAction<BillingParamsRequest>,
) => {
  state.params = payload;
};

const fetchCustomersRequest: CaseReducer<
  BillingsState,
  PayloadAction<GetCustomersRequest>
> = (state: BillingsState) => {
  state.customersList.status = RequestStatus.Loading;
};

const fetchCustomersSuccess: CaseReducer<
  BillingsState,
  PayloadAction<ListCustomersResponse>
> = (
  state: BillingsState,
  { payload }: PayloadAction<ListCustomersResponse>,
) => {
  state.customersList.status = RequestStatus.Success;
  state.customersList.data = payload;
};

const fetchCustomersFailed: CaseReducer<BillingsState> = (
  state: BillingsState,
) => {
  state.customersList.status = RequestStatus.Failed;
};

const fetchInvoicePdfRequest: CaseReducer<BillingsState, PayloadAction<any>> = (
  state: BillingsState,
) => {
  state.invoicePdf.status = RequestStatus.Loading;
};

const fetchInvoicePdfSuccess: CaseReducer<BillingsState, PayloadAction<any>> = (
  state: BillingsState,
  { payload }: PayloadAction<any>,
) => {
  state.invoicePdf.status = RequestStatus.Success;
  state.invoicePdf.data = payload;
};

const fetchInvoicePdfFailed: CaseReducer<
  BillingsState,
  PayloadAction<string>
> = (state: BillingsState, { payload }: PayloadAction<string>) => {
  state.invoicePdf.status = RequestStatus.Failed;
  state.invoicePdf.error = payload;
};

const billingsSlice = createSlice({
  name: 'billings',
  initialState,
  reducers: {
    getBillingListRequest,
    getBillingListSuccess,
    getBillingListFailed,

    updateCustomerBillings,

    getBillingDetailRequest,
    getBillingDetailSuccess,
    getBillingDetailFailed,

    markAsBillingReviewedRequest,
    markAsBillingReviewedSuccess,
    markAsBillingReviewedFailed,

    appendBillings,

    setCanLoadMore,

    resetBillingPage,
    resetAllBillingPage,

    setBillingParam<K extends keyof BillingParamsRequest>(
      state: BillingsState,
      action: PayloadAction<{ key: K; value: BillingParamsRequest[K] }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
    },
    setBillingFilterParams,
    resetParamsBilling,

    fetchCustomersRequest,
    fetchCustomersSuccess,
    fetchCustomersFailed,

    fetchInvoicePdfRequest,
    fetchInvoicePdfSuccess,
    fetchInvoicePdfFailed,

    markAsBillingPaidRequest,
    markAsBillingPaidSuccess,
    markAsBillingPaidFailed,
  },
});

// Actions
export const billingsActions = billingsSlice.actions;

// Reducer
export const billingsReducer = billingsSlice.reducer;

// Selectors
export const selectBillingList = (state: AppState) => state.billings?.billings;

export const selectparamsBillingList = (state: AppState) =>
  state.billings?.params;

export const selectCanLoadMoreBillings = (state: AppState) =>
  state.billings?.canLoadMore;

export const selectBillingDetail = (state: AppState) =>
  state.billings?.billingDetail;

export const selectMarkAsBillingReviewed = (state: AppState) =>
  state.billings?.markAsBillingReviewed;

export const selectCustomersHasInvoice = (state: AppState) =>
  state.billings.customersList;

export const selectMarkAsBillingPaid = (state: AppState) =>
  state.billings?.markAsBillingPaid;
