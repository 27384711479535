import { SvgIcon } from 'app/components/SvgIcon';
import React from 'react';

import styles from './index.module.scss';

type Props = {
  children: any;
  title: string;
  icon: string;
};

const WrapCard = ({ title, icon, children }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <SvgIcon name={icon} width={16} height={16} />
        </div>
        <div className={styles.title}>{title}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default WrapCard;
