import { SvgIcon } from 'app/components/SvgIcon';
import { UserApproval } from 'interfaces/transactions';
import { orderBy } from 'lodash';
import GeneralInfoApprover from './GeneralInfoApprover';

import styles from './index.module.scss';
import { RoleUser } from 'enum/userList';

type Props = {
  approvalLogs: UserApproval[];
  transactionDetail: any;
  numberApproved: number;
  isMaskedTransaction: boolean;
};

const ApprovalLog = ({
  approvalLogs,
  transactionDetail,
  numberApproved,
  isMaskedTransaction,
}: Props) => {
  const allApprovalLogs = [...approvalLogs];
  const approvalLogsCleaned =
    (allApprovalLogs?.length &&
      allApprovalLogs.filter(item => !!item.userId && item.isApprover)) ||
    [];
  const total = approvalLogsCleaned.length;

  const ApprovalLogsSortRequiredToTop =
    (approvalLogsCleaned?.length &&
      orderBy(approvalLogsCleaned, ['requiredApprover'], ['desc'])) ||
    [];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <SvgIcon name="approval-log" width={16} height={16} />
          </div>
          <div className={styles.title}>Vault policy</div>
          <div className={styles.approved} data-testid="total-approved">
            {numberApproved} of {total} {total > 1 ? 'approvals' : 'approval'}
          </div>
        </div>
      </div>
      <div className={styles.list} data-testid="user-list">
        {ApprovalLogsSortRequiredToTop.map((item, index) => {
          return (
            <GeneralInfoApprover
              key={`user-${item.userId}`}
              data={item}
              isMaskedTransaction={isMaskedTransaction}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ApprovalLog;
