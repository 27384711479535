import { LoadingOutlined } from '@ant-design/icons';
import { ConfigProvider, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Customer } from 'interfaces/customers';
import styles from './index.module.scss';
import clsx from 'clsx';
import { PAGE } from 'enum/common';
import PleaseSearchForSeeRecord from '../common/PleaseSearch';
import { isEmpty } from 'lodash';

interface TableProps {
  page?: PAGE;
  columns: ColumnsType<Customer>;
  currentTableData: any;
  loading?: boolean;
  searchValue?: any;
  clickOnRow?: (row: any) => void;
  onChange?: (pagination: any, filters: any, sorter: any) => void;
  isFirstRender?: boolean;
  isFilter?: boolean;
  selectedRowId?: string;
  height?: number;
  NoSearchResults?: () => JSX.Element;
}

export default function RTable({
  columns,
  currentTableData,
  loading,
  searchValue,
  clickOnRow,
  onChange,
  isFirstRender,
  isFilter,
  height,
  page = PAGE.CUSTOMER_MANAGEMENT,
  NoSearchResults,
  ...props
}: TableProps) {
  const customizeRenderEmpty = () => (
    <div style={{ height: 'calc(100vh - 268px)' }}>
      <PleaseSearchForSeeRecord
        title="No matching search results"
        description="Try another keyword"
      />
    </div>
  );
  const renderEmpty = () => {
    if (page === PAGE.CUSTOMER_MANAGEMENT) {
      return (
        <div style={{ height: 'calc(100vh - 268px)' }}>
          <PleaseSearchForSeeRecord
            title="Please search to see the records"
            description="Information is protected"
          />
        </div>
      );
    }

    if (page === PAGE.AUDITLOGLIST) {
      return (
        <div
          style={{ height: `${!!height && height - 24}px`, display: 'flex' }}
        >
          {NoSearchResults ? <NoSearchResults /> : null}
        </div>
      );
    }

    if (page === PAGE.PENDING_REQUEST) {
      return customizeRenderEmpty();
    }
    return <div style={{ height: 'calc(100vh - 268px)' }}></div>;
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
  );

  const tableLoading = {
    spinning: loading,
    indicator: antIcon,
  };

  return (
    <div className={styles.table__container}>
      <ConfigProvider
        renderEmpty={
          loading
            ? () => <div style={{ height: 'calc(100vh - 268px)' }}></div>
            : page === PAGE.AUDITLOGLIST &&
              searchValue &&
              isEmpty(currentTableData)
            ? renderEmpty
            : !isFilter && !searchValue
            ? renderEmpty
            : customizeRenderEmpty
        }
      >
        <Table
          id="table"
          data-testid="r-table"
          onRow={(record: Customer) => {
            return {
              onClick: event => {
                clickOnRow?.(record);
              }, // click row
            };
          }}
          loading={tableLoading}
          className={clsx(styles.table, {
            [styles.disabled__header]: isFirstRender,
          })}
          rowClassName={(record, index) =>
            record.id === props.selectedRowId ? `${styles.rowActive}` : ''
          }
          columns={columns}
          dataSource={currentTableData}
          pagination={false}
          onChange={onChange}
          scroll={height ? { y: height } : {}}
        />
      </ConfigProvider>
    </div>
  );
}
