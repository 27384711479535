import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getTransactionListRequest,
  getTransferTokens,
} from 'services/transactions';
import {
  selectAsset,
  selectAssetParams,
  transactionsActions,
} from 'store/slices/transactions';
import { handleShowMsgErr } from 'utils/common';

const handleGetTransactionListRequest = function* ({
  payload,
}: PayloadAction<any>) {
  try {
    const res = yield call(getTransactionListRequest, payload);

    const params = yield select(state => state.transactions.params) as any;

    const transactions = yield select(
      state => state.transactions?.transactions?.data?.transactions,
    ) as any;

    yield put(
      transactionsActions.setCanLoadMore(
        params.offset + params.limit < res.totalCount,
      ),
    );

    if (params.offset > 0) {
      const newList: any = [...transactions, ...res.transactions];
      yield put(transactionsActions.appendTransactions(newList));
    } else {
      yield put(transactionsActions.getTranscactionListSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(transactionsActions.getTranscactionListFailed());
  }
};

const handleGetAssets = function* ({
  payload,
}: PayloadAction<{ params: any }>) {
  try {
    const res = yield call(getTransferTokens, payload.params);

    const params = yield select(selectAssetParams);
    const assets = yield select(selectAsset);
    const listAsset = assets?.data?.wallets || assets?.data?.tokens;
    yield put(
      transactionsActions.setCanLoadMoreAssets(
        params.offset + params.limit < res.totalCount,
      ),
    );

    const tokens = res?.tokens.sort(
      (a, b) => b.isAllowTransfer - a.isAllowTransfer,
    );

    if (params.offset > 0) {
      const newAssets = res?.wallets || tokens;
      const arrMerged = [...listAsset, ...newAssets];
      const newRes = {
        ...res,
        tokens: [...listAsset, ...newAssets],
        ...(res?.tokens ? { tokens: arrMerged } : []),
        ...(res?.wallets ? { wallets: arrMerged } : []),
      };
      yield put(transactionsActions.getAssetsSuccess(newRes));
    } else {
      yield put(transactionsActions.getAssetsSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(transactionsActions.getAssetsFailed());
  }
};

const transactionsSaga = function* () {
  yield takeLatest(
    transactionsActions.getTransactionListRequest.type,
    handleGetTransactionListRequest,
  );
  yield takeLatest(transactionsActions.getAssetsRequest.type, handleGetAssets);
};

export default transactionsSaga;
