export enum SortTransactionListTable {
  createdAt = 'CREATED_DATE',
  transactionId = 'TRANSACTION_ID',
  customerName = 'CUSTOMER_NAME',
  symbol = 'ASSET_SYMBOL',
  amount = 'AMOUNT',
  status = 'TRANSACTION_STATUS',
  type = 'TRANSACTION_TYPE',
}

export enum TransactionStatusApprovalLogs {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
  REVIEWED = 'reviewed',
}

export enum StatusUserApprovalLogs {
  PENDING = 'PENDING',
  INITIATED = 'INITIATED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  APPROVED = 'APPROVED',
  PROCESSING = 'PROCESSING',
  PENDING_REVIEW = 'PENDING_REVIEW',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  APPROVED_AND_SIGNED = 'APPROVED_AND_SIGNED',
}

export enum TierSignerLevel {
  LOW = 'LOW',
  MID = 'MID',
  LARGE = 'LARGE',
}

export enum TransactionStatusFireBlocks {
  PENDING_AUTHORIZATION = 'PENDING_AUTHORIZATION',
  REJECTED = 'REJECTED',
  CONFIRMING = 'CONFIRMING',
  PENDING_AML_SCREENING = 'PENDING_AML_SCREENING',
}

export enum TransactionStatusResponse {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  CONFIRMING = 'CONFIRMING',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  PENDING_REVIEW = 'PENDING_REVIEW',
}

export enum SubStatus {
  FROZEN_MANUALLY = 'FROZEN_MANUALLY',
  REJECTED_AML_SCREENING = 'REJECTED_AML_SCREENING',
  AUTO_FREEZE = 'AUTO_FREEZE',
}

export enum SourceType {
  HOT_WALLET = 'HOT_WALLET',
  COLD_WALLET = 'COLD_WALLET',
}
