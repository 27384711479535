import { Col } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import { STATUS_REQUEST } from 'constants/index';
import styles from './index.module.scss';

interface PropsStatus {
  isApproved?: boolean;
  isRejected?: boolean;
}
interface IPropsTitleInfo {
  label?: string;
  setOpenDrawer: (boolean) => void;
  children: JSX.Element;
}
export function StatusRequest({ isApproved, isRejected }: PropsStatus) {
  return isApproved || isRejected ? (
    <div
      className={`${styles.status} ${
        isApproved ? styles.approved : styles.rejected
      }`}
      data-testid="status"
    >
      <div>
        <SvgIcon name={isApproved ? 'white-approve' : 'white-reject'} />
      </div>
      <div>
        {isApproved ? STATUS_REQUEST.APPROVED : STATUS_REQUEST.REJECTED}
      </div>
    </div>
  ) : null;
}

export function TitleInfo({ label, setOpenDrawer, children }: IPropsTitleInfo) {
  return (
    <Col span={12}>
      <div className={styles['customer-inform__label']}>{label}</div>
      <div
        className={styles['customer-inform__content']}
        onClick={() => setOpenDrawer(true)}
      >
        {children}
      </div>
    </Col>
  );
}
