import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortWcRequestWeb3Connect, GroupByField, CustomerOnScreen } from 'enum/wcRequestWeb3Connect.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetWcRequestWeb3ConnectRequestParamDTO,
  GetWcRequestWeb3ConnectResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IWcRequestWeb3ConnectDetail,
} from 'interfaces/wcRequestWeb3Connect';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface WcRequestWeb3ConnectState {
  params: GetWcRequestWeb3ConnectRequestParamDTO;
  wcRequestWeb3Connect: ResponseData<GetWcRequestWeb3ConnectResponseDTO>;
  wcRequestWeb3ConnectPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  wcRequestWeb3ConnectDetail: ResponseData<IWcRequestWeb3ConnectDetail>;
}
export function getDefaultWcRequestWeb3ConnectRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortWcRequestWeb3Connect.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: WcRequestWeb3ConnectState = {
  params: getDefaultWcRequestWeb3ConnectRequestParams(),
  wcRequestWeb3Connect: {
    status: RequestStatus.Idle,
  },
  wcRequestWeb3ConnectPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ROLE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  wcRequestWeb3ConnectDetail: {
    status: RequestStatus.Idle,
  },
};
const getWcRequestWeb3ConnectRequest: CaseReducer<WcRequestWeb3ConnectState, PayloadAction<any>> = (
  state: WcRequestWeb3ConnectState,
) => {
  state.wcRequestWeb3Connect.status = RequestStatus.Loading;
};
const getWcRequestWeb3ConnectSuccess: CaseReducer<
  WcRequestWeb3ConnectState,
  PayloadAction<GetWcRequestWeb3ConnectResponseDTO>
> = (state: WcRequestWeb3ConnectState, { payload }: PayloadAction<GetWcRequestWeb3ConnectResponseDTO>) => {
  state.wcRequestWeb3Connect.status = RequestStatus.Success;
  state.wcRequestWeb3Connect.data = payload;
};
const getWcRequestWeb3ConnectFailed: CaseReducer<WcRequestWeb3ConnectState> = (state: WcRequestWeb3ConnectState) => {
  state.wcRequestWeb3Connect.status = RequestStatus.Failed;
};
const getDetailWcRequestWeb3ConnectRequest: CaseReducer<WcRequestWeb3ConnectState, PayloadAction<string>> = (
  state: WcRequestWeb3ConnectState,
) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Loading;
};
const getDetailWcRequestWeb3ConnectSuccess: CaseReducer<
  WcRequestWeb3ConnectState,
  PayloadAction<IWcRequestWeb3ConnectDetail>
> = (state: WcRequestWeb3ConnectState, { payload }: PayloadAction<IWcRequestWeb3ConnectDetail>) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Success;
  state.wcRequestWeb3ConnectDetail.data = payload;
};
const getDetailWcRequestWeb3ConnectFailed: CaseReducer<WcRequestWeb3ConnectState> = (state: WcRequestWeb3ConnectState) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Failed;
};
const createWcRequestWeb3ConnectRequest: CaseReducer<WcRequestWeb3ConnectState, PayloadAction<IWcRequestWeb3ConnectDetail>> = (
  state: WcRequestWeb3ConnectState,
) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Loading;
};
const createWcRequestWeb3ConnectSuccess: CaseReducer<WcRequestWeb3ConnectState, PayloadAction<IWcRequestWeb3ConnectDetail>> = (
  state: WcRequestWeb3ConnectState,
  { payload }: PayloadAction<IWcRequestWeb3ConnectDetail>,
) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Success;
  state.wcRequestWeb3ConnectDetail.data = payload;
};
const createWcRequestWeb3ConnectFailed: CaseReducer<WcRequestWeb3ConnectState> = (state: WcRequestWeb3ConnectState) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Failed;
};
const updateWcRequestWeb3ConnectRequest: CaseReducer<WcRequestWeb3ConnectState, PayloadAction<IWcRequestWeb3ConnectDetail>> = (
  state: WcRequestWeb3ConnectState,
) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Loading;
};
const updateWcRequestWeb3ConnectSuccess: CaseReducer<WcRequestWeb3ConnectState, PayloadAction<IWcRequestWeb3ConnectDetail>> = (
  state: WcRequestWeb3ConnectState,
  { payload }: PayloadAction<IWcRequestWeb3ConnectDetail>,
) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Success;
  state.wcRequestWeb3ConnectDetail.data = payload;
};
const updateWcRequestWeb3ConnectFailed: CaseReducer<WcRequestWeb3ConnectState> = (state: WcRequestWeb3ConnectState) => {
  state.wcRequestWeb3ConnectDetail.status = RequestStatus.Failed;
};
const appendWcRequestWeb3Connect: CaseReducer<WcRequestWeb3ConnectState, PayloadAction<any>> = (
  state: WcRequestWeb3ConnectState,
  { payload }: PayloadAction<any>,
) => {
  if (state.wcRequestWeb3Connect?.data?.listWcRequestWeb3Connect) {
    state.wcRequestWeb3Connect.status = RequestStatus.Success;
    state.wcRequestWeb3Connect.data.listWcRequestWeb3Connect = [...payload];
  }
};
const setCanLoadMore = (state: WcRequestWeb3ConnectState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetWcRequestWeb3Connect: CaseReducer<WcRequestWeb3ConnectState> = (state: WcRequestWeb3ConnectState) => {
  state.wcRequestWeb3Connect = {
    status: RequestStatus.Idle,
  };
};
const resetWcRequestWeb3ConnectParams: CaseReducer<WcRequestWeb3ConnectState> = (state: WcRequestWeb3ConnectState) => {
  state.params = getDefaultWcRequestWeb3ConnectRequestParams();
};
const setWcRequestWeb3ConnectFilterParams = (
  state: WcRequestWeb3ConnectState,
  { payload }: PayloadAction<GetWcRequestWeb3ConnectRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  WcRequestWeb3ConnectState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: WcRequestWeb3ConnectState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<WcRequestWeb3ConnectState, PayloadAction<any>> = (
  state: WcRequestWeb3ConnectState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<WcRequestWeb3ConnectState> = (state: WcRequestWeb3ConnectState) => {
  state.action.status = RequestStatus.Failed;
};
const getWcRequestWeb3ConnectPaginationConfigRequest: CaseReducer<
  WcRequestWeb3ConnectState,
  PayloadAction<any>
> = (state: WcRequestWeb3ConnectState) => {
  state.wcRequestWeb3ConnectPaginationConfig.status = RequestStatus.Loading;
};
const getWcRequestWeb3ConnectPaginationConfigSuccess: CaseReducer<
  WcRequestWeb3ConnectState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: WcRequestWeb3ConnectState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.wcRequestWeb3ConnectPaginationConfig.status = RequestStatus.Success;
  state.wcRequestWeb3ConnectPaginationConfig.data = payload;
};
const getWcRequestWeb3ConnectPaginationConfigFailed: CaseReducer<WcRequestWeb3ConnectState> = (
  state: WcRequestWeb3ConnectState,
) => {
  state.wcRequestWeb3ConnectPaginationConfig.status = RequestStatus.Failed;
};
const wcRequestWeb3ConnectSlice = createSlice({
  name: 'wcRequestWeb3Connect',
  initialState,
  reducers: {
    getWcRequestWeb3ConnectRequest,
    getWcRequestWeb3ConnectSuccess,
    getWcRequestWeb3ConnectFailed,
    getDetailWcRequestWeb3ConnectRequest,
    getDetailWcRequestWeb3ConnectSuccess,
    getDetailWcRequestWeb3ConnectFailed,
    createWcRequestWeb3ConnectRequest,
    createWcRequestWeb3ConnectSuccess,
    createWcRequestWeb3ConnectFailed,
    updateWcRequestWeb3ConnectRequest,
    updateWcRequestWeb3ConnectSuccess,
    updateWcRequestWeb3ConnectFailed,
    appendWcRequestWeb3Connect,
    setCanLoadMore,
    resetWcRequestWeb3Connect,
    setWcRequestWeb3ConnectParams<K extends keyof GetWcRequestWeb3ConnectRequestParamDTO>(
      state: WcRequestWeb3ConnectState,
      action: PayloadAction<{
        key: K;
        value: GetWcRequestWeb3ConnectRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setWcRequestWeb3ConnectFilterParams,
    resetWcRequestWeb3ConnectParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getWcRequestWeb3ConnectPaginationConfigRequest,
    getWcRequestWeb3ConnectPaginationConfigSuccess,
    getWcRequestWeb3ConnectPaginationConfigFailed,
  },
});
// Actions
export const wcRequestWeb3ConnectActions = wcRequestWeb3ConnectSlice.actions;
// Reducer
export const wcRequestWeb3ConnectReducer = wcRequestWeb3ConnectSlice.reducer;
// Selectors
export const selectWcRequestWeb3Connect = (state: AppState) => state.wcRequestWeb3Connect?.wcRequestWeb3Connect;
export const selectParamWcRequestWeb3Connect = (state: AppState) => state.wcRequestWeb3Connect?.params;
export const selectCanLoadMoreWcRequestWeb3Connect = (state: AppState) =>
  state.wcRequestWeb3Connect?.canLoadMore;
export const selectWcRequestWeb3ConnectDetail = (state: AppState) => state.wcRequestWeb3Connect?.wcRequestWeb3ConnectDetail;
export const selectWcRequestWeb3ConnectPagination = (state: AppState) =>
  state.wcRequestWeb3Connect?.wcRequestWeb3ConnectPaginationConfig;
