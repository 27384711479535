import { PayloadAction } from '@reduxjs/toolkit';
import ToastMessage from 'app/components/common/ToastMessage';
import {
  AccessControlRequest,
  GetListAccessControlRequestParams,
} from 'interfaces/accessControl';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getAccessControlListRequest,
  getDetailAccessControl,
  requestUpdateAccessControl,
} from 'services/accessControl';
import { accessControlListActions } from 'store/slices/accessControl/accessControl.slice';
import { commonActions } from 'store/slices/common';
import { handleShowMsgErr } from 'utils/common';

const handleGetAccessControlListRequest = function* ({
  payload,
}: PayloadAction<GetListAccessControlRequestParams>) {
  try {
    const res = yield call(getAccessControlListRequest, payload);
    const paramsAccessControlList = yield select(
      state => state.accessControl.params,
    ) as any;
    const accessControls = yield select(
      state => state.accessControl?.accessControlList,
    ) as any;

    yield put(
      accessControlListActions.setCanLoadMore(
        paramsAccessControlList.offset + paramsAccessControlList.limit <
          res.total,
      ),
    );
    if (paramsAccessControlList?.offset > 0) {
      const newList = [...accessControls.data.records, ...res.records];
      yield put(accessControlListActions.appendAccessControl(newList));
    } else {
      yield put(accessControlListActions.getAccessControlListSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(accessControlListActions.getAccessControlListFailed());
  }
};

const handleGetDetailAccessControlRequest = function* ({
  payload,
}: PayloadAction<any>) {
  try {
    const res = yield call(getDetailAccessControl, payload?.id, payload?.limit);
    if (res) {
      yield put(accessControlListActions.getDetailAccessControlSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(accessControlListActions.getDetailAccessControlFailed());
  }
};

const handleAccessControlRequest = function* ({
  payload,
}: PayloadAction<AccessControlRequest>) {
  try {
    const res = yield call(
      requestUpdateAccessControl,
      payload.id,
      payload.groups,
    );
    if (res) {
      yield put(accessControlListActions.accessControlSuccess());
      toast(
        <ToastMessage
          type="info"
          message="The request has been submitted for approval"
        />,
      );
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error?.response?.data);
    yield put(accessControlListActions.accessControlFailed());
  }
};

const accessControl = function* () {
  yield takeLatest(
    accessControlListActions.getAccessControlListRequest.type,
    handleGetAccessControlListRequest,
  );
  yield takeLatest(
    accessControlListActions.getDetailAccessControlRequest.type,
    handleGetDetailAccessControlRequest,
  );
  yield takeLatest(
    accessControlListActions.accessControlRequest.type,
    handleAccessControlRequest,
  );
};

export default accessControl;
