import debounce from 'debounce-promise';
import { resendFbWebhookTxnsAPI } from 'services/transaction';

export const resendFbWebhookTxns = async (body: any) => {
  const response = await resendFbWebhookTxnsAPI(body);
  return response;
};

export const debounceValidateWcApplicationValuesExist = debounce(
  resendFbWebhookTxns,
  500,
);
