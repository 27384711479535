import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getCfg_countryRequest,
  getDetailCfg_country,
  createCfg_countryRequest,
  updateCfg_countryRequest,
  getCfg_countryRequestPaginationConfig,
} from 'services/cfg_country.service';
import { cfg_countryActions } from 'store/slices/cfg_country/cfg_country.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetCfg_countryRequestParamDTO,
  ICreateCfg_country,
} from 'interfaces/cfg_country';
const handleGetCfg_countryRequest = function* ({
  payload,
}: PayloadAction<GetCfg_countryRequestParamDTO>) {
  try {
    const res = yield call(getCfg_countryRequest, payload);
    const params = yield select(state => state.cfg_country.params) as any;
    const listCfg_country = yield select(
      state => state.cfg_country?.cfg_country?.data?.listCfg_country,
    ) as any;
    if (res) {
      yield put(
        cfg_countryActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listCfg_country || []), ...res.list];
        yield put(cfg_countryActions.appendCfg_country(newList));
      } else {
        yield put(
          cfg_countryActions.getCfg_countrySuccess({
            listCfg_country: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_countryActions.getCfg_countryFailed());
  }
};
const handleGetDetailCfg_countryRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailCfg_country, payload);
    if (res) {
      yield put(cfg_countryActions.getDetailCfg_countrySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_countryActions.getDetailCfg_countryFailed());
  }
};
const handleCreateCfg_countryRequest = function* ({
  payload,
}: PayloadAction<ICreateCfg_country>) {
  try {
    const res = yield call(createCfg_countryRequest, payload);
    if (res) {
      yield put(cfg_countryActions.createCfg_countrySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_countryActions.createCfg_countryFailed());
  }
};
const handleUpdateCfg_countryRequest = function* ({
  payload,
}: PayloadAction<ICreateCfg_country>) {
  try {
    const res = yield call(updateCfg_countryRequest, payload);
    if (res) {
      yield put(cfg_countryActions.updateCfg_countrySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_countryActions.updateCfg_countryFailed());
  }
};
const handleGetCfg_countryPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetCfg_countryRequestParamDTO>) {
  try {
    const res = yield call(getCfg_countryRequestPaginationConfig);
    if (res) {
      yield put(cfg_countryActions.getCfg_countryPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_countryActions.getCfg_countryPaginationConfigFailed());
  }
};
const cfg_countrySaga = function* () {
  yield takeLatest(
    cfg_countryActions.getCfg_countryRequest.type,
    handleGetCfg_countryRequest,
  );
  yield takeLatest(
    cfg_countryActions.createCfg_countryRequest.type,
    handleCreateCfg_countryRequest,
  );
  yield takeLatest(
    cfg_countryActions.updateCfg_countryRequest.type,
    handleUpdateCfg_countryRequest,
  );
  yield takeLatest(
    cfg_countryActions.getCfg_countryPaginationConfigRequest.type,
    handleGetCfg_countryPaginationConfigRequest,
  );
  yield takeLatest(
    cfg_countryActions.getDetailCfg_countryRequest.type,
    handleGetDetailCfg_countryRequest,
  );
};
export default cfg_countrySaga;
