import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import styles from './index.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  loading: boolean;
  handleConfirm?: () => void;
}
const PopupMarkDeviceAsLost = ({
  isOpen,
  setIsOpen,
  loading = false,
  handleConfirm,
  ...props
}: Props) => {
  const content = (
    <div className={styles.content}>
      After marked as lost, the user will be no longer access Rakkar application
      with the device
    </div>
  );
  return (
    <BasicModal
      destroyOnClose={true}
      className={styles.approve__popup}
      visible={isOpen}
      children={content}
      title="You confirm to request marking this user’s device as lost ?"
      centered={true}
      onCancel={() => setIsOpen(false)}
      maskClosable={true}
      width={404}
      footer={[
        <div className={styles.button}>
          <BasicButton
            title="Back"
            type="outline"
            onClick={() => setIsOpen(false)}
          />
          <BasicButton
            title="Confirm"
            type="filled"
            className={styles.confirm}
            onClick={() => handleConfirm?.()}
            loading={loading}
          />
        </div>,
      ]}
    />
  );
};

export default PopupMarkDeviceAsLost;
