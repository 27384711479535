import { Form, Select } from 'antd';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  customersActions,
  selectVaultsByCustomer,
} from 'store/slices/customers';
import styles from './index.module.scss';
import BasicSelect from '../../BasicSelect';
const { Option } = Select;
export interface ItemVault {
  name: string;
  id: string;
  type: string;
  createdAt: string;
}
interface Props {
  customerId: string;
  initVault?: string[];
  changeVault?: () => void;
}
const SelectVault = ({ customerId, initVault, changeVault }: Props) => {
  const dispatch = useAppDispatch();

  const vaultsByCustomer = useAppSelector(selectVaultsByCustomer);

  const vaults = vaultsByCustomer?.data?.vaults;
  useEffect(() => {
    dispatch(customersActions.fetchVaultsByCustomerRequest({ id: customerId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BasicSelect
      placeholder="Select..."
      name="vaultIds"
      allowClear
      showSearch
      optionFilterProp="children"
      mode="multiple"
      keyValue="id"
      labelValue="name"
      valueSelected={initVault}
      onChange={changeVault}
      options={vaults || []}
    />
  );
};

export default SelectVault;
