import { FC, useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import { modalConfirm } from 'app.v2/components/atoms/BasicModal/ConfirmModal';
import { Info, PlusCirCle } from 'app.v2/components/atoms/Icon';
import BasicButton from 'app.v2/components/atoms/BasicButton';
import clsx from 'clsx';
import styles from '../index.module.scss';

import { cusSubscribeApi } from '../../../../../../../store/slices/cusSubscribe/cusSubscribe.api';
import { useAppSelector } from 'store/hooks';
import { selectCustomerProfile } from 'store/slices/customerProfile';
import { CustomerSubscribeType } from 'interfaces/cusSubscribe';
import useViewRequest from 'hooks/useViewRequest';
import { toast } from 'react-toastify';
import ToastMessage from 'app/components/common/ToastMessage';
import useCheckExistCusSubscribe from '../hooks/useCheckExistCusSubscribe';
import { selectRoles } from 'store/slices/auth';
import { USER_ROLES } from 'enum/common';
import { checkRoles } from 'utils/common';

const { useCreateCusSubscribeRequestMutation } = cusSubscribeApi;

type Props = {
  customerId: string;
};

const ProductSubscriptionRakkarConnect: FC<Props> = ({ customerId }) => {
  const { handleViewRequest } = useViewRequest();

  const userRoles = useAppSelector(selectRoles);

  const existCusSubscribe = useCheckExistCusSubscribe(customerId);

  const [createCustomerSubscribeRequest, { isLoading, isSuccess }] =
    useCreateCusSubscribeRequestMutation({});

  const customerProfile = useAppSelector(selectCustomerProfile);

  const openConfirmModal = () => {
    const modal = modalConfirm({
      title: 'You confirm to submit the request?',
      description:
        'After the request has been approved, the new product will be available to the customer.',
      cancelText: 'Back',
      okText: 'Confirm',
      cancelButtonType: 'transparent',
      okButtonProps: {},
      onOk: async () => {
        await createCustomerSubscribeRequest({
          subscribeTypes: [{ type: CustomerSubscribeType.WALLET_CONNECT }],
          customerId,
        }).unwrap();

        toast(
          <ToastMessage
            type="info"
            message={'The request has been submitted for approval'}
          />,
        );

        modal.destroy();
      },
      onCancel: () => modal.destroy(),
    });
  };

  const isPendingRequest = useMemo(
    () =>
      Boolean(customerProfile.data?.pendingCustomerSubscribeRequestId) ||
      isLoading ||
      isSuccess,
    [isSuccess, customerProfile, isLoading],
  );

  const isOperationRole = useMemo(() => {
    return checkRoles(USER_ROLES.OPERATION, userRoles);
  }, [userRoles]);

  return (
    <Row
      justify="center"
      align="middle"
      className={styles['production-subscription-rakkar-connect']}
    >
      {(Boolean(customerProfile.data?.pendingCustomerSubscribeRequestId) ||
        isSuccess) && (
        <Col
          className={styles['production-subscription-rakkar-connect-banner']}
        >
          <Row justify="space-between" align="middle">
            <Col className={styles['pending-text']}>
              <Info className={styles['info-icon']} /> The customer has a
              pending request for approval
            </Col>
            <Col
              className={styles['view-request']}
              onClick={() =>
                handleViewRequest(
                  customerProfile.data
                    ?.pendingCustomerSubscribeRequestId as string,
                )
              }
            >
              View request
            </Col>
          </Row>
        </Col>
      )}
      {existCusSubscribe || (
        <Col className={styles['not-subscribe-text-container']}>
          <Typography.Text className={styles['not-subscribe-text']}>
            Customer has not subscribed to this product.
          </Typography.Text>
        </Col>
      )}
      <Col
        className={clsx(styles['subscribe-button-container'], {
          [`${styles['pending']}`]: isPendingRequest,
        })}
      >
        {isOperationRole && (
          <BasicButton
            disabled={existCusSubscribe || isPendingRequest}
            type={isPendingRequest ? 'transparent' : 'outline'}
            onClick={existCusSubscribe ? () => {} : openConfirmModal}
            className={
              isPendingRequest ? styles['disable-rakkar-connect-button'] : ''
            }
            title={
              <Row justify="center" align="middle">
                <Col>
                  {existCusSubscribe ? null : (
                    <PlusCirCle
                      className={clsx(styles['plus-icon'], {
                        [`${styles['disable-icon']}`]: isPendingRequest,
                      })}
                    />
                  )}
                </Col>
                <Col>
                  <Typography.Text
                    className={clsx(styles['subscribe-text'], {
                      [`${styles['disable-subscribe-text']}`]:
                        isLoading || isPendingRequest,
                    })}
                  >
                    {existCusSubscribe ? 'Unsubscribe' : 'Subscribe'}
                  </Typography.Text>
                </Col>
              </Row>
            }
          />
        )}
      </Col>
    </Row>
  );
};

export default ProductSubscriptionRakkarConnect;
