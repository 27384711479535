import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getDefaultBusinessInformations,
  getDefaultFeeInfomation,
} from 'app/pages/AddCustomer/index.helper';
import { AdminQuorumData } from 'interfaces/adminQuorum';
import {
  BusinessInformationInterface,
  FeeInformationInterface,
} from 'interfaces/businessInformation';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import { AppState } from '../..';

interface Step2 {
  hotWalletId: string | number;
  coldWalletId: string | number;
  staking: any;
}

interface NewCustomerInfoState {
  businessInformation: BusinessInformationInterface;
  // step2: Step2;
  step2: Step2;
  feeInformation: FeeInformationInterface;
  customer: ResponseData<any>;
  adminQuorumData: AdminQuorumData;
  detailStakingList: ResponseData<any>;
  oldBusinessInformation: BusinessInformationInterface;
}

const initialState: NewCustomerInfoState = {
  businessInformation: getDefaultBusinessInformations(),
  feeInformation: getDefaultFeeInfomation(),

  step2: {
    hotWalletId: '',
    coldWalletId: '',
    staking: {},
  },
  detailStakingList: {
    status: RequestStatus.Idle,
  },

  adminQuorumData: { quorumSize: 2, adminQuorum: [] },

  customer: {
    status: RequestStatus.Idle,
  },
  oldBusinessInformation: getDefaultBusinessInformations(),
};

const updateBussinessInformation: CaseReducer<
  NewCustomerInfoState,
  PayloadAction<BusinessInformationInterface>
> = (
  state: NewCustomerInfoState,
  { payload }: PayloadAction<BusinessInformationInterface>,
) => {
  state.businessInformation = payload;
};

const updateFeeInformation: CaseReducer<
  NewCustomerInfoState,
  PayloadAction<FeeInformationInterface>
> = (
  state: NewCustomerInfoState,
  { payload }: PayloadAction<FeeInformationInterface>,
) => {
  state.feeInformation = payload;
};

const setStep2 = (
  state: NewCustomerInfoState,
  { payload }: PayloadAction<Step2>,
) => {
  state.step2 = payload;
};

const getStakingListRequest: CaseReducer<NewCustomerInfoState> = (
  state: NewCustomerInfoState,
) => {
  state.detailStakingList.status = RequestStatus.Loading;
};

const getStakingListSuccess: CaseReducer<
  NewCustomerInfoState,
  PayloadAction<any>
> = (state: NewCustomerInfoState, { payload }: PayloadAction<any>) => {
  state.detailStakingList.status = RequestStatus.Success;
  state.detailStakingList.data = payload;
};

const getStakingListFailed: CaseReducer<NewCustomerInfoState> = (
  state: NewCustomerInfoState,
) => {
  state.detailStakingList.status = RequestStatus.Failed;
};

const updateAdminQuorum: CaseReducer<
  NewCustomerInfoState,
  PayloadAction<AdminQuorumData>
> = (
  state: NewCustomerInfoState,
  { payload }: PayloadAction<AdminQuorumData>,
) => {
  state.adminQuorumData = payload;
};

const RequestGetBusinessTypes: CaseReducer<
  NewCustomerInfoState,
  PayloadAction<BusinessInformationInterface>
> = (
  state: NewCustomerInfoState,
  { payload }: PayloadAction<BusinessInformationInterface>,
) => {
  state.businessInformation = payload;
};

const createNewCustomerRequest: CaseReducer<
  NewCustomerInfoState,
  PayloadAction<any>
> = (state: NewCustomerInfoState) => {
  state.customer.status = RequestStatus.Loading;
};

const createNewCustomerSuccess: CaseReducer<
  NewCustomerInfoState,
  PayloadAction<any>
> = (state: NewCustomerInfoState, { payload }: PayloadAction<any>) => {
  state.customer.status = RequestStatus.Success;
  state.customer.data = payload;
};

const createNewCustomerFailed: CaseReducer<NewCustomerInfoState> = (
  state: NewCustomerInfoState,
) => {
  state.customer.status = RequestStatus.Failed;
};

const setOldBusinessInformation: CaseReducer<
  NewCustomerInfoState,
  PayloadAction<BusinessInformationInterface>
> = (
  state: NewCustomerInfoState,
  { payload }: PayloadAction<BusinessInformationInterface>,
) => {
  state.oldBusinessInformation = payload;
};

const resetcreateNewCustomer = state => {
  state.businessInformation = getDefaultBusinessInformations();
  state.step2 = {
    hotWalletId: '',
    coldWalletId: '',
    staking: {},
  };
  state.feeInformation = getDefaultFeeInfomation();

  state.adminQuorumData = { quorumSize: 2, adminQuorum: [] };
};

const addNewCustomerSlice = createSlice({
  name: 'newCustomerInfo',
  initialState,
  reducers: {
    updateBussinessInformation,
    updateFeeInformation,
    RequestGetBusinessTypes,
    setStep2,
    updateAdminQuorum,

    getStakingListRequest,
    getStakingListSuccess,
    getStakingListFailed,

    createNewCustomerRequest,
    createNewCustomerSuccess,
    createNewCustomerFailed,

    resetcreateNewCustomer,

    setOldBusinessInformation,
  },
});

// Actions
export const newCustomerActions = addNewCustomerSlice.actions;

// Reducer
export const newCustomerReducer = addNewCustomerSlice.reducer;

// Selectors
export const selectBussinessInformation = (state: AppState) =>
  state.newCustomerInfo.businessInformation;

export const selectFeeInformation = (state: AppState) =>
  state.newCustomerInfo.feeInformation;

export const selectAdminQuorumData = (state: AppState) =>
  state.newCustomerInfo.adminQuorumData;

export const selectStep2 = (state: AppState) => state.newCustomerInfo.step2;

export const selectStakingList = (state: AppState) =>
  state.newCustomerInfo.detailStakingList;

export const selectOldBussinessInformation = (state: AppState) =>
  state.newCustomerInfo.oldBusinessInformation;
