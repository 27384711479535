import { GetCustomerEntityRelationsResponse } from 'interfaces/customerEntityRelations';
import Instance from '../app.v2/config/instance';
import { BaseParams } from 'interfaces/base';

const CustomerEntityRelationsApi = {
  getCustomerEntityRelations: '/core/v2/rep/customerEntityRelations',
};

export const getCustomerEntityRelations = async (
  params?: BaseParams,
): Promise<GetCustomerEntityRelationsResponse['data']> => {
  const response = (await Instance.get(
    CustomerEntityRelationsApi.getCustomerEntityRelations,
    { params },
  )) as unknown as GetCustomerEntityRelationsResponse['data'];

  return {
    ...response,
    list: response.list.sort((a, b) => a.name.localeCompare(b.name)),
  };
};
