import { AddCustomer } from 'app/pages/AddCustomer/Loadable';

const withoutSidebarLayout = [
  {
    path: '/create-customer',
    element: <AddCustomer />,
  },
  // user-management
];
export default withoutSidebarLayout;
