export enum SortWalletInfoList {
  ID = 'id',
  NAME = 'name',
  SYMBOL = 'symbol',
  NETWORK = 'network',
  STATUS = 'status',
  TYPE = 'type',
  DECIMALS = 'decimals',
  NATIVE_ASSET = 'nativeAsset',
  TOKEN_ADDRESS = 'tokenAddress',
}

export enum GroupByField {
  CATEGORY = 'CATEGORY',
  TYPE = 'TYPE',
}

export enum CustomerOnScreen {
  CUSTOMER_MANAGEMENT = 'CUSTOMER_MANAGEMENT',
  WALLET_INFO = 'WALLET_INFO',
}
