export enum SortWcWeb3Connect {
  createdAt = 'ACTIVITY_DATE',
  company = 'COMPANY',
  username = 'USERNAME',
  requestCategory = 'CATEGORY',
  requestType = 'TYPE',
  requestAction = 'ACTION',
  status = 'STATUS',
}
export enum GroupByField {
  CATEGORY = 'CATEGORY',
  TYPE = 'TYPE',
}
export enum CustomerOnScreen {
  CUSTOMER_MANAGEMENT = 'CUSTOMER_MANAGEMENT',
  WC_WEB3CONNECT = 'WC_WEB3CONNECT',
  // TODO: Check
  ROLE = '',
}
