export enum RoleUser {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  VIEWER = 'VIEWER',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  OPERATION = 'OPERATION',
}

export enum StatusUser {
  CONFIRMED = 'CONFIRMED',
  WAITING_ON_BOARDING = 'AWAIT_ONBOARDING',
  FAILED_ONBOARDING = 'FAILED_ONBOARDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  PENDING_VERIFY = 'PENDING_VERIFY',
  LOCKED = 'LOCKED',
}

export enum SortListUser {
  name = 'NAME',
  role = 'ROLE',
  status = 'STATUS',
  email = 'EMAIL',
}
