import React from 'react';
import { SvgIcon } from '../SvgIcon';
import styles from './index.module.scss';

interface Props {
  title?: string;
  description?: string;
}
const PleaseSearchForSeeRecord = ({ title, description }: Props) => {
  return (
    <div className={styles.empty} data-testid="empty-screen">
      <div className={styles.content}>
        <div className={styles.icon}>
          <SvgIcon name={'search'} width={80} height={80} fill="#25397C" />
        </div>
        <div className={styles.text}>{title}</div>
        <div className={styles.text_appear}>{description}</div>
      </div>
    </div>
  );
};

export default PleaseSearchForSeeRecord;
