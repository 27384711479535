import { PREFIX_API } from 'constants/index';
import {
  CheckBusinessAndCountryRequest,
  CheckValidateDuplicateUserRequest,
} from 'interfaces/customers';
import Instance from '../app.v2/config/instance';

export function getBusinessType(): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/business-type`);
}

export function checkBusinessRegistrationId(name: string): Promise<any> {
  const params = {
    name: name,
  };
  return Instance.get(
    `/${PREFIX_API.CORE}/rep/customers/check-business-registration-id`,
    {
      params,
    },
  );
}

export function checkPassportNumber(number: string): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/check/${number}`);
}

export function checkShortNameCustomer(params: any): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/check-short-name`, {
    params,
  });
}

export function checkValidateDuplicateUser(
  params: CheckValidateDuplicateUserRequest,
): Promise<any> {
  return Instance.get(`/${PREFIX_API.AUTH}/account/validate-duplicate-user`, {
    params,
  });
}

export function checkBothBusinessIdAndCountry(
  params: CheckBusinessAndCountryRequest,
): Promise<any> {
  return Instance.get(
    `/${PREFIX_API.CORE}/rep/customers/check-bri-and-country`,
    {
      params,
    },
  );
}

export function checkIdNumber(number: string): Promise<any> {
  return Instance.get(
    `/${PREFIX_API.AUTH}/account/check-id-card-number/${number}`,
  );
}

export function getListAssetStaking(): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/assets-staking`);
}
