import { SortDirection } from 'enum/common';
import {
  SortCusSubscribe,
  GroupByField,
  CustomerOnScreen,
} from 'enum/cusSubscribe.enum';
import { ListCustomersResponse } from './customers';
import { ParamsCustomers, ParamsDefault } from './request';
import { BaseQueryString, ExtendBaseEntity } from './base';
import { extend } from 'lodash';
export interface GetCusSubscribeRequestParamDTO {
  limit?: number;
  offset?: number;
  sort?: SortDirection;
  customerIds?: string[];
  userIds?: string[];
  types?: string[];
  actions?: string[];
  statuses?: string[];
  dateFrom?: string;
  dateTo?: string;
  keyword?: string;
  searchText?: string;
  sortBy?: SortCusSubscribe;
  isAllRequest?: boolean;
  timeZone?: number;
  where?: string;

  order?: string;
}

export enum CustomerSubscribeType {
  WALLET_CONNECT = 'WALLET_CONNECT',
  WARM = 'WARM',
  COLD = 'COLD',
}
export interface CusSubscribe extends ExtendBaseEntity {
  id: string;
  type: CustomerSubscribeType;
  customerId: string;
  startDate: string;
  endDate: string;
}

export interface CreateCusSubscribeBody
  extends Pick<CusSubscribe, 'customerId'> {
  subscribeTypes: { type: CustomerSubscribeType }[];
}

export interface CusSubscribeQuery extends BaseQueryString {}

export enum TokenType {
  ALGO_ASSET = 'ALGO_ASSET',
  BASE_ASSET = 'BASE_ASSET',
  BEP20 = 'BEP20',
  COMPOUND = 'COMPOUND',
  ERC20 = 'ERC20',
  FIAT = 'FIAT',
  SOL_ASSET = 'SOL_ASSET',
  TRON_TRC20 = 'TRON_TRC20',
  XLM_ASSET = 'XLM_ASSET',
}

export interface GetCusSubscribeResponseDTO {
  listCusSubscribe: CusSubscribeDTO[];
  total: number;
}
export interface GetCompanyListRequestParamsDTO extends ParamsCustomers {
  searchOnScreen?: CustomerOnScreen;
}
export interface GetCompanyListDTO extends ListCustomersResponse {}
export interface GetCategoryDTO {
  id: {
    requestCategory: string;
  };
  requestType: string[];
}
export interface CusSubscribeDTO {
  id: string;
  name: string;
  symbol: string;
  status: string;
}
export interface ICreateCusSubscribe extends CusSubscribeDTO {}
export interface GroupByUserRequestParamsDTO extends ParamsDefault {}
export interface ICusSubscribeDetail {
  id?: string;
  name?: string;
  symbol?: string;
  status?: string;
  metaData?: any;
}
export interface GroupByUserRequestParamsDTO {
  customerIds: string;
  keyword?: string;
}
export interface GroupByUserListDTO {
  customers: {
    customerId: string;
    users: {
      name: string;
      userId: string;
      userName: string;
    }[];
  };
  total: number;
}
export interface CategoryGroupByRequestParamsDTO {
  categories?: string;
  types?: string;
  groupBy?: GroupByField;
}
