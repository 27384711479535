import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import bookOpen from 'images/book-open.svg';

import { Params } from 'interfaces/request';

import { GetAuditLogListRequestParamDTO } from 'interfaces/auditLogList.interface';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  auditLogListActions,
  selectParamAuditLogList,
} from 'store/slices/auditLogList/auditLogList.slice';

import AuditLogFilter from 'app/components/AuditLog/AuditLogFilter';
import AuditLogList from 'app/components/AuditLog/AuditLogList';

import RButton from 'app/components/Button';
import BasicModal from 'app/components/common/BasicModal';
import { SvgIcon } from 'app/components/common/SvgIcon';

import ToastMessage from 'app/components/common/ToastMessage';
import { FORMAT_DATE, timeZone } from 'constants/index';
import moment from 'moment';
import { toast } from 'react-toastify';
import { downloadAuditLog } from 'services/auditLogList.service';
import { downloadFile, handleShowMsgErr, isNonEmptyObject } from 'utils/common';
import { mapRequest } from 'utils/helpers';
import styles from './AuditLogPage.module.scss';

const AuditLogPageContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 32px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #0c0d12;

  margin-left: 12px;
`;

const FilterButton = styled.div<{ isHasFilter: boolean }>`
  cursor: pointer;

  height: 36px;
  padding: 0 16px;
  border: 1px solid #d5d7df;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  background: ${({ isHasFilter }) => (isHasFilter ? '#ceddfb' : '#ffffff')};

  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    margin-left: 8px;
  }
`;

const AuditLogListContainer = styled.div`
  margin-top: 24px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const queryParamsFilter = [
  'customerIds',
  'userIds',
  'categories',
  'types',
  'actions',
  'statuses',
  'dateFrom',
  'dateTo',
];

const AuditLogPage: React.FunctionComponent = () => {
  const [isFilterShown, setIsFilterShown] = useState(true);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [isHasFilter, setIsHasFilter] = useState(false);

  const [isMaskAuditLog, setIsMaskAuditLog] = useState(true);

  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParamAuditLogList);

  const setValue = (key: keyof GetAuditLogListRequestParamDTO) => {
    return (
      value: GetAuditLogListRequestParamDTO[keyof GetAuditLogListRequestParamDTO],
    ) => {
      dispatch(
        auditLogListActions.setAuditLogListParams({
          key,
          value,
        }),
      );
    };
  };

  const setKeyword = setValue(Params.KeyWord);
  const setOffset = setValue(Params.Offset);

  useEffect(() => {
    return () => {
      dispatch(auditLogListActions.resetAuditLogList());
      dispatch(auditLogListActions.resetCompany());
      dispatch(auditLogListActions.resetUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefresh = () => {
    setOffset(0);
    dispatch(auditLogListActions.getAuditLogListRequest(params));
  };

  const onInputSearch = (keyword: string) => {
    dispatch(auditLogListActions.resetAuditLogList());

    setOffset(0);
    setKeyword(keyword);
  };

  const onDownloadClick = () => setOpenDownloadModal(true);
  const onFinishFilter = (values: any) => {
    const { action, activityDate, category, company, status, type, username } =
      values;
    const paramsFilter = {
      customerIds: !!company ? company.toString() : undefined,
      userIds: !!username ? username.toString() : undefined,
      categories: !!category ? category.toString() : undefined,
      types: !!type ? type.toString() : undefined,
      actions: !!action ? action.toString() : undefined,
      statuses: !!status ? status.toString() : undefined,
      dateFrom:
        activityDate && activityDate?.[0]
          ? moment(activityDate[0]).startOf('day').toISOString()
          : undefined,
      dateTo:
        activityDate && activityDate?.[1]
          ? moment(activityDate[1]).endOf('day').toISOString()
          : undefined,
    };

    dispatch(
      auditLogListActions.setAuditLogListFilterParams({
        ...mapRequest(paramsFilter),
        offset: 0,
        limit: 10,
        keyword: params?.keyword,
        sort: params?.sort,
        sortBy: params?.sortBy,
        isAllRequest: false,
      }),
    );

    const isHasParamsFilter = isNonEmptyObject(paramsFilter);
    setIsHasFilter(isHasParamsFilter);
  };

  useEffect(() => {
    dispatch(auditLogListActions.getAuditLogListRequest(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleDownloadFile = useCallback(async () => {
    try {
      const downloadParameters = {
        ...params,
        isAllRequest: true,
        timeZone: timeZone,
      };
      const { data } = await downloadAuditLog(downloadParameters);
      data &&
        toast(
          <ToastMessage type="info" message="Your download has started." />,
        );
      setOpenDownloadModal(false);
      const filename = `Rakkar Audit Log ${moment().format(
        FORMAT_DATE.DATE_DMY,
      )}.csv`;
      downloadFile(data, filename);
    } catch (error: any) {
      handleShowMsgErr(error);
    }
  }, [params]);

  const FilterComponent = useCallback(
    () => (
      <FilterButton
        isHasFilter={isHasFilter}
        onClick={() => setIsFilterShown(prevState => !prevState)}
      >
        <SvgIcon
          name={`chevron-${isFilterShown ? 'up' : 'down'}`}
          width={16}
          height={16}
          fill="#64697B"
        />
        <div className="label">{`${
          isFilterShown ? 'Hide' : 'Show'
        } filter`}</div>
      </FilterButton>
    ),
    [isFilterShown, isHasFilter],
  );

  return (
    <AuditLogPageContainer>
      <TitleContainer>
        <img src={bookOpen} alt="" />
        <Title>Audit log</Title>
      </TitleContainer>
      <AuditLogFilter
        keyword={params.keyword}
        onInputSearch={onInputSearch}
        onRefresh={onRefresh}
        onDownload={onDownloadClick}
        onFinishFilter={onFinishFilter}
        isFilterShown={isFilterShown}
        FilterButton={FilterComponent}
        isMaskAuditLog={isMaskAuditLog}
        setIsMaskAuditLog={setIsMaskAuditLog}
      />
      <AuditLogListContainer>
        <AuditLogList
          isFilterShown={isFilterShown}
          isMaskAuditLog={isMaskAuditLog}
        />
      </AuditLogListContainer>
      <BasicModal
        destroyOnClose={true}
        closable={false}
        visible={openDownloadModal}
        maskClosable={true}
        width={408}
        centered={true}
        key={'auditLog'}
        title="Available to export up to 1,000 logs"
        children={<div>Do you want to export?</div>}
        // className="exportBasicModal"
        className={styles['exportBasicModal']}
        onCancel={() => setOpenDownloadModal(false)}
        footer={[
          <RButton
            data-testid="cancel-button"
            buttonType="cancel"
            content="Cancel"
            onClick={() => setOpenDownloadModal(false)}
            width={79}
            height={36}
            style={{ marginRight: 4 }}
            key={'cancel-button'}
          />,
          <RButton
            data-testid="download-button"
            htmlType="submit"
            buttonType="submit"
            content="Export"
            onClick={handleDownloadFile}
            width={73}
            height={36}
            key={'download-button'}
          />,
        ]}
      />
    </AuditLogPageContainer>
  );
};

export default AuditLogPage;
