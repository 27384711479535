export type WalletApplicationForm = {
  name: string;
  externalId: string;
  url: string;
  logo: any;
  destinationNote: string;
  walletType: string;
  allowedEntities: string[];
};

export const MAX_LOGO_FILE_SIZE = 1048576;

export const ApplicationFormErrorMessage: Record<
  keyof WalletApplicationForm,
  {
    required?: string;
    fileType?: string;
    invalid?: string;
    overSize?: string;
  }
> = {
  externalId: {
    required: 'Application Id is required',
  },
  name: {
    required: 'Application name is required',
  },
  walletType: {
    required: 'Wallet type is required',
  },
  url: {
    invalid: 'Invalid URL format',
  },
  logo: {
    invalid: 'Invalid logo format',
    required: 'Logo is required',
  },
  // logo: {
  //   fileType: 'Unsupported file type',
  //   overSize: 'Over file size',
  //   required: 'Logo is required',
  // },
  destinationNote: {
    required: 'Destination note is required',
  },
  allowedEntities: {
    required: 'Allowed entity is required',
  },
};
