import { Button, Col, Divider, Row, Table } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import { ListActionNeedToCheckPermission } from 'enum/common';
import { WalletFeeInformation } from 'interfaces/businessInformation';
import { ItemAUC } from 'interfaces/customers';
import { RequestStatus } from 'interfaces/request';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectPermissions } from 'store/slices/auth';
import {
  customerProfileActions,
  selectAddNewFee,
  selectUpdateFee,
} from 'store/slices/customerProfile';

import { defaultAUCs } from 'utils/assets';
import {
  checkPermissions,
  checkRoles,
  formatThousandSeparator,
} from 'utils/common';
import { formatDate, formatDateRange } from 'utils/date';
import { handleKeyDown } from 'utils/event.utils';

import { isNullOrUndefined } from 'utils/string';

import styles from '../index.module.scss';

import { CustomSpace } from './CustomSpace';
import { UpcomingFeeInformation } from './UpcomingFeeInformation';
import { FeeInformationDialog } from './FeeInformationDialog';
import { assetUnderCustodyColumns } from '../const';

type ProductSubscriptionWalletProps = {
  customerId: string;
  onUpdateFeeSuccess?: () => void;
  walletTypeLabel: string;
  walletType: 'HOT_WALLET' | 'COLD_WALLET';
  walletName: string;
  isRequestPending: boolean;
  effectiveFrom?: string;
  effectiveTo?: string;
  initialSetupFee: number | string;
  minimumMonthlyFee: number | string;
  minimumAUCBalance: number | string;
  assetUnderCustody: ItemAUC[];
  upcomingWallet?: WalletFeeInformation[];
};

const ProductSubscriptionWallet = (props: ProductSubscriptionWalletProps) => {
  const addNewFeeSelector = useAppSelector(selectAddNewFee);
  const updateFeeSelector = useAppSelector(selectUpdateFee);
  const permissions = useAppSelector(selectPermissions);
  const dispatch = useAppDispatch();

  const {
    customerId,
    onUpdateFeeSuccess,
    walletTypeLabel,
    walletType,
    walletName,
    isRequestPending,
    effectiveFrom,
    effectiveTo,
    initialSetupFee,
    minimumMonthlyFee,
    minimumAUCBalance,
    assetUnderCustody,
    upcomingWallet,
  } = props;
  const [openAddNewDialog, setOpenAddNewDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [editingItem, setEditingItem] = useState<WalletFeeInformation | null>(
    null,
  );
  const [requestItem, setRequestItem] = useState<WalletFeeInformation | null>(
    null,
  );
  const [mode, setMode] = useState<'ADD' | 'EDIT' | null>(null);

  const allowEditInfo = checkPermissions(
    ListActionNeedToCheckPermission.EDIT_CUSTOMER,
    permissions,
  );

  const sortedUpcomingWallet = useMemo(() => {
    if (!upcomingWallet?.length) return [];

    return [...upcomingWallet].sort((a, b) =>
      moment(a.effectiveFrom).isBefore(moment(b.effectiveFrom)) ? -1 : 1,
    );
  }, [upcomingWallet]);

  const unavailablePeriod = useMemo(() => {
    const upcomingPeriod =
      upcomingWallet?.map(i => {
        return { effectiveFrom: i.effectiveFrom, effectiveTo: i.effectiveTo };
      }) || [];
    return [{ effectiveFrom, effectiveTo }, ...upcomingPeriod];
  }, [effectiveFrom, effectiveTo, upcomingWallet]);

  const handleConfirm = (
    mode: 'ADD' | 'EDIT',
    values: WalletFeeInformation,
  ) => {
    setMode(mode);
    setRequestItem(values);
    setOpenConfirmDialog(true);
  };

  const handleDialogConfirm = () => {
    if (!requestItem) return;

    if (mode === 'ADD') {
      dispatch(
        customerProfileActions.addNewFeeInformationRequest({
          customerId,
          body: {
            productType: walletType,
            feeInformation: requestItem,
          },
        }),
      );
    }
    if (mode === 'EDIT') {
      dispatch(
        customerProfileActions.updateFeeInformationRequest({
          customerId,
          feeInformationId: requestItem.id,
          body: { feeInformation: requestItem },
        }),
      );
    }
  };

  useEffect(() => {
    if (mode === 'ADD' && addNewFeeSelector.status === RequestStatus.Success) {
      setOpenAddNewDialog(false);
      setOpenConfirmDialog(false);
      onUpdateFeeSuccess?.();
      dispatch(customerProfileActions.resetAddNewFeeInformationStatus());
    }

    if (mode === 'EDIT' && updateFeeSelector.status === RequestStatus.Success) {
      setOpenEditDialog(false);
      setOpenConfirmDialog(false);
      onUpdateFeeSuccess?.();
      dispatch(customerProfileActions.resetUpdateFeeInformationStatus());
    }
  }, [
    mode,
    addNewFeeSelector,
    updateFeeSelector,
    onUpdateFeeSuccess,
    dispatch,
  ]);

  return (
    <>
      <Divider orientationMargin="0" orientation="left">
        <span className={styles.info__title}>{walletTypeLabel}</span>
      </Divider>
      <Row>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Wallet workspace</span>
            <span className={styles.text}>{walletName}</span>
          </CustomSpace>
        </Col>
      </Row>

      <Divider orientationMargin="0" orientation="left">
        <span className={styles.info__title}>
          Current fee information (
          {formatDate(moment(), FORMAT_DATE.DATE_MMMMYYYY, true)})
        </span>
      </Divider>
      {allowEditInfo && (
        <div className={styles.buttonWrapper}>
          <div className={styles.innerButtonWrapper}>
            <Button
              className={clsx(
                styles.button,
                isRequestPending ? styles.button__disabled : null,
              )}
              icon={
                <SvgIcon
                  style={{
                    marginRight: 10,
                    marginBottom: 2,
                  }}
                  width={15}
                  height={15}
                  name="plus-circle"
                  fill={
                    isRequestPending
                      ? styles.button__disabled['color']
                      : '#25397C'
                  }
                />
              }
              onClick={() => {
                setEditingItem({
                  id: '',
                  initialSetupFee: 0,
                  minimumMonthlyFee: 0,
                  minimumAUCBalance: 0,
                  assetUnderCustody: defaultAUCs,
                });
                setOpenAddNewDialog(true);
              }}
              onKeyDown={handleKeyDown}
              htmlType="button"
              disabled={isRequestPending}
            >
              Add new fee
            </Button>
          </div>
        </div>
      )}
      <Row gutter={[0, 16]}>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Effective period</span>
            <span className={styles.text}>
              {formatDateRange(
                effectiveFrom,
                effectiveTo,
                FORMAT_DATE.DATE_MMMMYYYY,
                true,
              )}
            </span>
          </CustomSpace>
        </Col>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Initial setup fee (USD)</span>
            <span className={styles.text}>
              {!isNullOrUndefined(initialSetupFee)
                ? formatThousandSeparator(initialSetupFee)
                : 0}
            </span>
          </CustomSpace>
        </Col>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Minimum monthly fee (USD)</span>
            <span className={styles.text}>
              {!isNullOrUndefined(minimumMonthlyFee)
                ? formatThousandSeparator(minimumMonthlyFee)
                : 0}
            </span>
          </CustomSpace>
        </Col>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Minimum AUC balance (USD)</span>
            <span className={styles.text}>
              {!isNullOrUndefined(minimumAUCBalance)
                ? formatThousandSeparator(minimumAUCBalance)
                : 0}
            </span>
          </CustomSpace>
        </Col>
      </Row>

      {assetUnderCustody?.length > 0 && (
        <>
          <Divider orientationMargin="0" orientation="left">
            <span className={styles.info__title}>Asset under custody</span>
          </Divider>
          <Table
            className={styles.tableAUC}
            columns={assetUnderCustodyColumns}
            bordered
            dataSource={[...assetUnderCustody]
              .sort((a, b) => +(a.id || 1) - +(b.id || 1))
              .map(({ from, to, point }) => ({
                from: formatThousandSeparator(from) || 0,
                to: formatThousandSeparator(to),
                point: formatThousandSeparator(point),
              }))}
            pagination={false}
          />
        </>
      )}

      {sortedUpcomingWallet?.map((item, key) => (
        <UpcomingFeeInformation
          key={key}
          effectiveFrom={item.effectiveFrom}
          effectiveTo={item.effectiveTo}
          initialSetupFee={item.initialSetupFee}
          minimumMonthlyFee={item.minimumMonthlyFee}
          minimumAUCBalance={item.minimumAUCBalance}
          assetUnderCustody={item.assetUnderCustody}
          disabled={isRequestPending}
          onEdit={() => {
            setEditingItem(item);
            setOpenEditDialog(true);
          }}
          onDelete={() => setEditingItem(item)}
          allowEditInfo={allowEditInfo}
        />
      ))}

      {editingItem && (
        <FeeInformationDialog
          key="add-new-fee"
          title="Add new fee information"
          feeInfo={editingItem}
          open={openAddNewDialog}
          onClose={() => setOpenAddNewDialog(false)}
          onConfirm={values => handleConfirm('ADD', values)}
          unavailablePeriod={unavailablePeriod}
        />
      )}

      {editingItem && (
        <FeeInformationDialog
          key="update-fee"
          title="Edit fee information"
          feeInfo={editingItem}
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          onConfirm={values => handleConfirm('EDIT', values)}
          unavailablePeriod={unavailablePeriod}
        />
      )}

      <BasicModal
        destroyOnClose={true}
        className={styles.confirm__popup}
        visible={openConfirmDialog}
        children={
          <div className={styles.confirm__content}>
            After the request has been approved, new fee will be applied from
            effective date
          </div>
        }
        title="You confirm to submit the update fee request?"
        centered={true}
        onCancel={() => setOpenConfirmDialog(false)}
        maskClosable={true}
        width={404}
        footer={[
          <div className={styles.confirm__button}>
            <BasicButton
              title="Back"
              type="outline"
              onClick={() => setOpenConfirmDialog(false)}
            />
            <BasicButton
              title="Confirm"
              type="filled"
              onClick={handleDialogConfirm}
              loading={
                addNewFeeSelector.status === RequestStatus.Loading ||
                updateFeeSelector.status === RequestStatus.Loading
              }
            />
          </div>,
        ]}
      />
    </>
  );
};

export default ProductSubscriptionWallet;
