import { PayloadAction } from '@reduxjs/toolkit';
import {
  AddNewCommentRequest,
  GetCommnentsRequest,
  UpdateCommentRequest,
} from 'interfaces/comment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  addComment,
  deleteComment,
  getCommentsByTxnId,
  updateComment,
} from 'services/comment';
import { commentActions } from 'store/slices/comment';
import { handleShowMsgErr } from 'utils/common';

const fetchCommentsRequest = function* ({
  payload,
}: PayloadAction<GetCommnentsRequest>) {
  try {
    const res = yield call(getCommentsByTxnId, payload.txnId);
    yield put(commentActions.getCommentsSuccess(res.comments));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(commentActions.getCommentsFailed());
  }
};

const addCommentRequestSaga = function* ({
  payload,
}: PayloadAction<AddNewCommentRequest>) {
  try {
    const comments = yield select(state => state.comment.comments?.data);
    const res = yield call(addComment, payload.txnId, payload.params);
    const newRes = [...comments, res];
    yield put(commentActions.addCommentSuccess(newRes));
  } catch (error: any) {
    yield put(commentActions.addCommentFailed());
  }
};

const updateCommentRequestSaga = function* ({
  payload,
}: PayloadAction<UpdateCommentRequest>) {
  try {
    const comments = yield select(state => state.comment.comments.data);
    const { txnId, commentId, params } = payload;
    const res = yield call(updateComment, txnId, commentId, params);
    const newArr = comments.map(comment => {
      if (comment.id === commentId) {
        return res;
      }

      return comment;
    });

    yield put(commentActions.updateCommentSuccess(newArr));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(commentActions.updateCommentFailed());
  }
};

const deleteCommentRequestSaga = function* ({
  payload,
}: PayloadAction<UpdateCommentRequest>) {
  try {
    const comments = yield select(state => state.comment.comments.data);
    const newComments = comments.filter(
      comment => comment.id !== payload.commentId,
    );
    yield put(commentActions.deleteCommentSuccess(newComments));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(commentActions.deleteCommentFailed());
  }
};

const commentSaga = function* () {
  yield takeLatest(
    commentActions.getCommentsRequest.type,
    fetchCommentsRequest,
  );
  yield takeLatest(
    commentActions.addCommentRequest.type,
    addCommentRequestSaga,
  );
  yield takeLatest(
    commentActions.updateCommentRequest.type,
    updateCommentRequestSaga,
  );
  yield takeLatest(
    commentActions.deleteCommentRequest.type,
    deleteCommentRequestSaga,
  );
};

export default commentSaga;
