import RButton from 'app/components/Button';
import BasicModal from 'app/components/common/BasicModal';
import { useDispatch } from 'react-redux';
import { newCustomerActions } from 'store/slices/addNewCustomer';
import styles from './index.module.scss';
import { useAppSelector } from 'store/hooks';
import {
  editCustomerActions,
  selectIsOpenNavigation,
  selectNavigationPath,
} from 'store/slices/editCustomer';
import { useEffect } from 'react';
import { history } from 'utils/history';

const ModalConfirmNavigation = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector(selectIsOpenNavigation);
  const navigationPath = useAppSelector(selectNavigationPath);
  const onClickCancel = () => {
    setIsOpen(false);
  };

  const setIsOpen = (isOpen: boolean) => {
    dispatch(editCustomerActions.setIsOpenNavigation(isOpen));
  };

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  return (
    <BasicModal
      className={styles.modal}
      visible={isOpen}
      centered
      onCancel={onClickCancel}
      footer={[
        <RButton
          buttonType="back"
          content="Back"
          width={67}
          height={36}
          onClick={() => setIsOpen(false)}
          key="back-button"
        />,
        <RButton
          buttonType="submit"
          content="Confirm"
          width={90}
          height={36}
          onClick={() => {
            dispatch(newCustomerActions.resetcreateNewCustomer());
            history.push(navigationPath);
          }}
          key="submit-button"
        />,
      ]}
    >
      <>
        <div className={styles.modal__title}>Leave this page?</div>
        <div className={styles.modal__desc}>
          All of your changes will be discarded. You will need to start over
          again.
        </div>
      </>
    </BasicModal>
  );
};

export default ModalConfirmNavigation;
