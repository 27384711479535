import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Poppins/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/Poppins/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2') format('woff2');
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Poppins', sans-serif !important;
    background-color: #f7f7f7;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Poppins', sans-serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: 'Poppins', sans-serif;
    font-size: inherit;
  }

  .ant-form-item-label > label.ant-form-item-required:before {
    display: none !important;
  }
  .ant-form-item-label > label.ant-form-item-required:after {
    display: inline-block !important;
    margin-right: 4px !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    font-family: Poppins ,sans-serif !important;
    line-height: 1 !important;
    content: "*" !important;
  }
  
  .ant-input{
    // padding: 8px 12px !important;
    // border-radius: 6px !important; 
  }

  .ant-form-item{
    margin: 0 0 16px;
  }

  .ant-form-item-explain-error{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #F04438;
    margin-top: 6px
  }
  .ant-form-item-label>label{
    font-size: 12px !important
  }

  .PhoneInputCountry{
    width: 112px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #B5B6B7;
    border-radius: 6px;
    padding-left: 12px;
    margin-right: 8px;
  }
  .PhoneInputInput{
    height: 36px;
    background: #FFFFFF;
    width: 100%;
    border-radius: 6px;
    padding: 8px 12px;
    box-sizing: border-box;
    width: 100%;
    color: rgba(0, 0, 0, 0.85);
    background-color: #FFFFFF;
    border: 1px solid #B5B6B7;
    transition: all 0.3s;
  }
  .PhoneInputInput:hover {
    border-color: #40a9ff;
    border-right-width: 1px;
  }
  .PhoneInputInput:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    border-right-width: 1px;
    outline: 0;
  }

  .PhoneInputCountrySelectArrow{
    margin-left: 55px !important;
    font-size: 20px;
  }

  /* HEADER */

  /* css dot */

  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail, .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: block;
}

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #ECA5E2 !important;
  }
  

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #D63A95 !important;
  }
  

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 2px !important;
  }

  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #F3C5E0 !important;
  }
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
    background: linear-gradient(180deg, #D63A95 0%, #F3F3F4 100%);
  }

  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-weight: 400;
    font-size: 10px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #383A3C !important;
    
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: #D63A95 !important;
  }

  /* .ant-steps-item-container:hover {
    pointer-events: none !important;
  } */


  /* FOOTER */
  .ant-pagination-disabled, .ant-pagination-disabled:hover {
    pointer-events: none;
    svg{
      color: #b5b6b7;
    }
  }

  .ant-pagination-item-active {
    background: #ECEDED !important;
    a {
      color: #0E0E0F !important;
    }
  }
  
  .ant-collapse-header {
    padding: 0 40px 0 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-tooltip-inner {
    border-radius: 10px !important;
    padding: 12px !important;
  }

  
  .ant-input-clear-icon {
    display: flex !important;
  }
  
  .ant-select-clear {
    display: flex !important;
  }
`;
