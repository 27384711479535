import { SvgIcon } from 'app/components/common/SvgIcon';
import styles from './index.module.scss';
import ItemComment from './ItemComment';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserProfile } from 'store/slices/auth';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import TextEditor from 'app/components/common/TextEditor';
import { ItemComment as IComment } from 'interfaces/comment';
import { commentActions, selectAddComment } from 'store/slices/comment';
import BasicButton from 'app/components/common/BasicButton';
import { isQuillEmpty } from 'utils/string';
import { RequestStatus } from 'interfaces/request';
import RakkarAvatar from './Avatar';

type Props = {
  txnId: string;
  comments: IComment[];
};

const BoxComment = ({ comments, txnId }: Props) => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectUserProfile);
  const addComment = useAppSelector(selectAddComment);
  const [isTextEditor, setIsTextEditor] = useState<boolean>(false);
  const initDefault = '';
  const [txtComment, setTxtComment] = useState<string>(initDefault);

  const loadingSave = addComment.status === RequestStatus.Loading;

  const handleSend = () => {
    if (txtComment.length <= 0 || isQuillEmpty(txtComment)) {
      return;
    }

    dispatch(
      commentActions.addCommentRequest({
        txnId,
        params: {
          content: txtComment,
        },
      }),
    );
  };

  useEffect(() => {
    if (addComment.status === RequestStatus.Success) {
      setIsTextEditor(false);
    }
  }, [addComment.status]);

  const handleEditorChange = value => {
    setTxtComment(value);
  };

  if (!profile) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} data-testid="header-comment">
        <SvgIcon
          className={styles.icon}
          name="comment"
          width={16}
          height={16}
        />
        <div className={styles.heading} data-testid="heading-comment">
          Comment
        </div>
      </div>
      <div className={styles.list_comment}>
        {comments.length > 0 &&
          comments.map(comment => {
            return (
              <ItemComment key={comment.id} comment={comment} txnId={txnId} />
            );
          })}
      </div>
      <div
        className={clsx(styles.send, { [styles.send__editor]: isTextEditor })}
      >
        <RakkarAvatar avatar={profile.picture} name={profile.name} />
        {!isTextEditor ? (
          <span
            className={styles.send__text}
            onClick={() => {
              setTxtComment(initDefault);
              setIsTextEditor(!isTextEditor);
            }}
            data-testid="add-comment"
          >
            Add comment
          </span>
        ) : (
          <div>
            <TextEditor
              initValue={txtComment}
              onChangeEditor={handleEditorChange}
            />
            <div className={styles.actionBtn}>
              <BasicButton
                dataTestId="cancel"
                title="Cancel"
                type="outline"
                onClick={() => {
                  setIsTextEditor(!isTextEditor);
                }}
              />
              <BasicButton
                dataTestId="save"
                title="Save"
                type="filled"
                loading={loadingSave}
                onClick={handleSend}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoxComment;
