import { Form, FormItemProps, Input } from 'antd';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { CSSProperties, ReactNode } from 'react';
import styles from './index.module.scss';
import { TextAreaProps } from 'antd/lib/input';

export interface IInputProps {
  label?: string | ReactNode;
  rules?: FormItemProps['rules'];
  name?: string | string[];
  colon?: boolean;
  useLabel?: boolean;
  placeholder?: string;
  value?: string;
  id?: string;
  onChange?: (e: any) => void;
  onInput?: (e: any) => void;
  allowClear?: boolean;
  bordered?: boolean;
  defaultValue?: string;
  status?: 'error' | 'warning';
  maxLength?: number;
  disabled?: boolean;
  onPressEnter?: (e: any) => void;
  className?: string;
  classNameFormItem?: string;
  initialValueForm?: string;
  tooltip?: LabelTooltipType;
  readOnly?: boolean;
  noStyle?: boolean;
  inputStyle?: CSSProperties;
  dependencies?: string[];
  validateFirst?: boolean;
  onClick?: (e: any) => void;
  dataTestId?: string;
  onKeyDown?: (e: any, name?: string) => void;
  autoComplete?: string;
  autoFocus?: boolean;
  autoSize?: TextAreaProps['autoSize'];
}
const BasicTextArea = (props: IInputProps) => {
  const isRequired = props.rules
    ? props.rules.filter((r: any) => r.required === true).length > 0
    : false;

  const isLabel = props.useLabel || true;
  return (
    <Form.Item
      label={isLabel ? props.label : ''}
      colon={props.colon || false}
      name={props.name}
      required={!props.disabled && isRequired}
      rules={props.disabled ? [] : props.rules}
      className={`${styles.classNameFormItem} ${props.classNameFormItem}`}
      initialValue={props.initialValueForm}
      tooltip={props.tooltip}
      noStyle={props.noStyle}
      dependencies={props.dependencies}
      validateFirst={props.validateFirst}
    >
      <Input.TextArea
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
        className={`${styles.input} ${props.className}`}
        value={props.value}
        id={props.id}
        allowClear={props.allowClear}
        bordered={props.bordered}
        defaultValue={props.defaultValue}
        status={props.status}
        maxLength={props.maxLength}
        disabled={props.disabled}
        onPressEnter={props.onPressEnter}
        onChange={props.onChange}
        style={props.inputStyle}
        readOnly={props.readOnly}
        onClick={props.onClick}
        data-testid={props.dataTestId}
        onKeyDown={props.onKeyDown}
        onInput={props.onInput}
        autoComplete={props.autoComplete}
        autoSize={props.autoSize}
      />
    </Form.Item>
  );
};
export default BasicTextArea;
