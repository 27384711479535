import { Button, Divider, Row, Table } from 'antd';
import { useState } from 'react';

import { SvgIcon } from 'app/components/SvgIcon';

import CustomerProfileTab from 'app/components/common/CustomerProfile';
import TextInfor from 'app/components/common/TextInfor';
import { FORMAT_DATE, PRODUCT_TYPE, STATUS_REQUEST } from 'constants/index';
import { RequestType } from 'enum/pendingRequest';
import { formatThousandSeparator } from 'utils/common';
import { formatDateRange } from 'utils/date';
import { renderStatus } from '../MarkedAsLostDevice';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
  isMask: boolean;
}

const defaultColumns = [
  {
    title: 'From',
    dataIndex: 'from',
  },
  {
    title: 'To',
    dataIndex: 'to',
  },
  {
    title: 'Point',
    dataIndex: 'point',
    render: (point: string, record) => {
      return record.oldPoint ? (
        <Row className={styles.monthly__fee}>
          <span className={styles.current__fee}>20</span>
          <SvgIcon name="arrow-right" width={11} height={11} />
          <span className={styles.updated__fee}>15</span>
        </Row>
      ) : (
        point
      );
    },
  },
];

const UpdateFee = ({ requestDetail, isMask }: Props) => {
  const [open, setOpen] = useState(false);

  const showChangingPrice = (name: string) => {
    if (
      !requestDetail?.currentSubscription?.feeInformation[name] &&
      !requestDetail?.newSubscription?.feeInformation[name]
    ) {
      return 0;
    }
    return requestDetail?.action === RequestType.UPDATE_FEE_INFORMATION &&
      requestDetail?.currentSubscription?.feeInformation[name] !==
        requestDetail?.newSubscription?.feeInformation[name] ? (
      <Row className={styles.monthly__fee}>
        <span className={styles.current__fee}>
          {formatThousandSeparator(
            requestDetail?.currentSubscription?.feeInformation[name],
          )}
        </span>
        <SvgIcon name="arrow-right" width={11} height={11} />
        <span className={styles.updated__fee}>
          {formatThousandSeparator(
            requestDetail?.newSubscription?.feeInformation[name],
          )}
        </span>
      </Row>
    ) : (
      <>
        {formatThousandSeparator(
          requestDetail?.newSubscription?.feeInformation[name],
        )}
      </>
    );
  };

  const showChangingInAUC = (name: string, index) => {
    const oldValue =
      requestDetail?.currentSubscription?.feeInformation?.assetUnderCustody?.[
        index
      ]?.[name];
    const newValue =
      requestDetail?.newSubscription?.feeInformation?.assetUnderCustody?.[
        index
      ]?.[name];
    return requestDetail?.action === RequestType.UPDATE_FEE_INFORMATION &&
      oldValue !== newValue ? (
      <Row className={styles.monthly__fee}>
        <span className={styles.current__fee}>
          {formatThousandSeparator(oldValue)}
        </span>
        <SvgIcon name="arrow-right" width={11} height={11} />
        <span className={styles.updated__fee}>
          {formatThousandSeparator(newValue)}
        </span>
      </Row>
    ) : (
      formatThousandSeparator(newValue)
    );
  };

  const showChangingPeriod = () => {
    const effectiveFrom =
      requestDetail?.currentSubscription?.feeInformation?.effectiveFrom;
    const effectiveTo =
      requestDetail?.currentSubscription?.feeInformation?.effectiveTo;

    const effectivePeriod = formatDateRange(
      effectiveFrom,
      effectiveTo,
      FORMAT_DATE.DATE_MMMMYYYY,
      true,
    );

    const newEffectiveFrom =
      requestDetail?.newSubscription?.feeInformation?.effectiveFrom;
    const newEffectiveTo =
      requestDetail?.newSubscription?.feeInformation?.effectiveTo;

    const newEffectivePeriod = formatDateRange(
      newEffectiveFrom,
      newEffectiveTo,
      FORMAT_DATE.DATE_MMMMYYYY,
      true,
    );

    return requestDetail?.action === RequestType.UPDATE_FEE_INFORMATION &&
      newEffectivePeriod !== effectivePeriod ? (
      <Row className={styles.monthly__fee}>
        <span className={styles.current__fee}>{effectivePeriod}</span>
        <SvgIcon name="arrow-right" width={11} height={11} />
        <span className={styles.updated__fee}>{newEffectivePeriod}</span>
      </Row>
    ) : (
      newEffectivePeriod
    );
  };

  return (
    <div className={styles.content}>
      <SvgIcon name="update-fee-icon" width={32} height={32} />
      <Row className={styles.reviewing__title}>
        {requestDetail?.action === RequestType.UPDATE_FEE_INFORMATION
          ? 'Update fee request'
          : 'Add fee request'}
        {(requestDetail?.status === STATUS_REQUEST.APPROVED ||
          requestDetail?.status === STATUS_REQUEST.REJECTED) &&
          renderStatus(requestDetail.status)}
      </Row>
      <Row>
        <TextInfor
          label="Customer name"
          text={
            <Button
              type="link"
              onClick={() => setOpen(true)}
              className={styles.customer__detail}
            >
              {requestDetail?.customer?.name ?? ''}
            </Button>
          }
          dataTestId="customer-name"
        />
        <TextInfor
          label="Business registration number"
          text={
            <Button
              type="link"
              onClick={() => setOpen(true)}
              className={styles.customer__detail}
            >
              {requestDetail?.customer?.businessRegistrationId ?? ''}
            </Button>
          }
          dataTestId="registration-id"
        />
      </Row>
      <div className={styles.user__info}>
        <Row>
          <TextInfor
            span={12}
            label="Product subcription"
            text={
              requestDetail?.newSubscription?.productType
                ? PRODUCT_TYPE[requestDetail?.newSubscription?.productType].text
                : ''
            }
          />
          <TextInfor
            span={12}
            label="Wallet workspace"
            text={requestDetail?.workspace?.name ?? ''}
          />
        </Row>
        <Divider
          orientationMargin="0"
          className={styles.content__header}
          orientation="left"
        >
          Fee information
        </Divider>
        <Row>
          <TextInfor
            span={12}
            label="Effective period"
            text={showChangingPeriod()}
          />
          <TextInfor
            span={12}
            label="Initial setup fee (USD)"
            text={showChangingPrice('initialSetupFee')}
          />
          <TextInfor
            span={12}
            label="Minimum monthly fee (USD)"
            text={showChangingPrice('minimumMonthlyFee')}
          />
          <TextInfor
            span={12}
            label="Minimum AUC Balance (USD)"
            text={showChangingPrice('minimumAUCBalance')}
          />
        </Row>
        <>
          <Divider
            orientationMargin="0"
            className={styles.content__header}
            orientation="left"
          >
            Asset under custody
          </Divider>
          <div style={{ marginTop: '12px' }}>
            <Table
              className={styles.tableAUC}
              columns={defaultColumns}
              bordered
              dataSource={requestDetail?.newSubscription?.feeInformation?.assetUnderCustody.map(
                ({ key, from, to, point }, index) => ({
                  key,
                  from: showChangingInAUC('from', index),
                  to: showChangingInAUC('to', index),
                  point: showChangingInAUC('point', index),
                }),
              )}
              pagination={false}
            />
          </div>
        </>
      </div>

      <CustomerProfileTab
        open={open}
        setOpen={setOpen}
        requestDetail={requestDetail}
        isMask={isMask}
      />
    </div>
  );
};

export default UpdateFee;
