import { Configuration, LogLevel, PopupRequest } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_BASE_AUTH_CLIENT_ID as string,
    authority: process.env.REACT_APP_BASE_AUTH_AUTHORITY as string,
    redirectUri: '/redirect',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};
