import { FC, useEffect, useRef, useState } from 'react';
import { Checkbox, Form, FormItemProps, Row, Typography } from 'antd';
import { REGEXES } from '../../../../../constants';
import {
  WcApplicationCheckData,
  validateWcApplicationValuesExist,
  debounceValidateWcApplicationValuesExist,
} from '../../helper';
import {
  ApplicationFormErrorMessage,
  MAX_LOGO_FILE_SIZE,
  WalletApplicationForm,
} from '../../const';
import { FormInstance } from 'antd/es/form/Form';
import BasicInput from 'app/components/common/BasicInput';

import BasicModal from 'app/components/common/BasicModal';
import { modalConfirm } from 'app/components/common/BasicModal/ConfirmModal';
import FormItem from 'antd/es/form/FormItem';
import BasicButton from 'app/components/common/BasicButton';
import BasicCheckbox from 'app/components/common/BasicCheckbox';
import imgColdWallet from 'images/cold-wallet.png';
import imgHotWallet from 'images/hot-wallet.png';

import BasicDraggerUpload from 'app/components/common/BasicUpload';
import BasicTextArea from 'app/components/common/BasicTextArea';
import { wcApplicationActions } from 'store/slices/wcApplication/wcApplication.slice';
import { useAppDispatch } from 'store/hooks';

import { SvgIcon } from 'app/components/SvgIcon';
import { getBase64 } from 'utils/helpers';
import { toast } from 'react-toastify';

import './styles.scss';
import ToastMessage from 'app/components/common/ToastMessage';
import DynamicSelect from 'app/components/common/DynamicSelect';

import { getCustomerEntityRelations } from 'services/customerEntityRelations';
import { WorkSpaceType } from 'enum/common';

type Props = {
  form: FormInstance<WalletApplicationForm>;
  open: boolean;
  onCloseModal: () => void;
};

const AddNewApplicationPopup: FC<Props> = ({ form, open, onCloseModal }) => {
  const dispatch = useAppDispatch();
  const [logoBase64, setLogoBase64] = useState<string>('');

  const handleSubmit = async () => {
    try {
      const data = await form.validateFields();

      const checkExistApplicationId = await validateWcApplicationValuesExist(
        'externalId',
        form.getFieldValue('externalId'),
      );

      const checkExistApplicationName = await validateWcApplicationValuesExist(
        'name',
        form.getFieldValue('name'),
      );

      if (checkExistApplicationId || checkExistApplicationName) {
        const error: Parameters<typeof form.setFields>[0][number][] = [];

        checkExistApplicationId &&
          error.push({
            name: 'externalId',
            errors: [`Application ID already exists. Please try another one`],
          });

        checkExistApplicationName &&
          error.push({
            name: 'name',
            errors: [`Application name already exists. Please try another one`],
          });

        form.setFields(error);
        return;
      }

      const modal = modalConfirm({
        title: 'Are you sure to add new application',
        description:
          'Please ensure to check all information carefully before confirm',
        okText: 'Confirm',
        cancelText: 'Back',
        onOk: async () => {
          const {
            externalId,
            name,
            url,
            logo,
            destinationNote,
            walletType,
            allowedEntities,
          } = data;
          await dispatch(
            wcApplicationActions.createWcApplicationRequest({
              externalId,
              name,
              url,
              // logo: `/logo/${externalId}.png`,
              logo,
              destinationNote,
              allowedEntities,
              isWarm: walletType.includes(WorkSpaceType.Hot),
              isCold: walletType.includes(WorkSpaceType.Cold),
            }),
          );

          toast(
            <ToastMessage
              type="info"
              message={'The application has been added successfully'}
            />,
          );
          onCloseModal();
          modal.destroy();
        },
        onCancel: () => {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    !open && setLogoBase64('');
  }, [open]);

  const handleCloseModal = () => {
    const modal = modalConfirm({
      okText: 'Confirm',
      cancelText: 'Back',
      title: 'Are you sure to discard this process?',
      description:
        'All information will not be saved. You need to start over again',
      onOk: () => {
        onCloseModal();
        modal.destroy();
      },
      onCancel: () => modal.destroy(),
    });
  };

  const generateCheckExistWcApplicationField = (arg: {
    mappedValueMessage: string;
    fieldName: keyof WcApplicationCheckData;
  }): NonNullable<FormItemProps['rules']>[number] => {
    return {
      validator: async (_, value) => {
        return new Promise((resolve, reject) => {
          debounceValidateWcApplicationValuesExist(arg.fieldName, value)?.then(
            (check: boolean) => {
              if (check) {
                reject(
                  `${arg.mappedValueMessage} already exists. Please try another one`,
                );
              }

              return resolve(check);
            },
          );
        });
      },
    };
  };

  const generateCustomMaxLengthValidator = (data: {
    maxLength: number;
    fieldName: keyof WalletApplicationForm;
  }): NonNullable<FormItemProps['rules']>[number] => {
    return {
      validator: (_, value: string) => {
        if (value?.length > data.maxLength) {
          form.setFieldValue(
            data.fieldName,
            value.substring(0, data.maxLength),
          );
        }

        return Promise.resolve();
      },
    };
  };

  const logoValue = form.getFieldValue('logo')?.[0] || {};

  return (
    <BasicModal
      className={'new-application-modal'}
      visible={open}
      title="Add new application"
      onCancel={handleCloseModal}
      footer={
        <Row justify="end">
          <BasicButton
            type="outline"
            title="Cancel"
            onClick={handleCloseModal}
          />
          <BasicButton type="filled" title="Add" onClick={handleSubmit} />
        </Row>
      }
    >
      <Form form={form} layout="vertical">
        <BasicInput
          label="Application name"
          name="name"
          placeholder="Enter application name"
          rules={[
            generateCustomMaxLengthValidator({
              maxLength: 100,
              fieldName: 'name',
            }),

            generateCheckExistWcApplicationField({
              mappedValueMessage: 'Application name',
              fieldName: 'name',
            }),
            {
              required: true,
              message: ApplicationFormErrorMessage.name.required,
            },
          ]}
        />
        <BasicInput
          label="Application ID"
          name="externalId"
          placeholder="Enter application ID"
          rules={[
            generateCustomMaxLengthValidator({
              maxLength: 100,
              fieldName: 'externalId',
            }),

            generateCheckExistWcApplicationField({
              mappedValueMessage: 'Application ID',
              fieldName: 'externalId',
            }),
            {
              required: true,
              message: ApplicationFormErrorMessage.externalId.required,
            },
          ]}
        />
        <BasicInput
          label="Web URL"
          name="url"
          placeholder="https://"
          rules={[
            generateCustomMaxLengthValidator({
              maxLength: 255,
              fieldName: 'url',
            }),
            generateCheckExistWcApplicationField({
              mappedValueMessage: 'Web URL',
              fieldName: 'url',
            }),
            {
              pattern: REGEXES.url,
              message: ApplicationFormErrorMessage.url.invalid,
            },
          ]}
        />
        <BasicInput
          label="Logo"
          name="logo"
          placeholder="https://"
          rules={[
            {
              required: true,
              message: ApplicationFormErrorMessage.logo.required,
            },
            generateCustomMaxLengthValidator({
              maxLength: 255,
              fieldName: 'logo',
            }),
            {
              type: 'url',
              message: ApplicationFormErrorMessage.logo.invalid,
            },
          ]}
        />

        {/* {Boolean(logoBase64) ? (
          <Form.Item label="Logo" className="show-logo-container" required>
            <img src={logoBase64} alt={logoValue.originFileObj?.name} />

            <Row align="middle">
              <Typography.Paragraph>
                {logoValue.originFileObj?.name}
              </Typography.Paragraph>

              <SvgIcon
                name="trash"
                width={16}
                height={16}
                onClick={() => {
                  form.setFieldValue('logo', undefined);
                  setLogoBase64('');
                }}
              />
            </Row>
          </Form.Item>
        ) : (
          <BasicDraggerUpload
            label="Logo"
            name="logo"
            allowFileType="image/png"
            maxFileSize={MAX_LOGO_FILE_SIZE}
            valuePropName="fileList"
            text="Click or drag file to this area to upload"
            hint="Maximum file size: 1MB. Supported format PNG"
            getValueFromEvent={(e: any) => {
              if (Array.isArray(e)) {
                return e;
              }

              const fileList = e?.fileList || [];

              const file = fileList[fileList.length - 1];

              return [file];
            }}
            noStyle
            rules={[
              {
                required: true,
                message: ApplicationFormErrorMessage.logo.required,
              },
              {
                validator: (_, value) => {
                  const file = value?.[0];
                  if (file?.type && !file?.type?.includes('png')) {
                    return Promise.reject(
                      ApplicationFormErrorMessage.logo.fileType,
                    );
                  }

                  if (file?.size && file?.size > MAX_LOGO_FILE_SIZE) {
                    return Promise.reject(
                      ApplicationFormErrorMessage.logo.overSize,
                    );
                  }

                  file?.originFileObj &&
                    getBase64(file?.originFileObj, url => {
                      setLogoBase64(url);
                    });

                  return Promise.resolve();
                },
              },
            ]}
            multiple={false}
            uploadName="files"
          />
        )} */}

        <BasicTextArea
          label="Destination note"
          name="destinationNote"
          placeholder="Enter note"
          rules={[
            {
              required: true,
              message: ApplicationFormErrorMessage.destinationNote.required,
            },
            generateCustomMaxLengthValidator({
              maxLength: 500,
              fieldName: 'destinationNote',
            }),
          ]}
        />

        <div className={'devider'} />

        <Typography.Text className={'section-title'}>
          Rakkar availability
        </Typography.Text>

        <DynamicSelect
          serviceFunc={getCustomerEntityRelations}
          mode="multiple"
          name="allowedEntities"
          label="Allowed entity"
          labelValue="name"
          keyValue="countryCode"
          placeholder="Select..."
          rules={[
            {
              required: true,
              message: ApplicationFormErrorMessage.allowedEntities.required,
            },
          ]}
        />

        <FormItem
          label="Wallet type"
          name="walletType"
          rules={[
            {
              required: true,
              message: ApplicationFormErrorMessage.walletType.required,
            },
          ]}
        >
          <Checkbox.Group>
            <BasicCheckbox value={WorkSpaceType.Cold}>
              <div className="checkbox-content">
                <img src={imgColdWallet} loading="lazy" alt="" />
                <span>Rakkar Cold</span>
              </div>
            </BasicCheckbox>
            <BasicCheckbox value={WorkSpaceType.Hot}>
              <div className="checkbox-content">
                <img src={imgHotWallet} loading="lazy" alt="" />
                <span>Rakkar Warm</span>
              </div>
            </BasicCheckbox>
          </Checkbox.Group>
        </FormItem>
      </Form>
    </BasicModal>
  );
};

export default AddNewApplicationPopup;
