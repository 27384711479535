import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { RequestStatus } from 'interfaces/request';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectReviewerByTxnIdData,
  transactionActions,
} from 'store/slices/transaction';
import styles from './index.module.scss';

interface Props {
  isOpen: boolean;
  txId: string;
  setIsOpen: (value: boolean) => void;
  customerName: string;
}
const MarkAsReviewed = ({ isOpen, setIsOpen, txId, customerName }: Props) => {
  const dispatch = useAppDispatch();
  const reviewerData = useAppSelector(selectReviewerByTxnIdData) ?? {};
  const loading = reviewerData.status === RequestStatus.Loading;
  const handleApprove = () => {
    dispatch(
      transactionActions.markTransactionAsReviewRequest({
        txId: txId,
        body: {
          reviewStatus: 'REVIEWED',
          verifyApprovalLogWithQuorumRequirement: true,
          verifyCustomerIdentityFromLiveVideoCapture: true,
          signTransactionOnFBColdWalletApp: true,
          approveTransactionOnFBMobileApp: true,
          reviewKYTInformation: true,
          approveTransactionOnMobileApp: true,
        },
      }),
    );
  };

  useEffect(() => {
    if (reviewerData.status === RequestStatus.Success) {
      setIsOpen(false);
    }
  }, [reviewerData.status, setIsOpen]);

  const content = (
    <div className={styles.content}>
      Please check the information carefully before confirming as you will be
      responsible for this transaction after reviewing
    </div>
  );
  return (
    <BasicModal
      destroyOnClose={true}
      className={styles.approve__popup}
      visible={isOpen}
      children={content}
      title={`You have reviewed the transaction?`}
      centered={true}
      onCancel={() => setIsOpen(false)}
      maskClosable={true}
      width={404}
      footer={[
        <div className={styles.button}>
          <BasicButton
            title="Back"
            type="outline"
            onClick={() => setIsOpen(false)}
          />
          <BasicButton
            title="Confirm"
            type="filled"
            className={styles.confirm}
            onClick={handleApprove}
            loading={loading}
          />
        </div>,
      ]}
    />
  );
};

export default MarkAsReviewed;
