import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import RButton from 'app/components/Button';
import BasicModal from 'app/components/common/BasicModal';
import { RequestType } from 'enum/pendingRequest';
import { RequestStatus } from 'interfaces/request';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { requestActions, selectRejectRequest } from 'store/slices/request';
import styles from './index.module.scss';

interface Props {
  isOpen: boolean;
  requestId: string;
  setIsOpen: (value: boolean) => void;
  customerName: string;
  reqAction?: string;
}

const RejectRequest = ({
  isOpen,
  setIsOpen,
  customerName,
  requestId,
  reqAction,
}: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const reject = useAppSelector(selectRejectRequest);
  const actionReplaceDevice = reqAction === RequestType.REP_REPLACE_DEVICE;
  const actionUnlockUser = reqAction === RequestType.UNLOCK_USER;
  const actionAddNewSubscription = reqAction === RequestType.CUSTOMER_SUBSCRIBE;

  useEffect(() => {
    form.setFieldsValue({ reason: '' });
    setIsDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onFinish = () => {
    const values = form.getFieldsValue();
    dispatch(
      requestActions.updateRejectRequest({
        recordId: requestId,
        reason: values.reason,
      }),
    );
  };

  useEffect(() => {
    if (reject?.status === RequestStatus.Success) {
      setIsOpen(false);
    }
  }, [reject?.status, setIsOpen]);

  const onValuesChange = useCallback((_, values: any) => {
    const isEmpty =
      Object.values(values).some((e: any) => !e.toString().trim()) ||
      Object.values(values).includes(undefined);
    setIsDisabled(isEmpty);
  }, []);

  const handleChangeReason = e => {
    const value = e.target.value && e.target.value.trim().length;
    if (!value) {
      setIsDisabled(true);
    }
  };

  const content = (
    <Form form={form} onValuesChange={onValuesChange} layout="vertical">
      <span className={styles.desc}>
        {actionReplaceDevice || actionUnlockUser || actionAddNewSubscription
          ? 'Please ensure to check all information carefully before rejecting'
          : 'Please check customer information carefully before completing the action'}
      </span>
      <Form.Item
        name={'reason'}
        label={'Reason'}
        rules={[
          { required: true, message: 'Reason is required' },
          {
            validator: (_, value) => {
              const valueTrim = value.trim();
              if (value.length && !valueTrim) {
                setIsDisabled(true);
                return Promise.reject('Reason is required');
              }
              if (value && value.length > 500) {
                setIsDisabled(true);
                return Promise.reject('Max of 500 characters');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <TextArea
          data-testid="input-reason"
          name={'reason'}
          className={styles.reason}
          placeholder="Please add a brief reason for this rejection..."
          style={{ height: 120, resize: 'none', marginTop: '8px' }}
          onChange={handleChangeReason}
        ></TextArea>
      </Form.Item>
    </Form>
  );

  return (
    <BasicModal
      className={styles.reject__popup}
      visible={isOpen}
      children={content}
      title={
        actionReplaceDevice || actionUnlockUser || actionAddNewSubscription
          ? 'You confirm to reject the request?'
          : `You confirm to reject ${customerName || 'the request'}?`
      }
      centered={true}
      width={404}
      maskClosable={true}
      onCancel={() => setIsOpen(false)}
      footer={[
        <RButton
          buttonType="back"
          content={'Back'}
          width={83}
          height={36}
          style={{ marginRight: '8px' }}
          onClick={() => setIsOpen(false)}
        />,
        <RButton
          htmlType="submit"
          buttonType="submit"
          content="Confirm"
          width={90}
          height={36}
          onClick={() => onFinish()}
          disable={isDisabled}
        />,
      ]}
    ></BasicModal>
  );
};

export default RejectRequest;
