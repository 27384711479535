import { Row } from 'antd';
import { showUserInfo } from 'app/components/PendingRequestDrawer/UserCreation';
import { DOCUMENT_TYPE } from 'enum/common';
import { useMemo } from 'react';
import { mapCountry } from 'utils/helpers';
import TextInfor from '../TextInfor';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
  isMask: boolean;
  isEditUser?: boolean;
}

const IdPassportInfo = ({
  requestDetail,
  isMask,
  isEditUser = false,
}: Props) => {
  const oldEditUserData = requestDetail?.oldUser;
  const newEditUserData = requestDetail?.newUser;

  const documentType = useMemo(() => {
    if (isEditUser) {
      return newEditUserData?.documentType
        ? newEditUserData?.documentType
        : oldEditUserData?.documentType;
    } else return requestDetail?.user?.documentType;
  }, [requestDetail]);

  return documentType === DOCUMENT_TYPE.ID_CARD ? (
    <Row>
      <TextInfor
        label="ID Number"
        text={
          !isEditUser ? (
            <div className={styles.role} data-testid="passport-number">
              {requestDetail?.user?.idCardNumber}
            </div>
          ) : (
            showUserInfo(
              oldEditUserData?.idCardNumber ?? oldEditUserData?.passportNumber,
              newEditUserData?.idCardNumber,
            )
          )
        }
        isMask={isMask}
        isMaskable={true}
      />
      <TextInfor
        label="Country of issued ID card"
        text={
          !isEditUser ? (
            <div data-testid="country-passport">
              {!!requestDetail?.user?.idCardIssuedCountry &&
                mapCountry(requestDetail?.user?.idCardIssuedCountry)}
            </div>
          ) : (
            showUserInfo(
              mapCountry(
                oldEditUserData?.idCardIssuedCountry ??
                  oldEditUserData?.passportIssuedCountry,
              ),
              mapCountry(newEditUserData?.idCardIssuedCountry),
            )
          )
        }
      />
    </Row>
  ) : (
    <Row>
      <TextInfor
        label="Passport Number"
        text={
          !isEditUser ? (
            <div className={styles.role} data-testid="passport-number">
              {requestDetail?.user?.passportNumber}
            </div>
          ) : (
            showUserInfo(
              oldEditUserData?.idCardNumber ?? oldEditUserData?.passportNumber,
              newEditUserData?.passportNumber,
            )
          )
        }
        isMask={isMask}
        isMaskable={true}
      />
      <TextInfor
        label="Country of issued passport"
        text={
          !isEditUser ? (
            <div data-testid="country-passport">
              {!!requestDetail?.user?.passportIssuedCountry &&
                mapCountry(requestDetail?.user?.passportIssuedCountry)}
            </div>
          ) : (
            showUserInfo(
              mapCountry(
                oldEditUserData?.idCardIssuedCountry ??
                  oldEditUserData?.passportIssuedCountry,
              ),
              mapCountry(newEditUserData?.passportIssuedCountry),
            )
          )
        }
      />
    </Row>
  );
};

export default IdPassportInfo;
