import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { RequestType } from 'enum/pendingRequest';
import { RequestStatus } from 'interfaces/request';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { requestActions, selectApproveRequest } from 'store/slices/request';
import styles from './index.module.scss';

interface Props {
  isOpen: boolean;
  requestId: string;
  setIsOpen: (value: boolean) => void;
  customerName: string;
  reqAction?: string;
}
const ApproveRequest = ({
  isOpen,
  setIsOpen,
  requestId,
  customerName,
  reqAction,
}: Props) => {
  const dispatch = useAppDispatch();
  const approve = useAppSelector(selectApproveRequest);
  const loading = approve.status === RequestStatus.Loading;
  const handleApprove = () => {
    dispatch(requestActions.approveRequest({ quorumRecordId: requestId }));
  };
  const actionReplaceDevice = reqAction === RequestType.REP_REPLACE_DEVICE;
  const actionUnlockUser = reqAction === RequestType.UNLOCK_USER;
  const actionEditUser = reqAction === RequestType.REP_UPDATE_USER;
  const actionAddNewSubscription = reqAction === RequestType.CUSTOMER_SUBSCRIBE;

  useEffect(() => {
    if (approve.status === RequestStatus.Success) {
      setIsOpen(false);
    }
  }, [approve.status, setIsOpen]);

  const content = (
    <div className={styles.content}>
      Please ensure to check all information carefully before approval
    </div>
  );
  return (
    <BasicModal
      destroyOnClose={true}
      className={styles.approve__popup}
      visible={isOpen}
      children={content}
      title={`You confirm to approve ${
        customerName &&
        !actionReplaceDevice &&
        !actionUnlockUser &&
        !actionEditUser &&
        !actionAddNewSubscription
          ? `${customerName} in Rakkar`
          : 'the request'
      }?`}
      centered={true}
      onCancel={() => setIsOpen(false)}
      maskClosable={true}
      width={404}
      footer={[
        <div className={styles.button}>
          <BasicButton
            title="Back"
            type="outline"
            onClick={() => setIsOpen(false)}
          />
          <BasicButton
            title="Confirm"
            type="filled"
            className={styles.confirm}
            onClick={handleApprove}
            loading={loading}
          />
        </div>,
      ]}
    />
  );
};

export default ApproveRequest;
