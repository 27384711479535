import { UserApproval, UserApprovalLog } from 'interfaces/transactions';
import ApproverDetail from '../ApproverDetail';

import styles from './index.module.scss';
import { orderBy } from 'lodash';
import { Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import { FORMAT_DATE } from 'constants/index';
import moment from 'moment';
import clsx from 'clsx';
import { StatusUserApprovalLogs } from 'enum/transaction';
import { capitalizeFirstLetter } from 'utils/helpers';
import { dots } from 'app/components/common/TextInfor';

type Props = {
  user: UserApproval;
  approvers?: UserApprovalLog[];
  isBlur: boolean;
};

const InitiatorAndApprovers = (props: Props) => {
  const userArranged = orderBy(props.approvers, ['createdAt'], ['asc']);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Row
            align="middle"
            justify="space-between"
            className={clsx(styles.content, {
              [styles.contentWithLine]: userArranged.length,
            })}
          >
            <Row align="middle">
              <div className={styles.stepIcon}>
                <SvgIcon name="grey-plus-icon" width={12} height={12} />
              </div>
              <span className={styles.stepTitle}>
                {capitalizeFirstLetter(props.user?.status ?? '')}
              </span>
              <div className={styles.greyDot} />
              <span className={styles.initiatedDate}>
                {props.user?.createdAt
                  ? moment(props.user?.createdAt).format(FORMAT_DATE.DATE_DMY_hM)
                  : '-'}
              </span>
            </Row>
            <span className={styles.approverName}>
              {props.isBlur ? (
                <div>{dots(8)}</div>
              ) : (
                props.user?.approverName ?? '-'
              )}
            </span>
          </Row>
          <div className={styles.wrapInitiated}>
            <ApproverDetail
              user={props.user}
              isBlur={props.isBlur}
              isShowInform={false}
            />
          </div>
        </div>
        {!userArranged.length ? (
          <></>
        ) : (
          <>
            {userArranged.map((approvedUser, idx) => {
              console.debug(`💀 ${(new Date()).toISOString()} ~ file: index.tsx:70 ~ {userArranged.map ~ userArranged:`, userArranged)
              return (
                <div key={`member-${idx}`} className={styles.wrapper}>
                  <Row
                    align="middle"
                    justify="space-between"
                    className={clsx(styles.content, {
                      [styles.contentWithLine]: idx < userArranged.length - 1,
                    })}
                  >
                    <Row align="middle">
                      <div
                        className={clsx(styles.stepIcon, {
                          [styles.approvedIcon]: [
                            StatusUserApprovalLogs.APPROVED.toString(),
                            StatusUserApprovalLogs.COMPLETED.toString(),
                            StatusUserApprovalLogs.APPROVED_AND_SIGNED.toString(),
                          ].includes(approvedUser.status),
                          [styles.rejectedIcon]:
                            approvedUser.status ===
                            StatusUserApprovalLogs.REJECTED,
                        })}
                      >
                        <SvgIcon
                          name={
                            approvedUser.status ===
                            StatusUserApprovalLogs.INITIATED
                              ? 'plus-icon'
                              : [
                                  StatusUserApprovalLogs.APPROVED.toString(),
                                  StatusUserApprovalLogs.COMPLETED.toString(),
                                  StatusUserApprovalLogs.APPROVED_AND_SIGNED.toString(),
                                ].includes(approvedUser.status)
                              ? 'approve-icon'
                              : 'white-reject'
                          }
                          width={9}
                          height={9}
                        />
                      </div>
                      <span className={styles.stepTitle}>
                        {capitalizeFirstLetter(approvedUser.status)}
                      </span>
                      {approvedUser.createdAt && (
                        <>
                          <div className={styles.greyDot} />
                          <span className={styles.initiatedDate}>
                            {approvedUser.createdAt
                              ? moment(approvedUser.createdAt).format(
                                  FORMAT_DATE.DATE_DMY_hM,
                                )
                              : ''}
                          </span>
                        </>
                      )}
                    </Row>
                    <span className={styles.approverName}>
                      {props.isBlur ? (
                        <div>{dots(8)}</div>
                      ) : (
                        approvedUser.approverName || '-'
                      )}
                    </span>
                  </Row>
                  <div className={styles.wrapInitiated}>
                    <ApproverDetail
                      user={approvedUser}
                      idx={idx}
                      isBlur={props.isBlur}
                      isShowInform={false}
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default InitiatorAndApprovers;
