import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetVaultWcRequestParamDTO,
  GetVaultWcResponseDTO,
  ICreateVaultWc,
  IVaultWcDetail,
} from 'interfaces/vaultWc';
export function getVaultWcRequest(
  params?: GetVaultWcRequestParamDTO,
): Promise<GetVaultWcResponseDTO> {
  return Instance.get(`/${PREFIX_API.VAULTWC}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailVaultWc(vaultWcId: string): Promise<IVaultWcDetail> {
  return Instance.get(`/${PREFIX_API.VAULTWC}/${vaultWcId}`);
}
export function createVaultWcRequest(
  vaultWc: ICreateVaultWc,
): Promise<GetVaultWcResponseDTO> {
  return Instance.post(`/${PREFIX_API.VAULTWC}`, vaultWc);
}
export function updateVaultWcRequest(
  vaultWc: ICreateVaultWc,
): Promise<GetVaultWcResponseDTO> {
  const payload: any = { ...vaultWc };
  const id = vaultWc.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.VAULTWC}/${id}`, payload);
}
export function getVaultWcRequestPaginationConfig(): Promise<GetVaultWcResponseDTO> {
  return Instance.get(`/${PREFIX_API.VAULTWC}/pagination-config`);
}
