export const getWorkspaceTypeName = (value: string) => {
  switch (value.toLocaleLowerCase()) {
    case 'hot':
    case 'hot wallet':
      return 'Rakkar Warm';

    case 'cold':
    case 'cold wallet':
      return 'Rakkar Cold';

    case 'staking':
      return 'Staking';
    default:
      return value;
  }
};

export const getShortWorkspaceTypeName = (value: string) => {
  switch (value.toLocaleLowerCase()) {
    case 'hot':
      return 'Warm';

    case 'cold':
      return 'Cold';

    default:
      return value;
  }
};
