import { Button, Col, Divider, Row, Table } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import { ItemAUC } from 'interfaces/customers';
import { formatThousandSeparator } from 'utils/common';
import { formatDateRange } from 'utils/date';
import { handleKeyDown } from 'utils/event.utils';
import { isNullOrUndefined } from 'utils/string';

import styles from '../index.module.scss';
import { CustomSpace } from './CustomSpace';
import { assetUnderCustodyColumns } from '../const';

type UpcomingFeeInformationType = {
  effectiveFrom?: string;
  effectiveTo?: string;
  initialSetupFee: number | string;
  minimumMonthlyFee: number | string;
  minimumAUCBalance: number | string;
  assetUnderCustody: ItemAUC[];
  disabled: boolean;
  onEdit: () => void;
  onDelete: () => void;
  allowEditInfo: boolean;
};

export const UpcomingFeeInformation = (props: UpcomingFeeInformationType) => {
  const {
    effectiveFrom,
    effectiveTo,
    initialSetupFee,
    minimumMonthlyFee,
    minimumAUCBalance,
    assetUnderCustody,
    disabled,
    onEdit,
    onDelete,
    allowEditInfo,
  } = props;

  return (
    <>
      <Divider orientationMargin="0" orientation="left">
        <span className={styles.info__title}>
          Upcoming fee information (
          {formatDateRange(
            effectiveFrom,
            effectiveTo,
            FORMAT_DATE.DATE_MMMMYYYY,
            true,
          )}
          )
        </span>
      </Divider>
      {allowEditInfo && (
        <div className={styles.buttonWrapper}>
          <div className={styles.innerButtonWrapper}>
            <Button
              className={clsx(
                styles.button,
                disabled ? styles.button__disabled : null,
              )}
              icon={
                <SvgIcon
                  style={{
                    marginRight: 10,
                    marginBottom: 2,
                  }}
                  width={15}
                  height={15}
                  name="custom-icon"
                  fill={disabled ? styles.button__disabled['color'] : '#25397C'}
                />
              }
              onClick={() => onEdit()}
              onKeyDown={handleKeyDown}
              htmlType="button"
              disabled={disabled}
            >
              Edit fee
            </Button>

            <Button
              className={clsx(
                styles.button,
                disabled ? styles.button__disabled : null,
              )}
              style={{ marginLeft: 8 }}
              shape="circle"
              icon={
                <SvgIcon
                  style={{
                    marginBottom: 2,
                  }}
                  width={15}
                  height={15}
                  name="close-no-color"
                  fill={disabled ? styles.button__disabled['color'] : '#F04438'}
                />
              }
              onClick={() => onDelete()}
              onKeyDown={handleKeyDown}
              htmlType="button"
              disabled={disabled}
            />
          </div>
        </div>
      )}
      <Row gutter={[0, 16]}>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Effective period</span>
            <span className={styles.text}>
              {formatDateRange(
                effectiveFrom,
                effectiveTo,
                FORMAT_DATE.DATE_MMMMYYYY,
                true,
              )}
            </span>
          </CustomSpace>
        </Col>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Initial setup fee (USD)</span>
            <span className={styles.text}>
              {!isNullOrUndefined(initialSetupFee)
                ? formatThousandSeparator(initialSetupFee)
                : 0}
            </span>
          </CustomSpace>
        </Col>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Minimum monthly fee (USD)</span>
            <span className={styles.text}>
              {!isNullOrUndefined(minimumMonthlyFee)
                ? formatThousandSeparator(minimumMonthlyFee)
                : 0}
            </span>
          </CustomSpace>
        </Col>
        <Col span={12}>
          <CustomSpace>
            <span className={styles.label}>Minimum AUC balance (USD)</span>
            <span className={styles.text}>
              {!isNullOrUndefined(minimumAUCBalance)
                ? formatThousandSeparator(minimumAUCBalance)
                : 0}
            </span>
          </CustomSpace>
        </Col>
      </Row>

      {assetUnderCustody?.length > 0 && (
        <>
          <Divider orientationMargin="0" orientation="left">
            <span className={styles.info__title}>Asset under custody</span>
          </Divider>
          <Table
            className={styles.tableAUC}
            columns={assetUnderCustodyColumns}
            bordered
            dataSource={[...assetUnderCustody]
              .sort((a, b) => +(a.id || 1) - +(b.id || 1))
              .map(({ from, to, point }) => ({
                from: formatThousandSeparator(from),
                to: formatThousandSeparator(to),
                point: formatThousandSeparator(point),
              }))}
            pagination={false}
          />
        </>
      )}
    </>
  );
};
