import { RequestStatus } from 'interfaces/request';
import { defaultAUCs } from 'utils/assets';

export const getDefaultBusinessInformations = () => {
  return {
    businessRegistrationId: '',
    businessTypeId: '',
    companyType: '',
    customerName: '',
    customerShortName: '',
    dateOfLastestRiskLevel: '',
    email: '',
    firstName: '',
    lastName: '',
    lastestRiskLevel: '',
    originOfFunds: 'Singapore',
    phoneNumber: '',
    purposeOfRelationship: '',
    registrationCountry: 'Singapore',
    registrationDate: '',
    countryCode: '',
    sourceOfFunds: '',
    sourceOfWealth: '',
    registrationAddress: {
      country: 'Singapore',
      city: '',
      address: '',
    },
    currentAddress: {
      country: 'Singapore',
      city: '',
      address: '',
    },
    billingAddress: {
      country: 'Singapore',
      city: '',
      address: '',
    },
    position: '',
  };
};

export const getDefaultFeeInfomation = () => {
  return {
    hotWallet: {
      initialSetupFee: 0,
      minimumMonthlyFee: 0,
      minimumAUCBalance: 0,
      assetUnderCustody: defaultAUCs,
    },
    coldWallet: {
      initialSetupFee: 0,
      minimumMonthlyFee: 0,
      minimumAUCBalance: 0,
      assetUnderCustody: defaultAUCs,
    },
  };
};

export const mockStoreNewCustomerInfo = {
  newCustomerInfo: {
    businessTypes: {
      status: RequestStatus.Idle,
    },
    workspaces: {
      status: RequestStatus.Idle,
    },
    bussinessInformation: getDefaultBusinessInformations(),
    feeInformation: {
      initialSetupFee: 0,
      minimumMonthlyFee: 0,
    },

    step2: {
      hotWalletId: '',
      coldWalletId: '',
    },

    customer: {
      status: RequestStatus.Idle,
    },
    adminQuorumData: {
      adminQuorum: [],
      quorumSize: 2,
    },
  },
};
