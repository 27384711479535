import clsx from 'clsx';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import {
  commonAttributesColumnTable,
  FORMAT_DATE,
  PER_PAGE,
  timeZone,
} from 'constants/index';
import { PAGE } from 'enum/common';
import { Params, RequestStatus } from 'interfaces/request';

import { GetAuditLogListRequestParamDTO } from 'interfaces/auditLogList.interface';

import { useAppSelector } from 'store/hooks';
import {
  auditLogListActions,
  selectAuditLogList,
  selectCanLoadMoreAuditLogList,
  selectParamAuditLogList,
} from 'store/slices/auditLogList/auditLogList.slice';

import BasicIconSort from 'app/components/common/BasicIconSort';
import RakkarStatusButton from 'app/components/common/RakkarStatusButton';
import { SvgIcon } from 'app/components/common/SvgIcon';
import RTable from 'app/components/Table';

import AuditLogDetail from './AuditLogDetail';

import { Tooltip } from 'antd';
import { SortAuditLogList } from 'enum/auditLogList.enum';
import { dots } from '../common/TextInfor';
import styles from './AuditLogList.module.scss';

const StyledNoSearchResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;

  .Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #959aaa;

    margin: 24px 0 8px;
  }

  .SubTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #959aaa;
  }
`;

const NoSearchResults = () => (
  <StyledNoSearchResults>
    <div>
      <SvgIcon name={'search-empty'} width={80} height={80} fill="#25397C" />
    </div>
    <div className="Title">No search results</div>
    <div className="SubTitle">
      <div>Your search returns no matches,</div>
      <div>please check and try again.</div>
    </div>
  </StyledNoSearchResults>
);

const renderIcon = (
  sortOrder: string,
  sortColumn: any,
  title: string,
  dataIndex: string,
  align = 'left' as string,
) => {
  return (
    <>
      <div
        className={clsx(styles.title, {
          [styles.titleHeaderRight]: align === 'right',
        })}
        data-testid={`column-${dataIndex}`}
        id={dataIndex}
      >
        <span>{title}</span>
        {sortOrder && sortColumn?.dataIndex === dataIndex && (
          <BasicIconSort sortOrder={sortOrder} />
        )}
      </div>
    </>
  );
};

interface Props {
  isFilterShown: boolean;
  isMaskAuditLog: boolean;
}

const AuditLogList: React.FunctionComponent<Props> = ({
  isFilterShown,
  isMaskAuditLog,
}) => {
  const [selectedRowId, setSelectedRowId] = useState('');
  const [open, setOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [auditLogs, setAuditLogs] = useState<any[]>([]);
  const [firstRender, setFirstRender] = useState(true);

  const dispatch = useDispatch();

  const auditLogList = useAppSelector(selectAuditLogList);

  const params = useAppSelector(selectParamAuditLogList);
  const canLoadMore = useAppSelector(selectCanLoadMoreAuditLogList);

  const refCanLoadMoreStore = useRef(canLoadMore);

  const loading = auditLogList?.status === RequestStatus.Loading;

  const height = useMemo(() => {
    const heightFilter = isFilterShown ? 242 : 36;
    const heightPadding = 120; // both top bottom
    return window.innerHeight - (32 * 2 + 24) - heightFilter - heightPadding; //count table height
  }, [isFilterShown]);

  const setValue = (key: keyof GetAuditLogListRequestParamDTO) => {
    return (
      value: GetAuditLogListRequestParamDTO[keyof GetAuditLogListRequestParamDTO],
    ) => {
      dispatch(
        auditLogListActions.setAuditLogListParams({
          key,
          value,
        }),
      );
    };
  };

  const setSortStore = setValue(Params.Sort);
  const setSortByStore = setValue(Params.SortBy);
  const setOffset = setValue(Params.Offset);
  const setTimeZone = setValue(Params.TimeZone);

  if (-12 <= timeZone && timeZone <= 14) {
    setTimeZone(timeZone);
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    const sort =
      sorter.order === 'descend'
        ? 'DESC'
        : sorter.order === 'ascend'
        ? 'ASC'
        : undefined;
    const sortBy = SortAuditLogList[sorter.field];

    setOffset(0);
    setSortStore(sort);
    setSortByStore(sortBy);
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    return () => {
      if (!firstRender) {
        dispatch(auditLogListActions.resetAuditLogListParams());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  useEffect(() => {
    refCanLoadMoreStore.current = canLoadMore;
  }, [canLoadMore]);

  const loadMore = useCallback(
    event => {
      const maxScroll = event.target.scrollHeight - event.target.clientHeight;
      const currentScroll = (event.target.scrollTop + 2).toFixed();
      let offset = params?.offset || 0;
      if (maxScroll === 0 && offset > 0) {
        offset = 0;
      }
      if (
        refCanLoadMoreStore.current &&
        Number(currentScroll) > maxScroll &&
        auditLogs.length === offset + PER_PAGE
      ) {
        setOffset(offset + PER_PAGE);
      }
    },
    [auditLogs.length, params?.offset, setOffset],
  );

  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (!tableContent) return;

    tableContent.addEventListener('scroll', loadMore);
    return () => tableContent.removeEventListener('scroll', loadMore);
  }, [loadMore]);

  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (tableContent && params.offset === 0) {
      tableContent.scrollTop = 0;
    }
  }, [params.offset]);

  useEffect(() => {
    if (auditLogList?.data?.listAuditLog) {
      setAuditLogs(auditLogList.data.listAuditLog);
    }
  }, [auditLogList]);

  useEffect(() => {
    const length = auditLogs.length || 0;
    const count = height / 60; //60 height 1 record
    let offset = Number(params?.offset) + PER_PAGE || PER_PAGE;
    const total = auditLogList?.data?.total ?? 0;

    if (offset < count && offset < total && length === offset) {
      setOffset(offset);
    }
  }, [auditLogList?.data?.total, auditLogs, height, params?.offset, setOffset]);

  const handleClickOnRow = (value: any) => {
    if (value) {
      setOpen(true);
      setItemSelected(value);
      setSelectedRowId(value.id);
    }
  };
  const NoResultComponent = useCallback(() => <NoSearchResults />, []);

  const columns: any = [
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Activity date', 'createdAt');
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'desend',
      render: (createdAt: string) => {
        return (
          <div className={styles.date}>
            {moment(createdAt).format(FORMAT_DATE.DATE_DMY_hM)}
          </div>
        );
      },
      width: '188px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Company', 'company');
      },
      dataIndex: 'company',
      key: 'company',
      render: (_, item) => {
        return (
          <Tooltip title={item?.customer?.customerName} placement="bottomLeft">
            <div className={styles.name}>
              <span>{item?.customer?.customerName}</span>
            </div>
          </Tooltip>
        );
      },
      width: '172px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Username', 'username');
      },
      dataIndex: 'username',
      key: 'username',
      render: (_, item) => {
        return (
          <Tooltip
            title={isMaskAuditLog ? dots() : item?.actor.name}
            placement="bottomLeft"
          >
            <div className={styles.NormalData}>
              <span>{isMaskAuditLog ? dots() : item?.actor.name}</span>
            </div>
          </Tooltip>
        );
      },
      width: '148px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Category', 'requestCategory');
      },
      dataIndex: 'requestCategory',
      key: 'requestCategory',
      render: (requestCategory: string) => {
        return (
          <div className={styles.NormalData}>
            <span>{requestCategory}</span>
          </div>
        );
      },
      width: '152px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Type', 'requestType');
      },
      dataIndex: 'requestType',
      key: 'requestType',
      render: (requestType: string) => {
        return (
          <div className={styles.NormalData}>
            <span>{requestType}</span>
          </div>
        );
      },
      width: '160px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Action', 'requestAction');
      },
      dataIndex: 'requestAction',
      key: 'requestAction',
      render: (requestAction: string) => {
        return (
          <div className={styles.NormalData}>
            <span>{requestAction}</span>
          </div>
        );
      },
      width: '160px',
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Status', 'status');
      },
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return <RakkarStatusButton status={status} />;
      },
      width: '148px',
    },
  ];

  return (
    <div className={styles.tableAudit} data-testId="table">
      <RTable
        currentTableData={auditLogs}
        columns={columns}
        loading={loading}
        height={height}
        selectedRowId={selectedRowId}
        clickOnRow={handleClickOnRow}
        page={PAGE.AUDITLOGLIST}
        onChange={onTableChange}
        NoSearchResults={NoResultComponent}
      />

      {!!itemSelected && (
        <AuditLogDetail
          open={open}
          onClose={() => {
            setOpen(false);
            setSelectedRowId('');
          }}
          itemSelected={itemSelected}
        />
      )}
    </div>
  );
};

export default AuditLogList;
