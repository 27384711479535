import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getSnapshotTokenPriceRequest,
  getDetailSnapshotTokenPrice,
  createSnapshotTokenPriceRequest,
  updateSnapshotTokenPriceRequest,
} from 'services/snapshotTokenPrice.service';
import { snapshotTokenPriceActions } from 'store/slices/snapshotTokenPrice/snapshotTokenPrice.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetSnapshotTokenPriceRequestParamDTO,
  ICreateSnapshotTokenPrice,
} from 'interfaces/snapshotTokenPrice';
const handleGetSnapshotTokenPriceRequest = function* ({
  payload,
}: PayloadAction<GetSnapshotTokenPriceRequestParamDTO>) {
  try {
    const res = yield call(getSnapshotTokenPriceRequest, payload);
    const params = yield select(state => state.snapshotTokenPrice.params) as any;
    const listSnapshotTokenPrice = yield select(
      state => state.snapshotTokenPrice?.snapshotTokenPrice?.data?.listSnapshotTokenPrice,
    ) as any;
    if (res) {
      yield put(
        snapshotTokenPriceActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listSnapshotTokenPrice || []), ...res.list];
        yield put(snapshotTokenPriceActions.appendSnapshotTokenPrice(newList));
      } else {
        yield put(
          snapshotTokenPriceActions.getSnapshotTokenPriceSuccess({
            listSnapshotTokenPrice: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(snapshotTokenPriceActions.getSnapshotTokenPriceFailed());
  }
};
const handleGetDetailSnapshotTokenPriceRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailSnapshotTokenPrice, payload);
    if (res) {
      yield put(snapshotTokenPriceActions.getDetailSnapshotTokenPriceSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(snapshotTokenPriceActions.getDetailSnapshotTokenPriceFailed());
  }
};
const handleCreateSnapshotTokenPriceRequest = function* ({
  payload,
}: PayloadAction<ICreateSnapshotTokenPrice>) {
  try {
    const res = yield call(createSnapshotTokenPriceRequest, payload);
    if (res) {
      yield put(snapshotTokenPriceActions.createSnapshotTokenPriceSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(snapshotTokenPriceActions.createSnapshotTokenPriceFailed());
  }
};
const handleUpdateSnapshotTokenPriceRequest = function* ({
  payload,
}: PayloadAction<ICreateSnapshotTokenPrice>) {
  try {
    const res = yield call(updateSnapshotTokenPriceRequest, payload);
    if (res) {
      yield put(snapshotTokenPriceActions.updateSnapshotTokenPriceSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(snapshotTokenPriceActions.updateSnapshotTokenPriceFailed());
  }
};
const snapshotTokenPriceSaga = function* () {
  yield takeLatest(
    snapshotTokenPriceActions.getSnapshotTokenPriceRequest.type,
    handleGetSnapshotTokenPriceRequest,
  );
  yield takeLatest(
    snapshotTokenPriceActions.getDetailSnapshotTokenPriceRequest.type,
    handleGetDetailSnapshotTokenPriceRequest,
  );
  yield takeLatest(
    snapshotTokenPriceActions.createSnapshotTokenPriceRequest.type,
    handleCreateSnapshotTokenPriceRequest,
  );
  yield takeLatest(
    snapshotTokenPriceActions.updateSnapshotTokenPriceRequest.type,
    handleUpdateSnapshotTokenPriceRequest,
  );
};
export default snapshotTokenPriceSaga;
