import Instance from 'app.v2/config/instance';
import { PREFIX_API } from 'constants/index';

import {
  GetCfg_sourceOfFundRequestParamDTO,
  GetCfg_sourceOfFundResponseDTO,
  ICreateCfg_sourceOfFund,
  ICfg_sourceOfFundDetail,
} from 'interfaces/cfg_sourceOfFund';
export function getCfg_sourceOfFundRequest(
  params?: GetCfg_sourceOfFundRequestParamDTO,
): Promise<GetCfg_sourceOfFundResponseDTO> {
  return Instance.get(`/${PREFIX_API.CFG_SOURCEOFFUND}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailCfg_sourceOfFund(
  cfg_sourceOfFundId: string,
): Promise<ICfg_sourceOfFundDetail> {
  return Instance.get(`/${PREFIX_API.CFG_SOURCEOFFUND}/${cfg_sourceOfFundId}`);
}
export function createCfg_sourceOfFundRequest(
  cfg_sourceOfFund: ICreateCfg_sourceOfFund,
): Promise<GetCfg_sourceOfFundResponseDTO> {
  return Instance.post(`/${PREFIX_API.CFG_SOURCEOFFUND}`, cfg_sourceOfFund);
}
export function updateCfg_sourceOfFundRequest(
  cfg_sourceOfFund: ICreateCfg_sourceOfFund,
): Promise<GetCfg_sourceOfFundResponseDTO> {
  const payload: any = { ...cfg_sourceOfFund };
  const id = cfg_sourceOfFund.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.CFG_SOURCEOFFUND}/${id}`, payload);
}
export function getCfg_sourceOfFundRequestPaginationConfig(): Promise<GetCfg_sourceOfFundResponseDTO> {
  return Instance.get(`/${PREFIX_API.CFG_SOURCEOFFUND}/pagination-config`);
}
