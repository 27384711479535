import { PREFIX_API } from 'constants/index';
import { CommentsResponse } from 'interfaces/comment';
import Instance from '../app.v2/config/instance';

const entityType = 'transactions';

export function getCommentsByTxnId(txnId: string): Promise<CommentsResponse> {
  return Instance.get(`/${PREFIX_API.CORE}/${entityType}/${txnId}/comments`);
}

export function addComment(txnId: string, params: any): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.CORE}/${entityType}/${txnId}/comments`,
    params,
  );
}

export function updateComment(
  txnId: string,
  commentId: string,
  params: any,
): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.CORE}/${entityType}/${txnId}/comments/${commentId}`,
    params,
  );
}

export function deleteComment(txnId: string, commentId: string): Promise<any> {
  return Instance.delete(
    `/${PREFIX_API.CORE}/${entityType}/${txnId}/comments/${commentId}`,
  );
}
