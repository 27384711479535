import { LOCKED_BY } from 'constants/customer';
import { DOCUMENT_TYPE, SortType } from 'enum/common';
import { RequestType } from 'enum/pendingRequest';

export function getOptionsSort() {
  return [
    { label: 'A to Z', icon: 'AZ-name', value: SortType.AZ_NAME },
    { label: 'Z to A', icon: 'ZA-name', value: SortType.ZA_NAME },
    { label: 'Highest value', icon: 'AZ-amount', value: SortType.AZ_AMOUNT },
    { label: 'Lowest value', icon: 'ZA-amount', value: SortType.ZA_AMOUNT },
  ];
}
export const countryData = [
  {
    id: 98,
    name: 'Hong Kong S.A.R.',
    code: 'HK',
  },
  {
    id: 219,
    name: 'Thailand',
    code: 'TH',
  },
  {
    id: 199,
    name: 'Singapore',
    code: 'SG',
  },
  {
    id: 1,
    name: 'Afghanistan',
    code: 'AF',
  },
  {
    id: 2,
    name: 'Aland Islands',
    code: 'AX',
  },
  {
    id: 3,
    name: 'Albania',
    code: 'AL',
  },
  {
    id: 4,
    name: 'Algeria',
    code: 'DZ',
  },
  {
    id: 5,
    name: 'American Samoa',
    code: 'AS',
  },
  {
    id: 6,
    name: 'Andorra',
    code: 'AD',
  },
  {
    id: 7,
    name: 'Angola',
    code: 'AO',
  },
  {
    id: 8,
    name: 'Anguilla',
    code: 'AI',
  },
  {
    id: 9,
    name: 'Antarctica',
    code: 'AQ',
  },
  {
    id: 10,
    name: 'Antigua And Barbuda',
    code: 'AG',
  },
  {
    id: 11,
    name: 'Argentina',
    code: 'AR',
  },
  {
    id: 12,
    name: 'Armenia',
    code: 'AM',
  },
  {
    id: 13,
    name: 'Aruba',
    code: 'AW',
  },
  {
    id: 14,
    name: 'Australia',
    code: 'AU',
  },
  {
    id: 15,
    name: 'Austria',
    code: 'AT',
  },
  {
    id: 16,
    name: 'Azerbaijan',
    code: 'AZ',
  },
  {
    id: 18,
    name: 'Bahrain',
    code: 'BH',
  },
  {
    id: 19,
    name: 'Bangladesh',
    code: 'BD',
  },
  {
    id: 20,
    name: 'Barbados',
    code: 'BB',
  },
  {
    id: 21,
    name: 'Belarus',
    code: 'BY',
  },
  {
    id: 22,
    name: 'Belgium',
    code: 'BE',
  },
  {
    id: 23,
    name: 'Belize',
    code: 'BZ',
  },
  {
    id: 24,
    name: 'Benin',
    code: 'BJ',
  },
  {
    id: 25,
    name: 'Bermuda',
    code: 'BM',
  },
  {
    id: 26,
    name: 'Bhutan',
    code: 'BT',
  },
  {
    id: 27,
    name: 'Bolivia',
    code: 'BO',
  },
  {
    id: 155,
    name: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
  },
  {
    id: 28,
    name: 'Bosnia and Herzegovina',
    code: 'BA',
  },
  {
    id: 29,
    name: 'Botswana',
    code: 'BW',
  },
  {
    id: 30,
    name: 'Bouvet Island',
    code: 'BV',
  },
  {
    id: 31,
    name: 'Brazil',
    code: 'BR',
  },
  {
    id: 32,
    name: 'British Indian Ocean Territory',
    code: 'IO',
  },
  {
    id: 33,
    name: 'Brunei',
    code: 'BN',
  },
  {
    id: 34,
    name: 'Bulgaria',
    code: 'BG',
  },
  {
    id: 35,
    name: 'Burkina Faso',
    code: 'BF',
  },
  {
    id: 36,
    name: 'Burundi',
    code: 'BI',
  },
  {
    id: 37,
    name: 'Cambodia',
    code: 'KH',
  },
  {
    id: 38,
    name: 'Cameroon',
    code: 'CM',
  },
  {
    id: 39,
    name: 'Canada',
    code: 'CA',
  },
  {
    id: 40,
    name: 'Cape Verde',
    code: 'CV',
  },
  {
    id: 41,
    name: 'Cayman Islands',
    code: 'KY',
  },
  {
    id: 42,
    name: 'Central African Republic',
    code: 'CF',
  },
  {
    id: 43,
    name: 'Chad',
    code: 'TD',
  },
  {
    id: 44,
    name: 'Chile',
    code: 'CL',
  },
  {
    id: 45,
    name: 'China',
    code: 'CN',
  },
  {
    id: 46,
    name: 'Christmas Island',
    code: 'CX',
  },
  {
    id: 47,
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
  },
  {
    id: 48,
    name: 'Colombia',
    code: 'CO',
  },
  {
    id: 49,
    name: 'Comoros',
    code: 'KM',
  },
  {
    id: 50,
    name: 'Congo',
    code: 'CG',
  },
  {
    id: 52,
    name: 'Cook Islands',
    code: 'CK',
  },
  {
    id: 53,
    name: 'Costa Rica',
    code: 'CR',
  },
  {
    id: 54,
    name: 'Cote D`Ivoire (Ivory Coast)',
    code: 'CI',
  },
  {
    id: 55,
    name: 'Croatia',
    code: 'HR',
  },
  {
    id: 56,
    name: 'Cuba',
    code: 'CU',
  },
  {
    id: 249,
    name: 'Curaçao',
    code: 'CW',
  },
  {
    id: 57,
    name: 'Cyprus',
    code: 'CY',
  },
  {
    id: 58,
    name: 'Czech Republic',
    code: 'CZ',
  },
  {
    id: 51,
    name: 'Democratic Republic of the Congo',
    code: 'CD',
  },
  {
    id: 59,
    name: 'Denmark',
    code: 'DK',
  },
  {
    id: 60,
    name: 'Djibouti',
    code: 'DJ',
  },
  {
    id: 61,
    name: 'Dominica',
    code: 'DM',
  },
  {
    id: 62,
    name: 'Dominican Republic',
    code: 'DO',
  },
  {
    id: 63,
    name: 'East Timor',
    code: 'TL',
  },
  {
    id: 64,
    name: 'Ecuador',
    code: 'EC',
  },
  {
    id: 65,
    name: 'Egypt',
    code: 'EG',
  },
  {
    id: 66,
    name: 'El Salvador',
    code: 'SV',
  },
  {
    id: 67,
    name: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    id: 68,
    name: 'Eritrea',
    code: 'ER',
  },
  {
    id: 69,
    name: 'Estonia',
    code: 'EE',
  },
  {
    id: 70,
    name: 'Ethiopia',
    code: 'ET',
  },
  {
    id: 71,
    name: 'Falkland Islands',
    code: 'FK',
  },
  {
    id: 72,
    name: 'Faroe Islands',
    code: 'FO',
  },
  {
    id: 73,
    name: 'Fiji Islands',
    code: 'FJ',
  },
  {
    id: 74,
    name: 'Finland',
    code: 'FI',
  },
  {
    id: 75,
    name: 'France',
    code: 'FR',
  },
  {
    id: 76,
    name: 'French Guiana',
    code: 'GF',
  },
  {
    id: 77,
    name: 'French Polynesia',
    code: 'PF',
  },
  {
    id: 78,
    name: 'French Southern Territories',
    code: 'TF',
  },
  {
    id: 79,
    name: 'Gabon',
    code: 'GA',
  },
  {
    id: 80,
    name: 'Gambia The',
    code: 'GM',
  },
  {
    id: 81,
    name: 'Georgia',
    code: 'GE',
  },
  {
    id: 82,
    name: 'Germany',
    code: 'DE',
  },
  {
    id: 83,
    name: 'Ghana',
    code: 'GH',
  },
  {
    id: 84,
    name: 'Gibraltar',
    code: 'GI',
  },
  {
    id: 85,
    name: 'Greece',
    code: 'GR',
  },
  {
    id: 86,
    name: 'Greenland',
    code: 'GL',
  },
  {
    id: 87,
    name: 'Grenada',
    code: 'GD',
  },
  {
    id: 88,
    name: 'Guadeloupe',
    code: 'GP',
  },
  {
    id: 89,
    name: 'Guam',
    code: 'GU',
  },
  {
    id: 90,
    name: 'Guatemala',
    code: 'GT',
  },
  {
    id: 91,
    name: 'Guernsey and Alderney',
    code: 'GG',
  },
  {
    id: 92,
    name: 'Guinea',
    code: 'GN',
  },
  {
    id: 93,
    name: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    id: 94,
    name: 'Guyana',
    code: 'GY',
  },
  {
    id: 95,
    name: 'Haiti',
    code: 'HT',
  },
  {
    id: 96,
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
  },
  {
    id: 97,
    name: 'Honduras',
    code: 'HN',
  },
  {
    id: 99,
    name: 'Hungary',
    code: 'HU',
  },
  {
    id: 100,
    name: 'Iceland',
    code: 'IS',
  },
  {
    id: 101,
    name: 'India',
    code: 'IN',
  },
  {
    id: 102,
    name: 'Indonesia',
    code: 'ID',
  },
  {
    id: 103,
    name: 'Iran',
    code: 'IR',
  },
  {
    id: 104,
    name: 'Iraq',
    code: 'IQ',
  },
  {
    id: 105,
    name: 'Ireland',
    code: 'IE',
  },
  {
    id: 106,
    name: 'Israel',
    code: 'IL',
  },
  {
    id: 107,
    name: 'Italy',
    code: 'IT',
  },
  {
    id: 108,
    name: 'Jamaica',
    code: 'JM',
  },
  {
    id: 109,
    name: 'Japan',
    code: 'JP',
  },
  {
    id: 110,
    name: 'Jersey',
    code: 'JE',
  },
  {
    id: 111,
    name: 'Jordan',
    code: 'JO',
  },
  {
    id: 112,
    name: 'Kazakhstan',
    code: 'KZ',
  },
  {
    id: 113,
    name: 'Kenya',
    code: 'KE',
  },
  {
    id: 114,
    name: 'Kiribati',
    code: 'KI',
  },
  {
    id: 248,
    name: 'Kosovo',
    code: 'XK',
  },
  {
    id: 117,
    name: 'Kuwait',
    code: 'KW',
  },
  {
    id: 118,
    name: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    id: 119,
    name: 'Laos',
    code: 'LA',
  },
  {
    id: 120,
    name: 'Latvia',
    code: 'LV',
  },
  {
    id: 121,
    name: 'Lebanon',
    code: 'LB',
  },
  {
    id: 122,
    name: 'Lesotho',
    code: 'LS',
  },
  {
    id: 123,
    name: 'Liberia',
    code: 'LR',
  },
  {
    id: 124,
    name: 'Libya',
    code: 'LY',
  },
  {
    id: 125,
    name: 'Liechtenstein',
    code: 'LI',
  },
  {
    id: 126,
    name: 'Lithuania',
    code: 'LT',
  },
  {
    id: 127,
    name: 'Luxembourg',
    code: 'LU',
  },
  {
    id: 128,
    name: 'Macau S.A.R.',
    code: 'MO',
  },
  {
    id: 129,
    name: 'Macedonia',
    code: 'MK',
  },
  {
    id: 130,
    name: 'Madagascar',
    code: 'MG',
  },
  {
    id: 131,
    name: 'Malawi',
    code: 'MW',
  },
  {
    id: 132,
    name: 'Malaysia',
    code: 'MY',
  },
  {
    id: 133,
    name: 'Maldives',
    code: 'MV',
  },
  {
    id: 134,
    name: 'Mali',
    code: 'ML',
  },
  {
    id: 135,
    name: 'Malta',
    code: 'MT',
  },
  {
    id: 136,
    name: 'Man (Isle of)',
    code: 'IM',
  },
  {
    id: 137,
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    id: 138,
    name: 'Martinique',
    code: 'MQ',
  },
  {
    id: 139,
    name: 'Mauritania',
    code: 'MR',
  },
  {
    id: 140,
    name: 'Mauritius',
    code: 'MU',
  },
  {
    id: 141,
    name: 'Mayotte',
    code: 'YT',
  },
  {
    id: 142,
    name: 'Mexico',
    code: 'MX',
  },
  {
    id: 143,
    name: 'Micronesia',
    code: 'FM',
  },
  {
    id: 144,
    name: 'Moldova',
    code: 'MD',
  },
  {
    id: 145,
    name: 'Monaco',
    code: 'MC',
  },
  {
    id: 146,
    name: 'Mongolia',
    code: 'MN',
  },
  {
    id: 147,
    name: 'Montenegro',
    code: 'ME',
  },
  {
    id: 148,
    name: 'Montserrat',
    code: 'MS',
  },
  {
    id: 149,
    name: 'Morocco',
    code: 'MA',
  },
  {
    id: 150,
    name: 'Mozambique',
    code: 'MZ',
  },
  {
    id: 151,
    name: 'Myanmar',
    code: 'MM',
  },
  {
    id: 152,
    name: 'Namibia',
    code: 'NA',
  },
  {
    id: 153,
    name: 'Nauru',
    code: 'NR',
  },
  {
    id: 154,
    name: 'Nepal',
    code: 'NP',
  },
  {
    id: 156,
    name: 'Netherlands',
    code: 'NL',
  },
  {
    id: 157,
    name: 'New Caledonia',
    code: 'NC',
  },
  {
    id: 158,
    name: 'New Zealand',
    code: 'NZ',
  },
  {
    id: 159,
    name: 'Nicaragua',
    code: 'NI',
  },
  {
    id: 160,
    name: 'Niger',
    code: 'NE',
  },
  {
    id: 161,
    name: 'Nigeria',
    code: 'NG',
  },
  {
    id: 162,
    name: 'Niue',
    code: 'NU',
  },
  {
    id: 163,
    name: 'Norfolk Island',
    code: 'NF',
  },
  {
    id: 115,
    name: 'North Korea',
    code: 'KP',
  },
  {
    id: 164,
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    id: 165,
    name: 'Norway',
    code: 'NO',
  },
  {
    id: 166,
    name: 'Oman',
    code: 'OM',
  },
  {
    id: 167,
    name: 'Pakistan',
    code: 'PK',
  },
  {
    id: 168,
    name: 'Palau',
    code: 'PW',
  },
  {
    id: 169,
    name: 'Palestinian Territory Occupied',
    code: 'PS',
  },
  {
    id: 170,
    name: 'Panama',
    code: 'PA',
  },
  {
    id: 171,
    name: 'Papua new Guinea',
    code: 'PG',
  },
  {
    id: 172,
    name: 'Paraguay',
    code: 'PY',
  },
  {
    id: 173,
    name: 'Peru',
    code: 'PE',
  },
  {
    id: 174,
    name: 'Philippines',
    code: 'PH',
  },
  {
    id: 175,
    name: 'Pitcairn Island',
    code: 'PN',
  },
  {
    id: 176,
    name: 'Poland',
    code: 'PL',
  },
  {
    id: 177,
    name: 'Portugal',
    code: 'PT',
  },
  {
    id: 178,
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    id: 179,
    name: 'Qatar',
    code: 'QA',
  },
  {
    id: 180,
    name: 'Reunion',
    code: 'RE',
  },
  {
    id: 181,
    name: 'Romania',
    code: 'RO',
  },
  {
    id: 182,
    name: 'Russia',
    code: 'RU',
  },
  {
    id: 183,
    name: 'Rwanda',
    code: 'RW',
  },
  {
    id: 184,
    name: 'Saint Helena',
    code: 'SH',
  },
  {
    id: 185,
    name: 'Saint Kitts And Nevis',
    code: 'KN',
  },
  {
    id: 186,
    name: 'Saint Lucia',
    code: 'LC',
  },
  {
    id: 187,
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    id: 188,
    name: 'Saint Vincent And The Grenadines',
    code: 'VC',
  },
  {
    id: 189,
    name: 'Saint-Barthelemy',
    code: 'BL',
  },
  {
    id: 190,
    name: 'Saint-Martin (French part)',
    code: 'MF',
  },
  {
    id: 191,
    name: 'Samoa',
    code: 'WS',
  },
  {
    id: 192,
    name: 'San Marino',
    code: 'SM',
  },
  {
    id: 193,
    name: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    id: 194,
    name: 'Saudi Arabia',
    code: 'SA',
  },
  {
    id: 195,
    name: 'Senegal',
    code: 'SN',
  },
  {
    id: 196,
    name: 'Serbia',
    code: 'RS',
  },
  {
    id: 197,
    name: 'Seychelles',
    code: 'SC',
  },
  {
    id: 198,
    name: 'Sierra Leone',
    code: 'SL',
  },
  {
    id: 250,
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
  },
  {
    id: 200,
    name: 'Slovakia',
    code: 'SK',
  },
  {
    id: 201,
    name: 'Slovenia',
    code: 'SI',
  },
  {
    id: 202,
    name: 'Solomon Islands',
    code: 'SB',
  },
  {
    id: 203,
    name: 'Somalia',
    code: 'SO',
  },
  {
    id: 204,
    name: 'South Africa',
    code: 'ZA',
  },
  {
    id: 205,
    name: 'South Georgia',
    code: 'GS',
  },
  {
    id: 116,
    name: 'South Korea',
    code: 'KR',
  },
  {
    id: 206,
    name: 'South Sudan',
    code: 'SS',
  },
  {
    id: 207,
    name: 'Spain',
    code: 'ES',
  },
  {
    id: 208,
    name: 'Sri Lanka',
    code: 'LK',
  },
  {
    id: 209,
    name: 'Sudan',
    code: 'SD',
  },
  {
    id: 210,
    name: 'Suriname',
    code: 'SR',
  },
  {
    id: 211,
    name: 'Svalbard And Jan Mayen Islands',
    code: 'SJ',
  },
  {
    id: 212,
    name: 'Swaziland',
    code: 'SZ',
  },
  {
    id: 213,
    name: 'Sweden',
    code: 'SE',
  },
  {
    id: 214,
    name: 'Switzerland',
    code: 'CH',
  },
  {
    id: 215,
    name: 'Syria',
    code: 'SY',
  },
  {
    id: 216,
    name: 'Taiwan',
    code: 'TW',
  },
  {
    id: 217,
    name: 'Tajikistan',
    code: 'TJ',
  },
  {
    id: 218,
    name: 'Tanzania',
    code: 'TZ',
  },
  {
    id: 17,
    name: 'The Bahamas',
    code: 'BS',
  },
  {
    id: 220,
    name: 'Togo',
    code: 'TG',
  },
  {
    id: 221,
    name: 'Tokelau',
    code: 'TK',
  },
  {
    id: 222,
    name: 'Tonga',
    code: 'TO',
  },
  {
    id: 223,
    name: 'Trinidad And Tobago',
    code: 'TT',
  },
  {
    id: 224,
    name: 'Tunisia',
    code: 'TN',
  },
  {
    id: 225,
    name: 'Turkey',
    code: 'TR',
  },
  {
    id: 226,
    name: 'Turkmenistan',
    code: 'TM',
  },
  {
    id: 227,
    name: 'Turks And Caicos Islands',
    code: 'TC',
  },
  {
    id: 228,
    name: 'Tuvalu',
    code: 'TV',
  },
  {
    id: 229,
    name: 'Uganda',
    code: 'UG',
  },
  {
    id: 230,
    name: 'Ukraine',
    code: 'UA',
  },
  {
    id: 231,
    name: 'United Arab Emirates',
    code: 'AE',
  },
  {
    id: 232,
    name: 'United Kingdom',
    code: 'GB',
  },
  {
    id: 233,
    name: 'United States',
    code: 'US',
  },
  {
    id: 234,
    name: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  {
    id: 235,
    name: 'Uruguay',
    code: 'UY',
  },
  {
    id: 236,
    name: 'Uzbekistan',
    code: 'UZ',
  },
  {
    id: 237,
    name: 'Vanuatu',
    code: 'VU',
  },
  {
    id: 238,
    name: 'Vatican City State (Holy See)',
    code: 'VA',
  },
  {
    id: 239,
    name: 'Venezuela',
    code: 'VE',
  },
  {
    id: 240,
    name: 'Vietnam',
    code: 'VN',
  },
  {
    id: 241,
    name: 'Virgin Islands (British)',
    code: 'VG',
  },
  {
    id: 242,
    name: 'Virgin Islands (US)',
    code: 'VI',
  },
  {
    id: 243,
    name: 'Wallis And Futuna Islands',
    code: 'WF',
  },
  {
    id: 244,
    name: 'Western Sahara',
    code: 'EH',
  },
  {
    id: 245,
    name: 'Yemen',
    code: 'YE',
  },
  {
    id: 246,
    name: 'Zambia',
    code: 'ZM',
  },
  {
    id: 247,
    name: 'Zimbabwe',
    code: 'ZW',
  },
];

export const defaultAUCs = [
  {
    key: '0',
    from: 0,
    to: 10000000,
    point: 30,
  },
  {
    key: '1',
    from: 10000000,
    to: 50000000,
    point: 25,
  },
  {
    key: '2',
    from: 50000000,
    to: 100000000,
    point: 20,
  },
  {
    key: '3',
    from: 100000000,
    to: null,
    point: 15,
  },
];

export const requestsType = [
  {
    value: 'CREATE_CUSTOMER',
    name: 'Customer creation',
  },
  {
    value: 'UNFREEZE_TRANSACTION',
    name: 'Unfreeze transaction',
  },
  {
    value: 'ADD_NEW_USER',
    name: 'User creation',
  },
  {
    value: 'REP_UPDATE_USER',
    name: 'Edit user profile',
  },
  {
    value: 'MARK_LOST_DEVICE',
    name: 'Mark device as lost',
  },
  {
    value: 'CREATE_FEE_INFORMATION',
    name: 'Create fee',
  },
  {
    value: 'UPDATE_FEE_INFORMATION',
    name: 'Update fee',
  },
  {
    value: 'REP_REPLACE_DEVICE',
    name: 'Replace device',
  },
  {
    value: 'RESEND_VERIFICATION_EMAIL',
    name: 'Resend verification email',
  },
  {
    value: 'RESEND_ONBOARDING_EMAIL',
    name: 'Resend onboarding email',
  },
  {
    value: 'UNLOCK_USER',
    name: 'Unlock User',
  },
  {
    value: RequestType.REP_REMOVE_USER,
    name: 'Remove User Access',
  },
  {
    value: RequestType.UPDATE_FEATURE_NETWORK_TRANSFER_ACCESS,
    name: 'Edit Network transfer access request',
  },
  {
    value: RequestType.EDIT_CUSTOMER_POLICY,
    name: 'Edit Customer',
  },
  {
    value: RequestType.CUSTOMER_SUBSCRIBE,
    name: 'Customer Subscribe',
  },
];

export const mockRequestedBy = [
  {
    id: 'd1e32330-52c0-47aa-afbe-ea1d72f7748a',
    name: 'Miss Lance Hilpert',
  },
  {
    id: 'b7e5b71b-9936-49ee-92ab-4bc4d9e68c33',
    name: 'Miss Ervin Lockman',
  },
  {
    id: '252a8b72-a59f-4aef-91e4-4d74a05f4ec2',
    name: 'Miss Daryl Cronin',
  },
];

export const documentTypes = [
  {
    label: 'Passport',
    value: DOCUMENT_TYPE.PASSPORT,
  },
  {
    label: 'ID card',
    value: DOCUMENT_TYPE.ID_CARD,
  },
];

export const lockByOpts = [
  {
    label: 'user',
    value: LOCKED_BY.USER,
  },
  {
    label: 'Rakkar',
    value: LOCKED_BY.RAKKAR,
  },
];
