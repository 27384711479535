import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NOT_LIMITED } from 'constants/index';
import {
  AccessControlDetail,
  AccessControlGroups,
  AccessControlRequest,
  GetListAccessControlRequestParams,
} from 'interfaces/accessControl';
import { Params, RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import { AppState } from 'store';

interface AccessControlListState {
  params: GetListAccessControlRequestParams;
  accessControlList: ResponseData<any>;
  accessControlDetail: ResponseData<AccessControlDetail>;
  canLoadMore: boolean;
  accessControlRequest: ResponseData<any>;
  initGroups: AccessControlGroups[];
  updateGroups: AccessControlGroups[];
}

export function getDefaultParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: NOT_LIMITED,
  };
}

const resetDefaultParams: CaseReducer<AccessControlListState> = (
  state: AccessControlListState,
) => {
  state.params = getDefaultParams();
};

const initialState: AccessControlListState = {
  params: getDefaultParams(),
  accessControlList: {
    status: RequestStatus.Idle,
  },
  accessControlDetail: {
    status: RequestStatus.Idle,
  },
  canLoadMore: false,
  accessControlRequest: {
    status: RequestStatus.Idle,
  },
  initGroups: [],
  updateGroups: [],
};

const setCanLoadMore = (
  state: AccessControlListState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};

const resetAccessControlList: CaseReducer<AccessControlListState> = (
  state: AccessControlListState,
) => {
  state.accessControlList = {
    status: RequestStatus.Idle,
  };
};

const setAccessControlFilterParams = (
  state: AccessControlListState,
  { payload }: PayloadAction<GetListAccessControlRequestParams>,
) => {
  state.params = payload;
};

const appendAccessControl = (
  state: AccessControlListState,
  { payload }: PayloadAction<any[]>,
) => {
  state.accessControlList.status = RequestStatus.Success;
  if (state.accessControlList?.data?.records) {
    state.accessControlList.data.records = [...payload];
  }
};

const getAccessControlListRequest: CaseReducer<
  AccessControlListState,
  PayloadAction<any>
> = (state: AccessControlListState) => {
  state.accessControlList.status = RequestStatus.Loading;
};

const getAccessControlListSuccess: CaseReducer<
  AccessControlListState,
  PayloadAction<any>
> = (state: AccessControlListState, { payload }: PayloadAction<any>) => {
  state.accessControlList.status = RequestStatus.Success;
  state.accessControlList.data = payload;
};

const getAccessControlListFailed: CaseReducer<AccessControlListState> = (
  state: AccessControlListState,
) => {
  state.accessControlList.status = RequestStatus.Failed;
};

const getDetailAccessControlRequest: CaseReducer<
  AccessControlListState,
  PayloadAction<any>
> = (state: AccessControlListState) => {
  state.accessControlDetail.status = RequestStatus.Loading;
};

const getDetailAccessControlSuccess: CaseReducer<
  AccessControlListState,
  PayloadAction<AccessControlDetail>
> = (
  state: AccessControlListState,
  { payload }: PayloadAction<AccessControlDetail>,
) => {
  state.accessControlDetail.status = RequestStatus.Success;
  state.accessControlDetail.data = payload;
};

const getDetailAccessControlFailed: CaseReducer<AccessControlListState> = (
  state: AccessControlListState,
) => {
  state.accessControlDetail.status = RequestStatus.Failed;
};

const resetDetailAccessControl: CaseReducer<AccessControlListState> = (
  state: AccessControlListState,
) => {
  state.accessControlDetail.status = RequestStatus.Idle;
};

const accessControlRequest: CaseReducer<
  AccessControlListState,
  PayloadAction<AccessControlRequest>
> = (state: AccessControlListState) => {
  state.accessControlRequest.status = RequestStatus.Loading;
};

const accessControlSuccess: CaseReducer<AccessControlListState> = (
  state: AccessControlListState,
) => {
  state.accessControlRequest.status = RequestStatus.Success;
};

const accessControlFailed: CaseReducer<AccessControlListState> = (
  state: AccessControlListState,
) => {
  state.accessControlRequest.status = RequestStatus.Failed;
};

const setInitGroups = (
  state: AccessControlListState,
  { payload }: PayloadAction<any>,
) => {
  state.initGroups = payload;
};

const setUpdateGroups = (
  state: AccessControlListState,
  { payload }: PayloadAction<any>,
) => {
  state.updateGroups = payload;
};

const accessControlListSlice = createSlice({
  name: 'accessControlListSlice',
  initialState,
  reducers: {
    getAccessControlListRequest,
    getAccessControlListSuccess,
    getAccessControlListFailed,
    resetDetailAccessControl,

    setCanLoadMore,

    resetAccessControlList,
    appendAccessControl,

    setListAccessControlParams<
      K extends keyof GetListAccessControlRequestParams,
    >(
      state: AccessControlListState,
      action: PayloadAction<{
        key: K;
        value: GetListAccessControlRequestParams[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
    },

    setAccessControlFilterParams,
    resetDefaultParams,

    getDetailAccessControlRequest,
    getDetailAccessControlSuccess,
    getDetailAccessControlFailed,

    accessControlRequest,
    accessControlSuccess,
    accessControlFailed,

    setInitGroups,
    setUpdateGroups,
  },
});

// Actions
export const accessControlListActions = accessControlListSlice.actions;

// Reducer
export const accessControlListReducer = accessControlListSlice.reducer;

// Selectors
export const selectAccessControlList = (state: AppState) =>
  state.accessControl?.accessControlList;

export const selectParamAccessControlList = (state: AppState) =>
  state.accessControl?.params;

export const selectAccessControlDetail = (state: AppState) =>
  state.accessControl?.accessControlDetail;

export const selectAccessControlRequest = (state: AppState) =>
  state.accessControl?.accessControlRequest;

export const selectUpdateGroups = (state: AppState) =>
  state.accessControl?.updateGroups;

export const selectInitGroups = (state: AppState) =>
  state.accessControl?.initGroups;
