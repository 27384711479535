import { Col, Row } from 'antd';
import CustomerProfileTab from 'app/components/common/CustomerProfile';
import { useState } from 'react';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
}

const CustomerInfo = ({ requestDetail }: Props) => {
  const [open, setOpen] = useState(false);

  const info = (label, content) => {
    return (
      <Col span={12}>
        <div className={styles['customer-inform__label']} data-testid="label">
          {label}
        </div>
        <div
          className={styles['customer-inform__content']}
          onClick={() => setOpen(true)}
        >
          {content}
        </div>
      </Col>
    );
  };
  return (
    <>
      <div className={styles['customer-inform']} data-testid="customer">
        <Row>
          {info('Customer name', requestDetail?.customer?.name)}
          {info(
            'Business registration number',
            requestDetail?.customer?.businessRegistrationId,
          )}
        </Row>
      </div>
      <CustomerProfileTab
        requestDetail={requestDetail}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
export default CustomerInfo;
