import { Button } from 'antd';
import RakkarHeaderFilter from 'app/components/common/RakkarHeaderFilter';
import FilterRequest from 'app/components/Request/FilterRequest';
import PendingRequestTable from 'app/components/Request/PendingRequestTable';
import { SvgIcon } from 'app/components/SvgIcon';
import NotFound from 'app/components/Table/components/EmptyState';
import {
  ListActionNeedToCheckPermission,
  PAGE,
  TAB_REQUEST,
} from 'enum/common';
import { ParamsRequest } from 'interfaces/pendingRequest';
import { Params, RequestStatus } from 'interfaces/request';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectPermissions } from 'store/slices/auth';
import { commonActions, selectTotalRequest } from 'store/slices/common';
import {
  requestActions,
  selectParamsRequest,
  selectPendingRequestList,
} from 'store/slices/request';
import { checkPermissions } from 'utils/common';

import BasicButton from 'app/components/common/BasicButton';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { history } from 'utils/history';
import styles from './index.module.scss';

const PendingRequest = () => {
  const dispatch = useDispatch();
  const [isFilter, setIsFilter] = useState(false);
  const params = useAppSelector(selectParamsRequest);
  const requestList = useAppSelector(selectPendingRequestList);
  const total =
    useAppSelector(selectTotalRequest)?.data?.totalPendingRequest || 0;
  const [firstRender, setFirstRender] = useState(true);

  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const search = useLocation().search;
  const queryTab = new URLSearchParams(search).get('tab') as TAB_REQUEST;
  const [tabRequest, setTabRequest] = useState(queryTab || TAB_REQUEST.PENDING);

  const permissions = useAppSelector(selectPermissions);
  const allowViewHistoryRequest = checkPermissions(
    ListActionNeedToCheckPermission.VIEW_HISTORY_REQUEST,
    permissions,
  );

  useEffect(() => {
    dispatch(commonActions.getWorkSpacesRequest());
    dispatch(commonActions.getBusinessTypesRequest());
  }, []);

  useEffect(() => {
    if (isOpenPopover) {
      dispatch(commonActions.getCreatorsRequestRequest({}));
    }
  }, [isOpenPopover]);

  useEffect(() => {
    const isCheck =
      !!params?.dateFrom ||
      !!params?.dateTo ||
      (params?.requestType && params?.requestType?.length > 0) ||
      (params?.requestedBy && params?.requestedBy?.length > 0) ||
      (params?.filterStatus && params?.filterStatus?.length > 0);
    setIsFilter(!!isCheck);
  }, [params]);

  const setValue = (key: keyof ParamsRequest) => {
    return (value: ParamsRequest[keyof ParamsRequest]) => {
      dispatch(
        requestActions.setPendingRequestParam({
          key,
          value,
        }),
      );
    };
  };

  const setKeyword = setValue(Params.KeyWord);
  const setOffset = setValue(Params.Offset);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    return () => {
      if (!firstRender) {
        dispatch(requestActions.resetPendingRequestList());
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  useEffect(() => {
    if (
      !requestList?.data?.records?.length &&
      requestList?.status === RequestStatus.Success
    ) {
      setShowEmptyScreen(true);
    } else {
      setShowEmptyScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestList]);

  useEffect(() => {
    dispatch(
      requestActions.getPendingRequestListRequest({
        params: params,
      }),
    );
  }, [params, dispatch]);

  const handleSearch = useCallback(
    async (keyword: string) => {
      const newParams = {
        ...params,
        keyword,
        offset: 0,
      };
      await dispatch(requestActions.setPendingFilterParams(newParams));
      await dispatch(requestActions.resetPendings());
    },
    [dispatch, params],
  );

  const onFinishFilter = (values: any) => {
    setIsOpenPopover(false);
    const newParams = {
      ...params,
      ...values,
      offset: 0,
    };

    dispatch(requestActions.setPendingFilterParams(newParams));
    dispatch(requestActions.resetPendings());
  };

  const onClickRefresh = useCallback(
    () => {
      setOffset(0);
      dispatch(
        requestActions.getPendingRequestListRequest({
          params: params,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const handleAllRequests = () => {
    setTabRequest(TAB_REQUEST.ALL);
    dispatch(requestActions.resetPendingRequestAll());
    history.replace({
      pathname: window.location.pathname,
      search: `?tab=${TAB_REQUEST.ALL}`,
    });
  };
  const handleBack = () => {
    setTabRequest(TAB_REQUEST.PENDING);
    dispatch(requestActions.resetPendingRequestList());
    history.replace({
      pathname: window.location.pathname,
      search: `?tab=${TAB_REQUEST.PENDING}`,
    });
  };

  const hasSearchOrFilter = useMemo(() => {
    return !!isFilter || !!params?.keyword;
  }, [isFilter, params?.keyword]);

  const header = useMemo(() => {
    if (tabRequest === TAB_REQUEST.PENDING) {
      return (
        <div className={styles.heading}>
          <SvgIcon name="request-title-icon" width={28} height={28} />
          <div className={styles.title} data-testid="title-pending-request">
            Pending requests
          </div>
          <div className={styles.total}>
            <div
              className={clsx(styles.total__number, {
                [styles.total__empty]: total === 0,
              })}
              data-testid="total-request"
            >
              {`${total > 99 ? '99+' : total} pending`}
            </div>
          </div>
          {allowViewHistoryRequest && (
            <div className={styles.next__tab}>
              <Button
                type="link"
                onClick={handleAllRequests}
                data-testid="btnClickAllRequest"
              >
                <SvgIcon
                  name="history-icon"
                  width={13}
                  height={13}
                  style={{ marginBottom: 1 }}
                />
                Request history
                <SvgIcon name="arrow-right" width={10} height={10} />
              </Button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={clsx(styles.heading, styles.headingAll)}>
        <BasicButton
          title="Back"
          type="outline"
          icon={
            <SvgIcon width={16} height={16} name="left-arrow" fill="#2C303C" />
          }
          onClick={handleBack}
        />
        <div className={styles.title} data-testid="title-all-request">
          Request history
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestList?.status, tabRequest, total]);

  return (
    <div className={styles.container}>
      {header}
      {showEmptyScreen && !hasSearchOrFilter ? (
        <div className={styles.body}>
          <NotFound
            title={"You don't have any pending request"}
            description="Requests will appear here"
          />
        </div>
      ) : (
        <>
          <div className={styles.heading_filter}>
            <RakkarHeaderFilter
              page={PAGE.PENDING_REQUEST}
              keyword={params?.keyword}
              onInputSearch={handleSearch}
              onRefresh={onClickRefresh}
              isOpenPopover={isOpenPopover}
              setIsOpenPopover={setIsOpenPopover}
              isHasFilter={isFilter}
              filterContent={
                <FilterRequest
                  tab={tabRequest}
                  isOpen={isOpenPopover}
                  onFinishFilter={onFinishFilter}
                />
              }
              tabRequest={tabRequest}
            />
          </div>
          <div className={styles.content}>
            <PendingRequestTable
              params={params}
              setValue={setValue}
              tabRequest={tabRequest}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PendingRequest;
