import { useCallback } from 'react';
import { history } from 'utils/history';

export const useViewRequest = () => {
  const handleViewRequest = useCallback((requestId: string) => {
    history.replace({
      pathname: '/pending-request',
      search: `id=${requestId}`,
    });
  }, []);

  return { handleViewRequest };
};

export default useViewRequest;
