import BasicButton from 'app/components/common/BasicButton';
import { RawHtml } from 'app/components/common/RawHtml';
import TextEditor from 'app/components/common/TextEditor';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import { ItemComment as IComment } from 'interfaces/comment';
import { RequestStatus } from 'interfaces/request';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserProfile } from 'store/slices/auth';
import { commentActions, selectUpdateComment } from 'store/slices/comment';
import { formatDate } from 'utils/date';
import { isQuillEmpty } from 'utils/string';
import RakkarAvatar from '../Avatar';
import PopupDeleteComment from '../PopupDeleteComment';
import styles from './index.module.scss';

type Props = {
  comment: IComment;
  txnId: string;
  isNotAllowedAction?: boolean;
};

const ItemComment = ({ comment, isNotAllowedAction, ...props }: Props) => {
  const initTextComment = comment.content;
  const dispatch = useAppDispatch();
  const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const profile = useAppSelector(selectUserProfile);
  const editComment = useAppSelector(selectUpdateComment);
  const [isTextEditor, setIsTextEditor] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initTextComment);

  const loadingSave = editComment.status === RequestStatus.Loading;

  const isOwner = useMemo(() => {
    return comment?.createdBy?.id === profile?.id;
  }, [comment?.createdBy?.id, profile?.id]);

  const handleEditorChange = value => {
    setValue(value);
  };

  const handleSave = () => {
    if (value.length <= 0 || isQuillEmpty(value)) {
      return;
    }
    dispatch(
      commentActions.updateCommentRequest({
        txnId: props.txnId,
        commentId: comment.id,
        params: {
          content: value,
        },
      }),
    );
  };

  useEffect(() => {
    if (editComment.status === RequestStatus.Success) {
      setIsTextEditor(false);
    }
  }, [editComment.status]);

  useEffect(() => {
    return () => {
      setValue(initTextComment);
    };
  }, [initTextComment]);

  const { createdBy, createdAt, updatedAt, content } = comment;
  const isSame = useMemo(() => {
    return moment(createdAt).isSame(moment(updatedAt));
  }, [createdAt, updatedAt]);
  return (
    <div className={styles.comment}>
      <RakkarAvatar avatar={createdBy?.avatar} name={createdBy?.name} />

      <div className={styles.row}>
        <div className={styles.row__top}>
          <div className={styles.comment__name} data-testid="name">
            {createdBy?.name}
          </div>
          <div className={styles.comment__time} data-testid="time">
            <span>
              {' '}
              {formatDate(updatedAt || createdAt, FORMAT_DATE.DATE_DMY_hM)}
            </span>{' '}
            {!isSame && (
              <span className={styles.comment__textEdited}>Edited</span>
            )}
          </div>
        </div>
        {!isTextEditor && (
          <RawHtml className={styles.comment__content} data-testid="content">
            {content}
          </RawHtml>
        )}
        {isOwner && !isNotAllowedAction && (
          <div
            className={clsx({
              [styles.actionBtnEdit]: isTextEditor,
            })}
          >
            <>
              {!isTextEditor ? (
                <div className={styles.actionNotEditor}>
                  {' '}
                  <span
                    className={clsx(styles.action, styles['action--edit'])}
                    onClick={() => {
                      setValue(initTextComment);
                      setIsTextEditor(!isTextEditor);
                    }}
                  >
                    Edit
                  </span>
                  <span
                    className={clsx(styles.action, styles['action--delete'])}
                    onClick={() => setShowPopupDelete(true)}
                  >
                    Delete
                  </span>
                </div>
              ) : (
                <>
                  <TextEditor
                    initValue={value}
                    onChangeEditor={handleEditorChange}
                  />
                  <div className={styles.actionBtn}>
                    <BasicButton
                      title="Cancel"
                      type="outline"
                      onClick={() => {
                        setIsTextEditor(!isTextEditor);
                      }}
                    />
                    <BasicButton
                      title="Save"
                      type="filled"
                      loading={loadingSave}
                      onClick={handleSave}
                    />
                  </div>
                </>
              )}
            </>
          </div>
        )}

        <PopupDeleteComment
          isOpen={showPopupDelete}
          txnId={props.txnId}
          commentId={comment.id}
          setIsOpen={setShowPopupDelete}
        />
      </div>
    </div>
  );
};

export default ItemComment;
