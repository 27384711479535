import { PayloadAction } from '@reduxjs/toolkit';
import { PermissionDetail } from 'interfaces/auth';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getAuthInfo, getProfileInfo, getRoles } from 'services/auth';
import { authActions } from 'store/slices/auth';
import { handleShowMsgErr } from 'utils/common';

const handleGetAuthInfoRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const data = yield call(getAuthInfo, payload);
    yield put(authActions.loginSuccess(data));
    yield put(authActions.getAuthInfoSuccess(data));
    yield put(authActions.getPermissionsRequest());
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(authActions.getAuthInfoFailed());
  }
};

const handleGetPermissions = function* () {
  try {
    const res = yield call(getRoles);

    if (res?.roles && res?.permissions) {
      const listPermission = res?.roles
        .map(({ permissionIds }) => {
          return [...permissionIds];
        })
        .flat()
        .reduce((acc: any, cur: any) => {
          if (!acc.includes(cur)) {
            acc.push(cur);
          }
          return acc;
        }, []);

      const listMyPermission = res?.permissions.filter(item => {
        return listPermission.includes(item.id);
      }) as PermissionDetail[];
      yield put(
        authActions.getPermissionsSuccess({
          permissions: listMyPermission,
          roles: res?.roles,
        }),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(authActions.getPermissionsFailed());
  }
};

const handleGetUserProfile = function* () {
  try {
    const res = yield call(getProfileInfo);
    yield put(authActions.getUserProfileSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(authActions.getUserProfileFailed());
  }
};

const authSaga = function* () {
  yield takeLatest(
    authActions.getAuthInfoRequest.type,
    handleGetAuthInfoRequest,
  );

  yield takeLatest(
    authActions.getPermissionsRequest.type,
    handleGetPermissions,
  );

  yield takeLatest(
    authActions.getUserProfileRequest.type,
    handleGetUserProfile,
  );
};

export default authSaga;
