import { Button } from 'antd';
import FilterBilling from 'app/components/Billing/FilterBilling';
import ListBilling from 'app/components/Billing/ListBilling';
import BasicButton from 'app/components/common/BasicButton';
import NoResult from 'app/components/common/NoResult';
import RakkarHeaderFilter from 'app/components/common/RakkarHeaderFilter';
import { SvgIcon } from 'app/components/common/SvgIcon';
import clsx from 'clsx';
import {
  ListActionNeedToCheckPermission,
  PAGE,
  TAB_REQUEST,
} from 'enum/common';
import { BillingParamsRequest } from 'interfaces/billings';
import { Params, RequestStatus } from 'interfaces/request';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectPermissions } from 'store/slices/auth';
import {
  billingsActions,
  selectBillingList,
  selectparamsBillingList,
} from 'store/slices/billings';
import { checkPermissions } from 'utils/common';
import { mapRequest } from 'utils/helpers';
import { history } from 'utils/history';
import styles from './index.module.scss';

type Props = {};

const paramNamesFilter = [
  'feeFrom',
  'feeTo',
  'dateFrom',
  'dateTo',
  'customerIds',
  'dueDateFrom',
  'dueDateTo',
  'status',
];

const Billing = (props: Props) => {
  const dispatch = useAppDispatch();

  const paramsBilling = useAppSelector(selectparamsBillingList);
  const billingList = useAppSelector(selectBillingList);

  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [isHasFilter, setIsHasFilter] = useState(false);

  const [firstRender, setFirstRender] = useState(true);

  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const permissions = useAppSelector(selectPermissions);
  const allowViewBillingHistory = checkPermissions(
    ListActionNeedToCheckPermission.VIEW_BILLING_HISTORY,
    permissions,
  );
  const search = useLocation().search;
  const queryTab = new URLSearchParams(search).get('tab') as TAB_REQUEST;
  const [tabRequest, setTabRequest] = useState(queryTab || TAB_REQUEST.PENDING);
  const isFilter = tabRequest === TAB_REQUEST.ALL;

  const setValue = (key: keyof BillingParamsRequest) => {
    return (value: BillingParamsRequest[keyof BillingParamsRequest]) => {
      dispatch(
        billingsActions.setBillingParam({
          key,
          value,
        }),
      );
    };
  };

  const setKeyword = setValue(Params.KeyWord);
  const setOffset = setValue(Params.Offset);

  // TODO: Refresh screen
  const onRefresh = () => {
    dispatch(
      billingsActions.getBillingListRequest({
        ...paramsBilling,
        isHistory: tabRequest === TAB_REQUEST.ALL,
        offset: 0,
      }),
    );
    setOffset(0);
  };

  // TODO: Keyword search
  const onInputChange = (keyword: string) => {
    if (tabRequest === TAB_REQUEST.ALL) {
      dispatch(billingsActions.resetAllBillingPage());
    } else {
      dispatch(billingsActions.resetBillingPage());
    }
    setOffset(0);
    setKeyword(keyword);
  };

  const hasSearchOrFilter = useMemo(() => {
    return !!isHasFilter || !!paramsBilling.keyword;
  }, [isHasFilter, paramsBilling.keyword]);

  useEffect(() => {
    const paramsFilter: any = {};
    paramNamesFilter.forEach(element => {
      paramsFilter[element] = paramsBilling[element];
    });
    const isEmpty =
      Object.values(paramsFilter).some(e => !!e) ||
      paramsFilter?.feeFrom === 0 ||
      paramsFilter?.feeTo === 0;
    setIsHasFilter(isEmpty);
  }, [paramsBilling]);

  useEffect(() => {
    if (
      !billingList?.data?.customerBillings?.length &&
      billingList?.status === RequestStatus.Success
    ) {
      setShowEmptyScreen(true);
    } else {
      setShowEmptyScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingList]);

  const handleViewBillingHistory = () => {
    setTabRequest(TAB_REQUEST.ALL);
    dispatch(billingsActions.resetAllBillingPage());
    history.replace({
      pathname: window.location.pathname,
      search: `?tab=${TAB_REQUEST.ALL}`,
    });
  };

  const handleBack = () => {
    setTabRequest(TAB_REQUEST.PENDING);
    dispatch(billingsActions.resetBillingPage());
    history.replace({
      pathname: window.location.pathname,
      search: `?tab=${TAB_REQUEST.PENDING}`,
    });
  };

  const handleDownloadFile = () => {};

  useEffect(() => {
    if (isOpenPopover) {
      dispatch(
        billingsActions.fetchCustomersRequest(
          mapRequest({
            params: {
              offset: 0,
              limit: 1000,
              hasInvoicesReviewed: true,
            },
          } as any),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenPopover]);

  const onFinishFilter = (values: any) => {
    setIsOpenPopover(false);
    const params = {
      ...paramsBilling,
      ...values,
      offset: 0,
    };
    dispatch(billingsActions.setBillingFilterParams(mapRequest(params)));
  };

  const setBillingFilterOnChangePage = (key: keyof BillingParamsRequest) => {
    return (value: '') => {
      dispatch(
        billingsActions.setBillingParam({
          key,
          value,
        }),
      );
    };
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    return () => {
      if (!firstRender) {
        dispatch(billingsActions.resetParamsBilling());
        setBillingFilterOnChangePage(Params?.KeyWord);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  const header = useMemo(() => {
    if (tabRequest === TAB_REQUEST.PENDING) {
      return (
        <div className={styles.heading}>
          <SvgIcon name="billing-title" width={28} height={28} />
          <div className={styles.title}>Billing</div>
          {allowViewBillingHistory && (
            <div className={styles.next__tab}>
              <Button type="link" onClick={handleViewBillingHistory}>
                <SvgIcon
                  name="history-icon"
                  width={13}
                  height={13}
                  style={{ marginBottom: 1 }}
                />
                Billing history
                <SvgIcon name="arrow-right" width={10} height={10} />
              </Button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={clsx(styles.heading, styles.headingAll)}>
        <BasicButton
          title="Back"
          type="outline"
          icon={
            <SvgIcon width={16} height={16} name="left-arrow" fill="#2C303C" />
          }
          onClick={handleBack}
        />
        <div
          className={clsx(styles.title, styles.titleAll)}
          data-testid="title-all-billing"
        >
          Billing history
        </div>
      </div>
    );
  }, [allowViewBillingHistory, tabRequest]);

  return (
    <>
      <Helmet>
        <title>Billing</title>
        <meta name="description" content="A Boilerplate application Billing" />
      </Helmet>
      <div className={styles.container}>
        {header}
        {showEmptyScreen && !hasSearchOrFilter ? (
          <NoResult
            title="There are no unapproved bills here"
            description="Please try again later"
          />
        ) : (
          <>
            <div className={styles.heading_filter}>
              <RakkarHeaderFilter
                page={PAGE.BILLING}
                keyword={paramsBilling.keyword}
                onInputSearch={onInputChange}
                onRefresh={onRefresh}
                isOpenPopover={isOpenPopover}
                setIsOpenPopover={setIsOpenPopover}
                isFilter={isFilter}
                isHasFilter={isHasFilter}
                onDownload={handleDownloadFile}
                filterContent={
                  <FilterBilling
                    isOpen={isOpenPopover}
                    onFinishFilter={onFinishFilter}
                  />
                }
              />
            </div>
            <div className={styles.table}>
              <ListBilling
                hasSearchOrFilter={hasSearchOrFilter}
                showEmptyScreen={showEmptyScreen}
                isHasFilter={isHasFilter}
                tabRequest={tabRequest}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Billing;
