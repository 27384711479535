import { SvgIcon } from 'app/components/SvgIcon';
import { STATUS_REQUEST } from 'constants/index';
import UnlockUser from 'images/UnlockUser.png';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
}

const RequestHeader = ({ requestDetail }: Props) => {
  const isApproved = requestDetail?.status === STATUS_REQUEST.APPROVED;
  const isRejected = requestDetail?.status === STATUS_REQUEST.REJECTED;

  const approveOrReject = (isApproved: boolean) => {
    return (
      (isApproved || isRejected) && (
        <div
          className={`${styles.status} ${
            isApproved ? styles.approved : styles.rejected
          }`}
        >
          <div>
            <SvgIcon name={isApproved ? 'white-approve' : 'white-reject'} />
          </div>
          <div>
            {isApproved ? STATUS_REQUEST.APPROVED : STATUS_REQUEST.REJECTED}
          </div>
        </div>
      )
    );
  };
  return (
    <>
      <img src={UnlockUser} width={32} height={32} />
      <div className={styles.title}>
        <div>Unlock user request</div>
        {approveOrReject(isApproved)}
      </div>
    </>
  );
};
export default RequestHeader;
