import { Col, Row } from 'antd';
import { documentTypeOpts } from 'app/components/BusinessInformation/index.helper';
import { SvgIcon } from 'app/components/SvgIcon';
import CustomerProfileTab from 'app/components/common/CustomerProfile';
import IdPassportInfo from 'app/components/common/IdOrPassportInfo';
import RakkarUserRole from 'app/components/common/RakkarUserRole';
import TextInfor from 'app/components/common/TextInfor';
import Vaults from 'app/components/common/Vaults';
import { STATUS_REQUEST } from 'constants/index';
import { DOCUMENT_TYPE } from 'enum/common';
import { RequestType } from 'enum/pendingRequest';
import { useMemo, useState } from 'react';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
  isMask: boolean;
}

const ResendEmail = ({ requestDetail, isMask }: Props) => {
  const isApproved = requestDetail?.status === STATUS_REQUEST.APPROVED;
  const isRejected = requestDetail?.status === STATUS_REQUEST.REJECTED;
  const [open, setOpen] = useState(false);

  const documentType = useMemo(() => {
    return (
      (!!requestDetail &&
        documentTypeOpts?.find(
          item => item.value === requestDetail?.user?.documentType,
        )?.label) ||
      ''
    );
  }, [requestDetail]);

  const approveOrReject = (isApproved: boolean) => {
    return (
      (isApproved || isRejected) && (
        <div
          className={`${styles.status} ${
            isApproved ? styles.approved : styles.rejected
          }`}
        >
          <div>
            <SvgIcon name={isApproved ? 'white-approve' : 'white-reject'} />
          </div>
          <div>
            {isApproved ? STATUS_REQUEST.APPROVED : STATUS_REQUEST.REJECTED}
          </div>
        </div>
      )
    );
  };

  const inform = (label: string, content) => {
    return (
      <Col span={12}>
        <div className={styles['customer-inform__label']} data-testid="label">
          {label}
        </div>
        <div
          className={styles['customer-inform__content']}
          onClick={() => setOpen(true)}
        >
          {content}
        </div>
      </Col>
    );
  };

  const customerInfo = () => {
    return (
      <div className={styles['customer-inform']} data-testid="customer">
        <Row>
          {inform('Customer name', requestDetail?.customer?.name)}
          {inform(
            'Business registration number',
            requestDetail?.customer?.businessRegistrationId,
          )}
        </Row>
      </div>
    );
  };

  const userInfo = () => {
    return (
      <>
        <div className={styles['user-inform']}>
          <div>
            <SvgIcon name="user-info" width={16} height={16} />
          </div>
          <div className={styles['user-inform__title']}>User information</div>
        </div>
        <Row>
          <TextInfor
            label="Name"
            text={
              <div className={styles.name}>{requestDetail?.user?.name}</div>
            }
            data-testid="user-name"
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            label="Role"
            text={
              <div className={styles.role} data-testid="user-role">
                <div>
                  <RakkarUserRole userRole={requestDetail?.user?.role} />
                </div>
                {requestDetail?.user?.requiredApprover && (
                  <div className={styles.required}>
                    REQUIRED APPROVER
                    <SvgIcon
                      name="required-approver"
                      width={10}
                      height={12}
                      data-testid="required-approver-icon"
                    />
                  </div>
                )}
              </div>
            }
          />
        </Row>
      </>
    );
  };

  return (
    <>
      <div className={styles.container} data-testid="resend-email-request">
        <div className={styles.icon}>
          <SvgIcon name="resend-email" width={32} height={32} />
        </div>
        <div className={styles.title}>
          {requestDetail?.action === RequestType.RESEND_ONBOARDING_EMAIL ? (
            <div>Resend onboarding email request</div>
          ) : (
            <div>Resend verification email request</div>
          )}
          {approveOrReject(isApproved)}
        </div>
        {customerInfo()}
        <div className={styles.content}>
          {userInfo()}
          <TextInfor
            label="Identity document"
            text={
              <div
                data-testid="identity-document"
                className={styles.passport_id}
              >
                {requestDetail?.user?.documentType && (
                  <SvgIcon
                    name={
                      requestDetail?.user?.documentType ===
                      DOCUMENT_TYPE.PASSPORT
                        ? 'passport'
                        : 'id_card'
                    }
                    width={16}
                    height={16}
                    className={styles.passport_id__icon}
                  />
                )}
                {documentType ? documentType : '-'}
              </div>
            }
          />
          <IdPassportInfo requestDetail={requestDetail} isMask={isMask} />
          <Row>
            <TextInfor
              label="Phone number"
              text={
                <div data-testid="phone-number">
                  {requestDetail?.user?.phoneNumber}
                </div>
              }
              isMask={isMask}
              isMaskable={true}
            />
            <TextInfor
              label="Email"
              text={<div data-testid="email">{requestDetail?.user?.email}</div>}
              isMask={isMask}
              isMaskable={true}
            />
          </Row>
          <Vaults requestDetail={requestDetail} />
        </div>
        <CustomerProfileTab
          open={open}
          setOpen={setOpen}
          requestDetail={requestDetail}
          isMask={isMask}
        />
      </div>
    </>
  );
};

export default ResendEmail;
