export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Sort {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  TOTAL_USD = 'TOTAL_USD',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  CREATED_DATE = 'CREATED_DATE',
}

export enum SortType {
  AZ_NAME = 'AZ_NAME',
  ZA_NAME = 'ZA_NAME',
  AZ_AMOUNT = 'AZ_AMOUNT',
  ZA_AMOUNT = 'ZA_AMOUNT',
}

export enum WorkSpaceType {
  Hot = 'warm',
  Cold = 'cold',
}

export enum TransactionType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  REBALANCING = 'REBALANCING',
  TRANSFER = 'TRANSFER',
  STAKING = 'STAKING',
  UNSTAKING = 'UNSTAKING',
  CLAIM_REWARD = 'CLAIM_REWARD',
  CHANGE_POOL = 'CHANGE_POOL',
  CONTRACT_CALL = 'CONTRACT_CALL',
}

export enum PeerType {
  VAULT_ACCOUNT = 'VAULT_ACCOUNT',
  EXCHANGE_ACCOUNT = 'EXCHANGE_ACCOUNT',
  INTERNAL_WALLET = 'INTERNAL_WALLET',
  EXTERNAL_WALLET = 'EXTERNAL_WALLET',
  UNKNOWN = 'UNKNOWN',
  NETWORK_CONNECTION = 'NETWORK_CONNECTION',
  FIAT_ACCOUNT = 'FIAT_ACCOUNT',
  COMPOUND = 'COMPOUND',
  ONE_TIME_ADDRESS = 'ONE_TIME_ADDRESS',
  OEC_PARTNER = 'OEC_PARTNER',
}

export enum TransactionStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  CONFIRMING = 'confirming',
  CANCELLED = 'cancelled',
  PENDING_REVIEW = 'Pending review',
  REJECTED = 'rejected',
}

export enum ContractCallMethod {
  DEPOSIT = 'deposit',
  INCOMING = 'incoming',
}

export enum TransactionFeature {
  LIQUID_STAKING = 'LIQUID_STAKING',
}

export enum BillingStatus {
  PENDING = 'pending',
  REVIEWED = 'reviewed',
}

export enum AuditLogStatus {
  INITIATED = 'INITIATED',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export enum WalletInfoStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}

export enum SnapshotTokenPriceStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}

export enum CommonStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}

export enum ListActionNeedToCheckPermission {
  // -Customer page
  VIEW_CUSTOMER_LISTING = 'VIEW_CUSTOMER_LISTING',
  ADD_NEW_CUSTOMER = 'ADD_NEW_CUSTOMER',
  EDIT_CUSTOMER = 'EDIT_CUSTOMER',
  DEACTIVE_CUSTOMER = 'DEACTIVE_CUSTOMER',
  // -Customer user page
  VIEW_USER_LIST = 'VIEW_USER_LIST',
  VIEW_USER_DETAIL = 'VIEW_USER_DETAIL',
  ADD_NEW_USER = 'ADD_NEW_USER',
  EDIT_USER = 'EDIT_USER',
  DEACTIVE_USER = 'DEACTIVE_USER',
  // -Rakkar employee page
  // -Request page
  VIEW_PENDING_REQUEST = 'VIEW_PENDING_REQUEST',
  VIEW_REQUEST_DETAIL = 'VIEW_REQUEST_DETAIL',
  VIEW_HISTORY_REQUEST = 'VIEW_HISTORY_REQUEST',
  APPROVE_REJECT = 'APPROVE_REJECT',

  // -Transaction page
  VIEW_TRANSACTION_HISTORY = 'VIEW_TRANSACTION_HISTORY',
  VIEW_TRANSACTION_LISTING = 'VIEW_TRANSACTION_LISTING',
  VIEW_TRANSACTION_DETAIL = 'VIEW_TRANSACTION_DETAIL',
  VIEW_CHECKLIST_REVIEW = 'VIEW_CHECKLIST_REVIEW',
  UNFREEZE_TRANSACTION = 'UNFREEZE_TRANSACTION',
  // Unfreeze transaction
  APPROVE_UNFREEZE_TRANSACTION = 'APPROVE_UNFREEZE_TRANSACTION',

  // Billing
  VIEW_BILLING_LISTING = 'VIEW_BILLING_LISTING',
  VIEW_BILLING_DETAIL = 'VIEW_BILLING_DETAIL',
  REVIEW_INVOICE = 'REVIEW_INVOICE',
  VIEW_BILLING_HISTORY = 'VIEW_BILLING_HISTORY',
  MARK_BILLING_AS_REVIEWED = 'MARK_BILLING_AS_REVIEWED',
  MARK_BILLING_AS_PAID = 'MARK_BILLING_AS_PAID',

  // Audit log page
  AUDIT_LOG = 'AUDIT_LOG',

  // Access Control page
  ACCESS_CONTROL = 'ACCESS_CONTROL',

  // Sync Fireblocks Transaction
  SYNC_FB_TXN = 'SYNC_FB_TXN',
}
export enum PAGE {
  CUSTOMER_MANAGEMENT = 'CUSTOMER_MANAGEMENT',
  PENDING_REQUEST = 'PENDING_REQUEST',
  TRANSACTION = 'TRANSACTION',
  BILLING = 'BILLING',
  AUDITLOGLIST = 'AUDITLOGLIST',
  WALLET_INFO = 'WALLET_INFO',
  CURRENCY = 'CURRENCY',
  CURRENCYCONVERT = 'CURRENCYCONVERT',
  SNAPSHOTTOKENPRICE = 'SNAPSHOTTOKENPRICE',
}


export enum TAB_REQUEST {
  PENDING = 'PENDING',
  ALL = 'ALL',
}

export enum USER_ROLES {
  ADMIN = 'ADMIN', // dev1
  OPERATION = 'OPERATION', // dev2
  COMPLIANCE = 'COMPLIANCE', // dev4
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS', //dev3
  MEMBER = 'MEMBER',
  VIEWER = 'VIEWER',
  FINANCE = 'FINANCE', //dev5
  PRODUCT = 'PRODUCT', //dev7
  SECURITY = 'SECURITY', //dev8

  PRODUCT_WITH_REVIEW_TRANSACTION = 'PRODUCT_WITH_REVIEW_TRANSACTION',
  SECURITY_WITH_REVIEW_TRANSACTION = 'SECURITY_WITH_REVIEW_TRANSACTION',
  REVIEW_TRANSACTION = 'REVIEW_TRANSACTION',
}

export enum DOCUMENT_TYPE {
  PASSPORT = 'PASSPORT',
  ID_CARD = 'ID_CARD',
}

export enum TransactionTypeSource {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  REBALANCING = 'REBALANCING',
}

export enum SCREEN_USER {
  ADD_USER = 'ADD_USER',
  EDIT_USER = 'EDIT_USER',
  ADMIN_QUORUM_ADD = 'ADMIN_QUORUM_ADD',
  ADMIN_QUORUM_EDIT = 'ADMIN_QUORUM_EDIT',
}

export enum DOCUMENT_TYPE_VIEW {
  PASSPORT = 'Passport',
  ID_CARD = 'ID',
}

export enum VAULT_POLICY_TYPE {
  STANDARD = 'STANDARD',
  ADVANCE = 'ADVANCE',
}

export enum ERROR_CODE {
  EXISTS_CUSTOMER = 'EXISTS_CUSTOMER',
}

export enum CurrencyStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}

export enum CurrencyConvertStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}
