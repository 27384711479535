/* eslint-disable no-useless-concat */
/* eslint-disable no-template-curly-in-string */
import ToastMessage from 'app/components/common/ToastMessage';
import { KEY_CODE } from 'constants/index';
import { DOCUMENT_TYPE, TAB_REQUEST } from 'enum/common';
import { RoleUser } from 'enum/userList';
import { ResponseError } from 'interfaces/auth';
import { uniq } from 'lodash';
import { Country, getCountries } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { countryData } from './assets';

export const handleShowMsgErr = (err: ResponseError, msg?: string) => {
  toast(
    <ToastMessage
      type="error"
      message={msg || err?.message || 'Something went wrong'}
    />,
    {
      toastId: msg || err?.message,
    },
  );
};

export const getDefaultAvatarInfo = (text: string) => {
  let result = text.trim();
  const splitText = result.split(' ');
  const length = splitText.length;
  if (length > 1) {
    result = `${splitText[0].substring(0, 1).toUpperCase()}${splitText[
      length - 1
    ]
      .substring(0, 1)
      .toUpperCase()}`;
  } else {
    result = result.substring(0, 2).toUpperCase();
  }
  return result;
};

// https://github.com/react-component/field-form/blob/master/src/utils/messages.ts
export const validateMessages = () => ({
  required: '${label}' + ` is required`,
  types: {
    email: 'Invalid Email format',
    number: '${label}' + ` is not a valid number`,
  },
  string: {
    max: 'Max of ${max} characters',
  },
  number: {
    range: '${label}' + ` must be between ` + '${min}' + ` and ` + '${max}',
  },
});

export const getNameFromArrayById = (arrays: any, id: number | string) => {
  if (!arrays) return '';
  return arrays.find(item => item.id === id)?.name;
};

export const validateRegex = (value: string, pattern: any) => {
  let result = pattern.exec(value);
  return result === null;
};

export const detectingAnObjectProperty = (obj: object) => {
  const arrValue = Object.values(obj);
  const result = arrValue.findIndex(value => !value);
  return result === -1;
};

export const revertFormat = (value: any) => {
  return value ? +value.toString().replace(/[^0-9.]/g, '') : 0;
};

export const formatThousandSeparator = (amount: any) => {
  if (amount === null) return null;
  let value =
    amount &&
    amount
      .toString()
      .replace(/[^0-9.]/g, '')
      .replace('.', 'x')
      .replace(/\./g, '')
      .replace('x', '.')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return value;
};

export const formatThousandSeparatorInput = (amount: any) => {
  let value = amount
    ? amount
        .toString()
        .replace(/[^0-9.]/g, '')
        .replace('.', 'x')
        .replace(/\./g, '')
        .replace('x', '.')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0;

  return value;
};

export const CURRENCY_FORMAT_DECIMAL_PLACES = {
  BHD: 3,
  BIF: 0,
  BYR: 0,
  CLP: 0,
  DJF: 0,
  GNF: 0,
  GWP: 0,
  IQD: 3,
  JOD: 3,
  JPY: 0,
  KMF: 0,
  KRW: 0,
  KWD: 3,
  MGA: 0,
  OMR: 3,
  PYG: 0,
  RWF: 0,
  TND: 3,
  VND: 0,
  VUV: 0,
  XAF: 0,
  XOF: 0,
  XPF: 0,
  TWD: 0,
  OTHER: 2,
};

export const formatNumberWithOptionCurrencyCode = (
  value,
  code = '',
  showSymbol = false,
) => {
  value = Number(value);
  value = Number.isNaN(value) ? 0 : value;
  const options = {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  if (code) {
    const digits = CURRENCY_FORMAT_DECIMAL_PLACES;

    options.maximumFractionDigits = digits.hasOwnProperty(code)
      ? digits[code]
      : digits.OTHER;
  }

  const formatter = new Intl.NumberFormat('en-US', options);
  const result = formatter.format(value);

  if (code && showSymbol) {
    // get symbol from currency code
    // const symbol = this.getCurrencySymbol(code)
    // return `${result} ${symbol}`
    return `${value.toLocaleString('en-US', {
      style: 'currency',
      currency: code,
      minimumFractionDigits: 0,
    })}`;
  }

  return result;
};

export const formatterNumberThousand = (value: any) => {
  const clean = String(value).replace(/,/g, '');
  const regex = /^[0-9]*\.?[0-9]*$/;

  let formatted;
  if (String(value) && clean.match(regex)) {
    if (!String(value).includes('.')) {
      formatted = new Intl.NumberFormat().format(parseFloat(clean));
    } else {
      formatted = String(value);
    }
  } else {
    formatted = '';
  }
  return formatted;
};

export const isCommonKey = event => {
  const CODE = KEY_CODE;
  const validKeyCodes = [
    CODE.ENTER,
    CODE.DELETE,
    CODE.TAB,
    CODE.ARROW_LEFT,
    CODE.ARROW_UP,
    CODE.ARROW_RIGHT,
    CODE.ARROW_DOWN,
  ];
  const validCombinedKeyCodes = [CODE.Z, CODE.A, CODE.Y, CODE.X];

  if (validKeyCodes.includes(event.keyCode)) return true;
  if (event.ctrlKey && validCombinedKeyCodes.includes(event.keyCode))
    return true;

  return false;
};

export const isNumberKey = event => {
  if (isCommonKey(event)) return true;
  if (/^\d+$/.test(event.key)) return true;

  return false;
};

export const isAllowNumberAndOneDot = value => {
  return /^(?=[0-9.]+$)(?!.*\..*\.)[0-9.]+$/.test(value);
};

export const isDecimalNumberKey = event => {
  if (isCommonKey(event)) return true;
  if (/^[0-9]*\.?[0-9]*$/.test(event.key)) return true;

  return false;
};

export const dotAndCommaFormat = number => {
  if (!number) return 0;
  return new Intl.NumberFormat('de-DE').format(number);
};

export const removeEmptyValueInObject = (obj: any) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const checkPermissions = (value: string, permissions) => {
  return (
    permissions && permissions.findIndex(item => item.name === value) !== -1
  );
};

export const checkRoles = (value: string, roles) => {
  return roles && roles.findIndex(item => item.name === value) !== -1;
};

export const downloadFile = (
  blobData: Blob,
  filename: string,
  type?: string,
) => {
  const url = window.URL.createObjectURL(
    new Blob([blobData], {
      ...(type ? { type: type } : {}),
    }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const getCountryName = (countryCode: string) => {
  return countryData.find(country => country.code === countryCode)?.name;
};
export const showRoleInfo = (role: RoleUser) => {
  return {
    [RoleUser.ADMIN]: {
      text: 'Admin',
      className: 'role__admin',
    },
    [RoleUser.MEMBER]: {
      text: 'Member',
      className: 'role__member',
    },
    [RoleUser.VIEWER]: {
      text: 'Viewer',
      className: 'role__viewer',
    },
  }[role as RoleUser];
};

export const isHistoryPage = () => {
  const search: any = window.location.search;
  const result =
    search && (new URLSearchParams(search).get('tab') as TAB_REQUEST);
  return result === TAB_REQUEST.ALL ? true : false;
};

export const listCountryForPhoneNumber = (): Country[] => {
  const countries = getCountries();
  return uniq(['SG', 'HK', 'TH', ...countries]);
};

export const isNonEmptyObject = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return false; // if not an object or is null, return false
  }

  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== ''
    ) {
      return true; // if any value is not null, undefined, or empty, return true
    }
  }

  return false; // otherwise, return false
};

export const spacingRawText = (rawText: string): string => {
  const spacedText = rawText.replaceAll(',', ', ');
  return spacedText;
};

export const convertDocumentTypeToText = (str: string): string => {
  if (str === DOCUMENT_TYPE.PASSPORT) {
    return 'Passport';
  }
  return 'ID card';
};

export const compareStrings = (a: string, b: string) => {
  return a.localeCompare(b);
};
