export enum CompanyType {
  EXEMPT_PRIVATE_COMPANY = 'EXEMPT_PRIVATE_COMPANY',
  PRIVATE_COMPANY_LIMITED_BY_SHARES = 'PRIVATE_COMPANY_LIMITED_BY_SHARES',
  PUBLIC_COMPANY_LIMITED_BY_SHARES = 'PUBLIC_COMPANY_LIMITED_BY_SHARES',
  LIMITED_LIABILITY_PARTNERSHIP = 'LIMITED_LIABILITY_PARTNERSHIP',
  OTHER = 'OTHER',
}

export enum SourceOfFunds {
  BUSINESS_OPERATIONS = 'BUSINESS_OPERATIONS',
  CUSTOMER_FUNDS = 'CUSTOMER_FUNDS',
  INVESTMENT_FROM_PARENT_COMPANY = 'INVESTMENT_FROM_PARENT_COMPANY',
  INVESTMENT_FROM_THIRD_PARTY = 'INVESTMENT_FROM_THIRD_PARTY',
  OTHER = 'OTHER',
}

export enum PurposeOfRelationship {
  QUALIFIED_CUSTODIAN_PER_REGULATION = 'QUALIFIED_CUSTODIAN_PER_REGULATION',
  SECURELY_STORE_DIGITAL_ASSETS = 'SECURELY_STORE_DIGITAL_ASSETS',
  YIELD_GENERATION = 'YIELD_GENERATION',
  OTHER = 'OTHER',
}

export enum LastestRiskLevel {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum AddressType {
  REGISTRATION = 'registration',
  CURRENT = 'current',
  BILLING = 'billing',
  ADDRESS = 'address',
}

export enum RoleQuorum {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  VIEWER = 'VIEWER',
}

export enum ContactPersonType {
  GENERAL = 'GENERAL',
  BILLING = 'BILLING',
}

export enum SourceOfWealth {
  BUSINESS_OPERATIONS = 'BUSINESS_OPERATIONS',
  FAMILY_GENERATIONAL_WEALTH = 'FAMILY_GENERATIONAL_WEALTH',
  INVESTMENT_ACTIVITIES = 'INVESTMENT_ACTIVITIES',
  OTHER = 'OTHER',
}
