import { SvgIcon } from 'app/components/SvgIcon';
import {
  commonAttributesColumnTable,
  FORMAT_DATE,
  PER_PAGE,
} from 'constants/index';
import { SortPendingQuestList } from 'enum/customerManager';
import { RecordPendingRequest } from 'interfaces/pendingRequest';
import { Params, RequestStatus } from 'interfaces/request';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  requestActions,
  selectCanLoadMore,
  selectPendingRequestList,
} from 'store/slices/request';
import { history } from 'utils/history';

import NoResultWhenSearch from 'app/components/common/NoResultWhenSearch';
import DetailPendingRequest from 'app/components/DetailPendingRequest';
import RTable from 'app/components/Table';
import clsx from 'clsx';
import {
  ListActionNeedToCheckPermission,
  PAGE,
  TAB_REQUEST,
} from 'enum/common';
import { RequestPendingStatus } from 'enum/request';
import { useLocation } from 'react-router-dom';
import { selectPermissions } from 'store/slices/auth';
import { checkPermissions } from 'utils/common';
import styles from './index.module.scss';

type Props = {
  params: any;
  setValue: any;
  firstRender?: boolean;
  tabRequest: TAB_REQUEST;
};
interface CreatedBy {
  name: string;
}
interface Type {
  value: string;
  nameDisplay: string;
}

const initRequest = {
  id: '',
};

const PendingRequestTable = ({ setValue, params, tabRequest }: Props) => {
  const requestList = useAppSelector(selectPendingRequestList);
  const canLoadMoreStore = useAppSelector(selectCanLoadMore);
  const refCanLoadMoreStore = useRef(canLoadMoreStore);

  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<{ id: string }>(initRequest);
  const dataSource: RecordPendingRequest[] = requestList?.data?.records || [];
  const loading = requestList?.status === RequestStatus.Loading;
  const dispatch = useDispatch();
  const [selectedRowId, setSelectedRowId] = useState('');
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const haveSearch = !!params?.keyword;
  const permissions = useAppSelector(selectPermissions);

  const allowViewRequestDetail = checkPermissions(
    ListActionNeedToCheckPermission.VIEW_REQUEST_DETAIL,
    permissions,
  );

  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get('id');

  const heightHeader =
    document.querySelector('#table')?.getBoundingClientRect()?.y || 0;
  const height = window.innerHeight - heightHeader - 90; //count table height

  const setSort = setValue(Params.Sort);
  const setSortBy = setValue(Params.SortBy);
  const setOffset = setValue(Params.Offset);

  useEffect(() => {
    const isCheck =
      !!params?.dateFrom ||
      !!params?.dateTo ||
      (params?.requestType && params?.requestType?.length > 0) ||
      (params?.requestedBy && params?.requestedBy?.length > 0);
    setIsFilter(!!isCheck);
  }, [params]);

  useEffect(() => {
    refCanLoadMoreStore.current = canLoadMoreStore;
  }, [canLoadMoreStore]);

  useEffect(() => {
    if (!dataSource.length && requestList?.status === RequestStatus.Success) {
      setShowEmptyScreen(true);
    } else {
      setShowEmptyScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestList?.status, requestList?.data]);

  const loadMore = useCallback(
    event => {
      const maxScroll = event.target.scrollHeight - event.target.clientHeight;
      const currentScroll = (event.target.scrollTop + 2).toFixed();
      let offset = params?.offset || 0;
      if (maxScroll === 0 && offset > 0) {
        offset = 0;
      }
      if (
        refCanLoadMoreStore.current &&
        Number(currentScroll) > maxScroll &&
        dataSource.length === offset + PER_PAGE
      ) {
        setOffset(offset + PER_PAGE);
      }
    },
    [dataSource.length, params?.offset, setOffset],
  );

  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (!tableContent) return;
    tableContent.addEventListener('scroll', loadMore);
    return () => tableContent.removeEventListener('scroll', loadMore);
  }, [loadMore]);

  useEffect(() => {
    const length = dataSource.length || 0;
    const count = height / 44; //44 height 1 record
    let offset = Number(params?.offset) + PER_PAGE || PER_PAGE;
    const total = requestList?.data?.total ?? 0;

    if (offset < count && offset < total && length === offset) {
      setOffset(offset);
    }
  }, [dataSource, params?.offset, requestList?.data?.total]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSort(
      sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : undefined,
    );
    setSortBy(sorter.order ? SortPendingQuestList[sorter.field] : undefined);
    setOffset(0);
  };

  const getStatus = (status: RequestPendingStatus) => {
    return {
      [RequestPendingStatus.PENDING]: {
        text: 'Pending',
        className: 'status__pending',
      },
      [RequestPendingStatus.REJECTED]: {
        text: 'Rejected',
        className: 'status__rejected',
      },
      [RequestPendingStatus.APPROVED]: {
        text: 'Approved',
        className: 'status__approved',
      },
    }[status as RequestPendingStatus];
  };

  const renderIcon = (
    sortOrder: string,
    sortColumn: any,
    title: string,
    dataIndex: string,
  ) => {
    return (
      <>
        <div className={styles.title} data-testid={`column-${dataIndex}`}>
          {title}
          {sortOrder && sortColumn?.dataIndex === dataIndex && (
            <div className={styles.icon}>
              <SvgIcon
                name={sortOrder === 'ascend' ? 'arrow-up' : 'arrow-down'}
                width={11}
                height={11}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  const columns: any = [
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Requested date', 'createdAt');
      },
      defaultSortOrder: 'desend',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => {
        return (
          <div data-testid="date">
            {moment(date).format(FORMAT_DATE.DATE_DMY_hM)}
          </div>
        );
      },
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Request ID', 'requestId');
      },
      dataIndex: 'requestId',
      key: 'requestId',
      render: (requestId: string) => {
        return <div className={styles.ellipsis}>{requestId}</div>;
      },
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Request type', 'type');
      },
      dataIndex: 'type',
      key: 'type',
      render: (type: Type) => {
        return <div className={styles.ellipsis}>{type.nameDisplay}</div>;
      },
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Customer name',
          'organizationName',
        );
      },
      dataIndex: 'organizationName',
      key: 'organizationName',
      render: (name: string) => <div className={styles.name}>{name}</div>,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Requested by', 'createdBy');
      },
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy: CreatedBy) => <div>{createdBy.name}</div>,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Status', 'status');
      },
      dataIndex: 'status',
      key: 'status',
      sorter: false,
      render: (status: RequestPendingStatus) => (
        <div
          className={clsx(styles.status, styles[getStatus(status).className])}
        >
          {getStatus(status).text}
        </div>
      ),
    },
    ...(tabRequest === TAB_REQUEST.ALL
      ? [
          {
            ...commonAttributesColumnTable,
            title: ({ sortOrder, sortColumn }: any) => {
              return renderIcon(
                sortOrder,
                sortColumn,
                'Last updated date',
                'updatedAt',
              );
            },
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date: string) => {
              return (
                <div data-testid="last_updated_date">
                  {moment(date).format(FORMAT_DATE.DATE_DMY_hM)}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedRowId('');
    setRequest(initRequest);
    dispatch(requestActions.resetRequestDetail());
    history.replace({
      pathname: window.location.pathname,
    });
  };

  const handleViewDetail = (row: any) => {
    // setIdProfile(id);
    history.replace({
      pathname: window.location.pathname,
      search: `?id=${row.id}`,
    });

    setSelectedRowId(row.id);
    setRequest({
      id: row.id,
    });
    showDrawer();
  };

  useEffect(() => {
    if (requestId) {
      setRequest({
        id: requestId,
      });
      setSelectedRowId(requestId);
      showDrawer();
    }
  }, [requestId]);

  if (showEmptyScreen)
    return (
      <NoResultWhenSearch
        title={isFilter ? 'No matching results' : 'No matching search results'}
        description={isFilter ? '' : 'Try another keyword'}
      />
    );
  return (
    <div data-testid="table-pending-request">
      <RTable
        searchValue={haveSearch}
        isFilter={isFilter}
        currentTableData={dataSource}
        columns={columns}
        loading={loading}
        clickOnRow={allowViewRequestDetail && handleViewDetail}
        height={height}
        selectedRowId={selectedRowId}
        onChange={handleTableChange}
        page={PAGE.PENDING_REQUEST}
      />
      {!!request.id && (
        <DetailPendingRequest id={request.id} open={open} onClose={onClose} />
      )}
    </div>
  );
};

export default PendingRequestTable;
