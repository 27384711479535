import { Button, Col, DatePicker, Form, Row } from 'antd';
import RButton from 'app/components/Button';
import BasicInput from 'app/components/common/BasicInput';
import BasicSelect from 'app/components/common/BasicSelect';
import { SvgIcon } from 'app/components/common/SvgIcon';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import { InvoiceStatus } from 'enum/billing';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectCustomersHasInvoice,
  selectparamsBillingList,
} from 'store/slices/billings';
import {
  formatterNumberThousand,
  isNumberKey,
  revertFormat,
} from 'utils/common';
import styles from './index.module.scss';

const { RangePicker } = DatePicker;
interface Props {
  isOpen: boolean;
  onFinishFilter: (values: any) => void;
}

const defaultFormFilter = {
  customerIds: [],
  feeFrom: '',
  feeTo: '',
  billingPeriod: null,
  status: [],
  dueDate: null,
};

const statusOpts = [
  {
    label: 'Reviewed',
    value: InvoiceStatus.REVIEWED,
  },
  {
    label: 'Unpaid',
    value: InvoiceStatus.UNPAID,
  },
  {
    label: 'Paid',
    value: InvoiceStatus.PAID,
  },
  {
    label: 'Overdue',
    value: InvoiceStatus.OVERDUE,
  },
];

const FilterBilling = ({ isOpen, onFinishFilter }: Props) => {
  const [form] = Form.useForm();
  const paramsBilling = useAppSelector(selectparamsBillingList);

  const [isDisabledClearAll, setIsDisabledClearAll] = useState<boolean>(true);
  const [isReset, setIsReset] = useState<boolean>(false);

  const customer = useAppSelector(selectCustomersHasInvoice).data;
  const [isErrorFeeFrom, setIsErrorFeeFrom] = useState<boolean>(false);
  const [isErrorFeeTo, setIsErrorFeeTo] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasSomeParameters = (values: any) => {
    const { customerIds, billingPeriod, feeTo, feeFrom, status, dueDate } =
      values;

    const hasCustomerIds = customerIds?.length;
    const hasStatus = status?.length;
    const hasBillingPeriod = billingPeriod?.some(Boolean);
    const hasDueDate = dueDate?.some(Boolean);

    return (
      hasCustomerIds ||
      hasBillingPeriod ||
      hasDueDate ||
      hasStatus ||
      !!feeFrom ||
      !!feeTo
    );
  };

  useEffect(() => {
    if (!hasSomeParameters || isDisabledClearAll || !isOpen) {
      setIsErrorFeeFrom(false);
      setIsErrorFeeTo(false);
    }
  }, [hasSomeParameters, isDisabledClearAll, isOpen]);

  useEffect(() => {
    if (isReset) {
      setIsDisabledClearAll(true);
    }
  }, [isReset]);

  useEffect(() => {
    if (isOpen) {
      const {
        customerIds,
        feeTo,
        feeFrom,
        dateFrom,
        dateTo,
        dueDateFrom,
        dueDateTo,
        status,
      } = paramsBilling;

      const data = {
        customerIds:
          typeof customerIds === 'string' ? customerIds?.split(',') : [],
        billingPeriod: [
          dateFrom ? moment(dateFrom) : null,
          dateTo ? moment(dateTo) : null,
        ],
        feeFrom,
        feeTo,
        dueDate: [
          dueDateFrom ? moment(dueDateFrom) : null,
          dueDateTo ? moment(dueDateTo) : null,
        ],
        status: typeof status === 'string' ? status?.split(',') : [],
      };

      form.setFieldsValue(data);
      const hasSomeValue = hasSomeParameters(data);
      setIsDisabledClearAll(!hasSomeValue);
      setIsReset(false);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsBilling, isOpen]);

  const onValuesChange = useCallback((_, values) => {
    setIsReset(false);
    const hasSomeValue = hasSomeParameters(values);
    setIsDisabledClearAll(!hasSomeValue);
  }, []);

  const onClickReset = () => {
    setIsReset(true);
    form.setFieldsValue(defaultFormFilter);
  };

  const onFinish = (values: any) => {
    const { customerIds, feeTo, feeFrom, billingPeriod, dueDate, status } =
      values;

    const startOfMonth = billingPeriod
      ? moment(billingPeriod[0]).startOf('month').toISOString()
      : undefined;
    const endOfMonth = billingPeriod
      ? moment(billingPeriod[1]).endOf('month').toISOString()
      : undefined;

    const dueDateFrom =
      dueDate && dueDate.length
        ? moment(dueDate[0]).startOf('day').toISOString()
        : undefined;
    const dueDateTo =
      dueDate && dueDate.length
        ? moment(dueDate[1]).endOf('day').toISOString()
        : undefined;

    const dataFilter = {
      customerIds: !!customerIds && customerIds.join(','),
      dateFrom: startOfMonth,
      dateTo: endOfMonth,
      feeFrom: feeFrom === 0 ? 0 : feeFrom ? revertFormat(feeFrom) : undefined,
      feeTo: feeTo === 0 ? 0 : feeTo ? revertFormat(feeTo) : undefined,
      dueDateFrom: dueDateFrom,
      dueDateTo: dueDateTo,
      status: !!status && status.join(','),
    };

    // if (!isErrorFeeFrom && !isErrorFeeTo) {
    onFinishFilter(dataFilter);
    // }
  };

  const disabledDate = (current: Moment) => {
    return current && current.valueOf() > Date.now();
  };

  const checkFeeFrom = useCallback(
    e => {
      const feeTo = form.getFieldValue('feeTo');
      const { value } = e.target;
      if (feeTo && !!value && revertFormat(value) > revertFormat(feeTo)) {
        form.setFields([
          {
            name: 'feeFrom',
            errors: ['You must input equal or lower value than the one in To'],
          },
        ]);
        setIsErrorFeeFrom(true);
      } else {
        form.setFields([
          {
            name: 'feeFrom',
            errors: [],
          },
        ]);
        setIsErrorFeeFrom(false);
      }
    },
    [form],
  );

  const checkFeeTo = e => {
    const feeFrom = form.getFieldValue('feeFrom');
    const { value } = e.target;
    if (feeFrom && !!value && revertFormat(value) < revertFormat(feeFrom)) {
      form.setFields([
        {
          name: 'feeTo',
          errors: ['You must input equal or higher value than the one in From'],
        },
      ]);
      setIsErrorFeeTo(true);
    } else {
      form.setFields([
        {
          name: 'feeTo',
          errors: [],
        },
      ]);
      setIsErrorFeeTo(false);
    }
  };

  const onChangeInput = (e: any, name: string) => {
    let value = formatterNumberThousand(e.target.value);
    form.setFieldsValue({ [name]: value });
  };

  const onSubmit = event => {
    if (isErrorFeeFrom || isErrorFeeTo) {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.container}>
      <Form
        form={form}
        initialValues={defaultFormFilter}
        layout="vertical"
        name="billingFilter"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <Row>
          <Col span={12} className={styles.title}>
            Filter
          </Col>
          <Col span={12} className={styles.reset}>
            <Button
              data-testid="reset-button"
              type="link"
              onClick={onClickReset}
              className={clsx({
                [styles.disabled__button]: isDisabledClearAll,
              })}
              disabled={isDisabledClearAll}
            >
              Clear all
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span="24" data-testid="customerName">
            <BasicSelect
              className={styles.customerName}
              options={(customer && customer.customers) ?? []}
              label="Customer name"
              name="customerIds"
              allowClear
              labelValue="customerName"
              placeholder="Select"
              keyValue="id"
              mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
        </Row>
        <div>
          <span className={styles.label}>Billing period</span>
          <Row style={{ marginTop: '8px' }}>
            <Col span={24} data-testid="billing=period">
              <Form.Item name="billingPeriod">
                <RangePicker
                  picker="month"
                  className={styles.datePicker}
                  popupStyle={{ borderRadius: 8 }}
                  style={{ borderRadius: 8 }}
                  format={FORMAT_DATE.MONTH_YEAR}
                  dropdownClassName={styles.dropdown__datepicker}
                  placeholder={['From', 'To (optional)']}
                  allowEmpty={[false, true]}
                  disabledDate={disabledDate}
                  allowClear
                  separator={
                    <SvgIcon name="grey-right-arrow" width={12} height={12} />
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <span className={styles.label}>Due date</span>
          <Row style={{ marginTop: '8px' }}>
            <Col span={24} data-testid="due-date">
              <Form.Item name="dueDate">
                <RangePicker
                  className={styles.datePicker}
                  popupStyle={{ borderRadius: 8 }}
                  style={{ borderRadius: 8 }}
                  format={FORMAT_DATE.DEFAULT}
                  dropdownClassName={styles.dropdown__datepicker}
                  placeholder={['From', 'To (optional)']}
                  allowEmpty={[true, true]}
                  disabledDate={disabledDate}
                  allowClear
                  separator={
                    <SvgIcon name="grey-right-arrow" width={12} height={12} />
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <span className={styles.label}>Fee (USD)</span>
          <div className={styles.divFee}>
            <div>
              <BasicInput
                autoFocus={isErrorFeeFrom}
                id="feeFrom"
                name="feeFrom"
                placeholder="From"
                maxLength={50}
                onChange={(e: any) => {
                  onChangeInput(e, 'feeFrom');
                  checkFeeFrom(e);
                }}
                onKeyDown={(event: any) => {
                  if (!isNumberKey(event)) {
                    event.preventDefault();
                  }

                  return true;
                }}
                autoComplete="off"
              />
            </div>
            <div>
              <SvgIcon
                name="arrow-right"
                width={16}
                height={16}
                fill="#7b7b84"
                className={styles.iconArrowRight}
              />
            </div>
            <div>
              <BasicInput
                autoFocus={isErrorFeeTo}
                id="feeTo"
                name="feeTo"
                placeholder="To (optional)"
                maxLength={50}
                onChange={(e: any) => {
                  onChangeInput(e, 'feeTo');
                  checkFeeTo(e);
                }}
                onKeyDown={(event: any) => {
                  if (!isNumberKey(event)) {
                    event.preventDefault();
                  }

                  return true;
                }}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <Row>
          <Col span="24" data-testid="status">
            <BasicSelect
              className={styles.customerName}
              options={statusOpts}
              label="Status"
              name="status"
              allowClear
              placeholder="Select"
              mode="multiple"
            />
          </Col>
        </Row>

        <Row style={{ justifyContent: 'end' }} data-testid="submit-button">
          <RButton
            htmlType="submit"
            buttonType="submit"
            content="Apply"
            width={75}
            height={32}
            onClick={onSubmit}
            style={{ padding: '0', marginTop: '10px' }}
          />
        </Row>
      </Form>
    </div>
  );
};

export default FilterBilling;
