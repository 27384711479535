import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortWcAppEntity, GroupByField, CustomerOnScreen } from 'enum/wcAppEntity.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetWcAppEntityRequestParamDTO,
  GetWcAppEntityResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IWcAppEntityDetail,
} from 'interfaces/wcAppEntity';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface WcAppEntityState {
  params: GetWcAppEntityRequestParamDTO;
  wcAppEntity: ResponseData<GetWcAppEntityResponseDTO>;
  wcAppEntityPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  wcAppEntityDetail: ResponseData<IWcAppEntityDetail>;
}
export function getDefaultWcAppEntityRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortWcAppEntity.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: WcAppEntityState = {
  params: getDefaultWcAppEntityRequestParams(),
  wcAppEntity: {
    status: RequestStatus.Idle,
  },
  wcAppEntityPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ROLE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  wcAppEntityDetail: {
    status: RequestStatus.Idle,
  },
};
const getWcAppEntityRequest: CaseReducer<WcAppEntityState, PayloadAction<any>> = (
  state: WcAppEntityState,
) => {
  state.wcAppEntity.status = RequestStatus.Loading;
};
const getWcAppEntitySuccess: CaseReducer<
  WcAppEntityState,
  PayloadAction<GetWcAppEntityResponseDTO>
> = (state: WcAppEntityState, { payload }: PayloadAction<GetWcAppEntityResponseDTO>) => {
  state.wcAppEntity.status = RequestStatus.Success;
  state.wcAppEntity.data = payload;
};
const getWcAppEntityFailed: CaseReducer<WcAppEntityState> = (state: WcAppEntityState) => {
  state.wcAppEntity.status = RequestStatus.Failed;
};
const getDetailWcAppEntityRequest: CaseReducer<WcAppEntityState, PayloadAction<string>> = (
  state: WcAppEntityState,
) => {
  state.wcAppEntityDetail.status = RequestStatus.Loading;
};
const getDetailWcAppEntitySuccess: CaseReducer<
  WcAppEntityState,
  PayloadAction<IWcAppEntityDetail>
> = (state: WcAppEntityState, { payload }: PayloadAction<IWcAppEntityDetail>) => {
  state.wcAppEntityDetail.status = RequestStatus.Success;
  state.wcAppEntityDetail.data = payload;
};
const getDetailWcAppEntityFailed: CaseReducer<WcAppEntityState> = (state: WcAppEntityState) => {
  state.wcAppEntityDetail.status = RequestStatus.Failed;
};
const createWcAppEntityRequest: CaseReducer<WcAppEntityState, PayloadAction<IWcAppEntityDetail>> = (
  state: WcAppEntityState,
) => {
  state.wcAppEntityDetail.status = RequestStatus.Loading;
};
const createWcAppEntitySuccess: CaseReducer<WcAppEntityState, PayloadAction<IWcAppEntityDetail>> = (
  state: WcAppEntityState,
  { payload }: PayloadAction<IWcAppEntityDetail>,
) => {
  state.wcAppEntityDetail.status = RequestStatus.Success;
  state.wcAppEntityDetail.data = payload;
};
const createWcAppEntityFailed: CaseReducer<WcAppEntityState> = (state: WcAppEntityState) => {
  state.wcAppEntityDetail.status = RequestStatus.Failed;
};
const updateWcAppEntityRequest: CaseReducer<WcAppEntityState, PayloadAction<IWcAppEntityDetail>> = (
  state: WcAppEntityState,
) => {
  state.wcAppEntityDetail.status = RequestStatus.Loading;
};
const updateWcAppEntitySuccess: CaseReducer<WcAppEntityState, PayloadAction<IWcAppEntityDetail>> = (
  state: WcAppEntityState,
  { payload }: PayloadAction<IWcAppEntityDetail>,
) => {
  state.wcAppEntityDetail.status = RequestStatus.Success;
  state.wcAppEntityDetail.data = payload;
};
const updateWcAppEntityFailed: CaseReducer<WcAppEntityState> = (state: WcAppEntityState) => {
  state.wcAppEntityDetail.status = RequestStatus.Failed;
};
const appendWcAppEntity: CaseReducer<WcAppEntityState, PayloadAction<any>> = (
  state: WcAppEntityState,
  { payload }: PayloadAction<any>,
) => {
  if (state.wcAppEntity?.data?.listWcAppEntity) {
    state.wcAppEntity.status = RequestStatus.Success;
    state.wcAppEntity.data.listWcAppEntity = [...payload];
  }
};
const setCanLoadMore = (state: WcAppEntityState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetWcAppEntity: CaseReducer<WcAppEntityState> = (state: WcAppEntityState) => {
  state.wcAppEntity = {
    status: RequestStatus.Idle,
  };
};
const resetWcAppEntityParams: CaseReducer<WcAppEntityState> = (state: WcAppEntityState) => {
  state.params = getDefaultWcAppEntityRequestParams();
};
const setWcAppEntityFilterParams = (
  state: WcAppEntityState,
  { payload }: PayloadAction<GetWcAppEntityRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  WcAppEntityState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: WcAppEntityState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<WcAppEntityState, PayloadAction<any>> = (
  state: WcAppEntityState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<WcAppEntityState> = (state: WcAppEntityState) => {
  state.action.status = RequestStatus.Failed;
};
const getWcAppEntityPaginationConfigRequest: CaseReducer<
  WcAppEntityState,
  PayloadAction<any>
> = (state: WcAppEntityState) => {
  state.wcAppEntityPaginationConfig.status = RequestStatus.Loading;
};
const getWcAppEntityPaginationConfigSuccess: CaseReducer<
  WcAppEntityState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: WcAppEntityState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.wcAppEntityPaginationConfig.status = RequestStatus.Success;
  state.wcAppEntityPaginationConfig.data = payload;
};
const getWcAppEntityPaginationConfigFailed: CaseReducer<WcAppEntityState> = (
  state: WcAppEntityState,
) => {
  state.wcAppEntityPaginationConfig.status = RequestStatus.Failed;
};
const wcAppEntitySlice = createSlice({
  name: 'wcAppEntity',
  initialState,
  reducers: {
    getWcAppEntityRequest,
    getWcAppEntitySuccess,
    getWcAppEntityFailed,
    getDetailWcAppEntityRequest,
    getDetailWcAppEntitySuccess,
    getDetailWcAppEntityFailed,
    createWcAppEntityRequest,
    createWcAppEntitySuccess,
    createWcAppEntityFailed,
    updateWcAppEntityRequest,
    updateWcAppEntitySuccess,
    updateWcAppEntityFailed,
    appendWcAppEntity,
    setCanLoadMore,
    resetWcAppEntity,
    setWcAppEntityParams<K extends keyof GetWcAppEntityRequestParamDTO>(
      state: WcAppEntityState,
      action: PayloadAction<{
        key: K;
        value: GetWcAppEntityRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setWcAppEntityFilterParams,
    resetWcAppEntityParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getWcAppEntityPaginationConfigRequest,
    getWcAppEntityPaginationConfigSuccess,
    getWcAppEntityPaginationConfigFailed,
  },
});
// Actions
export const wcAppEntityActions = wcAppEntitySlice.actions;
// Reducer
export const wcAppEntityReducer = wcAppEntitySlice.reducer;
// Selectors
export const selectWcAppEntity = (state: AppState) => state.wcAppEntity?.wcAppEntity;
export const selectParamWcAppEntity = (state: AppState) => state.wcAppEntity?.params;
export const selectCanLoadMoreWcAppEntity = (state: AppState) =>
  state.wcAppEntity?.canLoadMore;
export const selectWcAppEntityDetail = (state: AppState) => state.wcAppEntity?.wcAppEntityDetail;
export const selectWcAppEntityPagination = (state: AppState) =>
  state.wcAppEntity?.wcAppEntityPaginationConfig;
