import { ReactNode } from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';

interface Props {
  buttonType: string;
  width?: number;
  height?: number;
  content: ReactNode | string;
  onClick?: (event) => void;
  disable?: boolean;
  style?: React.CSSProperties | undefined;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  dataTestId?: string;
  loading?: boolean;
  className?: any;
}

const RButton = ({
  buttonType,
  content,
  width,
  height,
  disable,
  htmlType = undefined,
  dataTestId,
  style,
  loading,
  className,
  ...props
}: Props) => {
  return (
    <>
      <Button
        {...props}
        htmlType={htmlType}
        style={{ ...style, width: `${width}px`, height: `${height}px` }}
        className={clsx(styles.button, className, {
          [styles.button__back]: buttonType === 'back',
          [styles.button__cancel]: buttonType === 'cancel',
          [styles.button__disable]: disable,
        })}
        disabled={disable}
        data-testid={dataTestId}
        loading={loading}
      >
        {content}
      </Button>
    </>
  );
};

export default RButton;
