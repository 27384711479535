import { KEY_CODE } from 'constants/index';
import { isNumberKey } from './common';

export const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
  if (event.which === KEY_CODE.ENTER || event.keyCode === KEY_CODE.ENTER) {
    event.preventDefault();
    return;
  }
};

export const handleNumberKeyDown = (event: any) => {
  if (!isNumberKey(event)) {
    event.preventDefault();
  }

  return true;
};
