import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getVaultWcRequest,
  getDetailVaultWc,
  createVaultWcRequest,
  updateVaultWcRequest,
  getVaultWcRequestPaginationConfig,
} from 'services/vaultWc.service';
import { vaultWcActions } from 'store/slices/vaultWc/vaultWc.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetVaultWcRequestParamDTO, ICreateVaultWc } from 'interfaces/vaultWc';
const handleGetVaultWcRequest = function* ({
  payload,
}: PayloadAction<GetVaultWcRequestParamDTO>) {
  try {
    const res = yield call(getVaultWcRequest, payload);
    const params = yield select(state => state.vaultWc.params) as any;
    const listVaultWc = yield select(
      state => state.vaultWc?.vaultWc?.data?.listVaultWc,
    ) as any;
    if (res) {
      yield put(
        vaultWcActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listVaultWc || []), ...res.list];
        yield put(vaultWcActions.appendVaultWc(newList));
      } else {
        yield put(
          vaultWcActions.getVaultWcSuccess({
            listVaultWc: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(vaultWcActions.getVaultWcFailed());
  }
};
const handleGetDetailVaultWcRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailVaultWc, payload);
    if (res) {
      yield put(vaultWcActions.getDetailVaultWcSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(vaultWcActions.getDetailVaultWcFailed());
  }
};
const handleCreateVaultWcRequest = function* ({
  payload,
}: PayloadAction<ICreateVaultWc>) {
  try {
    const res = yield call(createVaultWcRequest, payload);
    if (res) {
      yield put(vaultWcActions.createVaultWcSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(vaultWcActions.createVaultWcFailed());
  }
};
const handleUpdateVaultWcRequest = function* ({
  payload,
}: PayloadAction<ICreateVaultWc>) {
  try {
    const res = yield call(updateVaultWcRequest, payload);
    if (res) {
      yield put(vaultWcActions.updateVaultWcSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(vaultWcActions.updateVaultWcFailed());
  }
};
const handleGetVaultWcPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetVaultWcRequestParamDTO>) {
  try {
    const res = yield call(getVaultWcRequestPaginationConfig);
    if (res) {
      yield put(vaultWcActions.getVaultWcPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(vaultWcActions.getVaultWcPaginationConfigFailed());
  }
};
const vaultWcSaga = function* () {
  yield takeLatest(
    vaultWcActions.getVaultWcRequest.type,
    handleGetVaultWcRequest,
  );
  yield takeLatest(
    vaultWcActions.createVaultWcRequest.type,
    handleCreateVaultWcRequest,
  );
  yield takeLatest(
    vaultWcActions.updateVaultWcRequest.type,
    handleUpdateVaultWcRequest,
  );
  yield takeLatest(
    vaultWcActions.getVaultWcPaginationConfigRequest.type,
    handleGetVaultWcPaginationConfigRequest,
  );
  yield takeLatest(
    vaultWcActions.getDetailVaultWcRequest.type,
    handleGetDetailVaultWcRequest,
  );
};
export default vaultWcSaga;
