import debounce from 'debounce-promise';
import { getWcApplicationRequest } from 'services/wcApplication.service';

export type WcApplicationCheckData = {
  name: string;
  externalId: string;
  url: string;
};

export const validateWcApplicationValuesExist = async (
  name: keyof WcApplicationCheckData,
  value: string,
) => {
  const response = await getWcApplicationRequest({
    where: { AND: [{ [`${name}`]: { IS_IGNORE_CASE: value || '' } }] },
  });

  return Boolean(response.list?.length);
};

export const debounceValidateWcApplicationValuesExist = debounce(
  validateWcApplicationValuesExist,
  500,
);
