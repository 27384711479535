import BasicIconSort from 'app/components/common/BasicIconSort';
import NoResultWhenSearch from 'app/components/common/NoResultWhenSearch';
import RakkarStatusButton from 'app/components/common/RakkarStatusButton';
import RTable from 'app/components/Table';
import clsx from 'clsx';
import {
  commonAttributesColumnTable,
  FORMAT_DATE,
  PER_PAGE,
} from 'constants/index';
import { InvoiceStatus, SortBillingListTable } from 'enum/billing';
import { SortDirection, TAB_REQUEST } from 'enum/common';
import { BillingParamsRequest, BillingRecord } from 'interfaces/billings';
import { Params, RequestStatus } from 'interfaces/request';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  billingsActions,
  selectBillingList,
  selectCanLoadMoreBillings,
  selectparamsBillingList,
} from 'store/slices/billings';
import { isHistoryPage } from 'utils/common';
import { formatCurrencyTypeB } from 'utils/helpers';
import BillingDetail from '../BillingDetail';
import styles from './index.module.scss';

type Props = {
  hasSearchOrFilter?: boolean;
  showEmptyScreen?: boolean;
  isHasFilter?: boolean;
  tabRequest?: string;
};

interface refSort {
  sortBy: SortBillingListTable;
  sort: SortDirection | undefined;
}

const ListBilling = ({ showEmptyScreen, isHasFilter, tabRequest }: Props) => {
  const dispatch = useDispatch();
  const billingList = useAppSelector(selectBillingList);
  const params = useAppSelector(selectparamsBillingList);
  const canLoadMore = useAppSelector(selectCanLoadMoreBillings);
  const [billings, setBillings] = useState<BillingRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const refCanLoadMoreStore = useRef(canLoadMore);
  const [open, setOpen] = useState(false);
  const itemSelected = useRef<BillingRecord | undefined>();
  const [firstRender, setFirstRender] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState('');
  const billingHistoryPage = isHistoryPage();
  const refBillingHistoryPage = useRef(billingHistoryPage);
  const refSort = useRef<refSort>({
    sortBy: SortBillingListTable.customerName,
    sort: SortDirection.ASC,
  });

  const heightHeader =
    document.querySelector('#table-billing')?.getBoundingClientRect()?.y || 0;
  const height = window.innerHeight - heightHeader - 260; //count table height

  const setValue = (key: keyof BillingParamsRequest) => {
    return (value: BillingParamsRequest[keyof BillingParamsRequest]) => {
      dispatch(
        billingsActions.setBillingParam({
          key,
          value,
        }),
      );
    };
  };

  const setSort = setValue(Params.Sort);
  const setSortBy = setValue(Params.SortBy);
  const setOffset = setValue(Params.Offset);

  useEffect(() => {
    refCanLoadMoreStore.current = canLoadMore;
  }, [canLoadMore]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  useEffect(() => {
    //handle resetting sort to default on page change(Billing <-> Billing History)
    const elmColumnCustomerName = document.getElementById('customerName');
    const allowClick = // case: sort customer name (DESC) or sort another column clickable reset sort to default
      (refSort.current.sortBy === SortBillingListTable.customerName &&
        refSort.current.sort !== SortDirection.ASC) ||
      refSort.current.sortBy !== SortBillingListTable.customerName;
    if (
      refBillingHistoryPage.current !== billingHistoryPage &&
      !firstRender &&
      elmColumnCustomerName &&
      allowClick
    ) {
      elmColumnCustomerName.click();
    }
    if (refBillingHistoryPage.current !== billingHistoryPage) {
      refBillingHistoryPage.current = billingHistoryPage;
    }
  }, [firstRender, billingHistoryPage]);

  useEffect(() => {
    dispatch(
      billingsActions.getBillingListRequest({
        isHistory: tabRequest === TAB_REQUEST.ALL,
        ...params,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (billingList?.data?.customerBillings) {
      setBillings(billingList?.data?.customerBillings);
    }
    if (billingList?.status === RequestStatus.Loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [billingList]);

  const loadMore = useCallback(
    event => {
      const maxScroll = event.target.scrollHeight - event.target.clientHeight;
      const currentScroll = (event.target.scrollTop + 2).toFixed();
      let offset = params?.offset || 0;
      if (maxScroll === 0 && offset > 0) {
        offset = 0;
      }
      if (
        refCanLoadMoreStore.current &&
        Number(currentScroll) > maxScroll &&
        billings.length === offset + PER_PAGE
      ) {
        setOffset(offset + PER_PAGE);
      }
    },
    [billings.length, params?.offset, setOffset],
  );

  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (!tableContent) return;
    tableContent.addEventListener('scroll', loadMore);
    return () => tableContent.removeEventListener('scroll', loadMore);
  }, [loadMore]);

  useEffect(() => {
    const length = billings.length || 0;
    const count = height / 46; //46 height 1 record
    let offset = Number(params?.offset) + PER_PAGE || PER_PAGE;
    const total = billingList?.data?.totalCount ?? 0;

    if (offset < count && offset < total && length === offset) {
      setOffset(offset);
    }
  }, [billings, params?.offset]);

  const handleStatus = status => {
    if (status === InvoiceStatus.INITIAL || status === InvoiceStatus.PENDING) {
      return InvoiceStatus.PENDING;
    }
    return status;
  };

  const renderIcon = (
    sortOrder: string,
    sortColumn: any,
    title: string,
    dataIndex: string,
    align = 'left' as string,
  ) => {
    return (
      <>
        <div
          className={clsx(styles.title, {
            [styles.titleHeaderRight]: align === 'right',
          })}
          data-testid={`column-${dataIndex}`}
          id={dataIndex}
        >
          <span>{title}</span>
          {sortOrder && sortColumn?.dataIndex === dataIndex && (
            <BasicIconSort sortOrder={sortOrder} />
          )}
        </div>
      </>
    );
  };

  const columns: any = [
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Invoice number',
          'invoiceNumber',
        );
      },
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      render: (invoiceNumber: string) => {
        return <div className={styles.invoiceNumber}>{invoiceNumber}</div>;
      },
      width: 180,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Billing period',
          'billingPeriod',
        );
      },
      dataIndex: 'billingPeriod',
      key: 'billingPeriod',
      render: (_, record) => {
        return (
          <div className={styles.ellipsis}>{`${moment(record.dateFrom).format(
            FORMAT_DATE.DEFAULT,
          )} - ${moment(record.dateTo).format(FORMAT_DATE.DEFAULT)}`}</div>
        );
      },
    },
    {
      ...(billingHistoryPage
        ? {
            ...commonAttributesColumnTable,
            title: ({ sortOrder, sortColumn }: any) => {
              return renderIcon(sortOrder, sortColumn, 'Due date', 'dueDate');
            },
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (_, record) => {
              return (
                <div className={styles.ellipsis}>
                  {moment(record.dueDate).format(FORMAT_DATE.DEFAULT)}
                </div>
              );
            },
          }
        : {}),
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Customer name',
          'customerName',
        );
      },
      defaultSortOrder: 'ascend',
      dataIndex: 'customerName',
      key: 'customerName',
      render: (customerName: string) => {
        return <div className={styles.name}>{customerName}</div>;
      },
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Fee (USD)', 'fee', 'right');
      },
      dataIndex: 'fee',
      key: 'fee',
      align: 'right',
      render: (fee: number) => (
        <div className={styles.amount}>{formatCurrencyTypeB(fee)}</div>
      ),
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Status', 'status');
      },
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return (
          <div>
            <RakkarStatusButton status={handleStatus(status)} />
          </div>
        );
      },
      width: 150,
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortOrder = sorter.order
      ? sorter.order === 'ascend'
        ? 'ASC'
        : 'DESC'
      : undefined;
    setSort(sortOrder);

    const sortBy = sorter.order
      ? SortBillingListTable[sorter.field]
      : undefined;
    setSortBy(sortBy);

    refSort.current = {
      sortBy: sortBy,
      sort: sortOrder ? SortDirection[sortOrder] : undefined,
    };
    setOffset(0);
  };

  const handleClickOnRow = (values: any) => {
    if (values) {
      setOpen(true);
      itemSelected.current = values;
      setSelectedRowId(values.id);
    }
  };

  if (showEmptyScreen)
    return (
      <NoResultWhenSearch
        title={
          isHasFilter ? 'No matching results' : 'No matching search results'
        }
        description={isHasFilter ? '' : 'Try another keyword'}
      />
    );

  return (
    <div className={styles.table} data-testid="table">
      <RTable
        currentTableData={billings}
        columns={columns}
        loading={loading}
        height={height}
        selectedRowId={selectedRowId}
        clickOnRow={handleClickOnRow}
        onChange={handleTableChange}
        searchValue={params.keyword}
      />
      {open && itemSelected.current && (
        <BillingDetail
          open={open}
          setOpen={setOpen}
          itemSelected={itemSelected.current}
          setSelectedRowId={setSelectedRowId}
          tabRequest={tabRequest}
        />
      )}
    </div>
  );
};

export default ListBilling;
