import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  Tag,
} from 'antd';
import {
  DynamicCondition,
  DynamicOperation,
  IRakCRUDBaseControllerConfig,
} from 'interfaces/dynamicFilter';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getLS, removeLS, setLS } from 'services/localStorageSvc';

const minWidth = '200px';

const DynamicFilterOption = ({
  key,
  name,
  add,
  remove,
  filter,
  form,
  ...restField
}: {
  from?: FormInstance<any>;
  [key: string]: any;
}) => {
  const formValues = form.getFieldsValue();
  const [counter, setCounter] = useState(0);
  const _key = formValues?.filter?.[name]?._key;
  const _operation = formValues?.filter?.[name]?.operation;

  const { options = [] } = _key ? filter?.[_key] || {} : {};
  const { operation: operation_, options: options_ } =
    options.find(({ operation }) => operation === _operation) || {};
  return (
    <div key={counter}>
      <Space
        key={key}
        style={{ display: 'flex', marginBottom: 8 }}
        align="baseline"
      >
        <Form.Item
          {...restField}
          name={[name, '_key']}
          rules={[{ required: true, message: 'Missing key' }]}
        >
          <Select
            style={{ minWidth }}
            onChange={v => {
              form.setFieldValue(
                ['filter', name, 'key'],
                filter?.[v]?.key || v,
              );
              setCounter(counter + 1);
            }}
          >
            {Object.keys(filter).map(k => {
              return (
                <Select.Option id={k} value={k}>
                  {k}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          {...restField}
          hidden
          name={[name, 'key']}
          // rules={[{ required: true, message: 'Missing value' }]}
        >
          <Input placeholder="value" />
        </Form.Item>

        {_key && (
          <>
            <Form.Item
              {...restField}
              name={[name, 'operation']}
              rules={[{ required: true, message: 'Missing operation' }]}
            >
              <Select
                style={{ minWidth }}
                onChange={() => {
                  setCounter(counter + 1);
                }}
              >
                {options?.map(({ operation }: any) => {
                  return (
                    <Select.Option id={operation} value={operation}>
                      {operation}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            {_operation && (
              <>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  rules={[{ required: true, message: 'Missing value' }]}
                >
                  {[DynamicOperation.IN, DynamicOperation.NOT_IN].includes(
                    operation_,
                  ) ? (
                    <Select
                      mode="tags"
                      style={{ minWidth }}
                      onChange={() => {
                        setCounter(counter + 1);
                      }}
                    >
                      {options_?.map(({ key, label }: any) => {
                        return (
                          <Select.Option id={label} value={key}>
                            {label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Input placeholder="value" />
                  )}
                </Form.Item>
              </>
            )}
          </>
        )}
        <MinusCircleOutlined onClick={() => remove(name)} />
      </Space>
    </div>
  );
};

const DynamicSortOption = ({
  key,
  name,
  add,
  remove,
  filter,
  form,
  ...restField
}: {
  from?: FormInstance<any>;
  [key: string]: any;
}) => {
  return (
    <Space
      key={key}
      style={{ display: 'flex', marginBottom: 8 }}
      align="baseline"
    >
      <Form.Item
        {...restField}
        name={[name, '_sort']}
        rules={[{ required: true, message: 'Missing key' }]}
      >
        <Select
          style={{ minWidth }}
          onChange={v => {
            form.setFieldValue(['order', name, 'sort'], filter?.[v]?.key || v);
          }}
        >
          {Object.keys(filter).map(k => {
            return (
              <Select.Option id={k} value={k}>
                {k}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        {...restField}
        name={[name, 'order']}
        rules={[{ required: true, message: 'order' }]}
      >
        <Select style={{ minWidth }}>
          {['ASC', 'DESC'].map(k => {
            return (
              <Select.Option id={k} value={k}>
                {k}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <MinusCircleOutlined onClick={() => remove(name)} />
    </Space>
  );
};
export interface IDynamicFilter {
  setParams: Function;
  where?: any;
  order?: any;
  paginationConfig: any;
}
const DynamicFilter = (props: IDynamicFilter) => {
  const [form] = Form.useForm();
  const [counter, setCounter] = useState(0);
  const [collapse, setCollapse] = useState(true);
  const { paginationConfig = {}, where, order, setParams } = props;
  const { data = {} } = paginationConfig;
  const { condition = [], filter = {} }: IRakCRUDBaseControllerConfig = data;
  const onFinish = (values: any) => {
    const { condition = 'AND', filter = [], order = [] } = values;
    setParams({
      where: {
        [condition]: filter?.map(({ key, operation, value }) => ({
          [key]: {
            [operation]: value,
          },
        })),
      },
      order,
    });
  };
  const cacheFilter = getLS(`DF_${window.location.href}`);
  console.debug(
    `🚀 ${new Date().toISOString()} ~ file: index.tsx:236 ~ DynamicFilter ~ cacheFilter:`,
    cacheFilter,
  );
  const initialValues = {
    condition: DynamicCondition.AND,
    filter: [],
    order: [],
  };
  const formValues = form.getFieldsValue();
  console.debug(
    `🚀 ${new Date().toISOString()} ~ file: index.tsx:241 ~ DynamicFilter ~ formValues:`,
    formValues,
  );
  return (
    <>
      <Form
        key={counter}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        form={form}
        style={{ maxWidth: 600 }}
        initialValues={cacheFilter?.date ? cacheFilter?.value : initialValues}
        autoComplete="off"
      >
        {collapse && (
          <div onClick={() => setCollapse(false)} style={{ cursor: 'pointer' }}>
            Filter:{' '}
            {formValues?.filter?.map((item, i) => (
              <Tag key={i} color="blue">
                {item?._key} {item?.operation} "{item?.value}"
              </Tag>
            ))}
            <br />
            Order:{' '}
            {formValues?.order?.map((item, i) => (
              <Tag key={i} color="blue">
                {item?._sort} {item?.order}
              </Tag>
            ))}
          </div>
        )}
        <div
          style={
            collapse
              ? {
                  opacity: 0,
                  height: 5,
                  pointerEvents: 'none',
                }
              : { opacity: 1 }
          }
        >
          <Button
            type="link"
            onClick={() => {
              setCollapse(!collapse);
            }}
          >
            {collapse ? 'More' : 'Collapse'}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setLS(`DF_${window.location.href}`, {
                value: form.getFieldsValue(),
                date: moment().format(),
              });
              setCounter(counter + 1);
            }}
          >
            Save
          </Button>
          {cacheFilter?.date && (
            <>
              <Button
                type="dashed"
                onClick={() => {
                  form.setFieldsValue(initialValues);
                  setTimeout(() => {
                    form.submit();
                    setCounter(counter + 1);
                  }, 100);
                }}
              >
                Clear
              </Button>
              <Button
                type="default"
                onClick={() => {
                  form.setFieldsValue(
                    getLS(`DF_${window.location.href}`)?.value,
                  );
                  setCounter(counter + 1);
                  form.submit();
                }}
              >
                Load {cacheFilter?.date}
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => {
                  removeLS(`DF_${window.location.href}`);
                  setCounter(counter + 1);
                }}
              >
                Delete
              </Button>
            </>
          )}
          <Form.Item name={'condition'}>
            <Select style={{ minWidth }}>
              {Object.keys(condition).map(k => {
                return (
                  <Select.Option id={condition[k]} value={condition[k]}>
                    {condition[k]}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.List name="filter">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <DynamicFilterOption
                      {...{
                        key,
                        name,
                        ...restField,
                        add,
                        remove,
                        filter,
                        form,
                      }}
                    />
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Form.List name="order">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <DynamicSortOption
                      {...{
                        key,
                        name,
                        ...restField,
                        add,
                        remove,
                        filter,
                        form,
                      }}
                    />
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DynamicFilter;
