export const setLS = (key: string, value: any): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    localStorage.setItem(key, value);
  }
};

export const getLS = (key: string): any => {
  try {
    return JSON.parse(localStorage.getItem(key) || '');
  } catch (error) {
    return localStorage.getItem(key);
  }
};

export const removeLS = (key: string): any => {
  try {
    return localStorage.removeItem(key);
  } catch (error) {}
};
