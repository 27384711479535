import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetCurrencyRequestParamDTO,
  GetCurrencyResponseDTO,
  ICreateCurrency,
  ICurrencyDetail,
} from 'interfaces/currency';
export function getCurrencyRequest(
  params?: GetCurrencyRequestParamDTO,
): Promise<GetCurrencyResponseDTO> {
  return Instance.get(`/${PREFIX_API.CURRENCY}`, {
    params,
  });
}
export function getDetailCurrency(
  currencyId: string,
): Promise<ICurrencyDetail> {
  return Instance.get(`/${PREFIX_API.CURRENCY}/${currencyId}`);
}
export function createCurrencyRequest(
  currency: ICreateCurrency,
): Promise<GetCurrencyResponseDTO> {
  return Instance.post(`/${PREFIX_API.CURRENCY}`, currency);
}
export function updateCurrencyRequest(
  currency: ICreateCurrency,
): Promise<GetCurrencyResponseDTO> {
  const payload: any = { ...currency };
  const id = currency.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.CURRENCY}/${id}`, payload);
}
