import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetWcWeb3ConnectRequestParamDTO,
  GetWcWeb3ConnectResponseDTO,
  ICreateWcWeb3Connect,
  IWcWeb3ConnectDetail,
} from 'interfaces/wcWeb3Connect';
export function getWcWeb3ConnectRequest(
  params?: GetWcWeb3ConnectRequestParamDTO,
): Promise<GetWcWeb3ConnectResponseDTO> {
  return Instance.get(`/${PREFIX_API.WC_WEB3CONNECT}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailWcWeb3Connect(
  wcWeb3ConnectId: string,
): Promise<IWcWeb3ConnectDetail> {
  return Instance.get(`/${PREFIX_API.WC_WEB3CONNECT}/${wcWeb3ConnectId}`);
}
export function createWcWeb3ConnectRequest(
  wcWeb3Connect: ICreateWcWeb3Connect,
): Promise<GetWcWeb3ConnectResponseDTO> {
  return Instance.post(`/${PREFIX_API.WC_WEB3CONNECT}`, wcWeb3Connect);
}
export function updateWcWeb3ConnectRequest(
  wcWeb3Connect: ICreateWcWeb3Connect,
): Promise<GetWcWeb3ConnectResponseDTO> {
  const payload: any = { ...wcWeb3Connect };
  const id = wcWeb3Connect.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.WC_WEB3CONNECT}/${id}`, payload);
}
export function getWcWeb3ConnectRequestPaginationConfig(): Promise<GetWcWeb3ConnectResponseDTO> {
  return Instance.get(`/${PREFIX_API.WC_WEB3CONNECT}/pagination-config`);
}
