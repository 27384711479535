import { Button, Divider, Row } from 'antd';
import clsx from 'clsx';
import { RoleQuorum } from 'enum/addNewCustomer';
import { useState } from 'react';

import { SvgIcon } from 'app/components/SvgIcon';

import CustomerProfileTab from 'app/components/common/CustomerProfile';
import TextInfor from 'app/components/common/TextInfor';
import { STATUS_REQUEST } from 'constants/index';
import { capitalize } from 'lodash';
import styles from './index.module.scss';

interface Props {
  id: string;
  name: string;
  onClose?: () => void;
  requestDetail: any;
  isMask: boolean;
}

export const renderStatus = (type: string) => {
  return (
    <Row
      className={clsx(styles.status, {
        [styles.status__approved]: STATUS_REQUEST.APPROVED === type,
      })}
      style={{ gap: 8 }}
    >
      {STATUS_REQUEST.REJECTED === type && (
        <SvgIcon
          name="white-reject"
          width={9}
          height={9}
          style={{ marginTop: 3 }}
        />
      )}
      {STATUS_REQUEST.APPROVED === type && (
        <SvgIcon
          name="white-approve"
          width={14}
          height={14}
          style={{ marginTop: 0 }}
        />
      )}
      <span className={styles.status__text}>{type.toUpperCase()}</span>
    </Row>
  );
};

const MarkedAsLostDevice = ({
  id,
  name,
  onClose,
  requestDetail,
  isMask,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.content}>
      <SvgIcon name="mark-as-lost-device" width={32} height={32} />
      <Row className={styles.reviewing__title} data-testid="title">
        Marked as lost device request
        {(requestDetail?.status === STATUS_REQUEST.APPROVED ||
          requestDetail?.status === STATUS_REQUEST.REJECTED) &&
          renderStatus(requestDetail.status)}
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <TextInfor
          label="Customer name"
          text={
            <Button
              type="link"
              onClick={() => setOpen(true)}
              className={styles.customer__detail}
            >
              {requestDetail?.customer?.name ?? ''}
            </Button>
          }
          dataTestId="customer-name"
        />
        <TextInfor
          label="Business registration number"
          text={
            <Button
              type="link"
              onClick={() => setOpen(true)}
              className={styles.customer__detail}
            >
              {requestDetail?.customer?.businessRegistrationId ?? ''}
            </Button>
          }
          dataTestId="registration-id"
        />
      </Row>
      <div className={styles.user__info}>
        <Row style={{ alignItems: 'center', marginBottom: 20 }}>
          <SvgIcon name="menu-icon" width={12} height={8} />{' '}
          <span className={styles.content__header} data-testid="user-info">
            User information
          </span>
        </Row>
        <Row>
          <TextInfor
            dataTestId="name"
            span={12}
            label="Name"
            text={
              <span className={styles.user__name}>
                {requestDetail?.user?.name ?? '-'}
              </span>
            }
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            span={12}
            label="Role"
            dataTestId="role"
            text={
              <Row>
                <div
                  className={clsx(styles.role, {
                    [styles.role__viewer]:
                      requestDetail?.user?.roleName?.toUpperCase() ===
                      RoleQuorum.VIEWER,
                    [styles.role__member]:
                      requestDetail?.user?.roleName?.toUpperCase() ===
                      RoleQuorum.MEMBER,
                  })}
                >
                  {capitalize(requestDetail?.user?.roleName)}
                </div>
                {requestDetail?.user?.roleName?.toUpperCase() ===
                  RoleQuorum.ADMIN &&
                  requestDetail?.user?.requiredApprover && (
                    <div className={styles.required__approver}>
                      <span className={styles.required__approver__text}>
                        REQUIRED APPROVER
                      </span>{' '}
                      <SvgIcon
                        width={10}
                        height={12}
                        name="required-approver"
                        className={styles.required__approver__icon}
                      />
                    </div>
                  )}
              </Row>
            }
          />
          <Divider
            orientationMargin="0"
            className={styles.content__header}
            orientation="left"
          >
            Device information
          </Divider>
          <TextInfor
            span={12}
            dataTestId="device"
            label="Device"
            text={
              <Row style={{ gap: 8 }}>
                <span>{requestDetail?.user?.device?.deviceName ?? '-'}</span>
                <SvgIcon name="device-icon" width={12} height={16} />
              </Row>
            }
          />
          <TextInfor
            span={12}
            dataTestId="device-registered-date"
            label="Device registered date"
            text={requestDetail?.user?.device?.registeredDate ?? '-'}
          />
          <Divider
            orientationMargin="0"
            className={styles.content__header}
            orientation="left"
          >
            Contact information
          </Divider>
          <TextInfor
            span={12}
            dataTestId="phone-number"
            label="Phone number"
            text={requestDetail?.user.phoneNumber ?? '-'}
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            dataTestId="email"
            span={12}
            label="Email"
            text={requestDetail?.user.email ?? '-'}
            isMask={isMask}
            isMaskable={true}
          />
        </Row>
      </div>

      <CustomerProfileTab
        open={open}
        setOpen={setOpen}
        requestDetail={requestDetail}
        isMask={isMask}
      />
    </div>
  );
};

export default MarkedAsLostDevice;
