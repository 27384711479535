import { createSlice } from '@reduxjs/toolkit';
import { PER_PAGE } from 'constants/index';
import { Params } from 'interfaces/request';

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Sort, SortDirection } from 'enum/common';
import {
  Customer,
  GetCustomersRequest,
  ListCustomersResponse,
  ListVaultsByCustomerRequest,
  ListVaultsByCustomerResponse,
  TotalActiveResponse,
} from 'interfaces/customers';
import { ParamsCustomers, RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import { AppState } from '../..';

interface CustomersState {
  params: ParamsCustomers;
  customersList: ResponseData<ListCustomersResponse>;
  forceScreen: ResponseData<any>;
  vaultsByCustomer: ResponseData<ListVaultsByCustomerResponse>;
  user: ResponseData<any>;
  firstRender: boolean;

  canLoadMore: boolean;

  deleteCus: ResponseData<any>;
  totalActive: ResponseData<TotalActiveResponse>;
  editUser: ResponseData<any>;
  updateStaking: ResponseData<any>;
}

export function getDefaultCustomersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
  };
}

const initialState: CustomersState = {
  params: getDefaultCustomersParams(),
  customersList: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  forceScreen: {
    status: RequestStatus.Idle,
  },
  vaultsByCustomer: {
    status: RequestStatus.Idle,
  },
  user: {
    status: RequestStatus.Idle,
  },
  firstRender: true,

  deleteCus: {
    status: RequestStatus.Idle,
  },
  totalActive: {
    status: RequestStatus.Idle,
  },
  editUser: {
    status: RequestStatus.Idle,
  },
  updateStaking: {
    status: RequestStatus.Idle,
  },
};

const fetchCustomersRequest: CaseReducer<
  CustomersState,
  PayloadAction<GetCustomersRequest>
> = (state: CustomersState) => {
  state.customersList.status = RequestStatus.Loading;
};

const setCustomer = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.customersList.status = RequestStatus.Success;
  state.customersList.data = payload;
};

const appendCustomer = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.customersList.status = RequestStatus.Success;
  state.customersList?.data?.customers.push(...payload);
};

const setCanLoadMore = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};

const resetCustomers = (state: CustomersState) => {
  state.customersList = {
    status: RequestStatus.Idle,
  };
  state.canLoadMore = true;
};

const fetchCustomersSuccess: CaseReducer<
  CustomersState,
  PayloadAction<ListCustomersResponse>
> = (
  state: CustomersState,
  { payload }: PayloadAction<ListCustomersResponse>,
) => {
  state.customersList.status = RequestStatus.Success;
  state.customersList.data = payload;
};

const fetchCustomersFailed: CaseReducer<CustomersState> = (
  state: CustomersState,
) => {
  state.customersList.status = RequestStatus.Failed;
};

const resetStatusfetchCustomers = (state: CustomersState) => {
  state.user.status = RequestStatus.Idle;
};

const refreshCustomers: CaseReducer<
  CustomersState,
  PayloadAction<GetCustomersRequest>
> = (state: CustomersState) => {
  state.customersList.status = RequestStatus.Loading;
};

const updateListCustomer: CaseReducer<
  CustomersState,
  PayloadAction<Customer[]>
> = (state: CustomersState, { payload }: PayloadAction<Customer[]>) => {
  if (state.customersList?.data?.customers) {
    state.customersList.data.customers = payload;
  }
};

const resetCustomersParams = state => {
  state.params = getDefaultCustomersParams();
};
const setCustomerFilterParams = (
  state: CustomersState,
  { payload }: PayloadAction<ParamsCustomers>,
) => {
  state.params = { ...state.params, ...payload };
};

const getEmptyCustomers: CaseReducer<CustomersState> = (
  state: CustomersState,
) => {
  state.customersList.data = { customers: [], totalCount: 0, totalActive: 0 };
};

const forceScreenRequest: CaseReducer<CustomersState> = (
  state: CustomersState,
) => {
  state.forceScreen.status = RequestStatus.Loading;
};

const forceScreenSuccess: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.customersList.data = payload.customersList;

  state.forceScreen.status = RequestStatus.Success;
};

const forceScreenFailed: CaseReducer<CustomersState, PayloadAction<string>> = (
  state: CustomersState,
  { payload }: PayloadAction<string>,
) => {
  state.forceScreen.error = payload;
};

const updateFirstRender: CaseReducer<CustomersState, PayloadAction<boolean>> = (
  state: CustomersState,
  { payload }: PayloadAction<boolean>,
) => {
  state.firstRender = payload;
};

const resetFirstRender: CaseReducer<CustomersState> = (
  state: CustomersState,
) => {
  state.firstRender = true;
};

// get vaults by customer
const fetchVaultsByCustomerRequest: CaseReducer<
  CustomersState,
  PayloadAction<ListVaultsByCustomerRequest>
> = (state: CustomersState) => {
  state.vaultsByCustomer.status = RequestStatus.Loading;
};

const fetchVaultsByCustomerSuccess: CaseReducer<
  CustomersState,
  PayloadAction<ListVaultsByCustomerResponse>
> = (
  state: CustomersState,
  { payload }: PayloadAction<ListVaultsByCustomerResponse>,
) => {
  state.vaultsByCustomer.data = payload;
};

const fetchVaultsByCustomerFailed: CaseReducer<
  CustomersState,
  PayloadAction<string>
> = (state: CustomersState, { payload }: PayloadAction<string>) => {
  state.customersList.status = RequestStatus.Failed;
  state.customersList.error = payload;
};

const createNewUserRequest: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
) => {
  state.user.status = RequestStatus.Loading;
};

const createNewUserSuccess: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.user.status = RequestStatus.Success;
  state.user.data = payload;
};

const createNewUserFailed: CaseReducer<
  CustomersState,
  PayloadAction<string>
> = (state: CustomersState, { payload }: PayloadAction<string>) => {
  state.user.status = RequestStatus.Failed;
  state.user.error = payload;
};

const editUserRequest: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
) => {
  state.editUser.status = RequestStatus.Loading;
};

const editUserSuccess: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.editUser.status = RequestStatus.Success;
  state.editUser.data = payload;
};

const editUserFailed: CaseReducer<CustomersState> = (state: CustomersState) => {
  state.editUser.status = RequestStatus.Failed;
};

const resetEditUser: CaseReducer<CustomersState> = (state: CustomersState) => {
  state.editUser.status = RequestStatus.Idle;
};

const deleteCustomerRequest: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
) => {
  state.deleteCus.status = RequestStatus.Loading;
};

const deleteCustomerSuccess: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.deleteCus.data = payload;
  state.deleteCus.status = RequestStatus.Success;
};

const deleteCustomerFailed: CaseReducer<CustomersState> = (
  state: CustomersState,
) => {
  state.deleteCus.status = RequestStatus.Failed;
};

const resetDeleteCustomer: CaseReducer<CustomersState> = (
  state: CustomersState,
) => {
  state.deleteCus.status = RequestStatus.Idle;
};

const getTotalActiveRequest: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
) => {
  state.totalActive.status = RequestStatus.Loading;
};

const getTotalActiveSuccess: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.totalActive.data = payload;
};

const getTotalActiveFailed: CaseReducer<
  CustomersState,
  PayloadAction<string>
> = (state: CustomersState, { payload }: PayloadAction<any>) => {
  state.totalActive.status = RequestStatus.Failed;
};

const updateStakingRequest: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
) => {
  state.updateStaking.status = RequestStatus.Loading;
};

const updateStakingSuccess: CaseReducer<CustomersState, PayloadAction<any>> = (
  state: CustomersState,
  { payload }: PayloadAction<any>,
) => {
  state.updateStaking.status = RequestStatus.Success;
  state.updateStaking.data = payload;
};

const updateStakingFailed: CaseReducer<CustomersState> = (
  state: CustomersState,
) => {
  state.updateStaking.status = RequestStatus.Failed;
};

const resetUpdateStaking: CaseReducer<CustomersState> = (
  state: CustomersState,
) => {
  state.updateStaking.status = RequestStatus.Idle;
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    fetchCustomersRequest,
    fetchCustomersSuccess,
    fetchCustomersFailed,
    resetStatusfetchCustomers,

    refreshCustomers,

    getTotalActiveRequest,
    getTotalActiveSuccess,
    getTotalActiveFailed,

    setCustomer,
    appendCustomer,
    setCanLoadMore,
    resetCustomers,
    setCustomerFilterParams,

    updateListCustomer,

    resetCustomersParams,

    getEmptyCustomers,

    forceScreenRequest,
    forceScreenSuccess,
    forceScreenFailed,

    fetchVaultsByCustomerRequest,
    fetchVaultsByCustomerSuccess,
    fetchVaultsByCustomerFailed,

    createNewUserRequest,
    createNewUserSuccess,
    createNewUserFailed,

    editUserRequest,
    editUserSuccess,
    editUserFailed,
    resetEditUser,

    updateFirstRender,
    resetFirstRender,

    deleteCustomerRequest,
    deleteCustomerSuccess,
    deleteCustomerFailed,
    resetDeleteCustomer,

    setCustomersParam<K extends keyof ParamsCustomers>(
      state: CustomersState,
      action: PayloadAction<{ key: K; value: ParamsCustomers[K] }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
    },

    updateStakingRequest,
    updateStakingSuccess,
    updateStakingFailed,
    resetUpdateStaking,
  },
});

// Actions
export const customersActions = customersSlice.actions;

// Reducer
export const customersReducer = customersSlice.reducer;

// Selectors
export const selectParamsCustomers = (state: AppState) =>
  state.customers?.params;
export const selectCustomers = (state: AppState) =>
  state.customers.customersList;

export const selectFirstRender = (state: AppState) =>
  state.customers.firstRender;

export const selectVaultsByCustomer = (state: AppState) =>
  state.customers?.vaultsByCustomer;

export const selectStatusDeleteCustomer = (state: AppState) =>
  state.customers?.deleteCus;

export const selectTotalActive = (state: AppState) =>
  state.customers?.totalActive;

export const selectUser = (state: AppState) => state.customers?.user;

export const selectEditUser = (state: AppState) => state.customers?.editUser;

export const selectUpdateStaking = (state: AppState) =>
  state.customers?.updateStaking;
