import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../common/SvgIcon';
import background from 'images/background.png';

import styles from './index.module.scss';
import { useMsal } from '@azure/msal-react';
import { authActions } from 'store/slices/auth';
import { useAppDispatch } from 'store/hooks';
import { RakkarLogoIcon } from 'assets/components/icon';

type Props = {};

const NotPermision = (props: Props) => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/login',
    });
    dispatch(authActions.logout());
  };

  const Main = styled.div`
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6)),
      url(${background});
    height: 100vh;
    background-repeat: initial;
    position: relative;
    background-size: cover;
  `;

  return (
    <Main>
      <div className={styles.container}>
        <div>
          <div className={styles.logo}>
            <RakkarLogoIcon />
          </div>
          <div className={styles.icon}>
            <SvgIcon name="cant_access" width={206} height={176} />
          </div>
          <div className={styles.content}>You have no permission</div>
          <div className={styles.sub}>Please contact your administrator</div>
          <div
            className={styles.link}
            onClick={() => {
              handleLogout();
            }}
          >
            Back to login
          </div>
        </div>
      </div>
    </Main>
  );
};

export default NotPermision;
function dispatch(arg0: { payload: undefined; type: string }) {
  throw new Error('Function not implemented.');
}
