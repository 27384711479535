import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getWcAppAdapterRequest,
  getDetailWcAppAdapter,
  createWcAppAdapterRequest,
  updateWcAppAdapterRequest,
  getWcAppAdapterRequestPaginationConfig,
} from 'services/wcAppAdapter.service';
import { wcAppAdapterActions } from 'store/slices/wcAppAdapter/wcAppAdapter.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetWcAppAdapterRequestParamDTO,
  ICreateWcAppAdapter,
} from 'interfaces/wcAppAdapter';
const handleGetWcAppAdapterRequest = function* ({
  payload,
}: PayloadAction<GetWcAppAdapterRequestParamDTO>) {
  try {
    const res = yield call(getWcAppAdapterRequest, payload);
    const params = yield select(state => state.wcAppAdapter.params) as any;
    const listWcAppAdapter = yield select(
      state => state.wcAppAdapter?.wcAppAdapter?.data?.listWcAppAdapter,
    ) as any;
    if (res) {
      yield put(
        wcAppAdapterActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listWcAppAdapter || []), ...res.list];
        yield put(wcAppAdapterActions.appendWcAppAdapter(newList));
      } else {
        yield put(
          wcAppAdapterActions.getWcAppAdapterSuccess({
            listWcAppAdapter: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppAdapterActions.getWcAppAdapterFailed());
  }
};
const handleGetDetailWcAppAdapterRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailWcAppAdapter, payload);
    if (res) {
      yield put(wcAppAdapterActions.getDetailWcAppAdapterSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppAdapterActions.getDetailWcAppAdapterFailed());
  }
};
const handleCreateWcAppAdapterRequest = function* ({
  payload,
}: PayloadAction<ICreateWcAppAdapter>) {
  try {
    const res = yield call(createWcAppAdapterRequest, payload);
    if (res) {
      yield put(wcAppAdapterActions.createWcAppAdapterSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppAdapterActions.createWcAppAdapterFailed());
  }
};
const handleUpdateWcAppAdapterRequest = function* ({
  payload,
}: PayloadAction<ICreateWcAppAdapter>) {
  try {
    const res = yield call(updateWcAppAdapterRequest, payload);
    if (res) {
      yield put(wcAppAdapterActions.updateWcAppAdapterSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppAdapterActions.updateWcAppAdapterFailed());
  }
};
const handleGetWcAppAdapterPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetWcAppAdapterRequestParamDTO>) {
  try {
    const res = yield call(getWcAppAdapterRequestPaginationConfig);
    if (res) {
      yield put(
        wcAppAdapterActions.getWcAppAdapterPaginationConfigSuccess(res),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppAdapterActions.getWcAppAdapterPaginationConfigFailed());
  }
};
const wcAppAdapterSaga = function* () {
  yield takeLatest(
    wcAppAdapterActions.getWcAppAdapterRequest.type,
    handleGetWcAppAdapterRequest,
  );
  yield takeLatest(
    wcAppAdapterActions.createWcAppAdapterRequest.type,
    handleCreateWcAppAdapterRequest,
  );
  yield takeLatest(
    wcAppAdapterActions.updateWcAppAdapterRequest.type,
    handleUpdateWcAppAdapterRequest,
  );
  yield takeLatest(
    wcAppAdapterActions.getWcAppAdapterPaginationConfigRequest.type,
    handleGetWcAppAdapterPaginationConfigRequest,
  );
  yield takeLatest(
    wcAppAdapterActions.getDetailWcAppAdapterRequest.type,
    handleGetDetailWcAppAdapterRequest,
  );
};
export default wcAppAdapterSaga;
