import { PathList } from 'constants/index';
import { ListActionNeedToCheckPermission } from 'enum/common';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectPermissions } from 'store/slices/auth';

export default function PermissionRoute({ children }: { children: ReactNode }) {
  const permissions = useAppSelector(selectPermissions);
  const navigate = useNavigate();
  const pathname = useLocation()?.pathname;
  const [page, setPage] = useState<string | undefined>();

  useEffect(() => {
    if (pathname) {
      switch (pathname) {
        case PathList.customer:
          setPage(ListActionNeedToCheckPermission.VIEW_CUSTOMER_LISTING);
          break;
        case PathList.request:
          setPage(ListActionNeedToCheckPermission.VIEW_PENDING_REQUEST);
          break;
        case PathList.transaction:
          setPage(ListActionNeedToCheckPermission.VIEW_TRANSACTION_HISTORY);
          break;
        case PathList.billing:
          setPage(ListActionNeedToCheckPermission.VIEW_BILLING_LISTING);
          break;
        case PathList.accessControl:
          setPage(ListActionNeedToCheckPermission.ACCESS_CONTROL);
          break;
        case PathList.auditLog:
          setPage(ListActionNeedToCheckPermission.AUDIT_LOG);
          break;
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (permissions && page) {
      const checkPer = permissions.findIndex(item => item.name === page);
      if (checkPer === -1) {
        navigate('/403');
      }
    }
  }, [permissions, page, navigate]);

  return <>{children}</>;
}
