import { capitalize, lowerCase } from 'lodash';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  userRole: string;
};

const RakkarUserRole = ({ userRole }: Props) => {
  if (!userRole) return <></>;
  return (
    <div
      className={`${styles.container} ${styles[lowerCase(userRole)]}`}
      data-testid="user-role"
    >
      {capitalize(userRole)}
    </div>
  );
};

export default RakkarUserRole;
