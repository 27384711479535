import { Col } from 'antd';
import styles from './index.module.scss';

interface TextInfoProps {
  label: any;
  text: any;
  span?: number;
  dataTestId?: string;
  isMask?: boolean;
  isMaskable?: boolean;
  size?: number;
}

export const dots = (size = 8) =>
  Array.from({ length: 6 }, (_, index) => (
    <div
      key={index}
      className={styles.dot}
      style={{ width: size, height: size }}
      data-testid="masking-dots"
    ></div>
  ));

const TextInfor = ({
  span = 12,
  label,
  text,
  dataTestId,
  isMask = true,
  isMaskable = false,
  size = 8,
}: TextInfoProps) => {
  return (
    <Col span={span}>
      <div className={styles.content__item}>
        <p className={styles.content__label}>{label}</p>
        <p className={styles.content__text} data-testid={dataTestId}>
          {isMask && isMaskable ? dots(size) : text || '-'}
        </p>
      </div>
    </Col>
  );
};

export default TextInfor;
