import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortSnapshotTokenPrice,
  GroupByField,
  CustomerOnScreen,
} from 'enum/snapshotTokenPrice.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetSnapshotTokenPriceRequestParamDTO,
  GetSnapshotTokenPriceResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ISnapshotTokenPriceDetail,
} from 'interfaces/snapshotTokenPrice';
import { AppState } from '../..';
interface SnapshotTokenPriceState {
  params: GetSnapshotTokenPriceRequestParamDTO;
  snapshotTokenPrice: ResponseData<GetSnapshotTokenPriceResponseDTO>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  snapshotTokenPriceDetail: ResponseData<ISnapshotTokenPriceDetail>;
}
export function getDefaultSnapshotTokenPriceRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortSnapshotTokenPrice.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: SnapshotTokenPriceState = {
  params: getDefaultSnapshotTokenPriceRequestParams(),
  snapshotTokenPrice: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.SNAPSHOTTOKENPRICE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  snapshotTokenPriceDetail: {
    status: RequestStatus.Idle,
  },
};
const getSnapshotTokenPriceRequest: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<any>
> = (state: SnapshotTokenPriceState) => {
  state.snapshotTokenPrice.status = RequestStatus.Loading;
};
const getSnapshotTokenPriceSuccess: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<GetSnapshotTokenPriceResponseDTO>
> = (
  state: SnapshotTokenPriceState,
  { payload }: PayloadAction<GetSnapshotTokenPriceResponseDTO>,
) => {
  state.snapshotTokenPrice.status = RequestStatus.Success;
  state.snapshotTokenPrice.data = payload;
};
const getSnapshotTokenPriceFailed: CaseReducer<SnapshotTokenPriceState> = (
  state: SnapshotTokenPriceState,
) => {
  state.snapshotTokenPrice.status = RequestStatus.Failed;
};
const getDetailSnapshotTokenPriceRequest: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<string>
> = (state: SnapshotTokenPriceState) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Loading;
};
const getDetailSnapshotTokenPriceSuccess: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<ISnapshotTokenPriceDetail>
> = (
  state: SnapshotTokenPriceState,
  { payload }: PayloadAction<ISnapshotTokenPriceDetail>,
) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Success;
  state.snapshotTokenPriceDetail.data = payload;
};
const getDetailSnapshotTokenPriceFailed: CaseReducer<SnapshotTokenPriceState> = (
  state: SnapshotTokenPriceState,
) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Failed;
};
const createSnapshotTokenPriceRequest: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<ISnapshotTokenPriceDetail>
> = (state: SnapshotTokenPriceState) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Loading;
};
const createSnapshotTokenPriceSuccess: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<ISnapshotTokenPriceDetail>
> = (
  state: SnapshotTokenPriceState,
  { payload }: PayloadAction<ISnapshotTokenPriceDetail>,
) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Success;
  state.snapshotTokenPriceDetail.data = payload;
};
const createSnapshotTokenPriceFailed: CaseReducer<SnapshotTokenPriceState> = (
  state: SnapshotTokenPriceState,
) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Failed;
};
const updateSnapshotTokenPriceRequest: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<ISnapshotTokenPriceDetail>
> = (state: SnapshotTokenPriceState) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Loading;
};
const updateSnapshotTokenPriceSuccess: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<ISnapshotTokenPriceDetail>
> = (
  state: SnapshotTokenPriceState,
  { payload }: PayloadAction<ISnapshotTokenPriceDetail>,
) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Success;
  state.snapshotTokenPriceDetail.data = payload;
};
const updateSnapshotTokenPriceFailed: CaseReducer<SnapshotTokenPriceState> = (
  state: SnapshotTokenPriceState,
) => {
  state.snapshotTokenPriceDetail.status = RequestStatus.Failed;
};
const appendSnapshotTokenPrice: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<any>
> = (state: SnapshotTokenPriceState, { payload }: PayloadAction<any>) => {
  if (state.snapshotTokenPrice?.data?.listSnapshotTokenPrice) {
    state.snapshotTokenPrice.status = RequestStatus.Success;
    state.snapshotTokenPrice.data.listSnapshotTokenPrice = [...payload];
  }
};
const setCanLoadMore = (
  state: SnapshotTokenPriceState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetSnapshotTokenPrice: CaseReducer<SnapshotTokenPriceState> = (
  state: SnapshotTokenPriceState,
) => {
  state.snapshotTokenPrice = {
    status: RequestStatus.Idle,
  };
};
const resetSnapshotTokenPriceParams: CaseReducer<SnapshotTokenPriceState> = (
  state: SnapshotTokenPriceState,
) => {
  state.params = getDefaultSnapshotTokenPriceRequestParams();
};
const setSnapshotTokenPriceFilterParams = (
  state: SnapshotTokenPriceState,
  { payload }: PayloadAction<GetSnapshotTokenPriceRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: SnapshotTokenPriceState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<
  SnapshotTokenPriceState,
  PayloadAction<any>
> = (state: SnapshotTokenPriceState, { payload }: PayloadAction<any>) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<SnapshotTokenPriceState> = (
  state: SnapshotTokenPriceState,
) => {
  state.action.status = RequestStatus.Failed;
};
const snapshotTokenPriceSlice = createSlice({
  name: 'snapshotTokenPrice',
  initialState,
  reducers: {
    getSnapshotTokenPriceRequest,
    getSnapshotTokenPriceSuccess,
    getSnapshotTokenPriceFailed,
    getDetailSnapshotTokenPriceRequest,
    getDetailSnapshotTokenPriceSuccess,
    getDetailSnapshotTokenPriceFailed,
    createSnapshotTokenPriceRequest,
    createSnapshotTokenPriceSuccess,
    createSnapshotTokenPriceFailed,
    updateSnapshotTokenPriceRequest,
    updateSnapshotTokenPriceSuccess,
    updateSnapshotTokenPriceFailed,
    appendSnapshotTokenPrice,
    setCanLoadMore,
    resetSnapshotTokenPrice,
    setSnapshotTokenPriceParams<K extends keyof GetSnapshotTokenPriceRequestParamDTO>(
      state: SnapshotTokenPriceState,
      action: PayloadAction<{
        key: K;
        value: GetSnapshotTokenPriceRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setSnapshotTokenPriceFilterParams,
    resetSnapshotTokenPriceParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
  },
});
// Actions
export const snapshotTokenPriceActions = snapshotTokenPriceSlice.actions;
// Reducer
export const snapshotTokenPriceReducer = snapshotTokenPriceSlice.reducer;
// Selectors
export const selectSnapshotTokenPrice = (state: AppState) =>
  state.snapshotTokenPrice?.snapshotTokenPrice;
export const selectParamSnapshotTokenPrice = (state: AppState) =>
  state.snapshotTokenPrice?.params;
export const selectCanLoadMoreSnapshotTokenPrice = (state: AppState) =>
  state.snapshotTokenPrice?.canLoadMore;
export const selectSnapshotTokenPriceDetail = (state: AppState) =>
  state.snapshotTokenPrice?.snapshotTokenPriceDetail;
