import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getWcAdapterRequest,
  getDetailWcAdapter,
  createWcAdapterRequest,
  updateWcAdapterRequest,
  getWcAdapterRequestPaginationConfig,
} from 'services/wcAdapter.service';
import { wcAdapterActions } from 'store/slices/wcAdapter/wcAdapter.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetWcAdapterRequestParamDTO,
  ICreateWcAdapter,
} from 'interfaces/wcAdapter';
const handleGetWcAdapterRequest = function* ({
  payload,
}: PayloadAction<GetWcAdapterRequestParamDTO>) {
  try {
    const res = yield call(getWcAdapterRequest, payload);
    const params = yield select(state => state.wcAdapter.params) as any;
    const listWcAdapter = yield select(
      state => state.wcAdapter?.wcAdapter?.data?.listWcAdapter,
    ) as any;
    if (res) {
      yield put(
        wcAdapterActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listWcAdapter || []), ...res.list];
        yield put(wcAdapterActions.appendWcAdapter(newList));
      } else {
        yield put(
          wcAdapterActions.getWcAdapterSuccess({
            listWcAdapter: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAdapterActions.getWcAdapterFailed());
  }
};
const handleGetDetailWcAdapterRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailWcAdapter, payload);
    if (res) {
      yield put(wcAdapterActions.getDetailWcAdapterSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAdapterActions.getDetailWcAdapterFailed());
  }
};
const handleCreateWcAdapterRequest = function* ({
  payload,
}: PayloadAction<ICreateWcAdapter>) {
  try {
    const res = yield call(createWcAdapterRequest, payload);
    if (res) {
      yield put(wcAdapterActions.createWcAdapterSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAdapterActions.createWcAdapterFailed());
  }
};
const handleUpdateWcAdapterRequest = function* ({
  payload,
}: PayloadAction<ICreateWcAdapter>) {
  try {
    const res = yield call(updateWcAdapterRequest, payload);
    if (res) {
      yield put(wcAdapterActions.updateWcAdapterSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAdapterActions.updateWcAdapterFailed());
  }
};
const handleGetWcAdapterPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetWcAdapterRequestParamDTO>) {
  try {
    const res = yield call(getWcAdapterRequestPaginationConfig);
    if (res) {
      yield put(wcAdapterActions.getWcAdapterPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAdapterActions.getWcAdapterPaginationConfigFailed());
  }
};
const wcAdapterSaga = function* () {
  yield takeLatest(
    wcAdapterActions.getWcAdapterRequest.type,
    handleGetWcAdapterRequest,
  );
  yield takeLatest(
    wcAdapterActions.createWcAdapterRequest.type,
    handleCreateWcAdapterRequest,
  );
  yield takeLatest(
    wcAdapterActions.updateWcAdapterRequest.type,
    handleUpdateWcAdapterRequest,
  );
  yield takeLatest(
    wcAdapterActions.getWcAdapterPaginationConfigRequest.type,
    handleGetWcAdapterPaginationConfigRequest,
  );
  yield takeLatest(
    wcAdapterActions.getDetailWcAdapterRequest.type,
    handleGetDetailWcAdapterRequest,
  );
};
export default wcAdapterSaga;
