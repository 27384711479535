import { Button, Spin, Tabs } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import { RequestStatus } from 'interfaces/request';
import { history } from 'utils/history';
import { useGetProductSubscriptionSubTabs } from './hooks/useGetProductSubscriptionSubTabs';
import { walletTypes } from './const';
import styles from './index.module.scss';

type ProductSubscriptionTabType = {
  customerId: string;
  onUpdateFeeSuccess?: () => void;
};

export const ProductSubscriptionTab = ({
  customerId,
  onUpdateFeeSuccess,
}: ProductSubscriptionTabType) => {
  const {
    customerProfile,
    feeInformation,
    handleShowPendingReqMsg,
    pendingReqId,
    tabItems,
  } = useGetProductSubscriptionSubTabs({ customerId, onUpdateFeeSuccess });

  if (
    customerProfile?.status === RequestStatus.Loading ||
    feeInformation?.status === RequestStatus.Loading
  ) {
    return (
      <div className={styles.loading}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {pendingReqId && (
        <div className={styles.pendingRequestMsgWrapper}>
          <div className={styles.innerPendingRequestMsgWrapper}>
            <span className={styles.pendingRequestMsg}>
              <SvgIcon
                style={{
                  marginRight: 8,
                  marginBottom: 2,
                }}
                width={14}
                height={14}
                name="info-outlined"
              />
              The customer has a pending request for approval
            </span>
            <Button
              className={styles.pendingRequestButton}
              type="link"
              onClick={() =>
                history.push({
                  pathname: `/pending-request?id=${pendingReqId}`,
                })
              }
            >
              View request
            </Button>
          </div>
        </div>
      )}
      <Tabs
        defaultActiveKey={walletTypes.hotWallet.key}
        items={tabItems}
        onChange={handleShowPendingReqMsg}
      />
    </div>
  );
};
