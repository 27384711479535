import { memo, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import styles from './index.module.scss';
import React from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

interface TextEditorProps {
  initValue?: string;
  onChangeEditor: (content: string) => void;
  handleClose?: any;
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: ['Poppins'] }],
    [{ align: [] }],

    ['clean'], // remove formatting button
  ],
};

const formats = [
  //'font',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  // 'image',
  'align',
  'color',
  'background',
];

const TextEditor = (props: TextEditorProps) => {
  const { onChangeEditor, initValue } = props;
  const reactQuillRef = React.useRef<any>();
  const containerRef = React.useRef<any>();

  const [value, setValue] = useState(initValue);

  useOnClickOutside(containerRef, props?.handleClose);

  const handleChange = (content, delta, source, editor) => {
    // console.log(JSON.stringify(editor.getContents())); // delta 사용시
    setValue(editor.getHTML());
    onChangeEditor(editor.getHTML());
  };

  // useEffect(() => {
  //   setValue(initValue);
  // }, [initValue]);

  const MAX_LENGTH_COMMENT = 1000;

  const checkCharacterCount = event => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (
      unprivilegedEditor.getLength() > MAX_LENGTH_COMMENT
      // && event.key !== 'Backspace'
    )
      event.preventDefault();
  };

  return (
    <div className={styles.text__editor} ref={containerRef}>
      <ReactQuill
        theme="snow"
        ref={reactQuillRef}
        modules={modules}
        formats={formats}
        value={value}
        onKeyDown={checkCharacterCount}
        onChange={handleChange}
      />
    </div>
  );
};

export default memo(TextEditor);
