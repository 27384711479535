import { PREFIX_API } from 'constants/index';
import { WorkSpaceType } from 'enum/common';
import {
  CustomerEntityRelationRes,
  TypeOfCompanyParams,
  WorkSpaces,
} from 'interfaces/businessInformation';
import { CheckQuorumRequest } from 'interfaces/customers';
import { ParamsCustomers } from 'interfaces/request';
import Instance from '../app.v2/config/instance';

export function getCustomersList(params: ParamsCustomers): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers`, { params });
}

export function createNewCustomer(params: any): Promise<any> {
  return Instance.post(`/${PREFIX_API.CORE}/rep/customers`, params);
}

export function createNewUser(id: string, payload: any): Promise<any> {
  return Instance.post(`/${PREFIX_API.AUTH}/account/${id}/users`, payload);
}

export function updateUser(userId: string, payload: any): Promise<any> {
  return Instance.put(
    `/${PREFIX_API.AUTH}/account/rep/users/${userId}`,
    payload,
  );
}

export function getVaultsByCustomer(id: string): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/${id}/vaults`);
}
export function getBusinessTypes(): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/business-type`);
}

export function getWorkspaces(params: {
  type?: WorkSpaceType.Hot | WorkSpaceType.Cold;
}): Promise<WorkSpaces> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/workspace`, {
    params,
  });
}

export function getCustomerEntityRelation(): Promise<CustomerEntityRelationRes> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/entity-relation`);
}

export function deleteCustomer(customerId: string): Promise<any> {
  return Instance.delete(`/core/customers/${customerId}`);
}
export function getTotalActive(): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/total-active`);
}

export function getSourceOfFund(
  params: TypeOfCompanyParams,
): Promise<CustomerEntityRelationRes> {
  return Instance.get(`/${PREFIX_API.CORE}/v2/rep/cfg_sourceOfFund`, {
    params,
  });
}

export function getTypeOfCompany(
  params: TypeOfCompanyParams,
): Promise<CustomerEntityRelationRes> {
  return Instance.get(`/${PREFIX_API.CORE}/v2/rep/cfg_typeOfCompany`, {
    params,
  });
}

export function checkQuorumByUser(
  userId: string,
  payload: CheckQuorumRequest,
): Promise<any> {
  return Instance.put(
    `/${PREFIX_API.AUTH}/account/check-quorum/${userId}`,
    payload,
  );
}

export function updateStakingReq(userId: string, payload: any): Promise<any> {
  return Instance.put(
    `/${PREFIX_API.CORE}/rep/customers/${userId}/subscribe-staking`,
    payload,
  );
}
