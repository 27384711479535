import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Tag,
  Space,
} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import RButton from 'app/components/Button';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectCreatorsRequest } from 'store/slices/common';
import {
  selectParamsRequest,
  getDefaultPendingRequestAllParams,
} from 'store/slices/request';
import { requestsType } from 'utils/assets';
import { getDefaultAvatarInfo } from 'utils/common';
import styles from './index.module.scss';
import Styled from 'app/pages/CustomerManagement/components/FilterModal/styled';
import { SvgIcon } from 'app/components/SvgIcon';
import { RequestPendingStatus } from 'enum/request';
import { TAB_REQUEST } from 'enum/common';

const { Option } = Select;

const { RangePicker } = DatePicker;

interface Props {
  tab: TAB_REQUEST;
  isOpen: boolean;
  onFinishFilter: (values: any) => void;
}

const defaultFormFilter = {
  requestType: [],
  requestedBy: [],
  requestDate: [null, null],
  lastUpdatedDate: [null, null],
  // ignore requestStatus cause of manually handle value change
};

const FilterRequest = ({ tab, isOpen, onFinishFilter }: Props) => {
  const [form] = Form.useForm();
  const [isDisabledReset, setIsDisabledReset] = useState<boolean>(true);
  const [requestStatus, setRequestStatus] = useState<CheckboxValueType[]>([]);

  const creators = useAppSelector(selectCreatorsRequest);
  const params = useAppSelector(selectParamsRequest);

  const listCreators = creators.data;

  const hasSomeParameters = (values: any) => {
    const hasRequestType = values.requestType?.length;
    const hasRequestStatus = values.requestStatus?.length;
    const hasRequestBy = values.requestedBy?.length;
    const hasRequestDate = values.requestDate?.every(Boolean);

    const hasLastUpdatedDate = values.lastUpdatedDate?.some(Boolean);

    return (
      hasRequestType ||
      hasRequestStatus ||
      hasRequestBy ||
      hasRequestDate ||
      hasLastUpdatedDate
    );
  };

  useEffect(() => {
    const data = {
      requestType: params?.requestType,
      requestStatus: params?.status,
      requestedBy: params?.requestedBy,
      requestDate: [
        params?.dateFrom ? moment(params?.dateFrom) : null,
        params?.dateTo ? moment(params?.dateTo) : null,
      ],
      lastUpdatedDate: [
        params?.lastUpdatedFrom ? moment(params?.lastUpdatedFrom) : null,
        params?.lastUpdatedTo ? moment(params?.lastUpdatedTo) : null,
      ],
    };
    form.setFieldsValue(data);
    const hasSomeValue = hasSomeParameters(data);
    setIsDisabledReset(!hasSomeValue);
  }, [params, isOpen]);

  const onValuesChange = (values: any) => {
    const hasSomeValue = hasSomeParameters(values);

    setIsDisabledReset(!hasSomeValue);
  };

  const onFinish = (values: any) => {
    const { requestType, requestedBy, requestDate, lastUpdatedDate } = values;

    const dateFrom = requestDate?.[0]
      ? moment(requestDate[0]).startOf('day').toISOString()
      : undefined;
    const dateTo = requestDate?.[1]
      ? moment(requestDate[1]).endOf('day').toISOString()
      : undefined;
    const lastUpdatedFrom = lastUpdatedDate?.[0]
      ? moment(lastUpdatedDate[0]).startOf('day').toISOString()
      : undefined;
    const lastUpdatedTo = lastUpdatedDate?.[1]
      ? moment(lastUpdatedDate[1]).endOf('day').toISOString()
      : undefined;

    const defaultFilter = getDefaultPendingRequestAllParams();
    const dataFilter = {
      requestedBy,
      requestType,
      status:
        tab === TAB_REQUEST.ALL && requestStatus?.length === 0
          ? defaultFilter.status
          : requestStatus,
      dateFrom,
      dateTo,
      lastUpdatedFrom,
      lastUpdatedTo,
      filterStatus: requestStatus,
    };
    onFinishFilter(dataFilter);
  };

  const onClickReset = () => {
    form.resetFields();
    setRequestStatus([]);
  };

  const disabledDate = (current: Moment) => {
    return current && current.valueOf() > Date.now();
  };

  const renderLabelRequestBy = props => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
        className={styles.itemTag}
      >
        <span className={styles.circleName}>
          {!!label.props.children && getDefaultAvatarInfo(label.props.children)}
        </span>
        <span>{label}</span>
      </Tag>
    );
  };

  return (
    <div className={styles.container}>
      <Form
        form={form}
        initialValues={defaultFormFilter}
        layout="vertical"
        name="requestFilter"
        onValuesChange={(_, values) =>
          onValuesChange({ ...values, requestStatus })
        }
        onFinish={onFinish}
      >
        <Row>
          <Col span={12} className={styles.title}>
            Filter
          </Col>
          <Col span={12} className={styles.reset}>
            <Button
              data-testid="reset-button"
              type="link"
              onClick={onClickReset}
              className={clsx({ [styles.disabled__button]: isDisabledReset })}
            >
              Clear all
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span="24" data-testid="request-type">
            <Form.Item
              label={<span className={styles.label}>Request type</span>}
              name="requestType"
            >
              <Select
                className={styles.requestType}
                placeholder="Select..."
                allowClear
                showSearch
                mode="multiple"
              >
                {requestsType.map(item => {
                  return (
                    <Option value={item.value} key={item.value}>
                      <span>{item.name}</span>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {tab === TAB_REQUEST.ALL && (
          <Col>
            <Space direction="vertical" size={4} style={{ marginBottom: 16 }}>
              <span className={styles.requestStatus__label}>Status</span>
              <Styled>
                <Checkbox.Group
                  data-testid="requestStatus"
                  onChange={(checkedValue: CheckboxValueType[]) => {
                    setRequestStatus(checkedValue);
                    onValuesChange({
                      ...form.getFieldsValue(),
                      requestStatus: checkedValue,
                    });
                  }}
                  value={requestStatus}
                >
                  <Checkbox
                    value={RequestPendingStatus.APPROVED}
                    key={RequestPendingStatus.APPROVED}
                    data-testid="requestStatusApproved"
                  >
                    <div className={styles.checkbox__option}>
                      <SvgIcon
                        fill="#01904F"
                        name="approve-sign-nocolor"
                        width={14}
                        height={14}
                      ></SvgIcon>
                      <span>
                        {RequestPendingStatus.APPROVED.substring(0, 1)}
                        {RequestPendingStatus.APPROVED.substring(
                          1,
                        ).toLowerCase()}
                      </span>
                    </div>
                  </Checkbox>
                  <Checkbox
                    value={RequestPendingStatus.REJECTED}
                    key={RequestPendingStatus.REJECTED}
                    data-testid="requestStatusRejected"
                  >
                    <div className={styles.checkbox__option}>
                      <SvgIcon
                        fill="#D8382C"
                        name="reject-sign-nocolor"
                        width={14}
                        height={14}
                      ></SvgIcon>
                      <span>
                        {RequestPendingStatus.REJECTED.substring(0, 1)}
                        {RequestPendingStatus.REJECTED.substring(
                          1,
                        ).toLowerCase()}
                      </span>
                    </div>
                  </Checkbox>
                </Checkbox.Group>
              </Styled>
            </Space>
          </Col>
        )}
        <Row>
          <Col span="24" data-testid="requested-by">
            <Form.Item
              label={<span className={styles.label}>Requested by</span>}
              name="requestedBy"
            >
              <Select
                className={styles.requestedBy}
                placeholder="Select..."
                allowClear
                showSearch
                mode="multiple"
                tagRender={renderLabelRequestBy}
              >
                {!!listCreators &&
                  listCreators.map(item => {
                    return (
                      <Option value={item.userId} key={item.userId}>
                        <span>{item.name}</span>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div>
          <span className={styles.label}>Requested date</span>
          <Row style={{ marginTop: '8px' }}>
            <Col span={24} data-testid="request-date">
              <Form.Item name="requestDate">
                <RangePicker
                  className={styles.datePicker}
                  popupStyle={{ borderRadius: 8 }}
                  style={{ borderRadius: 8 }}
                  format={FORMAT_DATE.DEFAULT}
                  disabledDate={disabledDate}
                  placeholder={['From', 'To']}
                  separator={
                    <SvgIcon name="grey-right-arrow" width={12} height={12} />
                  }
                  allowClear
                  allowEmpty={[true, true]}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {tab === TAB_REQUEST.ALL && (
          <div>
            <span className={styles.label}>Last updated date</span>
            <Row style={{ marginTop: '8px' }}>
              <Col span={24} data-testid="last-updated-date">
                <Form.Item name="lastUpdatedDate">
                  <RangePicker
                    className={styles.datePicker}
                    popupStyle={{ borderRadius: 8 }}
                    style={{ borderRadius: 8 }}
                    format={FORMAT_DATE.DEFAULT}
                    disabledDate={disabledDate}
                    placeholder={['From', 'To']}
                    separator={
                      <SvgIcon name="grey-right-arrow" width={12} height={12} />
                    }
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        <Row style={{ justifyContent: 'end' }} data-testid="submit-button">
          <RButton
            htmlType="submit"
            buttonType="submit"
            content="Apply"
            width={75}
            height={32}
            onClick={() => {}}
            style={{ padding: '0' }}
          />
        </Row>
      </Form>
    </div>
  );
};

export default FilterRequest;
