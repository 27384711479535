import { SvgIcon } from 'app/components/SvgIcon';
import avatar from 'images/avatar.svg';
import { UserApproval } from 'interfaces/transactions';

import { dots } from 'app/components/common/TextInfor';
import clsx from 'clsx';
import { dotSize } from 'constants/index';
import { capitalize } from 'lodash';
import { getDefaultAvatarInfo } from 'utils/common';
import styles from './index.module.scss';

type Props = {
  data: UserApproval;
  isMaskedTransaction: boolean;
  isAdvanced?: boolean;
};

const GeneralInfoApprover = ({
  data,
  isMaskedTransaction,
  isAdvanced = false,
}: Props) => {
  const dotSizeMask = isAdvanced ? dotSize.medium : dotSize.large;

  return (
    <div className={styles.container} key={data.userId} data-testid="user-info">
      <div className={styles.avatar}>
        {data?.picture ? (
          <div className={styles.image}>
            <img src={data?.picture || avatar} alt="" data-testid="avatar" />
          </div>
        ) : (
          <div
            className={styles['avatar-default']}
            data-testid="avatar-default"
          >
            {getDefaultAvatarInfo(data?.name || '')}
          </div>
        )}

        {data.requiredApprover && (
          <div className={styles.required}>
            <SvgIcon
              name="shield"
              width={12}
              height={12}
              data-testid="required-approver"
            />
          </div>
        )}
      </div>
      <div className={styles.user_info}>
        <div
          className={clsx(styles.name, { [styles.name_advanced]: isAdvanced })}
          data-testid="name"
        >
          {isMaskedTransaction ? dots(dotSizeMask) : <>{data?.name || '-'}</>}
        </div>
        {isAdvanced && (
          <div className={styles.role}>{capitalize(data?.role)}</div>
        )}
      </div>
    </div>
  );
};

export default GeneralInfoApprover;
