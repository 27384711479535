import { Button, Checkbox, Col, Divider, Form, Row, Select } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import RButton from 'app/components/Button';
import BasicSelect from 'app/components/common/BasicSelect';
import clsx from 'clsx';
import { WorkSpaceType } from 'enum/common';
import { BusinessTypeInterface } from 'interfaces/businessInformation';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectParamsCustomers } from 'store/slices/customers';
import { countryData } from 'utils/assets';
import styles from './index.module.scss';
import Styled from './styled';

const { Option } = Select;

type Props = {
  hotWorkspaces: any;
  coldWorkspaces: any;
  businessTypeList: BusinessTypeInterface[];
  isOpen?: boolean;
  onFinishFilter: (values: any) => void;
};

const FilterContent = ({
  hotWorkspaces,
  coldWorkspaces,
  businessTypeList,
  isOpen,
  onFinishFilter,
}: Props) => {
  const [form] = Form.useForm();
  const [isDisabledReset, setIsDisabledReset] = useState<boolean>(true);
  const [isDisabledWorkspace, setIsDisabledWorkspace] = useState<boolean>(true);
  const [workspaces, setWorkspaces] = useState<any>([]);
  const [product, setProduct] = useState<CheckboxValueType[]>([]);
  const [customerStatus, setCustomerStatus] = useState<CheckboxValueType[]>([]);
  const params = useAppSelector(selectParamsCustomers);

  useEffect(() => {
    const country = countryData.find(
      country => country.name === params.country,
    );
    const business = businessTypeList.find(
      type => type.value === params.businessType,
    );
    const workspaceId = params?.workspace
      ? params.workspace.split(',').map(w => {
          const result = workspaces.find(workspace => workspace.label === w);
          return result.value;
        })
      : undefined;
    let initialProduct: any = null;
    switch (params?.product) {
      case 'HOT_WALLET,COLD_WALLET':
        initialProduct = [WorkSpaceType.Hot, WorkSpaceType.Cold];
        break;
      case 'HOT_WALLET':
        initialProduct = [WorkSpaceType.Hot];
        break;
      case 'COLD_WALLET':
        initialProduct = [WorkSpaceType.Cold];
        break;
      default:
        initialProduct = [];
        break;
    }

    const initialStatus = params?.customerStatus
      ? params.customerStatus.toLocaleLowerCase().split(',')
      : [];

    const submitData = {
      businessType: business?.id ?? null,
      country: country?.id ?? null,
      workspace: workspaceId,
      customerStatus: params.customerStatus?.toLowerCase() ?? null,
    };

    form.setFieldsValue(submitData);
    setProduct(initialProduct);
    setIsDisabledWorkspace(!(initialProduct.length > 0));

    setCustomerStatus(initialStatus);
    const values = form.getFieldsValue();
    const isEmpty = Object.values(values).some(e => !!e);

    setIsDisabledReset(
      !isEmpty && customerStatus.length === 0 && product.length === 0,
    );
  }, [isOpen, params]);

  useEffect(() => {
    setIsDisabledWorkspace(!(product.length > 0));
    const values = form.getFieldsValue();
    const isEmpty = Object.values(values).some(e => !!e);
    setIsDisabledReset(
      !isEmpty && customerStatus.length === 0 && product.length === 0,
    );
  }, [product, customerStatus]);

  const onValuesChange = useCallback((_, values) => {
    const isEmpty = Object.values(values).some(e => !!e);
    setIsDisabledReset(
      !isEmpty && customerStatus.length === 0 && product.length === 0,
    );
  }, []);

  const onChangeProduct = (e: CheckboxValueType[]) => {
    let newWorkspaces: number[] = [];
    const oldWorkspaces = form.getFieldValue('workspace');
    if (e.length === 0) {
      setIsDisabledWorkspace(true);
      form.setFieldValue('product', '');
    } else if (e.length === 2) {
      setIsDisabledWorkspace(false);
      const newWorkspace = hotWorkspaces.concat(coldWorkspaces);
      setWorkspaces(newWorkspace);
      form.setFieldValue('product', 'HOT_WALLET,COLD_WALLET');
      newWorkspaces = oldWorkspaces ? [...oldWorkspaces] : [];
    } else {
      setIsDisabledWorkspace(false);
      const workspacesList =
        e[0] === WorkSpaceType.Hot ? hotWorkspaces : coldWorkspaces;
      const product = e[0] === WorkSpaceType.Hot ? 'HOT_WALLET' : 'COLD_WALLET';

      oldWorkspaces?.forEach(element => {
        if (workspacesList.some(workspace => workspace.value === element)) {
          newWorkspaces.push(element);
        }
      });
      setWorkspaces(workspacesList);
      form.setFieldValue('product', product);
    }
    form.setFieldValue(
      'workspace',
      newWorkspaces.length === 0 ? undefined : newWorkspaces,
    );
    setProduct(e);
  };

  const onChangeStatus = (e: CheckboxValueType[]) => {
    setCustomerStatus(e);
    const values = form.getFieldsValue();
    const isEmpty = Object.values(values).some(e => !!e);
    setIsDisabledReset(
      !isEmpty && customerStatus.length === 0 && product.length === 0,
    );
  };

  const onClickReset = () => {
    form.setFieldsValue({
      businessType: null,
      country: null,
      product: null,
      workspace: undefined,
      customerStatus: null,
    });
    setProduct([]);
    setCustomerStatus([]);
  };

  const onFinish = (values: any) => {
    const formValues = form.getFieldsValue(true);
    const country = countryData.find(country => country.id === values.country);
    const business = businessTypeList.find(
      type => type.id === values.businessType,
    );

    const workspaceName = formValues.workspace
      ? formValues.workspace.map(w => {
          const result = workspaces.find(workspace => workspace.value === w);
          return result.label;
        })
      : [];

    const submitData = {
      businessType: business?.value ?? '',
      country: country?.name ?? '',
      product: formValues?.product ?? '',
      customerStatus: customerStatus.join(',').toUpperCase(),
      workspace: workspaceName.join(',') ?? '',
    };

    onFinishFilter(submitData);
  };

  return (
    <div className={styles.container}>
      <Form
        form={form}
        layout="vertical"
        name="bussinessForm"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <Row>
          <Col span={12} className={styles.title}>
            Filter
          </Col>
          <Col span={12} className={styles.reset}>
            <Button
              data-testid="reset-button"
              type="link"
              onClick={onClickReset}
              className={clsx({ [styles.disabled__button]: isDisabledReset })}
            >
              Clear all
            </Button>
          </Col>
        </Row>
        <Col span="24" data-testid="business-type">
          <BasicSelect
            options={businessTypeList || []}
            placeholder="Select..."
            label="Business type"
            name={'businessType'}
            labelValue="name"
            keyValue="id"
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Col>
        <Col span="24" data-testid="country">
          <BasicSelect
            options={countryData}
            placeholder="Select..."
            label="Country"
            name={'country'}
            labelValue="name"
            keyValue="id"
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Col>
        <Divider></Divider>
        <Col>
          <Form.Item label={<span className={styles.label}>Product</span>}>
            <Styled>
              <Checkbox.Group
                data-testid="product"
                options={[
                  { label: 'Warm Wallet', value: WorkSpaceType.Hot },
                  { label: 'Cold Wallet', value: WorkSpaceType.Cold },
                ]}
                onChange={(value: CheckboxValueType[]) =>
                  onChangeProduct(value)
                }
                value={product}
              />
            </Styled>
          </Form.Item>
        </Col>
        <Col
          className={clsx(styles.multiple__select, {
            [styles.has__scrollbar]: window.innerHeight <= 720,
            [styles.no__scrollbar]: window.innerHeight > 720,
          })}
        >
          <Form.Item
            label={<span className={styles.label}>Workspace</span>}
            name={'workspace'}
          >
            <Select
              data-testid="workspace"
              disabled={isDisabledWorkspace}
              placeholder="Select..."
              allowClear
              showSearch
              mode="multiple"
              showArrow
            >
              {workspaces.map(workspace => {
                return (
                  <Option value={workspace.value}>
                    <span>{workspace.label}</span>
                    <br />
                    <span className={styles.workspace__type}>
                      {workspace.type}
                    </span>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Divider></Divider>

        <Col>
          <Form.Item
            label={<span className={styles.label}>Customer’s status</span>}
          >
            <Styled>
              <Checkbox.Group
                data-testid="customer-status"
                options={[
                  { label: 'Active', value: 'active' },
                  { label: 'Inactive', value: 'inactive' },
                ]}
                onChange={(value: CheckboxValueType[]) => onChangeStatus(value)}
                value={customerStatus}
              />
            </Styled>
          </Form.Item>
        </Col>
        <Divider></Divider>
        <Row style={{ justifyContent: 'end' }} data-testid="submit-button">
          <RButton
            htmlType="submit"
            buttonType="submit"
            content="Apply"
            width={75}
            height={32}
            onClick={() => {}}
            style={{ padding: '0' }}
          />
        </Row>
      </Form>
    </div>
  );
};

export default FilterContent;
