import {
  LastestRiskLevel,
  PurposeOfRelationship,
  SourceOfWealth,
} from 'enum/addNewCustomer';
import { DOCUMENT_TYPE } from 'enum/common';

export enum BusinessTypeOther {
  OTHER = 'Other (please specify)',
}

export const purposeOfRelationshipOpts = [
  {
    label: 'Qualified custodian per regulation',
    value: PurposeOfRelationship.QUALIFIED_CUSTODIAN_PER_REGULATION,
  },
  {
    label: 'Securely store digital assets',
    value: PurposeOfRelationship.SECURELY_STORE_DIGITAL_ASSETS,
  },
  {
    label: 'Yield generation',
    value: PurposeOfRelationship.YIELD_GENERATION,
  },
  {
    label: 'Other (please specify)',
    value: PurposeOfRelationship.OTHER,
  },
];

export const latestRiskLevelOpts = [
  {
    label: 'High',
    value: LastestRiskLevel.HIGH,
  },
  {
    label: 'Medium',
    value: LastestRiskLevel.MEDIUM,
  },
  {
    label: 'Low',
    value: LastestRiskLevel.LOW,
  },
];

export const registrationAddressDefault = {
  registrationCountry: '',
  registrationCity: '',
  registrationAddress: '',
  registrationPostalCode: '',
};

export const addressInfoDefault = {
  registrationAddress: {
    country: 'Singapore',
    city: '',
    address: '',
  },
  currentAddress: {
    country: 'Singapore',
    city: '',
    address: '',
  },
  billingAddress: {
    country: 'Singapore',
    city: '',
    address: '',
  },
};

export const sourceOfWealthOpts = [
  {
    label: 'Business operations',
    value: SourceOfWealth.BUSINESS_OPERATIONS,
  },
  {
    label: 'Family / generational wealth',
    value: SourceOfWealth.FAMILY_GENERATIONAL_WEALTH,
  },
  {
    label: 'Investment activities',
    value: SourceOfWealth.INVESTMENT_ACTIVITIES,
  },
  {
    label: 'Other (please specify)',
    value: SourceOfWealth.OTHER,
  },
];

export const documentTypeOpts = [
  {
    label: 'ID Card',
    value: DOCUMENT_TYPE.ID_CARD,
  },
  {
    label: 'Passport',
    value: DOCUMENT_TYPE.PASSPORT,
  },
];
