import { PayloadAction } from '@reduxjs/toolkit';
import ToastMessage from 'app/components/common/ToastMessage';
import {
  CreateFeeInformationReq,
  UpdateFeeInformationReq,
} from 'interfaces/businessInformation';
import { ListVaultsUnAssignedRequest } from 'interfaces/customers';
import {
  CustomerProfileRequest,
  GetListUserRequest,
  MarkAsLostDeviceRequest,
  RemoveUserAccess,
  ReplaceLostDeviceRequest,
  ResendEmailRequest,
  UnlockUserRequest,
} from 'interfaces/userListing';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  addNewCustomerFee,
  getCustomerAccountAdminPolicyById,
  getCustomerById,
  getCustomerFeeById,
  getUserDetailById,
  getUserListing,
  getVaultsUnAssigned,
  markAsLostDevice,
  removeUserAccess,
  replaceLostDevice,
  resendEmail,
  unlockUser,
  updateCustomerFee,
} from 'services/customerProfile';
import { updateUser } from 'services/customers';
import { commonActions } from 'store/slices/common';
import {
  customerProfileActions,
  selectParamsUserList,
} from 'store/slices/customerProfile';
import { customersActions } from 'store/slices/customers';
import { handleShowMsgErr } from 'utils/common';

const handleFetchUserListingRequest = function* ({
  payload,
}: PayloadAction<GetListUserRequest>) {
  try {
    const res = yield call(getUserListing, payload.customerId, payload.params);
    const params = yield select(selectParamsUserList);
    yield put(
      customerProfileActions.setCanLoadMoreUserListing(
        params.offset + params.limit < res.totalCount,
      ),
    );

    let newRes = { ...res };
    const paramsUserList = yield select(
      state => state.customerProfile.paramsUserList,
    ) as any;
    const userList = yield select(
      state => state.customerProfile?.userListing?.data?.users,
    ) as any;

    if (paramsUserList?.offset > 0 && userList) {
      newRes.users = [...userList, ...newRes.users];
    }
    yield put(customerProfileActions.fetchUserListingSuccess(newRes));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customerProfileActions.fetchUserListingFailed());
  }
};

const handleFetchCustomerByIdRequest = function* ({
  payload,
}: PayloadAction<CustomerProfileRequest>) {
  try {
    const res = yield call(getCustomerById, payload.customerId, payload.query);
    yield put(customerProfileActions.fetchCustomerByIdSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customerProfileActions.fetchCustomerByIdFailed());
  }
};

const handleForceScreenUserListingRequest = function* ({
  payload,
}: PayloadAction<{ customerId: string }>) {
  try {
    const defaultParams = yield select(
      state => state.customerProfile.paramsUserList,
    ) as any;

    const [users] = yield all([
      call(getUserListing, payload.customerId, defaultParams),
    ]);
    yield put(customerProfileActions.forceScreenSuccess());
    yield put(customerProfileActions.fetchUserListingSuccess(users));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customerProfileActions.forceScreenFailed());
  }
};

// eslint-disable-next-line require-yield
const handleFetchUserDetailRequest = function* ({
  payload,
}: PayloadAction<any>) {
  try {
    const res = yield call(getUserDetailById, payload.id);
    yield put(customerProfileActions.fetchUserDetailSuccess(res));
  } catch (error: any) {
    yield put(customerProfileActions.fetchUserDetailFailed());
  }
};

const handleMarkDeviceAsLostRequest = function* ({
  payload,
}: PayloadAction<MarkAsLostDeviceRequest>) {
  try {
    const res = yield call(markAsLostDevice, payload);
    if (res) {
      yield put(customerProfileActions.markAsLostDeviceSuccess());
      toast(
        <ToastMessage
          type="info"
          message="The request has been submitted for approval"
        />,
      );
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error?.response?.data);
    yield put(customerProfileActions.markAsLostDeviceFailed());
  }
};

const handleResendEmailRequest = function* ({
  payload,
}: PayloadAction<ResendEmailRequest>) {
  try {
    const res = yield call(resendEmail, payload);
    if (res) {
      yield put(customerProfileActions.resendEmailSuccess());
      toast(
        <ToastMessage
          type="info"
          message="The request has been submitted for approval"
        />,
      );
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error?.response?.data);
    yield put(customerProfileActions.resendEmailFailed());
  }
};

const handleReplaceLostRequest = function* ({
  payload,
}: PayloadAction<ReplaceLostDeviceRequest>) {
  try {
    const res = yield call(replaceLostDevice, payload);
    if (res) {
      yield put(customerProfileActions.replaceLostDeviceSuccess());
      toast(
        <ToastMessage
          type="info"
          message="The request has been submitted for approval"
        />,
      );
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error?.response?.data);
    yield put(customerProfileActions.replaceLostDeviceFailed());
  }
};

const handleUnlockRequest = function* ({
  payload,
}: PayloadAction<UnlockUserRequest>) {
  try {
    const res = yield call(unlockUser, payload);
    if (res) {
      yield put(customerProfileActions.unlockUserSuccess());
      toast(
        <ToastMessage
          type="info"
          message="The request has been submitted for approval"
        />,
      );
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error?.response?.data);
    yield put(customerProfileActions.unlockUserFailed());
  }
};

const handleFetchCustomerFeeByIdRequest = function* ({
  payload,
}: PayloadAction<{ customerId: string }>) {
  try {
    const res = yield call(getCustomerFeeById, payload.customerId);
    yield put(customerProfileActions.fetchCustomerFeeByIdSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customerProfileActions.fetchCustomerFeeByIdFailed());
  }
};

const handleRemoveAccessRequest = function* ({
  payload,
}: PayloadAction<RemoveUserAccess>) {
  try {
    const res = yield call(removeUserAccess, payload.userId, payload.params);
    const accountIsRequiredApprover = res.accountLVCheck.isRequiredApprover;
    const advancedQuorumVaults = res?.vaultLVCheck?.filter(
      vaultCheck => vaultCheck.isInAdvancedQuorum,
    );
    const vaultIsRequiredApprover = res?.vaultLVCheck?.filter(
      vaultCheck => vaultCheck.isRequiredApprover,
    );
    const vaultIsValidNumUserInQuorum = res?.vaultLVCheck?.filter(
      vaultCheck => !vaultCheck.isValidNumUserInQuorum,
    );

    //failed advanced quorum
    if (advancedQuorumVaults.length !== 0) {
      yield put(
        customerProfileActions.removeUserAccessFailedAdvancedVaults(
          advancedQuorumVaults,
        ),
      );
      return;
    }

    //failed only required approver acc level
    if (accountIsRequiredApprover && !vaultIsRequiredApprover.length) {
      yield put(
        customerProfileActions.removeUserAccessFailedRequired(undefined),
      );
      return;
    }
    // both required approver acc level and vault
    if (accountIsRequiredApprover && !!vaultIsRequiredApprover.length) {
      yield put(
        customerProfileActions.removeUserFailedRequiredAndVault(
          vaultIsRequiredApprover,
        ),
      );
      return;
    }

    const accountIsValidNumUserInQuorum =
      res.accountLVCheck.isValidNumUserInQuorum;

    //only unmet minimum quorum size
    if (
      typeof accountIsValidNumUserInQuorum === 'boolean' &&
      !accountIsValidNumUserInQuorum &&
      !vaultIsValidNumUserInQuorum.length
    ) {
      yield put(customerProfileActions.removeUserAccessFailedQuorum(undefined));
      return;
    }

    // both unmet minimum quorum size and vault
    if (
      typeof accountIsValidNumUserInQuorum === 'boolean' &&
      !accountIsValidNumUserInQuorum &&
      !!vaultIsValidNumUserInQuorum.length
    ) {
      yield put(
        customerProfileActions.removeUserUnmetQuorumAndVault(
          vaultIsValidNumUserInQuorum,
        ),
      );
      return;
    }

    if (vaultIsRequiredApprover && vaultIsRequiredApprover.length !== 0) {
      yield put(
        customerProfileActions.removeUserAccessFailedRequired(
          vaultIsRequiredApprover,
        ),
      );
      return;
    }

    if (vaultIsRequiredApprover && vaultIsValidNumUserInQuorum.length !== 0) {
      yield put(
        customerProfileActions.removeUserAccessFailedQuorum(
          vaultIsValidNumUserInQuorum,
        ),
      );
      return;
    }

    const checkRemoveSuccess = yield call(
      updateUser,
      payload.userId,
      payload.params,
    );

    if (checkRemoveSuccess) {
      yield put(customerProfileActions.removeUserAccessSuccess());
      toast(
        <ToastMessage
          type="info"
          message="The request has been submitted for approval"
        />,
      );
      yield put(customersActions.editUserSuccess(res));
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error?.response?.data);
    yield put(customerProfileActions.removeUserAccessFailed());
  }
};

const handleAddNewFeeInformationRequest = function* ({
  payload,
}: PayloadAction<{ customerId: string; body: CreateFeeInformationReq }>) {
  try {
    const res = yield call(addNewCustomerFee, payload.customerId, payload.body);
    if (res) {
      yield put(customerProfileActions.addNewFeeInformationSuccess(res));
      toast(
        <ToastMessage
          type="info"
          message="The request has been submitted for approval"
        />,
      );
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error, 'Failed to update the fee request');
    yield put(customerProfileActions.addNewFeeInformationFailed());
  }
};

const handleUpdateFeeInformationRequest = function* ({
  payload,
}: PayloadAction<{
  customerId: string;
  feeInformationId: string;
  body: UpdateFeeInformationReq;
}>) {
  try {
    const res = yield call(
      updateCustomerFee,
      payload.customerId,
      payload.feeInformationId,
      payload.body,
    );
    if (res) {
      yield put(customerProfileActions.updateFeeInformationSuccess(res));
      toast(
        <ToastMessage
          type="info"
          message="The request has been submitted for approval"
        />,
      );
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customerProfileActions.updateFeeInformationFailed());
  }
};

const handleFetchCustomerAccountAdminPolicyByIdRequest = function* ({
  payload,
}: PayloadAction<{ customerId: string; query: { ignoreStatus?: boolean } }>) {
  try {
    const res = yield call(
      getCustomerAccountAdminPolicyById,
      payload.customerId,
      payload.query,
    );
    yield put(customerProfileActions.fetchCustomerAccPolicySuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customerProfileActions.fetchCustomerAccPolicyFailed());
  }
};

const handleFetchVaultsUnAssignedRequest = function* ({
  payload,
}: PayloadAction<ListVaultsUnAssignedRequest>) {
  try {
    const res = yield call(getVaultsUnAssigned, payload);
    yield put(customerProfileActions.fetchVaultsUnAssignedSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customerProfileActions.fetchVaultsUnAssignedFailed());
  }
};

const customerProfileSaga = function* () {
  yield takeLatest(
    customerProfileActions.fetchUserListingRequest.type,
    handleFetchUserListingRequest,
  );

  yield takeLatest(
    customerProfileActions.fetchCustomerByIdRequest.type,
    handleFetchCustomerByIdRequest,
  );

  yield takeLatest(
    customerProfileActions.forceScreenRequest.type,
    handleForceScreenUserListingRequest,
  );

  yield takeLatest(
    customerProfileActions.fetchUserDetailRequest.type,
    handleFetchUserDetailRequest,
  );
  yield takeLatest(
    customerProfileActions.markAsLostDeviceRequest.type,
    handleMarkDeviceAsLostRequest,
  );

  yield takeLatest(
    customerProfileActions.resendEmailRequest.type,
    handleResendEmailRequest,
  );
  yield takeLatest(
    customerProfileActions.replaceLostDeviceRequest.type,
    handleReplaceLostRequest,
  );

  yield takeLatest(
    customerProfileActions.removeUserAccessRequest.type,
    handleRemoveAccessRequest,
  );

  yield takeLatest(
    customerProfileActions.fetchCustomerFeeByIdRequest.type,
    handleFetchCustomerFeeByIdRequest,
  );

  yield takeLatest(
    customerProfileActions.addNewFeeInformationRequest.type,
    handleAddNewFeeInformationRequest,
  );

  yield takeLatest(
    customerProfileActions.updateFeeInformationRequest.type,
    handleUpdateFeeInformationRequest,
  );

  yield takeLatest(
    customerProfileActions.fetchCustomerAccPolicyRequest.type,
    handleFetchCustomerAccountAdminPolicyByIdRequest,
  );

  yield takeLatest(
    customerProfileActions.fetchVaultsUnAssignedRequest.type,
    handleFetchVaultsUnAssignedRequest,
  );

  yield takeLatest(
    customerProfileActions.unlockUserRequest.type,
    handleUnlockRequest,
  );
};
export default customerProfileSaga;
