import { Col, Collapse, Divider, Row, Space, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { SvgIcon } from 'app/components/SvgIcon';
import { FORMAT_DATE } from 'constants/index';
import { CustomerQuorumUser } from 'interfaces/customers';
import { RequestStatus } from 'interfaces/request';
import { UserInfoDetail } from 'interfaces/userListing';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  customerProfileActions,
  selectCustomerAccountQuorum,
  selectUserDetail,
} from 'store/slices/customerProfile';
import { getTextAddress, mapCountry } from 'utils/helpers';
import styles from './index.module.scss';
import { dots } from 'app/components/common/TextInfor';

const CustomSpace = ({ children }: { children: React.ReactNode }) => {
  return (
    <Space direction="vertical" size={4}>
      {children}
    </Space>
  );
};

type QuorumUserDetail = {
  [userId: string]: UserInfoDetail | undefined;
};

type AdminQuorumTabType = {
  id: string;
  isMaskedProfile: boolean;
};

export const AdminQuorumTab = ({ id, isMaskedProfile }: AdminQuorumTabType) => {
  const dispatch = useAppDispatch();
  const customerAccQuorum = useAppSelector(selectCustomerAccountQuorum);
  const userDetail = useAppSelector(selectUserDetail);
  const [quorumUsers, setQuorumUsers] = useState<QuorumUserDetail>({});

  const quorumSize = useMemo(() => {
    return customerAccQuorum?.data?.approverNumber || 0;
  }, [customerAccQuorum]);

  const users = useMemo(() => {
    const sortByName = (a: CustomerQuorumUser, b: CustomerQuorumUser) => {
      return a.name.localeCompare(b.name);
    };

    const items = customerAccQuorum?.data?.quorumParticipants || [];
    const aSet = items.filter(i => i.requiredApprover);
    const bSet = items.filter(i => !i.requiredApprover);

    return [...aSet.sort(sortByName), ...bSet.sort(sortByName)];
  }, [customerAccQuorum]);

  const numberOfUserRequiredApprover = useMemo(() => {
    return (
      customerAccQuorum?.data?.quorumParticipants?.filter(
        f => f.requiredApprover,
      )?.length || 0
    );
  }, [customerAccQuorum]);

  const handleCollapsePanelChanged = useCallback(
    (userIds: string[]) => {
      const missingDetials = userIds.filter(
        id => !Object.keys(quorumUsers).some(key => key === id),
      );
      missingDetials.map(id =>
        dispatch(customerProfileActions.fetchUserDetailRequest({ id })),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quorumUsers],
  );

  useEffect(() => {
    if (userDetail?.status === RequestStatus.Success && userDetail?.data) {
      const userId = userDetail.data.userId;
      setQuorumUsers(prev => {
        return {
          ...prev,
          ...{
            [userId]: userDetail.data,
          },
        };
      });
    }
  }, [userDetail]);

  useEffect(() => {
    dispatch(
      customerProfileActions.fetchCustomerAccPolicyRequest({
        customerId: id,
        query: { ignoreStatus: true },
      }),
    );

    setQuorumUsers({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (customerAccQuorum?.status === RequestStatus.Loading) {
    return (
      <div className={styles.loading}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.tab}>
      <Space className={styles.total} size="middle" align="center">
        <Space direction="vertical" size={0} align="start">
          <span className={styles.total_role_label_1}>Quorum</span>
          <span className={styles.total_role_label_2}>
            {quorumSize} of {users.length}
          </span>
        </Space>
        <Space direction="vertical" size={0} align="start">
          <span className={styles.total_role_label_1}>
            Required{' '}
            <SvgIcon
              width={12}
              height={12}
              name="required-approver"
              className={styles.require__approver__icon}
            />
          </span>
          <span className={styles.total_role_label_2}>
            {numberOfUserRequiredApprover}
          </span>
        </Space>
      </Space>

      <Collapse
        ghost
        expandIconPosition="end"
        className={styles.collapse}
        onChange={key =>
          handleCollapsePanelChanged(typeof key === 'string' ? [key] : [...key])
        }
        collapsible="disabled"
      >
        {users.map((admin: CustomerQuorumUser) => (
          <CollapsePanel
            header={
              <Divider
                orientationMargin="0"
                className={styles.user__name}
                orientation="left"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isMaskedProfile ? dots() : admin.name}
                  <div className={styles.role}>{admin.roleDisplayName}</div>
                  {admin.requiredApprover && (
                    <SvgIcon
                      width={20}
                      height={20}
                      name="is-admin"
                      className={styles.require__admin__icon}
                    />
                  )}
                </div>
              </Divider>
            }
            key={admin.userId}
            style={{ cursor: 'pointer' }}
          >
            {userDetail?.status === RequestStatus.Loading &&
            !quorumUsers?.[admin.userId] ? (
              <div className={styles.loading}>
                <Spin />
              </div>
            ) : (
              <>
                <Divider orientationMargin="0" orientation="left">
                  <span className={styles.info__title}>Legal information</span>
                </Divider>
                <Row gutter={[0, 16]}>
                  <Col span={12}>
                    <CustomSpace>
                      <span className={styles.label}>
                        {quorumUsers?.[admin.userId]?.passportIssuedCountry &&
                          'Passport number'}
                        {quorumUsers?.[admin.userId]?.idCardIssuedCountry &&
                          'ID number'}
                      </span>
                      <span className={styles.text}>
                        {quorumUsers?.[admin.userId]?.passportNumber ||
                          quorumUsers?.[admin.userId]?.idCardNumber ||
                          ''}
                      </span>
                    </CustomSpace>
                  </Col>
                  <Col span={12}>
                    <CustomSpace>
                      <span className={styles.label}>
                        {quorumUsers?.[admin.userId]?.passportIssuedCountry &&
                          'Passport issued country'}
                        {quorumUsers?.[admin.userId]?.idCardIssuedCountry &&
                          'ID card issued country'}
                      </span>
                      <span className={styles.text}>
                        {mapCountry(
                          quorumUsers?.[admin.userId]?.passportIssuedCountry ||
                            quorumUsers?.[admin.userId]?.idCardIssuedCountry ||
                            '',
                        )}
                      </span>
                    </CustomSpace>
                  </Col>
                </Row>

                <Divider orientationMargin="0" orientation="left">
                  <span className={styles.info__title}>
                    Contact information
                  </span>
                </Divider>
                <Row gutter={[0, 16]}>
                  <Col span={12}>
                    <CustomSpace>
                      <span className={styles.label}>Phone number</span>
                      <span className={styles.text}>
                        {quorumUsers?.[admin.userId]?.phoneNumber}
                      </span>
                    </CustomSpace>
                  </Col>
                  <Col span={12}>
                    <CustomSpace>
                      <span className={styles.label}>Email</span>
                      <span className={styles.text}>
                        {quorumUsers?.[admin.userId]?.email}
                      </span>
                    </CustomSpace>
                  </Col>
                  <Col span={12}>
                    <CustomSpace>
                      <span className={styles.label}>Nationality</span>
                      <span className={styles.text}>
                        {quorumUsers?.[admin.userId]?.nationality
                          ? mapCountry(
                              quorumUsers?.[admin.userId]?.nationality || '',
                            )
                          : '-'}
                      </span>
                    </CustomSpace>
                  </Col>
                  <Col span={12}>
                    <CustomSpace>
                      <span className={styles.label}>Date of birth</span>
                      <span className={styles.text}>
                        {quorumUsers?.[admin.userId]?.dateOfBirth
                          ? moment(
                              quorumUsers?.[admin.userId]?.dateOfBirth,
                            ).format(FORMAT_DATE.DEFAULT)
                          : '-'}
                      </span>
                    </CustomSpace>
                  </Col>
                  <Col span={24}>
                    <CustomSpace>
                      <span className={styles.label}>Residential address</span>
                      <span className={styles.text}>
                        {quorumUsers?.[admin.userId]?.address
                          ? getTextAddress(quorumUsers?.[admin.userId]?.address)
                          : '-'}
                      </span>
                    </CustomSpace>
                  </Col>
                </Row>
                <Divider />
              </>
            )}
          </CollapsePanel>
        ))}
      </Collapse>
    </div>
  );
};
