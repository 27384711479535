import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  TransactionTierSigner,
  TransactionChecklist,
} from 'interfaces/transaction';
import { AppState } from 'store';

export interface TransactionState {
  transactionDetail: ResponseData<any>;
  markAsReviewResponse: ResponseData<any>;
  reviewerByTxnIdData: ResponseData<any>;
  reviewChecklist: ResponseData<TransactionChecklist>;
  unfreezeTransactionResponse: ResponseData<any>;
  rejectUnfreezeTransactionResponse: ResponseData<any>;
  transactionTier: ResponseData<TransactionTierSigner[]>;
}

const initialState: TransactionState = {
  transactionDetail: {
    status: RequestStatus.Idle,
  },
  markAsReviewResponse: {
    status: RequestStatus.Idle,
  },
  reviewerByTxnIdData: {
    status: RequestStatus.Idle,
  },
  reviewChecklist: {
    status: RequestStatus.Idle,
  },
  unfreezeTransactionResponse: {
    status: RequestStatus.Idle,
  },
  rejectUnfreezeTransactionResponse: {
    status: RequestStatus.Idle,
  },
  transactionTier: {
    status: RequestStatus.Idle,
  },
};

const getTransactionDetailByTxnIdRequest: CaseReducer<
  TransactionState,
  PayloadAction<{ txId: string }>
> = (state: TransactionState) => {
  state.transactionDetail.status = RequestStatus.Loading;
};

const getTransactionDetailByTxnIdSuccess: CaseReducer<
  TransactionState,
  PayloadAction<any>
> = (state: TransactionState, { payload }: PayloadAction<any>) => {
  state.transactionDetail.status = RequestStatus.Success;
  state.transactionDetail.data = payload;
};

const getTransactionDetailByTxnIdFailed: CaseReducer<TransactionState> = (
  state: TransactionState,
) => {
  state.transactionDetail.status = RequestStatus.Failed;
};

const markTransactionAsReviewRequest: CaseReducer<
  TransactionState,
  PayloadAction<{ txId: string; body: any }>
> = (state: TransactionState) => {
  state.markAsReviewResponse.status = RequestStatus.Loading;
};

const markTransactionAsReviewSuccess: CaseReducer<
  TransactionState,
  PayloadAction<any>
> = (state: TransactionState, { payload }: PayloadAction<any>) => {
  state.markAsReviewResponse.status = RequestStatus.Success;
};

const markTransactionAsReviewFailed: CaseReducer<TransactionState> = (
  state: TransactionState,
) => {
  state.markAsReviewResponse.status = RequestStatus.Failed;
};

const unFreezeTransactionRequest: CaseReducer<
  TransactionState,
  PayloadAction<{ txId: string }>
> = (state: TransactionState) => {
  state.unfreezeTransactionResponse.status = RequestStatus.Loading;
};

const unFreezeTransactionSuccess: CaseReducer<
  TransactionState,
  PayloadAction<any>
> = (state: TransactionState, { payload }: PayloadAction<any>) => {
  state.unfreezeTransactionResponse.status = RequestStatus.Success;
};

const unFreezeTransactionFailed: CaseReducer<TransactionState> = (
  state: TransactionState,
) => {
  state.unfreezeTransactionResponse.status = RequestStatus.Failed;
};

const rejectUnfreezeTransactionRequest: CaseReducer<
  TransactionState,
  PayloadAction<{ txId: string; reason: string }>
> = (state: TransactionState) => {
  state.rejectUnfreezeTransactionResponse.status = RequestStatus.Loading;
};

const rejectUnfreezeTransactionSuccess: CaseReducer<
  TransactionState,
  PayloadAction<any>
> = (state: TransactionState, { payload }: PayloadAction<any>) => {
  state.rejectUnfreezeTransactionResponse.status = RequestStatus.Success;
};

const rejectUnfreezeTransactionFailed: CaseReducer<TransactionState> = (
  state: TransactionState,
) => {
  state.rejectUnfreezeTransactionResponse.status = RequestStatus.Failed;
};

const getReviewerByTxnIdRequest: CaseReducer<
  TransactionState,
  PayloadAction<{ txId: string }>
> = (state: TransactionState) => {
  state.reviewerByTxnIdData.status = RequestStatus.Loading;
};

const getReviewerByTxnIdSuccess: CaseReducer<
  TransactionState,
  PayloadAction<any>
> = (state: TransactionState, { payload }: PayloadAction<any>) => {
  state.reviewerByTxnIdData.status = RequestStatus.Success;
  state.reviewerByTxnIdData.data = payload;
};

const getReviewerByTxnIdFailed: CaseReducer<TransactionState> = (
  state: TransactionState,
) => {
  state.reviewerByTxnIdData.status = RequestStatus.Failed;
};

const getReviewChecklistByTxnIdRequest: CaseReducer<
  TransactionState,
  PayloadAction<{ txId: string }>
> = (state: TransactionState) => {
  state.reviewChecklist.status = RequestStatus.Loading;
};

const getReviewChecklistByTxnIdSuccess: CaseReducer<
  TransactionState,
  PayloadAction<any>
> = (
  state: TransactionState,
  { payload }: PayloadAction<TransactionChecklist>,
) => {
  state.reviewChecklist.status = RequestStatus.Success;
  state.reviewChecklist.data = payload;
};

const resetRejectUnfreezeTransaction: CaseReducer<TransactionState> = (
  state: TransactionState,
) => {
  state.rejectUnfreezeTransactionResponse.status = RequestStatus.Idle;
};

const getTransactionTierRequest: CaseReducer<TransactionState> = (
  state: TransactionState,
) => {
  state.transactionTier.status = RequestStatus.Loading;
};

const getTransactionTierSuccess: CaseReducer<
  TransactionState,
  PayloadAction<any>
> = (
  state: TransactionState,
  { payload }: PayloadAction<TransactionTierSigner[]>,
) => {
  state.transactionTier.status = RequestStatus.Success;
  state.transactionTier.data = payload;
};

const getTransactionTierFailed: CaseReducer<TransactionState> = (
  state: TransactionState,
) => {
  state.transactionTier.status = RequestStatus.Failed;
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    getTransactionDetailByTxnIdRequest,
    getTransactionDetailByTxnIdSuccess,
    getTransactionDetailByTxnIdFailed,

    markTransactionAsReviewRequest,
    markTransactionAsReviewSuccess,
    markTransactionAsReviewFailed,

    unFreezeTransactionRequest,
    unFreezeTransactionSuccess,
    unFreezeTransactionFailed,

    rejectUnfreezeTransactionRequest,
    rejectUnfreezeTransactionSuccess,
    rejectUnfreezeTransactionFailed,
    resetRejectUnfreezeTransaction,

    getReviewerByTxnIdRequest,
    getReviewerByTxnIdSuccess,
    getReviewerByTxnIdFailed,

    getReviewChecklistByTxnIdRequest,
    getReviewChecklistByTxnIdSuccess,

    getTransactionTierRequest,
    getTransactionTierSuccess,
    getTransactionTierFailed,
  },
});

// Actions
export const transactionActions = transactionSlice.actions;

// Reducer
export const transactionReducer = transactionSlice.reducer;

// Selectors
export const selectTransactionDetail = (state: AppState) =>
  state?.transaction?.transactionDetail;

export const selectReviewerByTxnIdData = (state: AppState) =>
  state?.transaction?.reviewerByTxnIdData;

export const selectReviewChecklist = (state: AppState) =>
  state?.transaction?.reviewChecklist;

export const selectUnfreezeTxnData = (state: AppState) =>
  state?.transaction?.unfreezeTransactionResponse;

export const selectRejectUnfreezeTxnData = (state: AppState) =>
  state?.transaction?.rejectUnfreezeTransactionResponse;

export const selectTransactionTier = (state: AppState) =>
  state?.transaction?.transactionTier;
