import { createSlice } from '@reduxjs/toolkit';
import { ApproveRequest, Params } from 'interfaces/request';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Sort, SortDirection } from 'enum/common';
import {
  ListPendingRequestResponse,
  ParamsRequest,
  RecordPendingRequest,
  StatusResponseApprove,
} from 'interfaces/pendingRequest';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import { AppState } from '../..';
import { PER_PAGE } from 'constants/index';
import { RequestPendingStatus } from 'enum/request';
import { mapRequest } from 'utils/helpers';
import { isHistoryPage } from 'utils/common';

interface RequestState {
  params: ParamsRequest;
  pendingRequestList: ResponseData<ListPendingRequestResponse>;
  reject: ResponseData<any>;
  requestDetail: ResponseData<any>;
  approve: ResponseData<any>;
  canLoadMore: boolean;
  forceScreen: ResponseData<any>;
  errorApprove: StatusResponseApprove;
}

export function getDefaultPendingRequestParams() {
  return {
    [Params.Page]: 1,
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.SortBy]: Sort.CREATED_DATE,
    [Params.Sort]: SortDirection.DESC,
  };
}

export function getDefaultPendingRequestAllParams() {
  return {
    [Params.Page]: 1,
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.SortBy]: Sort.CREATED_DATE,
    [Params.Sort]: SortDirection.DESC,
    [Params.isHistory]: true,
    [Params.Status]: [
      RequestPendingStatus.APPROVED,
      RequestPendingStatus.REJECTED,
      // RequestPendingStatus.PENDING,
    ],
  };
}

const initialState: RequestState = {
  params: isHistoryPage()
    ? getDefaultPendingRequestAllParams()
    : getDefaultPendingRequestParams(),
  pendingRequestList: {
    status: RequestStatus.Idle,
    data: { records: [], total: 0 },
  },
  reject: {
    status: RequestStatus.Idle,
  },
  requestDetail: {
    status: RequestStatus.Idle,
  },

  approve: {
    status: RequestStatus.Idle,
  },
  canLoadMore: false,

  forceScreen: {
    status: RequestStatus.Idle,
  },
  errorApprove: {
    showPopup: false,
    msg: '',
  },
};

const getPendingRequestListRequest: CaseReducer<
  RequestState,
  PayloadAction<any>
> = (state: RequestState) => {
  state.pendingRequestList.status = RequestStatus.Loading;
};

const getPendingRequestListSuccess: CaseReducer<
  RequestState,
  PayloadAction<ListPendingRequestResponse>
> = (
  state: RequestState,
  { payload }: PayloadAction<ListPendingRequestResponse>,
) => {
  state.pendingRequestList.status = RequestStatus.Success;
  state.pendingRequestList.data = payload;
};

const getPendingRequestListFailed: CaseReducer<
  RequestState,
  PayloadAction<string>
> = (state: RequestState, { payload }: PayloadAction<string>) => {
  state.pendingRequestList.status = RequestStatus.Failed;
  state.pendingRequestList.error = payload;
};

const resetRecordsPendingRequestList: CaseReducer<RequestState> = (
  state: RequestState,
) => {
  state.pendingRequestList.status = RequestStatus.Success;
  if (state.pendingRequestList?.data?.records) {
    state.pendingRequestList.data.records = [];
  }
};

const resetPendingRequestList: CaseReducer<RequestState> = (
  state: RequestState,
) => {
  state.params = getDefaultPendingRequestParams();
  state.pendingRequestList = {
    status: RequestStatus.Idle,
    data: { records: [], total: 0 },
  };
};

const resetPendingRequestAll: CaseReducer<RequestState> = (
  state: RequestState,
) => {
  state.params = getDefaultPendingRequestAllParams();
  state.pendingRequestList = {
    status: RequestStatus.Idle,
    data: { records: [], total: 0 },
  };
};

const resetPendings: CaseReducer<RequestState> = (state: RequestState) => {
  state.pendingRequestList = {
    status: RequestStatus.Idle,
    data: { records: [], total: 0 },
  };
};

const appendRequest = (
  state: RequestState,
  { payload }: PayloadAction<RecordPendingRequest[]>,
) => {
  state.pendingRequestList.status = RequestStatus.Success;
  if (state.pendingRequestList?.data?.records) {
    state.pendingRequestList.data.records = [...payload];
  }
};
// reject request
const updateRejectRequest: CaseReducer<RequestState, PayloadAction<any>> = (
  state: RequestState,
) => {
  state.reject.status = RequestStatus.Loading;
};

const updateRejectRequestSuccess: CaseReducer<RequestState> = (
  state: RequestState,
) => {
  state.reject.status = RequestStatus.Success;
};

const updateRejectRequestFailed: CaseReducer<
  RequestState,
  PayloadAction<string>
> = (state: RequestState, { payload }: PayloadAction<string>) => {
  state.reject.status = RequestStatus.Failed;
};
const approveRequest: CaseReducer<
  RequestState,
  PayloadAction<ApproveRequest>
> = (state: RequestState) => {
  state.approve.status = RequestStatus.Loading;
};

const approveSuccess: CaseReducer<RequestState> = (state: RequestState) => {
  state.approve.status = RequestStatus.Success;
};

const approveFailed: CaseReducer<RequestState> = (state: RequestState) => {
  state.approve.status = RequestStatus.Failed;
};

const setPendingRequests: CaseReducer<
  RequestState,
  PayloadAction<RecordPendingRequest[]>
> = (
  state: RequestState,
  { payload }: PayloadAction<RecordPendingRequest[]>,
) => {
  if (state.pendingRequestList?.data?.records) {
    state.pendingRequestList.data.records = payload;
    state.pendingRequestList.data.total = payload.length;
  }
};

const resetApproveRequest: CaseReducer<RequestState> = (
  state: RequestState,
) => {
  state.approve.status = RequestStatus.Idle;
};

const resetRejectRequest: CaseReducer<RequestState> = (state: RequestState) => {
  state.reject.status = RequestStatus.Idle;
};

const resetCustomerCreationRequest = state => {
  state.params = getDefaultPendingRequestParams();
  state.pendingRequestList = {
    status: RequestStatus.Idle,
  };

  state.approve = {
    status: RequestStatus.Idle,
  };
};

const setCanLoadMore = (
  state: RequestState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};

const setPendingFilterParams = (
  state: RequestState,
  { payload }: PayloadAction<ParamsRequest>,
) => {
  state.params = mapRequest({ ...state.params, ...payload });
};
//request detail
const getDetailPendingRequest: CaseReducer<RequestState, PayloadAction<any>> = (
  state: RequestState,
) => {
  state.requestDetail.status = RequestStatus.Loading;
};

const getDetailPendingRequestSuccess: CaseReducer<
  RequestState,
  PayloadAction<any>
> = (state: RequestState, { payload }: PayloadAction<any>) => {
  state.requestDetail.status = RequestStatus.Success;
  state.requestDetail.data = payload;
};

const getDetailPendingRequestFailed: CaseReducer<
  RequestState,
  PayloadAction<string>
> = (state: RequestState, { payload }: PayloadAction<string>) => {
  state.requestDetail.status = RequestStatus.Failed;
  state.requestDetail.error = payload;
};

const resetRequestDetail = (state: RequestState) => {
  state.requestDetail = {
    status: RequestStatus.Idle,
  };
};

const forceScreenRequest: CaseReducer<RequestState> = (state: RequestState) => {
  state.forceScreen.status = RequestStatus.Loading;
};

const forceScreenSuccess: CaseReducer<RequestState> = (state: RequestState) => {
  state.forceScreen.status = RequestStatus.Success;
};

const forceScreenFailed: CaseReducer<RequestState> = (state: RequestState) => {
  state.forceScreen.status = RequestStatus.Failed;
};

const updateStatusApprove: CaseReducer<
  RequestState,
  PayloadAction<StatusResponseApprove>
> = (
  state: RequestState,
  { payload }: PayloadAction<StatusResponseApprove>,
) => {
  state.errorApprove = payload;
};

const resetStatusApprove: CaseReducer<RequestState> = (state: RequestState) => {
  state.errorApprove = {
    showPopup: false,
    msg: '',
  };
};

const requestsSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    getPendingRequestListRequest,
    getPendingRequestListSuccess,
    getPendingRequestListFailed,
    appendRequest,

    resetRecordsPendingRequestList,

    resetPendingRequestList,
    resetPendingRequestAll,

    resetPendings,

    setPendingFilterParams,

    setCanLoadMore,

    updateRejectRequest,
    updateRejectRequestSuccess,
    updateRejectRequestFailed,
    resetRejectRequest,

    getDetailPendingRequest,
    getDetailPendingRequestSuccess,
    getDetailPendingRequestFailed,

    setPendingRequestParam<K extends keyof ParamsRequest>(
      state: RequestState,
      action: PayloadAction<{ key: K; value: ParamsRequest[K] }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
    },

    approveRequest,
    approveSuccess,
    approveFailed,
    resetApproveRequest,

    setPendingRequests,

    resetCustomerCreationRequest,
    resetRequestDetail,

    forceScreenRequest,
    forceScreenSuccess,
    forceScreenFailed,

    updateStatusApprove,
    resetStatusApprove,
  },
});

// Actions
export const requestActions = requestsSlice.actions;

// Reducer
export const requestsReducer = requestsSlice.reducer;

// Selectors
export const selectPendingRequestList = (state: AppState) =>
  state.requests?.pendingRequestList;

export const selectApproveRequest = (state: AppState) => state.requests.approve;

export const selectRejectRequest = (state: AppState) => state.requests?.reject;

export const selectParamsRequest = (state: AppState) => state.requests?.params;

export const selectCanLoadMore = (state: AppState) =>
  state.requests?.canLoadMore;

export const selectPendingRequestDetail = (state: AppState) =>
  state.requests.requestDetail;

export const selectStatusResponseApprove = (state: AppState) =>
  state.requests.errorApprove;
