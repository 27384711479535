import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getWcApplicationRequest,
  getDetailWcApplication,
  createWcApplicationRequest,
  updateWcApplicationRequest,
  getWcApplicationRequestPaginationConfig,
} from 'services/wcApplication.service';
import { wcApplicationActions } from 'store/slices/wcApplication/wcApplication.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetWcApplicationRequestParamDTO,
  ICreateWcApplication,
} from 'interfaces/wcApplication';
const handleGetWcApplicationRequest = function* ({
  payload,
}: PayloadAction<GetWcApplicationRequestParamDTO>) {
  try {
    const res = yield call(getWcApplicationRequest, payload);
    const params = yield select(state => state.wcApplication.params) as any;
    const listWcApplication = yield select(
      state => state.wcApplication?.wcApplication?.data?.listWcApplication,
    ) as any;
    if (res) {
      yield put(
        wcApplicationActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listWcApplication || []), ...res.list];
        yield put(wcApplicationActions.appendWcApplication(newList));
      } else {
        yield put(
          wcApplicationActions.getWcApplicationSuccess({
            list: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcApplicationActions.getWcApplicationFailed());
  }
};
const handleGetDetailWcApplicationRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailWcApplication, payload);
    if (res) {
      yield put(wcApplicationActions.getDetailWcApplicationSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcApplicationActions.getDetailWcApplicationFailed());
  }
};
const handleCreateWcApplicationRequest = function* ({
  payload,
}: PayloadAction<ICreateWcApplication>) {
  try {
    const res = yield call(createWcApplicationRequest, payload);
    if (res) {
      yield put(wcApplicationActions.createWcApplicationSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcApplicationActions.createWcApplicationFailed());
  }
};
const handleUpdateWcApplicationRequest = function* ({
  payload,
}: PayloadAction<ICreateWcApplication>) {
  try {
    const res = yield call(updateWcApplicationRequest, payload);
    if (res) {
      yield put(wcApplicationActions.updateWcApplicationSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcApplicationActions.updateWcApplicationFailed());
  }
};
const handleGetWcApplicationPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetWcApplicationRequestParamDTO>) {
  try {
    const res = yield call(getWcApplicationRequestPaginationConfig);
    if (res) {
      yield put(
        wcApplicationActions.getWcApplicationPaginationConfigSuccess(res),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcApplicationActions.getWcApplicationPaginationConfigFailed());
  }
};
const wcApplicationSaga = function* () {
  yield takeLatest(
    wcApplicationActions.getWcApplicationRequest.type,
    handleGetWcApplicationRequest,
  );
  yield takeLatest(
    wcApplicationActions.createWcApplicationRequest.type,
    handleCreateWcApplicationRequest,
  );
  yield takeLatest(
    wcApplicationActions.updateWcApplicationRequest.type,
    handleUpdateWcApplicationRequest,
  );
  yield takeLatest(
    wcApplicationActions.getWcApplicationPaginationConfigRequest.type,
    handleGetWcApplicationPaginationConfigRequest,
  );
  yield takeLatest(
    wcApplicationActions.getDetailWcApplicationRequest.type,
    handleGetDetailWcApplicationRequest,
  );
};
export default wcApplicationSaga;
