import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetWcAppEntityRequestParamDTO,
  GetWcAppEntityResponseDTO,
  ICreateWcAppEntity,
  IWcAppEntityDetail,
} from 'interfaces/wcAppEntity';
export function getWcAppEntityRequest(
  params?: GetWcAppEntityRequestParamDTO,
): Promise<GetWcAppEntityResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCAPPENTITY}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailWcAppEntity(
  wcAppEntityId: string,
): Promise<IWcAppEntityDetail> {
  return Instance.get(`/${PREFIX_API.WCAPPENTITY}/${wcAppEntityId}`);
}
export function createWcAppEntityRequest(
  wcAppEntity: ICreateWcAppEntity,
): Promise<GetWcAppEntityResponseDTO> {
  return Instance.post(`/${PREFIX_API.WCAPPENTITY}`, wcAppEntity);
}
export function updateWcAppEntityRequest(
  wcAppEntity: ICreateWcAppEntity,
): Promise<GetWcAppEntityResponseDTO> {
  const payload: any = { ...wcAppEntity };
  const id = wcAppEntity.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.WCAPPENTITY}/${id}`, payload);
}
export function getWcAppEntityRequestPaginationConfig(): Promise<GetWcAppEntityResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCAPPENTITY}/pagination-config`);
}
