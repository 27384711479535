import { CustomerManagement } from 'app/pages/CustomerManagement';
import { AddCustomer } from 'app/pages/AddCustomer/Loadable';
import PendingRequest from 'app/pages/PendingRequest';
import Transaction from 'app/pages/Transaction';
import Billing from 'app/pages/Billing';
import WalletConnect from '../app.v2/pages/WalletConnect';

const adminRoute = [
  { path: '/', element: <CustomerManagement /> },
  {
    path: '/customer-management',
    element: <CustomerManagement />,
  },
  {
    path: '/add-customer',
    element: <AddCustomer />,
  },
  {
    path: '/pending-request',
    element: <PendingRequest />,
  },
  {
    path: '/transaction',
    element: <Transaction />,
  },
  {
    path: '/billing',
    element: <Billing />,
  },
  {
    path: '/wallet-connect',
    element: <WalletConnect />,
  },

  // user-management
] as const;

export default adminRoute;
