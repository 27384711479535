import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getCfg_typeOfCompanyRequest,
  getDetailCfg_typeOfCompany,
  createCfg_typeOfCompanyRequest,
  updateCfg_typeOfCompanyRequest,
  getCfg_typeOfCompanyRequestPaginationConfig,
} from 'services/cfg_typeOfCompany.service';
import { cfg_typeOfCompanyActions } from 'store/slices/cfg_typeOfCompany/cfg_typeOfCompany.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetCfg_typeOfCompanyRequestParamDTO,
  ICreateCfg_typeOfCompany,
} from 'interfaces/cfg_typeOfCompany';
const handleGetCfg_typeOfCompanyRequest = function* ({
  payload,
}: PayloadAction<GetCfg_typeOfCompanyRequestParamDTO>) {
  try {
    const res = yield call(getCfg_typeOfCompanyRequest, payload);
    const params = yield select(state => state.cfg_typeOfCompany.params) as any;
    const listCfg_typeOfCompany = yield select(
      state =>
        state.cfg_typeOfCompany?.cfg_typeOfCompany?.data?.listCfg_typeOfCompany,
    ) as any;
    if (res) {
      yield put(
        cfg_typeOfCompanyActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listCfg_typeOfCompany || []), ...res.list];
        yield put(cfg_typeOfCompanyActions.appendCfg_typeOfCompany(newList));
      } else {
        yield put(
          cfg_typeOfCompanyActions.getCfg_typeOfCompanySuccess({
            listCfg_typeOfCompany: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_typeOfCompanyActions.getCfg_typeOfCompanyFailed());
  }
};
const handleGetDetailCfg_typeOfCompanyRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailCfg_typeOfCompany, payload);
    if (res) {
      yield put(
        cfg_typeOfCompanyActions.getDetailCfg_typeOfCompanySuccess(res),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_typeOfCompanyActions.getDetailCfg_typeOfCompanyFailed());
  }
};
const handleCreateCfg_typeOfCompanyRequest = function* ({
  payload,
}: PayloadAction<ICreateCfg_typeOfCompany>) {
  try {
    const res = yield call(createCfg_typeOfCompanyRequest, payload);
    if (res) {
      yield put(cfg_typeOfCompanyActions.createCfg_typeOfCompanySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_typeOfCompanyActions.createCfg_typeOfCompanyFailed());
  }
};
const handleUpdateCfg_typeOfCompanyRequest = function* ({
  payload,
}: PayloadAction<ICreateCfg_typeOfCompany>) {
  try {
    const res = yield call(updateCfg_typeOfCompanyRequest, payload);
    if (res) {
      yield put(cfg_typeOfCompanyActions.updateCfg_typeOfCompanySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_typeOfCompanyActions.updateCfg_typeOfCompanyFailed());
  }
};
const handleGetCfg_typeOfCompanyPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetCfg_typeOfCompanyRequestParamDTO>) {
  try {
    const res = yield call(getCfg_typeOfCompanyRequestPaginationConfig);
    if (res) {
      yield put(
        cfg_typeOfCompanyActions.getCfg_typeOfCompanyPaginationConfigSuccess(
          res,
        ),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(
      cfg_typeOfCompanyActions.getCfg_typeOfCompanyPaginationConfigFailed(),
    );
  }
};
const cfg_typeOfCompanySaga = function* () {
  yield takeLatest(
    cfg_typeOfCompanyActions.getCfg_typeOfCompanyRequest.type,
    handleGetCfg_typeOfCompanyRequest,
  );
  yield takeLatest(
    cfg_typeOfCompanyActions.createCfg_typeOfCompanyRequest.type,
    handleCreateCfg_typeOfCompanyRequest,
  );
  yield takeLatest(
    cfg_typeOfCompanyActions.updateCfg_typeOfCompanyRequest.type,
    handleUpdateCfg_typeOfCompanyRequest,
  );
  yield takeLatest(
    cfg_typeOfCompanyActions.getCfg_typeOfCompanyPaginationConfigRequest.type,
    handleGetCfg_typeOfCompanyPaginationConfigRequest,
  );
  yield takeLatest(
    cfg_typeOfCompanyActions.getDetailCfg_typeOfCompanyRequest.type,
    handleGetDetailCfg_typeOfCompanyRequest,
  );
};
export default cfg_typeOfCompanySaga;
