import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import styles from './index.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  loading: boolean;
  handleConfirm?: () => void;
}
const PopupUnlockUser = ({
  isOpen,
  setIsOpen,
  loading = false,
  handleConfirm,
  ...props
}: Props) => {
  const contentUnlockUserPopup = () => {
    return (
      <div className={styles.content}>
        After the request has been approved, customer can access to Rakkar on
        both mobile and web.
      </div>
    );
  };
  return (
    <BasicModal
      className={styles.approve__popup}
      destroyOnClose={true}
      children={contentUnlockUserPopup()}
      visible={isOpen}
      centered={true}
      title="You confirm to submit the unlock user request?"
      maskClosable={true}
      onCancel={() => setIsOpen(false)}
      width={404}
      footer={[
        <div className={styles.button} key="popup-unlock-user">
          <BasicButton
            title="Back"
            type="outline"
            onClick={() => setIsOpen(false)}
          />
          <BasicButton
            title="Confirm"
            type="filled"
            className={styles.confirm}
            onClick={handleConfirm}
            loading={loading}
          />
        </div>,
      ]}
    />
  );
};

export default PopupUnlockUser;
