import { BaseResponse } from 'interfaces/base';
import { baseRTKApi } from '../baseRtkQuery';
import {
  CreateCusSubscribeBody,
  CusSubscribe,
  CusSubscribeQuery,
} from 'interfaces/cusSubscribe';

export const cusSubscribeApi = baseRTKApi.injectEndpoints({
  endpoints: builder => ({
    createCusSubscribeRequest: builder.mutation({
      query: (body: CreateCusSubscribeBody) => ({
        url: 'core/v2/rep/cusSubscribe/request-create-customer-subscription',
        method: 'POST',
        data: body,
      }),
      transformResponse: response => response,
    }),
    getCusSubscribes: builder.query({
      keepUnusedDataFor: 0,
      query: (query?: Partial<CusSubscribeQuery>) => ({
        url: 'core/v2/rep/cusSubscribe',
        method: 'GET',
        params: query,
      }),
      transformResponse: response => {
        return response as BaseResponse<CusSubscribe, true>['data'];
      },
    }),
  }),
});
