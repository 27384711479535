import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getCfg_sourceOfFundRequest,
  getDetailCfg_sourceOfFund,
  createCfg_sourceOfFundRequest,
  updateCfg_sourceOfFundRequest,
  getCfg_sourceOfFundRequestPaginationConfig,
} from 'services/cfg_sourceOfFund.service';
import { cfg_sourceOfFundActions } from 'store/slices/cfg_sourceOfFund/cfg_sourceOfFund.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetCfg_sourceOfFundRequestParamDTO,
  ICreateCfg_sourceOfFund,
} from 'interfaces/cfg_sourceOfFund';
const handleGetCfg_sourceOfFundRequest = function* ({
  payload,
}: PayloadAction<GetCfg_sourceOfFundRequestParamDTO>) {
  try {
    const res = yield call(getCfg_sourceOfFundRequest, payload);
    const params = yield select(state => state.cfg_sourceOfFund.params) as any;
    const listCfg_sourceOfFund = yield select(
      state =>
        state.cfg_sourceOfFund?.cfg_sourceOfFund?.data?.listCfg_sourceOfFund,
    ) as any;
    if (res) {
      yield put(
        cfg_sourceOfFundActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listCfg_sourceOfFund || []), ...res.list];
        yield put(cfg_sourceOfFundActions.appendCfg_sourceOfFund(newList));
      } else {
        yield put(
          cfg_sourceOfFundActions.getCfg_sourceOfFundSuccess({
            listCfg_sourceOfFund: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_sourceOfFundActions.getCfg_sourceOfFundFailed());
  }
};
const handleGetDetailCfg_sourceOfFundRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailCfg_sourceOfFund, payload);
    if (res) {
      yield put(cfg_sourceOfFundActions.getDetailCfg_sourceOfFundSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_sourceOfFundActions.getDetailCfg_sourceOfFundFailed());
  }
};
const handleCreateCfg_sourceOfFundRequest = function* ({
  payload,
}: PayloadAction<ICreateCfg_sourceOfFund>) {
  try {
    const res = yield call(createCfg_sourceOfFundRequest, payload);
    if (res) {
      yield put(cfg_sourceOfFundActions.createCfg_sourceOfFundSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_sourceOfFundActions.createCfg_sourceOfFundFailed());
  }
};
const handleUpdateCfg_sourceOfFundRequest = function* ({
  payload,
}: PayloadAction<ICreateCfg_sourceOfFund>) {
  try {
    const res = yield call(updateCfg_sourceOfFundRequest, payload);
    if (res) {
      yield put(cfg_sourceOfFundActions.updateCfg_sourceOfFundSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(cfg_sourceOfFundActions.updateCfg_sourceOfFundFailed());
  }
};
const handleGetCfg_sourceOfFundPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetCfg_sourceOfFundRequestParamDTO>) {
  try {
    const res = yield call(getCfg_sourceOfFundRequestPaginationConfig);
    if (res) {
      yield put(
        cfg_sourceOfFundActions.getCfg_sourceOfFundPaginationConfigSuccess(res),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(
      cfg_sourceOfFundActions.getCfg_sourceOfFundPaginationConfigFailed(),
    );
  }
};
const cfg_sourceOfFundSaga = function* () {
  yield takeLatest(
    cfg_sourceOfFundActions.getCfg_sourceOfFundRequest.type,
    handleGetCfg_sourceOfFundRequest,
  );
  yield takeLatest(
    cfg_sourceOfFundActions.createCfg_sourceOfFundRequest.type,
    handleCreateCfg_sourceOfFundRequest,
  );
  yield takeLatest(
    cfg_sourceOfFundActions.updateCfg_sourceOfFundRequest.type,
    handleUpdateCfg_sourceOfFundRequest,
  );
  yield takeLatest(
    cfg_sourceOfFundActions.getCfg_sourceOfFundPaginationConfigRequest.type,
    handleGetCfg_sourceOfFundPaginationConfigRequest,
  );
  yield takeLatest(
    cfg_sourceOfFundActions.getDetailCfg_sourceOfFundRequest.type,
    handleGetDetailCfg_sourceOfFundRequest,
  );
};
export default cfg_sourceOfFundSaga;
