import { MoreOutlined } from '@ant-design/icons';
import { List, Popover } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import PopupAddOrEditUser from 'app/components/common/PopupAddOrEditUser';
import clsx from 'clsx';
import { CUSTOMER_ACTION, LOCKED_BY } from 'constants/customer';
import { SCREEN_USER, USER_ROLES } from 'enum/common';
import { StatusUser } from 'enum/userList';
import UnlockIcon from 'images/UnlockIcon.png';
import { RequestStatus } from 'interfaces/request';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRoles } from 'store/slices/auth';
import {
  customerProfileActions,
  selectCustomerProfile,
  selectMarkAsLostDevice,
  selectReplaceLostDevice,
  selectResendEmail,
  selectUnlockUser,
  selectUserDetail,
} from 'store/slices/customerProfile';
import { customersActions, selectEditUser } from 'store/slices/customers';
import { checkRoles } from 'utils/common';
import PopupMarkDeviceAsLost from '../PopupMarkDeviceAsLost';
import PopupReplaceLostDevice from '../PopupReplaceLostDevice';
import PopupResendEmail from '../PopupResendMail';
import PopupUnlockUser from '../PopupUnlockUser';
import styles from './index.module.scss';

type Props = {
  id: string;
  onClose?: () => void;
};

const CustomerOptionAction = ({ id, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpenOption, setIsOpenOption] = useState<boolean>(false);
  const [isOpenPopupResendEmail, setIsOpenPopupResendEmail] = useState(false);
  const [isOpenUnlockUser, setIsOpenUnlockUser] = useState(false);
  const resendEmail = useAppSelector(selectResendEmail);
  const unlockUser = useAppSelector(selectUnlockUser);
  const userDetail = useAppSelector(selectUserDetail);
  const userRoles = useAppSelector(selectRoles);
  const loadingBtnResend = resendEmail.status === RequestStatus.Loading;
  const loadingBtnUnlockUser = unlockUser.status === RequestStatus.Loading;

  const markAsLostDevice = useAppSelector(selectMarkAsLostDevice);
  const loadingBtnMark = markAsLostDevice.status === RequestStatus.Loading;
  const replaceLostDevice = useAppSelector(selectReplaceLostDevice);
  const loadingBtnReplace = replaceLostDevice.status === RequestStatus.Loading;
  const userEditStore = useAppSelector(selectEditUser);
  const customerProfile = useAppSelector(selectCustomerProfile);

  const [isOpenPopupMarkDeviceAsLost, setIsOpenPopupMarkDeviceAsLost] =
    useState(false);

  const [isOpenReplace, setIsOpenReplace] = useState(false);

  const [isOpenEdit, setIsOpenEdit] = useState(false);

  useEffect(() => {
    if (markAsLostDevice.status === RequestStatus.Success) {
      setIsOpenPopupMarkDeviceAsLost(false);
      dispatch(customerProfileActions.resetMarkAsLostDevice());
      dispatch(customerProfileActions.fetchUserDetailRequest({ id }));
      onClose?.();
    }
    if (resendEmail.status === RequestStatus.Success) {
      setIsOpenPopupResendEmail(false);
      dispatch(customerProfileActions.resetResendEmail());
      dispatch(customerProfileActions.fetchUserDetailRequest({ id }));
      onClose?.();
    }
    if (replaceLostDevice.status === RequestStatus.Success) {
      setIsOpenReplace(false);
      dispatch(customerProfileActions.resetReplaceLostDevice());
      dispatch(customerProfileActions.fetchUserDetailRequest({ id }));
      onClose?.();
    }
    if (userEditStore?.status === RequestStatus.Success) {
      setIsOpenReplace(false);
      dispatch(customersActions.resetEditUser());
      dispatch(customerProfileActions.fetchUserDetailRequest({ id }));
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (unlockUser.status === RequestStatus.Success) {
      setIsOpenReplace(false);
      dispatch(customerProfileActions.resetUnlockUser());
      dispatch(customerProfileActions.fetchUserDetailRequest({ id }));
      onClose?.();
    }
  }, [
    markAsLostDevice.status,
    onClose,
    replaceLostDevice.status,
    resendEmail.status,
    userEditStore?.status,
    unlockUser.status,
    dispatch,
    id,
  ]);

  const handleResendEmail = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(customerProfileActions.resendEmailRequest({ userId: id }));
    setIsOpenPopupResendEmail(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleReplaceLostDevice = () => {
    dispatch(customerProfileActions.replaceLostDeviceRequest({ userId: id }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMarkDeviceAsLost = () => {
    dispatch(customerProfileActions.markAsLostDeviceRequest({ userId: id }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUnlockUser = () => {
    dispatch(customerProfileActions.unlockUserRequest({ userId: id }));
  };

  const isExistedRequest = useMemo(() => {
    return (
      !!userDetail.data?.isPendingRequest ||
      !checkRoles(USER_ROLES.OPERATION, userRoles)
    );
  }, [userRoles, userDetail.data]);

  const actionList = [
    {
      name: 'lost-device',
      disable: 'disable-lost_device',
      value: 'Mark as lost device',
    },
    {
      name: 'phone-new',
      disable: 'disable-replace-phone',
      value: 'Replace device',
      width: 17,
      height: 17,
    },
    {
      name: 'unlock',
      disable: 'disable-unlock',
      value: 'Unlock user',
    },
    {
      name: 'resend-email',
      disable: 'disable-resend',
      value: 'Resend email',
      width: 18,
      height: 18,
    },
    {
      name: 'custom-icon',
      value: 'Edit',
    },
  ];

  const isResendEmailDisplay = (status, action) => {
    return (
      status === StatusUser.ACTIVE && action === CUSTOMER_ACTION.RESEND_EMAIL
    );
  };

  const isLostDeviceDisplay = (isLostDevice, action, status) => {
    return (
      action === CUSTOMER_ACTION.LOST_DEVICE &&
      (status !== StatusUser.ACTIVE ||
        (isLostDevice && status === StatusUser.ACTIVE))
    );
  };

  const isReplaceDeviceDisplay = (isLostDevice, action, status) => {
    return (
      action === CUSTOMER_ACTION.REPLACE_DEVICE &&
      ((!isLostDevice && status === StatusUser.ACTIVE) ||
        status !== StatusUser.ACTIVE)
    );
  };

  const isUnlockUserDisplay = (isLocked, action) => {
    return (
      isLocked === LOCKED_BY.NONE && action === CUSTOMER_ACTION.UNLOCK_USER
    );
  };

  const isCheckDisable = (user, item) => {
    return (
      isResendEmailDisplay(user.status, item.name) ||
      isLostDeviceDisplay(user.isLostDevice, item.name, user.status) ||
      isReplaceDeviceDisplay(user.isLostDevice, item.name, user.status) ||
      isUnlockUserDisplay(user?.lockedBy, item.name)
    );
  };

  const iconList = (item, user) => {
    return !(
      isExistedRequest || isUnlockUserDisplay(user?.lockedBy, item.name)
    ) && item.name === 'unlock' ? (
      <img
        src={UnlockIcon}
        alt=""
        width="16px"
        height="16px"
        style={{ marginRight: '10px', objectFit: 'scale-down' }}
      />
    ) : (
      <SvgIcon
        name={
          (isExistedRequest || isCheckDisable(user, item)) && item?.disable
            ? item.disable
            : item.name
        }
        width={item?.width ? item.width : 16}
        height={item?.height ? item.height : 16}
        style={{ marginRight: '10px' }}
      />
    );
  };

  const action = useCallback(
    () => (
      <>
        <div className={styles.popover}>
          <List
            size="small"
            dataSource={actionList}
            renderItem={item => (
              <List.Item
                style={{ borderBottom: 'none' }}
                className={clsx(styles.popover__item, {
                  [styles.popover__item__disable]:
                    isExistedRequest || isCheckDisable(userDetail.data, item),
                })}
              >
                <div
                  className={styles.popover__title}
                  onClick={() => {
                    switch (item.name) {
                      case CUSTOMER_ACTION.RESEND_EMAIL:
                        setIsOpenPopupResendEmail(true);
                        break;
                      case CUSTOMER_ACTION.REPLACE_DEVICE:
                        setIsOpenReplace(true);
                        break;
                      case CUSTOMER_ACTION.LOST_DEVICE:
                        setIsOpenPopupMarkDeviceAsLost(true);
                        break;
                      case CUSTOMER_ACTION.UNLOCK_USER:
                        setIsOpenUnlockUser(true);
                        break;
                      case CUSTOMER_ACTION.EDIT_USER:
                        setIsOpenEdit(true);
                        break;
                    }
                    setIsOpenOption(false);
                  }}
                >
                  {iconList(item, userDetail.data)}
                  {item.value}
                </div>
              </List.Item>
            )}
          />
        </div>
      </>
    ),
    [isCheckDisable, isExistedRequest, userDetail.data],
  );

  return (
    <>
      <Popover
        placement="bottomRight"
        content={action}
        trigger="click"
        overlayClassName={styles.action}
        open={isOpenOption}
        onOpenChange={e => {
          if (setIsOpenOption) setIsOpenOption(e);
        }}
        data-testid=""
      >
        <div
          className={clsx(styles.circle__button, {
            [styles.circle__button__bg]: isOpenOption,
          })}
          onClick={() => {
            setIsOpenOption(true);
          }}
        >
          <MoreOutlined
            rotate={90}
            style={{ fontSize: '16px', color: '#6B6D6F' }}
          />
        </div>
      </Popover>
      <PopupResendEmail
        isOpen={isOpenPopupResendEmail}
        setIsOpen={setIsOpenPopupResendEmail}
        loading={loadingBtnResend}
        handleConfirm={handleResendEmail}
        status={userDetail?.data?.status}
      />

      <PopupMarkDeviceAsLost
        isOpen={isOpenPopupMarkDeviceAsLost}
        setIsOpen={setIsOpenPopupMarkDeviceAsLost}
        loading={loadingBtnMark}
        handleConfirm={handleMarkDeviceAsLost}
      />

      <PopupReplaceLostDevice
        isOpen={isOpenReplace}
        setIsOpen={setIsOpenReplace}
        title="You confirm to submit the replace device request?"
        content="After the request has been approved, customer will receive an email with QR code and instructions for setting up new device"
        loading={loadingBtnReplace}
        handleConfirm={handleReplaceLostDevice}
      />

      {isOpenEdit && (
        <PopupAddOrEditUser
          isOpen={isOpenEdit}
          setIsOpen={setIsOpenEdit}
          userEdit={userDetail.data}
          customerId={customerProfile?.data?.id}
          fromScreen={SCREEN_USER.EDIT_USER}
        />
      )}
      <PopupUnlockUser
        isOpen={isOpenUnlockUser}
        setIsOpen={setIsOpenUnlockUser}
        loading={loadingBtnUnlockUser}
        handleConfirm={handleUnlockUser}
      />
    </>
  );
};
export default CustomerOptionAction;
