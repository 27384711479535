import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetWcRequestWeb3ConnectRequestParamDTO,
  GetWcRequestWeb3ConnectResponseDTO,
  ICreateWcRequestWeb3Connect,
  IWcRequestWeb3ConnectDetail,
} from 'interfaces/wcRequestWeb3Connect';
export function getWcRequestWeb3ConnectRequest(
  params?: GetWcRequestWeb3ConnectRequestParamDTO,
): Promise<GetWcRequestWeb3ConnectResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCREQUESTWEB3CONNECT}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailWcRequestWeb3Connect(
  wcRequestWeb3ConnectId: string,
): Promise<IWcRequestWeb3ConnectDetail> {
  return Instance.get(
    `/${PREFIX_API.WCREQUESTWEB3CONNECT}/${wcRequestWeb3ConnectId}`,
  );
}
export function createWcRequestWeb3ConnectRequest(
  wcRequestWeb3Connect: ICreateWcRequestWeb3Connect,
): Promise<GetWcRequestWeb3ConnectResponseDTO> {
  return Instance.post(
    `/${PREFIX_API.WCREQUESTWEB3CONNECT}`,
    wcRequestWeb3Connect,
  );
}
export function updateWcRequestWeb3ConnectRequest(
  wcRequestWeb3Connect: ICreateWcRequestWeb3Connect,
): Promise<GetWcRequestWeb3ConnectResponseDTO> {
  const payload: any = { ...wcRequestWeb3Connect };
  const id = wcRequestWeb3Connect.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.WCREQUESTWEB3CONNECT}/${id}`, payload);
}
export function getWcRequestWeb3ConnectRequestPaginationConfig(): Promise<GetWcRequestWeb3ConnectResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCREQUESTWEB3CONNECT}/pagination-config`);
}
