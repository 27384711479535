export function randomString(length) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const truncateAddress = (
  address?: string,
  isMobile = true,
  subStringFirst = 6,
  subStringLast = 4,
): string => {
  if (!address) return '';
  if (!isMobile) {
    return (
      address.substring(0, subStringFirst) +
      '...' +
      address.substring(address.length - subStringLast)
    );
  }
  return (
    address.substring(0, subStringFirst) +
    '...' +
    address.substring(address.length - subStringLast)
  );
};

export const isQuillEmpty = (value: string) => {
  if (
    value.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
    !value.includes('<img')
  ) {
    return true;
  }
  return false;
};

export const isNullOrUndefined = (value: any) => {
  return value === undefined || value === null;
};
