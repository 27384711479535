import { Form, Select } from 'antd';
import { LabelTooltipType } from 'antd/es/form/FormItemLabel';
import { NamePath } from 'antd/lib/form/interface';
import { debounce } from 'lodash';
import { CSSProperties, Key } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

interface Props {
  name?: string;
  colon?: boolean;
  useLabel?: boolean;
  options: any[];
  disabled?: boolean;
  label?: React.ReactNode;
  className?: string;
  classNameFormItem?: string;
  rules?: object[];
  placeholder?: string;
  loading?: boolean;
  mode?: 'multiple' | 'tags';
  defaultValue?: string | boolean;
  onChange?: (value: any, options: any) => void;
  onSearch?: (value: string) => void;
  allowClear?: boolean;
  showSearch?: boolean;
  filterOption?: any;
  optionFilterProp?: string;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  initialValueForm?: string | number | [];
  noStyle?: boolean;
  selectStyle?: CSSProperties;
  size?: 'large' | 'middle' | 'small';
  namePath?: NamePath | undefined;
  key?: Key | null | undefined;
  keyValue?: string;
  labelValue?: string;
  dataTestId?: string;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight' | undefined;
  open?: boolean;
  tooltip?: LabelTooltipType;
  valueSelected?: any;
  maxTagCount?: number | 'responsive';
}

const BasicSelect = ({
  keyValue = 'value',
  labelValue = 'label',
  ...props
}: Props) => {
  const isRequired = props.rules
    ? props.rules.filter((r: any) => r.required === true).length > 0
    : false;

  const handleSearchDebounce = debounce((value: string) => {
    return props.onSearch && props.onSearch(value);
  }, 500);

  const isLabel = props.useLabel || true;

  const tagRender = props => {
    const { label } = props;
    return (
      <span>
        {label}
        {','}
      </span>
    );
  };

  return (
    <Form.Item
      label={isLabel ? props.label : ''}
      colon={props.colon || false}
      name={props.name ?? props.namePath}
      required={!props.disabled && isRequired}
      rules={props.disabled ? [] : props.rules}
      className={props.classNameFormItem + ' ' + styles.select}
      initialValue={props.initialValueForm}
      noStyle={props.noStyle}
      key={props.key}
      tooltip={props.tooltip}
    >
      <Select
        getPopupContainer={props.getPopupContainer}
        className={clsx(props.className, {
          [styles.maxTagCount]: !!props.maxTagCount,
        })}
        disabled={props.disabled}
        placeholder={props.placeholder}
        loading={props.loading}
        mode={props.mode}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        allowClear={props.allowClear}
        showSearch={props.showSearch}
        filterOption={props.filterOption}
        optionFilterProp={props.optionFilterProp}
        size={props.size || 'middle'}
        onSearch={props.showSearch ? handleSearchDebounce : undefined}
        style={props.selectStyle}
        data-testid={props.dataTestId}
        placement={props.placement}
        open={props.open}
        value={props.valueSelected}
        maxTagCount={props.maxTagCount}
        tagRender={
          props.maxTagCount && props.mode === 'multiple' ? tagRender : undefined
        }
      >
        {props.options.map((o: any, i: number) => (
          <Select.Option value={o[keyValue]} key={i} disabled={o.isDisabled}>
            {o[labelValue]}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default BasicSelect;
