import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { RequestStatus } from 'interfaces/request';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { commonActions } from 'store/slices/common';
import { customersActions, selectUpdateStaking } from 'store/slices/customers';
import styles from './index.module.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (stt: boolean) => void;
  isAllowedToStake: boolean;
  handleConfirm?: () => void;
};

const ConfirmStaking = ({
  isOpen,
  setIsOpen,
  isAllowedToStake,
  handleConfirm,
}: Props) => {
  const dispatch = useDispatch();
  const updateStakingRequest = useAppSelector(selectUpdateStaking);
  const loading = updateStakingRequest?.status === RequestStatus.Loading;

  const onClickCancel = () => {
    dispatch(commonActions.setOpenPopupOnGoingStaking(false));
    setIsOpen(false);
    dispatch(customersActions.resetUpdateStaking());
  };

  const confirm = () => {
    if (isAllowedToStake && handleConfirm) {
      handleConfirm();
    } else {
      setIsOpen(false);
    }
    dispatch(customersActions.resetUpdateStaking());
  };

  return (
    <BasicModal
      destroyOnClose={true}
      onCancel={onClickCancel}
      closable={false}
      visible={isOpen}
      footer={false}
      closeIcon={false}
      maskClosable={false}
      centered={true}
      width={408}
    >
      <div className={styles.container}>
        <div className={styles.input__title} data-testid="title">
          {isAllowedToStake
            ? 'You confirm to submit the request?'
            : 'You currently cannot unsubscribe'}
        </div>
        <div className={styles.input__content} data-testid="content">
          {isAllowedToStake
            ? 'After the request has been approved, product subscription information will be changed.'
            : 'You cannot unsubscribe Cardano due to on-going staking or unclaimed rewards'}
        </div>
        <div className={styles.button}>
          {isAllowedToStake && (
            <BasicButton title="Back" type="outline" onClick={onClickCancel} />
          )}
          <BasicButton
            title={isAllowedToStake ? 'Confirm' : 'Ok'}
            type="filled"
            className={styles.confirm}
            onClick={isAllowedToStake ? confirm : onClickCancel}
            loading={loading}
          />
        </div>
      </div>
    </BasicModal>
  );
};

export default ConfirmStaking;
