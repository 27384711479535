import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { RequestStatus } from 'interfaces/request';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { commentActions, selectDeleteComment } from 'store/slices/comment';
import styles from './index.module.scss';

interface Props {
  isOpen: boolean;
  txnId: string;
  commentId: string;
  setIsOpen: (value: boolean) => void;
}
const PopupDeleteComment = ({ isOpen, setIsOpen, txnId, commentId }: Props) => {
  const dispatch = useAppDispatch();
  const deleteComment = useAppSelector(selectDeleteComment);
  const loading = deleteComment.status === RequestStatus.Loading;
  const handleDelete = () => {
    dispatch(
      commentActions.deleteCommentRequest({
        txnId: txnId,
        commentId: commentId,
      }),
    );
  };

  useEffect(() => {
    if (deleteComment.status === RequestStatus.Success) {
      setIsOpen(false);
    }
  }, [deleteComment.status, setIsOpen]);

  const content = (
    <div className={styles.content}>
      Once confirm, the comment will be permanently deleted.
    </div>
  );
  return (
    <BasicModal
      destroyOnClose={true}
      className={styles.approve__popup}
      visible={isOpen}
      children={content}
      title={`Delete this comment?`}
      centered={true}
      onCancel={() => setIsOpen(false)}
      maskClosable={true}
      width={404}
      footer={[
        <div className={styles.button}>
          <BasicButton
            title="Back"
            type="outline"
            onClick={() => setIsOpen(false)}
          />
          <BasicButton
            title="Delete"
            type="filled"
            className={styles.confirm}
            onClick={handleDelete}
            loading={loading}
          />
        </div>,
      ]}
    />
  );
};

export default PopupDeleteComment;
