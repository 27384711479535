import { TransactionType } from 'enum/common';
import { RequestStatus } from 'interfaces/request';

export const initStoreCommon = {
  common: {
    isMobile: true,
    isMobileOnly: true,
    workspaces: {
      data: {
        hot: [
          [
            {
              id: 1,
              name: 'Rakkar (API Test)',
              type: 'hot',
            },
          ],
        ],
        cold: [
          {
            id: 2,
            name: 'Rakkar (Cold Wallet - Testnet)',
            type: 'cold',
          },
        ],
      },
      status: RequestStatus.Success,
    },
    businessTypes: {
      status: RequestStatus.Success,
      data: [
        {
          id: 1,
          name: 'High net worth investor',
        },
        {
          id: 2,
          name: 'Digital asset issuer',
        },
        {
          id: 3,
          name: 'Exchange',
        },
        {
          id: 4,
          name: 'Family office',
        },
        {
          id: 5,
          name: 'Financial institution',
        },
        {
          id: 6,
          name: 'Payment provider',
        },
        {
          id: 7,
          name: 'Fund',
        },
        {
          id: 8,
          name: 'Other',
        },
      ],
    },
    creatorsRequest: {
      userId: 'eec457ac-78ad-4b29-8e64-4ec31a603f34',
      email: 'developer1@Rakkardigital.com',
      name: 'developer1',
      enabled: true,
      role: 'ADMIN',
      roleDisplayName: 'Admin',
    },
  },
};
export const transactionStatusOpts = [
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Failed',
    value: 'FAILED',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Confirming',
    value: 'CONFIRMING',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
  },
  {
    label: 'Pending review',
    value: 'PENDING_REVIEW',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Processing',
    value: 'PROCESSING',
  },
];

interface ITypeTXN {
  label: string;
  value: TransactionType;
  icon: string;
}

export const typesTXN: ITypeTXN[] = [
  {
    label: 'Deposit',
    value: TransactionType.INCOMING,
    icon: 'deposit-new',
  },
  {
    label: 'Withdraw',
    value: TransactionType.OUTGOING,
    icon: 'withdraw-new',
  },
  {
    label: 'Rebalance',
    value: TransactionType.REBALANCING,
    icon: 'rebalance',
  },
  {
    label: 'Stake',
    value: TransactionType.STAKING,
    icon: 'rebalance',
  },
  {
    label: 'Unstake',
    value: TransactionType.UNSTAKING,
    icon: 'rebalance',
  },
  {
    label: 'Claim rewards',
    value: TransactionType.CLAIM_REWARD,
    icon: 'rebalance',
  },
  {
    label: 'Change pool',
    value: TransactionType.CHANGE_POOL,
    icon: 'rebalance',
  },
  {
    label: 'Contract call',
    value: TransactionType.CONTRACT_CALL,
    icon: 'contract-call',
  },
];
