import BasicModal from 'app/components/common/BasicModal';
import React from 'react';
import styles from './index.module.scss';
import { spacingRawText } from 'utils/common';

type Props = {
  open: boolean;
  setOpen: (v: boolean) => void;
  user?: any;
};

const ModalIndentityVerification: React.FC<Props> = ({
  open,
  setOpen,
  user,
}: Props) => {
  return (
    <BasicModal
      className={styles.container}
      visible={open}
      title=""
      centered={true}
      onCancel={() => setOpen(false)}
      maskClosable={true}
      width={745}
      children={
        <>
          <div className={styles.title}>Identity verification</div>
          <div className={styles.content}>
            {user.imagePassport && (
              <div className={styles.passport}>
                <div className={styles.image}>
                  <img src={user.imagePassport} alt="" />
                </div>
              </div>
            )}

            <div className={styles.contentNonPassport}>
              <div className={styles.contentNonPassportImage}>
                {user?.imageLivenessOnboarding && (
                  <div
                    className={styles.onboarding}
                    data-testid="img-onboarding-modal"
                  >
                    <img src={user.imageLivenessOnboarding} alt="" />
                    <div className={styles.text}>Onboarding</div>
                  </div>
                )}

                {user?.imageLivenessAuthentication && (
                  <div
                    className={styles.authentication}
                    data-testid="vdo-authentication-modal"
                  >
                    <video width="194" height="259" controls>
                      <source
                        src={user.imageLivenessAuthentication}
                        type="video/mp4"
                      />
                    </video>
                    <div className={styles.text}>Authentication</div>
                  </div>
                )}
              </div>

              <div className={styles.contentNonPassportInfo}>
                {user?.vdoSentence && (
                  <div className={styles.info}>
                    <div className={styles.title}>Speech prompt</div>
                    <div className={`${styles.content} ${styles.speech}`}>
                      {spacingRawText(user.vdoSentence)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      }
      footer={false}
    />
  );
};

export default ModalIndentityVerification;
