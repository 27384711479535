import ToastMessage from 'app/components/common/ToastMessage';
import { toast } from 'react-toastify';
const useMessage = () => {
  const addMessage = (
    type: 'info' | 'warning' | 'success' | 'error',
    message: string,
    duration?: number,
  ) => {
    toast(<ToastMessage message={message} type={type} />, {
      autoClose: 2000,
    });
  };
  return { addMessage };
};

export default useMessage;
