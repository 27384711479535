import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { FORMAT_DATE } from 'constants/index';
import { RequestStatus } from 'interfaces/request';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  billingsActions,
  selectMarkAsBillingReviewed,
} from 'store/slices/billings';
import styles from './index.module.scss';

type Props = {
  open: boolean;
  setOpen: (v: boolean) => void;
  invoiceId: string | undefined;
  invoicePeriod: string | undefined;
};

const ModalReviewBilling = ({
  open,
  setOpen,
  invoiceId,
  invoicePeriod,
}: Props) => {
  const refInvoicePeriod = useRef('');

  useEffect(() => {
    const dateInvoice = invoicePeriod && invoicePeriod.split('-');
    if (dateInvoice?.length) {
      //get month and year of invoice period
      const invoicePeriodMonth = moment(dateInvoice[0], FORMAT_DATE.DEFAULT)
        .add(1, 'month')
        .format(FORMAT_DATE.MM_YYYY);

      refInvoicePeriod.current = `07/${invoicePeriodMonth}`; //07 is default
    }
  }, [invoicePeriod]);

  const dispatch = useDispatch();
  const onClose = () => {
    setOpen(false);
  };

  const data = useAppSelector(selectMarkAsBillingReviewed);

  const loading = data.status === RequestStatus.Loading;

  useEffect(() => {
    if (data.status === RequestStatus.Success) {
      setOpen(false);
    }
  }, [data, setOpen]);

  const handleConfirmReview = () => {
    if (invoiceId)
      dispatch(billingsActions.markAsBillingReviewedRequest(invoiceId));
  };

  return (
    <BasicModal
      destroyOnClose={false}
      className={styles.modal}
      visible={open}
      centered={true}
      onCancel={onClose}
      maskClosable={true}
      width={404}
      footer={null}
      closable={false}
    >
      <div data-testid="modal-confirm-review">
        <div className={styles.title}>You have reviewed the invoice?</div>
        <div className={styles.content}>
          {`Please check the information carefully before confirming. Once marked as reviewed, the invoice will be sent automatically to customer on ${refInvoicePeriod.current}.`}
        </div>
        <div className={styles.footer}>
          <BasicButton
            title="Cancel"
            dataTestId="cancel"
            type="outline"
            onClick={onClose}
          />
          <BasicButton
            title="Confirm"
            dataTestId="confirm"
            type="filled"
            onClick={handleConfirmReview}
            loading={loading}
          />
        </div>
      </div>
    </BasicModal>
  );
};

export default ModalReviewBilling;
