import { Col, Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import { FORMAT_DATE } from 'constants/index';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectTransactionDetail } from 'store/slices/transaction';

import styles from './index.module.scss';

type Props = {};

interface ITextInfor {
  label: any;
  text: any;
  span?: number;
  dataTestId?: string;
}

const KYTInformation = (props: Props) => {
  const transactionDetail = useSelector(selectTransactionDetail)?.data ?? {};
  const TextInfor = ({ span = 12, label, text, dataTestId }: ITextInfor) => {
    return (
      <Col span={span}>
        <div className={styles.content__item}>
          <p className={styles.content__label}>{label}</p>
          <p className={styles.content__text} data-testid={dataTestId}>
            {text}
          </p>
        </div>
      </Col>
    );
  };
  const formatText = (text: string) => {
    let result = "-"
    if(text) {
      result = text.toString().toLowerCase();
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <SvgIcon name="info-red" width={16} height={16} />
          </div>
          <div className={styles.title}>KYT information</div>
        </div>
      </div>
      <Row style={{ marginBottom: 20 }}>
        <TextInfor
          label="Risk score"
          dataTestId="risk-score"
          text={formatText(transactionDetail?.kyt?.riskScore || '')}
        />
        <TextInfor
          label="Category"
          dataTestId="category"
          text={formatText(transactionDetail?.kyt?.category || '')}
        />
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <TextInfor
            label="Exposure type"
            dataTestId="exposure-type"
            text={formatText(transactionDetail?.kyt?.exposureType || '')}
          />
      </Row>
      <Row>
        <TextInfor
          label="Provider"
          dataTestId="provider"
          text={
            <Row style={{ alignItems: 'center', gap: 10 }}>
              {transactionDetail?.kyt?.provider ? (
                <>
                  {' '}
                  <span>{formatText(transactionDetail?.kyt?.provider || '')}</span>
                  <SvgIcon
                    name="redirect"
                    width={13}
                    height={13}
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open('https://kyt.chainalysis.com/')}
                  />
                </>
              ) : (
                '-'
              )}
            </Row>
          }
        />
        <TextInfor
          label="Screening time"
          dataTestId="screening-time"
          text={
            transactionDetail?.kyt?.screeningTime
              ? moment
                  .unix(+transactionDetail?.kyt?.screeningTime / 1000)
                  .format(FORMAT_DATE.DATE_DMY_hM)
              : '-'
          }
        />
      </Row>
    </div>
  );
};

export default KYTInformation;
