import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortCfg_sourceOfFund,
  GroupByField,
  CustomerOnScreen,
} from 'enum/cfg_sourceOfFund.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetCfg_sourceOfFundRequestParamDTO,
  GetCfg_sourceOfFundResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ICfg_sourceOfFundDetail,
} from 'interfaces/cfg_sourceOfFund';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface Cfg_sourceOfFundState {
  params: GetCfg_sourceOfFundRequestParamDTO;
  cfg_sourceOfFund: ResponseData<GetCfg_sourceOfFundResponseDTO>;
  cfg_sourceOfFundPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  cfg_sourceOfFundDetail: ResponseData<ICfg_sourceOfFundDetail>;
}
export function getDefaultCfg_sourceOfFundRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortCfg_sourceOfFund.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: Cfg_sourceOfFundState = {
  params: getDefaultCfg_sourceOfFundRequestParams(),
  cfg_sourceOfFund: {
    status: RequestStatus.Idle,
  },
  cfg_sourceOfFundPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.CFG_SOURCEOFFUND,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  cfg_sourceOfFundDetail: {
    status: RequestStatus.Idle,
  },
};
const getCfg_sourceOfFundRequest: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<any>
> = (state: Cfg_sourceOfFundState) => {
  state.cfg_sourceOfFund.status = RequestStatus.Loading;
};
const getCfg_sourceOfFundSuccess: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<GetCfg_sourceOfFundResponseDTO>
> = (
  state: Cfg_sourceOfFundState,
  { payload }: PayloadAction<GetCfg_sourceOfFundResponseDTO>,
) => {
  state.cfg_sourceOfFund.status = RequestStatus.Success;
  state.cfg_sourceOfFund.data = payload;
};
const getCfg_sourceOfFundFailed: CaseReducer<Cfg_sourceOfFundState> = (
  state: Cfg_sourceOfFundState,
) => {
  state.cfg_sourceOfFund.status = RequestStatus.Failed;
};
const getDetailCfg_sourceOfFundRequest: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<string>
> = (state: Cfg_sourceOfFundState) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Loading;
};
const getDetailCfg_sourceOfFundSuccess: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<ICfg_sourceOfFundDetail>
> = (
  state: Cfg_sourceOfFundState,
  { payload }: PayloadAction<ICfg_sourceOfFundDetail>,
) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Success;
  state.cfg_sourceOfFundDetail.data = payload;
};
const getDetailCfg_sourceOfFundFailed: CaseReducer<Cfg_sourceOfFundState> = (
  state: Cfg_sourceOfFundState,
) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Failed;
};
const createCfg_sourceOfFundRequest: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<ICfg_sourceOfFundDetail>
> = (state: Cfg_sourceOfFundState) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Loading;
};
const createCfg_sourceOfFundSuccess: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<ICfg_sourceOfFundDetail>
> = (
  state: Cfg_sourceOfFundState,
  { payload }: PayloadAction<ICfg_sourceOfFundDetail>,
) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Success;
  state.cfg_sourceOfFundDetail.data = payload;
};
const createCfg_sourceOfFundFailed: CaseReducer<Cfg_sourceOfFundState> = (
  state: Cfg_sourceOfFundState,
) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Failed;
};
const updateCfg_sourceOfFundRequest: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<ICfg_sourceOfFundDetail>
> = (state: Cfg_sourceOfFundState) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Loading;
};
const updateCfg_sourceOfFundSuccess: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<ICfg_sourceOfFundDetail>
> = (
  state: Cfg_sourceOfFundState,
  { payload }: PayloadAction<ICfg_sourceOfFundDetail>,
) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Success;
  state.cfg_sourceOfFundDetail.data = payload;
};
const updateCfg_sourceOfFundFailed: CaseReducer<Cfg_sourceOfFundState> = (
  state: Cfg_sourceOfFundState,
) => {
  state.cfg_sourceOfFundDetail.status = RequestStatus.Failed;
};
const appendCfg_sourceOfFund: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<any>
> = (state: Cfg_sourceOfFundState, { payload }: PayloadAction<any>) => {
  if (state.cfg_sourceOfFund?.data?.listCfg_sourceOfFund) {
    state.cfg_sourceOfFund.status = RequestStatus.Success;
    state.cfg_sourceOfFund.data.listCfg_sourceOfFund = [...payload];
  }
};
const setCanLoadMore = (
  state: Cfg_sourceOfFundState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetCfg_sourceOfFund: CaseReducer<Cfg_sourceOfFundState> = (
  state: Cfg_sourceOfFundState,
) => {
  state.cfg_sourceOfFund = {
    status: RequestStatus.Idle,
  };
};
const resetCfg_sourceOfFundParams: CaseReducer<Cfg_sourceOfFundState> = (
  state: Cfg_sourceOfFundState,
) => {
  state.params = getDefaultCfg_sourceOfFundRequestParams();
};
const setCfg_sourceOfFundFilterParams = (
  state: Cfg_sourceOfFundState,
  { payload }: PayloadAction<GetCfg_sourceOfFundRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: Cfg_sourceOfFundState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<any>
> = (state: Cfg_sourceOfFundState, { payload }: PayloadAction<any>) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<Cfg_sourceOfFundState> = (
  state: Cfg_sourceOfFundState,
) => {
  state.action.status = RequestStatus.Failed;
};
const getCfg_sourceOfFundPaginationConfigRequest: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<any>
> = (state: Cfg_sourceOfFundState) => {
  state.cfg_sourceOfFundPaginationConfig.status = RequestStatus.Loading;
};
const getCfg_sourceOfFundPaginationConfigSuccess: CaseReducer<
  Cfg_sourceOfFundState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: Cfg_sourceOfFundState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.cfg_sourceOfFundPaginationConfig.status = RequestStatus.Success;
  state.cfg_sourceOfFundPaginationConfig.data = payload;
};
const getCfg_sourceOfFundPaginationConfigFailed: CaseReducer<
  Cfg_sourceOfFundState
> = (state: Cfg_sourceOfFundState) => {
  state.cfg_sourceOfFundPaginationConfig.status = RequestStatus.Failed;
};
const cfg_sourceOfFundSlice = createSlice({
  name: 'cfg_sourceOfFund',
  initialState,
  reducers: {
    getCfg_sourceOfFundRequest,
    getCfg_sourceOfFundSuccess,
    getCfg_sourceOfFundFailed,
    getDetailCfg_sourceOfFundRequest,
    getDetailCfg_sourceOfFundSuccess,
    getDetailCfg_sourceOfFundFailed,
    createCfg_sourceOfFundRequest,
    createCfg_sourceOfFundSuccess,
    createCfg_sourceOfFundFailed,
    updateCfg_sourceOfFundRequest,
    updateCfg_sourceOfFundSuccess,
    updateCfg_sourceOfFundFailed,
    appendCfg_sourceOfFund,
    setCanLoadMore,
    resetCfg_sourceOfFund,
    setCfg_sourceOfFundParams<
      K extends keyof GetCfg_sourceOfFundRequestParamDTO,
    >(
      state: Cfg_sourceOfFundState,
      action: PayloadAction<{
        key: K;
        value: GetCfg_sourceOfFundRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setCfg_sourceOfFundFilterParams,
    resetCfg_sourceOfFundParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getCfg_sourceOfFundPaginationConfigRequest,
    getCfg_sourceOfFundPaginationConfigSuccess,
    getCfg_sourceOfFundPaginationConfigFailed,
  },
});
// Actions
export const cfg_sourceOfFundActions = cfg_sourceOfFundSlice.actions;
// Reducer
export const cfg_sourceOfFundReducer = cfg_sourceOfFundSlice.reducer;
// Selectors
export const selectCfg_sourceOfFund = (state: AppState) =>
  state.cfg_sourceOfFund?.cfg_sourceOfFund;
export const selectParamCfg_sourceOfFund = (state: AppState) =>
  state.cfg_sourceOfFund?.params;
export const selectCanLoadMoreCfg_sourceOfFund = (state: AppState) =>
  state.cfg_sourceOfFund?.canLoadMore;
export const selectCfg_sourceOfFundDetail = (state: AppState) =>
  state.cfg_sourceOfFund?.cfg_sourceOfFundDetail;
export const selectCfg_sourceOfFundPagination = (state: AppState) =>
  state.cfg_sourceOfFund?.cfg_sourceOfFundPaginationConfig;
