import { SvgIcon } from 'app/components/SvgIcon';
import styles from './index.module.scss';
import { Row } from 'antd';
import RButton from 'app/components/Button';
import AddNewApplicationPopup from './components/AddNewApplicationPopup';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';

const WalletConnect = () => {
  const [form] = useForm();
  const [open, setOpen] = useState(false);

  const onOpenAddNewApplicationPopup = async () => {
    setOpen(true);
  };

  const onCloseAddNewApplicationPopup = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <SvgIcon name="wallet" width={28} height={28} />
        <h5 className={styles.title}>Rakkar Connect</h5>
      </div>
      <Row justify="end">
        <RButton
          buttonType="submit"
          content={
            <div className={styles['button-content']}>
              <SvgIcon name="plus-icon" width={16} height={16} />
              <p>Add new application</p>
            </div>
          }
          width={192}
          height={36}
          onClick={onOpenAddNewApplicationPopup}
        />
      </Row>

      <Row>
        <AddNewApplicationPopup
          form={form}
          open={open}
          onCloseModal={onCloseAddNewApplicationPopup}
        />
      </Row>
    </div>
  );
};

export default WalletConnect;
