import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RangePickerProps } from 'antd/lib/date-picker';
import RButton from 'app/components/Button';
import { SvgIcon } from 'app/components/SvgIcon';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import { StatusUserApprovalLogs } from 'enum/transaction';
import { isArray } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  transactionStatusOpts,
  typesTXN,
} from 'store/slices/common/commonStore.helper';
import {
  getDefaultTransactionRequestParams,
  selectIsReviewRequiredOnly,
  selectparamsTransactionList,
  transactionsActions,
} from 'store/slices/transactions';
import {
  isDecimalNumberKey,
  isAllowNumberAndOneDot,
  removeEmptyValueInObject,
  revertFormat,
} from 'utils/common';
import SelectAssetFilterTxn from '../SelectAssetFilterTxn';
import styles from './index.module.scss';

const { RangePicker } = DatePicker;

interface Props {
  isOpen: boolean;
  onFinishFilter: (values: any) => void;
}

const defaultFormFilter = {
  requestType: [],
  requestedBy: [],
  dateFrom: null,
  dateTo: null,
};

const FilterTransaction = ({ isOpen, onFinishFilter }: Props) => {
  const [form] = Form.useForm();
  const params = useAppSelector(selectparamsTransactionList);
  const isReviewRequiredOnly = useAppSelector(selectIsReviewRequiredOnly);
  const [isDisabledReset, setIsDisabledReset] = useState<boolean>(true);

  const [transactionStatus, setTransactionStatus] = useState<
    CheckboxValueType[]
  >([]);

  const [minValue, setMinValue] = useState<string | number>();
  const [maxValue, setMaxValue] = useState<string | number>();

  const [isErrValueUSD, setIsErrValueUSD] = useState(false);

  const dispatch = useDispatch();

  const setInitForm = () => {
    if (params?.priceFrom) {
      setMinValue(params?.priceFrom);
    }
    if (params?.priceTo) {
      setMaxValue(params?.priceTo);
    }

    form.setFieldsValue({
      ...(params?.txnDateFrom || params?.txnDateTo
        ? {
            lastUpdatedDate: [
              params?.txnDateFrom ? moment(params?.txnDateFrom) : undefined,
              params?.txnDateTo ? moment(params?.txnDateTo) : undefined,
            ],
          }
        : {}),
      ...(params?.dateFrom || params?.dateTo
        ? {
            initiatedDate: [
              params?.dateFrom ? moment(params?.dateFrom) : undefined,
              params?.dateTo ? moment(params?.dateTo) : undefined,
            ],
          }
        : {}),

      ...(params?.assetId
        ? {
            assetId: params?.assetId,
          }
        : {}),
    });
  };

  useEffect(() => {
    ///set init value
    const values = form.getFieldsValue();
    const isNoneValue = Object.values(values).some(e => !!e);
    const isEmpty =
      transactionStatus.length === 0 && !isNoneValue && !minValue && !maxValue;

    setIsDisabledReset(isEmpty);
    if (isOpen) {
      setInitForm();
    }
  }, [params, isOpen]);

  const onFinish = (values: any) => {
    const dataFilter: any = removeEmptyValueInObject({
      transactionType: values?.type ? values.type : undefined,
      status: values?.status ? values.status : undefined,
      isAllRequest: values?.status && values?.status?.length ? true : undefined,
      assetId: values?.assetId ? values.assetId : undefined,
      dateFrom:
        values?.initiatedDate && values?.initiatedDate[0]
          ? moment(values?.initiatedDate[0]).format(FORMAT_DATE.DATE_YMD)
          : undefined,
      dateTo:
        values?.initiatedDate && values?.initiatedDate[1]
          ? moment(values?.initiatedDate[1]).format(FORMAT_DATE.DATE_YMD)
          : undefined,
      txnDateFrom:
        values?.lastUpdatedDate && values?.lastUpdatedDate[0]
          ? moment(values?.lastUpdatedDate[0]).format(FORMAT_DATE.DATE_YMD)
          : undefined,
      txnDateTo:
        values?.lastUpdatedDate && values?.lastUpdatedDate[1]
          ? moment(values?.lastUpdatedDate[1]).format(FORMAT_DATE.DATE_YMD)
          : undefined,
      priceFrom: minValue ? Number(minValue) : undefined,
      priceTo: maxValue ? Number(maxValue) : undefined,
    });

    onFinishFilter(dataFilter);
  };

  useEffect(() => {
    if (minValue && maxValue) {
      const stt = revertFormat(minValue) > revertFormat(maxValue);
      setIsErrValueUSD(stt);
    }
  }, [minValue, maxValue]);

  useEffect(() => {
    if (isReviewRequiredOnly) {
      form.setFieldsValue({ status: [] });
    }
  }, [isReviewRequiredOnly]);

  const onClickReset = () => {
    setMaxValue(undefined);
    setMinValue(undefined);
    form.resetFields();
    setTransactionStatus([]);
    setIsErrValueUSD(false);
  };

  const onValuesChange = useCallback(
    (_, values: any) => {
      if (values.createdDate === 'custom') {
        delete values.createdDate;
      }
      const isNoneValue = Object.values(values).some(e => {
        if (isArray(e)) {
          return e.length;
        }
        return !!e;
      });
      const isEmpty = transactionStatus.length === 0 && !isNoneValue;

      setIsDisabledReset(isEmpty);
    },
    [transactionStatus],
  );

  const onChangeMin = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value === '') {
      setIsErrValueUSD(false);
    }
    if (!event?.target?.value || isAllowNumberAndOneDot(event?.target?.value)) {
      setIsDisabledReset(false);
      setMinValue(event?.target?.value);
    }
  };

  const onChangeMax = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value === '') {
      setIsErrValueUSD(false);
    }
    if (!event?.target?.value || isAllowNumberAndOneDot(event?.target?.value)) {
      setIsDisabledReset(false);
      setMaxValue(event?.target?.value);
    }
  };
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current.isAfter(moment().subtract(0, 'day'));
  };

  return (
    <div className={styles.container}>
      <Form
        form={form}
        initialValues={defaultFormFilter}
        layout="vertical"
        name="requestFilter"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <Row className={clsx({ [styles.header]: window.innerHeight <= 720 })}>
          <Col span={12} className={styles.title}>
            Filter
          </Col>
          <Col span={12} className={styles.reset}>
            <Button
              data-testid="reset-button"
              type="link"
              onClick={onClickReset}
              disabled={isDisabledReset}
              className={clsx({ [styles.disabled__button]: isDisabledReset })}
            >
              Clear all
            </Button>
          </Col>
        </Row>
        <div
          className={clsx({
            [styles.fiter__content]: window.innerHeight <= 720,
          })}
        >
          <Col span="24" className={styles.range}>
            <Form.Item
              label={<span className={styles.label}>Transaction type</span>}
              name={'type'}
            >
              <Select
                className={styles.createdDate}
                placeholder="Select"
                allowClear
                options={typesTXN}
                mode="multiple"
                disabled={isReviewRequiredOnly}
              />
            </Form.Item>
          </Col>
          <Col span="24" className={styles.range}>
            <Form.Item
              label={<span className={styles.label}>Transaction status</span>}
              name={'status'}
            >
              <Select
                className={styles.createdDate}
                placeholder="Select"
                allowClear
                options={transactionStatusOpts}
                mode="multiple"
                disabled={isReviewRequiredOnly}
              />
            </Form.Item>
          </Col>
          <Col span="24">
            <SelectAssetFilterTxn />
          </Col>
          <Col span={24} className={styles.range}>
            <Form.Item
              label={<span className={styles.label}>Initiated date</span>}
              name={'initiatedDate'}
            >
              <RangePicker
                separator={
                  <SvgIcon name="arrow-right" width={16} height={16} />
                }
                placeholder={['From', 'To']}
                allowEmpty={[true, true]}
                disabledDate={disabledDate}
                format={FORMAT_DATE.DEFAULT}
              />
            </Form.Item>
          </Col>
          <Col span={24} className={styles.range}>
            <Form.Item
              label={<span className={styles.label}>Last updated date</span>}
              name={'lastUpdatedDate'}
            >
              <RangePicker
                separator={
                  <SvgIcon name="arrow-right" width={16} height={16} />
                }
                placeholder={['From', 'To']}
                allowEmpty={[true, true]}
                disabledDate={disabledDate}
                format={FORMAT_DATE.DEFAULT}
              />
            </Form.Item>
          </Col>

          <div className={styles.box}>
            <span
              className={`${styles.box__title} ${
                isErrValueUSD && styles['box__title--error']
              }`}
            >
              Value in USD
            </span>
            <Input.Group compact className={styles.input__group}>
              <Col span={11}>
                <Input
                  value={minValue}
                  placeholder="Min"
                  className={clsx(styles.input__group__min, {
                    [styles.input__group__min__error]: isErrValueUSD,
                  })}
                  maxLength={50}
                  onKeyDown={(event: any) => {
                    if (!isDecimalNumberKey(event)) {
                      event.preventDefault();
                    }
                    return true;
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onChangeMin(event)
                  }
                  autoComplete="off"
                />
              </Col>
              <Col span={2}>
                <Input
                  disabled
                  className={clsx(styles.input__group__arrow, {
                    [styles.input__group__arrow__error]: isErrValueUSD,
                  })}
                />
              </Col>
              <Col span={11} className={styles.max}>
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onChangeMax(event)
                  }
                  value={maxValue}
                  placeholder="Max"
                  className={clsx(styles.input__group__max, {
                    [styles.input__group__max__error]: isErrValueUSD,
                  })}
                  maxLength={50}
                  onKeyDown={(event: any) => {
                    if (!isDecimalNumberKey(event)) {
                      event.preventDefault();
                    }

                    return true;
                  }}
                  autoComplete="off"
                />
                <div className={styles.max__suffix}>$</div>
              </Col>
            </Input.Group>
            <div
              style={{
                display:
                  minValue &&
                  maxValue &&
                  revertFormat(minValue) > revertFormat(maxValue)
                    ? 'block'
                    : 'none',
              }}
              className={styles.error__text}
            >
              Min value must be smaller than Max value
            </div>
          </div>
        </div>
        <Row
          style={{ justifyContent: 'end' }}
          className={clsx({ [styles.footer]: window.innerHeight <= 720 })}
          data-testid="submit-button"
        >
          <RButton
            htmlType="submit"
            buttonType="submit"
            content="Apply"
            width={75}
            height={32}
            style={{ padding: '0' }}
          />
        </Row>
      </Form>
    </div>
  );
};

export default FilterTransaction;
