import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './index.module.scss';

interface Props {
  title?: string;
  children: JSX.Element;
  visible: boolean;
  className?: string;
  footer?: ReactNode;
  closable?: boolean;
  closeIcon?: ReactNode;
  width?: string | number;
  onCancel?: () => void;
  onOk?: () => void;
  afterClose?: () => void;
  destroyOnClose?: boolean;
  maskClosable?: boolean;
  dataTestId?: string;
  centered?: boolean;
}

const BasicModal = ({
  children,
  visible,
  footer,
  closeIcon,
  width,
  onCancel,
  onOk,
  afterClose,
  className,
  closable,
  title,
  destroyOnClose,
  maskClosable,
  dataTestId,
  centered,
}: Props) => {
  return (
    <Modal
      maskClosable={maskClosable}
      title={title}
      closeIcon={closeIcon ? closeIcon : <CloseOutlined />}
      width={width}
      open={visible}
      className={clsx(styles.mainModal, className)}
      footer={footer}
      closable={closable}
      onCancel={onCancel}
      onOk={onOk}
      afterClose={afterClose}
      destroyOnClose={destroyOnClose}
      data-testid={dataTestId}
      centered={centered}
    >
      {children}
    </Modal>
  );
};

export default BasicModal;
