import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetWcAdapterRequestParamDTO,
  GetWcAdapterResponseDTO,
  ICreateWcAdapter,
  IWcAdapterDetail,
} from 'interfaces/wcAdapter';
export function getWcAdapterRequest(
  params?: GetWcAdapterRequestParamDTO,
): Promise<GetWcAdapterResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCADAPTER}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailWcAdapter(
  wcAdapterId: string,
): Promise<IWcAdapterDetail> {
  return Instance.get(`/${PREFIX_API.WCADAPTER}/${wcAdapterId}`);
}
export function createWcAdapterRequest(
  wcAdapter: ICreateWcAdapter,
): Promise<GetWcAdapterResponseDTO> {
  return Instance.post(`/${PREFIX_API.WCADAPTER}`, wcAdapter);
}
export function updateWcAdapterRequest(
  wcAdapter: ICreateWcAdapter,
): Promise<GetWcAdapterResponseDTO> {
  const payload: any = { ...wcAdapter };
  const id = wcAdapter.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.WCADAPTER}/${id}`, payload);
}
export function getWcAdapterRequestPaginationConfig(): Promise<GetWcAdapterResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCADAPTER}/pagination-config`);
}
