import { AuditLogStatus, TransactionStatus } from 'enum/common';
import { capitalize, lowerCase } from 'lodash';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  status: string;
};

const RakkarStatusButton: React.FC<Props> = ({ status }) => {
  const repStatus = TransactionStatus[status as keyof typeof TransactionStatus];
  if (!repStatus) return <></>;
  return (
    <div
      className={`${styles.container} ${
        repStatus === TransactionStatus.PENDING_REVIEW
          ? styles.pending_review
          : styles[lowerCase(status)]
      }`}
      data-testid="status"
    >
      {status === AuditLogStatus.SUCCESSFUL ? 'Success' : capitalize(repStatus)}
    </div>
  );
};

export default RakkarStatusButton;
