import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUserListing } from 'store/slices/customerProfile';
import { dotAndCommaFormat } from 'utils/common';
import styles from './index.module.scss';

type Props = {};

const ListTotal = (props: Props) => {
  const userList = useAppSelector(selectUserListing);
  const [total, setTotal] = useState({
    totalCount: 0,
    totalAdmin: 0,
    totalViewer: 0,
    totalMember: 0,
    totalActive: 0,
  });

  useEffect(() => {
    if (userList?.data) {
      setTotal(userList?.data);
    }
  }, [userList]);

  return (
    <div className={styles.total}>
      <div className={styles.total__actitve}>
        <div
          className={styles['total__actitve--number']}
          data-testid="active-user"
        >
          {dotAndCommaFormat(total.totalActive)}
        </div>
        <div className={styles.content}>
          <div className={styles.content__total}>Total</div>
          <div className={styles.content__type}>Active User</div>
        </div>
      </div>
      <div className={styles.total__common}>
        <div
          className={styles['total__common--total']}
          data-testid="total-admin"
        >
          {dotAndCommaFormat(total.totalAdmin)}
        </div>
        <div className={styles.content}>
          <div className={styles.content__total}>Total</div>
          <div className={styles.content__type}>Admin</div>
        </div>
      </div>
      <div className={styles.total__common}>
        <div
          className={styles['total__common--total']}
          data-testid="total-member"
        >
          {dotAndCommaFormat(total.totalMember)}
        </div>
        <div className={styles.content}>
          <div className={styles.content__total}>Total</div>
          <div className={styles.content__type}>Member</div>
        </div>
      </div>
      <div className={styles.total__common}>
        <div
          className={styles['total__common--total']}
          data-testid="total-viewer"
        >
          {dotAndCommaFormat(total.totalViewer)}
        </div>
        <div className={styles.content}>
          <div className={styles.content__total}>Total</div>
          <div className={styles.content__type}>Viewer</div>
        </div>
      </div>
    </div>
  );
};

export default ListTotal;
