/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'authConfig';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import RouterList from 'router/Router';
import styled from 'styled-components';
import { GlobalStyle } from 'styles/global-styles';
import { history } from 'utils/history';

export const msalInstance = new PublicClientApplication(msalConfig);

const ToastStyled = styled(ToastContainer)`
  .Toastify__toast {
    padding: 0;
    border-radius: 6px;
  }
  .Toastify__toast-body {
    min-height: 56px;
    padding: 0;
    margin: 0;
    align-items: normal;
  }

  .Toastify__close-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
  .Toastify__progress-bar {
    background-color: white;
    opacity: 0.75;
    top: 0;
  }
`;

export function App() {
  const { i18n } = useTranslation();

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="Rakkar Employee Portal | %s"
        defaultTitle="Rakkar Employee Portal"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A Rakkar application" />
      </Helmet>
      <MsalProvider instance={msalInstance}>
        <HistoryRouter history={history}>
          <RouterList />
        </HistoryRouter>
      </MsalProvider>
      <GlobalStyle />
      <ToastStyled
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme="colored"
      />
    </React.Fragment>
  );
}
