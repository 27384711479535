import CustomerInfo from './CustomerInfo';
import HeaderRequest from './HeaderRequest';
import UserInfo from './UserInfo';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
  isMask: boolean;
}

const UnlockUser = ({ requestDetail, isMask }: Props) => {
  return (
    <div className={styles.container} data-testid="unlock-user-request">
      <HeaderRequest requestDetail={requestDetail} />
      <CustomerInfo requestDetail={requestDetail} />
      <UserInfo requestDetail={requestDetail} isMask={isMask} />
    </div>
  );
};

export default UnlockUser;
