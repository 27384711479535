import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import { AxiosRequestConfig } from 'axios';

export function getBillingsListing(params): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/billings`, {
    params,
  });
}

export function getBillingDetail(id): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/billings/${id}`);
}

export function markAsBillingReviewed(id): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.CORE}/rep/customers/billings/${id}/review`,
  );
}

export function downloadBillingDetail(params): Promise<any> {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
    responseType: 'arraybuffer',
  };
  return Instance.post(
    `/${PREFIX_API.CORE}/rep/customers/billings/export`,
    {
      ...params,
    },
    config,
  );
}

export function viewInvoicePdf(payload): Promise<any> {
  return Instance.get(
    `/${PREFIX_API.CORE}/rep/customers/billings/${payload.billingId}/view-pdf`,
    { params: { isHistory: payload.isHistory } },
  );
}

export function markAsBillingPaid(id): Promise<any> {
  return Instance.post(`/${PREFIX_API.CORE}/rep/customers/billings/${id}/paid`);
}

export function syncTokenPrice(
  customerId: string,
  yearMonth: string,
): Promise<any> {
  return Instance.patch(
    `/${PREFIX_API.CORE}/rep/customers/${customerId}/billing/${yearMonth}/sync-price`,
  );
}

export function reGenerateBilling(
  customerId: string,
  yearMonth: string,
): Promise<any> {
  return Instance.patch(
    `/${PREFIX_API.CORE}/rep/customers/${customerId}/billing/${yearMonth}`,
  );
}
