import { PREFIX_API } from 'constants/index';
import {
  CreateFeeInformationReq,
  FetchFeeInformationRes,
  UpdateFeeInformationReq,
} from 'interfaces/businessInformation';
import {
  CustomerAccountQuorumResponse,
  ListVaultsUnAssignedRequest,
} from 'interfaces/customers';
import { ParamsUserList } from 'interfaces/request';
import {
  CustomerProfile,
  MarkAsLostDeviceRequest,
  ReplaceLostDeviceRequest,
  ResendEmailRequest,
  UnlockUserRequest,
  UserInfoDetail,
} from 'interfaces/userListing';
import Instance from '../app.v2/config/instance';

export function getUserListing(
  customerId: string,
  params: ParamsUserList,
): Promise<any> {
  return Instance.get(`/auth/account/${customerId}/users`, { params });
}

export function getUserDetailById(customerId: string): Promise<UserInfoDetail> {
  return Instance.get(`/auth/account/users/${customerId}`);
}

export function getCustomerById(
  customerId: string,
  params?: { includeAdditionalContacts?: boolean },
): Promise<CustomerProfile> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/${customerId}`, {
    params,
  });
}

export function getCustomerFeeById(
  customerId: string,
): Promise<FetchFeeInformationRes> {
  return Instance.get(`/${PREFIX_API.CORE}/rep/customers/${customerId}/fee`);
}

export function addNewCustomerFee(
  customerId: string,
  payload: CreateFeeInformationReq,
): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.CORE}/rep/customers/${customerId}/fee`,
    payload,
  );
}

export function updateCustomerFee(
  customerId: string,
  feeInformationId: string,
  payload: UpdateFeeInformationReq,
): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.CORE}/rep/customers/${customerId}/fee/${feeInformationId}`,
    payload,
  );
}

export function getCustomerAccountAdminPolicyById(
  customerId: string,
  query: { ignoreStatus?: boolean },
): Promise<CustomerAccountQuorumResponse> {
  return Instance.get(
    `/${PREFIX_API.CORE}/rep/customers/${customerId}/account-policy`,
    {
      params: { ...query },
    },
  );
}

export function markAsLostDevice(
  params: MarkAsLostDeviceRequest,
): Promise<any> {
  return Instance.post(`/${PREFIX_API.AUTH}/account/lost-device`, params);
}

export function replaceLostDevice(
  params: ReplaceLostDeviceRequest,
): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.AUTH}/account/rep-replace-device`,
    params,
  );
}

export function resendEmail(customerId: ResendEmailRequest): Promise<any> {
  return Instance.get(
    `/${PREFIX_API.AUTH}/onboarding/resend-email/${customerId.userId}`,
  );
}

export function getVaultsUnAssigned(
  params: ListVaultsUnAssignedRequest,
): Promise<any> {
  return Instance.get(`/${PREFIX_API.CORE}/vault/unassigned`, params);
}

export function unlockUser(params: UnlockUserRequest): Promise<any> {
  return Instance.put(
    `/${PREFIX_API.AUTH}/account/rep/unlock-user/${params.userId}`,
    params,
  );
}

export function removeUserAccess(
  userId: string,
  params: { isRemoveAccountAccess: boolean },
): Promise<any> {
  return Instance.put(`/${PREFIX_API.AUTH}/account/check-quorum/${userId}`, {
    ...params,
  });
}
