import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsLoggedIn, authActions } from 'store/slices/auth';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { useMsal } from '@azure/msal-react';

const ProtectedLayout = () => {
  const location = useLocation();
  const { instance } = useMsal();
  const [current, setCurrent] = useState(location.pathname);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/login',
    });
    dispatch(authActions.logout());
  };

  useEffect(() => {
    dispatch(authActions.getPermissionsRequest());
    dispatch(authActions.getUserProfileRequest());
  }, []);

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  );
};

export default ProtectedLayout;
