export enum SortListCustomer {
  customerName = 'CUSTOMER_NAME',
  businessRegistrationId = 'BUSINESS_REGISTRATION_ID',
  businessType = 'BUSINESS_TYPE',
  currentAddress = 'CURRENT_COUNTRY',
  status = 'STATUS',
  product = 'PRODUCT',
  workspace = 'WORKSPACE',
}

export enum SortPendingQuestList {
  createdAt = 'CREATED_DATE',
  updatedAt = 'UPDATED_DATE',
  type = 'REQUEST_TYPE',
  organizationName = 'CUSTOMER_NAME',
  createdBy = 'CREATED_BY',
  requestId = 'REQUEST_ID',
}
