import { Form, Select } from 'antd';
import { LabelTooltipType } from 'antd/es/form/FormItemLabel';
import { NamePath } from 'antd/lib/form/interface';
import { debounce } from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';
import { BaseParams, BaseResponse } from 'interfaces/base';

interface Props<TOption extends Record<string, any>> {
  name?: string;
  colon?: boolean;
  useLabel?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  className?: string;
  classNameFormItem?: string;
  rules?: object[];
  placeholder?: string;
  loading?: boolean;
  mode?: 'multiple' | 'tags';
  defaultValue?: TOption[Props<TOption>['keyValue']];
  onChange?: (value: any, options: any) => void;
  onSearch?: (value: string) => void;
  allowClear?: boolean;
  showSearch?: boolean;
  filterOption?: any;
  optionFilterProp?: string;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  initialValueForm?: string | number | [];
  noStyle?: boolean;
  selectStyle?: CSSProperties;
  size?: 'large' | 'middle' | 'small';
  namePath?: NamePath;
  keyValue: keyof TOption;
  labelValue: keyof TOption;
  dataTestId?: string;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  open?: boolean;
  tooltip?: LabelTooltipType;
  valueSelected?: TOption[Props<TOption>['keyValue']];
  maxTagCount?: number | 'responsive';
  serviceFunc: (
    params?: BaseParams,
  ) => Promise<BaseResponse<TOption, true>['data']>;
}

function DynamicSelect<TOption extends Record<string, any>>({
  keyValue,
  labelValue,
  rules,
  onSearch,
  useLabel,
  ...props
}: Props<TOption>) {
  const [options, setOptions] = useState<TOption[]>([]);

  const isRequired = rules
    ? rules.filter((r: any) => r.required === true).length > 0
    : false;

  const handleSearchDebounce = debounce((value: string) => {
    return onSearch && onSearch(value);
  }, 500);

  const isLabel = useLabel || true;

  const tagRender = props => {
    const { label } = props;
    return (
      <span>
        {label}
        {','}
      </span>
    );
  };

  useEffect(() => {
    props.serviceFunc().then(response => {
      setOptions(response.list);
    });
  }, [props.serviceFunc]);

  return (
    <Form.Item
      label={isLabel ? props.label : ''}
      colon={props.colon || false}
      name={props.name ?? props.namePath}
      required={!props.disabled && isRequired}
      rules={props.disabled ? [] : rules}
      className={props.classNameFormItem + ' ' + styles.select}
      initialValue={props.initialValueForm}
      noStyle={props.noStyle}
      tooltip={props.tooltip}
    >
      <Select
        getPopupContainer={props.getPopupContainer}
        className={clsx(props.className, {
          [styles.maxTagCount]: !!props.maxTagCount,
        })}
        disabled={props.disabled}
        placeholder={props.placeholder}
        loading={props.loading}
        mode={props.mode}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        allowClear={props.allowClear}
        showSearch={props.showSearch}
        filterOption={props.filterOption}
        optionFilterProp={props.optionFilterProp}
        size={props.size || 'middle'}
        onSearch={props.showSearch ? handleSearchDebounce : undefined}
        style={props.selectStyle}
        data-testid={props.dataTestId}
        placement={props.placement}
        open={props.open}
        value={props.valueSelected}
        maxTagCount={props.maxTagCount}
        tagRender={
          props.maxTagCount && props.mode === 'multiple' ? tagRender : undefined
        }
      >
        {options.map(option => (
          <Select.Option value={option[keyValue]} key={option[keyValue]}>
            {option[labelValue]}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default DynamicSelect;
