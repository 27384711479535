import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getCurrencyRequest,
  getDetailCurrency,
  createCurrencyRequest,
  updateCurrencyRequest,
} from 'services/currency.service';
import { currencyActions } from 'store/slices/currency/currency.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetCurrencyRequestParamDTO,
  ICreateCurrency,
} from 'interfaces/currency';
const handleGetCurrencyRequest = function* ({
  payload,
}: PayloadAction<GetCurrencyRequestParamDTO>) {
  try {
    const res = yield call(getCurrencyRequest, payload);
    const params = yield select(state => state.currency.params) as any;
    const listCurrency = yield select(
      state => state.currency?.currency?.data?.listCurrency,
    ) as any;
    if (res) {
      yield put(
        currencyActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listCurrency || []), ...res.list];
        yield put(currencyActions.appendCurrency(newList));
      } else {
        yield put(
          currencyActions.getCurrencySuccess({
            listCurrency: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(currencyActions.getCurrencyFailed());
  }
};
const handleGetDetailCurrencyRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailCurrency, payload);
    if (res) {
      yield put(currencyActions.getDetailCurrencySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(currencyActions.getDetailCurrencyFailed());
  }
};
const handleCreateCurrencyRequest = function* ({
  payload,
}: PayloadAction<ICreateCurrency>) {
  try {
    const res = yield call(createCurrencyRequest, payload);
    if (res) {
      yield put(currencyActions.createCurrencySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(currencyActions.createCurrencyFailed());
  }
};
const handleUpdateCurrencyRequest = function* ({
  payload,
}: PayloadAction<ICreateCurrency>) {
  try {
    const res = yield call(updateCurrencyRequest, payload);
    if (res) {
      yield put(currencyActions.updateCurrencySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(currencyActions.updateCurrencyFailed());
  }
};
const currencySaga = function* () {
  yield takeLatest(
    currencyActions.getCurrencyRequest.type,
    handleGetCurrencyRequest,
  );
  yield takeLatest(
    currencyActions.getDetailCurrencyRequest.type,
    handleGetDetailCurrencyRequest,
  );
  yield takeLatest(
    currencyActions.createCurrencyRequest.type,
    handleCreateCurrencyRequest,
  );
  yield takeLatest(
    currencyActions.updateCurrencyRequest.type,
    handleUpdateCurrencyRequest,
  );
};
export default currencySaga;
