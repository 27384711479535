import { TransactionType } from 'enum/common';

export const transactionDetail = {
  id: '58b9581b-f002-4505-8c32-93e4b4b18761',
  transactionId: 'TXN2022120400000001',
  type: 'REBALANCING',
  status: 'PENDING',
  amount: 20,
  feetype: null,
  name: 'XRP Test',
  symbol: 'XRP',
  network: 'Ripple Testnet',
  icon: 'https://rak-token-images.s3.ap-southeast-1.amazonaws.com/gray-scale/token_xrp.png',
  nativeAsset: 'XRP_TEST',
  blockExplorerUrl: 'https://testnet.xrpl.org/transactions/null',
  networkFee: null,
  txHash: 'e3601a...012c66',
  sourceName: 'Test With Avatar',
  createdAt: '2022-12-02T06:48:40.529Z',
  destinationName: 'Superme',
  sourceAddress: 'Test With Avatar',
  destinationAddress: 'Superme',
  createdById: '27a6116c-c984-4127-960c-d6a59fc1885a',
  feeCurrency: null,
  totalUSD: 7.786325738085794,
  price: null,
  totalFeeUSD: 0,
  externalLastUpdated: '2022-12-02T06:48:40.529Z',
  totalEstimatedFee: 0.000015,
  nativeSymbol: 'XRP',
  treatAsGrossAmount: true,
  createdByName: "Marcus O'Hara",
  requestId: 'd29ead12-91b1-48e2-afd4-5ab57ce2d684',
  customerName: 'Legacy',
  workspace: 'Rakkar (API Test)',
  fireblocksStatus: 'PENDING',
  fireblocksSubStatus: 'PENDING',
  approvalLogs: [
    {
      userId: null,
      status: 'APPROVED',
      reason: null,
      approverName: null,
      createdAt: '2022-12-02T06:48:40.703Z',
    },
    {
      userId: null,
      status: 'PENDING',
      reason: null,
      approverName: null,
      createdAt: '2022-12-02T06:48:40.702Z',
    },
    {
      userId: '27a6116c-c984-4127-960c-d6a59fc1885a',
      status: 'INITIATED',
      approverName: "Marcus O'Hara",
      approverAvatar:
        'https://rakkar-photos-dev.s3.ap-southeast-1.amazonaws.com/27a6116c-c984-4127-960c-d6a59fc1885a/avatar/09875b99-12e5-4762-91b2-8ca8da33b7f4.jpeg',
      createdAt: '2022-12-02T06:48:40.607Z',
    },
  ],
  reason: '',
  remainingApproversNumber: 2,
  remainingRequiredApprovers: [],
  remainingNonRequiredApprovers: [
    {
      userId: '8165ce3e-51ed-4041-8194-46a797005748',
      picture:
        'https://rakkar-photos-dev.s3.ap-southeast-1.amazonaws.com/8165ce3e-51ed-4041-8194-46a797005748/avatar/20bc6930-c1f1-41cc-95ab-ef80c045682c.jpeg',
      approverName: 'Margaret Berge III',
    },
    {
      userId: 'b5f935c5-4cf7-405f-a3cd-8db1032358c4',
      picture: '',
      approverName: 'Wilson Barton',
    },
  ],
};

export const infoTxn = (txnType: TransactionType) => {
  return {
    [TransactionType.INCOMING]: {
      title: 'Deposit',
      icon: 'Deposit',
    },
    [TransactionType.OUTGOING]: {
      title: 'Withdraw',
      icon: 'Withdraw',
    },
    [TransactionType.REBALANCING]: {
      title: 'Rebalance',
      icon: 'Rebalance',
    },
    [TransactionType.STAKING]: {
      title: 'Stake',
      icon: 'stake',
    },
    [TransactionType.UNSTAKING]: {
      title: 'Unstake',
      icon: 'unstake',
    },
    [TransactionType.CLAIM_REWARD]: {
      title: 'Claim rewards',
      icon: 'claim_reward',
    },
    [TransactionType.CHANGE_POOL]: {
      title: 'Change pool',
      icon: 'change_pool',
    },
    [TransactionType.CONTRACT_CALL]: {
      title: 'Contract call',
      icon: 'contract-call',
    },
  }[txnType];
};
