import { PayloadAction } from '@reduxjs/toolkit';
import ToastMessage from 'app/components/common/ToastMessage';
import { STATUS } from 'constants/index';
import {
  CreateUserRequest,
  GetCustomersRequest,
  ListVaultsByCustomerRequest,
} from 'interfaces/customers';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createNewUser,
  deleteCustomer,
  getCustomersList,
  getTotalActive,
  getVaultsByCustomer,
  updateStakingReq,
  updateUser,
} from 'services/customers';
import { commonActions } from 'store/slices/common';
import { customerProfileActions } from 'store/slices/customerProfile';
import { customersActions } from 'store/slices/customers';
import { handleShowMsgErr } from 'utils/common';
import { mapRequest } from 'utils/helpers';

const handleFetchCustomersRequest = function* ({
  payload,
}: PayloadAction<GetCustomersRequest>) {
  try {
    const { params } = payload;
    const paramsCustomer = yield select(state => state.customers.params) as any;
    const res = yield call(getCustomersList, params);

    if (paramsCustomer.page > 1) {
      yield put(customersActions.appendCustomer(res.customers));
    } else {
      yield put(customersActions.setCustomer(res));
    }

    yield put(
      customersActions.setCanLoadMore(
        paramsCustomer.page * paramsCustomer.limit < res.totalCount,
      ),
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customersActions.fetchCustomersFailed());
  }
};

const handleRefreshCustomer = function* ({
  payload,
}: PayloadAction<GetCustomersRequest>) {
  try {
    const { params } = payload;
    const res = yield call(getCustomersList, params);
    if (res) {
      yield put(customersActions.setCustomer(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customersActions.fetchCustomersFailed());
  }
};

const forceScreenCustomersRequest = function* ({
  payload,
}: PayloadAction<GetCustomersRequest>) {
  try {
    const defaultParams = yield select(state => state.customers.params) as any;

    const [customersList] = yield all([
      call(getCustomersList, mapRequest(defaultParams)),
    ]);
    yield put(
      customersActions.forceScreenSuccess({
        customersList,
      }),
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customersActions.forceScreenFailed(error.message));
  }
};

const handleFetchVaultsByCustomerRequest = function* ({
  payload,
}: PayloadAction<ListVaultsByCustomerRequest>) {
  try {
    const res = yield call(getVaultsByCustomer, payload.id);
    yield put(customersActions.fetchVaultsByCustomerSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customersActions.fetchVaultsByCustomerFailed(error.message));
  }
};

const createNewUserRequest = function* ({
  payload,
}: PayloadAction<CreateUserRequest>) {
  try {
    const res = yield call(createNewUser, payload.id, payload.user);
    yield put(customersActions.createNewUserSuccess(res));
    if (res)
      yield put(
        customerProfileActions.fetchUserListingRequest({
          customerId: payload.id,
          params: {},
        }),
      );
    yield put(commonActions.getTotalRequestRequest());
    toast(
      <ToastMessage
        type="info"
        message="Your request has been submitted for approval"
      />,
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customersActions.createNewUserFailed(error.message));
  }
};

const editUserRequestSaga = function* ({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(updateUser, payload.userId, payload.payload);
    yield put(customersActions.editUserSuccess(res));
    toast(
      <ToastMessage
        type="info"
        message="Your request has been submitted for approval"
      />,
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customersActions.editUserFailed());
  }
};

const deleteCustomerRequest = function* ({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(deleteCustomer, payload);

    const customersList = yield select(
      state => state.customers.customersList,
    ) as any;
    const newCustomers = customersList?.data?.customers.map(item => {
      if (item.id === res.id) {
        return { ...item, status: STATUS.INACTIVE };
      }
      return item;
    });
    yield put(customersActions.updateListCustomer(newCustomers));
    yield put(customersActions.deleteCustomerSuccess(res));
    yield put(customersActions.getTotalActiveRequest(''));
    toast(
      <ToastMessage
        type="info"
        message="Customer profile was deleted successfully"
      />,
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customersActions.deleteCustomerFailed());
  }
};

const handleGetTotalActiveRequest = function* ({
  payload,
}: PayloadAction<any>) {
  try {
    const res = yield call(getTotalActive);
    yield put(customersActions.getTotalActiveSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(customersActions.getTotalActiveFailed(''));
  }
};

const updateStakingRequest = function* ({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(updateStakingReq, payload.userId, payload.payload);
    yield put(customersActions.updateStakingSuccess(res));
    toast(
      <ToastMessage
        type="info"
        message="Your request has been submitted for approval"
      />,
    );
  } catch (error: any) {
    yield put(customersActions.updateStakingFailed());
  }
};

const customerSaga = function* () {
  yield takeLatest(
    customersActions.fetchCustomersRequest.type,
    handleFetchCustomersRequest,
  );
  yield takeLatest(
    customersActions.forceScreenRequest.type,
    forceScreenCustomersRequest,
  );
  yield takeLatest(
    customersActions.fetchVaultsByCustomerRequest.type,
    handleFetchVaultsByCustomerRequest,
  );
  yield takeLatest(
    customersActions.createNewUserRequest.type,
    createNewUserRequest,
  );
  yield takeLatest(
    customersActions.deleteCustomerRequest.type,
    deleteCustomerRequest,
  );
  yield takeLatest(
    customersActions.getTotalActiveRequest.type,
    handleGetTotalActiveRequest,
  );
  yield takeLatest(
    customersActions.refreshCustomers.type,
    handleRefreshCustomer,
  );
  yield takeLatest(customersActions.editUserRequest.type, editUserRequestSaga);
  yield takeLatest(
    customersActions.updateStakingRequest.type,
    updateStakingRequest,
  );
};
export default customerSaga;
