import { PREFIX_API } from 'constants/index';
import {
  ParamsPendingRequestDetail,
  ParamsRejectRequest,
} from 'interfaces/pendingRequest';
import { ApproveRequest, Creator } from 'interfaces/request';
import Instance from '../app.v2/config/instance';

export function approveCustomerCreationRequest(
  params: ApproveRequest,
): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.QUORUM}/quorums/approval/${params.quorumRecordId}`,
  );
}

export function getPendingRequestList({ params }): Promise<any> {
  return Instance.post(`/${PREFIX_API.QUORUM}/quorums`, params);
}

export function updateRejectRequest(params: ParamsRejectRequest): Promise<any> {
  return Instance.put(`/${PREFIX_API.QUORUM}/quorums/reject`, params);
}

export function getDetailPendingRequest(
  params: ParamsPendingRequestDetail,
): Promise<any> {
  return Instance.get(
    `/${PREFIX_API.QUORUM}/quorums/request/${params.requestId}`,
  );
}

export function getCreators(params?: any): Promise<Creator[]> {
  return Instance.get(`/${PREFIX_API.QUORUM}/quorums/list-creator`, params);
}
