import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import bookOpen from 'images/book-open.svg';

import { Params } from 'interfaces/request';

import { GetWalletInfoListRequestParamDTO } from 'interfaces/walletInfoList.interface';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  walletInfoListActions,
  selectParamWalletInfoList,
} from 'store/slices/walletInfoList/walletInfoList.slice';

import WalletInfoFilter from 'app/components/WalletInfo/WalletInfoFilter';
import WalletInfoList from 'app/components/WalletInfo/WalletInfoList';

import RButton from 'app/components/Button';
import BasicModal from 'app/components/common/BasicModal';
import { SvgIcon } from 'app/components/common/SvgIcon';

import moment from 'moment';
import { isNonEmptyObject } from 'utils/common';
import { mapRequest } from 'utils/helpers';
import styles from './WalletInfo.module.scss';

const WalletInfoPageContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 32px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #0c0d12;

  margin-left: 12px;
`;

const FilterButton = styled.div<{ isHasFilter: boolean }>`
  cursor: pointer;

  height: 36px;
  padding: 0 16px;
  border: 1px solid #d5d7df;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  background: ${({ isHasFilter }) => (isHasFilter ? '#ceddfb' : '#ffffff')};

  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    margin-left: 8px;
  }
`;

const WalletInfoListContainer = styled.div`
  margin-top: 24px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const queryParamsFilter = [
  'customerIds',
  'userIds',
  'categories',
  'types',
  'actions',
  'statuses',
  'dateFrom',
  'dateTo',
];

const WalletInfoPage: React.FunctionComponent = () => {
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [isHasFilter, setIsHasFilter] = useState(false);

  const [isMaskWalletInfo, setIsMaskWalletInfo] = useState(true);

  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParamWalletInfoList);

  const setValue = (key: keyof GetWalletInfoListRequestParamDTO) => {
    return (
      value: GetWalletInfoListRequestParamDTO[keyof GetWalletInfoListRequestParamDTO],
    ) => {
      dispatch(
        walletInfoListActions.setWalletInfoListParams({
          key,
          value,
        }),
      );
    };
  };

  const setKeyword = t => {
    setValue(Params.KeyWord)(t);
    setValue(Params.Search)(t);
    let whereOption = {};
    if (t) {
      whereOption = {
        OR: [
          { name: { CONTAINS: t } },
          { id: { CONTAINS: t } },
          { symbol: { CONTAINS: t } },
          { type: { CONTAINS: t } },
          { nativeAsset: { CONTAINS: t } },
          { decimals: { CONTAINS: t } },
          { tokenAddress: { CONTAINS: t } },
          { network: { CONTAINS: t } },
        ],
      };
      setValue(Params.Order)([{ sort: 'symbol', order: 'ASC' }]);
      setValue(Params.Sort)("ASC");
      setValue(Params.SortBy)("symbol");
    }else{
      setValue(Params.Order)([{ sort: 'createdAt', order: 'DESC' }]);
      setValue(Params.Sort)("DESC");
      setValue(Params.SortBy)("createdAt");
      
    }
    setValue(Params.Where)(whereOption);
  };
  const setOffset = setValue(Params.Offset);

  useEffect(() => {
    return () => {
      dispatch(walletInfoListActions.resetWalletInfoList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefresh = () => {
    setOffset(0);
    dispatch(walletInfoListActions.getWalletInfoListRequest(params));
  };

  const onInputSearch = (keyword: string) => {
    dispatch(walletInfoListActions.resetWalletInfoList());
    setOffset(0);
    setKeyword(keyword);
  };

  const onDownloadClick = () => setOpenDownloadModal(true);
  const onFinishFilter = (values: any) => {
    const { action, activityDate, category, company, status, type, username } =
      values;
    const paramsFilter = {
      customerIds: !!company ? company.toString() : undefined,
      userIds: !!username ? username.toString() : undefined,
      categories: !!category ? category.toString() : undefined,
      types: !!type ? type.toString() : undefined,
      actions: !!action ? action.toString() : undefined,
      statuses: !!status ? status.toString() : undefined,
      dateFrom:
        activityDate && activityDate?.[0]
          ? moment(activityDate[0]).startOf('day').toISOString()
          : undefined,
      dateTo:
        activityDate && activityDate?.[1]
          ? moment(activityDate[1]).endOf('day').toISOString()
          : undefined,
    };

    dispatch(
      walletInfoListActions.setWalletInfoListFilterParams({
        ...mapRequest(paramsFilter),
        offset: 0,
        limit: 10,
        isAllRequest: false,
      }),
    );

    const isHasParamsFilter = isNonEmptyObject(paramsFilter);
    setIsHasFilter(isHasParamsFilter);
  };
  useEffect(() => {
    dispatch(walletInfoListActions.getWalletInfoListRequest(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const FilterComponent = useCallback(
    () => (
      <FilterButton
        isHasFilter={isHasFilter}
        onClick={() => setIsFilterShown(prevState => !prevState)}
      >
        <SvgIcon
          name={`chevron-${isFilterShown ? 'up' : 'down'}`}
          width={16}
          height={16}
          fill="#64697B"
        />
        <div className="label">{`${
          isFilterShown ? 'Hide' : 'Show'
        } filter`}</div>
      </FilterButton>
    ),
    [isFilterShown, isHasFilter],
  );

  return (
    <WalletInfoPageContainer>
      <TitleContainer>
        <img src={bookOpen} alt="" />
        <Title>Wallet Info</Title>
      </TitleContainer>
      <WalletInfoFilter
        keyword={params.keyword}
        onInputSearch={onInputSearch}
        onRefresh={onRefresh}
        onDownload={onDownloadClick}
        onFinishFilter={onFinishFilter}
        isFilterShown={isFilterShown}
        FilterButton={FilterComponent}
        isMaskWalletInfo={isMaskWalletInfo}
        setIsMaskWalletInfo={setIsMaskWalletInfo}
      />
      <WalletInfoListContainer>
        <WalletInfoList
          isFilterShown={isFilterShown}
          isMaskWalletInfo={isMaskWalletInfo}
        />
      </WalletInfoListContainer>
      <BasicModal
        destroyOnClose={true}
        closable={false}
        visible={openDownloadModal}
        maskClosable={true}
        width={408}
        centered={true}
        key={'walletInfo'}
        title="Available to export up to 1,000 logs"
        children={<div>Do you want to export?</div>}
        // className="exportBasicModal"
        className={styles['exportBasicModal']}
        onCancel={() => setOpenDownloadModal(false)}
        footer={[
          <RButton
            data-testid="cancel-button"
            buttonType="cancel"
            content="Cancel"
            onClick={() => setOpenDownloadModal(false)}
            width={79}
            height={36}
            style={{ marginRight: 4 }}
            key={'cancel-button'}
          />,
        ]}
      />
    </WalletInfoPageContainer>
  );
};

export default WalletInfoPage;
