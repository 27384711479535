import Instance from 'app.v2/config/instance';
import { PREFIX_API } from 'constants/index';
import { BusinessInformationInterface } from 'interfaces/businessInformation';

export function editCustomer(
  params: BusinessInformationInterface,
): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.CORE}/v2/rep/customers/${params.id}/policy/request-update`,
    params,
  );
}
