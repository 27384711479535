import { SvgIcon } from 'app/components/SvgIcon';
import { dots } from 'app/components/common/TextInfor';
import clsx from 'clsx';
import { FORMAT_DATE } from 'constants/index';
import { TransactionStatusApprovalLogs } from 'enum/transaction';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { convertDocumentTypeToText, spacingRawText } from 'utils/common';
import ModalIndentityVerification from './components/ModalLivenessAndVideo';
import styles from './index.module.scss';

type Props = {
  isApprover?: boolean;
  idx?: number;
  user?: any;
  isBlur?: boolean;
  isShowInform?: boolean;
};

const ApproverDetail = ({
  isApprover,
  user,
  isBlur,
  isShowInform = true,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className={styles.container}
        key={`${isApprover && 'approver'}-${user?.userId}`}
      >
        {isShowInform && (
          <>
            <div className={styles.info}>
              <div className={styles.title}>
                {isApprover
                  ? `${capitalize(
                      TransactionStatusApprovalLogs[user?.status],
                    )} by`
                  : 'Initiated by'}
              </div>
              <div className={styles.content} data-testid="user-name">
                {isBlur ? <div>{dots(12)}</div> : user?.approverName || '-'}
                {isApprover && (
                  <div
                    className={`${styles.status} ${
                      styles[TransactionStatusApprovalLogs[user?.status]]
                    }`}
                  >
                    {capitalize(TransactionStatusApprovalLogs[user?.status])}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                {isApprover
                  ? `${capitalize(
                      TransactionStatusApprovalLogs[user?.status],
                    )} date`
                  : 'Initiated date'}
              </div>
              <div className={styles.content} data-testid="created-date">
                {user?.createdAt
                  ? moment(user?.createdAt).format(FORMAT_DATE.DATE_DMY_hM)
                  : '-'}
              </div>
            </div>
          </>
        )}
        {user?.location && (
          <div className={styles.info}>
            <div className={styles.title}>Location</div>
            <div className={styles.content} data-testid="location">
              {user?.location ? user?.location : '-'}
            </div>
          </div>
        )}
        {user?.role && (
          <div className={styles.info}>
            <div className={styles.title}>Role</div>
            <div className={styles.content} data-testid="role">
              {user?.role ? capitalize(user?.role) : '-'}
            </div>
          </div>
        )}
      </div>

      {user?.userId && (
        <div className={styles.mainTitle}>
          Identity Verification{' '}
          <SvgIcon
            name="redirect"
            width={16}
            height={16}
            onClick={() => {
              setOpen(true);
            }}
          />
          <div
            className={styles.container}
            key={`${isApprover && 'approver'}-${user?.userId}`}
          >
            {user?.imagePassport && (
              <div className={styles.passport}>
                <div
                  className={`${styles.image} ${isBlur && styles.blur}`}
                  data-testid="img-passport"
                >
                  <img src={user?.imagePassport} alt="" />
                  <div className={styles.text}>
                    {convertDocumentTypeToText(user?.documentType)}
                  </div>
                </div>
              </div>
            )}

            {(user?.imageLivenessOnboarding ||
              user?.imageLivenessAuthentication) && (
              <div className={styles.liveness}>
                <div className={styles.images}>
                  {user?.imageLivenessOnboarding && (
                    <div
                      className={`${styles.onboarding} ${
                        isBlur && styles.blur
                      }`}
                      data-testid="img-onboarding"
                    >
                      <img src={user?.imageLivenessOnboarding} alt="" />
                      <div className={styles.text}>Onboarding</div>
                    </div>
                  )}
                  {user?.imageLivenessAuthentication && (
                    <div
                      className={`${styles.authentication} ${
                        isBlur && styles.blur
                      }`}
                      data-testid="vdo-authentication"
                    >
                      <video
                        width="120"
                        height="154"
                        controls
                        className={`${isBlur && styles.blur}`}
                      >
                        <source
                          src={user?.imageLivenessAuthentication}
                          type="video/mp4"
                        />
                      </video>
                      <div className={styles.text}>Authentication</div>
                    </div>
                  )}
                </div>
                {user?.vdoSentence && (
                  <div className={styles.info}>
                    <div className={styles.title}>Speech prompt</div>
                    <div className={`${styles.content} ${styles.speech}`}>
                      {spacingRawText(user?.vdoSentence)}
                    </div>
                  </div>
                )}
              </div>
            )}
            {open && user && (
              <ModalIndentityVerification
                open={open}
                setOpen={setOpen}
                user={user}
              />
            )}
          </div>
        </div>
      )}

      {user?.reason && (
        <div className={clsx(styles.info, styles.infoReason)}>
          <div className={styles.title}>Reason</div>
          <div className={styles.reason} data-testid="reason">
            {user?.reason}
          </div>
        </div>
      )}
    </>
  );
};

export default ApproverDetail;
