import { Checkbox, Table } from 'antd';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { STATUS_REQUEST } from 'constants/index';
import accessControl from 'images/AccessControl.png';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
}

const columns: any = [
  {
    title: 'Customer Groups',
    dataIndex: 'name',
    key: 'name',
    render: (_, item) => {
      return (
        <div className={styles.feature_item}>
          <span>{item?.name}</span>
        </div>
      );
    },
    sorter: false,
    width: '50%',
  },
  {
    title: 'Current access',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (_, item) => {
      return (
        <div className={styles.feature_item}>
          <span>{item?.name}</span>
        </div>
      );
    },
    sorter: false,
    width: '138px',
    children: [
      {
        title: 'Mobile',
        dataIndex: 'oldMobile',
        key: 'oldMobile',
        align: 'center',
        width: '80px',
        render: (_, item) => {
          return (
            <Checkbox
              className={styles.check_box_access_control}
              checked={item.oldMobile === 'true'}
              disabled={true}
            />
          );
        },
      },
      {
        title: 'Web',
        dataIndex: 'oldWeb',
        key: 'oldWeb',
        align: 'center',
        width: '80px',
        render: (_, item) => {
          return (
            <Checkbox
              className={styles.check_box_access_control}
              checked={item.oldWeb === 'true'}
              disabled={true}
            />
          );
        },
      },
    ],
  },
  {
    title: 'New access',
    dataIndex: 'name',
    key: 'name',
    render: (_, item) => {
      return (
        <div className={styles.feature_item}>
          <span>{item?.name}</span>
        </div>
      );
    },
    align: 'center',
    children: [
      {
        title: 'Mobile',
        dataIndex: 'newMobile',
        key: 'newMobile',
        align: 'center',
        width: '80px',
        render: (_, item) => {
          return {
            props: {
              style: {
                background: item.isDifferent ? '#F5F8FF' : '',
              },
            },
            children: (
              <Checkbox
                className={styles.check_box_access_control}
                checked={item.newMobile === 'true'}
                disabled={true}
              />
            ),
          };
        },
      },
      {
        title: 'Web',
        dataIndex: 'newWeb',
        key: 'newWeb',
        align: 'center',
        width: '80px',
        render: (_, item) => {
          return {
            props: {
              style: {
                background: item.isDifferent ? '#F5F8FF' : '',
              },
            },
            children: (
              <Checkbox
                className={styles.check_box_access_control}
                checked={item.newWeb === 'true'}
                disabled={true}
              />
            ),
          };
        },
      },
    ],
    sorter: false,
    width: '25%',
  },
];

const AccessControl = ({ requestDetail }: Props) => {
  const isApproved = requestDetail?.status === STATUS_REQUEST.APPROVED;
  const isRejected = requestDetail?.status === STATUS_REQUEST.REJECTED;
  const feature = requestDetail?.data?.feature;
  const [dataSource, setDataSource] = useState();
  const oldGroups = feature?.oldGroups;
  const newGroups = feature?.newGroups;

  const differentElements = _.differenceWith(oldGroups, newGroups, _.isEqual);

  useEffect(() => {
    const newDatasource = feature.oldGroups.map((group, index) => {
      const isDifferent = differentElements.some(e => e.id === group.id);
      return {
        ...group,
        oldWeb: group.web,
        oldMobile: group.mobile,
        newWeb: feature.newGroups[index]?.web,
        newMobile: feature.newGroups[index]?.mobile,
        isDifferent: isDifferent,
      };
    });
    setDataSource(newDatasource);
  }, [feature]);

  const approveOrReject = (isApproved: boolean) => {
    return (
      (isApproved || isRejected) && (
        <div
          className={`${styles.status} ${
            isApproved ? styles.approved : styles.rejected
          }`}
        >
          <div>
            <SvgIcon name={isApproved ? 'white-approve' : 'white-reject'} />
          </div>
          <div>
            {isApproved ? STATUS_REQUEST.APPROVED : STATUS_REQUEST.REJECTED}
          </div>
        </div>
      )
    );
  };

  return (
    <>
      <div
        className={styles.container}
        data-testid="update-access-control-request"
      >
        <img src={accessControl} alt="" width={32} height={32} />
        <div className={styles.title} data-testId="title">
          <div>Edit Network transfer access request</div>
          {approveOrReject(isApproved)}
        </div>
      </div>
      <div className={styles.user__info}>
        <Table
          columns={columns}
          loading={false}
          dataSource={dataSource}
          pagination={false}
          className={styles.group_table}
          data-testId="table"
          bordered
        />
      </div>
    </>
  );
};

export default AccessControl;
