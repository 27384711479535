import { getDefaultAvatarInfo } from 'utils/common';
import styles from './index.module.scss';

interface Props {
  avatar: string | null;
  name: string;
}
const RakkarAvatar = ({ avatar, name }: Props) => {
  if (!avatar) {
    return (
      <div className={styles.profile__avatar}>
        <div
          className={styles['profile__avatar--default']}
          data-testid="avatar-default"
        >
          {getDefaultAvatarInfo(name ?? '')}
        </div>
      </div>
    );
  }
  return (
    <img className={styles.avatar} src={avatar} alt="" data-testid="avatar" />
  );
};

export default RakkarAvatar;
