import ReviewAndSubmitEditCus from 'app/components/EditCustomer/components/ReviewAndSubmit';
import { ContactPersonType } from 'enum/addNewCustomer';
import { BusinessInformationInterface } from 'interfaces/businessInformation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { newCustomerActions } from 'store/slices/addNewCustomer';
import { commonActions } from 'store/slices/common';
import { getTextAddress } from 'utils/helpers';

interface Props {
  requestDetail: any;
  isMask: boolean;
  requestStatus: string;
}

const EditCustomerProfile = ({
  requestDetail,
  isMask,
  requestStatus,
}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestDetail.customerEditInfo) {
      const current = requestDetail.customerEditInfo.current;
      const currentBillingContactInfo = current?.additionalContacts?.find(
        additionalContact =>
          additionalContact.type === ContactPersonType.BILLING,
      );

      const currentOtherContactInfo = current?.additionalContacts?.find(
        additionalContact =>
          additionalContact.type === ContactPersonType.GENERAL,
      );
      if (current) {
        const currentCusInform: BusinessInformationInterface = {
          ...current,
          billing: getTextAddress(current.billingAddress),
          registration: getTextAddress(current.registrationAddress),
          current: getTextAddress(current.currentAddress),
          billingPerson: currentBillingContactInfo,
          otherPerson: currentOtherContactInfo,
        };

        dispatch(
          newCustomerActions.setOldBusinessInformation(currentCusInform),
        );
      }

      const changes = requestDetail.customerEditInfo.changes;
      const newBillingContactInfo = changes?.additionalContacts?.find(
        i => i.type === ContactPersonType.BILLING,
      );

      const newOtherContactInfo = changes?.additionalContacts?.find(
        i => i.type === ContactPersonType.GENERAL,
      );
      if (changes) {
        const newCusInform: BusinessInformationInterface = {
          ...changes,
          billing: getTextAddress(changes.billingAddress),
          registration: getTextAddress(changes.registrationAddress),
          current: getTextAddress(changes.currentAddress),
          billingPerson: newBillingContactInfo,
          otherPerson: newOtherContactInfo,
        };

        dispatch(newCustomerActions.updateBussinessInformation(newCusInform));
      }
    }
  }, [requestDetail, dispatch]);

  useEffect(() => {
    dispatch(commonActions.getTypeOfCompanyRequest({}));
    dispatch(commonActions.getSourceOfFundRequest({}));
    dispatch(commonActions.getCustomerEntityRelationRequest());
  }, [dispatch]);

  return (
    <ReviewAndSubmitEditCus
      isMask={isMask}
      isRequest={true}
      requestStatus={requestStatus}
    />
  );
};

export default EditCustomerProfile;
