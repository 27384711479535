import styles from './index.module.scss';
import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { debounce } from 'lodash';
import { KEY_CODE } from 'constants/index';
import { PAGE } from 'enum/common';
import { useDispatch } from 'react-redux';
import {
  customersActions,
  selectParamsCustomers,
} from 'store/slices/customers';
import BasicButton from '../BasicButton';
import { useAppSelector } from 'store/hooks';

interface Props extends HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  onInputChange?: (term: string) => void;
  term?: string;
  isTest?: boolean;
  page?: string;
  isSuportSearchDebounce?: boolean;
}
const InputSearch = ({
  onInputChange,
  placeholder = 'Search...',
  isSuportSearchDebounce = true,
  ...props
}: Props) => {
  const [inputValue, setInputValue] = useState<any>('');
  const dispatch = useDispatch();
  const params = useAppSelector(selectParamsCustomers);

  useEffect(() => {
    setInputValue(props.term);
  }, [props.term]);
  useEffect(() => {
    if (props.isTest === true) {
      setInputValue('test');
    }
  }, [props.isTest]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const TIME_DEBOUNCE = 500;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(newValue => onInputChange?.(newValue.trim()), TIME_DEBOUNCE),
    [params],
  );

  const handleUpdateFirstRender = () => {
    props?.page === PAGE.CUSTOMER_MANAGEMENT &&
      dispatch(customersActions.updateFirstRender(false));
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.which === KEY_CODE.ENTER ||
      event.keyCode === KEY_CODE.ENTER ||
      event.key === 'Enter'
    ) {
      const { value } = event.currentTarget;
      onInputChange?.(value.trim());
      handleUpdateFirstRender();
      // updateValue(value);
    }
  };
  const updateValue = (newValue: string) => {
    setInputValue(newValue);
    isSuportSearchDebounce && debouncedSave(newValue);
  };
  const handleClick = () => {
    handleUpdateFirstRender();
    onInputChange && onInputChange(inputValue);
  };
  const handleChange = event => {
    let value = event.target.value;
    updateValue(value);
  };
  const handleClear = () => {
    setInputValue('');
    debouncedSave('');
  };
  return (
    <div className={styles.search} {...props}>
      <SvgIcon
        className={styles.search__icon}
        name="search"
        width={13}
        height={13}
        fill="#64646c"
        onClick={handleClick}
      />
      <input
        data-testid="input-search"
        className={`${styles.search__input} ${
          props.page === PAGE.AUDITLOGLIST && styles['search_AuditLogList']
        }`}
        type="text"
        autoComplete="on"
        name="search"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
      {inputValue && (
        <SvgIcon
          data-test-id="icon-clear"
          className={`${styles.search__clear} ${
            props.page === PAGE.CUSTOMER_MANAGEMENT &&
            styles['search__have--btn']
          }`}
          name="clear-search"
          width={18}
          height={18}
          fill="#25397C"
          onClick={handleClear}
        />
      )}
      {props.page === PAGE.CUSTOMER_MANAGEMENT && (
        <BasicButton title="Search" type="filled" onClick={handleClick} />
      )}
    </div>
  );
};
export default InputSearch;
