import RTable from 'app/components/Table';
import { renderIcon } from 'app/pages/CustomerManagement';
import { commonAttributesColumnTable } from 'constants/index';
import { GetListAccessControlRequestParams } from 'interfaces/accessControl';
import { Params, RequestStatus } from 'interfaces/request';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  accessControlListActions,
  selectAccessControlList,
  selectParamAccessControlList,
} from 'store/slices/accessControl/accessControl.slice';
import PopupAccessControl from '../AccessControlDetail';
import styles from './index.module.scss';

const columns: any = [
  {
    ...commonAttributesColumnTable,
    title: ({ sortOrder, sortColumn }: any) => {
      return renderIcon(sortOrder, sortColumn, 'Feature', 'feature');
    },
    dataIndex: 'feature',
    key: 'feature',
    render: (_, item) => {
      return (
        <div className={styles.feature_item}>
          <span>{item?.name}</span>
        </div>
      );
    },
    sorter: false,
    width: '40%',
  },
  {
    ...commonAttributesColumnTable,
    title: ({ sortOrder, sortColumn }: any) => {
      return renderIcon(sortOrder, sortColumn, 'Description', 'description');
    },
    dataIndex: 'description',
    key: 'description',
    sorter: false,
    render: (_, item) => {
      return (
        <div className={styles.table_items}>
          <span>{item?.description}</span>
        </div>
      );
    },
    width: '40%',
  },
  {
    ...commonAttributesColumnTable,
    title: ({ sortOrder, sortColumn }: any) => {
      return renderIcon(sortOrder, sortColumn, 'Enabled for', 'enabled_for');
    },
    dataIndex: 'enabled_for',
    key: 'enabled_for',
    sorter: false,
    render: (_, item) => {
      return (
        item?.numberGroups > 0 && (
          <div className={styles.table_items}>
            <span>
              {item?.numberGroups}
              {item?.numberGroups === 1
                ? ' customer group'
                : ' customer groups'}
            </span>
          </div>
        )
      );
    },
    width: '20%',
  },
];

type Props = {
  keyword?: string;
};

const AccessControlListing = ({ keyword }: Props) => {
  const dispatch = useDispatch();
  const accessControlList = useAppSelector(selectAccessControlList);
  const params = useAppSelector(selectParamAccessControlList);
  const [accessControls, setAccessControls] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState('');

  useEffect(() => {
    if (accessControlList?.data?.features) {
      setAccessControls(accessControlList?.data?.features);
    }
    if (accessControlList?.status === RequestStatus.Loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [accessControlList]);

  const handleClickOnRow = (value: any) => {
    if (value) {
      setOpen(true);
      setItemSelected(value);
      setSelectedRowId(value.id);
    }
  };

  const setValue = (key: keyof GetListAccessControlRequestParams) => {
    return (
      value: GetListAccessControlRequestParams[keyof GetListAccessControlRequestParams],
    ) => {
      dispatch(
        accessControlListActions.setListAccessControlParams({
          key,
          value,
        }),
      );
    };
  };

  const setOffset = setValue(Params.Offset);
  const setKeyword = setValue(Params.KeyWord);

  useEffect(() => {
    dispatch(
      accessControlListActions.getAccessControlListRequest({
        ...params,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    dispatch(
      accessControlListActions.getAccessControlListRequest({
        ...params,
        keyword: keyword,
      }),
    );

    setOffset(0);
    setKeyword(keyword);
  }, [keyword]);

  useEffect(() => {
    if (!open) {
      setOpen(false);
      setSelectedRowId('');
    }
  }, [selectedRowId, open]);

  return (
    <>
      <div className={styles.table_container_1}>
        <RTable
          currentTableData={accessControls}
          columns={columns}
          loading={loading}
          clickOnRow={handleClickOnRow}
          selectedRowId={selectedRowId}
          searchValue={params?.keyword}
        />
      </div>
      {!!itemSelected && (
        <PopupAccessControl
          isOpen={open}
          setIsOpen={setOpen}
          accessControlId={selectedRowId}
          loading={false}
        />
      )}
    </>
  );
};

export default AccessControlListing;
