import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getWcAppEntityRequest,
  getDetailWcAppEntity,
  createWcAppEntityRequest,
  updateWcAppEntityRequest,
  getWcAppEntityRequestPaginationConfig,
} from 'services/wcAppEntity.service';
import { wcAppEntityActions } from 'store/slices/wcAppEntity/wcAppEntity.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetWcAppEntityRequestParamDTO,
  ICreateWcAppEntity,
} from 'interfaces/wcAppEntity';
const handleGetWcAppEntityRequest = function* ({
  payload,
}: PayloadAction<GetWcAppEntityRequestParamDTO>) {
  try {
    const res = yield call(getWcAppEntityRequest, payload);
    const params = yield select(state => state.wcAppEntity.params) as any;
    const listWcAppEntity = yield select(
      state => state.wcAppEntity?.wcAppEntity?.data?.listWcAppEntity,
    ) as any;
    if (res) {
      yield put(
        wcAppEntityActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listWcAppEntity || []), ...res.list];
        yield put(wcAppEntityActions.appendWcAppEntity(newList));
      } else {
        yield put(
          wcAppEntityActions.getWcAppEntitySuccess({
            listWcAppEntity: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppEntityActions.getWcAppEntityFailed());
  }
};
const handleGetDetailWcAppEntityRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailWcAppEntity, payload);
    if (res) {
      yield put(wcAppEntityActions.getDetailWcAppEntitySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppEntityActions.getDetailWcAppEntityFailed());
  }
};
const handleCreateWcAppEntityRequest = function* ({
  payload,
}: PayloadAction<ICreateWcAppEntity>) {
  try {
    const res = yield call(createWcAppEntityRequest, payload);
    if (res) {
      yield put(wcAppEntityActions.createWcAppEntitySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppEntityActions.createWcAppEntityFailed());
  }
};
const handleUpdateWcAppEntityRequest = function* ({
  payload,
}: PayloadAction<ICreateWcAppEntity>) {
  try {
    const res = yield call(updateWcAppEntityRequest, payload);
    if (res) {
      yield put(wcAppEntityActions.updateWcAppEntitySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppEntityActions.updateWcAppEntityFailed());
  }
};
const handleGetWcAppEntityPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetWcAppEntityRequestParamDTO>) {
  try {
    const res = yield call(getWcAppEntityRequestPaginationConfig);
    if (res) {
      yield put(wcAppEntityActions.getWcAppEntityPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcAppEntityActions.getWcAppEntityPaginationConfigFailed());
  }
};
const wcAppEntitySaga = function* () {
  yield takeLatest(
    wcAppEntityActions.getWcAppEntityRequest.type,
    handleGetWcAppEntityRequest,
  );
  yield takeLatest(
    wcAppEntityActions.createWcAppEntityRequest.type,
    handleCreateWcAppEntityRequest,
  );
  yield takeLatest(
    wcAppEntityActions.updateWcAppEntityRequest.type,
    handleUpdateWcAppEntityRequest,
  );
  yield takeLatest(
    wcAppEntityActions.getWcAppEntityPaginationConfigRequest.type,
    handleGetWcAppEntityPaginationConfigRequest,
  );
  yield takeLatest(
    wcAppEntityActions.getDetailWcAppEntityRequest.type,
    handleGetDetailWcAppEntityRequest,
  );
};
export default wcAppEntitySaga;
