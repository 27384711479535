import { LoadingOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Row, Select } from 'antd';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { IWalletInfoDetail } from 'interfaces/walletInfoList.interface';
import { RequestStatus } from 'interfaces/request';
import { startCase, upperFirst } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  walletInfoListActions,
  selectWalletInfoDetail,
} from 'store/slices/walletInfoList/walletInfoList.slice';
import styled from 'styled-components/macro';
import { getTextAddress } from 'utils/helpers';
import styles from './WalletInfoDetail.module.scss';
import { Rule } from 'antd/lib/form';
import { WalletInfoStatus } from 'enum/common';
import RButton from '../Button';

const WalletFields = [
  { key: 'id', required: false, disabled: true, canInsert: true },
  { key: 'name' },
  { key: 'type' },
  { key: 'nativeAsset' },
  { key: 'decimals' },
  { key: 'tokenAddress' },
  { key: 'network' },
  { key: 'image' },
  { key: 'networkImage' },
  { key: 'blockExplorerUrl' },
  { key: 'minimumAmount' },
  { key: 'blockExplorerTxUrl' },
  { key: 'blockExplorerTokenUrl', required: false },
  { key: 'status' },
];

const layoutEdit = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayoutEdit = {
  wrapperCol: { offset: 8, span: 16 },
};

export const WalletInfoEditChildren = ({
  item,
  isMaskWalletInfo,
  id,
  onClose,
}) => {
  const isCreated = id === undefined;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const walletInfoDetail = useAppSelector(selectWalletInfoDetail);

  const [walletFields, setWalletFields] = useState([
    { key: 'id', required: false, disabled: true, canInsert: false },
    { key: 'tokenAddress', required: true },
    { key: 'name', required: true },
    { key: 'symbol', required: true },
    { key: 'image', required: false },
    { key: 'externalAssetId', required: false },
    { key: 'nativeAsset', required: false },
    { key: 'decimals', required: false },
    { key: 'type', required: false },
    { key: 'blockExplorerUrl', required: false },
    { key: 'network', required: false },
    { key: 'blockExplorerTxUrl', required: false },
    { key: 'minimumAmount', required: false },
    { key: 'networkImage', required: false },
    { key: 'blockExplorerTokenUrl', required: false },
  ]);

  useEffect(() => {
    if (!isCreated) {
      form.setFieldsValue(item);
    }
  }, [form, item]);

  const onFinish = (values: any) => {
    if (isCreated) {
      dispatch(walletInfoListActions.createWalletInfoRequest(values));
    } else {
      dispatch(walletInfoListActions.updateWalletInfoRequest(values));
    }
  };

  //TODO: refactor later
  useEffect(() => {
    if (isCreated && walletInfoDetail?.data?.['id'] !== undefined) {
      dispatch(walletInfoListActions.resetWalletInfoListParams());
      onReset();
      onClose();
    }

    if (!!id && walletInfoDetail?.data?.['affected'] !== undefined) {
      dispatch(walletInfoListActions.resetWalletInfoListParams());
      onClose();
    }
  }, [dispatch, walletInfoDetail]);

  useEffect(() => {
    if (isCreated) {
      setWalletFields([
        { key: 'tokenAddress', required: true },
        { key: 'name', required: true },
        { key: 'symbol', required: true },
        { key: 'image', required: false },
        { key: 'externalAssetId', required: false },
        { key: 'nativeAsset', required: false },
        { key: 'decimals', required: false },
        { key: 'type', required: false },
        { key: 'blockExplorerUrl', required: false },
        { key: 'network', required: false },
        { key: 'blockExplorerTxUrl', required: false },
        { key: 'minimumAmount', required: false },
        { key: 'networkImage', required: false },
        { key: 'blockExplorerTokenUrl', required: false },
      ]);
    }
  }, [isCreated]);

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Form {...layoutEdit} form={form} name="control-hooks" onFinish={onFinish}>
      {walletFields.map(({ key, required, disabled, canInsert }) => {
        return (
          <Form.Item
            name={key}
            label={upperFirst(key)}
            rules={[{ required: required }] as Rule[]}
          >
            {isCreated ? (
              <Input required={isCreated && required} />
            ) : (
              <Input
                required={isCreated && required}
                disabled={isCreated && canInsert ? false : disabled}
              />
            )}
          </Form.Item>
        );
      })}

      <Form.Item
        name="status"
        label={upperFirst('status')}
        rules={[{ required: true }] as Rule[]}
      >
        <Select
          style={{ width: 200 }}
          options={[
            {
              value: WalletInfoStatus.ACTIVE,
              label: WalletInfoStatus.ACTIVE,
            },
            {
              value: WalletInfoStatus.DEACTIVE,
              label: WalletInfoStatus.DEACTIVE,
            },
          ]}
        />
      </Form.Item>
      <Form.Item {...tailLayoutEdit}>
        <RButton
          htmlType="submit"
          buttonType="submit"
          content="Submit"
          width={100}
          height={32}
          style={{ marginLeft: '20px' }}
        />

        <RButton
          htmlType="button"
          buttonType="cancel"
          content="Reset"
          width={100}
          height={32}
          onClick={onReset}
          style={{ marginLeft: '10px' }}
        />
      </Form.Item>
    </Form>
  );
};

export const WalletInfoDetailChildren = ({ item, isMaskWalletInfo }) => {
  const { id, name, symbol } = item || {};

  return (
    <div>
      <Section>
        <div className="title">Detail</div>
        <div className="line"></div>
      </Section>
      <SectionDataContainer>
        {WalletFields.map(({ key }) => {
          return (
            <SectionName key={key}>
              <div className="title">{upperFirst(key)}</div>
              <div className="label" data-testid="category">
                {item?.[key] || '--'}
              </div>
            </SectionName>
          );
        })}
      </SectionDataContainer>
    </div>
  );
};

const MetadataDetailChildren = ({ item, isMaskWalletInfo }) => {
  return (
    <MetadataContainer isMaskWalletInfo={isMaskWalletInfo}>
      <Section>
        <div className="title">Metadata</div>
        <div className="line"></div>
      </Section>
      {item &&
        Object.keys(item).map(key => {
          let value;
          if (typeof item[key] === 'string' || typeof item[key] === 'number') {
            value = item[key];
          } else {
            value =
              key === 'address'
                ? getTextAddress(item[key])
                : JSON.stringify(item[key]);
          }
          return (
            <SectionDataContainer key={key}>
              <SectionName>
                <div className="title">{startCase(key)}</div>
                <div className="label">{value}</div>
              </SectionName>
            </SectionDataContainer>
          );
        })}
    </MetadataContainer>
  );
};

const getItems = (
  item?: IWalletInfoDetail,
  isMaskWalletInfo?: boolean,
  id?: string | number,
  onClose?: () => void,
) => [
  ...(id
    ? [
        {
          key: '1',
          label: `Detail info`,
          children: (
            <WalletInfoDetailChildren
              item={item}
              isMaskWalletInfo={isMaskWalletInfo}
            />
          ),
        },
        // {
        //   key: '2',
        //   label: `Metadata`,
        //   children: (
        //     <MetadataDetailChildren
        //       item={item?.metaData}
        //       isMaskWalletInfo={isMaskWalletInfo}
        //     />
        //   ),
        // },
      ]
    : []),
  {
    key: '3',
    label: `Upsert`,
    children: (
      <WalletInfoEditChildren
        item={item}
        isMaskWalletInfo={isMaskWalletInfo}
        id={id}
        onClose={onClose}
      />
    ),
  },
];

const TabBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 28px;
`;

const TabBar = styled.div<{ isActive: boolean }>`
  width: 292px;
  height: 36px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: ${({ isActive }) => (isActive ? '#25397C' : '#2C303C')};
  background-color: ${({ isActive }) => (isActive ? '#E5EDFC' : '#FFFFFF')};

  border: 1px solid ${({ isActive }) => (isActive ? '#CEDDFB' : '#D5D7DF')};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;

  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 6px 8px;

  .LogID {
    font-weight: 600;
    font-size: 12px;x
    color: #959aaa;
  }

  .icon {
    cursor: pointer;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 20px;

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #2c303c;
  }

  .line {
    height: 1px;
    flex: 1;
    background: #ebecf0;

    margin-left: 20px;
  }
`;

const MetadataContainer = styled.div<{ isMaskWalletInfo: boolean }>`
  filter: ${({ isMaskWalletInfo }) =>
    isMaskWalletInfo ? 'blur(6px)' : 'none'};
`;

const SectionDataContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
`;

const SectionName = styled.div<{ isName?: boolean }>`
  flex: 1;

  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #64697b;

    margin-bottom: 6px;
  }

  .label {
    font-weight: ${({ isName }) => (isName ? 600 : 400)};
    font-size: 14px;
    line-height: 20px;

    color: ${({ isName }) => (isName ? '#2E90FA' : '#2C303C')};
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  itemSelected: any;
}

const WalletInfoDetail: React.FunctionComponent<Props> = ({
  open,
  onClose,
  itemSelected,
}) => {
  const { id } = itemSelected || {};
  const [mode, setMode] = useState(0);
  const dispatch = useAppDispatch();
  const walletInfoDetail = useAppSelector(selectWalletInfoDetail);
  const [isMaskWalletInfo, setIsMaskWalletInfo] = useState(true);

  const isLoadingDetail = useMemo(() => {
    return walletInfoDetail.status === RequestStatus.Loading;
  }, [walletInfoDetail.status]);

  useEffect(() => {
    if (!open) {
      setIsMaskWalletInfo(true);
    }
  }, [open]);

  const items = getItems(walletInfoDetail.data, isMaskWalletInfo, id, () => {
    onClose?.();
  });

  useEffect(() => {
    if (id) {
      dispatch(walletInfoListActions.getDetailWalletInfoRequest(id));
    }
  }, [id, walletInfoDetail?.data?.id]);

  useEffect(() => {
    setMode(0);
  }, [open]);

  const Spinner = (
    <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
  );

  const content =
    isLoadingDetail || !walletInfoDetail.data ? (
      <div className={styles.loading}>{Spinner}</div>
    ) : (
      <>
        <TabBarContainer>
          {items.map(({ key, label }, index) => (
            <TabBar
              key={key}
              isActive={index === mode}
              onClick={() => setMode(index)}
            >
              {label}
            </TabBar>
          ))}
        </TabBarContainer>
        {items[mode].children}
      </>
    );
  return (
    <Drawer
      width="664px"
      placement="right"
      onClose={onClose}
      forceRender
      open={open}
      keyboard={false}
      title={
        <TitleContainer>
          <Row style={{ gap: 8, alignItems: 'center' }}>
            <div className="LogID" data-testid="log-ID">
              {`Log ID: ${id}`}
            </div>
            <SvgIcon
              name={isMaskWalletInfo ? 'black-eye' : 'black-eye-off'}
              onClick={() => setIsMaskWalletInfo(!isMaskWalletInfo)}
              width={16}
              height={isMaskWalletInfo ? 12 : 16}
              style={{ cursor: 'pointer', marginRight: '20px' }}
            />
          </Row>
          <div className="icon" onClick={onClose}>
            <SvgIcon name="close" width={16} height={16} />
          </div>
        </TitleContainer>
      }
      closable={false}
      bodyStyle={{ padding: '16px 32px 32px' }}
      // className={styles.drawerBilling}
    >
      {content}
    </Drawer>
  );
};

export default WalletInfoDetail;
