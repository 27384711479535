import { SvgIcon } from 'app/components/SvgIcon';
import Quorum from './Quorum';
import styles from './index.module.scss';
import { Col, Row } from 'antd';

interface Props {
  quorums: any[];
  approverNumber: number;
  isMaskedTransaction: boolean;
}

const AdvancedVaultPolicy = ({
  quorums,
  approverNumber,
  isMaskedTransaction,
}: Props) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.info}>
            <div className={styles.icon}>
              <SvgIcon name="approval-log" width={16} height={16} />
            </div>
            <div className={styles.title}>Vault policy</div>
            <div className={styles.approved} data-testid="total-approved">
              {approverNumber} of {quorums?.length}{' '}
              {quorums?.length > 1 ? 'quorums' : 'quorum'}
            </div>
          </div>
        </div>
        <Row className={styles.list} data-testid="user-list">
          {!!quorums &&
            quorums?.map((item, index) => {
              return (
                <Col span={11} key={item?.id} className={styles.list__item}>
                  <Quorum
                    members={item?.members}
                    quorumApprovals={item?.quorumApprovals}
                    index={index + 1}
                    isMaskedTransaction={isMaskedTransaction}
                    isRequired={item?.isRequired}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
};

export default AdvancedVaultPolicy;
