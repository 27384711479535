import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  customerProfileActions,
  selectCustomerFee,
  selectCustomerProfile,
} from 'store/slices/customerProfile';

import { selectUpdateStaking } from 'store/slices/customers';
import ProductSubscriptionWallet from '../components/ProductSubscriptionWallet';
import ProductSubscriptionStaking from '../components/ProductSubscriptionStaking';
import ProductSubscriptionRakkarConnect from '../components/ProductSubscriptionRakkarConnect';
import { RequestStatus } from 'interfaces/request';
import { walletTypes } from '../const';

export type UseGetProductSubscriptionSubTabs = {
  customerId: string;
  onUpdateFeeSuccess?: () => void;
};

export const useGetProductSubscriptionSubTabs = ({
  customerId,
  onUpdateFeeSuccess,
}: UseGetProductSubscriptionSubTabs) => {
  const dispatch = useAppDispatch();
  const customerProfile = useAppSelector(selectCustomerProfile);
  const feeInformation = useAppSelector(selectCustomerFee);
  const updateStakingRequest = useAppSelector(selectUpdateStaking);

  const [pendingReqId, setPendingReqId] = useState<string | undefined>(
    undefined,
  );

  const hotWallet = useMemo(() => {
    if (
      customerProfile?.status !== RequestStatus.Success ||
      !customerProfile?.data?.hotWalletObject ||
      feeInformation?.status !== RequestStatus.Success ||
      !feeInformation?.data?.hotWallet
    ) {
      return null;
    }

    return {
      walletName: customerProfile.data.hotWalletObject.name,
      isRequestPending: feeInformation.data.isHotWalletRequestPending,
      pendingRequestId: feeInformation.data.hotWalletPendingRequestId,
      feeInformationId: feeInformation.data.hotWallet.id,
      effectiveFrom: feeInformation.data.hotWallet.effectiveFrom,
      effectiveTo: feeInformation.data.hotWallet.effectiveTo,
      initialSetupFee: feeInformation.data.hotWallet.initialSetupFee,
      minimumMonthlyFee: feeInformation.data.hotWallet.minimumMonthlyFee,
      minimumAUCBalance: feeInformation.data.hotWallet.minimumAUCBalance,
      assetUnderCustody: feeInformation.data.hotWallet.assetUnderCustody,
      upcomingWallet: feeInformation.data.upcomingHotWallet,
    };
  }, [customerProfile, feeInformation]);

  const coldWallet = useMemo(() => {
    if (
      customerProfile?.status !== RequestStatus.Success ||
      !customerProfile?.data?.coldWalletObject ||
      feeInformation?.status !== RequestStatus.Success ||
      !feeInformation?.data?.coldWallet
    ) {
      return null;
    }

    return {
      walletName: customerProfile.data.coldWalletObject.name,
      isRequestPending: feeInformation.data.isColdWalletRequestPending,
      pendingRequestId: feeInformation.data.coldWalletPendingRequestId,
      feeInformationId: feeInformation.data.coldWallet.id,
      effectiveFrom: feeInformation.data.coldWallet.effectiveFrom,
      effectiveTo: feeInformation.data.coldWallet.effectiveTo,
      initialSetupFee: feeInformation.data.coldWallet.initialSetupFee,
      minimumMonthlyFee: feeInformation.data.coldWallet.minimumMonthlyFee,
      minimumAUCBalance: feeInformation.data.coldWallet.minimumAUCBalance,
      assetUnderCustody: feeInformation.data.coldWallet.assetUnderCustody,
      upcomingWallet: feeInformation.data.upcomingColdWallet,
    };
  }, [customerProfile, feeInformation]);

  const staking = useMemo(() => {
    if (
      customerProfile?.status !== RequestStatus.Success ||
      !feeInformation?.data?.staking ||
      feeInformation?.data?.staking.length === 0 ||
      feeInformation?.status !== RequestStatus.Success
    ) {
      return null;
    }
    return feeInformation.data.staking;
  }, [customerProfile, feeInformation]);

  const tabItems = useMemo(() => {
    const wallets: any[] = [];
    if (hotWallet) {
      wallets.push({
        label: walletTypes.hotWallet.label,
        key: '1',
        children: (
          <ProductSubscriptionWallet
            customerId={customerId}
            onUpdateFeeSuccess={onUpdateFeeSuccess}
            walletTypeLabel={walletTypes.hotWallet.label}
            walletType={'HOT_WALLET'}
            walletName={hotWallet.walletName}
            isRequestPending={hotWallet.isRequestPending}
            effectiveFrom={hotWallet.effectiveFrom}
            effectiveTo={hotWallet.effectiveTo}
            initialSetupFee={hotWallet.initialSetupFee}
            minimumMonthlyFee={hotWallet.minimumMonthlyFee}
            minimumAUCBalance={hotWallet.minimumAUCBalance}
            assetUnderCustody={hotWallet.assetUnderCustody}
            upcomingWallet={hotWallet.upcomingWallet}
          />
        ),
      });
    }

    if (coldWallet) {
      wallets.push({
        label: walletTypes.coldWallet.label,
        key: '2',
        children: (
          <ProductSubscriptionWallet
            customerId={customerId}
            onUpdateFeeSuccess={onUpdateFeeSuccess}
            walletTypeLabel={walletTypes.coldWallet.label}
            walletType={'COLD_WALLET'}
            walletName={coldWallet.walletName}
            isRequestPending={coldWallet.isRequestPending}
            effectiveFrom={coldWallet.effectiveFrom}
            effectiveTo={coldWallet.effectiveTo}
            initialSetupFee={coldWallet.initialSetupFee}
            minimumMonthlyFee={coldWallet.minimumMonthlyFee}
            minimumAUCBalance={coldWallet.minimumAUCBalance}
            assetUnderCustody={coldWallet.assetUnderCustody}
            upcomingWallet={coldWallet.upcomingWallet}
          />
        ),
      });

      if (staking) {
        wallets.push({
          label: walletTypes.staking.label,
          key: '3',
          children: (
            <ProductSubscriptionStaking
              staking={staking}
              customerId={customerId}
              pendingId={feeInformation.data?.updateStakingReqId}
            />
          ),
        });
      }
    }

    wallets.push({
      label: walletTypes.rakkarConnect.label,
      key: '4',
      children: <ProductSubscriptionRakkarConnect customerId={customerId} />,
    });

    return wallets;
  }, [customerId, onUpdateFeeSuccess, hotWallet, coldWallet, staking]);

  const handleShowPendingReqMsg = useCallback(
    (activeKey: string) => {
      if (
        activeKey === walletTypes.hotWallet.key &&
        hotWallet?.isRequestPending
      ) {
        setPendingReqId(hotWallet.pendingRequestId);
        return;
      }

      if (
        activeKey === walletTypes.coldWallet.key &&
        coldWallet?.isRequestPending
      ) {
        setPendingReqId(coldWallet.pendingRequestId);
        return;
      }

      if (
        activeKey === walletTypes.staking.key &&
        feeInformation.data?.updateStakingReqId
      ) {
        setPendingReqId(feeInformation.data?.updateStakingReqId);
        return;
      }

      setPendingReqId(undefined);
    },
    [
      hotWallet?.isRequestPending,
      hotWallet?.pendingRequestId,
      coldWallet?.isRequestPending,
      coldWallet?.pendingRequestId,
    ],
  );

  useEffect(() => {
    if (hotWallet) handleShowPendingReqMsg('1');
  }, [hotWallet, handleShowPendingReqMsg]);

  useEffect(() => {
    if (!(customerProfile?.status === RequestStatus.Success)) {
      dispatch(
        customerProfileActions.fetchCustomerByIdRequest({
          customerId,
        }),
      );
    }
    dispatch(
      customerProfileActions.fetchCustomerFeeByIdRequest({ customerId }),
    );
  }, [customerId, dispatch]);

  useEffect(() => {
    if (updateStakingRequest.status === RequestStatus.Success) {
      dispatch(
        customerProfileActions.fetchCustomerByIdRequest({
          customerId,
        }),
      );
      dispatch(
        customerProfileActions.fetchCustomerFeeByIdRequest({ customerId }),
      );
    }
  }, [updateStakingRequest.status, dispatch]);

  return {
    tabItems,
    handleShowPendingReqMsg,
    pendingReqId,
    customerProfile,
    feeInformation,
  };
};
