export enum SortWcAdapter {
  createdAt = 'ACTIVITY_DATE',
  company = 'COMPANY',
  username = 'USERNAME',
  requestCategory = 'CATEGORY',
  requestType = 'TYPE',
  requestAction = 'ACTION',
  status = 'STATUS',
}
export enum GroupByField {
  CATEGORY = 'CATEGORY',
  TYPE = 'TYPE',
}
export enum CustomerOnScreen {
  CUSTOMER_MANAGEMENT = 'CUSTOMER_MANAGEMENT',
  WCADAPTER = 'WCADAPTER',
  // TODO: wc check role
  ROLE = '',
}
