import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortWcWeb3Connect,
  GroupByField,
  CustomerOnScreen,
} from 'enum/wcWeb3Connect.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetWcWeb3ConnectRequestParamDTO,
  GetWcWeb3ConnectResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IWcWeb3ConnectDetail,
} from 'interfaces/wcWeb3Connect';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface WcWeb3ConnectState {
  params: GetWcWeb3ConnectRequestParamDTO;
  wcWeb3Connect: ResponseData<GetWcWeb3ConnectResponseDTO>;
  wcWeb3ConnectPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  wcWeb3ConnectDetail: ResponseData<IWcWeb3ConnectDetail>;
}
export function getDefaultWcWeb3ConnectRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortWcWeb3Connect.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: WcWeb3ConnectState = {
  params: getDefaultWcWeb3ConnectRequestParams(),
  wcWeb3Connect: {
    status: RequestStatus.Idle,
  },
  wcWeb3ConnectPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.WC_WEB3CONNECT,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  wcWeb3ConnectDetail: {
    status: RequestStatus.Idle,
  },
};
const getWcWeb3ConnectRequest: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<any>
> = (state: WcWeb3ConnectState) => {
  state.wcWeb3Connect.status = RequestStatus.Loading;
};
const getWcWeb3ConnectSuccess: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<GetWcWeb3ConnectResponseDTO>
> = (
  state: WcWeb3ConnectState,
  { payload }: PayloadAction<GetWcWeb3ConnectResponseDTO>,
) => {
  state.wcWeb3Connect.status = RequestStatus.Success;
  state.wcWeb3Connect.data = payload;
};
const getWcWeb3ConnectFailed: CaseReducer<WcWeb3ConnectState> = (
  state: WcWeb3ConnectState,
) => {
  state.wcWeb3Connect.status = RequestStatus.Failed;
};
const getDetailWcWeb3ConnectRequest: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<string>
> = (state: WcWeb3ConnectState) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Loading;
};
const getDetailWcWeb3ConnectSuccess: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<IWcWeb3ConnectDetail>
> = (
  state: WcWeb3ConnectState,
  { payload }: PayloadAction<IWcWeb3ConnectDetail>,
) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Success;
  state.wcWeb3ConnectDetail.data = payload;
};
const getDetailWcWeb3ConnectFailed: CaseReducer<WcWeb3ConnectState> = (
  state: WcWeb3ConnectState,
) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Failed;
};
const createWcWeb3ConnectRequest: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<IWcWeb3ConnectDetail>
> = (state: WcWeb3ConnectState) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Loading;
};
const createWcWeb3ConnectSuccess: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<IWcWeb3ConnectDetail>
> = (
  state: WcWeb3ConnectState,
  { payload }: PayloadAction<IWcWeb3ConnectDetail>,
) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Success;
  state.wcWeb3ConnectDetail.data = payload;
};
const createWcWeb3ConnectFailed: CaseReducer<WcWeb3ConnectState> = (
  state: WcWeb3ConnectState,
) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Failed;
};
const updateWcWeb3ConnectRequest: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<IWcWeb3ConnectDetail>
> = (state: WcWeb3ConnectState) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Loading;
};
const updateWcWeb3ConnectSuccess: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<IWcWeb3ConnectDetail>
> = (
  state: WcWeb3ConnectState,
  { payload }: PayloadAction<IWcWeb3ConnectDetail>,
) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Success;
  state.wcWeb3ConnectDetail.data = payload;
};
const updateWcWeb3ConnectFailed: CaseReducer<WcWeb3ConnectState> = (
  state: WcWeb3ConnectState,
) => {
  state.wcWeb3ConnectDetail.status = RequestStatus.Failed;
};
const appendWcWeb3Connect: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<any>
> = (state: WcWeb3ConnectState, { payload }: PayloadAction<any>) => {
  if (state.wcWeb3Connect?.data?.listWcWeb3Connect) {
    state.wcWeb3Connect.status = RequestStatus.Success;
    state.wcWeb3Connect.data.listWcWeb3Connect = [...payload];
  }
};
const setCanLoadMore = (
  state: WcWeb3ConnectState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetWcWeb3Connect: CaseReducer<WcWeb3ConnectState> = (
  state: WcWeb3ConnectState,
) => {
  state.wcWeb3Connect = {
    status: RequestStatus.Idle,
  };
};
const resetWcWeb3ConnectParams: CaseReducer<WcWeb3ConnectState> = (
  state: WcWeb3ConnectState,
) => {
  state.params = getDefaultWcWeb3ConnectRequestParams();
};
const setWcWeb3ConnectFilterParams = (
  state: WcWeb3ConnectState,
  { payload }: PayloadAction<GetWcWeb3ConnectRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: WcWeb3ConnectState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<any>
> = (state: WcWeb3ConnectState, { payload }: PayloadAction<any>) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<WcWeb3ConnectState> = (
  state: WcWeb3ConnectState,
) => {
  state.action.status = RequestStatus.Failed;
};
const getWcWeb3ConnectPaginationConfigRequest: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<any>
> = (state: WcWeb3ConnectState) => {
  state.wcWeb3ConnectPaginationConfig.status = RequestStatus.Loading;
};
const getWcWeb3ConnectPaginationConfigSuccess: CaseReducer<
  WcWeb3ConnectState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: WcWeb3ConnectState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.wcWeb3ConnectPaginationConfig.status = RequestStatus.Success;
  state.wcWeb3ConnectPaginationConfig.data = payload;
};
const getWcWeb3ConnectPaginationConfigFailed: CaseReducer<
  WcWeb3ConnectState
> = (state: WcWeb3ConnectState) => {
  state.wcWeb3ConnectPaginationConfig.status = RequestStatus.Failed;
};
const wcWeb3ConnectSlice = createSlice({
  name: 'wcWeb3Connect',
  initialState,
  reducers: {
    getWcWeb3ConnectRequest,
    getWcWeb3ConnectSuccess,
    getWcWeb3ConnectFailed,
    getDetailWcWeb3ConnectRequest,
    getDetailWcWeb3ConnectSuccess,
    getDetailWcWeb3ConnectFailed,
    createWcWeb3ConnectRequest,
    createWcWeb3ConnectSuccess,
    createWcWeb3ConnectFailed,
    updateWcWeb3ConnectRequest,
    updateWcWeb3ConnectSuccess,
    updateWcWeb3ConnectFailed,
    appendWcWeb3Connect,
    setCanLoadMore,
    resetWcWeb3Connect,
    setWcWeb3ConnectParams<K extends keyof GetWcWeb3ConnectRequestParamDTO>(
      state: WcWeb3ConnectState,
      action: PayloadAction<{
        key: K;
        value: GetWcWeb3ConnectRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setWcWeb3ConnectFilterParams,
    resetWcWeb3ConnectParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getWcWeb3ConnectPaginationConfigRequest,
    getWcWeb3ConnectPaginationConfigSuccess,
    getWcWeb3ConnectPaginationConfigFailed,
  },
});
// Actions
export const wcWeb3ConnectActions = wcWeb3ConnectSlice.actions;
// Reducer
export const wcWeb3ConnectReducer = wcWeb3ConnectSlice.reducer;
// Selectors
export const selectWcWeb3Connect = (state: AppState) =>
  state.wcWeb3Connect?.wcWeb3Connect;
export const selectParamWcWeb3Connect = (state: AppState) =>
  state.wcWeb3Connect?.params;
export const selectCanLoadMoreWcWeb3Connect = (state: AppState) =>
  state.wcWeb3Connect?.canLoadMore;
export const selectWcWeb3ConnectDetail = (state: AppState) =>
  state.wcWeb3Connect?.wcWeb3ConnectDetail;
export const selectWcWeb3ConnectPagination = (state: AppState) =>
  state.wcWeb3Connect?.wcWeb3ConnectPaginationConfig;
