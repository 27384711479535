import { Col } from 'antd';
import styles from './index.module.scss';
import { dots } from '../TextInfor';

interface TextInfoProps {
  label: any;
  span?: number;
  dataTestId?: string;
  newData: string | undefined;
  oldData: string | undefined;
  isMask?: boolean;
  isMaskable?: boolean;
}

const EditedTextInform = ({
  span = 12,
  label,
  dataTestId,
  newData = '',
  oldData = '',
  isMask = true,
  isMaskable = false,
}: TextInfoProps) => {
  const unchangedField = (data: string) => {
    return (
      <span data-testid="unchanged-field" className={styles.content__text}>
        {data}
      </span>
    );
  };

  const changedField = (newField: string, oldField: string) => {
    return (
      <Col>
        {oldField && (
          <div data-testid="old-field" className={styles.content__old__field}>
            {oldField}
          </div>
        )}
        {newField && (
          <div data-testid="new-field" className={styles.content__new__field}>
            {newField}
          </div>
        )}
      </Col>
    );
  };

  return (
    <Col span={span}>
      <div className={styles.content__item}>
        <p className={styles.content__label} data-testid="label">
          {label}
        </p>
        <p className={styles.content__text} data-testid={dataTestId}>
          {isMask && isMaskable
            ? dots()
            : newData === oldData
            ? unchangedField(newData)
            : changedField(newData, oldData)}
        </p>
      </div>
    </Col>
  );
};

export default EditedTextInform;
