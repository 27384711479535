import axios from 'axios';
import { PREFIX_API } from 'constants/index';
import { store } from 'store';
import Instance from '../app.v2/config/instance';
export const checkLocation = () => {
  const url = process.env.REACT_APP_BASE_URL || '';
  return axios.get(url);
};

export function getAuthInfo(token: string): Promise<any> {
  return Instance.post(`/${PREFIX_API.AUTH}/account/exchange-token`, {
    token: token,
  });
}

export function getProfileInfo(): Promise<any> {
  return Instance.get(`/${PREFIX_API.AUTH}/account/me`);
}

export function getRoles(): Promise<any> {
  return Instance.get(`${PREFIX_API.AUTH}/authorization/roles?target=current`);
}
