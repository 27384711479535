import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import styles from './index.module.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  loading?: boolean;
  handleConfirm?: () => void;
  featureName: string;
};

const PopupAccessControlRequest = ({
  isOpen,
  setIsOpen,
  loading = false,
  handleConfirm,
  featureName,
  ...props
}: Props) => {
  return (
    <BasicModal
      title={'You confirm to submit edit ' + featureName + ' access request?'}
      width={404}
      centered={true}
      className={styles.approve__popup}
      destroyOnClose={true}
      visible={isOpen}
      children={
        <>
          {'After the request has been approved, ' +
            featureName +
            ' access will be updated'}
        </>
      }
      maskClosable={true}
      onCancel={() => setIsOpen(false)}
      footer={[
        <div className={styles.button} key="popup-confirm-access-control">
          <BasicButton
            title="Back"
            type="outline"
            onClick={() => setIsOpen(false)}
            dataTestId="back-btn"
          />
          <BasicButton
            title="Confirm"
            type="filled"
            className={styles.confirm}
            onClick={handleConfirm}
            loading={loading}
            dataTestId="confirm-btn"
          />
        </div>,
      ]}
      dataTestId="popup-confirm"
    />
  );
};

export default PopupAccessControlRequest;
