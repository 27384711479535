import { Button, Form, Modal, Select, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import { resendFbWebhookTxns } from './helper';
import { AppState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { commonActions } from 'store/slices/common';

export default function ModalResendFBTransactionIds() {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [result, setResult] = useState({});
  const [form] = Form.useForm();
  const workspacesStore = useAppSelector(
    (state: AppState) => state.common.workspaces.data,
  );

  useEffect(() => {
    dispatch(commonActions.getWorkSpacesRequest());
  }, [dispatch]);

  const workspaces = [
    ...(workspacesStore?.cold || []),
    ...(workspacesStore?.hot || []),
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    console.debug(
      `💀 ${new Date().toISOString()} ~ file: index.tsx:21 ~ handleOk ~ form.getFieldsValue():`,
      await form.getFieldsValue(),
    );
    message.loading('Processing');
    const result_ = await resendFbWebhookTxns(await form.getFieldsValue());
    message.success('Success');
    setResult(result_);
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Resend webhook
      </Button>
      <Modal
        title="Resend webhook"
        open={isModalOpen}
        onOk={handleOk}
        okText="Confirm"
        onCancel={handleCancel}
        width={'80%'}
      >
        <p>
          You can enter a Fireblocks ID or multiple Fireblocks IDs with comma
          separation to retrieve webhooks from Fireblocks
        </p>
        <Form form={form} initialValues={{ fbTxns: [] }}>
          <Form.List name="fbTxns">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'tenantId']}
                      rules={[{ required: true, message: 'tenantId' }]}
                      style={{ width: '300px' }}
                    >
                      <Select
                        placeholder="Select a option and change input text above"
                        allowClear
                        loading={workspaces.length < 1}
                      >
                        {workspaces.map(workspace => (
                          <Select.Option
                            key={workspace.externalId}
                            value={workspace.externalId}
                          >
                            {workspace.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'fbTxnIds']}
                      rules={[{ required: true, message: 'fbTxnIds' }]}
                      style={{ width: '300px' }}
                    >
                      <Select
                        mode="tags"
                        style={{ width: '300px' }}
                        placeholder="Fireblocks IDs"
                      />
                    </Form.Item>
                    <Button onClick={() => remove(name)}>-</Button>
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={'+'}>
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
        <pre>{JSON.stringify(result, undefined, 2)}</pre>
      </Modal>
    </>
  );
}
