import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortCurrency,
  GroupByField,
  CustomerOnScreen,
} from 'enum/currency.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetCurrencyRequestParamDTO,
  GetCurrencyResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ICurrencyDetail,
} from 'interfaces/currency';
import { AppState } from '../..';
interface CurrencyState {
  params: GetCurrencyRequestParamDTO;
  currency: ResponseData<GetCurrencyResponseDTO>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  currencyDetail: ResponseData<ICurrencyDetail>;
}
export function getDefaultCurrencyRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortCurrency.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: CurrencyState = {
  params: getDefaultCurrencyRequestParams(),
  currency: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.CURRENCY,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  currencyDetail: {
    status: RequestStatus.Idle,
  },
};
const getCurrencyRequest: CaseReducer<CurrencyState, PayloadAction<any>> = (
  state: CurrencyState,
) => {
  state.currency.status = RequestStatus.Loading;
};
const getCurrencySuccess: CaseReducer<
  CurrencyState,
  PayloadAction<GetCurrencyResponseDTO>
> = (
  state: CurrencyState,
  { payload }: PayloadAction<GetCurrencyResponseDTO>,
) => {
  state.currency.status = RequestStatus.Success;
  state.currency.data = payload;
};
const getCurrencyFailed: CaseReducer<CurrencyState> = (
  state: CurrencyState,
) => {
  state.currency.status = RequestStatus.Failed;
};
const getDetailCurrencyRequest: CaseReducer<
  CurrencyState,
  PayloadAction<string>
> = (state: CurrencyState) => {
  state.currencyDetail.status = RequestStatus.Loading;
};
const getDetailCurrencySuccess: CaseReducer<
  CurrencyState,
  PayloadAction<ICurrencyDetail>
> = (state: CurrencyState, { payload }: PayloadAction<ICurrencyDetail>) => {
  state.currencyDetail.status = RequestStatus.Success;
  state.currencyDetail.data = payload;
};
const getDetailCurrencyFailed: CaseReducer<CurrencyState> = (
  state: CurrencyState,
) => {
  state.currencyDetail.status = RequestStatus.Failed;
};
const createCurrencyRequest: CaseReducer<
  CurrencyState,
  PayloadAction<ICurrencyDetail>
> = (state: CurrencyState) => {
  state.currencyDetail.status = RequestStatus.Loading;
};
const createCurrencySuccess: CaseReducer<
  CurrencyState,
  PayloadAction<ICurrencyDetail>
> = (state: CurrencyState, { payload }: PayloadAction<ICurrencyDetail>) => {
  state.currencyDetail.status = RequestStatus.Success;
  state.currencyDetail.data = payload;
};
const createCurrencyFailed: CaseReducer<CurrencyState> = (
  state: CurrencyState,
) => {
  state.currencyDetail.status = RequestStatus.Failed;
};
const updateCurrencyRequest: CaseReducer<
  CurrencyState,
  PayloadAction<ICurrencyDetail>
> = (state: CurrencyState) => {
  state.currencyDetail.status = RequestStatus.Loading;
};
const updateCurrencySuccess: CaseReducer<
  CurrencyState,
  PayloadAction<ICurrencyDetail>
> = (state: CurrencyState, { payload }: PayloadAction<ICurrencyDetail>) => {
  state.currencyDetail.status = RequestStatus.Success;
  state.currencyDetail.data = payload;
};
const updateCurrencyFailed: CaseReducer<CurrencyState> = (
  state: CurrencyState,
) => {
  state.currencyDetail.status = RequestStatus.Failed;
};
const appendCurrency: CaseReducer<CurrencyState, PayloadAction<any>> = (
  state: CurrencyState,
  { payload }: PayloadAction<any>,
) => {
  if (state.currency?.data?.listCurrency) {
    state.currency.status = RequestStatus.Success;
    state.currency.data.listCurrency = [...payload];
  }
};
const setCanLoadMore = (
  state: CurrencyState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetCurrency: CaseReducer<CurrencyState> = (state: CurrencyState) => {
  state.currency = {
    status: RequestStatus.Idle,
  };
};
const resetCurrencyParams: CaseReducer<CurrencyState> = (
  state: CurrencyState,
) => {
  state.params = getDefaultCurrencyRequestParams();
};
const setCurrencyFilterParams = (
  state: CurrencyState,
  { payload }: PayloadAction<GetCurrencyRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  CurrencyState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: CurrencyState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<CurrencyState, PayloadAction<any>> = (
  state: CurrencyState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<CurrencyState> = (
  state: CurrencyState,
) => {
  state.action.status = RequestStatus.Failed;
};
const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    getCurrencyRequest,
    getCurrencySuccess,
    getCurrencyFailed,
    getDetailCurrencyRequest,
    getDetailCurrencySuccess,
    getDetailCurrencyFailed,
    createCurrencyRequest,
    createCurrencySuccess,
    createCurrencyFailed,
    updateCurrencyRequest,
    updateCurrencySuccess,
    updateCurrencyFailed,
    appendCurrency,
    setCanLoadMore,
    resetCurrency,
    setCurrencyParams<K extends keyof GetCurrencyRequestParamDTO>(
      state: CurrencyState,
      action: PayloadAction<{
        key: K;
        value: GetCurrencyRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setCurrencyFilterParams,
    resetCurrencyParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
  },
});
// Actions
export const currencyActions = currencySlice.actions;
// Reducer
export const currencyReducer = currencySlice.reducer;
// Selectors
export const selectCurrency = (state: AppState) => state.currency?.currency;
export const selectParamCurrency = (state: AppState) => state.currency?.params;
export const selectCanLoadMoreCurrency = (state: AppState) =>
  state.currency?.canLoadMore;
export const selectCurrencyDetail = (state: AppState) =>
  state.currency?.currencyDetail;
