import { Row, Tooltip } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import styles from './index.module.scss';
import { dots } from 'app/components/common/TextInfor';
import { dotSize } from 'constants/index';

interface Props {
  quorum: any;
  isMask?: boolean;
}

const ItemGroup = ({ quorum, isMask = false }: Props) => {
  const { groupName, members } = quorum;

  return (
    <div className={styles.group}>
      <div className={styles.group__icon}>
        <SvgIcon name="group" width={18} height={18} />
      </div>
      <div>
        <Row>
          <span className={styles.group__name}>{groupName}</span>
        </Row>
        <Row>
          <Tooltip
            placement="bottom"
            title={
              members && (
                <div>
                  {members?.map(item => {
                    return (
                      <div key={item?.userId}>
                        {isMask ? dots(dotSize.medium) : item?.name}
                      </div>
                    );
                  })}
                </div>
              )
            }
          >
            <div className={styles.group__user}>
              {members?.length} {members?.length > 1 ? 'users' : 'user'}
            </div>
          </Tooltip>
        </Row>
      </div>
    </div>
  );
};

export default ItemGroup;
