import { getWorkspaceTypeName } from 'utils/workspace.utils';

export const walletTypes = {
  hotWallet: { key: '1', label: getWorkspaceTypeName('hot') },
  coldWallet: { key: '2', label: getWorkspaceTypeName('cold') },
  staking: { key: '3', label: getWorkspaceTypeName('staking') },
  rakkarConnect: { key: '4', label: 'Rakkar Connect' },
};

export const assetUnderCustodyColumns = [
  {
    title: 'From',
    dataIndex: 'from',
  },
  {
    title: 'To',
    dataIndex: 'to',
  },
  {
    title: 'Annual basis points',
    dataIndex: 'point',
  },
];
