import { Button, Col, Divider, Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';

import clsx from 'clsx';

import { USER_ROLES } from 'enum/common';
import { IStakingList } from 'interfaces/businessInformation';

import { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectRoles } from 'store/slices/auth';

import { checkRoles } from 'utils/common';

import { handleKeyDown } from 'utils/event.utils';

import ModalEditStaking from './EditStaking';
import { CustomSpace } from './CustomSpace';
import styles from '../index.module.scss';

type ProductSubscriptionStakingProps = {
  staking: IStakingList;
  customerId: string;
  pendingId?: string;
};
export const ProductSubscriptionStaking = (
  props: ProductSubscriptionStakingProps,
) => {
  const [isOpenEditStaking, setIsOpenEditStaking] = useState<boolean>(false);
  const role = useAppSelector(selectRoles);
  const isOperation = checkRoles(USER_ROLES.OPERATION, role);

  const isDisable = props.pendingId !== undefined;

  return (
    <>
      <Divider orientationMargin="0" orientation="left">
        <span className={styles.info__title}>Assets</span>
      </Divider>
      <div className={styles.buttonWrapper}>
        <div className={styles.innerButtonWrapper}>
          <Button
            hidden={!isOperation}
            className={clsx(styles.button, {
              [styles.button__disabled]: isDisable,
            })}
            icon={
              <SvgIcon
                style={{
                  marginRight: 10,
                  marginBottom: 2,
                }}
                width={15}
                height={15}
                name="edit"
              />
            }
            onClick={() => {
              setIsOpenEditStaking(true);
            }}
            onKeyDown={handleKeyDown}
            htmlType="button"
            disabled={isDisable}
            style={{
              cursor: isDisable ? 'default' : 'pointer',
            }}
          >
            Edit staking
          </Button>
        </div>
      </div>
      <Row>
        <Col span={24}>
          <CustomSpace>
            <span className={styles.text}>
              {props.staking.length > 0 &&
                props.staking.map(
                  (item, index) =>
                    item.isStaking && (
                      <>
                        {item.name} ({item.symbol})
                        {index !== props.staking.length - 1 && ', '}
                      </>
                    ),
                )}
            </span>
          </CustomSpace>
        </Col>
      </Row>
      <ModalEditStaking
        isOpen={isOpenEditStaking}
        setIsOpen={setIsOpenEditStaking}
        staking={props.staking}
        id={props.customerId}
      />
    </>
  );
};

export default ProductSubscriptionStaking;
