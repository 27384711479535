import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { renderIcon } from 'app/pages/CustomerManagement';
import { commonAttributesColumnTable } from 'constants/index';
import { SortListUser, StatusUser } from 'enum/userList';
import { Params, ParamsUserList, RequestStatus } from 'interfaces/request';
import { camelCase, capitalize, lowerCase } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  customerProfileActions,
  selectCanLoadMoreUserListing,
  selectParamsUserList,
  selectUserListing,
} from 'store/slices/customerProfile';
import { mapRequest } from 'utils/helpers';
import { SvgIcon } from '../common/SvgIcon';
import { dots } from '../common/TextInfor';
import HeaderTableUserList from './HeaderTableUserList';
import ListTotal from './ListTotal';
import UserDetail from './UserDetail';
import styles from './index.module.scss';

type Props = {
  id: string;
  isMaskedProfile?: boolean;
};

const UserListing = ({ id, isMaskedProfile }: Props) => {
  const dispatch = useDispatch();
  const userList = useAppSelector(selectUserListing);
  const params = useAppSelector(selectParamsUserList);
  const [loading, setLoading] = useState(false);
  const paramsUserList = useAppSelector(selectParamsUserList);
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const canLoadMore = useAppSelector(selectCanLoadMoreUserListing);
  const refCanLoadMore = useRef(canLoadMore);

  const [open, setOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState('');
  const height = window.innerHeight - 415; //415: height header + footer

  const dataSource = useMemo(() => {
    return userList.data?.users || [];
  }, [userList.data?.users]);

  useEffect(() => {
    refCanLoadMore.current = canLoadMore;
  }, [canLoadMore]);

  useEffect(() => {
    if (paramsUserList?.keyword && !showEmptyScreen) {
      setShowEmptyScreen(true);
    }
  }, [paramsUserList.keyword]);

  const setValue = (key: keyof ParamsUserList) => {
    return (value: ParamsUserList[keyof ParamsUserList]) => {
      dispatch(
        customerProfileActions.setUserListParam({
          key,
          value,
        }),
      );
    };
  };

  const setOffset = setValue(Params.Offset);
  const setSort = setValue(Params.Sort);
  const setSortBy = setValue(Params.SortBy);
  const setPage = setValue(Params.Page);

  useEffect(() => {
    setLoading(userList.status === RequestStatus.Loading);
  }, [userList?.status]);

  useEffect(() => {
    if (id) {
      dispatch(
        customerProfileActions.fetchUserListingRequest({
          customerId: id,
          params: paramsUserList,
        }),
      );
    }
  }, [id, dispatch, paramsUserList]);

  useEffect(() => {
    //add class to distinguish the tables to handle scroll
    const elmTable = document.querySelector('.user-listing');
    const elmBodyTable = elmTable && elmTable.querySelector('.ant-table-body');
    elmBodyTable && elmBodyTable.classList.add('table-body-user-listing');
  }, []);

  const loadMore = useCallback(
    event => {
      const maxScroll = event.target.scrollHeight - event.target.clientHeight;
      const currentScroll = (event.target.scrollTop + 2).toFixed();
      let offset = paramsUserList?.offset || 0;
      if (
        refCanLoadMore.current &&
        Number(currentScroll) > maxScroll &&
        userList.status === RequestStatus.Success
      ) {
        setOffset(offset + 10);
      }
    },
    [userList],
  );

  useEffect(() => {
    let tableContent: any = document.querySelector('.table-body-user-listing');
    if (!tableContent) return;
    tableContent.addEventListener('scroll', loadMore);
    return () => tableContent.removeEventListener('scroll', loadMore);
  }, [loadMore]);

  const formatStringFromEnum = (text: string) => {
    return capitalize(lowerCase(camelCase(text)));
  };

  const mapStatus = (status: string) => {
    return status === StatusUser.WAITING_ON_BOARDING ||
      status === StatusUser.PENDING_VERIFY
      ? 'Await onboarding'
      : 'Failed onboarding';
  };

  const columns: ColumnsType<any> = [
    {
      ...commonAttributesColumnTable,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Name', 'name');
      },
      render: (name: string) => (
        <div className={styles.name}>{isMaskedProfile ? dots() : name}</div>
      ),
    },
    {
      dataIndex: 'role',
      key: 'role',
      sorter: true,
      showSorterTooltip: false,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Role', 'role');
      },
      render: role =>
        role && (
          <div className={styles[lowerCase(role)]}>
            {formatStringFromEnum(role)}
          </div>
        ),
    },
    {
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      showSorterTooltip: false,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Email', 'email');
      },
      render: (email: string) => (
        <div className={styles.email}>{isMaskedProfile ? dots() : email}</div>
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      showSorterTooltip: false,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Status', 'status');
      },
      render: (status: string) =>
        status && (
          <div className={styles[camelCase(status)]}>
            {status !== StatusUser.WAITING_ON_BOARDING &&
            status !== StatusUser.FAILED_ONBOARDING &&
            status !== StatusUser.PENDING_VERIFY
              ? capitalize(status)
              : mapStatus(status)}
          </div>
        ),
    },
  ];

  let locale = {
    emptyText: showEmptyScreen ? (
      <div className={styles.empty}>
        <div className={styles.icon}>
          <SvgIcon name="error-search" width={80} height={80} />
        </div>
        <div className={styles.title}>No matching search results</div>
        <div className={styles.content}>Try another keyword</div>
      </div>
    ) : (
      <></>
    ),
  };

  const onTableChange = (sorter: any) => {
    const sort = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const sortBy = SortListUser[sorter.field];

    setSort(sort);
    setSortBy(sortBy);
    setPage(1);
    setOffset(0);

    dispatch(
      customerProfileActions.fetchUserListingRequest({
        customerId: id,
        params: mapRequest({
          ...params,
          sort,
          sortBy,
          page: 1,
        }),
      }),
    );
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const handleViewDetail = (row: any) => {
    // handle selected row
    setSelectedRowId(row.userId);

    showDrawer();
  };

  const onClose = () => {
    setOpen(false);
    setSelectedRowId('');
  };

  return (
    <div className={styles.container}>
      <ListTotal />
      <HeaderTableUserList customerId={id} />
      <div className={`${styles.table} `}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
          scroll={{ y: height }}
          locale={locale}
          onRow={(record: any) => {
            return {
              onClick: event => {
                handleViewDetail(record);
              }, // click row
            };
          }}
          rowClassName={(record, index) =>
            record.userId === selectedRowId ? `${styles.rowActive}` : ''
          }
          className={`${
            !dataSource.length ? styles['remove-scroll'] : ''
          } user-listing`}
          onChange={onTableChange}
        />
      </div>
      {!!selectedRowId && (
        <UserDetail id={selectedRowId} open={open} onClose={onClose} />
      )}
    </div>
  );
};

export default UserListing;
