import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  getWalletInfoListRequest,
  getDetailWalletInfo,
  createWalletInfoRequest,
  updateWalletInfoRequest,
} from 'services/walletInfoList.service';
import { walletInfoListActions } from 'store/slices/walletInfoList/walletInfoList.slice';
import { handleShowMsgErr } from 'utils/common';

import {
  GetWalletInfoListRequestParamDTO,
  ICreateWalletInfo,
} from 'interfaces/walletInfoList.interface';

const handleGetWalletInfoListRequest = function* ({
  payload,
}: PayloadAction<GetWalletInfoListRequestParamDTO>) {
  try {
    const res = yield call(getWalletInfoListRequest, payload);

    const params = yield select(state => state.walletInfoList.params) as any;

    const listWalletInfo = yield select(
      state => state.walletInfoList?.walletInfoList?.data?.listWalletInfo,
    ) as any;

    if (res) {
      yield put(
        walletInfoListActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );

      if (params.offset > 0) {
        const newList: any = [...(listWalletInfo || []), ...res.list];
        yield put(walletInfoListActions.appendWalletInfoList(newList));
      } else {
        yield put(
          walletInfoListActions.getWalletInfoListSuccess({
            listWalletInfo: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(walletInfoListActions.getWalletInfoListFailed());
  }
};

const handleGetDetailWalletInfoRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailWalletInfo, payload);
    if (res) {
      yield put(walletInfoListActions.getDetailWalletInfoSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(walletInfoListActions.getDetailWalletInfoFailed());
  }
};

const handleCreateWalletInfoRequest = function* ({
  payload,
}: PayloadAction<ICreateWalletInfo>) {
  try {
    const res = yield call(createWalletInfoRequest, payload);
    if (res) {
      yield put(walletInfoListActions.createWalletInfoSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(walletInfoListActions.createWalletInfoFailed());
  }
};

const handleUpdateWalletInfoRequest = function* ({
  payload,
}: PayloadAction<ICreateWalletInfo>) {
  try {
    const res = yield call(updateWalletInfoRequest, payload);
    if (res) {
      yield put(walletInfoListActions.updateWalletInfoSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(walletInfoListActions.updateWalletInfoFailed());
  }
};

const walletInfoListSaga = function* () {
  yield takeLatest(
    walletInfoListActions.getWalletInfoListRequest.type,
    handleGetWalletInfoListRequest,
  );

  yield takeLatest(
    walletInfoListActions.getDetailWalletInfoRequest.type,
    handleGetDetailWalletInfoRequest,
  );

  yield takeLatest(
    walletInfoListActions.createWalletInfoRequest.type,
    handleCreateWalletInfoRequest,
  );

  yield takeLatest(
    walletInfoListActions.updateWalletInfoRequest.type,
    handleUpdateWalletInfoRequest,
  );
};

export default walletInfoListSaga;
