import { Sort, SortDirection } from 'enum/common';

export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success',
  Failed = 'failed',
}

export enum Params {
  KeyWord = 'keyword',
  // v2
  Search = 'searchText',
  Limit = 'limit',
  Page = 'page',
  Sort = 'sort',
  SortBy = 'sortBy',
  TokenSymbol = 'tokenSymbol',
  isHideList = 'isHideList',
  Offset = 'offset',
  Status = 'status',
  isHistory = 'isHistory',
  isAllRequest = 'isAllRequest',
  SearchOnScreen = 'searchOnScreen',
  TimeZone = 'timeZone',
  Where = 'where',
  Order = 'order',
}

export interface ParamsDefault {
  page: number;
  limit: number;
  keyword?: string;
  searchText?: string;
  sort?: SortDirection;
  sortBy?: Sort;
}

export interface ParamsCustomers extends ParamsDefault {
  businessType?: string;
  country?: string;
  workspace?: string;
  product?: string;
  customerStatus?: 'ACTIVE' | 'INACTIVE';
  hasInvoicesReviewed?: boolean;
}

export interface ParamsUserList extends ParamsDefault {
  offset?: number;
}

export interface ApproveRequest {
  quorumRecordId: string;
}

export interface Creator {
  userId: string;
  email: string;
  name: string;
  enabled: boolean;
  role: string;
  roleDisplayName: string;
}
