import React, { ReactNode } from 'react';
import { SvgIcon } from '../SvgIcon';
import styles from './index.module.scss';

interface Props {
  title?: string;
  description?: string | ReactNode;
}

const NoResultWhenSearch = ({ title, description }: Props) => {
  return (
    <div className={styles.empty} data-testid="empty-screen">
      <div className={styles.content}>
        <div className={styles.icon}>
          <SvgIcon name={'empty-data'} width={80} height={80} />
        </div>
        <div className={styles.text}>{title}</div>
        <div className={styles.text_appear}>{description}</div>
      </div>
    </div>
  );
};

export default NoResultWhenSearch;
