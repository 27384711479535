import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../..';
import { ResponseData } from 'interfaces/response';
import { RequestStatus } from 'interfaces/request';
import {
  AddNewCommentRequest,
  DeleteCommentRequest,
  GetCommnentsRequest,
  ItemComment,
  UpdateCommentRequest,
} from 'interfaces/comment';
interface CommentState {
  comments: ResponseData<ItemComment[]>;
  addComment: ResponseData<any>;
  updateComment: ResponseData<any>;
  deleteComment: ResponseData<any>;
}

const initialState: CommentState = {
  comments: {
    status: RequestStatus.Idle,
    // data: comments,
  },
  addComment: {
    status: RequestStatus.Idle,
  },
  updateComment: {
    status: RequestStatus.Idle,
  },
  deleteComment: {
    status: RequestStatus.Idle,
  },
};

const getCommentsRequest: CaseReducer<
  CommentState,
  PayloadAction<GetCommnentsRequest>
> = (state: CommentState) => {
  state.comments.status = RequestStatus.Loading;
};

const getCommentsSuccess: CaseReducer<CommentState, PayloadAction<any>> = (
  state: CommentState,
  { payload }: PayloadAction<any>,
) => {
  state.comments.status = RequestStatus.Success;
  state.comments.data = payload;
};

const getCommentsFailed: CaseReducer<CommentState> = (state: CommentState) => {
  state.comments.status = RequestStatus.Failed;
};

const addCommentRequest: CaseReducer<
  CommentState,
  PayloadAction<AddNewCommentRequest>
> = (state: CommentState) => {
  state.addComment.status = RequestStatus.Loading;
};

const addCommentSuccess: CaseReducer<CommentState, PayloadAction<any>> = (
  state: CommentState,
  { payload }: PayloadAction<any>,
) => {
  state.addComment.status = RequestStatus.Success;
  state.comments.data = payload;
};

const addCommentFailed: CaseReducer<CommentState> = (state: CommentState) => {
  state.addComment.status = RequestStatus.Failed;
};

const updateCommentRequest: CaseReducer<
  CommentState,
  PayloadAction<UpdateCommentRequest>
> = (state: CommentState) => {
  state.updateComment.status = RequestStatus.Loading;
};

const updateCommentSuccess: CaseReducer<CommentState, PayloadAction<any>> = (
  state: CommentState,
  { payload }: PayloadAction<any>,
) => {
  state.updateComment.status = RequestStatus.Success;
  state.comments.data = payload;
};

const updateCommentFailed: CaseReducer<CommentState> = (
  state: CommentState,
) => {
  state.updateComment.status = RequestStatus.Failed;
};

const deleteCommentRequest: CaseReducer<
  CommentState,
  PayloadAction<DeleteCommentRequest>
> = (state: CommentState) => {
  state.deleteComment.status = RequestStatus.Loading;
};

const deleteCommentSuccess: CaseReducer<CommentState, PayloadAction<any>> = (
  state: CommentState,
  { payload }: PayloadAction<any>,
) => {
  state.deleteComment.status = RequestStatus.Success;
  state.comments.data = payload;
};

const deleteCommentFailed: CaseReducer<CommentState> = (
  state: CommentState,
) => {
  state.deleteComment.status = RequestStatus.Failed;
};

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    getCommentsRequest,
    getCommentsSuccess,
    getCommentsFailed,

    addCommentRequest,
    addCommentSuccess,
    addCommentFailed,

    updateCommentRequest,
    updateCommentSuccess,
    updateCommentFailed,

    deleteCommentRequest,
    deleteCommentSuccess,
    deleteCommentFailed,
  },
});

// Actions
export const commentActions = commentSlice.actions;

// Reducer
export const commentReducer = commentSlice.reducer;

// Selectors
export const selectComments = (state: AppState) => state.comment.comments.data;
export const selectAddComment = (state: AppState) => state.comment.addComment;
export const selectDeleteComment = (state: AppState) =>
  state.comment.deleteComment;
export const selectUpdateComment = (state: AppState) =>
  state.comment.updateComment;
