import { Divider, Drawer, Row, Tooltip } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import clsx from 'clsx';
import { FORMAT_DATE, STATUS_REQUEST } from 'constants/index';
import { TransactionStatus } from 'enum/common';
import { capitalize, debounce, upperCase } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import TabGeneral from 'app.v2/components/orgarnism/CustomerProfile/Tabs/General';
import TextInfor from 'app/components/common/TextInfor';
import { useSelector } from 'react-redux';
import { selectPendingRequestDetail } from 'store/slices/request';
import { formatAssetDetails, formatCurrencyTypeB } from 'utils/helpers';
import { infoTxn } from './index.helper';
import styles from './index.module.scss';

type Props = { drawerWidth?: number; transactionData?: any; isMask: boolean };

const TransactionInformation = ({
  drawerWidth = 665,
  transactionData,
  isMask,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);
  const [isOpenSourceTooltip, setIsOpenSourceTooltip] =
    useState<boolean>(false);
  const [isOpenDestinationTooltip, setIsOpenDestinationTooltip] =
    useState<boolean>(false);
  const requestDetails = useSelector(selectPendingRequestDetail);
  const transactionDetail = requestDetails?.data ?? {};

  const [isMaskUnfreezeTransactionDetail, setIsMaskUnfreezeTransactionDetail] =
    useState(true);

  useEffect(() => {
    setIsMaskUnfreezeTransactionDetail(isMask ? isMask : !isMask);
  }, [isMask]);

  const substr = (text: string) => {
    if (text?.length > 10) {
      return `${text.substring(0, 6)} ... ${text.substring(
        text.length,
        text.length - 6,
      )}`;
    } else return text || '';
  };

  const onCopy = (e: string) => {
    navigator.clipboard.writeText(e);
    setIsOpenTooltip(true);
    const setCloseTooltip = debounce(() => {
      setIsOpenTooltip(false);
    }, 500);
    setCloseTooltip();
  };

  const onCopySource = (e: string) => {
    navigator.clipboard.writeText(e);
    setIsOpenSourceTooltip(true);
    const setCloseTooltip = debounce(() => {
      setIsOpenSourceTooltip(false);
    }, 500);
    setCloseTooltip();
  };

  const onCopyDestination = (e: string) => {
    navigator.clipboard.writeText(e);
    setIsOpenDestinationTooltip(true);
    const setCloseTooltip = debounce(() => {
      setIsOpenDestinationTooltip(false);
    }, 500);
    setCloseTooltip();
  };
  const decodeType = transactionDetail?.type;

  const renderStatus = (type: string) => {
    return (
      <Row
        className={clsx(styles.status, {
          [styles.status__title__approved]: STATUS_REQUEST.APPROVED === type,
        })}
        style={{ gap: 8 }}
      >
        {STATUS_REQUEST.REJECTED === type && (
          <SvgIcon
            name="white-reject"
            width={9}
            height={9}
            style={{ marginTop: 3 }}
          />
        )}
        {STATUS_REQUEST.APPROVED === type && (
          <SvgIcon
            name="white-approve"
            width={14}
            height={14}
            style={{ marginTop: 0 }}
          />
        )}
        <span className={styles.status__title__text}>{type.toUpperCase()}</span>
      </Row>
    );
  };

  return (
    <div className={styles.content}>
      <SvgIcon name="icon-snowflake" width={32} height={32} />
      <Row className={styles.reviewing__title} data-testid="transaction-title">
        Unfreeze transaction request
        {(transactionDetail?.status === STATUS_REQUEST.APPROVED ||
          transactionDetail?.status === STATUS_REQUEST.REJECTED) &&
          renderStatus(transactionDetail.status)}
      </Row>{' '}
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Transaction type"
          dataTestId="transaction-type"
          text={
            <Row style={{ gap: 8 }}>
              <div className={styles.icon}>
                <SvgIcon
                  name={infoTxn(decodeType)?.icon}
                  width={20}
                  height={20}
                />
              </div>
              <span
                className={clsx(styles.name, styles.bold__name_trans_detail)}
              >
                {infoTxn(decodeType)?.title}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Transaction ID"
          dataTestId="transaction-ID"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {transactionDetail?.transactionId}
              </span>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Customer name"
          dataTestId="customer-name"
          text={
            <Row style={{ gap: 8 }}>
              <div
                onClick={() => setOpen(true)}
                className={styles.customer__detail}
                data-testid="customer-name"
              >
                {transactionDetail?.customerName}
              </div>
            </Row>
          }
        />
        <TextInfor
          label="Asset"
          dataTestId="asset"
          text={
            <Row style={{ gap: 8 }}>
              <div className={styles.icon}>
                <img src={transactionDetail?.tokenIcon} alt="" />
              </div>
              <span
                className={clsx(styles.name, styles.bold__name_trans_detail)}
              >
                {transactionDetail?.tokenSymbol}
              </span>
              <span
                className={clsx(styles.name, styles.sub__name_trans_detail)}
              >
                {transactionDetail?.tokenName}
              </span>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Network"
          dataTestId="network"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {transactionDetail?.network}
              </span>
            </Row>
          }
        />
      </Row>
      <Row>
        <TextInfor
          label="Amount"
          dataTestId="amount"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.bold__name_trans_detail)}
              >
                {`${formatAssetDetails(transactionDetail?.amount)} ${
                  transactionDetail?.tokenSymbol
                }`}
              </span>
              <span
                className={clsx(styles.name, styles.sub__name_trans_detail)}
              >
                ${formatCurrencyTypeB(transactionDetail?.amountUSD)}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Network fee"
          dataTestId="network-fee"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {`${formatAssetDetails(transactionDetail?.networkFee)} ${
                  transactionDetail?.tokenSymbol
                }`}
              </span>
              <span
                className={clsx(styles.name, styles.sub__name_trans_detail)}
              >
                ${formatCurrencyTypeB(transactionDetail?.networkFeeUSD)}
              </span>
            </Row>
          }
        />
      </Row>
      <Divider orientationMargin="0" />
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Workspace"
          dataTestId="workspace"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {transactionDetail?.workspaces
                  ? transactionDetail.workspaces.map(w => w.name).join(', ')
                  : '-'}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Transaction status"
          dataTestId="transaction-status"
          text={
            <Row style={{ gap: 8 }}>
              <div
                className={`${styles.status} ${
                  styles[
                    transactionDetail?.transactionStatus?.toLowerCase() ===
                    TransactionStatus.FAILED
                      ? 'pending'
                      : TransactionStatus[transactionDetail?.transactionStatus]
                  ]
                }`}
              >
                {transactionDetail?.transactionStatus?.toLowerCase() ===
                TransactionStatus.FAILED
                  ? 'Pending review'
                  : capitalize(transactionDetail?.transactionStatus)}
              </div>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Transaction date"
          dataTestId="transaction-date"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {transactionDetail?.transactionDate
                  ? moment(transactionDetail?.transactionDate).format(
                      FORMAT_DATE.DATE_DMY_hM,
                    )
                  : '-'}
              </span>
            </Row>
          }
        />
        <TextInfor
          label={
            <Row style={{ gap: 10 }}>
              <span>Transaction hash</span>
              <Tooltip placement="top" title={'Copied'} open={isOpenTooltip}>
                <SvgIcon
                  name="copy"
                  width={14}
                  height={14}
                  className={styles.copy__icon}
                  onClick={() =>
                    onCopy(transactionDetail?.transactionHash ?? '')
                  }
                />
              </Tooltip>
            </Row>
          }
          dataTestId="transaction-hash"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
                style={{
                  color: '#2E90FA',
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  transactionDetail?.blockExplorerTxUrl
                    ? window.open(transactionDetail?.blockExplorerTxUrl)
                    : null
                }
              >
                {substr(transactionDetail?.transactionHash)}
              </span>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Source"
          dataTestId="source"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {transactionDetail?.source}
              </span>
            </Row>
          }
        />
        <TextInfor
          label={
            <Row style={{ gap: 10 }}>
              <span>Source Address</span>
              <Tooltip
                title={'Copied'}
                placement="top"
                open={isOpenSourceTooltip}
              >
                <SvgIcon
                  name="copy"
                  width={14}
                  height={14}
                  className={styles.copy__icon_trans_detail}
                  onClick={() => onCopySource(transactionDetail?.sourceAddress)}
                />
              </Tooltip>
            </Row>
          }
          dataTestId="source-address"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {substr(transactionDetail?.sourceAddress)}
              </span>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Destination"
          dataTestId="destination"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {transactionDetail?.destination}
              </span>
            </Row>
          }
        />
        <TextInfor
          label={
            <Row style={{ gap: 10 }}>
              <span>Destination Address</span>
              <Tooltip
                title={'Copied'}
                placement="top"
                open={isOpenDestinationTooltip}
              >
                <SvgIcon
                  name="copy"
                  width={14}
                  height={14}
                  className={styles.copy__icon_trans_detail}
                  onClick={() =>
                    onCopyDestination(transactionDetail?.destinationAddress)
                  }
                />
              </Tooltip>
            </Row>
          }
          dataTestId="destination-address"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {substr(transactionDetail?.destinationAddress)}
              </span>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Fireblock status"
          dataTestId="fireblocks-status"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
              >
                {capitalize(upperCase(transactionDetail?.fireblocksStatus))}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Substatus"
          dataTestId="substatus"
          text={
            <Row style={{ gap: 8 }}>
              <span
                className={clsx(styles.name, styles.normal__name_trans_detail)}
                style={{ fontSize: 12 }}
              >
                {capitalize(transactionDetail?.fireblocksStatus)}
              </span>
            </Row>
          }
        />
      </Row>
      <Drawer
        width={drawerWidth}
        placement="right"
        onClose={() => setOpen(false)}
        maskClosable={true}
        open={open}
        keyboard={false}
        title={
          <Row style={{ alignItems: 'center', gap: 8 }}>
            Customer Profile
            <SvgIcon
              name={
                isMaskUnfreezeTransactionDetail ? 'black-eye' : 'black-eye-off'
              }
              onClick={() =>
                setIsMaskUnfreezeTransactionDetail(
                  !isMaskUnfreezeTransactionDetail,
                )
              }
              width={16}
              height={isMaskUnfreezeTransactionDetail ? 12 : 16}
              style={{ cursor: 'pointer' }}
            />
          </Row>
        }
        className={styles.drawerProfile}
      >
        <div className={styles.profile}>
          <div className={styles.profile__top}>
            <span className={styles.name}>
              {transactionDetail?.customerName}
            </span>
          </div>
          {transactionDetail?.customerId && (
            <TabGeneral
              isMaskedProfile={isMaskUnfreezeTransactionDetail}
              id={transactionDetail?.customerId}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default TransactionInformation;
