import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import { AppState } from 'store';

interface EditCustomerState {
  isOpen: boolean;
  navigationPath: string;
  editCustomer: ResponseData<any>;
}

const initialState: EditCustomerState = {
  isOpen: false,
  navigationPath: '',
  editCustomer: {
    status: RequestStatus.Idle,
  },
};

const setIsOpenNavigation = (
  state: EditCustomerState,
  { payload }: PayloadAction<boolean>,
) => {
  state.isOpen = payload;
};

const setNavigationPath = (
  state: EditCustomerState,
  { payload }: PayloadAction<string>,
) => {
  state.navigationPath = payload;
};

const editCustomerRequest: CaseReducer<
  EditCustomerState,
  PayloadAction<any>
> = (state: EditCustomerState) => {
  state.editCustomer.status = RequestStatus.Loading;
};

const editCustomerSuccess: CaseReducer<
  EditCustomerState,
  PayloadAction<any>
> = (state: EditCustomerState, { payload }: PayloadAction<any>) => {
  state.editCustomer.status = RequestStatus.Success;
  state.editCustomer.data = payload;
};

const editCustomerFailed: CaseReducer<EditCustomerState> = (
  state: EditCustomerState,
) => {
  state.editCustomer.status = RequestStatus.Failed;
};

const editCustomerSlice = createSlice({
  name: 'editCustomerInfo',
  initialState,
  reducers: {
    setIsOpenNavigation,
    setNavigationPath,

    editCustomerRequest,
    editCustomerSuccess,
    editCustomerFailed,
  },
});

// Actions
export const editCustomerActions = editCustomerSlice.actions;

// Reducer
export const editCustomerReducer = editCustomerSlice.reducer;

// Selectors

export const selectIsOpenNavigation = (state: AppState) =>
  state.editCustomerInfo.isOpen;

export const selectNavigationPath = (state: AppState) =>
  state.editCustomerInfo.navigationPath;
