import { fork } from 'redux-saga/effects';
import accessControl from './accessControl/accessControl';
import addNewCustomerSaga from './addNewCustomer';
import auditLogListSaga from './auditLogList/auditLogList.saga';
import authSaga from './auth';
import billingsSaga from './billings';
import commentSaga from './comment';
import commonSaga from './common';
import customerProfileSaga from './customerProfile';
import customerSaga from './customers';
import requestsSaga from './requests';
import transactionSaga from './transaction';
import transactionsSaga from './transactions';
import walletInfoListSaga from './walletInfoList/walletInfoList.saga';
import currencySaga from './currency/currency.saga';
import currencyConvertSaga from './currencyConvert/currencyConvert.saga';
import snapshotTokenPriceSaga from './snapshotTokenPrice/snapshotTokenPrice.saga';
import wcWeb3ConnectSaga from './wcWeb3Connect/wcWeb3Connect.saga';
import wcAdapterSaga from './wcAdapter/wcAdapter.saga';
import wcAppAdapterSaga from './wcAppAdapter/wcAppAdapter.saga';
import wcAppEntitySaga from './wcAppEntity/wcAppEntity.saga';
import wcRequestWeb3ConnectSaga from './wcRequestWeb3Connect/wcRequestWeb3Connect.saga';
import vaultWcSaga from './vaultWc/vaultWc.saga';
import cusSubribeSaga from './cusSubribe/cusSubribe.saga';
import wcApplicationSaga from '../slices/wcApplication/wcApplication.saga';
import editCustomerSaga from './editCustomer';
import cfg_sourceOfFundSaga from './cfg_sourceOfFund/cfg_sourceOfFund.saga';
import cfg_typeOfCompanySaga from './cfg_typeOfCompany/cfg_typeOfCompany.saga';
import cfg_countrySaga from './cfg_country/cfg_country.saga';

const rootSaga = function* () {
  yield fork(customerSaga);
  yield fork(authSaga);
  yield fork(addNewCustomerSaga);
  yield fork(customerProfileSaga);
  yield fork(commonSaga);
  yield fork(requestsSaga);
  yield fork(transactionsSaga);
  yield fork(commentSaga);
  yield fork(transactionSaga);
  yield fork(billingsSaga);
  yield fork(auditLogListSaga);
  yield fork(accessControl);
  yield fork(walletInfoListSaga);
  yield fork(currencySaga);
  yield fork(currencyConvertSaga);
  yield fork(snapshotTokenPriceSaga);
  yield fork(wcWeb3ConnectSaga);
  yield fork(wcAdapterSaga);
  yield fork(wcAppAdapterSaga);
  yield fork(wcAppEntitySaga);
  yield fork(wcApplicationSaga);
  yield fork(wcRequestWeb3ConnectSaga);
  yield fork(vaultWcSaga);
  yield fork(cusSubribeSaga);
  yield fork(editCustomerSaga);
  yield fork(cfg_sourceOfFundSaga);
  yield fork(cfg_typeOfCompanySaga);
  yield fork(cfg_countrySaga);
};

export default rootSaga;
