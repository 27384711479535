import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetWcApplicationRequestParamDTO,
  GetWcApplicationResponseDTO,
  ICreateWcApplication,
  IWcApplicationDetail,
} from 'interfaces/wcApplication';
export function getWcApplicationRequest(
  params?: GetWcApplicationRequestParamDTO,
): Promise<GetWcApplicationResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCAPPLICATION}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailWcApplication(
  wcApplicationId: string,
): Promise<IWcApplicationDetail> {
  return Instance.get(`/${PREFIX_API.WCAPPLICATION}/${wcApplicationId}`);
}
export function createWcApplicationRequest(
  wcApplication: ICreateWcApplication,
): Promise<GetWcApplicationResponseDTO> {
  return Instance.post(`/${PREFIX_API.WCAPPLICATION}`, wcApplication);
}
export function updateWcApplicationRequest(
  wcApplication: ICreateWcApplication,
): Promise<GetWcApplicationResponseDTO> {
  const payload: any = { ...wcApplication };
  const id = wcApplication.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.WCAPPLICATION}/${id}`, payload);
}
export function getWcApplicationRequestPaginationConfig(): Promise<GetWcApplicationResponseDTO> {
  return Instance.get(`/${PREFIX_API.WCAPPLICATION}/pagination-config`);
}
