import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getWcWeb3ConnectRequest,
  getDetailWcWeb3Connect,
  createWcWeb3ConnectRequest,
  updateWcWeb3ConnectRequest,
  getWcWeb3ConnectRequestPaginationConfig,
} from 'services/wcWeb3Connect.service';
import { wcWeb3ConnectActions } from 'store/slices/wcWeb3Connect/wcWeb3Connect.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetWcWeb3ConnectRequestParamDTO,
  ICreateWcWeb3Connect,
} from 'interfaces/wcWeb3Connect';
const handleGetWcWeb3ConnectRequest = function* ({
  payload,
}: PayloadAction<GetWcWeb3ConnectRequestParamDTO>) {
  try {
    const res = yield call(getWcWeb3ConnectRequest, payload);
    const params = yield select(state => state.wcWeb3Connect.params) as any;
    const listWcWeb3Connect = yield select(
      state => state.wcWeb3Connect?.wcWeb3Connect?.data?.listWcWeb3Connect,
    ) as any;
    if (res) {
      yield put(
        wcWeb3ConnectActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listWcWeb3Connect || []), ...res.list];
        yield put(wcWeb3ConnectActions.appendWcWeb3Connect(newList));
      } else {
        yield put(
          wcWeb3ConnectActions.getWcWeb3ConnectSuccess({
            listWcWeb3Connect: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcWeb3ConnectActions.getWcWeb3ConnectFailed());
  }
};
const handleGetDetailWcWeb3ConnectRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailWcWeb3Connect, payload);
    if (res) {
      yield put(wcWeb3ConnectActions.getDetailWcWeb3ConnectSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcWeb3ConnectActions.getDetailWcWeb3ConnectFailed());
  }
};
const handleCreateWcWeb3ConnectRequest = function* ({
  payload,
}: PayloadAction<ICreateWcWeb3Connect>) {
  try {
    const res = yield call(createWcWeb3ConnectRequest, payload);
    if (res) {
      yield put(wcWeb3ConnectActions.createWcWeb3ConnectSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcWeb3ConnectActions.createWcWeb3ConnectFailed());
  }
};
const handleUpdateWcWeb3ConnectRequest = function* ({
  payload,
}: PayloadAction<ICreateWcWeb3Connect>) {
  try {
    const res = yield call(updateWcWeb3ConnectRequest, payload);
    if (res) {
      yield put(wcWeb3ConnectActions.updateWcWeb3ConnectSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcWeb3ConnectActions.updateWcWeb3ConnectFailed());
  }
};
const handleGetWcWeb3ConnectPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetWcWeb3ConnectRequestParamDTO>) {
  try {
    const res = yield call(getWcWeb3ConnectRequestPaginationConfig);
    if (res) {
      yield put(
        wcWeb3ConnectActions.getWcWeb3ConnectPaginationConfigSuccess(res),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(wcWeb3ConnectActions.getWcWeb3ConnectPaginationConfigFailed());
  }
};
const wcWeb3ConnectSaga = function* () {
  yield takeLatest(
    wcWeb3ConnectActions.getWcWeb3ConnectRequest.type,
    handleGetWcWeb3ConnectRequest,
  );
  yield takeLatest(
    wcWeb3ConnectActions.createWcWeb3ConnectRequest.type,
    handleCreateWcWeb3ConnectRequest,
  );
  yield takeLatest(
    wcWeb3ConnectActions.updateWcWeb3ConnectRequest.type,
    handleUpdateWcWeb3ConnectRequest,
  );
  yield takeLatest(
    wcWeb3ConnectActions.getWcWeb3ConnectPaginationConfigRequest.type,
    handleGetWcWeb3ConnectPaginationConfigRequest,
  );
  yield takeLatest(
    wcWeb3ConnectActions.getDetailWcWeb3ConnectRequest.type,
    handleGetDetailWcWeb3ConnectRequest,
  );
};
export default wcWeb3ConnectSaga;
