import { Drawer, Row } from 'antd';
import TabGeneral from 'app.v2/components/orgarnism/CustomerProfile/Tabs/General';
import { useEffect, useState } from 'react';
import { SvgIcon } from '../SvgIcon';
import styles from './index.module.scss';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  requestDetail: any;
  isMask?: boolean;
}

const CustomerProfileTab = ({
  open,
  setOpen,
  requestDetail,
  isMask = false,
}: Props) => {
  const [isMaskCustomer, setIsMaskCustomer] = useState(true);

  useEffect(() => {
    if (isMask) {
      setIsMaskCustomer(isMask);
    } else {
      setIsMaskCustomer(!isMask);
    }
  }, [isMask]);

  return (
    <Drawer
      width={1000}
      placement="right"
      onClose={() => setOpen(false)}
      forceRender
      maskClosable={true}
      open={open}
      keyboard={false}
      title={
        <Row style={{ alignItems: 'center', gap: 8 }}>
          Customer Profile
          <SvgIcon
            name={isMaskCustomer ? 'black-eye' : 'black-eye-off'}
            onClick={() => setIsMaskCustomer(!isMaskCustomer)}
            width={16}
            height={isMaskCustomer ? 12 : 16}
            style={{ cursor: 'pointer' }}
          />
        </Row>
      }
      destroyOnClose={true}
      className={styles.drawerProfile}
    >
      <div className={styles.profile}>
        <div className={styles.profile__top}>
          <span className={styles.name}>{requestDetail?.customer?.name}</span>{' '}
        </div>

        {requestDetail?.customer?.id && (
          <TabGeneral
            id={requestDetail?.customer?.id}
            isMaskedProfile={isMaskCustomer}
          />
        )}
      </div>
    </Drawer>
  );
};

export default CustomerProfileTab;
