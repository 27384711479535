import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getCurrencyConvertRequest,
  getDetailCurrencyConvert,
  createCurrencyConvertRequest,
  updateCurrencyConvertRequest,
} from 'services/currencyConvert.service';
import { currencyConvertActions } from 'store/slices/currencyConvert/currencyConvert.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetCurrencyConvertRequestParamDTO,
  ICreateCurrencyConvert,
} from 'interfaces/currencyConvert';
const handleGetCurrencyConvertRequest = function* ({
  payload,
}: PayloadAction<GetCurrencyConvertRequestParamDTO>) {
  try {
    const res = yield call(getCurrencyConvertRequest, payload);
    const params = yield select(state => state.currencyConvert.params) as any;
    const listCurrencyConvert = yield select(
      state =>
        state.currencyConvert?.currencyConvert?.data?.listCurrencyConvert,
    ) as any;
    if (res) {
      yield put(
        currencyConvertActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listCurrencyConvert || []), ...res.list];
        yield put(currencyConvertActions.appendCurrencyConvert(newList));
      } else {
        yield put(
          currencyConvertActions.getCurrencyConvertSuccess({
            listCurrencyConvert: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(currencyConvertActions.getCurrencyConvertFailed());
  }
};
const handleGetDetailCurrencyConvertRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailCurrencyConvert, payload);
    if (res) {
      yield put(currencyConvertActions.getDetailCurrencyConvertSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(currencyConvertActions.getDetailCurrencyConvertFailed());
  }
};
const handleCreateCurrencyConvertRequest = function* ({
  payload,
}: PayloadAction<ICreateCurrencyConvert>) {
  try {
    const res = yield call(createCurrencyConvertRequest, payload);
    if (res) {
      yield put(currencyConvertActions.createCurrencyConvertSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(currencyConvertActions.createCurrencyConvertFailed());
  }
};
const handleUpdateCurrencyConvertRequest = function* ({
  payload,
}: PayloadAction<ICreateCurrencyConvert>) {
  try {
    const res = yield call(updateCurrencyConvertRequest, payload);
    if (res) {
      yield put(currencyConvertActions.updateCurrencyConvertSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(currencyConvertActions.updateCurrencyConvertFailed());
  }
};
const currencyConvertSaga = function* () {
  yield takeLatest(
    currencyConvertActions.getCurrencyConvertRequest.type,
    handleGetCurrencyConvertRequest,
  );
  yield takeLatest(
    currencyConvertActions.getDetailCurrencyConvertRequest.type,
    handleGetDetailCurrencyConvertRequest,
  );
  yield takeLatest(
    currencyConvertActions.createCurrencyConvertRequest.type,
    handleCreateCurrencyConvertRequest,
  );
  yield takeLatest(
    currencyConvertActions.updateCurrencyConvertRequest.type,
    handleUpdateCurrencyConvertRequest,
  );
};
export default currencyConvertSaga;
