import { Checkbox, List } from 'antd';
import styles from './index.module.scss';

export interface IChecklist {
  label: React.ReactNode;
  value: string;
  ref: any;
}

type Props = {
  executeScroll: (value: string) => void;
  checklist: IChecklist[];
  checkedList: string[];
  setCheckedList: (checkedList: string[]) => void;
  checklistTitle?: string;
};

const Checklist = ({
  executeScroll,
  checklist,
  checkedList,
  setCheckedList,
  checklistTitle = 'CHECKLIST',
}: Props) => {
  const onCheckList = (e, value: string) => {
    if (e.target.checked) {
      const newCheckedList = [...checkedList];
      newCheckedList.push(value);
      setCheckedList(newCheckedList);
    } else {
      const oldCheckedList = [...checkedList];
      const newCheckedList = oldCheckedList.filter(
        checked => checked !== value,
      );
      setCheckedList(newCheckedList);
    }
  };

  return (
    <>
      <div className={styles.checklist__content}>
        <List
          header={<div className={styles.title}>{checklistTitle}</div>}
          bordered
          data-testid="checklist"
          dataSource={checklist}
          renderItem={(item, index) => (
            <List.Item data-testid={`item${index}`}>
              <Checkbox onChange={e => onCheckList(e, item.value)}></Checkbox>
              <span
                className={styles.item__text}
                onClick={() => executeScroll(item.value)}
              >
                {item.label}
              </span>
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default Checklist;
