import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { CUSTOMER_STATUS } from 'constants/customer';
import styles from './index.module.scss';

interface Props {
  status: any;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  loading: boolean;
  handleConfirm?: () => void;
}
const PopupResendEmail = ({
  status,
  isOpen,
  setIsOpen,
  loading = false,
  handleConfirm,
  ...props
}: Props) => {
  const contentPopup = status => {
    if (status === CUSTOMER_STATUS.AWAIT_ONBOARDING) {
      return (
        <div className={styles.content}>
          After the request has been approved, customer will receive an email
          for OTP verification.
        </div>
      );
    } else {
      return (
        <div className={styles.content}>
          After the request has been approved, customer will receive an email
          with the application download link, QR code, and onboarding
          instructions.
        </div>
      );
    }
  };
  return (
    <BasicModal
      destroyOnClose={true}
      className={styles.approve__popup}
      visible={isOpen}
      children={contentPopup(status)}
      title={
        status === CUSTOMER_STATUS.AWAIT_ONBOARDING
          ? 'You confirm to submit the resend verification email request?'
          : 'You confirm to submit the resend onboarding email request?'
      }
      centered={true}
      onCancel={() => setIsOpen(false)}
      maskClosable={true}
      width={404}
      footer={[
        <div className={styles.button} key="popup-resend-email">
          <BasicButton
            title="Back"
            type="outline"
            onClick={() => setIsOpen(false)}
          />
          <BasicButton
            title="Confirm"
            type="filled"
            className={styles.confirm}
            onClick={handleConfirm}
            loading={loading}
          />
        </div>,
      ]}
    />
  );
};

export default PopupResendEmail;
