import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { RequestStatus } from 'interfaces/request';
import { useAppSelector } from 'store/hooks';
import { selectStatusDeleteCustomer } from 'store/slices/customers';
import styles from './index.module.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (stt: boolean) => void;
  title: string;
  handleDeleteCustomer: () => void;
};

const ModalConfirmDeleteCustomer = ({
  isOpen,
  setIsOpen,
  title,
  handleDeleteCustomer,
}: Props) => {
  const statusDeleteCus = useAppSelector(selectStatusDeleteCustomer);
  const loading = statusDeleteCus?.status === RequestStatus.Loading;

  const onClickCancel = () => {
    setIsOpen(false);
  };

  return (
    <BasicModal
      destroyOnClose={true}
      onCancel={onClickCancel}
      closable={false}
      visible={isOpen}
      footer={false}
      closeIcon={false}
      maskClosable={true}
    >
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>
          Please check carefully before completing the action
        </div>
        <div className={styles.button}>
          <BasicButton title="Back" type="outline" onClick={onClickCancel} />
          <BasicButton
            title="Confirm"
            type="filled"
            className={styles.confirm}
            onClick={handleDeleteCustomer}
            loading={loading}
          />
        </div>
      </div>
    </BasicModal>
  );
};

export default ModalConfirmDeleteCustomer;
