import { LoadingOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

import styles from './index.module.scss';
import { RequestStatus } from 'interfaces/request';
import { FormItem } from './Styles';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  items: any[];
  canLoadMore?: boolean;
  status: RequestStatus;
  name: string;
  label?: string;
  keyValue?: string;
  labelValue?: string;
  placeholder?: string;
  loadMore?: () => void;
  disabled?: boolean;
  labelGroup?: string;
  keyGroup?: string;
  showSearch?: boolean;
  onInputSearch?: (keyword: string) => void;
  onChange?: (value: any) => void;
  onClear?: () => void;
  dataTestId?: string;
}

const SelectInfiniteScroll = ({
  items,
  canLoadMore,
  status,
  name,
  label,
  keyValue = 'value',
  labelValue = 'label',
  placeholder = 'Select',
  loadMore,
  onInputSearch,
  disabled = false,
  labelGroup,
  keyGroup,
  onChange,
  onClear,
  showSearch = true,
  dataTestId,
  ...props
}: Props) => {
  const refCanLoadMoreSources = useRef(canLoadMore);

  useEffect(() => {
    refCanLoadMoreSources.current = canLoadMore;
  }, [canLoadMore]);

  const isLoading = useMemo(
    () => status === RequestStatus.Loading || false,
    [status],
  );

  const debounceSearchOpts = debounce(async (value: string) => {
    onInputSearch?.(value);
  }, 500);

  const onSearchOpts = (newValue: string) => {
    const value = newValue || '';
    debounceSearchOpts(value);
  };

  const onScroll = event => {
    const target = event.target;
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      if (refCanLoadMoreSources.current) {
        loadMore?.();
      }
    }
  };

  const iconLoading = <LoadingOutlined className={styles.loading__icon} spin />;

  return (
    <FormItem label={label} name={name}>
      <Select
        mode="multiple"
        popupClassName={styles.dropdown}
        menuItemSelectedIcon={<SvgIcon name="check" width={16} height={16} />}
        loading={isLoading}
        filterOption={false}
        autoClearSearchValue={true}
        showSearch={showSearch}
        maxTagCount={1}
        allowClear
        suffixIcon={isLoading && <Spin indicator={iconLoading} />}
        placeholder={<span className={styles.placeholder}>{placeholder}</span>}
        onSearch={onSearchOpts}
        onPopupScroll={onScroll}
        onBlur={() => {
          onInputSearch?.('');
        }}
        onChange={value => {
          onChange?.(value);
        }}
        onClear={() => {
          onClear?.();
        }}
        disabled={disabled}
        data-testid={dataTestId}
      >
        {items &&
          items.map(item => {
            if (!!labelGroup && !!keyGroup) {
              return (
                <Select.OptGroup
                  label={
                    <span className={styles.wrapperGrp}>
                      {item[labelGroup]}
                    </span>
                  }
                  key={`${item[labelGroup]}-${uuidv4()}`}
                >
                  {item[keyGroup].map(itemChild => (
                    <Select.Option
                      value={itemChild[keyValue]}
                      key={`${itemChild[keyValue]}-${uuidv4()}`}
                    >
                      {itemChild[labelValue]}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              );
            }
            return (
              <Select.Option
                value={item[keyValue]}
                key={`${item[keyValue]}-${uuidv4()}`}
              >
                <span className={styles.source__item}>{item[labelValue]}</span>
              </Select.Option>
            );
          })}
      </Select>
    </FormItem>
  );
};

export default SelectInfiniteScroll;
