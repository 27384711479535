import { PayloadAction } from '@reduxjs/toolkit';
import { GetCustomersRequest } from 'interfaces/customers';
import {
  ParamsRejectRequest,
  ParamsPendingRequestDetail,
} from 'interfaces/pendingRequest';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getPendingRequestList,
  getDetailPendingRequest,
  updateRejectRequest,
  approveCustomerCreationRequest,
} from 'services/request';
import { ApproveRequest } from 'interfaces/request';
import { toast } from 'react-toastify';
import { requestActions } from 'store/slices/request';
import { handleShowMsgErr } from 'utils/common';
import ToastMessage from 'app/components/common/ToastMessage';
import { commonActions } from 'store/slices/common';
import { mapRequest } from 'utils/helpers';
import { getTotalPendingRequest } from 'services/common';
import { ApproveErrCode } from 'enum/pendingRequest';
import { ERROR_CODE } from 'enum/common';

const handleGetPendingRequestListRequest = function* ({
  payload,
}: PayloadAction<GetCustomersRequest>) {
  try {
    const res = yield call(getPendingRequestList, payload);
    const paramsPendingRequestList = yield select(
      state => state.requests.params,
    ) as any;
    const pendingRequests = yield select(
      state => state.requests?.pendingRequestList,
    ) as any;

    yield put(
      requestActions.setCanLoadMore(
        paramsPendingRequestList.offset + paramsPendingRequestList.limit <
          res.total,
      ),
    );
    if (paramsPendingRequestList?.offset > 0) {
      const newList = [...pendingRequests.data.records, ...res.records];
      yield put(requestActions.appendRequest(newList));
    } else {
      yield put(requestActions.getPendingRequestListSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(requestActions.getPendingRequestListFailed(error.message));
  }
};

const handleUpdateRejectRequest = function* ({
  payload,
}: PayloadAction<ParamsRejectRequest>) {
  const pendingRequests = yield select(
    state => state.requests.pendingRequestList,
  );
  try {
    const res = yield call(updateRejectRequest, payload);
    if (res) {
      const newPendingRequests = pendingRequests?.data?.records.filter(
        request => request.id !== payload.recordId,
      );

      yield put(requestActions.updateRejectRequestSuccess());
      toast(
        <ToastMessage type="info" message="The request has been rejected" />,
      );
      yield put(requestActions.setPendingRequests(newPendingRequests));
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(requestActions.updateRejectRequestFailed(error.message));
  }
};
const handleApproveCustomerCreationRequest = function* ({
  payload,
}: PayloadAction<ApproveRequest>) {
  try {
    const pendingRequests = yield select(
      state => state.requests.pendingRequestList,
    );
    const res = yield call(approveCustomerCreationRequest, payload);
    if (res) {
      const newPendingRequests = pendingRequests?.data?.records.filter(
        request => request.id !== payload.quorumRecordId,
      );
      yield put(requestActions.approveSuccess());
      toast(
        <ToastMessage type="info" message="The request has been approved" />,
      );
      yield put(requestActions.setPendingRequests(newPendingRequests));
      yield put(commonActions.getTotalRequestRequest());
    }
  } catch (error: any) {
    if (Object.values(ApproveErrCode).includes(error?.errorCode)) {
      handleShowMsgErr(
        error,
        error?.errorCode === ERROR_CODE.EXISTS_CUSTOMER
          ? 'Customer already exists in Rakkar'
          : 'This request has already been approved in the system',
      );
      const value = {
        showPopup: true,
        msg:
          error?.errorCode === ERROR_CODE.EXISTS_CUSTOMER
            ? 'Customer already exists in Rakkar'
            : 'This request has already been approved in the system',
      };
      yield put(requestActions.updateStatusApprove(value));
    } else {
      handleShowMsgErr(error);
    }
    yield put(requestActions.approveFailed());
  }
};

const handleGetDetailPendingRequest = function* ({
  payload,
}: PayloadAction<ParamsPendingRequestDetail>) {
  try {
    const res = yield call(getDetailPendingRequest, payload);
    yield put(requestActions.getDetailPendingRequestSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(requestActions.getDetailPendingRequestFailed(error.message));
  }
};

const handleForceScreenPendingRequests = function* () {
  try {
    const defaultParams = yield select(state => state.requests.params) as any;

    const [pendingRequests, totalRequest] = yield all([
      call(getPendingRequestList, mapRequest({ ...defaultParams, page: 1 })),
      call(getTotalPendingRequest),
    ]);
    yield put(requestActions.forceScreenSuccess());
    yield put(requestActions.getPendingRequestListSuccess(pendingRequests));
    yield put(commonActions.getTotalRequestSuccess(totalRequest));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(requestActions.forceScreenFailed());
  }
};

const requestsSaga = function* () {
  yield takeLatest(
    requestActions.getPendingRequestListRequest.type,
    handleGetPendingRequestListRequest,
  );

  yield takeLatest(
    requestActions.updateRejectRequest.type,
    handleUpdateRejectRequest,
  );
  yield takeLatest(
    requestActions.getDetailPendingRequest.type,
    handleGetDetailPendingRequest,
  );

  yield takeLatest(
    requestActions.approveRequest.type,
    handleApproveCustomerCreationRequest,
  );

  yield takeLatest(
    requestActions.forceScreenRequest.type,
    handleForceScreenPendingRequests,
  );
};

export default requestsSaga;
