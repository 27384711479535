import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';

import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortWalletInfoList,
  GroupByField,
  CustomerOnScreen,
} from 'enum/walletInfoList.enum';

import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetWalletInfoListRequestParamDTO,
  GetWalletInfoListResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IWalletInfoDetail,
} from 'interfaces/walletInfoList.interface';

import { AppState } from '../..';

interface WalletInfoListState {
  params: GetWalletInfoListRequestParamDTO;
  walletInfoList: ResponseData<GetWalletInfoListResponseDTO>;
  canLoadMore: boolean;

  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;

  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;

  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;

  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;

  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;

  walletInfoDetail: ResponseData<IWalletInfoDetail>;
}

export function getDefaultWalletInfoListRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.isAllRequest]: false,
    [Params.Order]: [{"sort":"createdAt","order":"DESC"}]
  };
}

export function getDefaultCompanyRequestParams() {}

export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}

export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}

export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}

const initialState: WalletInfoListState = {
  params: getDefaultWalletInfoListRequestParams(),
  walletInfoList: {
    status: RequestStatus.Idle,
  },

  canLoadMore: true,

  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.WALLET_INFO,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,

  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,

  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),

  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),

  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),

  walletInfoDetail: {
    status: RequestStatus.Idle,
  },
};

const getWalletInfoListRequest: CaseReducer<
  WalletInfoListState,
  PayloadAction<any>
> = (state: WalletInfoListState) => {
  state.walletInfoList.status = RequestStatus.Loading;
};

const getWalletInfoListSuccess: CaseReducer<
  WalletInfoListState,
  PayloadAction<GetWalletInfoListResponseDTO>
> = (
  state: WalletInfoListState,
  { payload }: PayloadAction<GetWalletInfoListResponseDTO>,
) => {
  state.walletInfoList.status = RequestStatus.Success;
  state.walletInfoList.data = payload;
};

const getWalletInfoListFailed: CaseReducer<WalletInfoListState> = (
  state: WalletInfoListState,
) => {
  state.walletInfoList.status = RequestStatus.Failed;
};

const getDetailWalletInfoRequest: CaseReducer<
  WalletInfoListState,
  PayloadAction<string>
> = (state: WalletInfoListState) => {
  state.walletInfoDetail.status = RequestStatus.Loading;
};

const getDetailWalletInfoSuccess: CaseReducer<
  WalletInfoListState,
  PayloadAction<IWalletInfoDetail>
> = (
  state: WalletInfoListState,
  { payload }: PayloadAction<IWalletInfoDetail>,
) => {
  state.walletInfoDetail.status = RequestStatus.Success;
  state.walletInfoDetail.data = payload;
};

const getDetailWalletInfoFailed: CaseReducer<WalletInfoListState> = (
  state: WalletInfoListState,
) => {
  state.walletInfoDetail.status = RequestStatus.Failed;
};

const createWalletInfoRequest: CaseReducer<
  WalletInfoListState,
  PayloadAction<IWalletInfoDetail>
> = (state: WalletInfoListState) => {
  state.walletInfoDetail.status = RequestStatus.Loading;
};

const createWalletInfoSuccess: CaseReducer<
  WalletInfoListState,
  PayloadAction<IWalletInfoDetail>
> = (
  state: WalletInfoListState,
  { payload }: PayloadAction<IWalletInfoDetail>,
) => {
  state.walletInfoDetail.status = RequestStatus.Success;
  state.walletInfoDetail.data = payload;
};

const createWalletInfoFailed: CaseReducer<WalletInfoListState> = (
  state: WalletInfoListState,
) => {
  state.walletInfoDetail.status = RequestStatus.Failed;
};

const updateWalletInfoRequest: CaseReducer<
  WalletInfoListState,
  PayloadAction<IWalletInfoDetail>
> = (state: WalletInfoListState) => {
  state.walletInfoDetail.status = RequestStatus.Loading;
};

const updateWalletInfoSuccess: CaseReducer<
  WalletInfoListState,
  PayloadAction<IWalletInfoDetail>
> = (
  state: WalletInfoListState,
  { payload }: PayloadAction<IWalletInfoDetail>,
) => {
  state.walletInfoDetail.status = RequestStatus.Success;
  state.walletInfoDetail.data = payload;
};

const updateWalletInfoFailed: CaseReducer<WalletInfoListState> = (
  state: WalletInfoListState,
) => {
  state.walletInfoDetail.status = RequestStatus.Failed;
};

const appendWalletInfoList: CaseReducer<
  WalletInfoListState,
  PayloadAction<any>
> = (state: WalletInfoListState, { payload }: PayloadAction<any>) => {
  if (state.walletInfoList?.data?.listWalletInfo) {
    state.walletInfoList.status = RequestStatus.Success;
    state.walletInfoList.data.listWalletInfo = [...payload];
  }
};

const setCanLoadMore = (
  state: WalletInfoListState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};

const resetWalletInfoList: CaseReducer<WalletInfoListState> = (
  state: WalletInfoListState,
) => {
  state.walletInfoList = {
    status: RequestStatus.Idle,
  };
};

const resetWalletInfoListParams: CaseReducer<WalletInfoListState> = (
  state: WalletInfoListState,
) => {
  state.params = getDefaultWalletInfoListRequestParams();
};

const setWalletInfoListFilterParams = (
  state: WalletInfoListState,
  { payload }: PayloadAction<GetWalletInfoListRequestParamDTO>,
) => {
  state.params = payload;
};

// TODO: Action
const fetchActionRequest: CaseReducer<
  WalletInfoListState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: WalletInfoListState) => {
  state.action.status = RequestStatus.Loading;
};

const fetchActionSuccess: CaseReducer<
  WalletInfoListState,
  PayloadAction<any>
> = (state: WalletInfoListState, { payload }: PayloadAction<any>) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};

const fetchActionFailed: CaseReducer<WalletInfoListState> = (
  state: WalletInfoListState,
) => {
  state.action.status = RequestStatus.Failed;
};

const walletInfoListSlice = createSlice({
  name: 'walletInfoList',
  initialState,
  reducers: {
    getWalletInfoListRequest,
    getWalletInfoListSuccess,
    getWalletInfoListFailed,

    getDetailWalletInfoRequest,
    getDetailWalletInfoSuccess,
    getDetailWalletInfoFailed,

    createWalletInfoRequest,
    createWalletInfoSuccess,
    createWalletInfoFailed,

    updateWalletInfoRequest,
    updateWalletInfoSuccess,
    updateWalletInfoFailed,

    appendWalletInfoList,

    setCanLoadMore,

    resetWalletInfoList,

    setWalletInfoListParams<K extends keyof GetWalletInfoListRequestParamDTO>(
      state: WalletInfoListState,
      action: PayloadAction<{
        key: K;
        value: GetWalletInfoListRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setWalletInfoListFilterParams,
    resetWalletInfoListParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
  },
});

// Actions
export const walletInfoListActions = walletInfoListSlice.actions;

// Reducer
export const walletInfoListReducer = walletInfoListSlice.reducer;

// Selectors
export const selectWalletInfoList = (state: AppState) =>
  state.walletInfoList?.walletInfoList;

export const selectParamWalletInfoList = (state: AppState) =>
  state.walletInfoList?.params;

export const selectCanLoadMoreWalletInfoList = (state: AppState) =>
  state.walletInfoList?.canLoadMore;

export const selectWalletInfoDetail = (state: AppState) =>
  state.walletInfoList?.walletInfoDetail;
