import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetCusSubscribeRequestParamDTO,
  GetCusSubscribeResponseDTO,
  ICreateCusSubscribe,
  ICusSubscribeDetail,
} from 'interfaces/cusSubscribe';
export function getCusSubscribeRequest(
  params?: GetCusSubscribeRequestParamDTO,
): Promise<GetCusSubscribeResponseDTO> {
  return Instance.get(`/${PREFIX_API.CUS_SUBSCRIBE}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailCusSubscribe(
  cusSubscribeId: string,
): Promise<ICusSubscribeDetail> {
  return Instance.get(`/${PREFIX_API.CUS_SUBSCRIBE}/${cusSubscribeId}`);
}
export function createCusSubscribeRequest(
  cusSubscribe: ICreateCusSubscribe,
): Promise<GetCusSubscribeResponseDTO> {
  return Instance.post(`/${PREFIX_API.CUS_SUBSCRIBE}`, cusSubscribe);
}
export function updateCusSubscribeRequest(
  cusSubscribe: ICreateCusSubscribe,
): Promise<GetCusSubscribeResponseDTO> {
  const payload: any = { ...cusSubscribe };
  const id = cusSubscribe.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.CUS_SUBSCRIBE}/${id}`, payload);
}
export function getCusSubscribeRequestPaginationConfig(): Promise<GetCusSubscribeResponseDTO> {
  return Instance.get(`/${PREFIX_API.CUS_SUBSCRIBE}/pagination-config`);
}
