import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import { RequestStatus } from 'interfaces/request';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectUnfreezeTxnData,
  transactionActions,
} from 'store/slices/transaction';
import styles from './index.module.scss';

interface Props {
  isOpen: boolean;
  txId: string;
  setIsOpen: (value: boolean) => void;
  customerName: string;
}
const UnfreezeTransaction = ({
  isOpen,
  setIsOpen,
  txId,
  customerName,
}: Props) => {
  const dispatch = useAppDispatch();
  const reviewerData = useAppSelector(selectUnfreezeTxnData) ?? {};
  const loading = reviewerData.status === RequestStatus.Loading;
  const handleUnfreeze = () => {
    dispatch(
      transactionActions.unFreezeTransactionRequest({
        txId: txId,
      }),
    );
  };

  useEffect(() => {
    if (reviewerData.status === RequestStatus.Success) {
      setIsOpen(false);
    }
  }, [reviewerData.status, setIsOpen]);

  const content = (
    <div className={styles.content}>
      Please ensure to check all information carefully before unfreezing
    </div>
  );
  return (
    <BasicModal
      destroyOnClose={true}
      className={styles.approve__popup}
      visible={isOpen}
      children={content}
      title={`You confirm to unfreeze the transaction?`}
      centered={true}
      onCancel={() => setIsOpen(false)}
      maskClosable={true}
      width={404}
      footer={[
        <div className={styles.button}>
          <BasicButton
            title="Back"
            type="outline"
            onClick={() => setIsOpen(false)}
          />
          <BasicButton
            title="Confirm"
            type="filled"
            className={styles.confirm}
            onClick={handleUnfreeze}
            loading={loading}
          />
        </div>,
      ]}
    />
  );
};

export default UnfreezeTransaction;
