import { Avatar, Drawer, Popover, Row } from 'antd';
import ApproveRequest from 'app/pages/PendingRequest/components/ApproveRequest';
import RejectRequest from 'app/pages/PendingRequest/components/RejectRequest';
import { RequestType } from 'enum/pendingRequest';
import { RequestStatus } from 'interfaces/request';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { LoadingOutlined, MoreOutlined, UserOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { FORMAT_DATE, STATUS_REQUEST } from 'constants/index';
import { ListActionNeedToCheckPermission, USER_ROLES } from 'enum/common';
import { capitalize } from 'lodash';
import {
  selectPermissions,
  selectRoles,
  selectUserProfile,
} from 'store/slices/auth';
import {
  requestActions,
  selectApproveRequest,
  selectPendingRequestDetail,
  selectRejectRequest,
} from 'store/slices/request';
import { checkPermissions, checkRoles } from 'utils/common';
import RButton from '../Button';
import AccessControl from '../PendingRequestDrawer/AccessControl';
import CustomerCreation from '../PendingRequestDrawer/CustomerCreation';
import MarkedAsLostDevice from '../PendingRequestDrawer/MarkedAsLostDevice';
import ProductionSubscriptionRequest from '../PendingRequestDrawer/ProductionSubscription';
import RemoveAccess from '../PendingRequestDrawer/RemoveAccess';
import ReplaceDeviceRequest from '../PendingRequestDrawer/ReplaceDeviceRequest';
import ResendEmail from '../PendingRequestDrawer/ResendEmail';
import UnfreezeTransactionRequest from '../PendingRequestDrawer/UnfreezeTransactionRequest';
import UnlockUser from '../PendingRequestDrawer/UnlockUser';
import UpdateFee from '../PendingRequestDrawer/UpdateFee';
import UserCreation from '../PendingRequestDrawer/UserCreation';
import { SvgIcon } from '../SvgIcon';
import styles from './index.module.scss';
import { commonActions } from 'store/slices/common';
import EditCustomerProfile from '../PendingRequestDrawer/EditCustomerProfile';
import NewProductSubscription from '../PendingRequestDrawer/NewProductSubscription';

interface Props {
  id: string;
  open?: boolean;
  onClose?: () => void;
}

const notShowIconEyeList = [
  RequestType.UNFREEZE_TRANSACTION,
  RequestType.CREATE_FEE_INFORMATION,
  RequestType.UPDATE_FEE_INFORMATION,
  RequestType.CHANGE_SUBSCRIBE_STAKING,
  RequestType.UPDATE_FEATURE_NETWORK_TRANSFER_ACCESS,
  RequestType.UPDATE_FEATURE_STAKING_ACCESS,
];

const DetailPendingRequest = ({ id, open, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectRoles);
  const userProfile = useAppSelector(selectUserProfile);

  const [isOpenRejectRequest, setIsOpenRejectRequest] =
    useState<boolean>(false);
  const [isOpenApproveRequest, setIsOpenApproveRequest] =
    useState<boolean>(false);
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const requestDetails = useAppSelector(selectPendingRequestDetail);
  const requestDetail = requestDetails?.data;

  const approveRequest = useAppSelector(selectApproveRequest);
  const rejectRequest = useAppSelector(selectRejectRequest);
  const permissions = useAppSelector(selectPermissions);

  const isOperationRole = checkRoles(USER_ROLES.OPERATION, roles);

  const [isMask, setIsMask] = useState(true);

  const isAdmin = useMemo(() => {
    return checkRoles(USER_ROLES.ADMIN, roles);
  }, [roles]);

  const isCustomerSuccess = useMemo(() => {
    return checkRoles(USER_ROLES.CUSTOMER_SUCCESS, roles);
  }, [roles]);

  const isProduct = useMemo(() => {
    return checkRoles(USER_ROLES.PRODUCT, roles);
  }, roles);

  const createdBy = useMemo(() => {
    return (
      (!!requestDetail?.approvalLogs &&
        requestDetail?.approvalLogs?.find(
          item => item.status === 'INITIATED',
        )) ||
      {}
    );
  }, [requestDetail?.approvalLogs]);

  const isAbleToRejectOrApprove = isProduct
    ? requestDetails?.data?.canApproveOrReject
    : true;

  const isAllowedForActions = useMemo(() => {
    if (
      requestDetail?.action === RequestType.UNFREEZE_TRANSACTION &&
      userProfile?.id !== createdBy.userId
    ) {
      return isOperationRole;
    }
    if (
      requestDetail?.action === RequestType.REP_REPLACE_DEVICE ||
      requestDetail?.action === RequestType.REMOVE_ACCESS
    ) {
      return isCustomerSuccess;
    }

    return (
      !isAdmin &&
      checkPermissions(
        ListActionNeedToCheckPermission.APPROVE_REJECT,
        permissions,
      )
    );
  }, [
    roles,
    permissions,
    requestDetail,
    isOperationRole,
    isAdmin,
    isCustomerSuccess,
    createdBy,
  ]);

  useEffect(() => {
    // dispatch api creation request
    dispatch(
      requestActions.getDetailPendingRequest({
        requestId: id,
      }),
    );
    dispatch(commonActions.getTypeOfCompanyRequest({}));
    dispatch(commonActions.getSourceOfFundRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      // approveRequest.status === RequestStatus.Failed ||
      approveRequest.status === RequestStatus.Success
    ) {
      setIsOpenApproveRequest(false);
      dispatch(requestActions.resetApproveRequest());
      onClose?.();
    }
    if (rejectRequest.status === RequestStatus.Success) {
      setIsOpenRejectRequest(false);
      dispatch(requestActions.resetRejectRequest());
      onClose?.();
    }
  }, [dispatch, onClose, approveRequest.status, rejectRequest.status]);

  const approvedBy = useMemo(() => {
    return (
      (!!requestDetail?.approvalLogs &&
        requestDetail?.approvalLogs?.find(
          item => item.status !== 'INITIATED',
        )) ||
      {}
    );
  }, [requestDetail?.approvalLogs]);

  const isNotShowEyeIcon = useMemo(() => {
    return notShowIconEyeList.includes(requestDetail?.action);
  }, [requestDetail?.action]);

  const title = useMemo(() => {
    return (
      requestDetail?.action && (
        <>
          <span className={styles.request__id}>
            ID:
            {requestDetail?.action === RequestType.UNFREEZE_TRANSACTION
              ? requestDetail.transactionId
              : requestDetail?.requestId}
          </span>
          {!isNotShowEyeIcon && (
            <SvgIcon
              name={isMask ? 'black-eye' : 'black-eye-off'}
              onClick={() => setIsMask(!isMask)}
              width={16}
              height={isMask ? 12 : 16}
              style={{ cursor: 'pointer', marginLeft: '14px' }}
            />
          )}
        </>
      )
    );
  }, [id, requestDetail?.action, isMask]);

  const customerFooter = useCallback(() => {
    if (isAllowedForActions && isAbleToRejectOrApprove) {
      return (
        <Row className={styles.customer__footer}>
          <>
            <RButton
              buttonType="back"
              content={
                <span style={{ color: '#F04438', fontSize: 13 }}>
                  <SvgIcon width={9} height={9} name="reject-icon"></SvgIcon>{' '}
                  Reject
                </span>
              }
              width={97}
              height={36}
              style={{ marginRight: '8px' }}
              onClick={() => setIsOpenRejectRequest(true)}
            />
            <RButton
              htmlType="submit"
              buttonType="submit"
              content={
                <>
                  <SvgIcon
                    width={11.67}
                    height={8.33}
                    name="approve-icon"
                  ></SvgIcon>{' '}
                  <span style={{ fontSize: 13 }}>Approve</span>
                </>
              }
              width={112}
              height={36}
              onClick={() => setIsOpenApproveRequest(true)}
            />
          </>
        </Row>
      );
    } else return null;
  }, [isAllowedForActions, requestDetail?.action]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userFooter = useCallback(() => {
    return (
      <Row className={styles.user__footer}>
        <span className={styles.created__by}>CREATED BY</span>
        <Avatar
          size={24}
          src={!!createdBy.approverAvatar ?? null}
          icon={<UserOutlined />}
        />
        <span className={styles.created__name}>{createdBy.approverName}</span>
        <span className={styles.created__time}>
          {createdBy.createdAt
            ? moment(createdBy.createdAt ?? '').format(FORMAT_DATE.DATE_DMY_hM)
            : ''}
        </span>
      </Row>
    );
  }, [createdBy]);

  const rejectingReason = useCallback(
    () => (
      <div className={styles.popover}>
        <div className={styles.popover__title}>Rejecting reason</div>
        <div className={styles.popover__content}>{requestDetail?.reason}</div>
      </div>
    ),
    [requestDetail],
  );

  const historyFooter = useCallback(
    (type: string) => (
      <Row className={styles.user__footer}>
        <Row
          className={clsx(styles.status, {
            [styles.status__approved]: STATUS_REQUEST.APPROVED === type,
          })}
        >
          <span
            className={clsx(styles.status__text, {
              [styles.status__approved__text]: STATUS_REQUEST.APPROVED === type,
            })}
          >
            {capitalize(type)}
          </span>
        </Row>
        <span className={styles.created__by}>BY</span>
        <Avatar size={24} icon={<UserOutlined />} />
        <span className={styles.created__name}>{approvedBy?.approverName}</span>
        <span className={styles.created__time}>
          {approvedBy?.createdAt
            ? moment(approvedBy?.createdAt ?? '').format(
                FORMAT_DATE.DATE_DMY_hM,
              )
            : ''}{' '}
        </span>
        {STATUS_REQUEST.REJECTED === type && (
          <Popover
            placement="topRight"
            content={rejectingReason}
            trigger="click"
            overlayClassName={styles.reject__reason}
            open={isOpenPopover}
            onOpenChange={e => {
              setIsOpenPopover(e);
            }}
            overlayStyle={{ width: 354 }}
          >
            <div
              className={clsx(styles.circle__button, {
                [styles.circle__button__bg]: isOpenPopover,
              })}
              onClick={() => setIsOpenPopover(true)}
            >
              <MoreOutlined
                rotate={90}
                style={{ fontSize: '16px', color: '#6B6D6F' }}
              />
            </div>
          </Popover>
        )}
      </Row>
    ),
    [isOpenPopover, approvedBy],
  );

  const footer = useMemo(() => {
    return (
      <div>
        {userFooter()}
        {requestDetail?.status === STATUS_REQUEST.APPROVED ||
        requestDetail?.status === STATUS_REQUEST.REJECTED
          ? historyFooter(requestDetail?.status)
          : customerFooter()}
      </div>
    );
  }, [id, requestDetail?.action, isOpenPopover]);

  const customerName = useMemo(() => {
    return requestDetail?.action === RequestType.CREATE_CUSTOMER
      ? requestDetail?.customerName
      : requestDetail?.customer?.name;
  }, [
    requestDetail?.action,
    requestDetail?.customer?.name,
    requestDetail?.customerName,
  ]);
  const renderComponent = useMemo(() => {
    if (
      requestDetail?.action === RequestType.RESEND_VERIFICATION_EMAIL ||
      requestDetail?.action === RequestType.RESEND_ONBOARDING_EMAIL
    ) {
      return <ResendEmail requestDetail={requestDetail} isMask={isMask} />;
    }
    if (requestDetail?.action === RequestType.CREATE_CUSTOMER) {
      return (
        <CustomerCreation
          onClose={onClose}
          name={requestDetail?.customerName}
          id={id}
          requestDetail={requestDetail}
          isMask={isMask}
        />
      );
    }
    if (requestDetail?.action === RequestType.REP_REPLACE_DEVICE) {
      return (
        <ReplaceDeviceRequest requestDetail={requestDetail} isMask={isMask} />
      );
    }
    if (
      requestDetail?.action === RequestType.ADD_NEW_USER ||
      requestDetail?.action === RequestType.REP_UPDATE_USER
    ) {
      return (
        <UserCreation
          requestDetail={requestDetail}
          isEditUser={requestDetail?.action === RequestType.REP_UPDATE_USER}
          isMask={isMask}
        />
      );
    }
    if (requestDetail?.action === RequestType.MARK_LOST_DEVICE) {
      return (
        <MarkedAsLostDevice
          onClose={onClose}
          name={customerName}
          id={id}
          requestDetail={requestDetail}
          isMask={isMask}
        />
      );
    }
    if (requestDetail?.action === RequestType.UNFREEZE_TRANSACTION) {
      return <UnfreezeTransactionRequest requestId={id} isMask={isMask} />;
    }
    if (
      requestDetail?.action === RequestType.CREATE_FEE_INFORMATION ||
      requestDetail?.action === RequestType.UPDATE_FEE_INFORMATION
    ) {
      return <UpdateFee requestDetail={requestDetail} isMask={isMask} />;
    }
    if (requestDetail?.action === RequestType.UNLOCK_USER) {
      return <UnlockUser requestDetail={requestDetail} isMask={isMask} />;
    }
    if (requestDetail?.action === RequestType.REP_REMOVE_USER)
      return <RemoveAccess requestDetail={requestDetail} isMask={isMask} />;
    if (
      requestDetail?.action ===
        RequestType.UPDATE_FEATURE_NETWORK_TRANSFER_ACCESS ||
      requestDetail?.action === RequestType.UPDATE_FEATURE_STAKING_ACCESS
    ) {
      return <AccessControl requestDetail={requestDetail} />;
    }
    if (requestDetail?.action === RequestType.CHANGE_SUBSCRIBE_STAKING) {
      return <ProductionSubscriptionRequest requestDetail={requestDetail} />;
    }
    if (requestDetail?.action === RequestType.EDIT_CUSTOMER_POLICY) {
      return (
        <EditCustomerProfile
          requestDetail={requestDetail}
          isMask={isMask}
          requestStatus={requestDetail.status}
        />
      );
    }
    if (requestDetail?.action === RequestType.CUSTOMER_SUBSCRIBE) {
      return (
        <NewProductSubscription requestDetail={requestDetail} isMask={isMask} />
      );
    }
  }, [id, onClose, requestDetail, isMask]);

  return (
    <div className={styles.container} data-testid="request-detail">
      {!id || requestDetails.status !== RequestStatus.Success ? (
        <Drawer
          width={1000}
          placement="right"
          onClose={onClose}
          forceRender
          maskClosable={true}
          open={open}
          keyboard={false}
          title={title}
          className={styles.drawerProfile}
        >
          <div className={styles.loading}>
            <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
          </div>
        </Drawer>
      ) : (
        <>
          <Drawer
            width={1000}
            placement="right"
            onClose={onClose}
            forceRender
            maskClosable={true}
            open={open}
            keyboard={false}
            title={title}
            footer={footer}
            className={styles.drawerProfile}
          >
            {renderComponent}
          </Drawer>
          {isOpenRejectRequest && (
            <RejectRequest
              requestId={id}
              customerName={customerName}
              isOpen={isOpenRejectRequest}
              setIsOpen={setIsOpenRejectRequest}
              reqAction={requestDetail?.action}
            />
          )}

          <ApproveRequest
            requestId={id}
            customerName={customerName}
            isOpen={isOpenApproveRequest}
            setIsOpen={setIsOpenApproveRequest}
            reqAction={requestDetail?.action}
          />
        </>
      )}
    </div>
  );
};

export default DetailPendingRequest;
