import { Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import NoResultWhenSearch from 'app/components/common/NoResultWhenSearch';
import RakkarHeaderFilter from 'app/components/common/RakkarHeaderFilter';
import CustomerProfile from 'app.v2/components/orgarnism/CustomerProfile';

import { SvgIcon } from 'app/components/SvgIcon';
import RTable from 'app/components/Table';
import NotFound from 'app/components/Table/components/EmptyState';

import clsx from 'clsx';
import {
  PER_PAGE,
  PRODUCT_TYPE,
  commonAttributesColumnTable,
} from 'constants/index';
import { PAGE } from 'enum/common';
import { SortListCustomer } from 'enum/customerManager';
import { BusinessTypeInterface } from 'interfaces/businessInformation';
import { Address, Customer } from 'interfaces/customers';
import { Params, ParamsCustomers, RequestStatus } from 'interfaces/request';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { AppState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  commonActions,
  selectBusinessTypes,
  selectWorkspaces,
} from 'store/slices/common';
import { customerProfileActions } from 'store/slices/customerProfile';
import {
  customersActions,
  selectCustomers,
  selectFirstRender,
  selectParamsCustomers,
  selectTotalActive,
} from 'store/slices/customers';
import { mapRequest } from 'utils/helpers';
import { history } from 'utils/history';
import FilterContent from './components/FilterModal';
import ProductButton from './components/ProductButton';
import StatusButton from './components/StatusButton';
import styles from './index.module.scss';
import EditCustomer from 'app/components/EditCustomer';

const paramNames = [
  'businessType',
  'country',
  'product',
  'workspace',
  'customerStatus',
];

const businessTypeOther = { id: 8, name: 'Other' };

export const renderIcon = (
  sortOrder: string,
  sortColumn: any,
  title: string,
  dataIndex: string,
) => {
  return (
    <>
      <div className={styles.title}>
        {title}
        {sortOrder && sortColumn?.dataIndex === dataIndex && (
          <div className={styles.icon}>
            <SvgIcon
              name={sortOrder === 'ascend' ? 'arrow-up' : 'arrow-down'}
              width={11}
              height={11}
            />
          </div>
        )}
      </div>
    </>
  );
};

const columns = (isFirstRender: boolean) => {
  const columns: ColumnsType<Customer> = [
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Customer name',
          'customerName',
        );
      },
      key: 'customerName',
      dataIndex: 'customerName',
      defaultSortOrder: 'ascend',
      render: text => <Typography.Text strong={true}>{text}</Typography.Text>,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Registration No',
          'businessRegistrationId',
        );
      },
      key: 'businessRegistrationId',
      dataIndex: 'businessRegistrationId',
      sorter: !isFirstRender,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Business type',
          'businessType',
        );
      },
      key: 'businessType',
      dataIndex: 'businessType',
      sorter: !isFirstRender,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Country', 'currentAddress');
      },
      dataIndex: 'currentAddress',
      sorter: !isFirstRender,
      key: 'currentAddress',
      render: (text: Address) => text.country,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Product', 'product');
      },
      dataIndex: 'product',
      key: 'product',
      sorter: !isFirstRender,
      render: (_, record) => {
        return (
          <div>
            {record.hotWallet && (
              <ProductButton productType={PRODUCT_TYPE.HOT_WALLET} />
            )}
            {record.coldWallet && (
              <>
                {'  '}
                <ProductButton productType={PRODUCT_TYPE.COLD_WALLET} />
              </>
            )}
          </div>
        );
      },
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Workspace', 'workspace');
      },
      key: 'workspace',
      dataIndex: 'workspace',
      sorter: !isFirstRender,
      showSorterTooltip: false,
      render: (_, record) => (
        <>
          {record.hotWallet && record.coldWallet ? (
            <div className={styles.workspace}>
              <div className={styles.workspace__text}>{record.hotWallet}</div>
              <Tooltip placement="bottom" title={record.coldWallet}>
                <div className={styles.more__workspace}>1+</div>
              </Tooltip>
            </div>
          ) : (
            <>{record.hotWallet || record.coldWallet}</>
          )}
        </>
      ),
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Status', 'status');
      },
      key: 'status',
      dataIndex: 'status',
      sorter: !isFirstRender,
      align: 'left',
      render: (text: string) => <StatusButton status={text} />,
    },
  ];
  return columns;
};

const initProfile = {
  id: '',
  name: '',
};

export function CustomerManagement() {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParamsCustomers);
  const customersList = useAppSelector(selectCustomers);
  const firstRender = useAppSelector(selectFirstRender);
  const forceScreen = useAppSelector(
    (state: AppState) => state.customers.forceScreen,
  );
  const itemSelected = useRef<Customer>();

  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get('id');

  const workspaces = useAppSelector(selectWorkspaces);
  const businessTypes = useAppSelector(selectBusinessTypes);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const loadDone = customersList.status === RequestStatus.Success;
  const currenPageRef = useRef(0);
  const totalCount = customersList.data?.totalCount || 0;
  const total = useAppSelector(selectTotalActive);
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const [businessTypesForFilter, setBusinessTypesForFilter] = useState<
    BusinessTypeInterface[]
  >([]);

  const setValue = (key: keyof ParamsCustomers) => {
    return (value: ParamsCustomers[keyof ParamsCustomers]) => {
      dispatch(
        customersActions.setCustomersParam({
          key,
          value,
        }),
      );
    };
  };

  const setPage = setValue(Params.Page);
  const setSort = setValue(Params.Sort);
  const setSortBy = setValue(Params.SortBy);

  const heightHeader =
    document.querySelector('#table')?.getBoundingClientRect()?.y || 0;
  const height = window.innerHeight - heightHeader - 90; //count table height

  const canLoadMoreStore = useAppSelector(
    (state: AppState) => state.customers.canLoadMore,
  );
  const refCanLoadMoreStore = useRef(canLoadMoreStore);

  const [open, setOpen] = useState(false);
  const [profile, setProfile] =
    useState<{ id: string; name: string }>(initProfile);
  const [selectedRowId, setSelectedRowId] = useState('');

  const hotWorkspaces = workspaces.data?.hot.map(e => {
    return { label: e.name, value: e.id, type: PRODUCT_TYPE.HOT_WALLET.text };
  });

  const coldWorkspaces = workspaces.data?.cold.map(e => {
    return { label: e.name, value: e.id, type: PRODUCT_TYPE.COLD_WALLET.text };
  });

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setProfile(initProfile);
    setSelectedRowId('');
    dispatch(customerProfileActions.resetUserListParams());
  };

  const handleViewDetail = (row: any) => {
    // handle selected row
    setSelectedRowId(row.id);

    itemSelected.current = row;
    setProfile({
      id: row.id,
      name: row.customerName,
    });
    showDrawer();
  };

  useEffect(() => {
    if (
      !customersList.data?.customers.length &&
      customersList?.status === RequestStatus.Success
    ) {
      setShowEmptyScreen(true);
    } else {
      setShowEmptyScreen(false);
    }
  }, [customersList?.status, customersList?.data]);

  useEffect(() => {
    if (params.page > 1) {
      dispatch(
        customersActions.fetchCustomersRequest({
          params: mapRequest(params),
        }),
      );
      currenPageRef.current = params.page;
    }
  }, [params, dispatch]);

  useEffect(() => {
    refCanLoadMoreStore.current = canLoadMoreStore;
  }, [canLoadMoreStore]);

  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    tableContent &&
      tableContent.addEventListener('scroll', event => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = (event.target.scrollTop + 2).toFixed();
        let page = params?.page || 0;
        if (maxScroll === 0 && page > 0) {
          page = 0;
        }
        if (
          refCanLoadMoreStore.current &&
          currentScroll > maxScroll &&
          totalCount > page * PER_PAGE &&
          customersList?.data?.customers.length === page * PER_PAGE
        ) {
          setPage(page + 1);
        }
      });
  }, [params, canLoadMoreStore, customersList]);

  useEffect(() => {
    const length = customersList.data?.customers.length || 0;
    const count = height / 46; //46 height of 1 record
    let page = Number(params?.page) || 1;
    const total = customersList?.data?.totalCount ?? 0;
    const totalRecord = page * PER_PAGE;
    const elmTable = document.querySelector('.ant-table-body');

    if (
      totalRecord < count &&
      totalRecord < total &&
      length === params?.page * PER_PAGE
    ) {
      setPage(page + 1);
      if (page > 0 && elmTable) {
        elmTable.scrollTo(0, 0);
      }
    }
  }, [customersList.data?.customers, params?.page, dispatch, height]);

  useEffect(() => {
    dispatch(commonActions.getWorkSpacesRequest());
    dispatch(commonActions.getBusinessTypesRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(commonActions.setViewPort(window.innerWidth));
    dispatch(customersActions.getTotalActiveRequest(''));

    return () => {
      dispatch(customersActions.resetFirstRender());
      dispatch(customersActions.resetCustomers());
      dispatch(customersActions.resetCustomersParams());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterParam = {};
    paramNames.forEach(element => {
      filterParam[element] = params[element];
    });
    const isEmpty = Object.values(filterParam).some(e => !!e);
    setIsFilter(isEmpty);
  }, [params]);

  useEffect(() => {
    const newBusinessTypes: BusinessTypeInterface[] = businessTypes?.data
      ? businessTypes.data.map(businessType => {
          return {
            ...businessType,
            name:
              businessType.id === businessTypeOther.id
                ? businessTypeOther.name
                : businessType.name,
          };
        })
      : [];
    setBusinessTypesForFilter(newBusinessTypes);
  }, [businessTypes?.data]);

  const onInputChange = useCallback(
    async (keyword: string) => {
      const newParams = {
        ...params,
        keyword,
        page: 1,
      };

      dispatch(customersActions.setCustomerFilterParams(newParams));

      await dispatch(
        customersActions.fetchCustomersRequest({
          params: mapRequest(newParams),
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const onClickRefresh = useCallback(
    async () => {
      setPage(1);
      dispatch(
        customersActions.refreshCustomers({
          params: mapRequest({
            ...params,
            page: 1,
          }),
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPage, setValue],
  );

  const onFinishFilter = async (values: any) => {
    setIsOpenPopover(false);
    dispatch(customersActions.updateFirstRender(false));
    const newParams = {
      ...params,
      ...values,
      page: 1,
    };
    dispatch(customersActions.setCustomerFilterParams(newParams));

    await dispatch(
      customersActions.fetchCustomersRequest({
        params: mapRequest(newParams),
      }),
    );
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any,
  ) => {
    const sort = sorter.order === 'descend' ? 'DESC' : 'ASC';

    const sortBy = SortListCustomer[sorter.field];

    setSort(sort);
    setSortBy(sortBy);
    setPage(1);

    await dispatch(
      customersActions.fetchCustomersRequest({
        params: mapRequest({
          ...params,
          sort,
          sortBy,
          page: 1,
        }),
      }),
    );
  };

  if (customerId) return <EditCustomer />;

  return (
    <>
      <Helmet>
        <title>Customer Management</title>
        <meta name="description" content="CustomerManagement" />
      </Helmet>
      <div
        className={clsx(styles.container, {
          [styles.empty__state]:
            firstRender && customersList.data?.customers.length === 0,
        })}
      >
        <div className={styles.header}>
          <div className={styles.icon}>
            <SvgIcon name="customer" width={28} height={28} />
          </div>
          <div className={styles.title}>Customer management</div>
          <div
            className={
              total?.data?.totalActive ? styles.active : styles.inactive
            }
          >
            Total active customers: {total?.data?.totalActive || 0}
          </div>
        </div>
        {firstRender && showEmptyScreen ? (
          <div className={styles.body}>
            <NotFound
              onClickButton={() =>
                history.push({ pathname: '/create-customer' })
              }
              allowAdd={true}
              buttonText={'Add Customer'}
              title={"You don't have any customers"}
              description="Let's add the first customer to the system"
            />
          </div>
        ) : (
          <>
            <div className={styles.header}>
              <RakkarHeaderFilter
                page={PAGE.CUSTOMER_MANAGEMENT}
                keyword={params.keyword}
                onInputSearch={onInputChange}
                onRefresh={onClickRefresh}
                isOpenPopover={isOpenPopover}
                setIsOpenPopover={setIsOpenPopover}
                isHasFilter={isFilter}
                isDisableRefresh={firstRender}
                filterContent={
                  <FilterContent
                    hotWorkspaces={hotWorkspaces || []}
                    coldWorkspaces={coldWorkspaces || []}
                    businessTypeList={businessTypesForFilter}
                    isOpen={isOpenPopover}
                    onFinishFilter={onFinishFilter}
                  />
                }
                onAddCustomer={() => {
                  history.push({
                    pathname: '/create-customer',
                  });
                }}
              />
            </div>
            <div
              className={clsx(styles.wrapperTable, {
                [styles.infinite__container]:
                  customersList.data?.customers.length === 0 && !firstRender,
              })}
            >
              {showEmptyScreen ? (
                <NoResultWhenSearch
                  title={
                    isFilter
                      ? 'No matching results'
                      : 'No matching search results'
                  }
                  description={isFilter ? '' : 'Try another keyword'}
                />
              ) : (
                <RTable
                  page={PAGE.CUSTOMER_MANAGEMENT}
                  isFirstRender={firstRender}
                  columns={columns(firstRender)}
                  currentTableData={
                    firstRender && loadDone
                      ? []
                      : customersList.data?.customers || []
                  }
                  loading={
                    customersList.status === RequestStatus.Loading ||
                    forceScreen.status === RequestStatus.Loading
                  }
                  selectedRowId={selectedRowId}
                  searchValue={params.keyword}
                  clickOnRow={handleViewDetail}
                  onChange={handleTableChange}
                  isFilter={isFilter}
                  height={height}
                />
              )}
            </div>
            {profile.id && (
              <CustomerProfile
                id={profile.id}
                name={profile.name}
                open={open}
                onClose={onClose}
                item={itemSelected.current}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
