import { Button, Col, Row } from 'antd';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { SvgIcon } from '../../SvgIcon';
import styles from './index.module.scss';

export const RenderVault = (vault: any) => {
  return (
    <Row className={styles.vault} key={`vault-${vault}`}>
      <div className={styles.vault__icon}>
        <SvgIcon name="vault" width={14} height={17} />
      </div>
      <span className={styles.vault__name}>{vault}</span>
    </Row>
  );
};

export const SortVaultName = list => {
  list.sort((a, b) => {
    const vaultA = a.name.toUpperCase();
    const vaultB = b.name.toUpperCase();
    if (vaultA < vaultB) {
      return -1;
    }
    if (vaultA > vaultB) {
      return 1;
    }
    return 0;
  });
};

interface Props {
  requestDetail: any;
}

export const RenVault = (
  vaultQuantity,
  showNumber: number,
  isShowAllVaults: boolean,
  list,
) => {
  if (vaultQuantity <= showNumber || isShowAllVaults) {
    return list?.map(vault => (
      <div key={vault?.id}>{RenderVault(vault?.name)}</div>
    ));
  } else {
    let returnVault: JSX.Element[] = [];
    for (let index = 0; index < showNumber; index++) {
      let vault = list[index];
      returnVault.push(RenderVault(vault?.name));
    }
    return returnVault;
  }
};

const Vaults = ({ requestDetail }: Props) => {
  const [isShowAllVaults, setIsShowAllVaults] = useState(false);
  let vaultQuantity =
    requestDetail?.vaults?.length ?? requestDetail?.user?.vaults.length;
  let showNumber = 10;

  useEffect(() => {
    if (vaultQuantity > showNumber) setIsShowAllVaults(false);
    else setIsShowAllVaults(true);
  }, [vaultQuantity]);

  const renderVaultConditional = useCallback(() => {
    if (!requestDetail) return;
    // sort vault name (A-Z)
    const listVault = [
      ...(requestDetail?.vaults || [...(requestDetail?.user?.vaults || [])]),
    ];
    SortVaultName(listVault);

    return RenVault(vaultQuantity, showNumber, isShowAllVaults, listVault);
  }, [requestDetail, isShowAllVaults, showNumber, vaultQuantity]);

  return (
    <div className={styles.text__portion} data-testid="vault-access">
      <span className={styles.label}>Assigned to vaults</span>
      <Row className={styles.sectionVaults}>
        {renderVaultConditional()}
        <div
          className={clsx({
            [styles.shadowVaults]: !isShowAllVaults,
          })}
        />
      </Row>
      <Row>
        {!isShowAllVaults && (
          <Col
            span={12}
            onClick={() => {
              setIsShowAllVaults(true);
            }}
          >
            <Button type="link">
              <Row gutter={12}>
                {vaultQuantity > 1 ? (
                  <Col>Show all {vaultQuantity} vaults </Col>
                ) : (
                  <Col>Show {vaultQuantity} vault </Col>
                )}
                <Col>
                  <SvgIcon name="down" width={12} height={8} />
                </Col>
              </Row>
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Vaults;
