import { Col, DatePicker, Form, Radio, Row } from 'antd';
import RButton from 'app/components/Button';
import BasicInput from 'app/components/common/BasicInput';
import BasicModal from 'app/components/common/BasicModal';
import BasicSelect from 'app/components/common/BasicSelect';
import RakSwitch from 'app/components/common/RakSwitch';
import clsx from 'clsx';
import {
  FORMAT_DATE,
  REGEX_ALLOW_AZ_09,
  REGEX_ALLOW_AZ_09_SPACE,
  SEARCH_DEBONCED,
} from 'constants/index';
import { AddressType, RoleQuorum } from 'enum/addNewCustomer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch } from 'react-redux';
import { checkValidateDuplicateUser } from 'services/addNewCustomer';
import { useAppSelector } from 'store/hooks';
import {
  customersActions,
  selectEditUser,
  selectUser,
} from 'store/slices/customers';
import { countryData, documentTypes } from 'utils/assets';
import { validateMessages, validateRegex } from 'utils/common';
import BasicButton from '../BasicButton';
import { SvgIcon } from '../SvgIcon';
import styles from './index.module.scss';
import { DOCUMENT_TYPE, SCREEN_USER } from 'enum/common';
import moment from 'moment';
import { ModalLocation } from 'app/pages/AddCustomer/BussinessInformation/ModalLocation';
import { getTextAddress } from 'utils/helpers';
import { RequestStatus } from 'interfaces/request';
import {
  CheckQuorumRequest,
  CheckValidateDuplicateUserRequest,
  EditUserRequest,
  User,
} from 'interfaces/customers';
import { debounce, isEqual } from 'lodash';
import { RoleUser } from 'enum/userList';
import SelectVault from './SelectVault';
import { checkQuorumByUser } from 'services/customers';
import { UserInformation } from 'interfaces/adminQuorum';
import { v4 as uuidv4 } from 'uuid';

const msg = 'Not allow to input special characters';
const msgForPassport = 'Not allow to input special characters and space';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  userEdit: any;
  customerId?: string;
  fromScreen?: SCREEN_USER;

  onSubmitAdminQuorum?: (values: any, screen: SCREEN_USER) => void;
  usersList?: UserInformation[];
}

const listRoles = [
  {
    value: RoleUser.VIEWER,
    text: 'Viewer',
  },
  {
    value: RoleUser.MEMBER,
    text: 'Member',
  },
  {
    value: RoleUser.ADMIN,
    text: 'Admin',
  },
];

const defaultValues = {
  email: null,
  lastName: null,
  middleName: null,
  passportIssuedCountry: null,
  passportNumber: null,
  phoneNumber: null,
  requiredApprover: false,
  userRole: RoleQuorum.VIEWER,
  address: null,
  dateOfBirth: null,
  nationality: null,
};

const PopupAddOrEditUser = ({
  isOpen,
  setIsOpen,
  userEdit,
  customerId,
  fromScreen = SCREEN_USER.ADD_USER,
  onSubmitAdminQuorum,
  usersList,
}: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [requiredApprover, setRequiredApprover] = useState(
    userEdit?.requiredApprover || false,
  );

  const [showPopLocation, setShowPopLocation] = useState(false);
  const [countryCode, setCountryCode] = useState(
    userEdit?.countryCode ?? '+65',
  );

  const documentTypeValue = Form.useWatch('documentType', form);

  const [userRole, setUserRole] = useState<string>(RoleQuorum.VIEWER);

  const user = useAppSelector(selectUser);
  const userEditStore = useAppSelector(selectEditUser);
  const statusCreateUser = user?.status;
  const defaultAddress = {
    country: 'Singapore',
    city: '',
    address: '',
  };
  const isEditUser = fromScreen === SCREEN_USER.EDIT_USER;

  const [valueAddress, setValueAddress] = useState(defaultAddress);
  const [isShowPopupError, setIsShowPopupError] = useState<boolean>(false);
  const [initDataForm, setInitDataForm] = useState<any>(null);
  const [isLoadingCheckDocumentType, setIsLoadingCheckDocumentType] =
    useState<boolean>(false);

  const showErrPhone = form.getFieldError('phoneNumber')?.length > 0;
  const vaultIds =
    fromScreen === SCREEN_USER.EDIT_USER &&
    userEdit?.vaults.map(item => item.id);
  const title = (screen: SCREEN_USER) => {
    return {
      [SCREEN_USER.ADD_USER]: 'Add user',
      [SCREEN_USER.EDIT_USER]: 'Edit user',
      [SCREEN_USER.ADMIN_QUORUM_ADD]: 'Add new user',
      [SCREEN_USER.ADMIN_QUORUM_EDIT]: 'Edit user',
    }[screen];
  };
  // setData when Edit
  useEffect(() => {
    if (
      fromScreen === SCREEN_USER.EDIT_USER ||
      fromScreen === SCREEN_USER.ADMIN_QUORUM_EDIT
    ) {
      const initData = {
        ...userEdit,
        address: getTextAddress(userEdit.address),
        userRole:
          fromScreen === SCREEN_USER.EDIT_USER
            ? userEdit?.roleName
            : userEdit?.userRole,
        vaultIds: fromScreen === SCREEN_USER.EDIT_USER && vaultIds,
        dateOfBirth: moment(userEdit.dateOfBirth),
      };
      form.setFieldsValue(initData);
      setInitDataForm(initData);
      setValueAddress(userEdit?.address);
      setCountryCode(userEdit?.countryCode ?? '+65');
      setUserRole(
        fromScreen === SCREEN_USER.EDIT_USER
          ? userEdit?.roleName
          : userEdit?.userRole,
      );
    } else {
      form.setFieldsValue(defaultValues);
      setUserRole(RoleQuorum.VIEWER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, dispatch, fromScreen]);

  useEffect(() => {
    if (
      (statusCreateUser === RequestStatus.Success &&
        fromScreen === SCREEN_USER.ADD_USER) ||
      (userEditStore?.status === RequestStatus.Success &&
        fromScreen === SCREEN_USER.EDIT_USER)
    ) {
      handleResetPopup();
      dispatch(customersActions.resetStatusfetchCustomers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateUser, userEditStore]);

  useEffect(() => {
    if (
      fromScreen === SCREEN_USER.EDIT_USER ||
      fromScreen === SCREEN_USER.ADMIN_QUORUM_EDIT
    ) {
      setIsDisabled(false);
    }
  }, [fromScreen]);

  const handleCheckUniqueByField = async (
    params: CheckValidateDuplicateUserRequest,
  ) => {
    try {
      const { exist } = await checkValidateDuplicateUser(params);
      const isCheckEmail = Object.keys(params).includes('email');

      if (exist) {
        form.setFields([
          {
            name: isCheckEmail ? 'email' : 'phoneNumber',
            errors: isCheckEmail
              ? ['Email already exists. Please try another one.']
              : ['Phone number already exists. Please try another one.'],
          },
        ]);
      }
      handleFieldsChange();
    } catch (error) {
      handleFieldsChange();
    }
  };

  const deboundCheckUniqueByField = debounce(
    async (params: CheckValidateDuplicateUserRequest) => {
      return handleCheckUniqueByField(params);
    },
    SEARCH_DEBONCED,
  );

  const handleCheckDuplicateUser = async (
    params: CheckValidateDuplicateUserRequest,
  ) => {
    setIsLoadingCheckDocumentType(true);
    try {
      const { exist } = await checkValidateDuplicateUser(params);
      if (exist) {
        setIsShowPopupError(true);
        return exist;
      }
    } catch (error) {
      setIsLoadingCheckDocumentType(false);
    } finally {
      setIsLoadingCheckDocumentType(false);
    }
  };

  const checkValidateDuplicateUserList = (user: UserInformation, params) => {
    return (
      user?.id !== userEdit?.id &&
      ((user?.passportNumber === params?.number &&
        user?.passportIssuedCountry === params?.country) ||
        user?.email === params?.email ||
        user?.phoneNumber === params?.phoneNumber ||
        (user?.idCardNumber === params?.number &&
          user?.idCardIssuedCountry === params?.country))
    );
  };

  const handleDuplicateUserList = (
    params: CheckValidateDuplicateUserRequest,
  ) => {
    setIsLoadingCheckDocumentType(true);
    let duplicated = true;
    try {
      if (usersList && usersList.length > 0) {
        for (const element of usersList) {
          const isDuplicated = checkValidateDuplicateUserList(element, params);
          if (isDuplicated) {
            setIsShowPopupError(true);
            duplicated = false;
            break;
          }
        }
      }
      return duplicated;
    } catch (error) {
      setIsLoadingCheckDocumentType(false);
    } finally {
      setIsLoadingCheckDocumentType(false);
    }
  };

  const checkDucumentTypeHasChanged = (objNeedCompare: any) => {
    const { documentType } = userEdit;
    const dataUser = {
      documentType,
      number:
        documentType === DOCUMENT_TYPE.ID_CARD
          ? userEdit.idCardNumber
          : userEdit.passportNumber,
      country:
        documentType === DOCUMENT_TYPE.ID_CARD
          ? userEdit.idCardIssuedCountry
          : userEdit.passportIssuedCountry,
    };
    return isEqual(dataUser, objNeedCompare);
  };

  const isLoadingSubmit = useMemo(() => {
    return (
      (statusCreateUser === RequestStatus.Loading &&
        fromScreen === SCREEN_USER.ADD_USER) ||
      (userEditStore?.status === RequestStatus.Loading &&
        fromScreen === SCREEN_USER.EDIT_USER) ||
      isLoadingCheckDocumentType
    );
  }, [
    fromScreen,
    isLoadingCheckDocumentType,
    statusCreateUser,
    userEditStore?.status,
  ]);

  const onSubmit = () => {
    form.validateFields().then(async values => {
      const { documentType } = values;

      let paramsDefault: CheckValidateDuplicateUserRequest = {
        documentType,
        ...(isEditUser ? { userId: userEdit?.userId } : {}),
      };

      if (documentType === DOCUMENT_TYPE.ID_CARD) {
        paramsDefault = {
          ...paramsDefault,
          number: values?.idCardNumber,
          country: values?.idCardIssuedCountry,
        };
      } else if (documentType === DOCUMENT_TYPE.PASSPORT) {
        paramsDefault = {
          ...paramsDefault,
          number: values?.passportNumber,
          country: values?.passportIssuedCountry,
        };
      }

      if (
        fromScreen === SCREEN_USER.ADMIN_QUORUM_ADD ||
        fromScreen === SCREEN_USER.ADMIN_QUORUM_EDIT
      ) {
        const dup = handleDuplicateUserList(paramsDefault);
        if (!dup) return;
      }

      const isHasChanged =
        (fromScreen === SCREEN_USER.EDIT_USER ||
          fromScreen === SCREEN_USER.ADMIN_QUORUM_EDIT) &&
        checkDucumentTypeHasChanged(paramsDefault);

      const res =
        !isHasChanged && (await handleCheckDuplicateUser(paramsDefault));
      if (res) return;

      // handle Data for Edit/Add User
      const payload = {
        id: customerId,
        user: {
          ...values,
          countryCode,
          requiredApprover: requiredApprover ?? false,
          dateOfBirth: moment(values.dateOfBirth).format(FORMAT_DATE.DATE_YMD),
          address: valueAddress,
          firstName: values.firstName.trim(),
          middleName: values?.middleName?.trim(),
          lastName: values.lastName.trim(),
        },
      };

      if (fromScreen === SCREEN_USER.EDIT_USER) {
        let roleWillUpdate: string;

        let vaultsWillRemoveAccess,
          vaultsWillAddAccess = [];

        vaultsWillAddAccess = values.vaultIds.filter(
          value => !vaultIds.find(vaultId => vaultId === value),
        );

        vaultsWillRemoveAccess = vaultIds.filter(
          value => !values.vaultIds.find(vaultId => vaultId === value),
        );

        roleWillUpdate = values.userRole;
        handleEditUser({
          roleWillUpdate,
          vaultsWillAddAccess,
          vaultsWillRemoveAccess,
          newUser: payload.user as User,
        });
      } else if (fromScreen === SCREEN_USER.ADD_USER) {
        dispatch(
          customersActions.createNewUserRequest({
            id: customerId,
            user: {
              ...values,
              countryCode,
              dateOfBirth: moment(values.dateOfBirth).format(
                FORMAT_DATE.DATE_YMD,
              ),
              address: { ...valueAddress },
              firstName: values.firstName.trim(),
              middleName: values?.middleName?.trim(),
              lastName: values.lastName.trim(),
            },
          }),
        );
      } else if (fromScreen === SCREEN_USER.ADMIN_QUORUM_ADD) {
        const data = {
          id: uuidv4(),
          ...payload.user,
        };
        onSubmitAdminQuorum?.(data, SCREEN_USER.ADMIN_QUORUM_ADD);
        handleResetPopup();
      } else {
        const data = {
          id: userEdit.id,
          ...payload.user,
        };
        onSubmitAdminQuorum?.(data, SCREEN_USER.ADMIN_QUORUM_EDIT);
        handleResetPopup();
      }
    });
  };

  const handleResetPopup = () => {
    setRequiredApprover(false);
    setCountryCode('+65');
    setIsOpen(false);
    setValueAddress(defaultAddress);
    form.resetFields();
  };

  const handleEditUser = async (payload: EditUserRequest) => {
    try {
      const { newUser, ...dataCheckQuorum } = payload;
      const res = await checkQuorum(userEdit.userId, dataCheckQuorum);

      if (res) {
        return;
      }
      dispatch(
        customersActions.editUserRequest({ userId: userEdit.userId, payload }),
      );
    } catch (error) {}
  };

  const checkQuorum = async (userId: string, payload: CheckQuorumRequest) => {
    try {
      const res = await checkQuorumByUser(userId, payload);
      let txtMsgErrorRole,
        txtMsgErrorVaults = '';

      const { accountLVCheck, vaultLVCheck } = res;

      // check  Account Level
      if (accountLVCheck && accountLVCheck.isRequiredApprover === true) {
        txtMsgErrorRole =
          'This user is a required approver in account admin policy.';
      }
      if (vaultLVCheck && accountLVCheck.isValidNumUserInQuorum === false) {
        txtMsgErrorRole =
          'You cannot change role due to unmet minimum quorum size for account admin policy.';
      }

      // check Vault Level
      if (
        vaultLVCheck &&
        vaultLVCheck.length > 0 &&
        vaultLVCheck.some(vault => vault.isRequiredApprover === true)
      ) {
        const vaultsName = vaultLVCheck
          .filter(item => item.isRequiredApprover === true)
          .map(vault => vault.vaultName)
          .join(', ');

        txtMsgErrorVaults = `User is a required approver in ${vaultsName}.`;
        txtMsgErrorRole = `This user is a required approver in ${vaultsName}.`;
      }

      if (
        vaultLVCheck &&
        vaultLVCheck.length > 0 &&
        vaultLVCheck.some(vault => vault.isValidNumUserInQuorum === false)
      ) {
        const vaultsName = vaultLVCheck
          .filter(item => item.isValidNumUserInQuorum === false)
          .map(vault => vault.vaultName)
          .join(', ');

        txtMsgErrorVaults = `You cannot remove access due to unmet minimum quorum size for ${vaultsName}.`;
        txtMsgErrorRole = `You cannot change role due to unmet minimum quorum size for ${vaultsName}.`;
      }

      if (!!txtMsgErrorVaults || !!txtMsgErrorRole) {
        form.setFields([
          {
            name: 'vaultIds',
            errors: [txtMsgErrorVaults],
          },
        ]);

        form.setFields([
          {
            name: 'userRole',
            errors: [txtMsgErrorRole],
          },
        ]);

        return true;
      }
      return false;
    } catch (error) {}
  };

  const onClickCancel = () => {
    handleResetPopup();
  };

  const handleFieldsChange = useCallback(() => {
    const values = form.getFieldsValue();
    const { middleName, vaultIds, requiredApprover, ...valuesRequired } =
      values;
    const hasEmpty = Object.values(valuesRequired).some((value: any) => !value);
    const fieldsError = form.getFieldsError();
    const hasError = fieldsError.some(({ errors }) =>
      errors.some(item => !!item),
    );
    setIsDisabled(hasError || hasEmpty);
  }, [form]);

  const handleChangeDocumentType = useCallback(() => {
    setInitDataForm({
      ...initDataForm,
      passportNumber: '',
      passportIssuedCountry: '',
      idCard: '',
      idCardIssuedCountry: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setShowPopLocation(false);
  };

  const handleAddAddress = values => {
    if (values) {
      setValueAddress(values);
      const text = getTextAddress(values);
      form.setFieldsValue({ address: text });
      setShowPopLocation(false);
    }
  };

  const renderPopupExisted = useMemo(() => {
    return (
      <BasicModal
        destroyOnClose={true}
        className={styles.existed__popup}
        visible={isShowPopupError}
        centered={true}
        onCancel={() => setIsShowPopupError(false)}
        maskClosable={true}
        closable={false}
        width={404}
        children={
          <>
            <div className={styles.title}>
              This user already exists in Rakkar
            </div>
            <div className={styles.description}>
              Please recheck if the user information is correct.
            </div>
            <div className={styles.footer} key="confirm">
              <BasicButton
                title="OK"
                type="filled"
                className={styles.confirm}
                onClick={() => setIsShowPopupError(false)}
              />
            </div>
          </>
        }
        footer={false}
      />
    );
  }, [isShowPopupError]);

  const contentModal = (
    <Form
      preserve={false}
      form={form}
      layout="vertical"
      name="bussinessForm"
      validateMessages={validateMessages()}
      className={styles.user__modal}
      onFinish={onSubmit}
      initialValues={initDataForm}
      // onValuesChange={onValuesChange}
      onFieldsChange={handleFieldsChange}
    >
      <div className={styles.title} style={{ marginTop: 0 }}>
        Identification information
      </div>
      <Row gutter={16}>
        <Col span={12} data-testid="first-name">
          <BasicInput
            label="First name"
            name="firstName"
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  const valueTrim = value.trim();
                  const checkValidValue = validateRegex(
                    value,
                    REGEX_ALLOW_AZ_09_SPACE,
                  );
                  if (value.length && !valueTrim) {
                    return Promise.reject('First name is required');
                  }
                  if (value && value.length > 50) {
                    return Promise.reject('Max of 50 characters');
                  }
                  if (value && checkValidValue) {
                    return Promise.reject(msg);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          />
        </Col>
        <Col span={12} data-testid="middle-name">
          <BasicInput
            label="Middle name"
            name="middleName"
            rules={[
              {
                validator: (_, value) => {
                  const checkValidValue = validateRegex(
                    value,
                    REGEX_ALLOW_AZ_09_SPACE,
                  );

                  if (value && value.length > 50) {
                    return Promise.reject('Max of 50 characters');
                  }
                  if (value && checkValidValue) {
                    return Promise.reject(msg);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} data-testid="last-name">
          <BasicInput
            label="Last name"
            name="lastName"
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  const valueTrim = value.trim();
                  const checkValidValue = validateRegex(
                    value,
                    REGEX_ALLOW_AZ_09_SPACE,
                  );
                  if (value.length && !valueTrim) {
                    return Promise.reject('Last name is required');
                  }
                  if (value && value.length > 50) {
                    return Promise.reject('Max of 50 characters');
                  }
                  if (value && checkValidValue) {
                    return Promise.reject(msg);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          />
        </Col>
      </Row>
      <div className={styles.title}>Role</div>
      <Row gutter={16}>
        <Col span={12} className={styles.user__role}>
          <Form.Item
            data-testid="user-role"
            label="User role"
            name="userRole"
            rules={[{ required: true, message: 'User role is required' }]}
          >
            <Radio.Group
              onChange={e => {
                setUserRole(e.target.value);
              }}
              defaultValue={userRole}
              className={styles.role__group}
            >
              {listRoles.map(role => {
                return (
                  <Radio.Button
                    key={role.value}
                    value={role.value}
                    className={styles.role__group__item}
                  >
                    {role.text}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          {userRole === RoleQuorum.ADMIN && (
            <>
              <span className={styles.label}>Admin</span>
              <div className={styles.input__bg}>
                <Form.Item
                  name="requiredApprover"
                  noStyle
                  valuePropName="checked"
                >
                  <RakSwitch
                    disabled={fromScreen === SCREEN_USER.EDIT_USER}
                    onChange={e => onSwitch(e)}
                    checked={requiredApprover}
                  />
                </Form.Item>
                <span
                  className={clsx(styles.input__label, {
                    [styles['input__label--active']]: requiredApprover,
                  })}
                >
                  Required Approver
                </span>
              </div>
            </>
          )}
        </Col>
      </Row>
      <div className={styles.title}>Legal information</div>

      <Row gutter={16}>
        <Col span={12}>
          <BasicSelect
            options={documentTypes}
            rules={[{ required: true }]}
            placeholder="Select..."
            label="Document Type"
            name="documentType"
            allowClear
            dataTestId="documentType"
            onChange={handleChangeDocumentType}
          />
        </Col>
      </Row>

      {documentTypeValue === DOCUMENT_TYPE.PASSPORT && (
        <Row gutter={16}>
          <Col span={12} data-testid="passport-number">
            <BasicInput
              label="Passport number"
              name="passportNumber"
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    const checkValidValue = validateRegex(
                      value,
                      REGEX_ALLOW_AZ_09,
                    );
                    if (value && value.length > 50) {
                      return Promise.reject('Max of 50 characters');
                    }
                    if (value && checkValidValue) {
                      return Promise.reject(msgForPassport);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          </Col>
          <Col span={12} data-testid="passport-issued-country">
            <BasicSelect
              options={countryData}
              rules={[{ required: true }]}
              placeholder="Select..."
              label="Country of issued passport"
              name="passportIssuedCountry"
              allowClear
              keyValue="code"
              labelValue="name"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
        </Row>
      )}

      {documentTypeValue === DOCUMENT_TYPE.ID_CARD && (
        <Row gutter={16}>
          <Col span={12} data-testid="idCard-number">
            <BasicInput
              label="ID number"
              name="idCardNumber"
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    const checkValidValue = validateRegex(
                      value,
                      REGEX_ALLOW_AZ_09,
                    );
                    if (value && value.length > 50) {
                      return Promise.reject('Max of 50 characters');
                    }
                    if (value && checkValidValue) {
                      return Promise.reject(msgForPassport);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          </Col>
          <Col span={12} data-testid="idCard-issued-country">
            <BasicSelect
              options={countryData}
              rules={[{ required: true }]}
              placeholder="Select..."
              label="Country of issued ID card"
              name="idCardIssuedCountry"
              allowClear
              keyValue="code"
              labelValue="name"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col span={12} data-testid="birth">
          <Form.Item
            label="Date of birth"
            className="birth"
            rules={[{ required: true }]}
            name="dateOfBirth"
          >
            <DatePicker
              format={FORMAT_DATE.DEFAULT}
              disabledDate={current => current.isAfter(moment())}
            />
          </Form.Item>
        </Col>
        <Col span={12} data-testid="nationality">
          <BasicSelect
            options={countryData}
            rules={[{ required: true }]}
            placeholder="Select..."
            label="Nationality"
            name="nationality"
            allowClear
            keyValue="code"
            labelValue="name"
            showSearch
            optionFilterProp="children"
            defaultValue="SG"
            initialValueForm={'SG'}
            filterOption={(input, option) =>
              (option.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BasicInput
            label="Address"
            name="address"
            rules={[{ required: true }]}
            placeholder="Select..."
            readOnly
            prefix={
              <SvgIcon name="address" width={16} height={16} color="#93939B" />
            }
            onClick={() => {
              setShowPopLocation(true);
            }}
            dataTestId="address"
          />
        </Col>
      </Row>

      <div className={styles.title}>Contact information</div>
      <Row gutter={16}>
        <Col span={12} className={styles.phone}>
          <Form.Item
            label={
              <>
                Phone number <span className={styles.required}>*</span>
              </>
            }
            name="phoneNumber"
            rules={[
              {
                validator: (_, value) => {
                  const checkPhone = isValidPhoneNumber(value || '');
                  if (value === undefined) {
                    return Promise.reject('Phone number is required');
                  }
                  if (value !== undefined && !checkPhone) {
                    return Promise.reject('Invalid phone number');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInput
              onChange={value => {
                if (value) {
                  deboundCheckUniqueByField({
                    phoneNumber: value,
                    ...(isEditUser ? { userId: userEdit?.userId } : {}),
                  });
                }
              }}
              defaultCountry="SG"
              addInternationalOption={false}
              displayInitialValueAsLocalNumber
              className={`${!!showErrPhone && styles.ipErr}`}
              dataTestId="phoneNumber"
              onCountryChange={(value: any) => {
                if (value) {
                  const code = getCountryCallingCode(value);
                  setCountryCode(`+${code}`);
                } else {
                  setCountryCode('');
                }
              }}
            />
          </Form.Item>
          <div className={styles.code}>{countryCode}</div>
        </Col>
        <Col span={12} data-testid="email">
          <BasicInput
            label="Email"
            name="email"
            rules={[{ required: true, type: 'email' }, { max: 100 }]}
            onChange={e => {
              const email = e.target.value;
              if (!!email) {
                deboundCheckUniqueByField({
                  email,
                  ...(isEditUser ? { userId: userEdit?.userId } : {}),
                });
              }
            }}
          />
        </Col>
      </Row>

      {!!customerId && (
        <>
          <div className={styles.title}>Assign into Vaults</div>
          <Row>
            <Col span={24}>
              <div className={styles.mutiple__select}>
                <SelectVault
                  customerId={customerId}
                  initVault={vaultIds}
                  changeVault={() => {
                    form.setFields([
                      {
                        name: 'vaultIds',
                        errors: [],
                      },
                    ]);
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );

  const onSwitch = e => {
    setRequiredApprover(e);
  };

  const handleClosedPopup = () => {
    setIsOpen(false);
    form.resetFields();
  };

  return (
    <>
      <BasicModal
        destroyOnClose={true}
        onCancel={onClickCancel}
        closable={false}
        visible={isOpen}
        maskClosable={true}
        dataTestId="modal-add-user"
        width={600}
        className={styles.popupUser}
        footer={[
          <RButton
            key="Cancel"
            buttonType="cancel"
            content="Cancel"
            width={83}
            height={36}
            onClick={onClickCancel}
            data-testid="cancel-button"
          />,
          <RButton
            key="Submit"
            data-testid="submit-button"
            htmlType="submit"
            buttonType="submit"
            content={
              fromScreen === SCREEN_USER.ADD_USER ||
              fromScreen === SCREEN_USER.EDIT_USER
                ? 'Submit'
                : 'Add'
            }
            width={120}
            height={36}
            onClick={onSubmit}
            disable={isDisabled || isLoadingCheckDocumentType}
            loading={isLoadingSubmit}
          />,
        ]}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            <span className={styles.top__title}>{title(fromScreen)}</span>
            <SvgIcon
              onClick={() => handleClosedPopup()}
              className={styles.iconClosed}
              name="close"
              fill="#62739D"
              width={16}
              height={16}
            />
          </div>
          <div className={styles.border}></div>
          {contentModal}
        </div>
      </BasicModal>
      {showPopLocation && (
        <ModalLocation
          openModal={showPopLocation}
          handleCancel={handleCancel}
          keyForm={AddressType.ADDRESS}
          onCreate={handleAddAddress}
          initValue={valueAddress}
        />
      )}
      {renderPopupExisted}
    </>
  );
};

export default PopupAddOrEditUser;
