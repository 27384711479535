import { Col, List, Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import clsx from 'clsx';
import { STATUS_REQUEST } from 'constants/index';
import CustomerInfo from '../UnlockUser/CustomerInfo';
import styles from './index.module.scss';
import TextInfor from 'app/components/common/TextInfor';

type Props = {
  requestDetail: any;
};
interface TextInfoProps {
  label: string;
  text: any;
  span?: number;
  dataTestId?: string;
  isStrike?: boolean;
}
interface ListItem {
  externalAssetId: string;
  isSubscribeStaking: boolean;
  assetName: string;
  networkId: string;
  networkName: string;
  image: string;
}
interface ListProps {
  list: ListItem[];
}

const ProductionSubscriptionRequest = ({ requestDetail }: Props) => {
  const isApprovedReq = requestDetail?.status === STATUS_REQUEST.APPROVED;
  const isRejectedReq = requestDetail?.status === STATUS_REQUEST.REJECTED;

  const approveOrRejectReq = (isApprovedReq, isRejectedReq: boolean) => {
    return (
      (isApprovedReq || isRejectedReq) && (
        <div
          className={`${styles.status} ${
            isApprovedReq ? styles.approved : styles.rejected
          }`}
        >
          <div>
            <SvgIcon name={isApprovedReq ? 'white-approve' : 'white-reject'} />
          </div>
          <div>
            {isApprovedReq ? STATUS_REQUEST.APPROVED : STATUS_REQUEST.REJECTED}
          </div>
        </div>
      )
    );
  };

  const TextInfo = ({
    span = 24,
    label,
    text,
    dataTestId,
    isStrike,
  }: TextInfoProps) => {
    return (
      <Col span={span}>
        <div className={styles.content_text__item}>
          <p className={styles.content_text__label}>{label}</p>
          <p
            className={clsx(styles.content_text__text, {
              [styles.content_text__text__strike]: isStrike,
            })}
            data-testid={dataTestId}
          >
            {text}
          </p>
        </div>
      </Col>
    );
  };

  const ListCoin = ({ list }: ListProps) => {
    const showEmptyScreen = list.length <= 0;
    let locale = {
      emptyText: !showEmptyScreen ? (
        <div className={styles.empty}>
          <div className={styles.icon}>
            <SvgIcon name="error-search" width={80} height={80} />
          </div>
          <div className={styles.title}>No matching search results</div>
          <div className={styles.content}>Try another keyword</div>
        </div>
      ) : (
        <div>{'-'}</div>
      ),
    };
    return (
      <List
        grid={{
          gutter: 11,
          column: 0,
        }}
        bordered={false}
        dataSource={list}
        renderItem={item => (
          <List.Item>
            <div className={styles.content_text__item_list}>
              <img
                className={styles.content_text__item_list__img}
                src={item.image}
                alt=""
                width={16}
                height={16}
              />
              <div className={styles.content_text__item_list__text}>
                {item.networkName}
              </div>
            </div>
          </List.Item>
        )}
        locale={locale}
      />
    );
  };

  const stakingInfo = () => {
    return (
      <>
        <div className={styles['staking-inform']}>
          <div>
            <SvgIcon name="user-info" width={16} height={16} />
          </div>
          <div className={styles['staking-inform__title']}>Staking</div>
        </div>
        {requestDetail?.newSubscription?.length !== 0 && (
          <Row>
            <TextInfor
              label="New subscription"
              text={<ListCoin list={requestDetail.newSubscription} />}
            />
          </Row>
        )}
        {requestDetail?.unsubscription?.length !== 0 && (
          <Row>
            <TextInfo
              label="Unsubscription"
              text={<ListCoin list={requestDetail.unsubscription} />}
              isStrike={true}
            />
          </Row>
        )}
        {requestDetail?.unchangedSubscription?.length !== 0 && (
          <Row>
            <TextInfor
              label="Unchanged subscription"
              text={<ListCoin list={requestDetail.unchangedSubscription} />}
            />
          </Row>
        )}
      </>
    );
  };

  return (
    <div
      className={styles.container}
      data-testid="update-production-subscription-request"
    >
      <SvgIcon name="stake" width={32} height={32} />
      <div className={styles.title} data-testId="title">
        <div>Update product subscription request</div>
        {approveOrRejectReq(isApprovedReq, isRejectedReq)}
      </div>
      <CustomerInfo requestDetail={requestDetail} />
      <div className={styles.content}>{stakingInfo()}</div>
    </div>
  );
};

export default ProductionSubscriptionRequest;
