import Instance from 'app.v2/config/instance';
import { PREFIX_API } from 'constants/index';

import {
  GetCfg_countryRequestParamDTO,
  GetCfg_countryResponseDTO,
  ICreateCfg_country,
  ICfg_countryDetail,
} from 'interfaces/cfg_country';
export function getCfg_countryRequest(
  params?: GetCfg_countryRequestParamDTO,
): Promise<GetCfg_countryResponseDTO> {
  return Instance.get(`/${PREFIX_API.CFG_COUNTRY}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailCfg_country(
  cfg_countryId: string,
): Promise<ICfg_countryDetail> {
  return Instance.get(`/${PREFIX_API.CFG_COUNTRY}/${cfg_countryId}`);
}
export function createCfg_countryRequest(
  cfg_country: ICreateCfg_country,
): Promise<GetCfg_countryResponseDTO> {
  return Instance.post(`/${PREFIX_API.CFG_COUNTRY}`, cfg_country);
}
export function updateCfg_countryRequest(
  cfg_country: ICreateCfg_country,
): Promise<GetCfg_countryResponseDTO> {
  const payload: any = { ...cfg_country };
  const id = cfg_country.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.CFG_COUNTRY}/${id}`, payload);
}
export function getCfg_countryRequestPaginationConfig(): Promise<GetCfg_countryResponseDTO> {
  return Instance.get(`/${PREFIX_API.CFG_COUNTRY}/pagination-config`);
}
