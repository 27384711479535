import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';

import {
  CategoryGroupByRequestParamsDTO,
  GetAuditLogListRequestParamDTO,
  GetAuditLogListResponseDTO,
  GetCategoryDTO,
  GroupByUserListDTO,
  GroupByUserRequestParamsDTO,
  IAuditLogDetail,
} from 'interfaces/auditLogList.interface';

export function getAuditLogListRequest(
  params?: GetAuditLogListRequestParamDTO,
): Promise<GetAuditLogListResponseDTO> {
  return Instance.get(`/${PREFIX_API.AUDIT}/audit-log`, {
    params,
  });
}

export function getListUserGroupByCustomer(
  params?: GroupByUserRequestParamsDTO,
): Promise<GroupByUserListDTO> {
  return Instance.get(`/${PREFIX_API.AUTH}/account/group-user`, {
    params,
  });
}

export function getListGroupByField(
  params: CategoryGroupByRequestParamsDTO,
): Promise<GetCategoryDTO> {
  return Instance.get(
    `/${PREFIX_API.AUDIT}/audit-log/category/group-by-field`,
    { params },
  );
}

export function getDetailAuditLog(
  auditlogId: string,
): Promise<IAuditLogDetail> {
  return Instance.get(`/${PREFIX_API.AUDIT}/audit-log/${auditlogId}`);
}

export function downloadAuditLog(params): Promise<any> {
  return Instance.get(`/${PREFIX_API.AUDIT}/audit-log/logging/export`, {
    params: { ...params },
  });
}
