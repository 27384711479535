import { Form, FormInstance } from 'antd';
import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import RakSwitch from 'app/components/common/RakSwitch';
import clsx from 'clsx';
import { RoleUser } from 'enum/userList';
import { RequestStatus } from 'interfaces/request';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectRoles } from 'store/slices/auth';
import { selectOpenPopUpOnGoing } from 'store/slices/common';
import { customersActions, selectUpdateStaking } from 'store/slices/customers';
import { checkRoles } from 'utils/common';
import ConfirmStaking from '../ConfirmStaking';
import styles from './index.module.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (stt: boolean) => void;
  staking: any;
  id: string;
};

const ModalEditStaking = ({ isOpen, setIsOpen, staking, id }: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);
  const updateStakingRequest = useAppSelector(selectUpdateStaking);
  const loading = updateStakingRequest?.status === RequestStatus.Loading;
  const [isDisabled, setIsDisabled] = useState(true);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [updateFormValues, setUpdateFormValues] = useState<any>([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const role = useAppSelector(selectRoles);
  const [isAllow, setAllow] = useState(false);
  const isOperation = checkRoles(RoleUser.OPERATION, role);
  const open = useAppSelector(selectOpenPopUpOnGoing);

  useEffect(() => {
    if (updateStakingRequest.status === RequestStatus.Failed || !isOperation) {
      setAllow(false);
      setIsOpenConfirm(true);
    } else {
      setAllow(true);
    }
  }, [updateStakingRequest.status, isOperation]);

  const onClickCancel = () => {
    setIsOpen(false);
    if (formRef.current) {
      formRef.current.resetFields();
    }
  };

  useEffect(() => {
    if (open) {
      setAllow(false);
      setIsOpenConfirm(true);
    }
    setIsOpenConfirm(false);
  }, [open]);

  const onSubmitStaking = () => {
    setIsOpenConfirm(true);
  };

  const handleConfirm = () => {
    if (updateFormValues) {
      dispatch(
        customersActions.updateStakingRequest({
          userId: id,
          payload: { editSubscribeStaking: updateFormValues },
        }),
      );
    }
  };

  const onValuesChange = () => {
    const updateData = Object.keys(form.getFieldsValue()).map(symbol => ({
      symbol,
      isSubscribeStaking: form.getFieldsValue()[symbol],
    }));
    setUpdateFormValues(updateData);
  };

  useEffect(() => {
    const changed = _.isEqual(initialFormValues, form.getFieldsValue());
    setIsDisabled(changed ? true : false);
  }, [form.getFieldsValue()]);

  useEffect(() => {
    const newFormValues = {};
    staking.forEach((item, _) => {
      newFormValues[item?.symbol] = item.isStaking;
    });
    setInitialFormValues(newFormValues);
  }, [staking, isOpen]);

  useEffect(() => {
    if (updateStakingRequest.status === RequestStatus.Success) {
      setIsOpen(false);
    }
  }, [updateStakingRequest.status]);

  return (
    <>
      <BasicModal
        destroyOnClose={true}
        className={styles.modal}
        visible={isOpen}
        title="Staking"
        centered={true}
        onCancel={onClickCancel}
        maskClosable={true}
        width={656}
        footer={null}
      >
        <Form
          className={styles.container}
          form={form}
          layout="vertical"
          name="staking"
          initialValues={initialFormValues}
          onFinish={() => onSubmitStaking()}
          onValuesChange={() => onValuesChange()}
          ref={formRef}
          data-testid="staking-asset-form"
        >
          <div className={styles.input__staking}>
            {staking &&
              staking.map((stakingToggle, _) => {
                return (
                  <div className={styles.input__bg}>
                    <Form.Item
                      valuePropName="checked"
                      name={stakingToggle?.symbol}
                    >
                      <RakSwitch checked={stakingToggle?.isStaking} />
                    </Form.Item>
                    <span
                      className={clsx(styles.input__label, {
                        [styles['input__label--active']]:
                          !!staking[stakingToggle.symbol],
                      })}
                    >
                      <img src={stakingToggle.image} alt="staking_icon" />{' '}
                      {stakingToggle.symbol}
                    </span>
                  </div>
                );
              })}
          </div>
          <div className={styles.button}>
            <BasicButton
              title="Cancel"
              type="outline"
              onClick={onClickCancel}
              dataTestId="cancel"
            />
            <BasicButton
              title="Submit"
              type="filled"
              className={styles.confirm}
              loading={loading}
              htmlType="submit"
              disabled={isDisabled}
              dataTestId="submit"
            />
          </div>
        </Form>
      </BasicModal>
      <ConfirmStaking
        isOpen={isOpenConfirm}
        setIsOpen={setIsOpenConfirm}
        isAllowedToStake={isAllow}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default ModalEditStaking;
