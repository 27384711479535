import { Col, DatePicker, Divider, Form, Row } from 'antd';
import AUC from 'app/components/AUC';
import BasicButton from 'app/components/common/BasicButton';
import BasicInput from 'app/components/common/BasicInput';
import BasicModal from 'app/components/common/BasicModal';
import { SvgIcon } from 'app/components/SvgIcon';
import { FORMAT_DATE } from 'constants/index';
import { WalletFeeInformation } from 'interfaces/businessInformation';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import {
  formatterNumberThousand,
  revertFormat,
  validateMessages,
} from 'utils/common';
import { handleNumberKeyDown } from 'utils/event.utils';
import styles from '../index.module.scss';

const { RangePicker } = DatePicker;

type FeeInformationDialogProps = {
  title: string;
  feeInfo: WalletFeeInformation;
  open: boolean;
  onClose: () => void;
  onConfirm: (values: WalletFeeInformation) => void;
  unavailablePeriod: {
    effectiveFrom: string | undefined;
    effectiveTo: string | undefined;
  }[];
};

export const FeeInformationDialog = ({
  title,
  feeInfo,
  open,
  onClose,
  onConfirm,
  unavailablePeriod,
}: FeeInformationDialogProps) => {
  const [form] = Form.useForm();
  const [isTableAUCError, setIsTableAUCError] = useState(false);
  const [item, setItem] = useState<WalletFeeInformation>(feeInfo);

  const onChangeInput = (e: any, name: string) => {
    const value = formatterNumberThousand(e.target.value);
    form.setFieldsValue({ [name]: value });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const handleConfirm = async () => {
    const values = await form.validateFields();
    if (isTableAUCError) return;

    const effectiveFrom = values.effectiveMonth[0]
      ? moment(values.effectiveMonth[0])
          .startOf('month')
          .format(FORMAT_DATE.DATE_YMD)
      : undefined;
    const effectiveTo = values.effectiveMonth[1]
      ? moment(values.effectiveMonth[1])
          .endOf('month')
          .format(FORMAT_DATE.DATE_YMD)
      : undefined;
    const assetUnderCustody = values.assetUnderCustody.map(
      ({ from, to, point, key }) => {
        return {
          key,
          from: !!from ? revertFormat(from) : 0,
          to: !!to ? revertFormat(to) : null,
          point: revertFormat(point),
        };
      },
    );

    const feeInformation = {
      id: feeInfo.id,
      effectiveFrom,
      effectiveTo,
      initialSetupFee: 0,
      minimumMonthlyFee: revertFormat(values.minimumMonthlyFee),
      minimumAUCBalance: revertFormat(values.minimumAUCBalance),
      assetUnderCustody,
    };

    onConfirm(feeInformation);
  };

  useEffect(() => {
    if (open && feeInfo) {
      const effectiveMonth: [Moment | null, Moment | null] =
        !feeInfo.effectiveFrom
          ? [null, null]
          : feeInfo.effectiveTo
          ? [
              moment(feeInfo.effectiveFrom).utc(),
              moment(feeInfo.effectiveTo).utc(),
            ]
          : [moment(feeInfo.effectiveFrom).utc(), null];

      setItem(feeInfo);
      form.setFieldsValue({
        effectiveMonth,
        minimumMonthlyFee: formatterNumberThousand(feeInfo.minimumMonthlyFee),
        minimumAUCBalance: formatterNumberThousand(feeInfo.minimumAUCBalance),
        assetUnderCustody: feeInfo.assetUnderCustody,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, feeInfo]);

  return (
    <BasicModal
      title={title}
      visible={open}
      onCancel={handleCancel}
      footer={
        <Row style={{ gap: 12, justifyContent: 'flex-end', padding: 20 }}>
          <>
            <BasicButton title="Cancel" type="outline" onClick={handleCancel} />
            <BasicButton
              title="Confirm"
              type="filled"
              onClick={handleConfirm}
            />
          </>
        </Row>
      }
      width={656}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        validateMessages={validateMessages()}
      >
        <Divider orientationMargin="0" orientation="left">
          <span className={styles.info__title}>
            Effective period
            <span className={styles.info__title_month__label}>*</span>
          </span>
        </Divider>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Form.Item
              name="effectiveMonth"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || !value?.[0]) {
                      return Promise.reject(
                        'Effective period start month is required',
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <RangePicker
                className={styles.rangePicker}
                picker="month"
                allowEmpty={[false, true]}
                format="MMMM YYYY"
                disabledDate={current => {
                  const isCollapsed = () => {
                    return unavailablePeriod.some(i => {
                      if (i.effectiveFrom && i.effectiveTo) {
                        return current
                          .startOf('day')
                          .isBetween(
                            moment(i.effectiveFrom, FORMAT_DATE.DATE_YMD),
                            moment(i.effectiveTo, FORMAT_DATE.DATE_YMD),
                            'month',
                            '[]',
                          );
                      }

                      if (i.effectiveFrom && !i.effectiveTo) {
                        return current
                          .startOf('day')
                          .isSame(i.effectiveFrom, 'month');
                      }

                      return false;
                    });
                  };

                  return (
                    current.startOf('day').isBefore(moment().add(1, 'month')) ||
                    isCollapsed()
                  );
                }}
                placeholder={['Start month', 'End month (Optional)']}
                separator={
                  <SvgIcon name="grey-right-arrow" width={12} height={12} />
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientationMargin="0" orientation="left">
          <span className={styles.info__title}>Initial & monthly fee</span>
        </Divider>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <BasicInput
              onKeyDown={handleNumberKeyDown}
              suffix={<span className={styles.suffix__input}>USD</span>}
              label="Minimum monthly fee (USD)"
              name="minimumMonthlyFee"
              onChange={(e: any) => onChangeInput(e, 'minimumMonthlyFee')}
              rules={[{ required: true }]}
              maxLength={50}
              useLabel={true}
            />
          </Col>
          <Col span={12}>
            <BasicInput
              onKeyDown={handleNumberKeyDown}
              suffix={<span className={styles.suffix__input}>USD</span>}
              label="Minimum AUC balance (USD)"
              name="minimumAUCBalance"
              onChange={(e: any) => onChangeInput(e, 'minimumAUCBalance')}
              rules={[{ required: true }]}
              maxLength={50}
              useLabel={true}
            />
          </Col>
        </Row>

        <Divider orientationMargin="0" orientation="left">
          <span className={styles.info__title}>Asset under custody</span>
        </Divider>
        <Form.Item name="assetUnderCustody">
          <AUC
            initValue={[...item.assetUnderCustody]
              .sort((a, b) => +(a.id || 1) - +(b.id || 1))
              .map(({ id, key, from, to, point }) => ({
                key: key || id,
                from: formatterNumberThousand(from),
                to: formatterNumberThousand(to),
                point: formatterNumberThousand(point),
              }))}
            onSave={(aucs: any) => {
              setItem(prev => ({ ...prev, assetUnderCustody: aucs }));
              form.setFieldsValue({ assetUnderCustody: aucs });
            }}
            onTriggerValid={isHasError => {
              setIsTableAUCError(isHasError);
            }}
            hideHeader={true}
          />
        </Form.Item>
      </Form>
    </BasicModal>
  );
};
