import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';

import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  TransactionParamsRequest,
  TransactionsResponse,
} from 'interfaces/transactions';
import { AppState } from '../..';

interface TransactionsState {
  params: TransactionParamsRequest;
  transactions: ResponseData<TransactionsResponse>;
  canLoadMore: boolean;
  assetParams: TransactionParamsRequest;
  canLoadMoreAssets: boolean;
  assets: ResponseData<any>;
  isReviewRequiredOnly: boolean;
}

export function getDefaultTransactionRequestParams() {
  return {
    [Params.Page]: 1,
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.SortBy]: Sort.CREATED_DATE,
    [Params.Sort]: SortDirection.DESC,
  };
}
export const defaultAssetParams = {
  limit: 10,
  page: 1,
  offset: 0,
  keyword: '',
};

const initialState: TransactionsState = {
  params: getDefaultTransactionRequestParams(),
  transactions: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  assetParams: defaultAssetParams,
  canLoadMoreAssets: true,
  assets: {
    status: RequestStatus.Idle,
  },
  isReviewRequiredOnly: false,
};

const getTransactionListRequest: CaseReducer<
  TransactionsState,
  PayloadAction<any>
> = (state: TransactionsState) => {
  state.transactions.status = RequestStatus.Loading;
};

const getTranscactionListSuccess: CaseReducer<
  TransactionsState,
  PayloadAction<TransactionsResponse>
> = (
  state: TransactionsState,
  { payload }: PayloadAction<TransactionsResponse>,
) => {
  state.transactions.status = RequestStatus.Success;
  state.transactions.data = payload;
};

const getTranscactionListFailed: CaseReducer<TransactionsState> = (
  state: TransactionsState,
) => {
  state.transactions.status = RequestStatus.Failed;
};

const appendTransactions: CaseReducer<TransactionsState, PayloadAction<any>> = (
  state: TransactionsState,
  { payload }: PayloadAction<any>,
) => {
  if (state.transactions?.data?.transactions) {
    state.transactions.status = RequestStatus.Success;
    state.transactions.data.transactions = [...payload];
  }
};

const setCanLoadMore = (
  state: TransactionsState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};

const resetTransactionList: CaseReducer<TransactionsState> = (
  state: TransactionsState,
) => {
  // state.params = getDefaultTransactionRequestParams();
  state.transactions = {
    status: RequestStatus.Idle,
  };
};

const resetParamsTransaction: CaseReducer<TransactionsState> = (
  state: TransactionsState,
) => {
  state.params = getDefaultTransactionRequestParams();
};

const setTransactionFilterParams = (
  state: TransactionsState,
  { payload }: PayloadAction<TransactionParamsRequest>,
) => {
  state.params = payload;
};

const getAssetsRequest: CaseReducer<
  TransactionsState,
  PayloadAction<{ params: any }>
> = (state: TransactionsState) => {
  state.assets.status = RequestStatus.Loading;
};

const getAssetsSuccess: CaseReducer<TransactionsState, PayloadAction<any>> = (
  state: TransactionsState,
  { payload }: PayloadAction<any>,
) => {
  state.assets.status = RequestStatus.Success;
  state.assets.data = payload;
};

const getAssetsFailed: CaseReducer<TransactionsState> = (
  state: TransactionsState,
) => {
  state.assets.status = RequestStatus.Failed;
};

const setCanLoadMoreAssets = (
  state: TransactionsState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMoreAssets = payload;
};

const setIsReviewRequiredOnly = (
  state: TransactionsState,
  { payload }: PayloadAction<any>,
) => {
  state.isReviewRequiredOnly = payload;
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getTransactionListRequest,
    getTranscactionListSuccess,
    getTranscactionListFailed,

    appendTransactions,

    setCanLoadMore,

    resetTransactionList,

    setTransactionParam<K extends keyof TransactionParamsRequest>(
      state: TransactionsState,
      action: PayloadAction<{ key: K; value: TransactionParamsRequest[K] }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
    },
    setTransactionFilterParams,
    resetParamsTransaction,

    getAssetsRequest,
    getAssetsSuccess,
    getAssetsFailed,

    setCanLoadMoreAssets,

    setIsReviewRequiredOnly,

    setAssetParams<K extends keyof TransactionParamsRequest>(
      state: TransactionsState,
      action: PayloadAction<{ key: K; value: TransactionParamsRequest[K] }>,
    ) {
      state.assetParams[action.payload.key] = action.payload.value;
    },
  },
});

// Actions
export const transactionsActions = transactionsSlice.actions;

// Reducer
export const transactionsReducer = transactionsSlice.reducer;

// Selectors
export const selectTransactionList = (state: AppState) =>
  state.transactions?.transactions;

export const selectparamsTransactionList = (state: AppState) =>
  state.transactions?.params;

export const selectCanLoadMoreTransactions = (state: AppState) =>
  state.transactions?.canLoadMore;

export const selectAssetParams = (state: AppState) =>
  state.transactions.assetParams;

export const selectAsset = (state: AppState) => state.transactions.assets;

export const selectCanLoadMoreAssets = (state: AppState) =>
  state.transactions.canLoadMoreAssets;

export const selectIsReviewRequiredOnly = (state: AppState) =>
  state.transactions.isReviewRequiredOnly;
