import BasicButton from 'app/components/common/BasicButton';
import BasicModal from 'app/components/common/BasicModal';
import styles from './index.module.scss';

interface IPropsErrorModal {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  title: string;
  contentError: React.ReactNode;
  handleConfirm?: () => void;
}
const PopupShowCaseError = ({
  isOpen,
  setIsOpen,
  handleConfirm,
  ...props
}: IPropsErrorModal) => {
  const content = <div>{props.contentError}</div>;
  return (
    <BasicModal
      destroyOnClose={true}
      visible={isOpen}
      children={content}
      title={props.title}
      centered={true}
      onCancel={() => setIsOpen(false)}
      maskClosable={false}
      closable={false}
      width={480}
      footer={[
        <div>
          <BasicButton
            title="OK"
            type="filled"
            onClick={() => handleConfirm?.()}
          />
        </div>,
      ]}
      className={styles.popup}
    />
  );
};

export default PopupShowCaseError;
