import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';

export function getTransactionListRequest(params): Promise<any> {
  return Instance.post(`/${PREFIX_API.TRANSACTION}/transactions/v1`, params);
}

export function exportTransactionListRequest(params): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.TRANSACTION}/transactions/export`,
    params,
    {
      responseType: 'arraybuffer',
    },
  );
}
export function getTransferTokens(params: any): Promise<any> {
  return Instance.get(`core/rep/wallet/v2/transfer-tokens`, { params });
}
