import { List } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import InputSearch from 'app/components/common/InputSearch';
import { SvgIcon } from 'app/components/common/SvgIcon';
import clsx from 'clsx';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  accessControlListActions,
  selectInitGroups,
  selectUpdateGroups,
} from 'store/slices/accessControl/accessControl.slice';
import { history } from 'utils/history';
import styles from './index.module.scss';

type Props = {
  loading: boolean;
  isPending?: boolean;
  pendingId?: string;
  isOpen: boolean;
};

const AccessControlCheckList = ({
  loading,
  isPending,
  pendingId,
  isOpen,
}: Props) => {
  const dispatch = useDispatch();
  const updatedGroups = useAppSelector(selectUpdateGroups);
  const [checkedAllMobile, setCheckAllMobile] = useState(false);
  const [checkedAllWeb, setCheckAllWeb] = useState(false);
  const initGroups = useAppSelector(selectInitGroups);
  const [updatedData, setUpdatedData] = useState(initGroups);
  const [keyword, setKeyword] = useState('');
  const [filteringGroups, setFilteringGroups] = useState<any[]>();
  const [webEnableGroups, setWebEnableGroups] = useState<any[]>();
  const [mobileEnableGroups, setMobileEnableGroups] = useState<any[]>();
  const isHavingKeyword = keyword.length > 0;

  useEffect(() => {
    if (isOpen) {
      setUpdatedData(initGroups);
      setKeyword('');
    }
  }, [isOpen, initGroups]);

  useEffect(() => {
    if (updatedData) {
      dispatch(accessControlListActions.setUpdateGroups(updatedData));
    }
  }, [updatedData]);

  useEffect(() => {
    let presentLength = updatedData?.length;
    let checkingGroupsMobile = updatedData?.filter(e => e.mobile === 'true');
    let checkingGroupsWeb = updatedData?.filter(e => e.web === 'true');
    if (isHavingKeyword) {
      presentLength = filteringGroups?.length || 0;
      checkingGroupsMobile =
        filteringGroups?.filter(e => e.mobile === 'true') || [];
      checkingGroupsWeb = filteringGroups?.filter(e => e.web === 'true') || [];
    }

    if (
      checkingGroupsMobile?.length === presentLength &&
      checkingGroupsMobile?.length > 0
    ) {
      setCheckAllMobile(true);
    } else {
      setCheckAllMobile(false);
    }

    if (
      checkingGroupsWeb?.length === presentLength &&
      checkingGroupsWeb?.length > 0
    ) {
      setCheckAllWeb(true);
    } else {
      setCheckAllWeb(false);
    }
  }, [updatedData, filteringGroups, isHavingKeyword]);

  const checkBoxMobile = (isChecked, id) => {
    const updatedMobileOnes = _.map(updatedData, element => {
      if (element.id === id) {
        return { ...element, mobile: isChecked.toString() };
      }
      return element;
    });

    setUpdatedData(updatedMobileOnes);
  };

  const checkBoxWeb = (isChecked, id) => {
    const updatedWebOnes = _.map(updatedData, element => {
      if (element.id === id) {
        return { ...element, web: isChecked.toString() };
      }
      return element;
    });

    setUpdatedData(updatedWebOnes);
  };

  const handleCheckBox = (e, id, isMobile) => {
    const isCheckedCheckBox = e.target.checked;
    if (isMobile) {
      checkBoxMobile(isCheckedCheckBox, id);
    } else {
      checkBoxWeb(isCheckedCheckBox, id);
    }
  };

  const handleCheckAllMobile = event => {
    if (isHavingKeyword) {
      const updatedAllMobileOnes = _.map(filteringGroups, element => {
        return {
          ...element,
          mobile: event.target.checked.toString(),
        };
      });
      const updatedDataObj = _.mapValues(
        _.keyBy(updatedAllMobileOnes, 'id'),
        'mobile',
      );
      const updatedAllMobileOnesForUpdateData = updatedData.map(item => {
        return {
          ...item,
          mobile:
            updatedDataObj[item.id] === undefined
              ? item.mobile
              : updatedDataObj[item.id],
        };
      });
      setFilteringGroups(updatedAllMobileOnes);
      setUpdatedData(updatedAllMobileOnesForUpdateData);
    } else {
      const updatedAllMobileOnes = _.map(updatedData, element => {
        return { ...element, mobile: event.target.checked.toString() };
      });
      setUpdatedData(updatedAllMobileOnes);
    }
  };

  const handleCheckAllWeb = event => {
    if (isHavingKeyword) {
      const updatedAllWebOnes = _.map(filteringGroups, element => {
        return {
          ...element,
          web: event.target.checked.toString(),
        };
      });
      const updatedDataObj = _.mapValues(
        _.keyBy(updatedAllWebOnes, 'id'),
        'web',
      );
      const updatedAllWebOnesForUpdateData = updatedData.map(item => {
        return {
          ...item,
          web:
            updatedDataObj[item.id] === undefined
              ? item.web
              : updatedDataObj[item.id],
        };
      });
      setFilteringGroups(updatedAllWebOnes);
      setUpdatedData(updatedAllWebOnesForUpdateData);
    } else {
      const updatedAllWebOnes = _.map(updatedData, element => {
        return { ...element, web: event.target.checked.toString() };
      });
      setUpdatedData(updatedAllWebOnes);
    }
  };

  const onInputSearch = keyword => {
    setKeyword(keyword);
  };

  const handleViewRequest = useCallback(() => {
    if (!pendingId) {
      return;
    }
    history.replace({
      pathname: '/pending-request',
      search: `id=${pendingId}`,
    });
  }, [pendingId]);

  let locale = {
    emptyText: filteringGroups?.length === 0 && (
      <div style={{ height: '367px' }}></div>
    ),
  };

  useEffect(() => {
    if (isHavingKeyword) {
      setWebEnableGroups(filteringGroups?.filter(e => e.web === 'true'));
      setMobileEnableGroups(filteringGroups?.filter(e => e.mobile === 'true'));
    } else {
      const enableMobileGroups = updatedGroups
        ? updatedGroups?.filter(e => e.mobile === 'true')
        : initGroups?.filter(e => e.mobile === 'true');
      const enableWebGroups = updatedGroups
        ? updatedGroups?.filter(e => e.web === 'true')
        : initGroups?.filter(e => e.web === 'true');

      setWebEnableGroups(enableWebGroups);
      setMobileEnableGroups(enableMobileGroups);
    }
  }, [isHavingKeyword, initGroups, updatedGroups, filteringGroups]);

  useEffect(() => {
    if (isHavingKeyword) {
      const filteredData = updatedGroups?.filter(item =>
        item.name.toLowerCase().includes(keyword.toLowerCase()),
      );
      setFilteringGroups(filteredData);
    }
  }, [isHavingKeyword, updatedGroups, keyword]);

  const checklistItem = (item: any) => {
    const isEnableMobile = mobileEnableGroups?.some(e => e.id === item.id);
    const isEnableWeb = webEnableGroups?.some(e => e.id === item.id);
    const isChecked = isEnableMobile || isEnableWeb;
    return (
      <List.Item
        key={item.name}
        className={clsx(styles.list_item, {
          [styles.list_item__checked]: isChecked,
        })}
      >
        <div className={styles.item_content}>{item?.name}</div>
        <div className={styles.check_container} style={{ marginRight: '20px' }}>
          <div className={styles.check_item} style={{ marginRight: '34px' }}>
            <Checkbox
              className={clsx(styles.check_box_access_control, {
                [styles.check_box_access_control__disable]: isPending,
              })}
              checked={isEnableMobile}
              onChange={event => handleCheckBox(event, item?.id, true)}
              disabled={isPending}
            />
          </div>

          <div className={styles.check_item}>
            <Checkbox
              className={clsx(styles.check_box_access_control, {
                [styles.check_box_access_control__disable]: isPending,
              })}
              checked={isEnableWeb}
              onChange={event => handleCheckBox(event, item?.id, false)}
              disabled={isPending}
            />
          </div>
        </div>
      </List.Item>
    );
  };

  return (
    <div className={styles.modal_content}>
      {isPending && (
        <div className={styles.pending_request} data-testId="pending-component">
          <SvgIcon name="infor---warning" width={18} height={18} />
          <div className={styles.pending_request__content}>
            This feature has a pending request awaiting for approval
          </div>
          <div
            className={styles.pending_request__viewRequest}
            onClick={handleViewRequest}
          >
            View request
          </div>
        </div>
      )}
      <InputSearch
        onInputChange={onInputSearch}
        className={styles.input_search_detail}
        data-testId="search-input"
        term={keyword}
      />
      <div className={styles.check_all_container}>
        <div className={styles.content}>Customer groups</div>
        <div className={styles.check_container}>
          <div className={styles.check_item} data-testId="mobile-check-all">
            <div>Mobile</div>
            <Checkbox
              className={clsx(styles.check_box_access_control, {
                [styles.check_box_access_control__disable]: isPending,
              })}
              checked={checkedAllMobile}
              onChange={handleCheckAllMobile}
              disabled={isPending}
            />
          </div>
          <div className={styles.check_item} data-testId="web-check-all">
            <div>Web</div>
            <Checkbox
              className={clsx(styles.check_box_access_control, {
                [styles.check_box_access_control__disable]: isPending,
              })}
              checked={checkedAllWeb}
              onChange={handleCheckAllWeb}
              disabled={isPending}
            />
          </div>
        </div>
      </div>

      <List
        dataSource={keyword.length > 0 ? filteringGroups : initGroups}
        className={styles.group_checklist}
        renderItem={item => checklistItem(item)}
        loading={loading}
        data-testId="groups-list"
        locale={locale}
      ></List>
    </div>
  );
};

export default AccessControlCheckList;
