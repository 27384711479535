import { LoadingOutlined } from '@ant-design/icons';
import { Col, Collapse, Drawer, Row, Table, notification } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import RakkarAvatar from 'app/components/Transaction/DetailTransaction/BoxComment/Avatar';
import BasicButton from 'app/components/common/BasicButton';
import RakkarStatusButton from 'app/components/common/RakkarStatusButton';
import { FILE_TYPE, FORMAT_DATE } from 'constants/index';
import { InvoiceStatus } from 'enum/billing';
import { ListActionNeedToCheckPermission, TAB_REQUEST } from 'enum/common';
import imgColdWallet from 'images/cold-wallet.png';
import imgHotWallet from 'images/hot-wallet.png';
import { RequestStatus } from 'interfaces/request';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectPermissions } from 'store/slices/auth';
import {
  billingsActions,
  selectBillingDetail,
  selectparamsBillingList,
} from 'store/slices/billings';
import {
  checkPermissions,
  downloadFile,
  formatThousandSeparator,
  handleShowMsgErr,
} from 'utils/common';
import ModalReviewBilling from './components/ModalReviewBilling.tsx';

import ToastMessage from 'app/components/common/ToastMessage';
import { BillingRecord } from 'interfaces/billings';
import { toast } from 'react-toastify';
import {
  downloadBillingDetail,
  reGenerateBilling,
  syncTokenPrice,
} from 'services/billings';
import { formatCurrencyTypeB } from 'utils/helpers';
import { getWorkspaceTypeName } from 'utils/workspace.utils';
import ModalMarkAsPaid from './components/ModalMarkAsPaid/index';
import styles from './index.module.scss';
import clsx from 'clsx';

type Props = {
  open: boolean;
  setOpen: (v: boolean) => void;
  itemSelected: BillingRecord | undefined;
  setSelectedRowId: (v: string) => void;
  tabRequest?: string;
};

interface ITextInfor {
  label: string;
  text: any;
  span?: number;
  dataTestId?: string;
  isNumb?: boolean;
  dataType?: string;
}
interface ITitleInfor {
  className?: string;
  title?: string;
}

const BillingDetail = ({
  open,
  setOpen,
  itemSelected,
  setSelectedRowId,
  tabRequest,
}: Props) => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmMarkAsPaid, setOpenModalConfirmMarkAsPaid] =
    useState(false);
  const BillingDetail = useAppSelector(selectBillingDetail);
  const params = useAppSelector(selectparamsBillingList);
  const loading = BillingDetail.status === RequestStatus.Loading;
  const { yearMonth } = BillingDetail?.data || {};
  const customerInfo = BillingDetail?.data?.customerInfo;
  const reviewedBy = BillingDetail?.data?.reviewedBy;
  const markAsPaidBy = BillingDetail?.data?.markAsPaidBy;
  const isReviewed =
    BillingDetail?.data?.customerInfo?.invoiceStatus === InvoiceStatus.REVIEWED;
  const isPaid =
    BillingDetail?.data?.customerInfo?.invoiceStatus === InvoiceStatus.PAID;
  const isInitial =
    BillingDetail?.data?.customerInfo?.invoiceStatus === InvoiceStatus.INITIAL;
  const [downloading, setDownloading] = useState(false);
  const invoicePeriod = BillingDetail?.data?.customerInfo?.invoicePeriod;

  const dispatch = useDispatch();
  const permissions = useAppSelector(selectPermissions);

  const allowApproveReject = checkPermissions(
    ListActionNeedToCheckPermission.REVIEW_INVOICE,
    permissions,
  );

  const allowMarkAsReviewed = checkPermissions(
    ListActionNeedToCheckPermission.MARK_BILLING_AS_REVIEWED,
    permissions,
  );
  const allowMarkAsPaid = checkPermissions(
    ListActionNeedToCheckPermission.MARK_BILLING_AS_PAID,
    permissions,
  );

  const isAllowMarkAsPaid = status => {
    return (
      status === InvoiceStatus.OVERDUE ||
      status === InvoiceStatus.UNPAID ||
      status === InvoiceStatus.REVIEWED
    );
  };

  const handleStatus = status => {
    if (status === InvoiceStatus.INITIAL) {
      return InvoiceStatus.PENDING;
    }
    return status;
  };

  const columnsUAC = [
    {
      title: 'From',
      dataIndex: 'from',
    },
    {
      title: 'To',
      dataIndex: 'to',
    },
    {
      title: 'Annual basis points',
      dataIndex: 'point',
    },
  ];
  useEffect(() => {
    if (itemSelected?.id) {
      dispatch(billingsActions.getBillingDetailRequest(itemSelected.id));
    }
  }, [itemSelected, dispatch]);

  const onClose = () => {
    setOpen(false);
    setSelectedRowId('');
  };

  const TextInfor = ({
    span = 12,
    label,
    text,
    dataTestId,
    isNumb = false,
    dataType,
  }: ITextInfor) => {
    return (
      <Col span={span}>
        <div className={styles.content__item}>
          <p className={styles.content__label}>{label}</p>
          <p
            className={styles.content__text}
            data-testid={dataType ? `${dataType}-${dataTestId}` : dataTestId}
          >
            {isNumb ? `$ ${formatCurrencyTypeB(text)}` : text}
          </p>
        </div>
      </Col>
    );
  };

  const TitleInfor = ({ className = '', title }: ITitleInfor) => {
    return (
      <div className={styles['wallet-title']}>
        <div className={`${styles['wallet-title__content']} ${className}`}>
          {title}
        </div>
        <div className={styles['wallet-title__line']} />
      </div>
    );
  };

  const handleDownloadFile = useCallback(async () => {
    setDownloading(true);
    try {
      if (customerInfo?.invoicePeriod && itemSelected?.id) {
        const params = {
          period: customerInfo.invoicePeriod,
          billingId: itemSelected.id,
          isHistory: tabRequest === TAB_REQUEST.ALL,
        };
        const { data } = await downloadBillingDetail(params);
        setDownloading(false);
        data && toast(<ToastMessage type="info" message="Download started." />);

        const filename = `${itemSelected?.invoiceNumber}.zip`;
        downloadFile(data, filename, FILE_TYPE.ZIP);
      }
    } catch (error: any) {
      setDownloading(false);
      handleShowMsgErr(error);
    }
  }, [customerInfo, itemSelected]);

  const RenderWallet = ({ walletDetail, type }: any) => {
    const currentMonthInvoice = walletDetail?.currentMonthInvoice;
    const previousMonthInvoice = walletDetail?.previousMonthInvoice;
    const feeProfile = walletDetail?.feeProfile;

    return (
      <div className={styles['wallet-detail']}>
        <TitleInfor className={styles.current} title="Current month invoice" />
        <Row>
          <TextInfor
            label="Total asset valuation"
            text={currentMonthInvoice?.totalAssetValue}
            dataTestId="total-asset"
            isNumb
            dataType={type}
          />
          <TextInfor
            label="Final fee"
            text={currentMonthInvoice?.finalFee}
            dataTestId="final-fee"
            isNumb
            dataType={type}
          />
          <TextInfor
            label="Total deposit value"
            text={currentMonthInvoice?.totalDepositValue}
            dataTestId="total-deposit"
            isNumb
            dataType={type}
          />
          <TextInfor
            label="Total withdrawal value"
            text={currentMonthInvoice?.totalWithdrawalValue}
            dataTestId="total-withdrawal"
            isNumb
            dataType={type}
          />
          <TextInfor
            label="Average AUC"
            text={currentMonthInvoice?.averageAUC}
            dataTestId="average-AUC"
            isNumb
            dataType={type}
          />
        </Row>
        {previousMonthInvoice && (
          <>
            <TitleInfor title="Previous month invoice" />
            <Row>
              <TextInfor
                label="Total asset valuation"
                text={previousMonthInvoice?.totalAssetValue}
                dataTestId="previous-total-asset"
                isNumb
                dataType={type}
              />
              <TextInfor
                label="Final fee"
                text={previousMonthInvoice?.finalFee}
                dataTestId="previous-final-fee"
                isNumb
                dataType={type}
              />
              <TextInfor
                label="Total deposit value"
                text={previousMonthInvoice?.totalDepositValue}
                dataTestId="previous-total-deposit"
                isNumb
                dataType={type}
              />
              <TextInfor
                label="Total withdrawal value"
                text={previousMonthInvoice?.totalWithdrawalValue}
                dataTestId="previous-total-withdrawal"
                isNumb
                dataType={type}
              />
              <TextInfor
                label="Average AUC"
                text={previousMonthInvoice?.averageAUC}
                dataTestId="previous-average-AUC"
                isNumb
                dataType={type}
              />
            </Row>
          </>
        )}
        <TitleInfor title="Fee profile" />
        <Row>
          <TextInfor
            label="Initial setup fee (USD)"
            text={feeProfile?.initialSetupFee}
            dataTestId="initial-setup-fee"
            isNumb
            dataType={type}
          />
          <TextInfor
            label="Minimum monthly fee (USD)"
            text={feeProfile?.minimumMonthlyFee}
            dataTestId="minimum-monthly-fee"
            isNumb
            dataType={type}
          />
          <TextInfor
            label="Minimum AUC Balance (USD)"
            text={feeProfile?.minimumAUCBalance}
            dataTestId="minimum-AUC-balance"
            isNumb
            dataType={type}
          />
          <TextInfor
            span={24}
            label="Asset under custody"
            text={
              <div>
                <Table
                  className={styles.tableAUC}
                  columns={columnsUAC}
                  bordered
                  dataSource={feeProfile?.assetUnderCustody.map(
                    ({ key, from, to, point }) => ({
                      key,
                      from: formatThousandSeparator(from),
                      to: formatThousandSeparator(to),
                      point: formatThousandSeparator(point),
                    }),
                  )}
                  pagination={false}
                />
              </div>
            }
            dataTestId="AUC-table"
            dataType={type}
          />
        </Row>
      </div>
    );
  };
  return (
    <div className={styles.container} data-testid="billing-detail">
      <Drawer
        width={665}
        placement="right"
        onClose={onClose}
        forceRender
        open={open}
        keyboard={false}
        title={
          <div
            className={styles.id}
            data-testid="invoice-ID"
          >{`Invoice ID: ${itemSelected?.invoiceNumber}`}</div>
        }
        extra={
          isInitial
            ? [
                <BasicButton
                  onClick={() => {
                    if (!!customerInfo?.id && !!yearMonth) {
                      notification.info({
                        message: `Start process`,
                      });
                      syncTokenPrice(customerInfo?.id, yearMonth)
                        .then(res => {
                          if (res?.[1] > 0) {
                            return notification.success({
                              message: `Sync ${res[1]} records`,
                            });
                          }
                          return notification.warn({
                            message: `No data sync`,
                          });
                        })
                        .catch(error => {
                          return notification.warn({
                            message: `Fail`,
                            description: error,
                          });
                        });
                    }
                  }}
                  key="Sync token price"
                  type="outline"
                  title={'Sync token price'}
                />,
                <BasicButton
                  key="Re generate"
                  type="filled"
                  title="Re generate"
                  onClick={() => {
                    if (!!customerInfo?.id && !!yearMonth) {
                      notification.info({
                        message: `Start process`,
                      });
                      reGenerateBilling(customerInfo?.id, yearMonth)
                        .then(() => {
                          dispatch(
                            billingsActions.getBillingListRequest({
                              isHistory: tabRequest === TAB_REQUEST.ALL,
                              ...params,
                            }),
                          );
                          dispatch(
                            billingsActions.getBillingDetailRequest(
                              itemSelected?.id,
                            ),
                          );
                        })
                        .catch(error => {
                          return notification.warn({
                            message: `Fail`,
                            description: error,
                          });
                        });
                    }
                  }}
                />,
              ]
            : []
        }
        closable={false}
        className={styles.drawerBilling}
      >
        {loading ? (
          <div className={styles.loading}>
            <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
          </div>
        ) : (
          <div>
            <div className={styles.title}>Invoice description</div>
            <div className={styles['customer-info']}>
              <div className={styles['customer-info__title']}>
                Customer information
              </div>
              <div className={styles['customer-info__line']} />
            </div>
            <Row>
              <TextInfor
                label="Customer name"
                text={
                  <div className={styles['customer-info__name']}>
                    {customerInfo?.name}
                  </div>
                }
                dataTestId="customer-name"
              />
              <TextInfor
                label="Business registration number"
                text={customerInfo?.businessRegistrationNumber}
                dataTestId="business-registration-number"
              />
              <TextInfor
                label="Rakkar ID"
                text={customerInfo?.rakkarId}
                dataTestId="rakkar-id"
              />
              <TextInfor
                label="Billing period"
                text={customerInfo?.invoicePeriod}
                dataTestId="invoice-period"
              />
              <TextInfor
                label="Due date"
                text={
                  customerInfo?.paymentDueDate
                    ? moment(customerInfo?.paymentDueDate ?? '').format(
                        FORMAT_DATE.DEFAULT,
                      )
                    : ''
                }
                dataTestId="rakkar-id"
              />
              <TextInfor
                label="Credit term"
                text={customerInfo?.creditTerm ?? ''}
                dataTestId="credit-term"
              />
              {customerInfo?.invoiceStatus && (
                <TextInfor
                  label="Invoice status"
                  text={
                    <RakkarStatusButton
                      status={handleStatus(customerInfo.invoiceStatus)}
                    />
                  }
                  dataTestId="invoice-status"
                />
              )}
            </Row>
            <div className={styles.wallet}>
              <Collapse
                ghost
                expandIconPosition="end"
                className={styles.collapse}
                defaultActiveKey={
                  BillingDetail?.data?.hotWallet
                    ? ['hot-wallet']
                    : ['cold-wallet']
                }
              >
                {BillingDetail?.data?.hotWallet && (
                  <CollapsePanel
                    header={
                      <div className={styles['collapse-title']}>
                        <div
                          className={styles['collapse-title__content']}
                          data-testid="wallet-collapse"
                        >
                          <img src={imgHotWallet} alt="" />
                          <div className={styles['hot-wallet']}>
                            {getWorkspaceTypeName('hot')}
                          </div>
                        </div>
                        <div className={styles['collapse-title__line']} />
                      </div>
                    }
                    key={'hot-wallet'}
                  >
                    <RenderWallet
                      walletDetail={BillingDetail?.data.hotWallet}
                    />
                  </CollapsePanel>
                )}
                {BillingDetail?.data?.coldWallet && (
                  <CollapsePanel
                    header={
                      <div className={styles['collapse-title']}>
                        <div className={styles['collapse-title__content']}>
                          <img src={imgColdWallet} alt="" />
                          <div className={styles['cold-wallet']}>
                            {getWorkspaceTypeName('cold')}
                          </div>
                        </div>
                        <div className={styles['collapse-title__line']} />
                      </div>
                    }
                    key={'cold-wallet'}
                  >
                    <RenderWallet
                      walletDetail={BillingDetail?.data.coldWallet}
                      type="cold"
                    />
                  </CollapsePanel>
                )}
              </Collapse>
            </div>
            {BillingDetail.data?.reviewedAt && (
              <div className={styles.history} data-testid="log">
                <div className={styles.reviewed}>Reviewed</div>
                <div>By</div>
                <div className={styles.img}>
                  <RakkarAvatar
                    avatar={reviewedBy?.picture || ''}
                    name={reviewedBy?.userName || ''}
                  />
                </div>
                <div className={styles.name}>{reviewedBy?.userName}</div>
                <div className={styles.date}>
                  {BillingDetail?.data?.reviewedAt
                    ? moment(BillingDetail.data.reviewedAt ?? '').format(
                        FORMAT_DATE.DATE_DMY_hM,
                      )
                    : ''}
                </div>
              </div>
            )}
            {isPaid && (
              <div
                className={clsx(styles.history, {
                  [styles.history__paid]: BillingDetail.data?.reviewedAt,
                })}
                data-testid="log"
              >
                <div className={styles.reviewed}>Marked as paid</div>
                <div>By</div>
                <div className={styles.img}>
                  <RakkarAvatar
                    avatar={markAsPaidBy?.picture || ''}
                    name={markAsPaidBy?.userName || ''}
                  />
                </div>
                <div className={styles.name}>{markAsPaidBy?.userName}</div>
                <div className={styles.date}>
                  {BillingDetail?.data?.paidAt
                    ? moment(BillingDetail.data.paidAt ?? '').format(
                        FORMAT_DATE.DATE_DMY_hM,
                      )
                    : ''}
                </div>
              </div>
            )}
            <div className={styles.footer}>
              <div className={styles.footer__left}>
                <BasicButton
                  title="View invoice"
                  type="outline"
                  onClick={() => {
                    itemSelected?.id &&
                      dispatch(
                        billingsActions.fetchInvoicePdfRequest({
                          billingId: itemSelected?.id,
                          isHistory: tabRequest === TAB_REQUEST.ALL,
                        }),
                      );
                  }}
                  dataTestId="view-invoice"
                />
              </div>
              <div className={styles.footer__right}>
                <div className={styles.download}>
                  <BasicButton
                    title="Download"
                    type="secondary"
                    dataTestId="download"
                    onClick={handleDownloadFile}
                    loading={downloading}
                  />
                </div>
                {allowApproveReject &&
                  !isReviewed &&
                  !isPaid &&
                  !isAllowMarkAsPaid(customerInfo?.invoiceStatus) &&
                  allowMarkAsReviewed && (
                    <div className={styles['mark-as-reviewd']}>
                      <BasicButton
                        title="Mark as reviewed"
                        type="filled"
                        onClick={() => {
                          setOpenModalConfirm(true);
                        }}
                        dataTestId="mark-as-reviewed"
                      />
                    </div>
                  )}
                {isAllowMarkAsPaid(customerInfo?.invoiceStatus) &&
                  allowMarkAsPaid && (
                    <div className={styles['mark-as-reviewd']}>
                      <BasicButton
                        title="Mark as paid"
                        type="filled"
                        onClick={() => {
                          setOpenModalConfirmMarkAsPaid(true);
                        }}
                        dataTestId="mark-as-paid"
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </Drawer>
      <ModalReviewBilling
        open={openModalConfirm}
        setOpen={setOpenModalConfirm}
        invoiceId={itemSelected?.id}
        invoicePeriod={invoicePeriod}
      />

      <ModalMarkAsPaid
        open={openModalConfirmMarkAsPaid}
        setOpen={setOpenModalConfirmMarkAsPaid}
        invoiceId={itemSelected?.id}
      />
    </div>
  );
};

export default BillingDetail;
