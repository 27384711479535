import { Col, Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import { STATUS_REQUEST } from 'constants/index';
import { useState } from 'react';

import CustomerProfileTab from 'app/components/common/CustomerProfile';
import RakkarUserRole from 'app/components/common/RakkarUserRole';
import TextInfor from 'app/components/common/TextInfor';
import styles from './index.module.scss';

type Props = {
  requestDetail: any;
  isMask: boolean;
};

const ReplaceDeviceRequest = ({ requestDetail, isMask }: Props) => {
  const isApproved = requestDetail?.status === STATUS_REQUEST.APPROVED;
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.container} data-testid="replace-device-request">
      <div className={styles.icon}>
        <SvgIcon name="phone-new" width={32} height={32} />
      </div>
      <div className={styles.title}>
        <div>Replace device request</div>
        {(isApproved || requestDetail?.status === STATUS_REQUEST.REJECTED) && (
          <div
            className={`${styles.status} ${
              isApproved ? styles.approved : styles.rejected
            }`}
            data-testid="status"
          >
            <div>
              <SvgIcon name={isApproved ? 'white-approve' : 'white-reject'} />
            </div>
            <div>
              {isApproved ? STATUS_REQUEST.APPROVED : STATUS_REQUEST.REJECTED}
            </div>
          </div>
        )}
      </div>
      <div className={styles['cus-info']}>
        <Row>
          <Col span={12}>
            <div className={styles['cus-info__label']}>Customer name</div>
            <div
              className={styles['cus-info__content']}
              onClick={() => setOpen(true)}
            >
              {requestDetail?.customer?.name}
            </div>
          </Col>
          <Col span={12}>
            <div className={styles['cus-info__label']}>
              Business registration number
            </div>
            <div
              className={styles['cus-info__content']}
              onClick={() => setOpen(true)}
            >
              {requestDetail?.customer?.businessRegistrationId}
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.content}>
        <div className={styles['user-info']}>
          <div>
            <SvgIcon name="user-info" width={16} height={16} />
          </div>
          <div className={styles['user-info__title']}>User information</div>
        </div>
        <Row>
          <TextInfor
            label="Name"
            text={
              <div className={styles.name} data-testid="name">
                {requestDetail?.user?.name}
              </div>
            }
            dataTestId="customer-name"
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            label="Role"
            text={
              <div className={styles.role}>
                <div>
                  <RakkarUserRole userRole={requestDetail?.user?.roleName} />
                </div>
                {requestDetail?.user?.requiredApprover && (
                  <div
                    className={styles.required}
                    data-testid="required-approver"
                  >
                    REQUIRED APPROVER
                    <SvgIcon name="required-approver" width={10} height={12} />
                  </div>
                )}
              </div>
            }
            dataTestId="customer-name"
          />
        </Row>
        <div className={styles['contact-info']}>
          <div className={styles['contact-info__title']}>
            Contact information
          </div>
          <div className={styles['contact-info__line']}></div>
        </div>
        <Row>
          <TextInfor
            label="Phone number"
            text={<div>{requestDetail?.user?.phoneNumber}</div>}
            dataTestId="phone-number"
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            label="Email"
            text={<div>{requestDetail?.user?.email}</div>}
            dataTestId="email"
            isMask={isMask}
            isMaskable={true}
          />
        </Row>
      </div>
      <CustomerProfileTab
        open={open}
        setOpen={setOpen}
        requestDetail={requestDetail}
        isMask={isMask}
      />
    </div>
  );
};

export default ReplaceDeviceRequest;
