import { useEffect } from 'react';

import { cusSubscribeApi } from '../../../../../../../store/slices/cusSubscribe/cusSubscribe.api';

const { useGetCusSubscribesQuery } = cusSubscribeApi;

const useCheckExistCusSubscribe = (customerId: string) => {
  const { data, refetch } = useGetCusSubscribesQuery({
    where: JSON.stringify({
      AND: [{ customerId: { IS: customerId } }],
    }),
  });

  useEffect(() => {
    refetch();
  }, [customerId]);

  return Boolean(data?.total);
};

export default useCheckExistCusSubscribe;
