import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PER_PAGE } from 'constants/index';
import {
  CreateFeeInformationReq,
  FetchFeeInformationRes,
  UpdateFeeInformationReq,
} from 'interfaces/businessInformation';
import {
  CustomerAccountQuorumResponse,
  ListVaultsByCustomerResponse,
  ListVaultsUnAssignedRequest,
} from 'interfaces/customers';
import { Params, ParamsUserList, RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CustomerProfile,
  CustomerProfileRequest,
  GetListUserRequest,
  ListUserResponse,
  MarkAsLostDeviceRequest,
  ReplaceLostDeviceRequest,
  UserInfoDetail,
  ResendEmailRequest,
  RemoveUserAccess,
  UnlockUserRequest,
  VaultLVCheck,
} from 'interfaces/userListing';
import { AppState } from 'store';

interface CustomerProfileState {
  paramsUserList: ParamsUserList;
  userListing: ResponseData<ListUserResponse>;
  profile: ResponseData<CustomerProfile>;
  forceScreenUserListing: ResponseData<any>;
  userDetail: ResponseData<UserInfoDetail>;
  resendEmail: ResponseData<any>;
  markAsLostDevice: ResponseData<any>;
  replaceLostDevice: ResponseData<any>;
  removeUserAccess: ResponseData<any>;
  feeInformation: ResponseData<FetchFeeInformationRes>;
  addNewFeeInformation: ResponseData<any>;
  updateFeeInformation: ResponseData<any>;
  accountQuorum: ResponseData<CustomerAccountQuorumResponse>;
  canLoadMoreUserListing: boolean;
  unlockUser: ResponseData<any>;
  vaultsUnAssigned: ResponseData<ListVaultsByCustomerResponse>;
}

export function defaultUserListParams() {
  return {
    [Params.Page]: 1,
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
  };
}

const initialState: CustomerProfileState = {
  paramsUserList: defaultUserListParams(),
  userListing: {
    status: RequestStatus.Idle,
  },
  profile: {
    status: RequestStatus.Idle,
  },
  forceScreenUserListing: {
    status: RequestStatus.Idle,
  },
  userDetail: {
    status: RequestStatus.Idle,
  },

  markAsLostDevice: {
    status: RequestStatus.Idle,
  },

  resendEmail: {
    status: RequestStatus.Idle,
  },

  replaceLostDevice: {
    status: RequestStatus.Idle,
  },

  removeUserAccess: {
    status: RequestStatus.Idle,
    data: {
      isRequiredApprover: {
        status: RequestStatus.Idle,
      },
      isValidQuromSize: {
        status: RequestStatus.Idle,
      },
      isInAdvancedVaults: {
        status: RequestStatus.Idle,
      },
      isRequiredApproverAndVault: {
        status: RequestStatus.Idle,
      },
      isUnmetQuorumAndVault: {
        status: RequestStatus.Idle,
      },
      vaultList: [],
    },
  },

  feeInformation: {
    status: RequestStatus.Idle,
  },
  addNewFeeInformation: {
    status: RequestStatus.Idle,
  },
  updateFeeInformation: {
    status: RequestStatus.Idle,
  },
  accountQuorum: {
    status: RequestStatus.Idle,
  },
  canLoadMoreUserListing: true,
  unlockUser: {
    status: RequestStatus.Idle,
  },

  vaultsUnAssigned: {
    status: RequestStatus.Idle,
  },
};

const fetchUserListingRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<GetListUserRequest>
> = (state: CustomerProfileState) => {
  state.userListing.status = RequestStatus.Loading;
};

const fetchUserListingSuccess: CaseReducer<
  CustomerProfileState,
  PayloadAction<ListUserResponse>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<ListUserResponse>,
) => {
  state.userListing.status = RequestStatus.Success;
  state.userListing.data = payload;
};

const fetchUserListingFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.userListing.status = RequestStatus.Failed;
};

const fetchCustomerByIdRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<CustomerProfileRequest>
> = (state: CustomerProfileState) => {
  state.profile.status = RequestStatus.Loading;
};

const fetchCustomerByIdSuccess: CaseReducer<
  CustomerProfileState,
  PayloadAction<CustomerProfile>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<CustomerProfile>,
) => {
  state.profile.status = RequestStatus.Success;
  state.profile.data = payload;
};

const fetchCustomerByIdFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.profile.status = RequestStatus.Failed;
};

const resetUserListParams = state => {
  state.paramsUserList = defaultUserListParams();
};

const resetUserListing: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.paramsUserList = defaultUserListParams();
  state.userListing = {
    status: RequestStatus.Idle,
  };
};

const forceScreenRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<{ customerId: string }>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<{ customerId: string }>,
) => {
  state.forceScreenUserListing.status = RequestStatus.Loading;
};

const forceScreenSuccess: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.forceScreenUserListing.status = RequestStatus.Success;
};

const forceScreenFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.forceScreenUserListing.status = RequestStatus.Failed;
};

const fetchUserDetailRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<{ id: string }>
> = (state: CustomerProfileState) => {
  state.userDetail.status = RequestStatus.Loading;
};

const fetchUserDetailSuccess: CaseReducer<
  CustomerProfileState,
  PayloadAction<UserInfoDetail>
> = (state: CustomerProfileState, { payload }: PayloadAction<any>) => {
  state.userDetail.status = RequestStatus.Success;
  state.userDetail.data = payload;
};

const fetchUserDetailFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.userDetail.status = RequestStatus.Failed;
};

const markAsLostDeviceRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<MarkAsLostDeviceRequest>
> = (state: CustomerProfileState) => {
  state.markAsLostDevice.status = RequestStatus.Loading;
};

const markAsLostDeviceSuccess: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.markAsLostDevice.status = RequestStatus.Success;
};

const markAsLostDeviceFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.markAsLostDevice.status = RequestStatus.Failed;
};

const resetMarkAsLostDevice: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.markAsLostDevice.status = RequestStatus.Idle;
};

const removeUserAccessRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<RemoveUserAccess>
> = (state: CustomerProfileState) => {
  state.removeUserAccess.status = RequestStatus.Loading;
};

const removeUserAccessSuccess: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.removeUserAccess.status = RequestStatus.Success;
};

const removeUserAccessFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.removeUserAccess.status = RequestStatus.Failed;
};

const removeUserAccessFailedAdvancedVaults: CaseReducer<
  CustomerProfileState,
  PayloadAction<VaultLVCheck | undefined>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<VaultLVCheck | undefined>,
) => {
  state.removeUserAccess.data.isInAdvancedVaults.status = RequestStatus.Failed;
  if (payload !== undefined) {
    state.removeUserAccess.data.vaultList = payload;
  }
};

const removeUserAccessFailedRequired: CaseReducer<
  CustomerProfileState,
  PayloadAction<VaultLVCheck | undefined>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<VaultLVCheck | undefined>,
) => {
  state.removeUserAccess.data.isRequiredApprover.status = RequestStatus.Failed;
  if (payload !== undefined) {
    state.removeUserAccess.data.vaultList = payload;
  }
};

const removeUserAccessFailedQuorum: CaseReducer<
  CustomerProfileState,
  PayloadAction<VaultLVCheck | undefined>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<VaultLVCheck | undefined>,
) => {
  state.removeUserAccess.data.isValidQuromSize.status = RequestStatus.Failed;
  if (payload !== undefined) {
    state.removeUserAccess.data.vaultList = payload;
  }
};

const removeUserFailedRequiredAndVault: CaseReducer<
  CustomerProfileState,
  PayloadAction<VaultLVCheck | undefined>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<VaultLVCheck | undefined>,
) => {
  state.removeUserAccess.data.isRequiredApproverAndVault.status =
    RequestStatus.Failed;
  if (payload !== undefined) {
    state.removeUserAccess.data.vaultList = payload;
  }
};

const removeUserUnmetQuorumAndVault: CaseReducer<
  CustomerProfileState,
  PayloadAction<VaultLVCheck | undefined>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<VaultLVCheck | undefined>,
) => {
  state.removeUserAccess.data.isUnmetQuorumAndVault.status =
    RequestStatus.Failed;
  if (payload !== undefined) {
    state.removeUserAccess.data.vaultList = payload;
  }
};

const resetremoveUserUnmetQuorumAndVault: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.removeUserAccess.data.isUnmetQuorumAndVault.status = RequestStatus.Idle;
  state.removeUserAccess.data.vaultList = [];
};

const resetremoveUserFailedRequiredAndVault: CaseReducer<
  CustomerProfileState
> = (state: CustomerProfileState) => {
  state.removeUserAccess.data.isRequiredApproverAndVault.status =
    RequestStatus.Idle;
  state.removeUserAccess.data.vaultList = [];
};

const resetremoveUserAccessFailedRequired: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.removeUserAccess.data.isRequiredApprover.status = RequestStatus.Idle;
  state.removeUserAccess.data.vaultList = [];
};

const resetRemoveUserAccessFailedQuorum: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.removeUserAccess.data.isValidQuromSize.status = RequestStatus.Idle;
  state.removeUserAccess.data.vaultList = [];
};

const resetRemoveUserAccessFailedAdvancedVault: CaseReducer<
  CustomerProfileState
> = (state: CustomerProfileState) => {
  state.removeUserAccess.data.isInAdvancedVaults.status = RequestStatus.Idle;
  state.removeUserAccess.data.vaultList = [];
};

const resetRemoveUserAccess: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.removeUserAccess.status = RequestStatus.Idle;
};

const resendEmailRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<ResendEmailRequest>
> = (state: CustomerProfileState) => {
  state.resendEmail.status = RequestStatus.Loading;
};

const resendEmailSuccess: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.resendEmail.status = RequestStatus.Success;
};

const resendEmailFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.resendEmail.status = RequestStatus.Failed;
};

const resetResendEmail: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.resendEmail.status = RequestStatus.Idle;
};

const replaceLostDeviceRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<ReplaceLostDeviceRequest>
> = (state: CustomerProfileState) => {
  state.replaceLostDevice.status = RequestStatus.Loading;
};

const replaceLostDeviceSuccess: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.replaceLostDevice.status = RequestStatus.Success;
};

const replaceLostDeviceFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.replaceLostDevice.status = RequestStatus.Failed;
};

const resetReplaceLostDevice: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.replaceLostDevice.status = RequestStatus.Idle;
};

const fetchCustomerFeeByIdRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<{ customerId: string }>
> = (state: CustomerProfileState) => {
  state.feeInformation.status = RequestStatus.Loading;
};

const fetchCustomerFeeByIdSuccess: CaseReducer<
  CustomerProfileState,
  PayloadAction<FetchFeeInformationRes>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<FetchFeeInformationRes>,
) => {
  state.feeInformation.status = RequestStatus.Success;
  state.feeInformation.data = payload;
};

const fetchCustomerFeeByIdFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.feeInformation.status = RequestStatus.Failed;
};

const addNewFeeInformationRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<{ customerId: string; body: CreateFeeInformationReq }>
> = (state: CustomerProfileState) => {
  state.addNewFeeInformation.status = RequestStatus.Loading;
};

const addNewFeeInformationSuccess: CaseReducer<
  CustomerProfileState,
  PayloadAction<any>
> = (state: CustomerProfileState) => {
  state.addNewFeeInformation.status = RequestStatus.Success;
};

const addNewFeeInformationFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.addNewFeeInformation.status = RequestStatus.Failed;
};

const resetAddNewFeeInformationStatus: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.addNewFeeInformation.status = RequestStatus.Idle;
};

const updateFeeInformationRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<{
    customerId: string;
    feeInformationId: string;
    body: UpdateFeeInformationReq;
  }>
> = (state: CustomerProfileState) => {
  state.updateFeeInformation.status = RequestStatus.Loading;
};

const updateFeeInformationSuccess: CaseReducer<
  CustomerProfileState,
  PayloadAction<any>
> = (state: CustomerProfileState) => {
  state.updateFeeInformation.status = RequestStatus.Success;
};

const updateFeeInformationFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.updateFeeInformation.status = RequestStatus.Failed;
};

const resetUpdateFeeInformationStatus: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.updateFeeInformation.status = RequestStatus.Idle;
};

const fetchCustomerAccPolicyRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<{ customerId: string; query: { ignoreStatus?: boolean } }>
> = (state: CustomerProfileState) => {
  state.accountQuorum.status = RequestStatus.Loading;
};

const fetchCustomerAccPolicySuccess: CaseReducer<
  CustomerProfileState,
  PayloadAction<CustomerAccountQuorumResponse>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<CustomerAccountQuorumResponse>,
) => {
  state.accountQuorum.status = RequestStatus.Success;
  state.accountQuorum.data = payload;
};

const fetchCustomerAccPolicyFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.accountQuorum.status = RequestStatus.Failed;
};

const setCanLoadMoreUserListing = (
  state: CustomerProfileState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMoreUserListing = payload;
};

const unlockUserRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<UnlockUserRequest>
> = (state: CustomerProfileState) => {
  state.unlockUser.status = RequestStatus.Loading;
};

const unlockUserSuccess: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.unlockUser.status = RequestStatus.Success;
};

const unlockUserFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.unlockUser.status = RequestStatus.Failed;
};

const resetUnlockUser: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.unlockUser.status = RequestStatus.Idle;
};

// get vaults unassigned by customer
const fetchVaultsUnAssignedRequest: CaseReducer<
  CustomerProfileState,
  PayloadAction<ListVaultsUnAssignedRequest>
> = (state: CustomerProfileState) => {
  state.vaultsUnAssigned.status = RequestStatus.Loading;
};

const fetchVaultsUnAssignedSuccess: CaseReducer<
  CustomerProfileState,
  PayloadAction<ListVaultsByCustomerResponse>
> = (
  state: CustomerProfileState,
  { payload }: PayloadAction<ListVaultsByCustomerResponse>,
) => {
  state.vaultsUnAssigned.status = RequestStatus.Success;
  state.vaultsUnAssigned.data = payload;
};

const fetchVaultsUnAssignedFailed: CaseReducer<CustomerProfileState> = (
  state: CustomerProfileState,
) => {
  state.vaultsUnAssigned.status = RequestStatus.Failed;
};

const customerProfileSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    fetchUserListingRequest,
    fetchUserListingSuccess,
    fetchUserListingFailed,

    fetchCustomerByIdRequest,
    fetchCustomerByIdSuccess,
    fetchCustomerByIdFailed,

    resetUserListParams,

    setUserListParam<K extends keyof ParamsUserList>(
      state: CustomerProfileState,
      action: PayloadAction<{ key: K; value: ParamsUserList[K] }>,
    ) {
      state.paramsUserList[action.payload.key] = action.payload.value;
    },

    forceScreenRequest,
    forceScreenSuccess,
    forceScreenFailed,
    resetUserListing,

    fetchUserDetailRequest,
    fetchUserDetailSuccess,
    fetchUserDetailFailed,

    markAsLostDeviceRequest,
    markAsLostDeviceSuccess,
    markAsLostDeviceFailed,
    resetMarkAsLostDevice,

    removeUserAccessRequest,
    removeUserAccessSuccess,
    removeUserAccessFailed,
    resetRemoveUserAccess,
    removeUserAccessFailedQuorum,
    removeUserAccessFailedRequired,
    removeUserFailedRequiredAndVault,
    removeUserUnmetQuorumAndVault,
    removeUserAccessFailedAdvancedVaults,
    resetRemoveUserAccessFailedQuorum,
    resetremoveUserAccessFailedRequired,
    resetRemoveUserAccessFailedAdvancedVault,
    resetremoveUserFailedRequiredAndVault,
    resetremoveUserUnmetQuorumAndVault,

    resendEmailRequest,
    resendEmailSuccess,
    resendEmailFailed,
    resetResendEmail,

    replaceLostDeviceRequest,
    replaceLostDeviceSuccess,
    replaceLostDeviceFailed,
    resetReplaceLostDevice,

    fetchCustomerFeeByIdRequest,
    fetchCustomerFeeByIdSuccess,
    fetchCustomerFeeByIdFailed,

    addNewFeeInformationRequest,
    addNewFeeInformationSuccess,
    addNewFeeInformationFailed,
    resetAddNewFeeInformationStatus,

    updateFeeInformationRequest,
    updateFeeInformationSuccess,
    updateFeeInformationFailed,
    resetUpdateFeeInformationStatus,

    fetchCustomerAccPolicyRequest,
    fetchCustomerAccPolicySuccess,
    fetchCustomerAccPolicyFailed,

    setCanLoadMoreUserListing,

    unlockUserRequest,
    unlockUserSuccess,
    unlockUserFailed,
    resetUnlockUser,

    fetchVaultsUnAssignedRequest,
    fetchVaultsUnAssignedSuccess,
    fetchVaultsUnAssignedFailed,
  },
});

export const customerProfileActions = customerProfileSlice.actions;

export const customerProfileReducer = customerProfileSlice.reducer;

export const selectUserListing = (state: AppState) =>
  state.customerProfile.userListing;

export const selectParamsUserList = (state: AppState) =>
  state.customerProfile.paramsUserList;

export const selectCustomerProfile = (state: AppState) =>
  state.customerProfile?.profile;

export const selectUserDetail = (state: AppState) =>
  state.customerProfile.userDetail;

export const selectMarkAsLostDevice = (state: AppState) =>
  state.customerProfile.markAsLostDevice;

export const selectReplaceLostDevice = (state: AppState) =>
  state.customerProfile.replaceLostDevice;

export const selectCustomerFee = (state: AppState) =>
  state.customerProfile.feeInformation;

export const selectAddNewFee = (state: AppState) =>
  state.customerProfile.addNewFeeInformation;

export const selectUpdateFee = (state: AppState) =>
  state.customerProfile.updateFeeInformation;

export const selectCustomerAccountQuorum = (state: AppState) =>
  state.customerProfile.accountQuorum;

export const selectCanLoadMoreUserListing = (state: AppState) =>
  state.customerProfile.canLoadMoreUserListing;

export const selectResendEmail = (state: AppState) =>
  state.customerProfile.resendEmail;

export const selectUnlockUser = (state: AppState) =>
  state.customerProfile.unlockUser;

export const selectVaultsUnAssigned = (state: AppState) =>
  state.customerProfile?.vaultsUnAssigned;

export const selectRemoveUserAccess = (state: AppState) =>
  state.customerProfile.removeUserAccess;