import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import background from 'images/background.png';
import { useAppSelector } from 'store/hooks';
import { selectIsLoggedIn } from 'store/slices/auth';

const Wrapper = styled.div``;
const Main = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6)),
    url(${background}) !important;
  height: 100vh;
  background-repeat: initial;
  background-size: cover;
`;
const AuthLayout = () => {
  const location = useLocation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <Wrapper className="wrapper">
      <Main>
        <Outlet />
      </Main>
    </Wrapper>
  );
};

export default AuthLayout;
