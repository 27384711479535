import React from 'react';

import styles from './index.module.scss';
import { SvgIcon } from '../SvgIcon';

type Props = { sortOrder?: string };

const BasicIconSort = ({ sortOrder }: Props) => {
  return (
    <div className={styles.icon}>
      <SvgIcon
        name="sort-icon"
        width={8}
        height={5}
        className={sortOrder === 'ascend' ? styles.increase : styles.decrease}
      />
    </div>
  );
};

export default BasicIconSort;
