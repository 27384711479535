import { Row } from 'antd';
import { documentTypeOpts } from 'app/components/BusinessInformation/index.helper';
import { SvgIcon } from 'app/components/SvgIcon';
import IdPassportInfo from 'app/components/common/IdOrPassportInfo';
import RakkarUserRole from 'app/components/common/RakkarUserRole';
import Vaults from 'app/components/common/Vaults';
import { DOCUMENT_TYPE } from 'enum/common';
import { useMemo } from 'react';
import { lockByOpts } from 'utils/assets';
import TextInfor from '../../../common/TextInfor';
import styles from './index.module.scss';

interface Props {
  requestDetail: any;
  isMask: boolean;
}

const UserInfo = ({ requestDetail, isMask }: Props) => {
  const documentType = useMemo(() => {
    return (
      (!!requestDetail &&
        documentTypeOpts?.find(
          item => item.value === requestDetail?.user?.documentType,
        )?.label) ||
      ''
    );
  }, [requestDetail]);

  const lockedByValue = useMemo(() => {
    return (
      (!!requestDetail?.user &&
        lockByOpts?.find(item => item.value === requestDetail?.user?.lockedBy)
          ?.label) ||
      ''
    );
  }, [requestDetail?.user]);

  return (
    <>
      <div className={styles.content}>
        <div className={styles['user-inform']}>
          <div>
            <SvgIcon name="user-info" width={16} height={16} />
          </div>
          <div className={styles['user-inform__title']}>User information</div>
        </div>
        <Row>
          <TextInfor
            label="Name"
            text={
              <div className={styles.name}>{requestDetail?.user?.name}</div>
            }
            dataTestId="user-name"
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            label="Role"
            text={
              <div className={styles.role} data-testid="user-role">
                <div>
                  <RakkarUserRole userRole={requestDetail?.user?.role} />
                </div>
                {requestDetail?.user?.requiredApprover && (
                  <div className={styles.required}>
                    REQUIRED APPROVER
                    <SvgIcon
                      name="required-approver"
                      width={10}
                      height={12}
                      data-testid="required-approver-icon"
                    />
                  </div>
                )}
              </div>
            }
          />
        </Row>
        <Row>
          <TextInfor
            label="Identity document"
            text={
              <div
                data-testid="identity-document"
                className={styles.passport_id}
              >
                {requestDetail?.user?.documentType && (
                  <SvgIcon
                    name={
                      requestDetail?.user?.documentType ===
                      DOCUMENT_TYPE.PASSPORT
                        ? 'passport'
                        : 'id_card'
                    }
                    width={16}
                    height={16}
                    className={styles.passport_id__icon}
                  />
                )}
                {documentType ? documentType : '-'}
              </div>
            }
            isMask={isMask}
            isMaskable={false}
          />
        </Row>
        {requestDetail?.user?.documentType && (
          <IdPassportInfo requestDetail={requestDetail} isMask={isMask} />
        )}
        <Row>
          <TextInfor
            label="Phone number"
            text={
              <div data-testid="phone-number">
                {requestDetail?.user?.phoneNumber
                  ? requestDetail?.user?.phoneNumber
                  : '-'}
              </div>
            }
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            label="Email"
            text={
              <div data-testid="email">
                {requestDetail?.user?.email ? requestDetail?.user?.email : '-'}
              </div>
            }
            isMask={isMask}
            isMaskable={true}
          />
          <TextInfor
            label="Remark"
            text={
              lockedByValue ? (
                <div data-testid="remark">
                  {'This user account has been locked by '}
                  {lockedByValue}
                </div>
              ) : (
                <div data-testid="remark">{'-'}</div>
              )
            }
          />
        </Row>
        <Vaults requestDetail={requestDetail} />
      </div>
    </>
  );
};

export default UserInfo;
